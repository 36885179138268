export default {
  TAB_BASIC_INFO: 'Basic Information',
  BASIC_INFO_GENERAL_CARD_TITLE: 'General',
  BASIC_INFO_USAGE_TITLE: 'Usage',
  BASIC_INFO_CATEGORIZATION_CARD_TITLE: 'Categorization',
  BASIC_INFO_VALIDITY_DATES_CARD_TITLE: 'Validity dates',
  BASIC_INFO_LABEL_NAME: 'Name',
  BASIC_INFO_PLACEHOLDER_NAME: 'Name of the reward',
  BASIC_INFO_LABEL_DESCRIPTION: 'Description',
  BASIC_INFO_PLACEHOLDER_DESCRIPTION: 'Description of the reward',
  BASIC_INFO_LABEL_CATEGORY: 'Category',
  BASIC_INFO_PLACEHOLDER_CATEGORY: 'Select a Category',
  BASIC_INFO_LABEL_SUBCATEGORY: 'Subcategory',
  BASIC_INFO_PLACEHOLDER_SUBCATEGORY: 'Select a Subcategory',
  BASIC_INFO_LABEL_TYPE: 'Type',
  BASIC_INFO_PLACEHOLDER_TYPE: 'Select a Type',
  BASIC_INFO_LABEL_EFFECTIVE_DATE: 'Effective Date',
  BASIC_INFO_LABEL_EXPIRY_DATE: 'Expiry Date',
  BASIC_INFO_TOOLTIP_CATEGORY: 'Category tooltip',
  BASIC_INFO_TOOLTIP_SUBCATEGORY: 'Subcategory tooltip',
  BASIC_INFO_TOOLTIP_TYPE: 'Type tooltip',
  BASIC_INFO_TOOLTIP_EFFECTIVE_DATE: 'Effective date tooltip',
  BASIC_INFO_TOOLTIP_EXPIRY_DATE: 'Expiry date tooltip',
  BASIC_INFO_TEMPLATE_LABEL: 'Template',
  BASIC_INFO_COUNTRY_LABEL: 'Country',
  BASIC_INFO_CURRENCY_LABEL: 'Currency',
  BASIC_INFO_FX_SOURCES: 'FX Source',
  BASIC_INFO_FX_RATE_CUSTOM_DATE: 'Custom Date',
  BASIC_INFO_TEMPLATE_PLACEHOLDER: 'Select template',
  BASIC_INFO_COUNTRY_PLACEHOLDER: 'Select country',
  BASIC_INFO_CURRENCY_PLACEHOLDER: 'Select currency',
  BASIC_INFO_FX_SOURCES_PLACEHOLDER: 'Select FX Source',
  BASIC_INFO_FX_RATE_DATE_LABEL: 'FX Rate Date',
  BASIC_INFO_FX_RATE_DATE_PLACEHOLDER: 'Select FX Rate Date',
  BASIC_INFO_FX_RATE_CUSTOM_DATE_LABEL: 'Custom Date',
  BASIC_INFO_INCLUDE_IN_EMPLOYEE_VIEW_LABEL: 'Include in Employee View',
  BASIC_INFO_INCLUDE_IN_THE_OVERALL_TOTAL_LABEL: 'Include in the Overall Total',
  BASIC_INFO_INCLUDE_IN_EMPLOYEE_VIEW_TOOLTIP: 'Tooltip help for include in employee view',
  BASIC_INFO_IS_HIDDEN_LABEL: 'Hide this reward',
  BASIC_INFO_RUN_EVERY: 'Run This Reward',
  BASIC_INFO_RECURRENCE_ERROR: 'Required',
  BASIC_INFO_EFFECTIVE_DATE_BASEMENT_CAPTION:
    'Will become effective at the selected date, 00:00 [UTC]',
  BASIC_INFO_EXPIRY_DATE_BASEMENT_CAPTION: 'Will expire at the selected date, 23:59 [UTC]',
  BASIC_INFO_NOT_RECURRENT_LABEL: 'Automatically',
  BASIC_INFO_RECURRENCE_MONTHS: 'Month(s)',
  BASIC_INFO_RECURRENCE_YEARS: 'Year(s)',
  BASIC_INFO_EVERY_LABEL: 'Every',
  BASIC_INFO_RECURRENCE_COUNT_PLACEHOLDER: 'No.',
  BASIC_INFO_RECURRENCE_FREQUENCY_PLACEHOLDER: 'Frequency',
  BASIC_INFO_TAXABLE_REWARD: 'Taxable Reward',
  BASIC_INFO_TAXABILITY_PLACEHOLDER: 'Select mapping',
  BASIC_INFO_TAXABLE_TYPE_TAXABLE: 'Taxable',
  BASIC_INFO_TAXABLE_TYPE_NOT_TAXABLE: 'Not taxable',
  BASIC_INFO_TAXABLE_TYPE_MOBILITY: 'Assignment mapping',
  BASIC_INFO_TAXABILITY_ERROR: 'Required',
  BASIC_INFO_CONTRIBUTION_TYPE: 'Value Is',
  BASIC_INFO_CONTRIBUTION_TYPE_GROSS: 'Gross',
  BASIC_INFO_CONTRIBUTION_TYPE_NET: 'Net',
  BASIC_INFO_SIMPLE_TEMPLATE: 'Standard Reward',
  BASIC_INFO_SHARE_TEMPLATE: 'Share Reward',
  BASIC_INFO_PENSION_TEMPLATE: 'Pension Threshold Reward',
  BASIC_INFO_PERCENTAGE_TEMPLATE: 'Percentage Reward',
  BASIC_INFO_SETTINGS_YES: 'Yes',
  BASIC_INFO_SETTINGS_NO: 'No',
};
