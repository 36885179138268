import { handleActions } from 'redux-actions';
import * as R from 'ramda';
import {
  loadNotificationsPending,
  loadNotificationsSuccess,
  loadNotificationsError,
  notificationsStoreReset,
  toggleNotificationsWidget,
} from '../actions';
import { NOTIFICATION_TYPE } from '../constants';
import { filterNotifications } from './helpers';

const allowedNotificationTypes = R.values(NOTIFICATION_TYPE);

export const initialState = {
  count: null,
  list: [],
  isLoading: false,
  isWidgetOpen: false,
};

const reducer = handleActions(
  new Map([
    [
      loadNotificationsPending,
      () => ({
        isLoading: true,
      }),
    ],
    [
      loadNotificationsError,
      state => ({
        ...state,
        isLoading: false,
      }),
    ],
    [
      loadNotificationsSuccess,
      (_, action) => {
        const filteredNotifications = filterNotifications(
          R.path(['payload', 'list'], action),
          allowedNotificationTypes
        );
        const unreadCount = R.length(R.reject(R.propEq('isRead', true), filteredNotifications));
        return {
          isLoading: false,
          list: R.sort(R.descend(R.prop('createdDate')), filteredNotifications),
          count: unreadCount,
        };
      },
    ],
    [
      toggleNotificationsWidget,
      state => ({
        ...state,
        isWidgetOpen: !state.isWidgetOpen,
      }),
    ],
    [notificationsStoreReset, () => R.clone(initialState)],
  ]),
  initialState
);

export default reducer;
