import * as R from 'ramda';
import { isBlank } from '@trs/utils';
import { inRange } from '../../util';

const checkInstanceFieldsValid = fields =>
  R.keys(fields).reduce((final, key) => {
    const fieldValue = R.path([key, 'value'], fields);
    if (!isBlank(R.path([key, 'value'], fields)) && R.path([key, 'rangedValue'], fields)) {
      return inRange(fieldValue);
    }
    return !isBlank(fieldValue) && final;
  }, true);

export const validateModellerInstances = (instances, instanceFields) => {
  let isModellerValid = true;
  const transformedInstances = R.reduce((res, instanceKey) => {
    const fields = R.prop(instanceKey, instanceFields);
    const isInstanceValid = checkInstanceFieldsValid(fields);
    // false if any is false
    isModellerValid = isModellerValid && isInstanceValid && R.length(R.keys(fields)) > 0;
    return {
      ...res,
      [instanceKey]: {
        ...instances[instanceKey],
        isValid: isInstanceValid,
        showValid: true,
      },
    };
  }, {})(R.keys(instanceFields));

  return {
    instances: transformedInstances,
    isModellerValid,
  };
};

export default checkInstanceFieldsValid;
