"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.keyCellFormat = exports["default"] = void 0;
var _utils = require("@trs/utils");
var formatTableKeys = {
  NO_OF_MEMBERS: 'noOfMembers',
  VALUE: 'value',
  REWARD_VALUE: 'RewardValue'
};
var keyCellFormat = function keyCellFormat(tableKey, dataItem) {
  if (Object.values(formatTableKeys).indexOf(tableKey) === -1 || dataItem[tableKey].toString().search(/[a-zA-Z]+/g) !== -1) return dataItem[tableKey];
  var dataKey = typeof dataItem[tableKey] === 'number' ? dataItem[tableKey].toString() : dataItem[tableKey];
  var value = dataKey.replace('%', '');
  return dataKey.slice(-1) === '%' ? (0, _utils.formatNumber)(value).concat('%') : (0, _utils.formatNumber)(value);
};
exports.keyCellFormat = keyCellFormat;
var _default = {
  keyCellFormat: keyCellFormat
};
exports["default"] = _default;