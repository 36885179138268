import * as R from 'ramda';
import {
  CONFLICT_STATUS_CODE,
  FORBIDDEN_STATUS_CODE,
  TOO_MANY_REQUESTS_STATUS_CODE,
} from '@trs/config';
import { getErrorMessage, getErrorStatus } from '@trs/utils';
import { showBanner } from 'actions/globalActions';
import { ERROR_CODES } from '../constants';

const dispatchError = (error, dispatch, state) => {
  const status = getErrorStatus(error);
  let errorMessage = getErrorMessage(error);

  if (state) {
    switch (status) {
      case CONFLICT_STATUS_CODE: {
        errorMessage = R.path(['cms', 'generic', 'data', 'CONFLICT_BANNER'], state);
        break;
      }

      case FORBIDDEN_STATUS_CODE: {
        const cmsErrorKey = R.path(['response', 'data', 'errors', 0], error);

        errorMessage = R.path(['cms', 'ltiPlans', 'data', cmsErrorKey], state);
        break;
      }

      case TOO_MANY_REQUESTS_STATUS_CODE: {
        errorMessage = R.path(['cms', 'generic', 'data', 'TRY_LATER_GENERIC'], state);
        break;
      }

      default:
        break;
    }
  }

  if (R.has(errorMessage, ERROR_CODES)) {
    errorMessage = R.path(['cms', 'ltiPlans', 'data', errorMessage], state);
  }

  dispatch(
    showBanner({
      type: 'error',
      content: errorMessage,
    })
  );
};

export default dispatchError;
