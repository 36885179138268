import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SanitizedHTML from 'react-sanitized';
import * as R from 'ramda';
import * as Utils from '@trs/utils';
import { CONFIG } from '../../config/api';

const { isBlank } = Utils;
const withHtml = (label = '') => <SanitizedHTML html={label} />;

const filterNonUtils = R.reduce(
  (acc, transf) => (Utils[transf] ? acc.concat(Utils[transf]) : acc),
  []
);

const getTransformsFromProps = (props) => {
  const transforms = filterNonUtils(R.keys(props));
  if (R.contains('transform', R.keys(props))) {
    transforms.push(R.prop('transform', props));
  }

  return transforms;
};

const getStore = () =>
  R.path(['GLOBAL_STORE', 'getState'], window) ? window.GLOBAL_STORE.getState() : {};
const store = getStore();

const getPlatformName = () => R.prop('platformName', CONFIG);

const withPlatform = (label = '') => label.replace(/%PLATFORM%/g, getPlatformName());

const applyTransforms = (props, label) => {
  const filteredTransforms = getTransformsFromProps(props);
  return R.compose(
    props.withHtml ? withHtml : R.identity,
    withPlatform,
    ...filteredTransforms // Utils transforms
  )(label);
};

/*
This should be rarely used(input placeholder for ex.)
*/

export const TextOnly = ({ path, ...other }) => {
  const label = R.path(['cms', ...R.insert(1, 'data', path.trim().split('.'))], getStore());
  if (isBlank(label)) return '';
  return applyTransforms(other, label);
};

/*
  <Text path="generic.WHATEVER" toUpper camelcase transform={(label)=>label.replace('%something%', 'something new')} />
*/
class Text extends PureComponent {
  render() {
    const { path, cms, text, ...other } = this.props;
    const label = text || R.path(R.insert(1, 'data', path.trim().split('.')), cms);

    if (isBlank(label)) return '';
    return applyTransforms(other, label);
  }
}

Text.propTypes = {
  path: PropTypes.string,
  text: PropTypes.string,
  cms: PropTypes.shape({}).isRequired,
};

Text.defaultProps = {
  path: '',
  text: '',
};

const mapStateToProps = (state) => ({
  cms: state.cms,
});

export default connect(mapStateToProps, { context: store })(Text);
