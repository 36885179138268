export default {
  PENSION_GENERIC_LABEL: 'Reward',
  PENSION_PENSIONABLE_SALARY_LABEL: 'Pensionable Salary',
  PENSION_PENSIONABLE_SALARY_DESCRIPTION: 'The Pensionable Salary defined above',
  PENSION_THRESHOLD_LABEL: 'Threshold',
  PENSION_THRESHOLD_ADD_LABEL: 'Add Threshold',
  PENSION_THRESHOLD_DUPLICATE_LABEL: 'Duplicate Threshold',
  PENSION_THRESHOLD_DELETE_LABEL: 'Delete Threshold',
  PENSION_THRESHOLD_PLACEHOLDER: 'Please first define the Pensionable Salary',
  PENSION_PENSIONABLE_SALARY_BLOCK_PLACEHOLDER: 'Please define the %Pensionable Salary%.',
  PENSION_PENSIONABLE_SALARY_IF_LABEL: 'If',
  PENSION_PENSIONABLE_SALARY_AND_LABEL: 'And',
  PENSION_PENSIONABLE_SALARY_THEN_LABEL: 'Then',
  PENSION_PENSIONABLE_SALARY_CONTRIBUTION_LABEL: 'Contribution is',
  PENSION_PENSIONABLE_SALARY_CONTRIBUTION_PLACEHOLDER: 'Contribution Value',
  PENSION_BASEMENT_CAPTION: `* Please take into consideration that Between function is inclusive 
    (will look for values greater than or equal and less than or equal).`,
  PENSION_CONTRIBUTION_OF_PENSIONABLE: 'of Pensionable Salary',
};
