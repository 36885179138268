import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import StyledLogo from './Logo.style';
import Text from '../../common/Text';

const Logo = ({ landingPage, logo: { isLoading, path } }) => (
  <StyledLogo isLoading={isLoading}>
    <Link to={landingPage === null ? '/' : landingPage}>
      <div className="logo-container">
        {!isLoading && <img src={path} alt="Logo" className="logo" />}
        <span>
          <Text path="generic.LOGO" />
        </span>
      </div>
    </Link>
  </StyledLogo>
);

Logo.defaultProps = {
  landingPage: '/',
};

Logo.propTypes = {
  landingPage: PropTypes.string,
  logo: PropTypes.shape({
    isLoading: PropTypes.bool,
    path: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = ({ cms, user }) => ({
  landingPage: user.landingPage,
  logo: cms.logo,
});

export default connect(mapStateToProps)(Logo);
