import { handleActions } from 'redux-actions';
import * as R from 'ramda';
import { v4 as uuid } from 'uuid';
import {
  calculateOverallImpactPending,
  calculateOverallImpactSuccess,
  calculateOverallImpactError,
  calculateOverallImpactUnauthorized,
  overallImpactStoreReset,
  loadOverallImpactRules,
  overallImpactRuleChange,
  overallImpactAddRule,
  overallImpactDuplicateRule,
  overallImpactDeleteRule,
  overallImpactDeleteRuleFormulas,
} from '../actions';
import { getLocalOverride, getNewRule } from '../../../reducer';
import { ACTIVE_TYPES } from '../../../constants';

export const initialState = {
  overallImpact: {
    summary: {
      employeesAffected: 0,
      totalEmployees: 0,
    },
    calculated: false,
  },
  rules: [],
  calculatingOverallImpact: false,
  calculatedOverallImpact: false,
  error: false,
};

const reducer = handleActions(
  new Map([
    [
      calculateOverallImpactPending,
      (state) => ({
        ...state,
        calculatingOverallImpact: true,
        calculatedOverallImpact: false,
      }),
    ],
    [
      calculateOverallImpactError,
      (state) => ({
        ...state,
        error: true,
        calculatingOverallImpact: false,
        calculatedOverallImpact: false,
      }),
    ],
    [
      calculateOverallImpactUnauthorized,
      (state) => ({
        ...state,
        error: false, // don't show the error message
        calculatingOverallImpact: false,
        calculatedOverallImpact: false,
      }),
    ],
    [
      calculateOverallImpactSuccess,
      (state, action) => ({
        ...state,
        calculatingOverallImpact: false,
        overallImpact: R.path(['payload', 'impact'], action),
        calculatedOverallImpact: true,
      }),
    ],
    [
      loadOverallImpactRules,
      (state, action) => ({
        ...state,
        error: !!action.error,
        ...action.payload,
      }),
    ],
    [overallImpactStoreReset, () => R.clone(initialState)],
    [
      overallImpactRuleChange,
      (state, action) => {
        const rules = R.clone(state.rules);
        rules[action.index] = {
          ...rules[action.index],
          [action.field]: action.value,
          ...action.other,
        };
        return {
          ...state,
          rules,
        };
      },
    ],
    [
      overallImpactAddRule,
      (state, action) => {
        const rules = R.clone(state.rules);
        const localOverride = getLocalOverride(
          R.path(['basicInformation', 'template'], state),
          R.prop('countries', state)
        );
        const newRule = getNewRule({ localOverride });

        return {
          ...state,
          rules: R.insert(action.index + 1, newRule, rules),
        };
      },
    ],
    [
      overallImpactDuplicateRule,
      (state, action) => {
        const rules = R.clone(state.rules);

        const newRule = {
          ...action.ruleBlock,
          localOverride: getLocalOverride(
            R.path(['basicInformation', 'template'], state),
            R.prop('countries', state)
          ),
        };

        return {
          ...state,
          rules: R.insert(action.index + 1, { ...newRule, key: uuid() }, rules),
        };
      },
    ],
    [
      overallImpactDeleteRule,
      (state, action) => {
        const { rules } = { ...state };

        return {
          ...state,
          rules: R.remove(action.index, 1, rules),
        };
      },
    ],
    [
      overallImpactDeleteRuleFormulas,
      (state, action) => {
        const rules = R.clone(state.rules);
        rules[action.index] = {
          ...rules[action.index],
          formula: [],
          threshold: [],
          complex: {
            activeType: ACTIVE_TYPES.fixed,
            [ACTIVE_TYPES.fixed]: {
              contribution: [],
              minimum: [],
              maximum: [],
            },
            [ACTIVE_TYPES.percentage]: {
              contribution: [],
              additionalFormulaItems: [],
              minimum: [],
              maximum: [],
            },
          },
          isStepper: false,
          stepper: {
            activeType: ACTIVE_TYPES.fixed,
            [ACTIVE_TYPES.fixed]: {
              steps: [],
            },
            [ACTIVE_TYPES.percentage]: {
              steps: [],
            },
          },
        };

        return {
          ...state,
          rules,
        };
      },
    ],
  ]),
  initialState
);

export default reducer;
