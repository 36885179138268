import actionTypes from '../actions/types';

export const initialState = {
  currencies: [],
  isFetchingCurrencies: false,
  currency: '',
  isFetchingDefaultCurrency: false,
  savedFxSource: '',
  totalFXSources: 0,
  isFetchingfxSources: false,
  fxSourceName: '',
  file: null,
};

export default function currencyReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_DEFAULT_CURRENCY_PENDING: {
      return {
        ...state,
        isFetchingDefaultCurrency: true,
      };
    }
    case actionTypes.FETCH_DEFAULT_CURRENCY_SUCCESS: {
      return {
        ...state,
        currency: action.payload.response,
        isFetchingDefaultCurrency: false,
      };
    }
    case actionTypes.FETCH_CURRENCIES_PENDING: {
      return {
        ...state,
        isFetchingCurrencies: true,
      };
    }
    case actionTypes.FETCH_CURRENCIES_SUCCESS: {
      const data = action.payload;
      const currencies = [];

      data.map(item =>
        currencies.push({
          value: item.code,
          label: item.code,
        })
      );

      return {
        ...state,
        currencies,
        isFetchingCurrencies: false,
      };
    }
    case actionTypes.FETCH_FXSOURCES_PENDING: {
      return {
        ...state,
        isFetchingfxSources: true,
      };
    }
    case actionTypes.FETCH_FXSOURCES_SUCCESS: {
      const data = action.payload;
      let savedFxSource = '';

      if (data.length === 1) {
        savedFxSource = data[0].name;
      }

      return {
        ...state,
        savedFxSource,
        totalFXSources: data.length,
        isFetchingfxSources: false,
      };
    }
    default: {
      return state;
    }
  }
}
