"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "BlockSubtitle", {
  enumerable: true,
  get: function get() {
    return _BlockSubtitle["default"];
  }
});
Object.defineProperty(exports, "PageSubtitle", {
  enumerable: true,
  get: function get() {
    return _PageSubtitle["default"];
  }
});
Object.defineProperty(exports, "PageTitleInsideTab", {
  enumerable: true,
  get: function get() {
    return _PageTitleInsideTab["default"];
  }
});
Object.defineProperty(exports, "TitleWithButton", {
  enumerable: true,
  get: function get() {
    return _TitleWithButton["default"];
  }
});
Object.defineProperty(exports, "default", {
  enumerable: true,
  get: function get() {
    return _PageTitle["default"];
  }
});
var _PageTitle = _interopRequireDefault(require("./PageTitle"));
var _PageTitleInsideTab = _interopRequireDefault(require("./PageTitleInsideTab"));
var _PageSubtitle = _interopRequireDefault(require("./PageSubtitle"));
var _BlockSubtitle = _interopRequireDefault(require("./BlockSubtitle"));
var _TitleWithButton = _interopRequireDefault(require("./TitleWithButton"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }