import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { resetCache, cache } from 'modules/common/timedCache';
import * as userActions from '../../../actions/userActions';

class Logout extends React.PureComponent {
  render() {
    const { user } = this.props;
    if (user.isLoggedIn) {
      resetCache(cache);
      this.props.actions.logoutUser();
      return null;
    }
    return <Redirect push to="/login" />;
  }
}

Logout.defaultProps = {
  user: {},
};

Logout.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  user: PropTypes.shape({
    isLoggedIn: PropTypes.bool,
  }),
};

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...userActions }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
