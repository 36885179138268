import { v4 as uuid } from 'uuid';
import { FILTER_TYPES } from '../routes/utils';

const initialState = {
  country: '',
  title: '',
  message: '',
  category: null,
  isFutureStatementOnly: false,
  from: '',
  to: '',
  etag: '',
  id: '',
  categories: [],
  fetchingById: false,
  rules: [
    {
      key: uuid(),
      eligibility: {},
      formula: [],
      excessFormula: null,
      minimumUsageFormula: null,
      budgetLabel: null,
      remainingBudgetLabel: null,
      threshold: [],
    },
  ],
  populationRules: [
    {
      key: uuid(),
      eligibility: {},
    },
  ],
  packageType: {
    type: FILTER_TYPES.MODELLING,
    date: '',
  },
  lookUpValues: {},
  eligibilityOperators: [],
  systemVariables: [],
  eligibilityRuleLabels: {},
  formulaOperators: [],
  budgetTitle: '',
  flexPackages: {
    globalFlexPackages: [],
    nationalFlexPackages: [],
  },
  countries: [],
  enrollmentRewards: {
    list: [],
    filterOptions: [],
    totalResults: 0,
    fetchingList: false,
  },
  allRewards: {
    list: [],
    filterOptions: [],
    totalResults: 0,
    fetchingList: false,
  },
  rewardsSelection: {},
  isLoadingPackages: true,
  isLoadingSelection: true,
  isFetchingCategories: true,
  isDeactivatingPackage: false,
  includeDisclaimer: false,
  includeEmail: false,
  includeAgreement: false,
};

export default initialState;
