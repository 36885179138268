export const REQUEST_SETTINGS_MODULES = 'REQUEST_SETTINGS_MODULES';
export const GET_SETTINGS_MODULES = 'GET_SETTINGS_MODULES';
export const EMPLOYEE_PERSONAL_DETAILS_PENDING = 'EMPLOYEE_PERSONAL_DETAILS_PENDING';
export const EMPLOYEE_PERSONAL_DETAILS = 'EMPLOYEE_PERSONAL_DETAILS';
export const GET_PROFILE_DATA = 'GET_PROFILE_DATA';
export const UPDATE_EMPLOYEE_PERSONAL_DETAILS = 'UPDATE_EMPLOYEE_PERSONAL_DETAILS';
export const LOAD_EMPLOYEE_DETAILS_SUCCESS = 'LOAD_EMPLOYEE_DETAILS_SUCCESS';
export const PERSONAL_DETAIL_VALUE_CHANGE = 'PERSONAL_DETAIL_VALUE_CHANGE';
export const PERSONAL_DETAIL_CHANGE = 'PERSONAL_DETAIL_CHANGE';
export const PERSONAL_DETAIL_DELETE = 'PERSONAL_DETAIL_DELETE';
export const VESTED_TOGGLE_VALUE = 'VESTED_TOGGLE_VALUE';
export const UNVESTED_TOGGLE_VALUE = 'UNVESTED_TOGGLE_VALUE';
export const GET_SHARES_DATA = 'GET_SHARES_DATA';
export const GET_SHARES_DATA_SUCCESS = 'GET_SHARES_DATA_SUCCESS';
export const GET_SHARES_DATA_PENDING = 'GET_SHARES_DATA_PENDING';
export const GET_SHARES_DATA_ERROR = 'GET_SHARES_DATA_ERROR';
