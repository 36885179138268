export const RECURRENCE_OPTIONS_TYPES = {
  MONTHS: 'months',
  YEARS: 'years',
};

export const RECURRENCE_OPTIONS = { months: 1, years: 2 };

export const DEFAULT_PILL_SIZE = 16;

export default RECURRENCE_OPTIONS;
