export default {
  FORMULA_INPUT_PLACEHOLDER: 'Type variable or number',
  FORMULA_INPUT_DECIMALS_PLACEHOLDER: 'No. of decimals',
  FORMULA_SECTION_HEADLINE: 'Enter an expression',
  FORMULA_FUNCTION_GROUP_LABEL: 'Functions',
  FORMULA_BRACKET_GROUP_LABEL: 'Groups',
  FORMULA_MAX_NESTING_LABEL: 'You have reached the maximum amount of nested functions',
  FORMULA_BLOCK_PLACEHOLDER:
    'Start by inserting a %value% or a function (%min%, %max%, %round%, %round up%, %round down%) or a %text-only reward% field.',
  FORMULA_SIMPLE_PLACEHOLDER:
    'Start by inserting a %value% or a function (%min%, %max%, %round%, %round up%, %round down%) or a %text-only reward% field.',
  FORMULA_GENERIC_LABEL: 'Reward',
  FORMULA_MAX_FUNCTION_LABEL: 'Max',
  FORMULA_MIN_FUNCTION_LABEL: 'Min',
  FORMULA_ROUND_FUNCTION_LABEL: 'Round',
  FORMULA_ROUND_UP_FUNCTION_LABEL: 'Round Up',
  FORMULA_ROUND_DOWN_FUNCTION_LABEL: 'Round Down',
  FORMULA_BETWEEN_FUNCTION_LABEL: 'Between',
  FORMULA_BETWEEN_FUNCTION_HEADER_LABEL: 'Is Between',
  FORMULA_CONTRIBUTION_RANGE_MINIMUM_AND_MAXIMUM_PLACEHOLDER:
    'Add %minimum or maximum% contribution, if needed.',
  FORMULA_REWARD_VALUE_RANGE_MINIMUM_AND_MAXIMUM_PLACEHOLDER:
    'To add adjustable limits to flexible reward choices, you can add %minimum and maximum% values',
  FORMULA_RANGE_MINIMUM: 'minimum',
  FORMULA_RANGE_MAXIMUM: 'maximum',
};
