"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var menuListItems = function menuListItems(_ref) {
  var labels = _ref.labels,
    actions = _ref.actions,
    ruleIndex = _ref.ruleIndex,
    setHasChanges = _ref.setHasChanges;
  return [{
    name: labels.BUTTON_DELETE,
    icon: 'ion-trash-b',
    onClick: function onClick() {
      actions.deleteRuleRange(ruleIndex);
      setHasChanges(true);
    }
  }];
};
var _default = menuListItems;
exports["default"] = _default;