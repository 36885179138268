const initialState = {
  1: {
    fieldsStructure: {},
    payScale: {
      minAnnualSalary: 0,
      midAnnualSalary: 0,
      maxAnnualSalary: 0,
    },
    defaultCurrency: '',
    snapshot: {},
    err: null,
    isValid: false,
    showValid: false,
    employeeId: '',
    instantiated: false,
    areAdvancedFieldsInView: false,
    isLoadingInstance: false,
    countryGradeProfileMappings: {},
  },
};

export default initialState;
