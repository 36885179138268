import React from 'react';
import styled, { css } from 'styled-components';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { FormattedNumber } from '@trs/components';
import { MAX_DECIMALS } from '@trs/config';

const StyledTick = styled.div`
  ${props => {
    const { spacing } = props.theme;
    const { tick } = props;

    return css`
      position: absolute;
      margin-top: ${spacing.xSmall};
      font-size: 13px;
      text-align: center;
      ${tick.percent === 100
        ? css`
            right: 0%;
          `
        : css`
            left: ${tick.percent}%;
          `}
    `;
  }};
`;

StyledTick.displayName = 'StyledTick';

const Tick = ({ tick, isPercentage }) => {
  return (
    <StyledTick tick={tick}>
      <FormattedNumber
        value={R.prop('value', tick)}
        isPercentage={isPercentage}
        decimals={MAX_DECIMALS}
      />
    </StyledTick>
  );
};

Tick.propTypes = {
  isPercentage: PropTypes.bool.isRequired,
  tick: PropTypes.shape({
    percent: PropTypes.number,
  }).isRequired,
};

Tick.defaultProps = {};

export default Tick;
