import * as R from 'ramda';
import { createAction } from 'redux-actions';
import { POST, PUT, PATCH, GET } from '../../../../../../../../../config/http';
import { benchmark } from '../../../../../../../../../config/api';
import { showBanner } from '../../../../../../../../../actions/globalActions';
import { TextOnly } from '../../../../../../../../common/Text';
import { loadLocalBenchmarkingStatuses } from '../../../../../actions';
import { getLocalSettingsId } from '../../../../../actions/helpers';
import { etagUpdate } from '../../../actions/helpers';
import { validateDataForSubmit } from '../../../actions';
import { dispatchError } from '../../../../../../common/helpers';

const actionTypes = {
  BENCHMARKING_MARKET_DATA_FILE_SUCCESS: 'BENCHMARKING_MARKET_DATA_FILE_SUCCESS',
  BENCHMARKING_MARKET_DATA_FILE_PENDING: 'BENCHMARKING_MARKET_DATA_FILE_PENDING',
  BENCHMARKING_MARKET_DATA_FILE_ERROR: 'BENCHMARKING_MARKET_DATA_FILE_ERROR',
  BENCHMARKING_AGE_DATA_PENDING: 'BENCHMARKING_AGE_DATA_PENDING',
  BENCHMARKING_AGE_DATA_SUCCESS: 'BENCHMARKING_AGE_DATA_SUCCESS',
  BENCHMARKING_AGE_DATA_ERROR: 'BENCHMARKING_AGE_DATA_ERROR',
  BENCHMARKING_UPDATE_REFERENCE_DATE: 'BENCHMARKING_UPDATE_REFERENCE_DATE',
  BENCKMARKING_TOGGLE_MANUAL_LTI: 'BENCKMARKING_TOGGLE_MANUAL_LTI',
  BENCKMARKING_TOGGLE_MANUAL_BENEFITS: 'BENCKMARKING_TOGGLE_MANUAL_BENEFITS',
  BENCKMARKING_TOGGLE_FIXED_ALLOWANCES: 'BENCKMARKING_TOGGLE_FIXED_ALLOWANCES',
  BENCKMARKING_UPDATE_MARKET_DATA: 'BENCKMARKING_UPDATE_MARKET_DATA',
  BENCKMARKING_SAVE_MARKET_DATA: 'BENCKMARKING_SAVE_MARKET_DATA',
  BENCHMARKING_SAVE_LAST_YEAR_DATA: 'BENCHMARKING_SAVE_LAST_YEAR_DATA',
  BENCHMARKING_GET_INPUT_DATA: 'BENCHMARKING/GET/INPUT_DATA',
};

export const uploadFilePending = createAction(actionTypes.BENCHMARKING_MARKET_DATA_FILE_PENDING);
export const uploadFileSuccess = createAction(actionTypes.BENCHMARKING_MARKET_DATA_FILE_SUCCESS);
export const uploadFileError = createAction(actionTypes.BENCHMARKING_MARKET_DATA_FILE_ERROR);
export const ageDataPending = createAction(actionTypes.BENCHMARKING_AGE_DATA_PENDING);
export const ageDataSuccess = createAction(actionTypes.BENCHMARKING_AGE_DATA_SUCCESS);
export const ageDataError = createAction(actionTypes.BENCHMARKING_AGE_DATA_ERROR);
export const updateSurveyReferenceDate = createAction(
  actionTypes.BENCHMARKING_UPDATE_REFERENCE_DATE
);
export const toggleManualLTI = createAction(actionTypes.BENCKMARKING_TOGGLE_MANUAL_LTI);
export const toggleManualBenefits = createAction(actionTypes.BENCKMARKING_TOGGLE_MANUAL_BENEFITS);
export const toggleFixedAllowances = createAction(actionTypes.BENCKMARKING_TOGGLE_FIXED_ALLOWANCES);
export const updateMarketData = createAction(actionTypes.BENCKMARKING_UPDATE_MARKET_DATA);
export const saveMarketDataSuccess = createAction(actionTypes.BENCKMARKING_SAVE_MARKET_DATA);
export const uploadLastYearMarketDataSuccess = createAction(
  actionTypes.BENCHMARKING_SAVE_LAST_YEAR_DATA
);

export const getLastYearInputData = createAction(actionTypes.BENCHMARKING_GET_INPUT_DATA);

export const uploadMarketData = payload => (dispatch, getState) => {
  const { benchmarkingId, localId } = R.path(
    ['benchmarking', 'local', 'localPreparation'],
    getState()
  );
  dispatch(uploadFilePending());

  return PUT(
    `${benchmark.benchmarking}/${benchmarkingId}/localPreparations/${localId}/marketData`,
    payload
  )
    .then(data => {
      dispatch(uploadFileSuccess(R.path(['data', 'response'], data)));
      dispatch(
        showBanner({
          type: 'success',
          content: TextOnly({ path: 'benchmarking.MARKET_INDICATORS_SAVE_SUCCESS' }),
        })
      );
    })
    .catch(error => {
      dispatch(uploadFileError());
      dispatchError(error, dispatch);
    });
};

export const loadLastYearMarketData = () => (dispatch, getState) => {
  const state = getState();
  const globalId = R.path(['benchmarking', 'global', 'main', 'identifiers', 'id'], state);
  const localId = R.path(['benchmarking', 'local', 'main', 'statusData', 'id'], state);

  const uri = `${
    benchmark.base
  }/benchmarking/${globalId}/localSettings/${localId}/previous/input-market-data`;

  return dispatch(
    getLastYearInputData(
      GET(uri)
        .then(body => {
          return {
            data: R.path(['data', 'response', 'data'], body),
            canUploadForCurrentYear: R.path(['data', 'response', 'canUploadForCurrentYear'], body),
          };
        })
        .catch(error => {
          dispatchError(error, dispatch);
          return Promise.reject(error);
        })
    )
  );
};

export const uploadLastYearMarketData = payload => (dispatch, getState) => {
  const { benchmarkingId, localId } = R.path(
    ['benchmarking', 'local', 'localPreparation'],
    getState()
  );
  dispatch(uploadFilePending());

  return PUT(
    `${benchmark.benchmarking}/${benchmarkingId}/localPreparations/${localId}/marketData/last-year`,
    payload
  )
    .then(responseBody => {
      dispatch(etagUpdate(R.path(['data', 'response', 'etag'], responseBody)));
      dispatch(
        uploadLastYearMarketDataSuccess(R.path(['data', 'response', 'marketData'], responseBody))
      );
      dispatch(loadLastYearMarketData());
      dispatch(
        showBanner({
          type: 'success',
          content: TextOnly({ path: 'benchmarking.MARKET_INDICATORS_SAVE_SUCCESS' }),
        })
      );
    })
    .catch(error => {
      dispatch(uploadFileError());
      dispatchError(error, dispatch);
    });
};
export const ageData = includeFixedAllowances => (dispatch, getState) => {
  const { benchmarkingId, localId } = R.path(
    ['benchmarking', 'local', 'localPreparation'],
    getState()
  );
  const localSettingsId = getLocalSettingsId(getState());
  const payload = {
    includeFixedAllowances,
  };

  dispatch(ageDataPending());
  return POST(
    `${benchmark.benchmarking}/${benchmarkingId}/localPreparations/${localId}/marketData/aged`,
    payload
  )
    .then(response => {
      dispatch(etagUpdate(R.path(['data', 'response', 'etag'], response)));
      dispatch(loadLocalBenchmarkingStatuses(localSettingsId));
      dispatch(ageDataSuccess(R.path(['data', 'response', 'marketData', 'agedData'], response)));
      dispatch(validateDataForSubmit(R.path(['data', 'response', 'isDataValid'], response)));
    })
    .catch(error => {
      dispatch(ageDataError());
      dispatchError(error, dispatch);
    });
};

export const saveMarketData = payload => (dispatch, getState) => {
  const { benchmarkingId, localId, etag } = R.path(
    ['benchmarking', 'local', 'localPreparation'],
    getState()
  );
  const localSettingsId = getLocalSettingsId(getState());

  return PATCH(
    `${benchmark.benchmarking}/${benchmarkingId}/localPreparations/${localId}`,
    payload,
    { ETag: etag }
  )
    .then(response => {
      const responseBody = R.path(['data', 'response'], response);

      dispatch(etagUpdate(R.prop('etag', responseBody)));
      dispatch(loadLocalBenchmarkingStatuses(localSettingsId));
      dispatch(ageDataSuccess(R.path(['marketData', 'agedData'], responseBody)));
      dispatch(saveMarketDataSuccess(R.prop('marketData', responseBody)));
      dispatch(validateDataForSubmit(R.prop('isDataValid', responseBody)));
      dispatch(
        showBanner({
          type: 'success',
          content: TextOnly({ path: 'benchmarking.MARKET_DATA_SAVE_SUCCESS' }),
        })
      );
    })
    .catch(error => {
      dispatchError(error, dispatch);
      return Promise.reject(error);
    });
};
