import React from 'react';
import { v4 as uuid } from 'uuid';
import * as R from 'ramda';
import APP_CONFIG from '@trs/config';
import { Chip } from '@trs/components';
import { toLower, toUpper } from '@trs/utils';
import Text, { TextOnly } from '../../common/Text';

const { REWARD_STATUSES } = APP_CONFIG;

const chipStatus = {
  draft: 'default',
  published: 'primary',
  live: 'success',
  expired: 'warning',
};

export const FILTER_TYPES = {
  ALL: 'all',
  MODELLING: 'modelling',
  SUBMISSION: 'submission',
};

export const BASE_FLEX_URL = '/flexible-rewards';
export const ROUTING_MAP = [
  'basic-information',
  'budget-builder',
  'rewards-selection',
  'eligible-population',
  'email-and-disclaimer',
];

export const getTopBannerData = (basicInfo, labels) => {
  const { status } = basicInfo;
  const displayedKeys = {
    country: labels.PACKAGE_DETAILS_COUNTRY,
    enrolmentWindow: labels.ENROLLMENT_WINDOW,
  };
  const isDraft = status === toLower(REWARD_STATUSES.draft);
  const data = [];

  if (status) {
    data.push(
      <Chip key={uuid()} className="status-chip" type={chipStatus[status]} outlined={isDraft}>
        <Text path={`flexibleRewards.STATUS_${toUpper(status)}`} />
      </Chip>
    );
  }
  Object.keys(displayedKeys).map(
    (key) =>
      basicInfo[key] &&
      data.push(
        <p key={uuid()}>
          <span>{displayedKeys[key]}</span>
          <strong>{basicInfo[key]}</strong>
        </p>
      )
  );
  return data;
};

export const validateTabs = (form) => {
  const hasPackageId = !!R.prop('id', form);
  const selectedRewards = R.length(R.keys(R.prop('rewardsSelection', form)));

  return {
    isBasicInfoValid: hasPackageId,
    isRewardsSelectionValid: selectedRewards > 0,
  };
};

export const getUnfilledTabs = (form) => {
  const unfilledTabs = [];
  const isSubmissionPackage =
    toLower(R.path(['packageType', 'type'], form)) === FILTER_TYPES.SUBMISSION;
  const isBudgetFilled = R.prop('includeBudget', form);
  const isRewardsFilled = R.length(R.keys(R.prop('rewardsSelection', form))) > 0;
  const isPopulationFilled = !R.isEmpty(R.path(['populationRules', 0, 'eligibility'], form));
  const isDisclaimerFilled = R.prop('includeDisclaimer', form);
  const isEmailFilled = R.prop('includeEmail', form);

  if (!isBudgetFilled) {
    unfilledTabs.push(TextOnly({ path: 'flexibleRewards.BUDGET_BUILDER' }));
  }
  if (!isRewardsFilled) {
    unfilledTabs.push(TextOnly({ path: 'flexibleRewards.REWARDS_SELECTION' }));
  }
  if (!isPopulationFilled) {
    unfilledTabs.push(TextOnly({ path: 'flexibleRewards.ELIGIBLE_POPULATION' }));
  }
  if (isSubmissionPackage && !isDisclaimerFilled && !isEmailFilled) {
    unfilledTabs.push(TextOnly({ path: 'flexibleRewards.EMAIL_AND_DISCLAIMER' }));
  }
  if (!isSubmissionPackage && !isDisclaimerFilled) {
    unfilledTabs.push(TextOnly({ path: 'flexibleRewards.DISCLAIMER' }));
  }

  return unfilledTabs;
};
