"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sharePlaceholdersTypeMap = exports.roundFunctionTypes = exports.placeholdersTypeMap = exports.extendedPlaceholdersTypeMap = exports.NUMBER_OF_DECIMALS_MAX_LENGTH = exports.FUNCTION_MEMBER_TYPES = exports.FORMULA_MEMBER_TYPES = exports.EMPTY_ITEM = exports.DEFAULT_FUNCTION_ARGUMENT = exports.DATA_TYPES = void 0;
var _uuid = require("uuid");
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
var NUMBER_OF_DECIMALS_MAX_LENGTH = 3;
exports.NUMBER_OF_DECIMALS_MAX_LENGTH = NUMBER_OF_DECIMALS_MAX_LENGTH;
var FUNCTION_MEMBER_TYPES = {
  MIN: 'Min',
  MAX: 'Max',
  ROUND: 'Round',
  ROUND_UP: 'RoundUp',
  ROUND_DOWN: 'RoundDown',
  LEFT: 'left',
  RIGHT: 'right'
};
exports.FUNCTION_MEMBER_TYPES = FUNCTION_MEMBER_TYPES;
var FORMULA_MEMBER_TYPES = {
  textOnly: 'text-only',
  variable: 'variable',
  numeric: 'numeric',
  stepper: 'stepper',
  percentage: 'percentage',
  empty: '',
  operator: 'operator',
  functionMember: 'function',
  parenthesis: 'parenthesis'
};
exports.FORMULA_MEMBER_TYPES = FORMULA_MEMBER_TYPES;
var DATA_TYPES = {
  MIXED: 'MIXED',
  NUMERIC: 'NUMERIC',
  UNKNOWN: 'UNKNOWN'
};
exports.DATA_TYPES = DATA_TYPES;
var EMPTY_ITEM = {
  type: FORMULA_MEMBER_TYPES.empty,
  dataType: DATA_TYPES.MIXED,
  value: '',
  displayName: '',
  description: ''
};
exports.EMPTY_ITEM = EMPTY_ITEM;
var DEFAULT_FUNCTION_ARGUMENT = _objectSpread(_objectSpread({}, EMPTY_ITEM), {}, {
  type: FORMULA_MEMBER_TYPES.variable,
  dataType: DATA_TYPES.MIXED
});
exports.DEFAULT_FUNCTION_ARGUMENT = DEFAULT_FUNCTION_ARGUMENT;
var roundFunctionTypes = [FUNCTION_MEMBER_TYPES.ROUND, FUNCTION_MEMBER_TYPES.ROUND_UP, FUNCTION_MEMBER_TYPES.ROUND_DOWN];
exports.roundFunctionTypes = roundFunctionTypes;
var defaultRoundFunctionArguments = [[_objectSpread({
  key: (0, _uuid.v4)()
}, EMPTY_ITEM)], [_objectSpread({
  key: (0, _uuid.v4)()
}, _objectSpread(_objectSpread({}, EMPTY_ITEM), {}, {
  type: FORMULA_MEMBER_TYPES.numeric,
  dataType: DATA_TYPES.NUMERIC
}))]];
var placeholdersTypeMap = {
  1: _objectSpread({}, EMPTY_ITEM),
  3: {
    type: FORMULA_MEMBER_TYPES.functionMember,
    value: FUNCTION_MEMBER_TYPES.MIN,
    arguments: [[_objectSpread({
      key: (0, _uuid.v4)()
    }, EMPTY_ITEM)], [_objectSpread({
      key: (0, _uuid.v4)()
    }, EMPTY_ITEM)]],
    displayName: '',
    description: ''
  },
  5: {
    type: FORMULA_MEMBER_TYPES.functionMember,
    value: FUNCTION_MEMBER_TYPES.MAX,
    arguments: [[_objectSpread({
      key: (0, _uuid.v4)()
    }, EMPTY_ITEM)], [_objectSpread({
      key: (0, _uuid.v4)()
    }, EMPTY_ITEM)]],
    displayName: '',
    description: ''
  },
  7: {
    type: FORMULA_MEMBER_TYPES.textOnly,
    value: '',
    displayName: '',
    description: ''
  },
  9: {
    type: FORMULA_MEMBER_TYPES.stepper,
    value: '',
    displayName: '',
    description: ''
  }
};
exports.placeholdersTypeMap = placeholdersTypeMap;
var extendedPlaceholdersTypeMap = {
  1: placeholdersTypeMap[1],
  3: placeholdersTypeMap[3],
  5: placeholdersTypeMap[5],
  7: {
    type: FORMULA_MEMBER_TYPES.functionMember,
    value: FUNCTION_MEMBER_TYPES.ROUND,
    arguments: defaultRoundFunctionArguments,
    displayName: '',
    description: ''
  },
  9: {
    type: FORMULA_MEMBER_TYPES.functionMember,
    value: FUNCTION_MEMBER_TYPES.ROUND_UP,
    arguments: defaultRoundFunctionArguments,
    displayName: '',
    description: ''
  },
  11: {
    type: FORMULA_MEMBER_TYPES.functionMember,
    value: FUNCTION_MEMBER_TYPES.ROUND_DOWN,
    arguments: defaultRoundFunctionArguments,
    displayName: '',
    description: ''
  },
  13: placeholdersTypeMap[7],
  15: placeholdersTypeMap[9]
};
exports.extendedPlaceholdersTypeMap = extendedPlaceholdersTypeMap;
var sharePlaceholdersTypeMap = {
  1: placeholdersTypeMap[1],
  3: placeholdersTypeMap[7],
  5: placeholdersTypeMap[9]
};
exports.sharePlaceholdersTypeMap = sharePlaceholdersTypeMap;