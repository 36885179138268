import { v4 as uuid } from 'uuid';
import { DEFAULT_TOTAL_DISTRIBUTION } from '../constants';

export const basicInformationState = {
  template: '',
  displayName: '',
  description: '',
  country: null,
  countryCode: '',
  currency: '',
  fxSource: '',
  fxRateDate: '',
  fxRateCustomDate: '',
  startDate: null,
  lengthInYears: '',
  vestingType: null,
  recurrenceCount: null,
  recurrenceFrequency: null,
};

/** don't remove  localOverride - needed by Rule Block*/
export const defaultRule = {
  key: uuid(),
  eligibility: {},
  formula: [],
  localOverride: {
    countryCode: '',
    country: '',
  },
};

export const distributionTableState = {
  table: {
    rows: [],
    dirtyRows: [],
    total: DEFAULT_TOTAL_DISTRIBUTION,
    defaultTotal: DEFAULT_TOTAL_DISTRIBUTION,
  },
};

export const rulesBuilderState = {
  rules: [{ ...defaultRule }],
  distribution: {
    type: '',
    tableDataWillLoad: false,
    ...distributionTableState,
  },
  swapDistribution: {
    type: '',
    tableDataWillLoad: false,
    ...distributionTableState,
  },
  swapRules: [{ ...defaultRule }],
};

const initialState = {
  status: '',
  savedLtiPlanId: '',
  savedLtiPlanEtag: '',
  basicInformation: basicInformationState,
  swapBasicInformation: basicInformationState,
  ...rulesBuilderState,
  countries: [],
  currencies: [],
  companyDefaultCurrency: '',
  defaultCurrencyMappings: [],
  fxSources: [],
  fxRateDates: '',
  systemVariables: [],
  formulaOperators: [],
  eligibilityOperators: [],
  list: [],
  filterOptions: [],
  totalResults: 0,
  redirectToRoot: false,
  serverError: false,
  fetchingList: false,
  loadingById: false,
};

export default initialState;
