export default {
  VERSIONING_TITLE: 'Versioning',
  VERSIONING_LOADING_VERSIONS: 'Loading versioning data, please wait...',
  VERSIONING_NO_VERSION: 'No version created.',
  VERSIONING_BEFORE_DRAFT_LABEL:
    'Please provide the missing information or %Discard% this version.',
  VERSIONING_CREATE_LABEL:
    '%Create a new version% by duplicating the version on which you are working.',
  VERSIONING_DRAFT_INFO:
    'Save this reward and version one will be created, and you will be able to add other versions to this reward.',
  VERSIONING_WORKING_ON: 'Working on',
  VERSIONING_VALIDITY_DATES: 'Validity dates:',
  VERSIONING_VERSION_NOT_SAVED: 'Version not saved',
  VERSIONING_SHOW_ALL: 'Show all versions',
  VERSIONING_HIDE_ALL: 'Hide all versions',
  VERSIONING_VERSION: 'Version',
  VERSIONING_CURRENTLY_WORKING_ON: 'Currently working on',
  VERSIONING_ALL_VERSIONS: 'All versions',
  VERSIONING_DUPLICATED: 'Version %index% has been duplicated.',
  VERSIONING_DISCARDED: 'Version %index% has been discarded.',
  VERSIONING_DISCARD_TITLE: 'Are you sure you want to discard this version?',
  VERSIONING_DISCARD_SUBTITLE: "Your changes will be lost if you don't save them.",
  VERSIONING_OVERLAP:
    'Do note that the set date interval currently overlaps that of another version: %version% If you confirm this action, Version %index% will be set to expire when the current version becomes active.',
  VALUE_LABEL: 'Value',
};
