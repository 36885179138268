export default {
  LINKED_REWARDS_PLACEHOLDER:
    'Start by %selecting rewards% that will add to the eligibility criterion defined above.',
  LINKED_REWARDS_GENERIC_LABEL: 'Link rewards',
  LINKED_REWARDS_VARIABLE_PLACEHOLDER: 'Reward',
  LINKED_REWARDS_VARIABLE_RULE_VARIABLE_NAME: 'Reward name',
  LINKED_REWARDS_NEW_LOCAL_OVERRIDE_BLOCK_DISABLED_PLACEHOLDER:
    'Please select a country in order to start linking rewards that will add to the eligibility criterion defined above.',
  LINKED_REWARDS_NO_REWARDS: 'There are no rewards that can be linked for the selected country.',
};
