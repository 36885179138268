import executiveReport from './executiveReport';

export default {
  MAIN_PAGE: 'Home',
  PAGE_TITLE: 'Report',
  PAGE_TITLE_BETA: 'Reports Dashboard',
  EXPORT: 'Export',
  EXPORT_IN_PROGRESS_BANNER:
    'We have initiated the export process. This may take a while. You will be notified when finished.',
  COUNTRY: 'Select Country',
  EXECUTIVE_REPORT: 'Executive Report',
  GENERATION_IS_IN_PROGRESS: 'Generation report %FILENAME% has started.',
  NO_REPORT_DATA: 'There is no data to display.',
  COL_EMPLOYEE_ID: 'Employee Id',
  COL_REWARD_NAME: 'Reward Name',
  COL_REWARD_VALUE: 'Reward Value',
  COL_CURRENCY: 'Currency',
  COL_IS_SHOWN_TO_EMPLOYEE: 'Is Shown To Employee',
  COL_CATEGORY_1_DESCRIPTION: 'Category 1 Description',
  COL_WORK_LEVEL: 'Level',
  COL_COMPENSATION_GRADE: 'Compensation Grade',
  COL_EMPLOYEE_TYPE: 'Employee Type',
  COL_COUNTRY_OF_EMPLOYMENT: 'Country Of Employment',
  COL_SITE_NAME: 'Site Name',
  NEW_REPORT_BUTTON_TITLE: 'Create New Report',
  NEW_REPORT_DIALOG_TITLE: 'Create New External Report',
  STANDARD_REPORTS_SECTION_TITLE: 'Standard Reports',
  FLEX_REPORTS_SECTION_TITLE: 'Configurable Reports',
  EXTERNAL_REPORTS_SECTION_TITLE: 'Custom Reports',
  EDIT_REPORT_DIALOG_TITLE: 'Edit %NAME% Report',
  REPORT_NAME: 'Report Name',
  REPORT_URL: 'Report URL',
  REPORT_NAME_PLACEHOLDER: 'Enter Report Name',
  REPORT_URL_PLACEHOLDER: 'Enter Report URL',
  REPORT_CREATED_SUCCESSFULLY: 'Report has been created successfully!',
  REPORT_EDITED_SUCCESSFULLY: 'Report has been edited successfully!',
  EDIT_REPORT_MENU: 'Edit Report',
  DELETE_REPORT_MENU: 'Delete Report',
  DELETE_REPORT_TITLE: 'Are you sure you wish to delete this report?',
  DELETE_REPORT_SUBTITLE: 'Once deleted, the report will be removed from this page.',
  REPORT_DELETED_SUCCESSFULLY: 'Report has been deleted successfully.',
  COLUMN_CUSTOM_TYPES: 'Custom Types',
  COLUMN_INCLUDED: 'Included',
  COLUMN_NOT_INCLUDED: 'Not Included',
  CONFIGURATION_PAGE_TITLE: 'System Reports Configuration',
  BACK_TO_REPORTS: 'Close',
  SAVE: 'Save',
  ...executiveReport,
};
