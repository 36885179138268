import styled, { css } from 'styled-components';

const StyledSnapshotPdf = styled.div`
  ${props => {
    const { hasPdfName, theme } = props;
    const { spacing } = theme;

    return css`
      ${!hasPdfName &&
        css`
          display: flex;
        `}
      .input-component {
        ${!hasPdfName &&
          css`
            align-self: flex-end;
          `}
        .pdf-name-input {
          width: 368px;
          margin-right: ${spacing.small};
          background: none;
        }
      }

      .downloadSnapshot {
        display: flex;
        align-items: center;
        text-decoration: none;
        justify-content: center;

        i {
          top: 0;
          margin-right: ${spacing.tiny};
        }
      }
    `;
  }};
`;

StyledSnapshotPdf.displayName = 'StyledSnapshotPdf';
export default StyledSnapshotPdf;
