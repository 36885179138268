export default {
  BM_STATUS_ALL: 'All',
  BM_STATUS_NOT_SUBMITTED: 'Not submitted',
  BM_STATUS_SUBMITTED: 'Submitted',
  BM_STATUS_APPROVED: 'Approved',
  BM_STATUS_REJECTED: 'Rejected',
  LOCAL_PREPARATION_NOT_SUBMITTED_OR_APPROVED:
    'Local preparation has not been submitted or approved.',
  INVALID_COUNTRY_CODE: 'The country code provided is not valid',
  LOCAL_SETTINGS_NOT_FOUND: 'The local benchmarking data for this country could not be found.',
  SALARY_INCREASE_HEAT_MAPS_NOT_CALCULATED:
    'Heatmaps not calculated, please press calculate before taking any action.',
  PAYSCALE_INCREASE_HEAT_MAPS_NOT_CALCULATED: 'Payscale heatmaps not calculated.',
  SALARY_INCREASE_NOT_SUBMITTED_OR_APPROVED: 'Salary increase has not been submitted or approved.',
  CURRENT_STEP_IS_SUBMITTED_OR_APPROVED: 'The current step has been submitted or approved.',
  CURRENT_STEP_IS_APPROVED: 'The current step is approved.',
  CURRENT_STEP_IS_NOT_SUBMITTED: 'The current step is not submitted',
  CAN_NOT_CHANGE_STATUS_TO_SUBMITTED: 'Could not change status to submitted.',
};
