import * as R from 'ramda';
import { createAction } from 'redux-actions';
import { upperFirstLetter, isBlank, rejectEmptyValues } from '@trs/utils';
import { encodeValuesInRules } from 'modules/common/helpers';
import { RequestFactory, GET, PATCH } from '../../../../../../../../../config/http';
import { rewards, benchmark } from '../../../../../../../../../config/api';
import { getError } from '../../../../../../../../../config/errors';
import { TextOnly } from '../../../../../../../../common/Text';
import { withCache } from '../../../../../../../../common/timedCache';
import { showBanner } from '../../../../../../../../../actions/globalActions';
import { etagUpdate } from '../../../actions/helpers';
import { loadLocalBenchmarkingStatuses } from '../../../../../actions';
import { getLocalSettingsId } from '../../../../../actions/helpers';
import { dispatchError } from '../../../../../../common/helpers';

const ROOT_TYPE = 'BENCHMARKING_POPULATION_EXCEPTION';
const IMPACT_TYPE = 'BENCHMARKING_POPULATION_IMPACT';
const cachedGet = withCache(GET, 3600);

export const saveActions = {
  pending: createAction(`${ROOT_TYPE}/SAVE/PENDING`),
  success: createAction(`${ROOT_TYPE}/SAVE/SUCCESS`),
  error: createAction(`${ROOT_TYPE}/SAVE/ERROR`),
};

export const generateImpactActions = {
  pending: createAction(`${IMPACT_TYPE}/SAVE/PENDING`),
  success: createAction(`${IMPACT_TYPE}/SAVE/SUCCESS`),
  error: createAction(`${IMPACT_TYPE}/SAVE/ERROR`),
};

const actionTypes = {
  BENCHMARKING_INIT_POPULATION_EXCEPTION: 'BENCHMARKING_INIT_POPULATION_EXCEPTION',
  BENCHMARKING_POPULATION_EXCEPTION_CHANGE: 'BENCHMARKING_POPULATION_EXCEPTION_CHANGE',
  BENCHMARKING_DELETE_POPULATION_EXCEPTION: 'BENCHMARKING_DELETE_POPULATION_EXCEPTION',
  BENCHMARKING_POPULATION_EXCEPTION_ERROR: 'BENCHMARKING_POPULATION_EXCEPTION_ERROR',
  BENCHMARKING_POPULATION_EXCEPTION_SUCCESS: 'BENCHMARKING_POPULATION_EXCEPTION_SUCCESS',
  BENCHMARKING_POPULATION_EXCEPTION_PENDING: 'BENCHMARKING_POPULATION_EXCEPTION_PENDING',
  BENCHMARKING_GET_SYSTEM_VARIABLES_LOOKUP_DATA: 'BENCHMARKING_GET_SYSTEM_VARIABLES_LOOKUP_DATA',
  BENCHMARKING_POPULATION_EXCEPTION_VALIDATION_ERROR:
    'BENCHMARKING_POPULATION_EXCEPTION_VALIDATION_ERROR',
  BENCHMARKING_RESET_IMPACTED_POPULATION: 'BENCHMARKING_RESET_IMPACTED_POPULATION',
};

export const initPopulationExceptions = createAction(
  actionTypes.BENCHMARKING_INIT_POPULATION_EXCEPTION
);

export const getPopulationExceptionsPending = createAction(
  actionTypes.BENCHMARKING_POPULATION_EXCEPTION_PENDING
);
export const getPopulationExceptionsSuccess = createAction(
  actionTypes.BENCHMARKING_POPULATION_EXCEPTION_SUCCESS
);
export const getPopulationExceptionsError = createAction(
  actionTypes.BENCHMARKING_POPULATION_EXCEPTION_ERROR
);

export const populationExceptionsChange = createAction(
  actionTypes.BENCHMARKING_POPULATION_EXCEPTION_CHANGE
);
export const deletePopulationExceptions = createAction(
  actionTypes.BENCHMARKING_DELETE_POPULATION_EXCEPTION
);
export const updatePopulationExceptionsError = createAction(
  actionTypes.BENCHMARKING_POPULATION_EXCEPTION_VALIDATION_ERROR
);
export const getVariablesLookupData = createAction(
  actionTypes.BENCHMARKING_GET_SYSTEM_VARIABLES_LOOKUP_DATA
);
export const resetImpactedPopulation = createAction(
  actionTypes.BENCHMARKING_RESET_IMPACTED_POPULATION
);

export const getPopulationExceptions = exceptionsId => dispatch => {
  dispatch(getPopulationExceptionsPending());

  return GET(`${rewards.eligibilityRules}/${exceptionsId}`)
    .then(response => {
      dispatch(
        getPopulationExceptionsSuccess({
          response: R.path(['data', 'response'], response),
          etag: R.path(['headers', 'etag'], response),
        })
      );
    })
    .catch(error => {
      dispatch(getPopulationExceptionsError());
      dispatchError(error, dispatch);
    });
};

export const validateEligibilitySimplePopulation = populationObject =>
  R.mapObjIndexed((item, key) => {
    let keyLabel;
    switch (key) {
      case 'variable':
        keyLabel = TextOnly({ path: 'rewards.ELIGIBILITY_RULE_VARIABLE_NAME' });
        break;
      case 'operator':
        keyLabel = TextOnly({ path: 'rewards.ELIGIBILITY_RULE_OPERATOR' });
        break;
      case 'values':
        keyLabel = TextOnly({ path: 'rewards.ELIGIBILITY_RULE_VALUE' });
        break;
      default:
        keyLabel = key;
        break;
    }

    return getError(TextOnly({ path: 'generic.ERROR_REQUIRED' }), upperFirstLetter(keyLabel));
  }, R.filter(R.isEmpty, populationObject));

export const changePopulationField = (field, value, other) => (dispatch, getState) => {
  const state = getState();
  const errors = R.clone(R.path(['benchmarking', 'local', 'errors', 'exception'], state));
  const newErrors = errors || {};

  if (R.path(['eligibility', R.path(['rule', 'uid'], other)], newErrors)) {
    const newException = R.prop('rule', other);
    newErrors.eligibility[newException.uid] = validateEligibilitySimplePopulation(newException);
  }

  dispatch(populationExceptionsChange({ field, value, other }));
  dispatch(updatePopulationExceptionsError({ data: newErrors }));
};

export const saveLocalPreparationExceptions = (benchmarkingId, localId, etag, exceptionsId) => (
  dispatch,
  getState
) => {
  const payload = { exceptionsId: exceptionsId || '' };
  const localSettingsId = getLocalSettingsId(getState());

  return PATCH(
    `${benchmark.benchmarking}/${benchmarkingId}/localPreparations/${localId}`,
    payload,
    { ETag: etag }
  )
    .then(response => {
      const responseBody = R.path(['data', 'response'], response);

      dispatch(etagUpdate(R.prop('etag', responseBody)));
      dispatch(loadLocalBenchmarkingStatuses(localSettingsId));
      dispatch(resetImpactedPopulation());
      dispatch(
        showBanner({
          type: 'success',
          content: TextOnly({ path: 'benchmarking.POPULATION_EXCEPTIONS_SAVE_SUCCESS' }),
        })
      );
    })
    .catch(error => {
      dispatchError(error, dispatch);
    });
};

export const removePopulationExceptions = () => (dispatch, getState) => {
  const { benchmarkingId, localId, etag, exceptionsId } = R.path(
    ['benchmarking', 'local', 'localPreparation'],
    getState()
  );

  if (!isBlank(exceptionsId)) {
    dispatch(saveLocalPreparationExceptions(benchmarkingId, localId, etag));
  }

  dispatch(deletePopulationExceptions());
};

export const loadSystemVariablesLookup = url => dispatch =>
  cachedGet(`${rewards.systemVariables}/${url}`)
    .then(response =>
      dispatch(getVariablesLookupData({ lookupData: R.prop('data', response), url }))
    )
    .catch(e => dispatchError(e, dispatch));

export const getEligibilityErrors = (eligibility, errors = {}) => {
  const errorObject = errors;
  if (eligibility.eligibilityRows) {
    R.forEach(
      eligibilityRow => getEligibilityErrors(eligibilityRow, errorObject),
      eligibility.eligibilityRows
    );
  }

  if (!isBlank(R.prop('eligibilityRowsOperator', eligibility))) {
    return errorObject;
  }

  const populationErrors = validateEligibilitySimplePopulation({
    variable: R.prop('variable', eligibility),
    operator: R.prop('operator', eligibility),
    values: R.prop('values', eligibility),
  });

  if (!isBlank(populationErrors)) {
    errorObject[eligibility.uid] = populationErrors;
  }

  return errorObject;
};

export const validateException = exception => {
  const { eligibility } = exception;

  const errors = {
    eligibility: getEligibilityErrors(eligibility),
  };
  return isBlank(rejectEmptyValues(R.values(errors.eligibility))) ? null : errors;
};

export const savePopulationExceptions = () => (dispatch, getState) => {
  const { benchmarkingId, localId, exceptionsId, exceptions, exceptionsEtag, etag } = R.path(
    ['benchmarking', 'local', 'localPreparation'],
    getState()
  );

  const deepCopyRules = JSON.parse(JSON.stringify(exceptions));

  const requestEligibilityRules = deepCopyRules.map(rule => ({
    ...rule,
    eligibility: encodeValuesInRules(rule.eligibility),
  }));

  const errors = validateException(exceptions[0]);
  if (errors !== null) return dispatch(updatePopulationExceptionsError({ data: errors }));
  const opts = {
    url: `${rewards.eligibilityRules}/${exceptionsEtag ? exceptionsId : ''}`,
    method: exceptionsId ? 'PUT' : 'POST',
    headers: exceptionsId ? { Etag: exceptionsEtag } : {},
    data: {
      eligibilityRules: requestEligibilityRules,
    },
  };
  dispatch(saveActions.pending());

  return RequestFactory(opts.method, opts.url, opts.headers, opts.data)
    .then(body => {
      dispatch(
        saveActions.success({
          exceptionsEtag: R.path(['headers', 'etag'], body),
          exceptionsId: R.path(['data', 'response', 'id'], body),
        })
      );
      dispatch(
        saveLocalPreparationExceptions(
          benchmarkingId,
          localId,
          etag,
          R.path(['data', 'response', 'id'], body)
        )
      );
      return Promise.resolve(body);
    })
    .catch(body => {
      const err = R.path(['response', 'data', 'errors'], body);
      dispatch(saveActions.error(err));
      return Promise.reject(err);
    });
};

export const combinePopulations = rulesEligibility => {
  const employees = [];
  R.forEach(rule => {
    employees.push(...R.prop('employees', rule));
  }, rulesEligibility);
  return employees;
};

export const generateImpactedPopulation = () => (dispatch, getState) => {
  const { populationsId, country, exceptionsId, exceptionsEtag } = R.path(
    ['benchmarking', 'local', 'localPreparation'],
    getState()
  );
  let url = `${rewards.eligibilityRules}/${populationsId}/employee/${country}`;
  url += !isBlank(exceptionsEtag) ? `/?exclusionId=${exceptionsId}` : '';

  dispatch(generateImpactActions.pending());
  return GET(url)
    .then(response => {
      const employees = combinePopulations(
        R.path(['data', 'response', 'rulesEligibility'], response)
      );
      dispatch(
        generateImpactActions.success({
          employees,
        })
      );
    })
    .catch(error => {
      dispatch(generateImpactActions.error());
      dispatchError(error, dispatch);
    });
};
