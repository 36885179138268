/* eslint-disable max-lines */
/* eslint-disable max-statements */
/* eslint-disable max-lines-per-function */
import * as R from 'ramda';
import APP_CONFIG from '@trs/config';
import { isBlank } from '@trs/utils';
import { v4 as uuid } from 'uuid';
import {
  getPath,
  mapResponseToBasicInfoModel,
  mapRulesToRequestModel,
  transformRewardsList,
} from './helpers';
import * as actions from '../actions/types';
import { filterCountriesByAccess } from '../../common/helpers';
import userModules from '../../../config/userManager/userModules';
import { ACTIVE_TYPES, FX_RATE_DATE_CUSTOM } from '../constants';
import initialState from './initialState';

const { TEMPLATE_TYPES } = APP_CONFIG;
let swap = {};

export const getLocalOverride = (template, countries) => {
  if (template !== TEMPLATE_TYPES.share || R.length(countries) > 1) {
    return {
      country: '',
      countryCode: '',
    };
  }

  return {
    country: R.path(['0', 'value'], countries),
    countryCode: R.path(['0', 'code'], countries),
  };
};

export const getNewRule = ({ localOverride }) => ({
  key: uuid(),
  eligibility: {},
  additionalRewardsEligibility: {},
  formula: [],
  threshold: [],
  localOverride,
  complex: {
    activeType: ACTIVE_TYPES.fixed,
    [ACTIVE_TYPES.fixed]: {
      contribution: [],
      minimum: [],
      maximum: [],
    },
    [ACTIVE_TYPES.percentage]: {
      contribution: [],
      additionalFormulaItems: [],
      minimum: [],
      maximum: [],
    },
  },
});

export default function rewardsReducer(state = R.clone(initialState), action) {
  switch (action.type) {
    case actions.REWARDS_REQUESTED: {
      return {
        ...state,
        fetchingList: true,
      };
    }
    case actions.REWARDS_SEARCH: {
      const rewardsCount = action.rewardList.data.count;
      const data = action.rewardList.data.versionRewards;
      const { authorizedModules } = action;
      const filterOptions = R.map(
        (item) => ({
          title: item.key,
          key: item.key,
          list: item.options,
        }),
        action.rewardList.data.filterOptions || []
      );

      const list = transformRewardsList(data, authorizedModules);
      return {
        ...state,
        list,
        filterOptions,
        totalResults: rewardsCount || 0,
        fetchingList: false,
      };
    }
    case actions.REWARDS_IMPACT_SEARCH: {
      const rewardsCount = action.rewardList.data.response.count;
      const data = action.rewardList.data.response.versionRewards;
      const { authorizedModules } = action;
      const filterOptions = R.map(
        (item) => ({
          title: item.key,
          key: item.key,
          list: item.options,
        }),
        action.rewardList.data.response.filterOptions || []
      );

      const list = transformRewardsList(data, authorizedModules);
      return {
        ...state,
        list,
        filterOptions,
        totalResults: rewardsCount || 0,
        fetchingList: false,
      };
    }
    case actions.REWARDS_VERSIONS_REQUESTED: {
      return {
        ...state,
        fetchingRewardsVersions: true,
      };
    }
    case actions.REWARDS_VERSIONS_SEARCH: {
      const { authorizedModules } = action;
      const list = state.list.map((reward) => {
        if (reward.id === action.payload.rewardsGroupId) {
          const rewardVersions = transformRewardsList(action.payload.versions, authorizedModules);
          return {
            ...reward,
            versions: rewardVersions,
          };
        }
        return reward;
      });
      return {
        ...state,
        list,
        fetchingRewardsVersions: false,
      };
    }
    case actions.REWARDS_GET_ERROR: {
      return {
        ...state,
        fetchingList: false,
      };
    }
    case actions.REWARDS_GET_IMPACT_ERROR: {
      return {
        ...state,
        fetchingList: false,
      };
    }
    case actions.SYSTEM_VARIABLES_GET: {
      const eligibilityRuleLabels = {};
      let systemVariables = [];

      action.systemVariables.data.map(({ displayName, propertyName, type, valuesUrl }) => {
        systemVariables.push({
          value: propertyName,
          label: displayName,
          type,
          valuesUrl,
        });
        eligibilityRuleLabels[propertyName] = displayName;
        return false;
      });
      systemVariables = R.sortBy(R.prop('value'))(systemVariables);

      return {
        ...state,
        systemVariables,
        eligibilityRuleLabels,
      };
    }

    case actions.FX_RATE_GET_DATES: {
      const fxRateDates = [FX_RATE_DATE_CUSTOM];

      action.fxRateDates.data.map(({ displayName, propertyName, type, valuesUrl }) => {
        fxRateDates.push({
          value: propertyName,
          label: displayName,
          type,
          valuesUrl,
        });
        return false;
      });

      return {
        ...state,
        fxRateDates,
      };
    }

    case actions.ELIGIBILITY_OPERATORS_GET: {
      const eligibilityOperators = [];

      action.eligibilityOperators.data.map(({ displayName, name, allowsMultipleSelect }) =>
        eligibilityOperators.push({
          value: name,
          label: displayName,
          allowsMultipleSelect,
        })
      );
      return {
        ...state,
        eligibilityOperators,
      };
    }

    case actions.SYSTEM_VARIABLES_LOOKUP_GET: {
      const { lookupData, url } = action;
      const lookUpValues = {};
      lookUpValues[url] = [];

      if (lookupData.data) {
        lookupData.data.forEach((item) =>
          lookUpValues[url].push({
            label: item,
            value: item,
          })
        );
        lookUpValues[url] = R.sortBy(R.prop('value'))(lookUpValues[url]);
      } else {
        lookUpValues[url] = [];
      }
      return {
        ...state,
        lookUpValues: { ...state.lookUpValues, ...lookUpValues },
      };
    }
    case actions.REWARDS_GET_CATEGORIES: {
      return {
        ...state,
        categories: action.categories,
      };
    }

    case actions.REWARDS_SAVE: {
      swap.basicInformation = R.clone(state.basicInformation);
      return {
        ...state,
        savedRewardId: action.id,
      };
    }

    case actions.REWARDS_PUBLISHED: {
      return {
        ...state,
        publishedSuccess: true,
      };
    }

    case actions.STORE_RESET: {
      swap = {};
      return {
        ...state,
        ...R.clone(initialState),
      };
    }

    case actions.BASIC_INFORMATION_CHANGE: {
      return {
        ...state,
        basicInformation: {
          ...state.basicInformation,
          [action.field]: action.value,
          ...action.other,
        },
      };
    }

    case actions.DESCRIPTION_CHANGE: {
      return {
        ...state,
        basicInformation: {
          ...state.basicInformation,
          description: {
            ...state.basicInformation.description,
            [action.field]: action.value,
          },
        },
      };
    }

    case actions.BASIC_INFORMATION_RESET: {
      const optionalDefaults = R.map(
        (key) => ({
          [key]: getPath(swap, key) || getPath('initialState', key) || action.optionalDefaults[key],
        }),
        R.keys(action.optionalDefaults)
      );
      return {
        ...state,
        basicInformation: {
          ...R.clone(swap.basicInformation || initialState.basicInformation),
          ...R.mergeAll(optionalDefaults),
        },
      };
    }

    case actions.REDIRECT_TO_ROOT: {
      return {
        ...state,
        redirectToRoot: true,
      };
    }

    case actions.REDIRECT_TO_EXCEPTIONS: {
      return {
        ...state,
        redirectToExceptions: true,
      };
    }

    case actions.ETAG_UPDATE: {
      swap.basicInformation = R.clone(state.basicInformation);
      swap.rules = R.clone(state.rules);

      return {
        ...state,
        savedRewardEtag: action.savedRewardEtag,
      };
    }

    case actions.RULES_LOGIC_BUILDER_SAVE: {
      return {
        ...state,
        hasSavedLogicBuilder: true,
      };
    }

    case actions.RULES_BUILDER_RESET: {
      return {
        ...state,
        rules: [...R.clone(swap.rules || initialState.rules)],
      };
    }

    case actions.RULE_CHANGE: {
      const rules = R.clone(state.rules);
      rules[action.index] = {
        ...rules[action.index],
        [action.field]: action.value,
        ...action.other,
      };
      return {
        ...state,
        rules,
      };
    }

    case actions.REWARDS_ADD_RULE_BLOCK: {
      const rules = R.clone(state.rules);
      const localOverride = getLocalOverride(
        R.path(['basicInformation', 'template'], state),
        R.prop('countries', state)
      );

      const newRule = getNewRule({ localOverride });

      return {
        ...state,
        rules: R.insert(action.index + 1, newRule, rules),
      };
    }

    case actions.REWARDS_DUPLICATE_RULE_BLOCK: {
      const rules = R.clone(state.rules);

      const newRule = {
        ...action.ruleBlock,
        localOverride: getLocalOverride(
          R.path(['basicInformation', 'template'], state),
          R.prop('countries', state)
        ),
      };

      return {
        ...state,
        rules: R.insert(action.index + 1, { ...newRule, key: uuid() }, rules),
      };
    }

    case actions.REWARDS_DELETE_RULE_BLOCK: {
      const { rules } = { ...state };

      return {
        ...state,
        rules: R.remove(action.index, 1, rules),
      };
    }

    case actions.REWARDS_DELETE_RULE_FORMULAS: {
      const rules = R.clone(state.rules);
      rules[action.index] = {
        ...rules[action.index],
        formula: [],
        threshold: [],
        complex: {
          activeType: ACTIVE_TYPES.fixed,
          [ACTIVE_TYPES.fixed]: {
            contribution: [],
            minimum: [],
            maximum: [],
          },
          [ACTIVE_TYPES.percentage]: {
            contribution: [],
            additionalFormulaItems: [],
            minimum: [],
            maximum: [],
          },
        },
        isStepper: false,
        stepper: {
          activeType: ACTIVE_TYPES.fixed,
          [ACTIVE_TYPES.fixed]: {
            steps: [],
          },
          [ACTIVE_TYPES.percentage]: {
            steps: [],
          },
        },
      };

      return {
        ...state,
        rules,
      };
    }

    case actions.REWARDS_DELETE_RULE_RANGE: {
      const rules = R.clone(state.rules);
      const { complex } = rules[action.index];
      rules[action.index] = {
        ...rules[action.index],
        complex: {
          ...complex,
          [complex.activeType]: {
            ...complex[complex.activeType],
            minimum: [],
            maximum: [],
          },
        },
      };

      return {
        ...state,
        rules,
      };
    }

    case actions.FORMULA_OPERATORS_GET: {
      return {
        ...state,
        formulaOperators: action.formulaOperators,
      };
    }

    case actions.REWARDS_GET_COUNTRIES: {
      if (action.error) {
        return {
          ...state,
          serverError: true,
        };
      }
      const { authorizedModules, profile } = action.user;
      const countries = [];
      const defaultCurrencyMappings = filterCountriesByAccess({
        countries: action.countries.data,
        module: userModules.REWARDS_MANAGEMENT,
        authorizedModules,
      });

      defaultCurrencyMappings.map((country) =>
        countries.push({
          value: country.name,
          label: country.name,
          code: country.code,
        })
      );

      if (isBlank(countries)) {
        countries.push({ value: profile.country, label: profile.country });
      }
      // the bellow is dangerous, needs to be revisited.
      return {
        ...state,
        basicInformation: {
          ...state.basicInformation,
          country: R.prop('value')(R.find(R.propEq('value', profile.country))(countries)) || '',
        },
        countries,
        defaultCurrencyMappings,
      };
    }

    case actions.REWARDS_GET_CURRENCIES: {
      const currencies = [];

      action.currencies.data.map((currency) =>
        currencies.push({
          value: currency.code,
          label: currency.code,
        })
      );

      return {
        ...state,
        currencies,
        basicInformation: {
          ...state.basicInformation,
          currency:
            R.prop('value')(R.find(R.propEq('value', action.user.profile.currency))(currencies)) ||
            '',
        },
      };
    }

    case actions.REWARDS_GET_FX_SOURCES: {
      const fxSources = [];

      if (action.fxSources) {
        action.fxSources.data.map((source) =>
          fxSources.push({
            value: source.id,
            label: source.name,
          })
        );
      }

      return {
        ...state,
        fxSources,
      };
    }

    case actions.REWARDS_GET_TAXABILITY_VALUES: {
      return {
        ...state,
        taxabilityValues: action.taxabilityValues.response.map((source) => ({
          value: source.lineItemId,
          label: source.lineItem,
        })),
      };
    }

    case actions.REWARDS_GET_BY_ID: {
      const { response } = action.response.data;
      const basicInformation = mapResponseToBasicInfoModel(response);
      const rewardRules = response.rules.length
        ? mapRulesToRequestModel(response.rules, basicInformation.template)
        : initialState.rules;
      swap.basicInformation = R.clone(basicInformation);
      return {
        ...state,
        basicInformation,
        savedRewardId: response.id,
        hasSavedLogicBuilder: response.rules.length > 0,
        rules: rewardRules,
        hasExceptions: response.hasExceptions,
        versionGroup: R.prop('versionGroup', response),
      };
    }
    case actions.REWARDS_GET_STATUS: {
      const { response } = action.response.data;

      return {
        ...state,
        savedRewardId: response.id,
        hasSavedLogicBuilder: response.rules.length > 0,
        basicInformation: {
          ...state.basicInformation,
          status: response.status,
        },
      };
    }
    case actions.FETCHING_REWARD_BY_ID: {
      return {
        ...state,
        fetchingRewardById: action.state,
      };
    }
    case actions.SET_REWARD_FEEDBACK: {
      const filterOptions = R.map(
        (item) => ({
          title: item.key,
          key: item.key,
          list: item.options,
        }),
        action.rewardFeedback.filterOptions || []
      );
      return {
        ...state,
        rewardFeedback: {
          ...state.rewardFeedback,
          list: action.rewardFeedback.feedbacks,
          totalResults: action.rewardFeedback.count,
          filterOptions,
        },
      };
    }
    case actions.SET_IS_LOADING_REWARD_FEEDBACK: {
      return {
        ...state,
        rewardFeedback: {
          ...state.rewardFeedback,
          fetchingList: action.fetchingList,
        },
      };
    }
    case actions.LINKED_REWARDS_GET: {
      let linkableRewards = [];
      action.linkableRewards.data.forEach(({ displayName, value, type, valuesUrl }) => {
        linkableRewards.push({
          value,
          label: displayName,
          type,
          valuesUrl,
        });
      });
      linkableRewards = R.sortBy(R.prop('displayName'))(linkableRewards);
      return {
        ...state,
        linkableRewards,
        linkableRewardsLoading: false,
      };
    }
    case actions.LINKED_REWARDS_OPERANDS_REQUESTED: {
      return {
        ...state,
        linkableRewardsLoading: true,
      };
    }
    case actions.GET_VIDEO_PLATFORMS_ACCEPTED: {
      return {
        ...state,
        videoPlatforms: action.payload,
      };
    }

    default:
      return state;
  }
}
