"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.UNITS = exports.TOO_MANY_REQUESTS_STATUS_CODE = exports.TEXTAREA_MAX_LENGTH = exports.TEMPLATE_TYPES = exports.REWARD_STATUSES = exports.REWARD_CONTEXTS = exports.PERCENTAGE_TOGGLER_ICONS = exports.PACKAGE_STATUSES = exports.PACKAGE_AVAILABILITY_THRESHOLD = exports.MEDIA_TYPE = exports.MAX_DECIMALS = exports.INPUT_MAX_LENGTH = exports.GLOBAL_KEYWORD = exports.GENERAL_STATUSES_KEYS = exports.GENERAL_STATUSES = exports.FORBIDDEN_STATUS_CODE = exports.EXPIRY_TIMES = exports.EMAIL_REGEX = exports.DATE_TIME_FORMAT = exports.DATE_TIME_EMPTY_FORMAT = exports.DATE_TIME_DASH_FORMAT = exports.DATE_FORMAT = exports.CONFLICT_STATUS_CODE = exports.AUDIT_REQUEST_LIMIT = void 0;
var DATE_FORMAT = 'dd/MM/yyyy';
exports.DATE_FORMAT = DATE_FORMAT;
var DATE_TIME_FORMAT = 'dd/MM/yyyy HH:mm';
exports.DATE_TIME_FORMAT = DATE_TIME_FORMAT;
var DATE_TIME_DASH_FORMAT = 'dd-MM-yyyy HH:mm';
exports.DATE_TIME_DASH_FORMAT = DATE_TIME_DASH_FORMAT;
var DATE_TIME_EMPTY_FORMAT = 'dd MMMM yyyy HH:mm';
exports.DATE_TIME_EMPTY_FORMAT = DATE_TIME_EMPTY_FORMAT;
var MAX_DECIMALS = 2;
exports.MAX_DECIMALS = MAX_DECIMALS;
var EMAIL_REGEX = /^[a-zA-Z0-9_+&*-]+(?:\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,7}$/;
exports.EMAIL_REGEX = EMAIL_REGEX;
var UNITS = ['kb', 'mb'];
exports.UNITS = UNITS;
var GENERAL_STATUSES_KEYS = {
  STATUS_DRAFT: 'STATUS_DRAFT',
  STATUS_PUBLISHED: 'STATUS_PUBLISHED',
  STATUS_LIVE: 'STATUS_LIVE',
  STATUS_EXPIRED: 'STATUS_EXPIRED',
  STATUS_ERROR: 'STATUS_ERROR',
  STATUS_CALCULATING: 'STATUS_CALCULATING'
};
exports.GENERAL_STATUSES_KEYS = GENERAL_STATUSES_KEYS;
var GENERAL_STATUSES = {
  DRAFT: 'draft',
  PUBLISHED: 'published',
  LIVE: 'live',
  EXPIRED: 'expired',
  ERROR: 'error',
  CALCULATING: 'calculating'
};
exports.GENERAL_STATUSES = GENERAL_STATUSES;
var REWARD_STATUSES = {
  calculating: 'calculating',
  live: 'live',
  expired: 'expired',
  published: 'published',
  draft: 'draft',
  error: 'error'
};
exports.REWARD_STATUSES = REWARD_STATUSES;
var PACKAGE_STATUSES = {
  draft: 'Draft',
  published: 'Published',
  live: 'Live',
  expired: 'Expired'
};
exports.PACKAGE_STATUSES = PACKAGE_STATUSES;
var MEDIA_TYPE = {
  IMAGE: 'Image',
  VIDEO: 'Video'
};
exports.MEDIA_TYPE = MEDIA_TYPE;
var TEMPLATE_TYPES = {
  simple: 'simple',
  share: 'share',
  pension: 'pension',
  flex: 'flex',
  percentage: 'percentage'
};
exports.TEMPLATE_TYPES = TEMPLATE_TYPES;
var REWARD_CONTEXTS = {
  reward: 'reward',
  share: 'share',
  flexPackage: 'flexPackage',
  fixedCosts: 'fixedCosts',
  ltiPlans: 'ltiPlans'
};
exports.REWARD_CONTEXTS = REWARD_CONTEXTS;
var PERCENTAGE_TOGGLER_ICONS = {
  NUMERIC: '123',
  PERCENTAGE: '%'
};
exports.PERCENTAGE_TOGGLER_ICONS = PERCENTAGE_TOGGLER_ICONS;
var GLOBAL_KEYWORD = 'Global';
exports.GLOBAL_KEYWORD = GLOBAL_KEYWORD;
var INPUT_MAX_LENGTH = 1000;
exports.INPUT_MAX_LENGTH = INPUT_MAX_LENGTH;
var TEXTAREA_MAX_LENGTH = 4000;
exports.TEXTAREA_MAX_LENGTH = TEXTAREA_MAX_LENGTH;
var EXPIRY_TIMES = {
  HH: 1800,
  H: 3600
};
exports.EXPIRY_TIMES = EXPIRY_TIMES;
var CONFLICT_STATUS_CODE = 412;
exports.CONFLICT_STATUS_CODE = CONFLICT_STATUS_CODE;
var FORBIDDEN_STATUS_CODE = 403;
exports.FORBIDDEN_STATUS_CODE = FORBIDDEN_STATUS_CODE;
var TOO_MANY_REQUESTS_STATUS_CODE = 429;
exports.TOO_MANY_REQUESTS_STATUS_CODE = TOO_MANY_REQUESTS_STATUS_CODE;
var PACKAGE_AVAILABILITY_THRESHOLD = 28;

// limit for the audit reports, integer or false
exports.PACKAGE_AVAILABILITY_THRESHOLD = PACKAGE_AVAILABILITY_THRESHOLD;
var AUDIT_REQUEST_LIMIT = 50;
exports.AUDIT_REQUEST_LIMIT = AUDIT_REQUEST_LIMIT;