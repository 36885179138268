const initialState = {
  executiveSummary: {},
  targetMarketPosition: {},
  marketData: {},
  rewardIds: [],
  impactedEmployees: [],
  benchmarkingId: null,
  localId: null,
  country: null,
  etag: null,
  isLoading: true,
  isUploadInProgress: false,
  isLastYearUploadInProgress: false,
  isAgingData: false,
  isRewardsLoading: false,
  isPopulationExceptionsLoading: false,
  savingExceptions: false,
  exceptionHasChanges: false,
  isDataValid: false,
  isSaving: false,
  status: '',
  isImpactLoading: false,
  impactedEmployeesGenerated: false,
  editableMarketIndicators: {
    countrySpecific: false,
    companySpecific: false,
  },
  isLoadingTargetMarketGroup: false,
  isSavingTargetMarketGroup: false,
  newCompanyInfo: {
    company: '',
    industry: '',
    revenue: null,
    numberOfEmployees: null,
  },
  inAddingMode: false,
  isLoadingLastYear: true,
};

export default initialState;
