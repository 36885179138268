import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Paragraph, Button } from '@trs/components';
import { NOTIFICATION_TYPE } from 'modules/notifications/constants';
import NotificationHeader from 'modules/notifications/components/header';
import Text, { TextOnly } from 'modules/common/Text';
import { isBlank } from '@trs/utils/lib/objects';

const ExceptionsNotification = ({ item, markNotification }) => {
  const {
    id,
    createdDate,
    content: { count, error },
    typeId,
  } = item;
  const isExceptionsPublished = typeId === NOTIFICATION_TYPE.EXCEPTIONS_PUBLISH;
  const notificationStatus = isBlank(error) || error.toLowerCase() === 'true' ? 'SUCCESS' : 'ERROR';
  return (
    <Fragment>
      <NotificationHeader
        createdDate={createdDate}
        title={TextOnly({
          path: isExceptionsPublished
            ? 'notifications.NOTIFICATION_EXCEPTION_PUBLISHED_TITLE'
            : 'notifications.NOTIFICATION_EXCEPTION_DELETED_TITLE',
        })}
      />
      <Paragraph className="notification-content">
        <Text
          path={
            isExceptionsPublished
              ? `notifications.NOTIFICATION_EXCEPTION_PUBLISHED_CONTENT_${notificationStatus}`
              : `notifications.NOTIFICATION_EXCEPTION_DELETED_CONTENT_${notificationStatus}`
          }
          transform={(label = '') => label.replace('%NR_EXCEPTIONS%', count)}
          withHtml
        />
        <Button type="link" className="notification-link" action={() => markNotification(id)}>
          <Text path="notifications.MARK_AS_READ" />
        </Button>
      </Paragraph>
    </Fragment>
  );
};

ExceptionsNotification.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    typeId: PropTypes.string.isRequired,
    isRead: PropTypes.bool.isRequired,
    content: PropTypes.shape({
      count: PropTypes.string,
      error: PropTypes.string,
    }).isRequired,
    createdDate: PropTypes.string.isRequired,
  }).isRequired,
  markNotification: PropTypes.func.isRequired,
};

export default ExceptionsNotification;
