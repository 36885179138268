import styled, { css } from 'styled-components';
import { OVERALL_STATUSSES } from '../../../common/helpers';

const StyledNotificationsItem = styled.li`
  ${props => {
    const { status } = props;
    const { palette, typography, spacing } = props.theme;

    let color = '';
    switch (status) {
      case OVERALL_STATUSSES.success: {
        color = palette.validation.valid;
        break;
      }
      case OVERALL_STATUSSES.failed: {
        color = palette.validation.error;
        break;
      }
      default: {
        break;
      }
    }

    return css`
      position: relative;
      .notification-content {
        font-size: ${typography.size.table};
        margin-bottom: 0;
        line-height: ${typography.lineHeight.paragraphSmall};
        word-wrap: break-word;
        strong {
          word-break: break-word;
        }
      }

      .notification-status-vl {
        position: absolute;
        top: 0px;
        bottom: 1px;
        left: 0px;
        width: 3px;
        background-color: ${color};
      }

      .notification-link {
        display: block;
        margin-top: ${spacing.tiny};
        margin-left: 0;
        text-align: left;
        font-weight: ${typography.weight.bold};
      }
    `;
  }};
`;

StyledNotificationsItem.displayName = 'StyledNotificationsItem';
export default StyledNotificationsItem;
