import * as R from 'ramda';

const COLUMN_ID_MAPPINGS = {
  1: 'basePay',
  2: 'fixedPay',
  3: 'targetBonus',
  4: 'lti',
  5: 'benefits',
  null: 'notIncluded',
};

export const mapPayloadToCategoryMappings = payload =>
  R.reduce(
    (acc, item) => {
      const { categoryId, subcategoryId, typeId, mappingId, selectedCategory } = item;
      const key = `${categoryId}/${subcategoryId}/${typeId}`;
      acc[key] = {
        mappingId,
        mappingName: selectedCategory,
        columnId: COLUMN_ID_MAPPINGS[mappingId],
      };
      return acc;
    },
    {},
    payload
  );

export const mapPayloadToTableStructure = payload =>
  R.reduce(
    (acc, item) => {
      const { category, subcategory } = item;
      const path = [category, subcategory];
      const existingTypes = R.pathOr([], path, acc);
      // eslint-disable-next-line no-param-reassign
      acc = R.assocPath(path, [...existingTypes, item], acc);
      return acc;
    },
    {},
    payload
  );
