import Big from 'big.js';
import * as R from 'ramda';
import { GLOBAL_KEYWORD, GENERAL_STATUSES_KEYS } from '@trs/config';
import { formatNumber, trimDecimals, sortListAscendingByProp } from '@trs/utils';
import { DEFAULT_TOTAL_DISTRIBUTION } from '../constants';

export const getSaveBannerContent = ({ basicInformation, fixedCostId, labels, genericLabels }) => {
  return (fixedCostId ? genericLabels.BANNER_UPDATE : genericLabels.BANNER_CREATE).replace(
    '%FIELD%',
    `${labels.PAGE_GROUP} ${basicInformation.displayName}`
  );
};

export const foundCountryGlobal = (countries) => {
  return countries.some((el) => el.code === GLOBAL_KEYWORD);
};

export const hasGlobalAccess = (authCountries) => {
  return authCountries.some((el) => el === GLOBAL_KEYWORD);
};

export const isCalculating = (status) => status === GENERAL_STATUSES_KEYS.STATUS_CALCULATING;

export const hasLocalAccess = (authCountries = [], fixedCostCountries = []) => {
  if (
    !hasGlobalAccess(authCountries) &&
    ((R.equals(fixedCostCountries.length, 1) &&
      !R.includes(fixedCostCountries[0], authCountries)) ||
      R.difference(fixedCostCountries, authCountries).length > 0)
  )
    return false;

  return true;
};

export const getServerErrorLabel = (field, labels) => R.prop(field, labels);

export const getFilteredVariablesCountryCodes = (resourceCountries) => {
  if (!foundCountryGlobal(resourceCountries)) {
    if (R.equals(resourceCountries.length, 1)) {
      return resourceCountries[0].code;
    }

    return R.join(
      '|',
      R.map((country) => {
        return country.code;
      }, resourceCountries)
    );
  }

  return GLOBAL_KEYWORD;
};

export const getFilteredCountries = (countries, selectedCountries) => {
  if (foundCountryGlobal(selectedCountries)) {
    return countries.filter((country) => {
      return country.code === GLOBAL_KEYWORD;
    });
  }

  let filteredCountries = R.reject(R.propEq('code', GLOBAL_KEYWORD), countries);
  R.forEach((val) => {
    filteredCountries = R.reject(R.propEq('code', val.code), filteredCountries);
  }, selectedCountries);

  return filteredCountries;
};

export const filterCountriesByType = (countries) => {
  if (foundCountryGlobal(countries)) {
    return R.reject(R.propEq('code', GLOBAL_KEYWORD), countries);
  }
  return countries;
};

export const getCostDistributionPerItem = (items) => {
  const valuePerItem = DEFAULT_TOTAL_DISTRIBUTION / items.length;
  return trimDecimals(formatNumber(valuePerItem));
};

/** compute total with decimal arithmetics **/
export const computeTotal = (payload) => {
  const totalResult = payload.reduce((result, entry) => {
    /** when only decimal separator is present **/
    const distributionValue = Number.isNaN(Number(entry.distribution))
      ? 0
      : Number(entry.distribution);

    return Big(result).plus(Big(distributionValue)).toString();
  }, 0);

  return Number(totalResult);
};

export const mapCustomPercentageDistributionByCountry = (countries) => {
  return R.map(({ code, label, value }) => {
    return {
      id: code,
      code,
      label,
      country: value,
      distribution: Number(getCostDistributionPerItem(countries)),
    };
  }, countries);
};

export const mapCustomDistributionTableConfig = ({ countries, isAutomatic }) => {
  const mappedRows = mapCustomPercentageDistributionByCountry(
    filterCountriesByType(sortListAscendingByProp('value', countries))
  );

  return {
    rows: mappedRows,
    total: isAutomatic ? DEFAULT_TOTAL_DISTRIBUTION : computeTotal(mappedRows),
  };
};

export default getFilteredVariablesCountryCodes;
