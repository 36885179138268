import * as actions from '../actions/types';
import initialState from './initialState';

export default function customisationReducer(state = initialState, action) {
  switch (action.type) {
    case actions.TOGGLE_CUSTOMISATION_WIDGET: {
      const { isCustomiserOpen } = state;

      return {
        ...state,
        isCustomiserOpen: !isCustomiserOpen,
      };
    }

    case actions.OPEN_CUSTOMISATION_WIDGET: {
      return {
        ...state,
        isCustomiserOpen: true,
      };
    }

    default:
      return state;
  }
}
