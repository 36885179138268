import styled, { css } from 'styled-components';

const StyledNotificationsWidget = styled.div`
  ${props => {
    const {
      palette,
      spacing,
      typography,
      extra: { boxShadow },
    } = props.theme;

    return css`
      box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.1);
      width: 320px;
      height: 100%;
      position: fixed;
      right: 0;
      top: 0;
      background-color: ${palette.basic.white};
      color: ${palette.gray.alpha};
      z-index: 9;

      .widget-title {
        display: flex;
        font-weight: ${typography.weight.thick};
        text-transform: uppercase;
        justify-content: space-between;
        align-items: center;
        padding: ${spacing.base} ${spacing.small};

        h6 {
          margin: 0;
        }
      }
      .widget-close {
        cursor: pointer;
        padding: 5px;
        color: ${palette.gray.silver};

        &:hover {
          color: ${palette.primary};
        }
      }
      .widget-content {
        list-style-type: none;
        margin: 0;
        padding: 0;
        height: calc(100% - 75px);
        overflow-x: auto;
        font-size: ${typography.size.table};
        .widget-item {
          padding: ${spacing.small};
          border-bottom: 1px solid ${palette.gray.charlie};
          &.active {
            background: rgba(165, 165, 165, 0.27);
            box-shadow: ${boxShadow};

            &:hover {
              background: rgba(165, 165, 165, 0.43);

              -moz-box-shadow: ${boxShadow}, inset 0 0 10px rgba(165, 165, 165, 0.89);
              -webkit-box-shadow: ${boxShadow}, inset 0 0 10px rgba(165, 165, 165, 0.89);
              box-shadow: ${boxShadow}, inset 0 0 10px rgba(165, 165, 165, 0.89);
            }
          }
          &:not(.active) {
            &:hover {
              background: rgba(165, 165, 165, 0.04);
              -moz-box-shadow: ${boxShadow}, inset 0 0 10px rgba(165, 165, 165, 0.69);
              -webkit-box-shadow: ${boxShadow}, inset 0 0 10px rgba(165, 165, 165, 0.69);
              box-shadow: ${boxShadow}, inset 0 0 10px rgba(165, 165, 165, 0.69);
            }
          }
        }
        a {
          color: ${palette.secondary};
          font-weight: ${typography.weight.bold};

          &:not(.notification-link) {
            margin-left: 5px;
          }
        }
      }
    `;
  }};
`;

StyledNotificationsWidget.displayName = 'StyledNotificationsWidget';

export default StyledNotificationsWidget;
