"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setRoundFieldProperties = exports.renderFunctionLabel = void 0;
var R = _interopRequireWildcard(require("ramda"));
var _utils = require("@trs/utils");
var _constants = require("../constants");
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var setRoundFieldProperties = function setRoundFieldProperties(_ref) {
  var member = _ref.member,
    labels = _ref.labels;
  if (!(0, _utils.isBlank)(member.dataType) && R.equals(member.dataType, _constants.DATA_TYPES.NUMERIC)) {
    return {
      placeholder: labels.FORMULA_INPUT_DECIMALS_PLACEHOLDER,
      allowSearch: false,
      allowPercentageToggler: false,
      inputType: 'number',
      searchableAction: _utils.noop,
      maxLength: _constants.NUMBER_OF_DECIMALS_MAX_LENGTH
    };
  }
  return {};
};
exports.setRoundFieldProperties = setRoundFieldProperties;
var renderFunctionLabel = function renderFunctionLabel(_ref2) {
  var value = _ref2.value,
    labels = _ref2.labels;
  switch (value) {
    case _constants.FUNCTION_MEMBER_TYPES.MAX:
      return labels.FORMULA_MAX_FUNCTION_LABEL;
    case _constants.FUNCTION_MEMBER_TYPES.MIN:
      return labels.FORMULA_MIN_FUNCTION_LABEL;
    case _constants.FUNCTION_MEMBER_TYPES.ROUND:
      return labels.FORMULA_ROUND_FUNCTION_LABEL;
    case _constants.FUNCTION_MEMBER_TYPES.ROUND_UP:
      return labels.FORMULA_ROUND_UP_FUNCTION_LABEL;
    case _constants.FUNCTION_MEMBER_TYPES.ROUND_DOWN:
      return labels.FORMULA_ROUND_DOWN_FUNCTION_LABEL;
    default:
      return value;
  }
};
exports.renderFunctionLabel = renderFunctionLabel;