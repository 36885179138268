export default {
  DIALOG_LEAVE_TITLE: 'Are you sure you want to leave this page?',
  DIALOG_EXIT_TITLE: 'Save changes before exiting?',
  DIALOG_RESET_TITLE: 'Are you sure you want to revert?',
  DIALOG_CANCEL_TITLE: 'Are you sure you want to cancel?',
  DIALOG_LEAVE_SUBTITLE:
    'Your changes have not been saved. If you leave this page all the unsaved information will be lost.',
  DIALOG_SAVE_CHANGES_TITLE: 'Save changes?',
  DIALOG_SAVE_SUBTITLE: "Your changes will be lost if you don't save them.",
  DIALOG_DELETE_TITLE: 'Are you sure you want to delete?',
  DIALOG_DELETE_SUBTITLE:
    'Please make sure you delete the correct %FIELD%. The delete action is irreversible.',
};
