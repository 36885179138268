import rulesBuilderGeneric from './rulesBuilderGeneric';
import basicInfo from './basicInfo';
import dialog from './dialog';
import eligibility from './eligibility';
import formula from './formula';
import pension from './pension';
import share from './share';
import percentage from './percentage';
import versioning from './versioning';
import stepper from './stepper';
import linkedRewards from './linkedRewards';
import description from './description';

import { rewardsExceptions } from '../exceptions';

export const rewardsLabels = {
  PAGE_TITLE: 'Reward List',
  NEW_BUTTON: 'New Reward',
  DISPLAY_NAME: 'name',
  COUNTRY: 'Country',
  CURRENCY: 'Currency',
  CATEGORY: 'Category',
  SUBCATEGORY: 'Subcategory',
  EFFECTIVE_DATE: 'Effective Date',
  EXPIRY_DATE: 'Expiry Date',
  MENU_LIST_EDIT: 'Edit',
  MENU_LIST_DUPLICATE: 'Duplicate reward',
  MENU_LIST_DELETE: 'Delete reward',
  NO_REWARDS: "There aren't any Rewards in the system. You can start by adding a reward.",
  FILTER_TITLE: 'Filtering options',
  NEVER_LABEL: 'Never',
  EMPLOYEE_SATISFACTION: 'Employee satisfaction',
  VIEW_FEEDBACK: 'View feedback',
};

export const newRewardLabels = {
  ...rulesBuilderGeneric,
  ...basicInfo,
  ...dialog,
  ...eligibility,
  ...formula,
  ...pension,
  ...share,
  ...versioning,
  ...stepper,
  ...percentage,
  ...linkedRewards,
  ...description,
  PAGE_GROUP: 'Reward',
  ADD_REWARD_PAGE_TITLE: 'New Reward',
  EDIT_REWARD_PAGE_TITLE: 'Edit Reward',
  STATUS_LABEL: 'Status',
  TAB_SETTINGS: 'Settings',
  SETTINGS_LIST_EMPTY: 'No Settings found',
  PREVIEW_BUTTON_BUILD_LABEL: 'Build',
  PREVIEW_BUTTON_PREVIEW_LABEL: 'Preview',
  EDIT_BUTTON: 'Edit',
  VIEW_BUTTON: 'View',
  REWARDS_PUBLISH_DENIED: 'You cannot publish this reward at this time, please try again later.',
  EMPLOYEE_FEEDBACK_WIDGET: 'Employee feedback',
  EMPLOYEE_FEEDBACK_WITH_STATUS: `<strong>%NO%</strong> of the employees are satisfied with their rewards!`,
  EMPLOYEE_FEEDBACK_WITHOUT_STATUS:
    '<strong>None</strong> of the employees gave any feedback until now.',
  EMPLOYEE_FEEDBACK_LINK: 'View feedback',
  SEARCHING_LABEL: 'Searching..',
  NO_RESULTS_FOUND_LABEL: 'No results found',
};

export const userEligibility = {
  AFFECTED_POPULATION_IS_FORBIDDEN: 'Your role permissions do not allow updating this reward.',
  EMPTY_ELIGIBILITY_FORBIDDEN:
    'Your role permissions do not allow saving rewards with no eligibility.',
};

export default {
  ...rewardsLabels,
  ...newRewardLabels,
  ...rewardsExceptions,
  ...userEligibility,
};
