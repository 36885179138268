import { v4 as uuid } from 'uuid';

export const ROUTES = {
  MAIN: '/report',
  COUNTRY_REWARDS_REPORT: '/report/country-rewards',
  EXECUTIVE_REPORT: '/report/executive-report',
  FLEX_REPORT: '/report/flex/:reportId',
};
export const ROUTE_KEYS = {
  MAIN: 'report',
  COUNTRY_REWARDS_REPORT: uuid(),
  EXECUTIVE_REPORT: 'executive-report',
  FLEX_REPORT: 'flex-report',
};
export const SORT_BY_EMPLOYEE_ID = 'EmployeeId';
export const DEFAULT_SORT = 'displayName';
export const REGEX = /[A-Z][a-z]+|[0-9]+/g;
export const REPORT_CELL_PROPS = {
  'Country Rewards': {
    EmployeeId: {
      maxWidth: 280,
    },
    RewardName: {
      maxWidth: 180,
    },
    RewardValue: {
      maxWidth: 190,
    },
    Currency: {
      maxWidth: 140,
    },
    IsShownToEmployee: {
      maxWidth: 290,
    },
    WorkLevel: {
      maxWidth: 170,
    },
    Category1Description: {
      maxWidth: 290,
    },
    CompensationGrade: {
      maxWidth: 240,
    },
    CountryOfEmployment: {
      maxWidth: 290,
    },
    EmployeeType: {
      maxWidth: 180,
    },
    SiteName: {
      maxWidth: 170,
    },
  },
};
