export default {
  SHARES_PAGE_GROUP: 'Shares',
  SHARES_PAGE_GROUP_SINGULAR: 'Share',
  SHARES_PAGE_TITLE: 'My shares',
  SHARES_PROVIDER_CAPTION: 'To view more details about the plans, please visit %PROVIDER%',
  PROFILE_VESTED_SHARES: 'Vested shares',
  PROFILE_UNVESTED_SHARES: 'Unvested shares',
  PROFILE_FIND_OUT_MORE: 'Find out more',
  GRANTED_SHARES_TITLE: 'Granted shares in',
  GRANTED_SHARES_CAPTION:
    'The values of the shares reflect the Stock Exchange end of day prices. The value of the granted shares reflects the value of the shares at granted date.',
  GRANTED_SHARES_TOTAL: 'Total',
  SHARES_SUMMARY_CAPTION: 'The amounts displayed are rounded down.',
  SHARES_PRICE_LABEL_TITLE: 'Price last updated',
};
