import styled, { css } from 'styled-components';
import { media, setColorAlphaChannel } from '@trs/utils';

const StyledBanners = styled.div`
  ${props => {
    const { palette: color } = props.theme;

    return css`
      padding: 0;
      .Toastify__toast-container {
        width: 100%;
        left: 0;
        ${media.tablet`
          width:500px;
          left: auto;
        `}
      }
      .Toastify__toast {
        background: transparent;
        padding: 0;
        .Toastify__progress-bar {
          background: ${setColorAlphaChannel(color.basic.black, 0.2)};
        }
      }
      .notification-banner {
        margin: 0;
        align-items: flex-start;
        .banner-message-container {
          word-break: break-word;
        }
      }
    `;
  }}
`;

StyledBanners.displayName = 'StyledBanners';
export default StyledBanners;
