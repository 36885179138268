import * as R from 'ramda';
import { v4 as uuid } from 'uuid';
import { handleActions, combineActions } from 'redux-actions';
import { isBlank } from '@trs/utils';
import initialState from './initialState';
import {
  processPopulationChange,
  mapEligibilityOperators,
  mapSystemVariables,
  getNewPopulation,
  getLookupData,
} from '../helpers';

import {
  getEligibilityOperators,
  getSystemVariables,
  populationChange,
  addPopulation,
  getVariablesLookupData,
  duplicatePopulation,
  deletePopulation,
  saveActions,
  getPopulationData,
  initPopulations,
  loadPayScale,
  updatePayScaleField,
  initPayScaleIntervals,
  addPayScaleInterval,
  duplicatePayScaleInterval,
  deletePayScaleInterval,
  updatePopulationError,
} from '../actions';

const DEFAULT_PAYSCALE_INTERVALS = {
  min: 80,
  max: 125,
};

const saveActionHandler = [
  [saveActions.pending, (state) => ({ ...state, savingPopulation: true })],
  [
    saveActions.success,
    (state, action) => ({ ...state, savingPopulation: false, ...action.payload }),
  ],
  [
    saveActions.error,
    (state, action) => ({ ...state, saveError: action.payload, savingPopulation: false }),
  ],
];

const reducer = handleActions(
  new Map([
    [
      initPopulations,
      (state, action) => {
        const { id } = action.payload;
        return {
          ...state,
          populations: [
            {
              key: id,
              id,
              name: `Population 1`,
              eligibility: {
                eligibilityRows: [
                  {
                    variable: '',
                    operator: '',
                    values: [],
                    uid: uuid(),
                  },
                ],
                uid: uuid(),
              },
            },
          ],
        };
      },
    ],
    [
      addPopulation,
      (state, action) => {
        const populations = R.clone(state.populations);
        const currentPopulationCount = R.length(populations);
        const { id } = R.prop('payload', action);

        return {
          ...state,
          populations: R.insert(
            currentPopulationCount,
            getNewPopulation(id, currentPopulationCount),
            populations
          ),
        };
      },
    ],
    [
      duplicatePopulation,
      (state, action) => {
        const populations = R.clone(state.populations);

        const { id, index, population } = R.prop('payload', action);
        const duplicatedPopulation = {
          ...population,
          key: id,
          id,
        };

        return {
          ...state,
          populations: R.insert(index + 1, duplicatedPopulation, populations),
        };
      },
    ],
    [
      deletePopulation,
      (state, action) => ({
        ...state,
        populations: R.remove(R.path(['payload', 'index'], action), 1, R.clone(state.populations)),
      }),
    ],
    [
      populationChange,
      (state, action) => ({
        ...state,
        populations: processPopulationChange(R.clone(state.populations), R.prop('payload', action)),
      }),
    ],
    [
      getEligibilityOperators,
      (state, action) => ({
        ...state,
        eligibilityOperators: mapEligibilityOperators(R.prop('payload', action)),
      }),
    ],
    [
      getPopulationData,
      (state, action) => ({
        ...state,
        ...action.payload,
        populations: R.path(['payload', 'eligibilityRules'], action),
      }),
    ],
    [
      getSystemVariables,
      (state, action) => ({
        ...state,
        systemVariables: mapSystemVariables(R.prop('payload', action)),
      }),
    ],
    [
      getVariablesLookupData,
      (state, action) => {
        const { lookupData, url } = action.payload;

        return {
          ...state,
          lookUpValues: {
            ...state.lookUpValues,
            ...getLookupData(lookupData, url),
          },
        };
      },
    ],
    [
      updatePayScaleField,
      (state, action) => {
        const { key, value, populationId } = action.payload;
        const { payScaleIntervals } = state;

        if (isBlank(R.prop(populationId, payScaleIntervals))) {
          payScaleIntervals[populationId] = {};
        }

        return {
          ...state,
          payScaleIntervals: R.assocPath([populationId, key], value, payScaleIntervals),
        };
      },
    ],
    [
      loadPayScale,
      (state, action) => {
        const { payload } = action;
        return {
          ...state,
          payScaleIntervals: payload,
        };
      },
    ],
    [
      [combineActions(initPayScaleIntervals, addPayScaleInterval)],
      (state, action) => ({
        ...state,
        payScaleIntervals: {
          ...state.payScaleIntervals,
          [R.path(['payload', 'id'], action)]: { ...DEFAULT_PAYSCALE_INTERVALS },
        },
      }),
    ],
    [
      duplicatePayScaleInterval,
      (state, action) => {
        const payScaleIntervals = R.clone(state.payScaleIntervals);

        const { id, index, populationId } = R.prop('payload', action);

        const payScaleIntervalsArray = R.toPairs(payScaleIntervals);
        const payScaleClone = [id, R.path(['payScaleIntervals', populationId], state)];
        const pairs = R.insert(index + 1, payScaleClone, payScaleIntervalsArray);

        return {
          ...state,
          payScaleIntervals: R.fromPairs(pairs),
        };
      },
    ],
    [
      deletePayScaleInterval,
      (state, action) => ({
        ...state,
        payScaleIntervals: R.dissoc(
          R.path(['payload', 'id'], action),
          R.prop('payScaleIntervals', state)
        ),
      }),
    ],
    [
      updatePayScaleField,
      (state, action) => {
        const { key, value, populationId } = action.payload;
        const { payScaleIntervals } = state;
        if (isBlank(R.prop(populationId, payScaleIntervals))) {
          payScaleIntervals[populationId] = {};
        }

        return {
          ...state,
          payScaleIntervals: R.assocPath([populationId, key], value, payScaleIntervals),
        };
      },
    ],
    [
      updatePopulationError,
      (state, action) => {
        const newState = state;
        return R.assocPath(['errors', ...action.payload.path], action.payload.data, newState);
      },
    ],
    ...saveActionHandler,
  ]),
  R.clone(initialState)
);

export default reducer;
