import userModules from '../userModules';
import { TextOnly } from '../../../modules/common/Text';
import { matchRoles } from '../../../modules/common/helpers';

const rewardManager = ({ authorizedModules }) => [
  {
    title: TextOnly({ path: 'header.REWARDS_MANAGEMENT' }),
    key: [
      userModules.REWARDS_MANAGEMENT,
      userModules.EXCEPTIONS_MANAGEMENT,
      userModules.BENEFITS_ENVELOPE,
      userModules.FLEXIBLE_MANAGEMENT,
      userModules.FX_MANAGEMENT,
      userModules.ARTICLES_MANAGEMENT,
      userModules.FIXED_COSTS_MANAGEMENT,
      userModules.LTI_PLANS_MANAGEMENT,
    ],
    items: [
      {
        title: TextOnly({ path: 'header.REWARDS' }),
        key: [userModules.REWARDS_MANAGEMENT],
        items: [],
        route: '/rewards',
      },
      {
        title: TextOnly({ path: 'header.VARIABLES' }),
        key: [userModules.REWARDS_MANAGEMENT],
        items: [],
        route: '/variables',
      },
      {
        title: TextOnly({ path: 'header.EXCEPTIONS' }),
        key: [userModules.EXCEPTIONS_MANAGEMENT],
        items: [],
        route: '/exceptions',
      },
      {
        title: TextOnly({ path: 'header.FIXED_COSTS' }),
        key: [userModules.FIXED_COSTS_MANAGEMENT],
        items: [],
        route: '/fixed-costs',
      },
      {
        title: TextOnly({ path: 'header.FOREIGN_EXCHANGE' }),
        key: [userModules.FX_MANAGEMENT],
        items: [],
        route: '/foreign-exchange',
      },
      {
        title: TextOnly({ path: 'header.LTI_PLANS' }),
        key: [userModules.LTI_PLANS_MANAGEMENT],
        items: [],
        route: '/lti-plans',
      },
      {
        title: TextOnly({ path: 'header.ARTICLES_MANAGEMENT' }),
        key: [userModules.ARTICLES_MANAGEMENT],
        items: [],
        route: '/articles',
      },
      {
        title: TextOnly({ path: 'header.FLEX_REWARDS' }),
        key: [userModules.FLEXIBLE_MANAGEMENT],
        items: [
          {
            title: TextOnly({ path: 'header.FLEX_PACKAGES' }),
            key: [userModules.FLEXIBLE_MANAGEMENT],
            items: [],
            route: '/flexible-rewards',
          },
          {
            title: TextOnly({ path: 'header.BENEFITS_ENVELOPE' }),
            key: [userModules.BENEFITS_ENVELOPE],
            items: [],
            route: '/benefits-envelope',
            hidden: !matchRoles([userModules.BENEFITS_ENVELOPE], authorizedModules),
          },
          {
            title: TextOnly({ path: 'header.ENROLLMENT_WINDOW' }),
            key: [userModules.FLEXIBLE_MANAGEMENT],
            items: [],
            route: '/flexible-rewards/enrolment-window',
          },
        ],
        route: '/rewards',
      },
    ],
    route: '/rewards',
  },
];

export default rewardManager;
