"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
var _TabHeader = _interopRequireDefault(require("./TabHeader.style"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var StyledSplashContainer = (0, _styledComponents["default"])(_TabHeader["default"]).withConfig({
  displayName: "StyledSplashContainerstyle__StyledSplashContainer",
  componentId: "sc-1lqurb8-0"
})(["", ""], function (props) {
  var _props$theme = props.theme,
    typography = _props$theme.typography,
    color = _props$theme.palette,
    spacing = _props$theme.spacing,
    genericBorderRadius = _props$theme.extra.genericBorderRadius;
  return (0, _styledComponents.css)(["display:flex;height:88px;width:100%;margin-bottom:", ";border:solid 1px ", ";border-radius:", ";> div{flex:1;&:first-child .tab-element{border-radius:", " 0 0 ", ";}&:last-child .tab-element{border-radius:0 ", " ", " 0;}&:not(:first-child) .tab-element{&:before{content:'';position:absolute;left:-", ";top:0;z-index:0;width:30px;height:100%;background-color:white;transform:skew(-15deg);border-left:1px solid ", ";transition:all 0.2s ease-in-out;}&.selected:before{content:'';background-color:", ";}}}&&{.tab-wrapper{.tab-element{border-bottom:0;position:relative;justify-content:center;align-items:center;text-transform:capitalize;background-color:", ";font-weight:", ";&.allowedNavigation{color:", ";cursor:pointer;transition:all 0.2s ease-in-out;&:hover{color:", ";border-bottom-color:", ";}}&.selected{color:", ";background-color:", ";pointer-events:none;.wizzard-step{color:", ";}}> span{font-size:", ";line-height:", ";}.wizzard-step{background-color:transparent;font-size:", ";color:", ";line-height:", ";display:flex;opacity:0.2;align-items:center;justify-content:center;margin-left:-", ";font-weight:bold;}}}}"], spacing.xxxBase, color.accent.tau, genericBorderRadius, genericBorderRadius, genericBorderRadius, genericBorderRadius, genericBorderRadius, spacing.small, color.accent.tau, color.secondary, color.basic.white, typography.weight.bold, color.gray.solo, color.gray.bravo, color.gray.bravo, color.basic.white, color.secondary, color.basic.white, typography.size.h4, typography.lineHeight.regular, typography.size.h1, color.gray.solo, typography.lineHeight.regular, spacing.xxxBase);
});
StyledSplashContainer.displayName = 'StyledSplashContainer';
var _default = StyledSplashContainer;
exports["default"] = _default;