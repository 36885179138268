import * as R from 'ramda';
import { upperFirstLetter, isBlank, rejectEmptyValues } from '@trs/utils';
import { TextOnly } from 'modules/common/Text';
import { getError } from 'config/errors';

export const validateEligibilitySimplePopulation = populationObject =>
  R.mapObjIndexed((item, key) => {
    let keyLabel;
    switch (key) {
      case 'variable':
        keyLabel = TextOnly({ path: 'rewards.ELIGIBILITY_RULE_VARIABLE_NAME' });
        break;
      case 'operator':
        keyLabel = TextOnly({ path: 'rewards.ELIGIBILITY_RULE_OPERATOR' });
        break;
      case 'values':
        keyLabel = TextOnly({ path: 'rewards.ELIGIBILITY_RULE_VALUE' });
        break;
      default:
        keyLabel = key;
        break;
    }

    return getError(TextOnly({ path: 'generic.ERROR_REQUIRED' }), upperFirstLetter(keyLabel));
  }, R.filter(R.isEmpty, populationObject));

export const getEligibilityErrors = (eligibility, errors = {}) => {
  const errorObject = errors;
  if (eligibility.eligibilityRows) {
    R.forEach(
      eligibilityRow => getEligibilityErrors(eligibilityRow, errorObject),
      eligibility.eligibilityRows
    );
  }

  if (!isBlank(R.prop('eligibilityRowsOperator', eligibility))) {
    return errorObject;
  }

  const populationErrors = validateEligibilitySimplePopulation({
    variable: R.prop('variable', eligibility),
    operator: R.prop('operator', eligibility),
    values: R.prop('values', eligibility),
  });

  if (!isBlank(populationErrors)) {
    errorObject[eligibility.uid] = populationErrors;
  }

  return errorObject;
};

export const validatePopulation = population => {
  const { eligibility } = population;

  const errors = getEligibilityErrors(eligibility);
  return isBlank(rejectEmptyValues(R.values(errors))) ? {} : errors;
};
