import * as actions from './actionTypes';
import { createBanner, removeAll } from '../modules/common/Banners/Banners';

export function showDialog(options = {}) {
  return {
    type: actions.SHOW_DIALOG,
    options,
  };
}

export function hideDialog() {
  return {
    type: actions.HIDE_DIALOG,
  };
}

export function showBanner(options = {}) {
  createBanner(options);
  return {
    type: actions.ADD_BANNER,
  };
}

export function removeBanner(bannerId) {
  return {
    bannerId,
    type: actions.REMOVE_BANNER,
  };
}

export function removeAllBanners() {
  removeAll();
  return {
    type: actions.REMOVE_ALL_BANNERS,
  };
}

export function setCookiePolicy(key, value) {
  sessionStorage.setItem(key, value);

  return {
    type: actions.SET_COOKIE_POLICY,
    approvedCookiePolicy: value,
  };
}
