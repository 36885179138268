/* eslint-disable max-lines-per-function */
import * as R from 'ramda';
import { GLOBAL_KEYWORD } from '@trs/config';
import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import initialState from './initialState';
import * as actions from '../actions/types';
import { mapColumns, mapRows, FR_STATUS } from '../routes/utils';
import modules from '../../../config/userManager/userModules';
import { filterCountriesByAccess } from '../../common/helpers';
import executiveReport from '../components/executiveReport/reducer';
import getReportUserPermissions, {
  getReportsCategoriesMapping,
  changeCategoriesMapping,
  resetCategoriesMapping,
  submitActions,
} from '../actions';
import { mapPayloadToTableStructure } from '../../benchmarking/components/global/components/dashboard/components/mapCategories/reducer/helpers';
import mapReportPayloadToCategoryMappings, { INCLUDED } from './helpers';

function main(state = initialState, action = {}) {
  switch (action.type) {
    case actions.GET_REPORTS_PENDING: {
      return {
        ...state,
        isFetchingReports: true,
      };
    }
    case actions.TRIGGER_COUNTRY_REWARDS_EXPORT: {
      return {
        ...state,
        isExportingCountryRewardsReport: !action.ready,
      };
    }
    case actions.GET_REPORTS_SUCCESS: {
      return {
        ...state,
        reports: R.path(['payload'], action),
        report: initialState.report,
        isFetchingReports: false,
      };
    }
    case actions.GET_REPORTS_FAILURE: {
      return {
        ...state,
        isFetchingReports: false,
      };
    }
    case actions.GET_REPORT_PENDING: {
      return {
        ...state,
        report: {
          ...state.report,
          fetchingList: true,
        },
      };
    }
    case actions.GET_REPORT_SUCCESS: {
      const columns = R.path(['payload', 'definition', 'columns'], action);
      const rows = R.path(['payload', 'rows'], action);
      return {
        ...state,
        report: {
          name: R.path(['payload', 'definition', 'name'], action),
          list: mapRows(columns, rows),
          totalResults: R.path(['payload', 'totalCount'], action),
          filterOptions: [],
          fetchingList: false,
          columnDefinition: mapColumns(columns, R.path(['payload', 'cmsLabels'], action)),
        },
      };
    }
    case actions.UPDATE_COUNTRIES: {
      return {
        ...state,
        exportCountries: R.reject(
          country => R.equals(R.path(['value'], country), GLOBAL_KEYWORD),
          filterCountriesByAccess({
            countries: R.path(['payload', 'countries'], action),
            authorizedModules: R.path(['payload', 'authorizedModules'], action),
            module: modules.REPORTS,
          })
        ),
      };
    }
    case actions.SELECT_COUNTRY: {
      return {
        ...state,
        selectedCountry: R.path(['payload'], action),
        report: {
          ...state.report,
          list: [],
          filterOptions: [],
          totalResults: 0,
          fetchingList: true,
        },
      };
    }
    case actions.CLEAR_REPORT_DATA: {
      return R.clone(initialState);
    }
    case actions.CHANGE_REPORT_DEFINITION: {
      return {
        ...state,
        newReport: {
          ...state.newReport,
          [action.payload.field]: action.payload.value,
        },
      };
    }
    case actions.CLEAR_REPORT_DEFINITION: {
      return {
        ...state,
        newReport: initialState.newReport,
      };
    }
    case actions.GET_EXTERNAL_REPORTS_PENDING: {
      return {
        ...state,
        isFetchingExternalReports: true,
      };
    }
    case actions.GET_EXTERNAL_REPORTS_SUCCESS: {
      return {
        ...state,
        externalReports: action.payload,
        isFetchingExternalReports: false,
      };
    }
    case actions.GET_EXTERNAL_REPORTS_FAILURE: {
      return {
        ...state,
        isFetchingExternalReports: false,
      };
    }
    case actions.GET_FLEX_REPORTS_PENDING: {
      return {
        ...state,
        isFetchingFlexReports: true,
      };
    }
    case actions.GET_FLEX_REPORTS_SUCCESS: {
      return {
        ...state,
        flexReports: action.payload,
        isFetchingFlexReports: false,
      };
    }
    case actions.GET_FLEX_REPORTS_FAILURE: {
      return {
        ...state,
        isFetchingFlexReports: false,
      };
    }
    case actions.GET_FLEX_EMBED_REPORTS_PENDING: {
      return {
        ...state,
        isLoadingFlexEmbedReport: true,
      };
    }
    case actions.GET_FLEX_EMBED_REPORTS_SUCCESS: {
      return {
        ...state,
        isLoadingFlexEmbedReport: false,
      };
    }
    case actions.GET_FLEX_EMBED_REPORTS_FAILURE: {
      return {
        ...state,
        isLoadingFlexEmbedReport: false,
      };
    }
    case actions.ADD_EXTERNAL_REPORT_PENDING: {
      return {
        ...state,
        isAddingExternalReport: true,
      };
    }
    case actions.ADD_EXTERNAL_REPORT_SUCCESS: {
      return {
        ...state,
        newReport: initialState.newReport,
        isAddingExternalReport: false,
      };
    }
    case actions.ADD_EXTERNAL_REPORT_FAILURE: {
      return {
        ...state,
        isAddingExternalReport: false,
      };
    }
    case actions.GET_REPORT_PERMISSIONS: {
      return {
        ...state,
        permissions: action.payload,
      };
    }
    case actions.SET_LAUNCHER_SCREEN_VISIBILITY: {
      return {
        ...state,
        isFlexReportPBIVisible: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}

const submitHandler = [
  [submitActions.pending, state => ({ ...state, isSubmitting: true })],
  [
    submitActions.success,
    state => ({
      ...state,
      isSubmitting: false,
      status: FR_STATUS.SUBMITTED,
      hasChanges: false,
    }),
  ],
  [submitActions.error, state => ({ ...state, isSubmitting: false })],
];

const configurationReducer = handleActions(
  new Map([
    [
      getReportsCategoriesMapping,
      (state, action) => {
        const { payload, ready, error } = action;

        if (!ready) {
          return {
            ...state,
            isLoading: true,
          };
        }

        if (error) {
          return {
            ...state,
            isLoading: false,
            categories: {},
            mappings: {},
          };
        }
        const { categoriesMappings, etag } = payload;

        return {
          ...state,
          hasChanges: false,
          isLoading: false,
          categories: mapPayloadToTableStructure(categoriesMappings),
          etag,
          status: FR_STATUS.NOT_SUBMITTED,
          mappings: mapReportPayloadToCategoryMappings(categoriesMappings),
        };
      },
    ],
    [
      changeCategoriesMapping,
      (state, { payload: { key, newMapping } }) => {
        return {
          ...state,
          hasChanges: true,
          status: FR_STATUS.NOT_SUBMITTED,
          mappings: {
            ...state.mappings,
            [key]: { ...newMapping, isIncluded: newMapping.columnId === INCLUDED },
          },
        };
      },
    ],
    [
      getReportUserPermissions,
      (state, action) => {
        const { payload } = action;
        return {
          ...state,
          payload,
        };
      },
    ],
    ...submitHandler,
    [resetCategoriesMapping, () => R.clone(initialState)],
  ]),
  R.clone(initialState)
);

export default combineReducers({
  main,
  executiveReport,
  configurationReducer,
});
