import { RECURRENCE_OPTIONS_TYPES } from 'modules/common/constants';
import APP_CONFIG from '@trs/config';

const {
  GENERAL_STATUSES_KEYS: { STATUS_LIVE, STATUS_PUBLISHED, STATUS_EXPIRED, STATUS_ERROR },
} = APP_CONFIG;

export const REVERT_STATUSES = [STATUS_LIVE, STATUS_PUBLISHED, STATUS_EXPIRED, STATUS_ERROR];

export const RECURRENCE_OPTIONS = [RECURRENCE_OPTIONS_TYPES.MONTHS, RECURRENCE_OPTIONS_TYPES.YEARS];

export const ERROR_CODES = {
  VALUE_IS_NEGATIVE: 'VALUE_IS_NEGATIVE',
  SUM_NOT_100: 'SUM_NOT_100',
  FIELD_IS_REQUIRED: 'FIELD_IS_REQUIRED',
};

export const DESCRIPTION_MAX_LENGTH = 500;
export const DISPLAY_NAME_FIELD_MAX_LENGTH = 100;
export const DISPLAY_NAME_FIELD_MIN_LENGTH = 3;

export const PERCENTAGE_DISTRIBUTION_MAX_LENGTH = 12;
export const DEFAULT_TOTAL_DISTRIBUTION = 100;

export const RESPONSE_DISTRIBUTION_TYPE = {
  AUTOMATIC: 1,
  MANUAL: 2,
};

export const DISTRIBUTION_TYPES = {
  AUTOMATIC: 'Automatic',
  MANUAL: 'Manual',
};

export const DETAILS_STATUS_LABELS = {
  status: 'fixedCosts.STATUS',
  startDate: 'fixedCosts.START_DATE',
  endDate: 'fixedCosts.END_DATE',
  currency: 'fixedCosts.CURRENCY',
  revertToDraft: 'fixedCosts.REVERT_TO_DRAFT',
};

export const ROUTING_MAP = ['basic-information', 'rules'];

export const DEFAULT_TABLE_COLUMN_IDS = {
  COUNTRY: 'country',
  DISTRIBUTION: 'distribution',
};
