"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
var R = _interopRequireWildcard(require("ramda"));
var _utils = require("@trs/utils");
var _H = _interopRequireDefault(require("../heading/H3.style"));
var _templateObject, _templateObject2;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
var StyledCardTitle = (0, _styledComponents["default"])(_H["default"]).withConfig({
  displayName: "CardTitlestyle__StyledCardTitle",
  componentId: "sc-1cstv9i-0"
})(["position:relative;margin:0;", ";"], function (props) {
  var _props$theme = props.theme,
    color = _props$theme.palette,
    spacing = _props$theme.spacing,
    typography = _props$theme.typography,
    delimiterTypes = _props$theme.extra.delimiterTypes,
    card = _props$theme.components.card;
  var titleProps = props.titleProps,
    delimiterProps = props.delimiterProps,
    borderColor = props.borderColor,
    withSubtitle = props.withSubtitle;
  var fontSize = titleProps.titleFontSize || typography.size.h4;
  var topMargin = delimiterProps.topMargin || spacing.tiny;
  var delimiterColor = R.path(['bottomBorder', 'color'], card) ? borderColor || card.bottomBorder.color : color.gray.charlie;
  var delimiterHeight = '';
  var delimiterWidth = '';
  if (delimiterProps.titleDelimiter) {
    delimiterHeight = delimiterTypes[delimiterProps.type].height;
    delimiterWidth = delimiterTypes[delimiterProps.type].width;
  }
  return (0, _styledComponents.css)(["color:", ";font-size:", ";text-transform:", ";margin-bottom:", ";", " ", " ", " ", " ", ";"], delimiterProps.titleDelimiter ? color.gray.alpha : color.primary, typography.size.paragraphPlus, titleProps.textTransform, spacing.small, _utils.media.tablet(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n        font-size: ", ";\n        margin-bottom: ", ";\n      "])), fontSize, withSubtitle ? spacing.tiny : spacing.base), _utils.media.desktop(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n      margin-bottom: ", ";\n    "])), spacing.xSmall), titleProps.isUppercase && (0, _styledComponents.css)(["text-transform:uppercase;"]), !titleProps.isBold && (0, _styledComponents.css)(["font-weight:400;"]), delimiterProps.titleDelimiter && (0, _styledComponents.css)(["&:after{height:", ";width:", ";background-color:", ";content:' ';margin-top:", ";clear:both;display:block;", " ", ";}"], delimiterHeight, delimiterWidth, delimiterColor, topMargin, delimiterProps.position === 'center' && (0, _styledComponents.css)(["margin-left:auto;margin-right:auto;"]), delimiterProps.position === 'right' && (0, _styledComponents.css)(["margin-left:calc(100% - ", ");"], delimiterWidth)));
});
StyledCardTitle.displayName = 'StyledCardTitle';
var _default = StyledCardTitle;
exports["default"] = _default;