import React, { Fragment } from 'react';
import { Dialog, H3, Paragraph, Button } from '@trs/components';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as globalActions from '../../../actions/globalActions';

const getDialogButtonLabels = (dialogData, genericLabel) => {
  if (R.prop('stayAndLeaveLabels', dialogData)) {
    return {
      confirmLabel: genericLabel.BUTTON_LEAVE,
      cancelLabel: genericLabel.BUTTON_STAY,
    };
  }

  if (R.prop('dialogButtonLabels', dialogData)) {
    return R.prop('dialogButtonLabels', dialogData);
  }

  return {
    confirmLabel: genericLabel.BUTTON_SAVE,
    cancelLabel: genericLabel.BUTTON_CANCEL,
  };
};

const GlobalDialog = ({ actions, dialogData, genericLabel }) => {
  const { confirmLabel, cancelLabel } = getDialogButtonLabels(dialogData, genericLabel);
  return dialogData.visible ? (
    <Dialog
      wrapperClassName={dialogData.wrapperClassName}
      className={dialogData.dialogClassName}
      width="850px"
      innerWidth={dialogData.innerWidth}
      onClose={dialogData.onClose}
      leftActions={
        dialogData.leftActions ? (
          dialogData.leftActions
        ) : (
          <Fragment>
            {dialogData.redirectPath && (
              <Link to={dialogData.redirectPath}>
                <Button action={actions.hideDialog} type="naked" id="globalDialogButtonNo">
                  {genericLabel.BUTTON_NO_SAVE}
                </Button>
              </Link>
            )}
            {dialogData.redirectAction && (
              <Button
                action={R.compose(dialogData.redirectAction, actions.hideDialog)}
                type="naked"
                className="globalDialogButtonNo"
              >
                {genericLabel.BUTTON_NO_SAVE}
              </Button>
            )}
          </Fragment>
        )
      }
      rightActions={
        dialogData.rightActions ? (
          dialogData.rightActions
        ) : (
          <Fragment>
            <Button action={actions.hideDialog} type="naked" id="globalDialogButtonCancel">
              {cancelLabel || genericLabel.BUTTON_CANCEL}
            </Button>
            <Button
              action={R.compose(actions.hideDialog, dialogData.confirm)}
              id="globalDialogButtonYes"
              disabled={dialogData.disableConfirm}
            >
              {confirmLabel || genericLabel.BUTTON_SAVE}
            </Button>
          </Fragment>
        )
      }
      closeTrigger={actions.hideDialog}
    >
      {dialogData.title && <H3 className="dialog-title">{dialogData.title}</H3>}
      {dialogData.subtitle && <Paragraph className="dialog-text">{dialogData.subtitle}</Paragraph>}
      {dialogData.body && <div className="dialog-body">{dialogData.body}</div>}
    </Dialog>
  ) : (
    ''
  );
};

GlobalDialog.propTypes = {
  actions: PropTypes.shape({
    hideDialog: PropTypes.func.isRequired,
  }).isRequired,
  dialogData: PropTypes.shape({
    wrapperClassName: PropTypes.string,
    dialogClassName: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    body: PropTypes.node,
    rightActions: PropTypes.node,
    leftActions: PropTypes.node,
    confirm: PropTypes.func,
    onClose: PropTypes.func,
    disableConfirm: PropTypes.bool,
    redirectPath: PropTypes.string,
    redirectAction: PropTypes.func,
    visible: PropTypes.bool,
    innerWidth: PropTypes.string,
  }).isRequired,
  genericLabel: PropTypes.shape({
    BUTTON_SAVE: PropTypes.string,
    BUTTON_CANCEL: PropTypes.string,
    BUTTON_NO_SAVE: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  dialogData: state.global.dialog,
  genericLabel: state.cms.generic.data,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(globalActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(GlobalDialog);
