export default {
  RADIO_GROUP_OPTION_REQUIRED: 'An option is required',
  CARD_MANDATORY_FIELDS_NOTE: 'Fields marked with * are mandatory',
  TABLE_SEARCH: 'Search in table',
  TABLE_FILTERS_TITLE: 'Filters',
  TABLE_SEARCH_RESULTS_FOUND:
    '%NUMBER% result(s) found. Click the filter button on the left-hand side of the table to narrow down your search.',
  TABLE_SEARCH_RESULTS_FOUND_NO_FILTERS: '%NUMBER% result(s) found.',
  TABLE_SEARCH_NO_RESULTS_FOUND: 'No results found. Please change your search criteria.',
  FILTER_BUTTON: 'Apply Filters',
  SEARCH_FORM_TITLE: 'Search employee',
  SEARCH_FORM_SUBTITLE: 'You can search an existing employee by employee name or ID',
  SEARCH_FORM_LABEL: 'Existing employee',
  LABEL_CATEGORY: 'Category',
  PLACEHOLDER_CATEGORY: 'Select a Category',
  LABEL_SUBCATEGORY: 'Subcategory',
  PLACEHOLDER_SUBCATEGORY: 'Select a Subcategory',
  LABEL_TYPE: 'Type',
  PLACEHOLDER_TYPE: 'Select a Type',
  PLACEHOLDER_ADD_PDF_NAME: 'Add PDF name',
  SEARCH_EXISTING_EMPLOYEE_PLACEHOLDER: 'Search for existing employee',
  SEARCHING_LABEL: 'Searching...',
  NO_RESULTS_FOUND_LABEL: 'No results found',
  WIDGET_VIEW_ALL: 'View all',
  LOADING_DATA: 'Loading your data, please wait...',
  REMOVE: 'Remove',
};
