import React from 'react';
import ReactDOM from 'react-dom';
import axe from 'react-axe';
import * as R from 'ramda';
import qs from 'qs';
import whyDidYouUpdate from '@welldone-software/why-did-you-render';
import { setConfiguration } from 'react-grid-system';
import APP_CONFIG from '@trs/config';
import userManager from './userManager/userManager';

const environment = process && process.env && process.env.NODE_ENV;
const renderDebug = process && process.env && process.env.REACT_APP_RENDER_DEBUG;
const axeDebug = process && process.env && process.env.REACT_APP_AXE_DEBUG;
const GUTTER_WIDTH = 24;

export const getTenantFromUrl = () => {
  if (window.location.pathname.includes('signin')) {
    return R.prop('tenant', qs.parse(window.location.search, { ignoreQueryPrefix: true }));
  }
  return '';
};

const getTabCounter = (tabHost) => parseInt(localStorage.getItem(tabHost), 10);

const setTabCounter = (tabHost, counter) => localStorage.setItem(tabHost, counter);

export const manageSession = () => {
  const {
    location: { host },
  } = window;

  const loginPages = ['/login', '/signin', '/callback'];
  const isLoginPage = loginPages.includes(window.location.pathname);

  window.onload = () => {
    const tabCounter = getTabCounter(host) || 0;

    setTabCounter(host, isLoginPage ? 1 : tabCounter + 1);
  };

  window.onbeforeunload = () => {
    const tabCounter = getTabCounter(host) || 0;
    setTabCounter(host, tabCounter - 1);

    if (tabCounter - 1 === 0) {
      userManager.removeUser();
      userManager.clearTRSData();
    }
  };
};

export const enableWhyDidYouUpdate = () => {
  if (environment === 'development' && renderDebug !== 'false') {
    window.R = R;
    whyDidYouUpdate(React);
  }
};

export const enableReactAxe = async () => {
  if (environment === 'development' && axeDebug !== 'false') {
    const config = {
      rules: [{ id: 'color-contrast', enabled: false }],
    };
    axe(React, ReactDOM, 1000, config);
  }
};

export const configureBreakpoints = () => {
  setConfiguration({
    breakpoints: R.map((item) => item.replace('px', ''), R.values(APP_CONFIG.breakpoints)),
    gutterWidth: GUTTER_WIDTH,
  });
};

export default {
  getTenantFromUrl,
  enableWhyDidYouUpdate,
  configureBreakpoints,
};
