"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var getBorderColor = function getBorderColor(_ref) {
  var error = _ref.error,
    disabled = _ref.disabled,
    isFocused = _ref.isFocused,
    color = _ref.color;
  if (error) return color.validation.error;
  if (disabled) return color.gray.charlie;
  if (isFocused) return color.secondary;
  return color.gray.bravo;
};
var StyledSearchable = _styledComponents["default"].label.withConfig({
  displayName: "Searchablestyle__StyledSearchable",
  componentId: "sc-1a9pduv-0"
})(["", ";"], function (props) {
  var _props$theme = props.theme,
    color = _props$theme.palette,
    spacing = _props$theme.spacing;
  var value = props.value,
    error = props.error,
    disabled = props.disabled,
    searchIcon = props.searchIcon,
    isFocused = props.isFocused,
    isNumeric = props.isNumeric,
    isFullWidth = props.isFullWidth;
  var fieldWidth = isFullWidth ? '100%' : '200px';
  var inputWidth = isFullWidth ? '100%' : 'auto';
  return (0, _styledComponents.css)(["display:inline-block;border-bottom:1px solid ", ";cursor:text;position:relative;height:36px;width:", ";padding-left:", ";i{top:0px;right:6px;pointer-events:none;}span{padding:1px;font-weight:normal;}.input-component{overflow:hidden;white-space:nowrap;text-overflow:ellipsis;", " input{background-color:transparent;border-bottom:0;padding-left:0;text-overflow:ellipsis;width:", ";}}.rule-formula{position:absolute;background-color:transparent;padding:0 0 ", ";width:", ";&.no-placeholder{width:2px;}", ";}", ";"], getBorderColor({
    error: error,
    disabled: disabled,
    isFocused: isFocused,
    color: color
  }), fieldWidth, spacing.tiny, isFullWidth && (0, _styledComponents.css)(["width:100%;"]), isFocused && isNumeric ? '67%' : inputWidth, spacing.small, fieldWidth, searchIcon && (0, _styledComponents.css)(["padding-right:", ";"], spacing.large), value && (0, _styledComponents.css)(["&.user-focused .formula-value{background-color:", ";}"], color.gray.delta));
});
StyledSearchable.displayName = 'StyledSearchable';
var _default = StyledSearchable;
exports["default"] = _default;