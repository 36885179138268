/* eslint-disable max-statements */
/* eslint-disable max-lines-per-function */
import * as R from 'ramda';
import { isBlank } from '@trs/utils';
import * as actions from '../actions/types';
import {
  TABLE_SELECT_ROW,
  TABLE_SELECT_ROWS,
  TABLE_CLEAR_SELECTED,
  TABLE_STORE_RESET,
  TABLE_DIRTY_CHANGE,
} from '../../../actions/actionTypes';
import initialState from './initialState';
import { mapRequestToRoleModel, mapRolesToUserModel } from './helpers';
import { filterCountriesByAccess } from '../../common/helpers';
import userModules from '../../../config/userManager/userModules';

export default function userManagementReducer(state = initialState, action) {
  switch (action.type) {
    case actions.ROLES_GET_ALL: {
      let allRoles = [];
      const { data } = action.response;

      if (!isBlank(data)) {
        allRoles = data.response.map((item) => ({ id: item.id, name: item.name }));
      }

      return {
        ...state,
        allRoles,
        savedRoleId: '',
      };
    }
    case actions.REDIRECT_TO_ROOT: {
      return {
        ...state,
        redirectToRoot: true,
      };
    }
    case actions.USERS_UPDATE_SNAPSHOT: {
      const roleId = action.value.id;
      const savedRole = roleId ? mapRequestToRoleModel(action.value) : action.value;

      return {
        ...state,
        savedRole: R.clone(savedRole),
        roleSnapShot: R.clone(savedRole),
      };
    }
    case actions.USERS_UPDATE_USERS_SNAPSHOT: {
      return {
        ...state,
        selectedUsersSnapshot: R.clone(action.value),
      };
    }
    case actions.USERS_RESET_DEFAULT: {
      return {
        ...state,
        savedRole: R.clone(state.roleSnapShot),
      };
    }
    case actions.USERS_RESET_USERSLIST_DEFAULT:
    case actions.RESET_HAS_CHANGES:
    case TABLE_STORE_RESET: {
      return {
        ...state,
        hasChangedUsers: false,
      };
    }
    case actions.STORE_RESET: {
      return {
        ...state,
        ...R.clone(initialState),
        banner: R.clone(state.banner),
        savedRole: { ...R.clone(initialState.savedRole) },
      };
    }
    case actions.USERS_MANAGEMENT_BANNER_TOGGLE: {
      return {
        ...state,
        banner: {
          bannerType: action.bannerType,
          bannerMessage: action.bannerMessage,
          bannerVisible: action.bannerVisible,
        },
      };
    }
    case actions.USERS_ROLE_FIELD_CHANGE: {
      if (action.field === 'availableModules') {
        const { key, isAvailable, name } = action.value;
        const { availableModules } = state.savedRole;
        const entry = { key, isAvailable, name };

        if (!availableModules.some((item) => item.key === key)) {
          availableModules.push(entry);
        } else {
          const entryIndex = availableModules.findIndex((item) => item.key === key);
          availableModules[entryIndex] = entry;
        }

        return {
          ...state,
          savedRole: {
            ...state.savedRole,
            availableModules,
          },
        };
      }

      return {
        ...state,
        savedRole: {
          ...state.savedRole,
          [action.field]: action.value,
        },
      };
    }
    case actions.USERS_ROLE_SELECTED_CHANGE: {
      const selectedRoles = [];
      return {
        ...state,
        selectedRoles: selectedRoles.concat(action.value),
      };
    }
    case actions.ROLES_SELECTED_CLEAR: {
      return {
        ...state,
        selectedRoles: [],
      };
    }
    case actions.ROLES_GET_BY_ID_PENDING: {
      return {
        ...state,
        savedRole: {
          ...state.savedRole,
          isLoading: true,
        },
      };
    }
    case actions.ROLES_GET_BY_ID_ERROR: {
      return {
        ...state,
        savedRole: {
          isLoading: false,
        },
      };
    }

    case actions.ROLES_GET_BY_ID: {
      const { role } = action;
      const savedRole = mapRequestToRoleModel(role);
      const savedRoleId = role.id;

      return {
        ...state,
        savedRole: {
          ...savedRole,
          isLoading: false,
        },
        savedRoleId,
      };
    }

    case actions.USER_SET_AS_NON_EMPLOYEE: {
      const { value } = action;
      return {
        ...state,
        userForEdit: {
          ...state.userForEdit,
          isNonEmployee: value,
        },
      };
    }

    case actions.USER_GET_USER_BY_ID: {
      const { user } = action;
      const userForEdit = mapRolesToUserModel(user);
      const userId = user.id;

      return {
        ...state,
        userForEdit,
        userId,
      };
    }

    case actions.ROLES_GET_USERS_BY_ID: {
      const { users } = action;

      return {
        ...state,
        selectedUsersSnapshot: R.clone(users),
      };
    }

    // this two actions return the same value, please don't change the order
    case TABLE_SELECT_ROW:
    case TABLE_SELECT_ROWS:
    case TABLE_CLEAR_SELECTED: {
      return {
        ...state,
        hasChangedUsers: true,
      };
    }

    case TABLE_DIRTY_CHANGE: {
      const { itemIds, checked } = R.prop('payload', action);
      const countToChange = Array.isArray(itemIds) ? itemIds.length : 1;
      let { usersInRole } = R.prop('savedRole', state);

      usersInRole = checked ? usersInRole + countToChange : usersInRole - countToChange;

      return {
        ...state,
        hasChangedUsers: true,
        savedRole: {
          ...state.savedRole,
          usersInRole,
        },
      };
    }

    case actions.USER_MANAGEMENT_GET_COUNTRIES: {
      const authorizedCountries = filterCountriesByAccess({
        countries: R.prop('countries', action),
        authorizedModules: R.prop('authorizedModules', action),
        module: userModules.USER_MANAGEMENT,
      });

      const countries = authorizedCountries.map((country) => ({
        label: country.name,
        value: country.code,
      }));

      return {
        ...state,
        countries,
      };
    }

    case actions.USER_GET_MODULE_PERMISSIONS_PENDING: {
      return {
        ...state,
        loadingModulePermissions: true,
      };
    }
    case actions.USER_GET_MODULE_PERMISSIONS: {
      return {
        ...state,
        loadingModulePermissions: false,
        modulePermissions: action.payload,
      };
    }
    case actions.USER_GET_MODULE_PERMISSIONS_ERROR: {
      return {
        ...state,
        loadingModulePermissions: false,
      };
    }

    default:
      return R.clone(state);
  }
}
