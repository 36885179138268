"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
var _utils = require("@trs/utils");
var _templateObject, _templateObject2, _templateObject3;
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
var StyledLegendItem = _styledComponents["default"].div.withConfig({
  displayName: "LegendItemstyle__StyledLegendItem",
  componentId: "sc-1lawjuj-0"
})(["", ";"], function (props) {
  var _props$theme = props.theme,
    color = _props$theme.palette,
    typography = _props$theme.typography,
    spacing = _props$theme.spacing;
  var active = props.active,
    highlightRow = props.highlightRow;
  return (0, _styledComponents.css)(["color:", ";cursor:pointer;display:flex;flex-wrap:wrap;flex-direction:column;font-size:", ";justify-content:space-between;line-height:", ";padding:0 ", ";position:relative;", " ", " .legend-label{padding-right:", ";margin-bottom:", ";word-break:break-word;max-width:100%;", " flex:1;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;}.legend-value{align-self:flex-start;font-weight:700;padding-left:42px;text-align:right;", "}.legend-description{flex-basis:100%;}.text-element{align-self:flex-end;}"], color.gray.alpha, typography.size.paragraph, typography.lineHeight.paragraph, spacing.tiny, _utils.media.tablet(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n        flex-direction: row;\n        padding-left: 100px;\n        width: 100%;\n      "]))), active && !highlightRow && (0, _styledComponents.css)(["font-weight:700;"]), spacing.base, spacing.tiny, _utils.media.tablet(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n          margin-bottom: 0;\n        "]))), _utils.media.tablet(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n          padding-left: 0;\n        "]))));
});
StyledLegendItem.displayName = 'StyledLegendItem';
var _default = StyledLegendItem;
exports["default"] = _default;