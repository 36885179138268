import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { processSilentRenew } from 'redux-oidc';
import { Provider } from 'react-redux';
import { isBlank } from '@trs/utils';
import userManager from './config/userManager/userManager';
import AppProvider from './AppProvider';
import configureStore from './config/store';
import GlobalStyle from './config/style';
import {
  getTenantFromUrl,
  enableWhyDidYouUpdate,
  configureBreakpoints,
  enableReactAxe,
} from './config/helpers';

const tenant = getTenantFromUrl();

if (window.location.pathname.includes('renew')) {
  processSilentRenew();
} else if (!isBlank(tenant)) {
  userManager.signinRedirect({ extraQueryParams: { domain_hint: tenant } });
} else {
  const store = configureStore();
  window.GLOBAL_STORE = store;
  enableWhyDidYouUpdate();
  enableReactAxe();
  configureBreakpoints();

  ReactDOM.render(
    <Provider store={store}>
      <>
        <GlobalStyle />
        <AppProvider />
      </>
    </Provider>,
    document.getElementById('root')
  );
}
