import * as R from 'ramda';

import {
  countryCellChange,
  companyCellChange,
  saveExecutiveSummarySuccess,
  additionalInfoChange,
  toggleEditMode,
  updateMarketIndicatorsForMarketData,
} from '../actions';

import targetMarketGroupHandlers from '../components/targetMarketGroup/reducer';

export default [
  [
    countryCellChange,
    (state, action) => {
      const { rowIndex, field, value } = action.payload;
      const { executiveSummary } = state;

      return {
        ...state,
        executiveSummary: R.assocPath(
          ['marketIndicators', 'countrySpecific', rowIndex, field],
          value,
          executiveSummary
        ),
      };
    },
  ],
  [
    companyCellChange,
    (state, action) => {
      const { rowIndex, field, value } = action.payload;
      const { executiveSummary } = state;

      return {
        ...state,
        executiveSummary: R.assocPath(
          ['marketIndicators', 'companySpecific', rowIndex, field],
          value,
          executiveSummary
        ),
      };
    },
  ],
  [
    additionalInfoChange,
    (state, action) => ({
      ...state,
      executiveSummary: R.assocPath(
        ['additionalInformation'],
        action.payload,
        state.executiveSummary
      ),
    }),
  ],
  [
    saveExecutiveSummarySuccess,
    (state, action) => {
      const { ready, error, payload } = action;

      if (!ready) {
        return {
          ...state,
          isSaving: true,
        };
      }

      if (error) {
        return {
          ...state,
          isSaving: false,
        };
      }

      return {
        ...state,
        executiveSummarySnapshot: state.executiveSummary,
        isSaving: false,
        editableMarketIndicators: R.assocPath([payload], false, state.editableMarketIndicators),
      };
    },
  ],
  [
    toggleEditMode,
    (state, action) => ({
      ...state,
      editableMarketIndicators: R.assocPath(
        [action.payload],
        !R.prop(action.payload, state.editableMarketIndicators),
        state.editableMarketIndicators
      ),
    }),
  ],
  [
    updateMarketIndicatorsForMarketData,
    (state, action) => {
      let newMarketData = R.assocPath(
        ['currentYearSalaryIncrease'],
        R.prop('currentYearSalaryIncrease', action.payload),
        state.marketData
      );
      newMarketData = R.assocPath(
        ['nextYearSalaryIncrease'],
        R.prop('nextYearSalaryIncrease', action.payload),
        newMarketData
      );
      newMarketData = R.assocPath(
        ['ageingFactor'],
        R.prop('ageingFactor', action.payload),
        newMarketData
      );

      return {
        ...state,
        marketData: newMarketData,
      };
    },
  ],
  ...targetMarketGroupHandlers,
];
