"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getValue = exports.getOptionsDataProps = void 0;
var _utils = require("@trs/utils");
var getValue = function getValue(value, isSimpleValue, options) {
  if ((0, _utils.isBlank)(value)) return null;
  if (isSimpleValue) return options.filter(function (_ref) {
    var optionValue = _ref.value;
    return optionValue === value;
  });
  return value;
};
exports.getValue = getValue;
var getOptionsDataProps = function getOptionsDataProps(labelKey, valueKey) {
  var optionsDataProps = {};
  if (labelKey) {
    optionsDataProps.getOptionLabel = function (option) {
      return option[labelKey];
    };
  }
  if (valueKey) {
    optionsDataProps.getOptionValue = function (option) {
      return option[valueKey];
    };
  }
  return optionsDataProps;
};
exports.getOptionsDataProps = getOptionsDataProps;