"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _utils = require("@trs/utils");
var _propTypes = _interopRequireDefault(require("prop-types"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
var FormattedNumber = function FormattedNumber(_ref) {
  var value = _ref.value,
    locale = _ref.locale,
    _ref$decimals = _ref.decimals,
    decimals = _ref$decimals === void 0 ? 0 : _ref$decimals,
    isPercentage = _ref.isPercentage,
    minimumFractionDigits = _ref.minimumFractionDigits,
    maximumFractionDigits = _ref.maximumFractionDigits,
    floor = _ref.floor,
    currency = _ref.currency,
    hasDecimals = _ref.hasDecimals;
  var decimalCount = maximumFractionDigits || minimumFractionDigits || decimals;
  var amount = floor ? Math.floor(value) : +parseFloat(value, 10).toFixed(decimalCount);
  var stringValue = String(value);
  var isPercent = isPercentage || stringValue.includes('%');
  var localizedValue = !Number.isNaN(amount) ? (0, _utils.formatNumber)(amount, locale, hasDecimals) : value;
  return "".concat(localizedValue).concat(isPercent ? '%' : '').concat(currency ? " ".concat(currency) : '');
};
FormattedNumber.defaultProps = {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
  locale: undefined,
  value: 0,
  decimals: 0,
  isPercentage: false,
  floor: false,
  currency: '',
  hasDecimals: false
};
FormattedNumber.propTypes = {
  currency: _propTypes["default"].string,
  floor: _propTypes["default"].bool,
  value: _propTypes["default"].oneOfType([_propTypes["default"].string, _propTypes["default"].number]),
  locale: _propTypes["default"].string,
  decimals: _propTypes["default"].number,
  minimumFractionDigits: _propTypes["default"].number,
  maximumFractionDigits: _propTypes["default"].number,
  isPercentage: _propTypes["default"].bool,
  hasDecimals: _propTypes["default"].bool
};
var _default = FormattedNumber;
exports["default"] = _default;