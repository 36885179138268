import styled, { css } from 'styled-components';
import { toLower, media } from '@trs/utils';
import { TILE_TYPES } from '../../../../constants';

const StyledOptionTile = styled.div`
  ${props => {
    const { palette, extra, typography, spacing } = props.theme;
    const { isSelected, type } = props;
    return css`
      align-items: stretch;
      background-color: ${palette.basic.white};
      border-radius: ${extra.genericBorderRadius};
      border: 1px solid ${palette.utility.border};
      display: flex;
      flex-direction: row;
      line-height: 1;
      position: relative;
      font-weight: ${typography.weight.normal};
      ${toLower(type) === TILE_TYPES.RADIO &&
        css`
          border-radius: 0;
          border-top: 0;
          &:first-child {
            border-top: 1px solid ${palette.utility.border};
            border-radius: ${extra.genericBorderRadius} ${extra.genericBorderRadius} 0 0;
          }
        `}

      .option-tile-action {
        padding: ${spacing.small} ${spacing.xSmall};
        border-right: solid 1px ${palette.utility.border};
        background-color: ${palette.utility.bodyBackground};
        display: flex;
        align-items: center;
        border-radius: ${extra.genericBorderRadius} 0 0 ${extra.genericBorderRadius};
        .radio-wrapper {
          margin: 0;
        }
        ${isSelected &&
          css`
            background-color: ${palette.accent.charlie};
          `}
        
        &--highlight {
          background-color: ${palette.utility.highlight};
        }
      }
      .option-tile-content {
        padding: ${spacing.small} ${spacing.basePlus};
        ${media.desktop`
          padding: ${spacing.small} ${spacing.xSmall};
        `}
        &--highlight {
          color ${palette.gray.bravo};
          font-size: ${typography.size.small};
        }
        width: 100%;
        .label-with-caption {
          display:flex;
          flex-direction: column;
          .reward-name {
            color: ${palette.gray.alpha};
            font-size: ${typography.size.base};
            line-height: ${typography.lineHeight.default};
            margin-bottom: ${spacing.tiny};
            position: relative;
            width: 100%;
            word-break: break-all;
            word-break: break-word;

            > div, > span {
              vertical-align: middle;
            }

            > div {
              margin-top: -${spacing.xTiny};
            }
          }
          .reward-text-only-description {
              display: flex;
              align-items: baseline;
              flex-direction: row;
              justify-content: space-between;
              color: black;
          }
          .reward-caption {
            width: 100%;
            font-size: ${typography.size.table};
            line-height: ${typography.lineHeight.default};
            color ${palette.gray.bravo};
            margin-bottom: ${spacing.tiny};
          }
        }
        .option-tile-tooltip {
          margin-right: ${spacing.tiny};
          position: relative;
          display: inline-block;
        }
        .editable-field-container {
          margin-top: ${spacing.tiny};
          .editable-field-value {
            color: ${palette.gray.alpha};
          }
        }
      }
    `;
  }};
`;

StyledOptionTile.displayName = 'StyledOptionTile';
export default StyledOptionTile;
