import React from 'react';
import PropTypes from 'prop-types';
import { Button, Card, TitleWithButton, PageLoader, Paragraph } from '@trs/components';
import StyledDisclaimerPage from './Disclaimer.style';
import Text, { TextOnly } from '../../common/Text';
import { LANDING_PAGES } from '../../../config/userManager/menuConfig';

const Disclaimer = ({ genericLabel, history, landingPage, user }) =>
  !user.isLoggedIn ? (
    <PageLoader pastDelay />
  ) : (
    <StyledDisclaimerPage>
      <TitleWithButton
        className="disclaimer-title"
        data-testid="disclaimer-title"
        title={TextOnly({ path: 'userManagement.DISCLAIMER_WELCOME_TEXT' })}
        wrapTitle
        button={
          <Button id="disclaimerAccept" action={() => history.push(landingPage)}>
            {genericLabel.BUTTON_CONTINUE}
          </Button>
        }
      />
      <Card className="disclaimer-content" data-testid="disclaimer-content">
        <Paragraph>
          <Text path="userManagement.DISCLAIMER_DESCRIPTION_TEXT" withHtml />
        </Paragraph>
      </Card>
    </StyledDisclaimerPage>
  );

Disclaimer.defaultProps = {
  landingPage: LANDING_PAGES.RM_DASHBOARD,
};

Disclaimer.propTypes = {
  user: PropTypes.shape({
    isLoggedIn: PropTypes.bool,
  }).isRequired,
  genericLabel: PropTypes.shape({
    BUTTON_CONTINUE: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  landingPage: PropTypes.string,
};

export default Disclaimer;
