import { handleActions } from 'redux-actions';
import * as R from 'ramda';

import {
  fetchOptionsPending,
  fetchOptionsSuccess,
  fetchOptionsError,
  changeSelectedRewards,
  changeAgreementTos,
  computeStatementPending,
  computeStatementSuccess,
  computeStatementError,
  saveStatementPending,
  saveStatementError,
  saveStatementSuccess,
  submitStatement,
  submitCommentsChange,
  updateEtag,
  sbsReset,
  initiateStatement,
  updateAvailableOptions,
} from '../actions';
import { getPackageDetails } from '../../submissionPage/actions';

import {
  groupAvailableOptions,
  getGroupedCalculatedStatement,
  getSelectedRewards,
} from './helpers';

import initialState from './initialState';

const reducer = handleActions(
  new Map([
    [sbsReset, () => R.clone(initialState)],
    [
      fetchOptionsPending,
      state => ({
        ...state,
        isFetchingOptions: true,
      }),
    ],
    [
      fetchOptionsError,
      state => ({
        ...state,
        isFetchingOptions: false,
        availableOptionsMap: initialState.availableOptionsMap,
        availableOptions: initialState.availableOptions,
        selectedRewards: initialState.selectedRewards,
        data: initialState.data,
      }),
    ],
    [
      fetchOptionsSuccess,
      (state, { payload, meta }) => ({
        ...state,
        isFetchingOptions: false,
        availableOptionsMap: R.groupBy(R.prop('id'), payload),
        availableOptions: groupAvailableOptions(payload, meta),
        selectedRewards: getSelectedRewards(payload),
      }),
    ],
    [
      initiateStatement,
      (state, { payload }) => ({
        ...state,
        employeeFlexibleRewardId: R.prop('employeeFlexibleRewardId', payload),
        employeeStatementHasBeenReset: R.prop('hasChanges', payload),
        availableOptionsRespModel: R.prop('availableOptions', payload),
        metaData: {
          ...state.metaData,
          agreedTos: R.path(['metaData', 'agreedTos'], payload),
        },
        canSubmit: R.path(['canSubmit'], payload),
        hasSubmitted: R.path(['hasSubmitted'], payload),
      }),
    ],
    [
      updateAvailableOptions,
      (state, { payload }) => ({
        ...state,
        availableOptions: R.clone(
          groupAvailableOptions(payload.newAvailableOptions, payload.categories)
        ),
        availableOptionsRespModel: payload.availableOptionsRespModel,
      }),
    ],
    [
      updateEtag,
      (state, action) => ({
        ...state,
        savedEtag: action.payload,
      }),
    ],
    [
      changeSelectedRewards,
      (state, action) => ({
        ...state,
        selectedRewards: action.payload,
      }),
    ],
    [
      changeAgreementTos,
      (state, action) => ({
        ...state,
        metaData: {
          ...state.metaData,
          agreedTos: action.payload,
        },
      }),
    ],
    [
      computeStatementPending,
      state => ({
        ...state,
        isComputingStatement: true,
      }),
    ],
    [
      computeStatementError,
      state => ({
        ...state,
        isComputingStatement: false,
      }),
    ],
    [
      computeStatementSuccess,
      (state, action) => {
        const { payload } = action;

        return {
          ...state,
          currentData: R.prop('currentData', payload),
          current: R.prop('current', payload),
          isComputingStatement: false,
          metaData: {
            ...R.prop('metaData', payload),
            agreedTos: state.metaData.agreedTos,
          },
          data: getGroupedCalculatedStatement(payload),
          accessFlags: R.prop('accessFlags', payload),
        };
      },
    ],
    [
      saveStatementPending,
      state => ({
        ...state,
        isSaving: true,
      }),
    ],
    [
      saveStatementError,
      state => ({
        ...state,
        isSaving: false,
      }),
    ],
    [
      saveStatementSuccess,
      (state, { payload }) => ({
        ...state,
        isSaving: false,
        employeeFlexibleRewardId: payload,
        employeeStatementHasBeenReset: false,
      }),
    ],
    [
      submitStatement,
      (state, action) => {
        const { ready, error } = action;

        if (!ready) {
          /**
           * We use the same flag since it serves the same purpose of disabling the page
           * while submitting
           */
          return {
            ...state,
            isSaving: true,
          };
        }

        if (error) {
          return {
            ...state,
            isSaving: false,
          };
        }

        return {
          ...state,
          isSaving: false,
          canSubmit: false,
          hasSubmitted: true,
        };
      },
    ],
    [
      submitCommentsChange,
      (state, action) => ({
        ...state,
        comment: action.payload,
      }),
    ],
    [
      getPackageDetails,
      (state, action) => {
        const packageDetails = action.payload;

        return {
          ...state,
          data: R.prop('data', packageDetails),
          availableOptions: R.prop('availableOptions', packageDetails),
          current: R.prop('currentStatement', packageDetails),
          selectedRewards: R.prop('selectedRewards', packageDetails),
          metaData: R.prop('metaData', packageDetails),
        };
      },
    ],
  ]),
  R.clone(initialState)
);

export default reducer;
