import * as R from 'ramda';
import { DATE_FORMAT } from '@trs/config';
import { processDate } from '@trs/utils';
import React from 'react';
import { RATING_ICONS, DEFAULT_FACE, MIN_FEEDBACK_LENGTH, MAX_FEEDBACK_LENGTH } from '../constants';
import EmployeeSatisfactionCol from './columns/EmployeeSatisfactionCol';

export const getStatusByScore = amount => {
  let status = DEFAULT_FACE;

  if (amount < 0) {
    return status;
  }

  R.reverse(RATING_ICONS).forEach(value => {
    if (amount <= value.scale) {
      status = value.iconName;
    }
  }, RATING_ICONS);

  return status;
};

export const hasScore = score => score >= 0;

export const convertDecimalToPercentage = score => {
  if (!hasScore(score)) return score;

  return parseFloat((score * 100).toFixed(0));
};

export const calculateAverageScore = R.compose(
  R.mean,
  R.filter(R.lte(0))
);

export const getArrayOfScoresFromRewards = rewards =>
  rewards.map(({ feedbackScore }) => feedbackScore);

export const mapFeedbackList = rewardFeedback => {
  return {
    ...rewardFeedback,
    list: R.map(feedback => {
      const rowData = {
        score: convertDecimalToPercentage(feedback.userScore),
        statusIconName: getStatusByScore(feedback.userScore),
        hasFeedback: hasScore(feedback.userScore),
      };
      return {
        employeeCountryName: feedback.employeeCountryName,
        feedbackText: feedback.feedbackText || '-',
        timestamp: processDate(feedback.timestamp, DATE_FORMAT),
        id: feedback.feedbackId,
        isActionDisabled: !feedback.feedbackText,
        feedbackScore: (
          <EmployeeSatisfactionCol
            statusIconName={rowData.statusIconName}
            score={rowData.score}
            hasFeedback={rowData.hasFeedback}
          />
        ),
      };
    }, rewardFeedback.list),
  };
};

export const isValidText = txt =>
  R.length(R.trim(txt)) >= MIN_FEEDBACK_LENGTH && R.length(R.trim(txt)) < MAX_FEEDBACK_LENGTH;

export const isValidRewardFeedback = (text, score) => {
  const isValidTxt = isValidText(text);
  return (hasScore(score) && isValidTxt) || isValidTxt;
};

export const amendmentFeedback = rewards =>
  R.map(
    item => ({
      ...item,
      feedbackText: R.trim(R.path(['feedbackText'], item)),
      isValid: isValidRewardFeedback(
        R.path(['feedbackText'], item),
        R.path(['feedbackScore'], item)
      ),
      hasRewardFeedbackChanged: false,
    }),
    rewards
  );

export const isChanged = (initReward, updatedReward, index) =>
  R.path(['feedbackScore'], updatedReward[index]) !==
    R.path(['feedbackScore'], initReward[index]) ||
  R.path(['feedbackText'], updatedReward[index]) !== R.path(['feedbackText'], initReward[index]);

export const validateRewardsFeedback = rewardsFeedback =>
  R.find(
    reward => !R.path(['isValid'], reward) && R.path(['hasRewardFeedbackChanged'], reward),
    rewardsFeedback
  );

export const addPercentageSymbol = score => `${score}%`;

export default {
  getStatusByScore,
  convertDecimalToPercentage,
  calculateAverageScore,
  getArrayOfScoresFromRewards,
  hasScore,
  addPercentageSymbol,
};
