"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "Datepicker", {
  enumerable: true,
  get: function get() {
    return _Datepicker["default"];
  }
});
Object.defineProperty(exports, "DatepickerV2", {
  enumerable: true,
  get: function get() {
    return _Datepicker2["default"];
  }
});
var _Datepicker = _interopRequireDefault(require("./Datepicker"));
var _Datepicker2 = _interopRequireDefault(require("./v2/Datepicker"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }