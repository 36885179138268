import * as R from 'ramda';
import { createAction } from 'redux-actions';
import { GET } from '../../../../../../../config/http';
import { feedback } from '../../../../../../../config/api';

export const actionTypes = {
  GET_FEEDBACK_STATS: 'GET_FEEDBACK_STATS',
  RESET_STORE: 'RESET_STORE',
};

export const feedbackStats = createAction(actionTypes.GET_FEEDBACK_STATS);
export const resetStore = createAction(actionTypes.RESET_STORE);

export const getFeedbackStats = () => (dispatch, getState) => {
  const country = R.path(['rmDashboard', 'dashboard', 'selectedCountry', 'value'], getState());
  dispatch(
    feedbackStats(
      GET(
        `${feedback.getFeedbackStats}/${country}`,
        { Pragma: 'no-cache' },
        { showErrorModal: true, dispatch }
      ).then(response => R.prop('data', response))
    )
  );
};
