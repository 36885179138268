import * as R from 'ramda';
import * as actionTypes from '../actions/actionTypes';

const initialState = {
  inactivityTimeout: 900,
  isWarningInactivityActive: false,
  shouldResetInactivityTimer: false,
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.USER_GET_INACTIVITY_TIMEOUT: {
      return {
        ...state,
        inactivityTimeout: action.payload,
      };
    }
    case actionTypes.SET_WARNING_INACTIVITY: {
      return {
        ...state,
        isWarningInactivityActive: action.payload,
      };
    }
    case actionTypes.RESET_TIMER_INACTIVITY: {
      return {
        ...state,
        shouldResetInactivityTimer: action.payload,
      };
    }
    case actionTypes.CLEAR_DATA_INACTIVITY: {
      return R.clone(initialState);
    }
    default:
      return state;
  }
}
