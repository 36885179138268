import { handleActions } from 'redux-actions';
import * as R from 'ramda';
import { getExtendedColors } from '@trs/utils';
import { getSummary, getDetailedChartData, getCurrencies, resetExecutiveReport } from '../actions';
import initialState from './initialState';
import { mapPayload, mapDetailsPayload, mapCurrencies } from './helpers';

const main = handleActions(
  new Map([
    [resetExecutiveReport, () => R.clone(initialState)],
    [
      getSummary,
      (state, action) => {
        const data = R.path(['payload', 'data'], action);
        let colors = [];

        if (data) {
          const indexes = R.pluck('orderIndex', R.prop('categories', data));
          colors = getExtendedColors(Math.max(...indexes), action.meta);
        }

        return {
          ...state,
          isGettingSummary: !action.ready,
          pageLevelError:
            (action.error && R.path(['payload', 'response'], action)) || state.pageLevelError,
          summary: data ? mapPayload(data, colors) : { ...state.summary },
        };
      },
    ],
    [
      getDetailedChartData,
      (state, action) => {
        const data = R.path(['payload', 'data'], action);
        return {
          ...state,
          isGettingDetails: !action.ready,
          pageLevelError:
            (action.error && R.path(['payload', 'response'], action)) || state.pageLevelError,
          details: data
            ? {
                ...state.details,
                [action.meta]: mapDetailsPayload(action.meta, data),
              }
            : state.details,
        };
      },
    ],
    [
      getCurrencies,
      (state, action) => {
        const data = R.path(['payload', 'data'], action);
        return {
          ...state,
          pageLevelError:
            (action.error && R.path(['payload', 'response'], action)) || state.pageLevelError,
          isGettingCurrencies: !action.ready,
          currencies: data ? mapCurrencies(data) : [],
        };
      },
    ],
  ]),
  R.clone(initialState)
);

export default main;
