import { createAction } from 'redux-actions';
import * as R from 'ramda';
import { showBanner } from 'actions/globalActions';
import { mobility } from 'config/api';
import { POST } from 'config/http';
import { TextOnly } from 'modules/common/Text';
import types from './types';

export const selectMobilityFile = createAction(types.MOBILITY_FILE_SELECTED);
export const uploadFilePending = createAction(types.MOBILITY_UPLOAD_PENDING);
export const uploadFileSuccess = createAction(types.MOBILITY_UPLOAD_SUCCESS);
export const uploadFileError = createAction(types.MOBILITY_UPLOAD_ERROR);
export const resetStore = createAction(types.RESET_STORE);

export const uploadMobilityData = payload => dispatch => {
  const formData = new FormData();
  formData.append('file', new Blob([payload], { type: 'text/csv' }), R.prop('name', payload));
  dispatch(uploadFilePending());
  return POST(mobility.upload, formData, {}, { showErrorModal: true, dispatch })
    .then(response => {
      dispatch(uploadFileSuccess(R.path(['data', 'response'], response)));
      dispatch(
        showBanner({
          type: 'success',
          content: TextOnly({ path: 'generic.UPLOAD_FILE_SUCCESS' }),
        })
      );
    })
    .catch(error => {
      dispatch(uploadFileError());
      return Promise.reject(error);
    });
};
