import React, { memo } from 'react';
import PropTypes from 'prop-types';
import StyledFace from './Face.style';
import { ReactComponent as VeryHappyFace } from '../../../../assets/images/faces/very-happy-grey.svg';
import { ReactComponent as HappyFace } from '../../../../assets/images/faces/happy-grey.svg';
import { ReactComponent as NeutralFace } from '../../../../assets/images/faces/neutral-grey.svg';
import { ReactComponent as SadFace } from '../../../../assets/images/faces/sad-grey.svg';
import { ReactComponent as VerySadFace } from '../../../../assets/images/faces/very-sad-grey.svg';
import { ReactComponent as NoFeedback } from '../../../../assets/images/faces/icon-no-feedback.svg';
import { FEEDBACK_MOODS } from '../../constants';

function noop() {}

/* eslint-disable react/prop-types */
function getFace({ className, status, size, disabled, onClick }) {
  const attributes = {
    className: `feedback-rating-icon ${className} ${status}`,
    height: size,
    width: size,
    onClick: disabled ? noop : onClick,
    'data-testid': 'feedback-rating-icon',
  };
  switch (status) {
    case FEEDBACK_MOODS.veryHappy:
      return <VeryHappyFace {...attributes} />;
    case FEEDBACK_MOODS.happy:
      return <HappyFace {...attributes} />;
    case FEEDBACK_MOODS.neutral:
      return <NeutralFace {...attributes} />;
    case FEEDBACK_MOODS.sad:
      return <SadFace {...attributes} />;
    case FEEDBACK_MOODS.verySad:
      return <VerySadFace {...attributes} />;
    default:
      return <NoFeedback {...attributes} />;
  }
}

const Face = ({ className, status, size, disabled, onClick }) => {
  return (
    <StyledFace size={size}>{getFace({ className, status, size, disabled, onClick })}</StyledFace>
  );
};

Face.defaultProps = {
  className: '',
  disabled: false,
  onClick: () => {},
};

Face.propTypes = {
  className: PropTypes.string,
  status: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

export default memo(Face);
