import * as R from 'ramda';
import { convertDecimalToPercentage, decimalTransform, isBlank } from '@trs/utils';
import { formatNumber } from '@trs/utils/lib/general';
import { TextOnly } from '../../../../../../../common/Text';

const MAX_HEATMAP_VALUE = Number.MAX_VALUE;

export const heatmapThresholds = [
  {
    min: -MAX_HEATMAP_VALUE,
    max: 86,
    display: '< 86%',
    hex: '#e84a6b',
  },
  {
    min: 86,
    max: 94,
    display: '86% - 94%',
    hex: '#f28d70',
  },
  {
    min: 95,
    max: 105,
    display: '95% - 105%',
    hex: '#13ba97',
  },
  {
    min: 105,
    max: MAX_HEATMAP_VALUE,
    display: '> 105%',
    hex: '#1c87b4',
  },
];

export const getColumnConfig = numeric => {
  const alignment = numeric ? 'right' : 'center';
  const columnPercentage = numeric ? '' : ' %';

  return {
    populationName: {
      id: 'populationName',
      title: TextOnly({ path: 'benchmarking.MERIT_POPULATION_NAME' }),
      alignment: 'left',
    },
    populationCount: {
      id: 'populationCount',
      title: TextOnly({ path: 'benchmarking.MERIT_POPULATION_COUNT' }),
      cellRenderer: data => formatNumber(data),
      width: 80,
      alignment: 'center',
    },
    basePay: {
      id: 'basePay',
      title: `${TextOnly({ path: 'benchmarking.COLUMN_BASE_PAY' })}${columnPercentage}`,
      cellRenderer: data => formatNumber(data),
      alignment,
      inHeatmap: true,
    },
    fixedPay: {
      id: 'fixedPay',
      title: `${TextOnly({ path: 'benchmarking.COLUMN_FIXED_PAY' })}${columnPercentage}`,
      cellRenderer: data => formatNumber(data),
      inHeatmap: true,
      alignment,
    },
    targetTotalCash: {
      id: 'targetTotalCash',
      title: `${TextOnly({ path: 'benchmarking.MERIT_TARGET_TOTAL_CASH' })}${columnPercentage}`,
      cellRenderer: data => formatNumber(data),
      inHeatmap: true,
      alignment,
    },
    targetTotalDirectCompensation: {
      id: 'targetTotalDirectCompensation',
      title: `${TextOnly({
        path: 'benchmarking.MERIT_TARGET_TOTAL_DIRECT_COMPENSATION',
      })}${columnPercentage}`,
      cellRenderer: data => formatNumber(data),
      inHeatmap: true,
      alignment,
    },
    totalReward: {
      id: 'totalReward',
      title: `${TextOnly({ path: 'benchmarking.MERIT_TOTAL_REWARD' })}${columnPercentage}`,
      cellRenderer: data => formatNumber(data),
      inHeatmap: true,
      alignment,
    },
  };
};

export const getHeatmapIntervals = R.memoizeWith(
  R.identity,
  R.map(item => [item.min, item.max])
);

export const getHighlightFromValue = value => {
  let hex = R.path([0, 'hex'], heatmapThresholds);
  const heatmapIntervals = getHeatmapIntervals(heatmapThresholds);

  heatmapIntervals.map((item, index) => {
    if (item[0] <= value && item[1] >= value) {
      hex = R.path([index, 'hex'], heatmapThresholds);
    }
    return item;
  });

  return hex;
};

export const validateFormatItem = item => {
  if (isBlank(item)) return item;
  return item.toString().search(/[a-zA-Z]+/g) !== -1 ? item : formatNumber(item);
};

export const processValue = (value = 0, numericOnly) => {
  if (numericOnly) return decimalTransform(value);
  return convertDecimalToPercentage(value);
};

export const isNoContent = status => status === 204;

export const HEATMAP_CALC_STATUS = {
  NOT_STARTED: 1,
  RUNNING: 2,
  COMPLETED: 3,
  FAILED: 4,
  TERMINATED: 5,
};

const mapRowData = (row, numericOnly) => ({
  populationName: R.prop('name', row),
  populationCount: R.prop('count', row),
  basePay: processValue(R.path(['basePay', 'value'], row), numericOnly),
  fixedPay: processValue(R.path(['fixedPay', 'value'], row), numericOnly),
  targetTotalCash: processValue(R.path(['targetTotalCash', 'value'], row), numericOnly),
  targetTotalDirectCompensation: processValue(
    R.path(['targetTotalDirectCompensation', 'value'], row),
    numericOnly
  ),
  totalReward: processValue(R.path(['totalReward', 'value'], row), numericOnly),
});

export const mapBEToFE = (data = {}, numericOnly) => {
  const model = {
    list: R.map(row => mapRowData(row, numericOnly), R.propOr([], 'populationRows', data)),
    average: isBlank(R.prop('averageRow', data))
      ? []
      : mapRowData(R.prop('averageRow', data), numericOnly),
  };
  model.combined = [...model.list, model.average];
  return model;
};

export const CALCULATION_TYPES = {
  default: 1,
  increased: 2,
};

export default {
  CALCULATION_TYPES,
  mapBEToFE,
  getColumnConfig,
  isNoContent,
  HEATMAP_CALC_STATUS,
};
