import userModules from 'config/userManager/userModules';

export const NOTIFICATION_TYPE = {
  OVERALL_IMPACT: 'OverallImpact',
  WORKSPACE: 'WorkspaceUploadFile',
  REPORT: 'Report',
  WORKSPACE_PROCESS_TRIGGERING: 'WorkspaceProcessTriggering',
  DATA_VALIDATION: 'DataValidation',
  GENERATE_EMPLOYEE_ELECTIONS: 'GenerateEmployeeElections',
  DOWNLOAD_AUDIT_INFO: 'DownloadAuditInfo',
  COUNTRY_REWARD_REPORT: 'GenerateCountryRewardsReport',
  EMPLOYEE_DATA_LOADING: 'EmployeeDataLoading',
  SHARES_DATA_LOADING: 'SharesDataLoading',
  ROLE_ELIGIBILITY: 'RoleEligibility',
  EXCEPTIONS_DELETE: 'ExceptionsDelete',
  EXCEPTIONS_PUBLISH: 'ExceptionsPublish',
  EXCEPTIONS_FILE_UPLOAD: 'ExceptionsFileUpload',
  BENEFITS_ENVELOPE_GENERATE_REPORT_CONFIGURATION: 'BenefitsEnvelopeGenerateReportConfiguration',
  FORECAST_REPORT_CONFIGURATION: 'ForecastReportConfiguration',
  ENROLMENT_WINDOWS_REPORT: 'EnrollmentWindowsExport',
  GENERATE_PAYSCALES: 'GeneratePayscales',
};

export const MODULES_WITH_NOTIFICATIONS = [
  userModules.AUDIT,
  userModules.EXCEPTIONS_MANAGEMENT,
  userModules.FLEXIBLE_MANAGEMENT,
  userModules.REPORTS,
  userModules.REWARDS_MANAGEMENT,
  userModules.USER_MANAGEMENT,
  userModules.SETTINGS,
  userModules.WORKSPACE,
  userModules.DATA_VALIDATION,
  userModules.BENEFITS_ENVELOPE,
  userModules.MODELLER,
];
