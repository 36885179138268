import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { Banner, Button, Dialog, Doughnut, H3, H4, Paragraph } from '@trs/components';
import { formatNumber } from '@trs/utils';
import { rewards as rewardsApi } from '../../../../../config/api';
import { exportDetailPieChart } from './helpers';
import Text, { TextOnly } from '../../../../common/Text';
import DownloadLink from '../../../../common/DownloadLink';
import TabularLegend from './TabularLegend';
import StyledImpactDetails from './impactDetails.style';

class ImpactDetailsDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      downloading: false,
      error: false,
    };
  }

  onDownloadClick = () => {
    this.setState({
      downloading: true,
      error: false,
    });
  };

  downloaded = ({ err }) => {
    if (err) {
      this.setState({
        downloading: false,
        error: true,
      });
    } else {
      this.props.onDownloaded();
    }
  };

  clearError = () => {
    this.setState({
      error: false,
    });
  };

  render() {
    const { impact, theme, onExit } = this.props;
    const summary = R.path(['overallImpact', 'summary'], impact);
    const detailPieChartData = exportDetailPieChart(impact.overallImpact);
    const totalValue = formatNumber(summary.totalRewardCost);
    const origin = R.path(['origin'], impact);

    return (
      <Dialog
        width="850px"
        className="impact-details-dialog"
        leftActions={
          <DownloadLink
            className="download-button"
            id="downloadOverallImpactCsv"
            type="naked"
            href={`${rewardsApi.downloadCsv}?overallImpactId=${impact.overallImpact.impactId}&origin=${origin}`}
            download={`OverallImpactCsv-${impact.displayName}.csv`}
            action={this.downloaded}
            onClick={this.onDownloadClick}
            loading={this.state.downloading}
          >
            <Text path="generic.BUTTON_DOWNLOAD" />
          </DownloadLink>
        }
        rightActions={
          <Button action={onExit} type="naked" id="ImpactDetailsDialog_Button_Exit">
            <Text path="generic.BUTTON_EXIT" />
          </Button>
        }
      >
        <H3 className="dialog-title">
          <Text path="rewards.RULES_BUILDER_WIDGET_OVERALL_IMPACT_TITLE" />
        </H3>
        <StyledImpactDetails>
          <div className="impact-detail-doughnut">
            <div className="doughnut-wrapper">
              <Doughnut
                data={detailPieChartData}
                height={220}
                colors={R.path(['extra', 'overallImpactColorMap', 'categories'], theme)}
                currency={summary.rewardCurrency}
                legend
                legendComponent={TabularLegend}
                legendProps={{
                  cols: [
                    {
                      type: 'string',
                      propName: 'grade',
                      label: TextOnly({ path: 'rewards.OVERALL_IMPACT_DETAILS_GRADE_LABEL' }),
                    },
                    {
                      type: 'number',
                      propName: 'value',
                      label: TextOnly({ path: 'rewards.OVERALL_IMPACT_DETAILS_EMPLOYEES_LABEL' }),
                    },
                    {
                      type: 'string',
                      propName: 'amount',
                      label: TextOnly({ path: 'rewards.OVERALL_IMPACT_DETAILS_AMOUNT_LABEL' }),
                    },
                  ],
                  extraRow: {
                    grade: 'Total',
                    value: summary.employeesAffected,
                    amount: `${totalValue} ${summary.rewardCurrency}`,
                  },
                }}
                withPagination
              />
            </div>
          </div>
          <div className="impact-detail-message">
            <H4>
              <Text path="rewards.RULES_BUILDER_WIDGET_OVERALL_IMPACT_DOWNLOAD_TITLE" />
            </H4>
            <Paragraph>
              <Text path="rewards.RULES_BUILDER_WIDGET_OVERALL_IMPACT_DOWNLOAD_SUMMARY" />
            </Paragraph>
            {this.state.error && (
              <Banner
                id="downloadBanner"
                type="error"
                closable
                autoHideTime={10000}
                closeTrigger={this.clearError}
              >
                <Text path="generic.ERROR_DOWNLOAD" />
              </Banner>
            )}
          </div>
        </StyledImpactDetails>
      </Dialog>
    );
  }
}

ImpactDetailsDialog.defaultProps = {
  onDownloaded: R.identity,
};

ImpactDetailsDialog.propTypes = {
  impact: PropTypes.shape({
    overallImpact: PropTypes.shape({
      impactId: PropTypes.string.isRequired,
    }),
    displayName: PropTypes.string.isRequired,
  }).isRequired,
  theme: PropTypes.shape({}).isRequired,
  onExit: PropTypes.func.isRequired,
  onDownloaded: PropTypes.func,
};

export default ImpactDetailsDialog;
