import * as R from 'ramda';
import {
  TABLE_PAGE_CHANGE,
  TABLE_SORT_CHANGE,
  TABLE_SEARCH_SUBMIT,
  TABLE_FILTERS_SUBMIT,
  TABLE_STORE_RESET,
  TABLE_SELECT_ROW,
  TABLE_SELECT_ROWS,
  TABLE_SELECT_ALL,
  TABLE_CLEAR_SELECTED,
  TABLE_SELECTED_ITEMS,
  TABLE_SET_SHOW_ONLY_SELECTED,
  TABLE_DIRTY_CHANGE,
  TABLE_SEARCH_TEXT_CHANGE,
  TABLE_SET_CHECKED_FILTERS,
} from '../actions/actionTypes';

import { DEFAULT_SORT_CONFIG } from '../modules/common/EnhancedDataTable/helpers';

export const initialState = {
  tableKey: '',
  appliedFilters: [],
  checkedFilters: {},
  pagination: {
    currentPage: 1,
    resultsPerPage: 10,
    totalResults: 0,
    onPageChange: () => {},
  },
  sorting: DEFAULT_SORT_CONFIG,
  searchText: '',
  submittedSearchText: '',
  selection: {
    clearLabel: {},
    onChange: () => {},
    onClear: () => {},
    pagesSelected: {},
    selectAll: () => {},
    selectedItems: [],
    dirtyChanges: [],
  },
  showOnlySelectedCheck: false,
};

export default function enhancedDataTableReducer(state = R.clone(initialState), action) {
  switch (action.type) {
    case TABLE_PAGE_CHANGE:
      return {
        ...state,
        pagination: action.options,
      };

    case TABLE_SORT_CHANGE:
      return {
        ...state,
        sorting: R.path(['payload', 'sorting'], action),
        tableKey: R.path(['payload', 'tableKey'], action),
      };

    case TABLE_SEARCH_SUBMIT:
      return {
        ...state,
        ...action.options,
      };

    case TABLE_FILTERS_SUBMIT:
      return {
        ...state,
        ...action.options,
      };

    case TABLE_SET_SHOW_ONLY_SELECTED: {
      return {
        ...state,
        showOnlySelectedCheck: action.value,
      };
    }

    case TABLE_SELECT_ROWS: {
      const { selection } = state;
      const { itemIds, checked } = action;

      selection.selectedItems = R.difference(selection.selectedItems, itemIds);

      if (checked) {
        selection.selectedItems = [...selection.selectedItems, ...itemIds];
      }

      return {
        ...state,
        selection,
      };
    }

    case TABLE_SELECT_ALL: {
      const { selection } = state;
      const { checked } = action;
      selection.pagesSelected[state.pagination.currentPage] = checked;
      return {
        ...state,
        selection,
      };
    }

    case TABLE_SELECT_ROW: {
      const { selection } = state;
      const { itemId } = action;
      if (R.indexOf(itemId, selection.selectedItems) < 0) {
        selection.selectedItems = [...selection.selectedItems, itemId];
      } else {
        selection.selectedItems = R.difference(selection.selectedItems, [itemId]);
        selection.pagesSelected[state.pagination.currentPage] = false;
      }
      return {
        ...state,
        selection,
      };
    }

    case TABLE_SELECTED_ITEMS: {
      const { selection } = state;
      selection.selectedItems = action.selectedItems;

      return {
        ...state,
        selection,
      };
    }

    case TABLE_CLEAR_SELECTED:
      return {
        ...state,
        selection: {
          clearLabel: {},
          onChange: () => {},
          onClear: () => {},
          pagesSelected: {},
          selectAll: () => {},
          selectedItems: [],
        },
      };

    case TABLE_STORE_RESET:
      return {
        ...state,
        ...R.clone(initialState),
      };

    case TABLE_DIRTY_CHANGE: {
      const { checked } = R.prop('payload', action);
      let { itemIds } = R.prop('payload', action);
      let newDirtyChanges = R.path(['selection', 'dirtyChanges'], state);

      if (!Array.isArray(itemIds)) {
        itemIds = [itemIds];
      }

      itemIds.map((itemId) => {
        const newItem = { itemId, checked };
        const existingItem = newDirtyChanges.find((elem) => elem.itemId === itemId);

        newDirtyChanges = !existingItem
          ? [...newDirtyChanges, newItem]
          : [...R.difference(newDirtyChanges, [existingItem]), newItem];

        return true;
      });

      return {
        ...state,
        selection: {
          ...state.selection,
          dirtyChanges: newDirtyChanges,
        },
      };
    }

    case TABLE_SEARCH_TEXT_CHANGE: {
      return {
        ...state,
        searchText: R.path(['payload', 'value'], action),
      };
    }

    case TABLE_SET_CHECKED_FILTERS: {
      return {
        ...state,
        checkedFilters: R.path(['payload', 'checkedFilters'], action),
      };
    }

    default:
      return state;
  }
}
