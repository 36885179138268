/* eslint-disable max-lines */
import { handleActions } from 'redux-actions';
import * as R from 'ramda';
import {
  getHRSystemDataLoadStatus,
  selectEmployeesDataFile,
  saveEmployeeFileData,
  validateEmployeeFileData,
  resetStore,
  getDimensions,
  addNewEmptyDimension,
  changeDimensionOption,
  deleteDimension,
  saveConfiguration,
  saveConfigurationFail,
  revertDimensionConfiguration,
  updateSystemMode,
  setIsCustomPayscaleEditable,
  getSystemPayscales,
  loadCountriesDependenciesPending,
  loadCountriesDependenciesSuccess,
  loadCountriesDependenciesError,
  setDisableToggleState,
  saveSystemPayscales,
  uploadFileError,
  uploadFilePending,
  uploadFileSuccess,
  loadConfigurationError,
  loadConfigurationSuccess,
  loadConfigurationPending,
  editTableCell,
} from '../actions';
import initialState from './initialState';
import { transformSystemPayscalesPayload, updateSystemPayscalesRows } from './helpers';
import TOGGLE_STATES from '../actions/constants';

const hrReducer = handleActions(
  new Map([
    [
      getHRSystemDataLoadStatus,
      (state, action) => {
        const { ready, payload, error } = action;

        if (!ready) {
          return {
            ...state,
            isDataLoadLoading: true,
          };
        }

        if (error) {
          return {
            ...state,
            isDataLoadLoading: false,
          };
        }

        return {
          ...state,
          ...payload,
          isDataLoadLoading: false,
        };
      },
    ],
    [
      selectEmployeesDataFile,
      (state, action) => {
        const {
          payload: { selectedFile, error },
        } = action;
        return {
          ...state,
          selectedFile,
          error,
          fileName: R.prop('name', selectedFile),
        };
      },
    ],
    [
      saveEmployeeFileData,
      (state, action) => {
        const { ready, payload, error } = action;

        if (!ready) {
          return {
            ...state,
            isUploadInProgress: true,
          };
        }

        if (error) {
          return {
            ...state,
            isUploadInProgress: false,
          };
        }

        return {
          ...state,
          ...payload,
          isUploadInProgress: false,
          selectedFile: null,
          error: null,
        };
      },
    ],
    [
      validateEmployeeFileData,
      (state, action) => {
        const { ready, payload, error } = action;

        if (!ready) {
          return {
            ...state,
            isValidateInProgress: true,
          };
        }

        if (error) {
          return {
            ...state,
            isValidateInProgress: false,
          };
        }

        return {
          ...state,
          ...payload,
          isValidateInProgress: false,
          selectedFile: null,
          error: null,
        };
      },
    ],
    [
      getDimensions,
      (state, action) => {
        const { ready, payload, error } = action;

        if (!ready) {
          return {
            ...state,
            configurationLoading: true,
          };
        }

        if (error) {
          return {
            ...state,
            configurationLoading: false,
          };
        }

        return {
          ...state,
          configurationLoading: false,
          dimensionsOptions: payload,
        };
      },
    ],
    [
      uploadFilePending,
      (state) => {
        return {
          ...state,
          payscales: {
            ...state.payscales,
            isUploadInProgress: true,
          },
        };
      },
    ],
    [
      uploadFileError,
      (state) => {
        return {
          ...state,
          payscales: {
            ...state.payscales,
            isUploadInProgress: false,
          },
        };
      },
    ],
    [
      uploadFileSuccess,
      (state, action) => {
        const {
          payload: { response },
        } = action;

        const countryCode = R.path(['countryCode'], response);
        const transformedResponse = {
          ...state.payscales.systemPayscales[countryCode],
          rows: transformSystemPayscalesPayload(response.updatedRows),
        };

        return {
          ...state,
          payscales: {
            ...state.payscales,
            systemPayscales: {
              ...state.payscales.systemPayscales,
              [countryCode]: transformedResponse,
            },
            isUploadInProgress: false,
          },
        };
      },
    ],
    [
      loadConfigurationError,
      (state) => {
        return {
          ...state,
          dimensionsLoading: false,
          payscaleConfigLoading: false,
        };
      },
    ],
    [
      loadConfigurationPending,
      (state, action) => {
        const {
          payload: { saveOptionsData, saveDimensionsData },
        } = action;
        return {
          ...state,
          ...(saveDimensionsData && { dimensionsLoading: true }),
          ...(saveOptionsData && { payscaleConfigLoading: true }),
        };
      },
    ],
    [
      loadConfigurationSuccess,
      (state, action) => {
        const {
          payload: {
            dimensionIds,
            id,
            isCalculating,
            isSystemGenerated,
            saveOptionsData,
            saveDimensionsData,
            ETag,
          },
        } = action;

        const newDimensionsData = {
          dimensionsData: dimensionIds,
          originalDimensionsData: dimensionIds,
          configurationId: id,
        };

        return {
          ...state,
          ...(saveDimensionsData && newDimensionsData),
          dimensionsLoading: false,
          payscaleConfigLoading: false,
          isCalculating,
          payscales: {
            ...state.payscales,
            ...(saveOptionsData && {
              isSystemGenerated,
              originalIsSystemGenerated: isSystemGenerated,
            }),
            ETag,
          },
        };
      },
    ],
    [
      editTableCell,
      (state, action) => {
        const { payload } = action;
        return {
          ...state,
          payscales: {
            ...state.payscales,
            dirtyRows: [...state.payscales.dirtyRows, payload],
          },
        };
      },
    ],
    [
      addNewEmptyDimension,
      (state) => {
        return {
          ...state,
          dimensionsData: [...state.dimensionsData, ''],
        };
      },
    ],
    [
      changeDimensionOption,
      (state, action) => {
        const {
          payload: { index, option },
        } = action;

        return {
          ...state,
          dimensionsData: state.dimensionsData.map((item, itemIndex) => {
            if (itemIndex === index) {
              return option;
            }
            return item;
          }),
        };
      },
    ],
    [
      deleteDimension,
      (state, action) => {
        const {
          payload: { index },
        } = action;

        return {
          ...state,
          dimensionsData: state.dimensionsData.filter((item, itemIndex) => index !== itemIndex),
        };
      },
    ],
    [
      revertDimensionConfiguration,
      (state) => {
        return {
          ...state,
          dimensionsData: state.originalDimensionsData,
        };
      },
    ],
    [
      saveConfiguration,
      (state, action) => {
        const {
          ready,
          payload: { isCalculating, isSystemGenerated },
        } = action;

        if (!ready) {
          return {
            ...state,
            configurationLoading: true,
          };
        }

        return {
          ...state,
          configurationLoading: false,
          originalDimensionsData: state.dimensionsData,
          isCalculating,
          payscales: {
            ...state.payscales,
            isSystemGenerated,
            originalIsSystemGenerated: isSystemGenerated,
          },
        };
      },
    ],
    [
      saveConfigurationFail,
      (state, action) => {
        const { field, message } = action.payload;

        return {
          ...state,
          isLoading: false,
          payscales: {
            ...state.payscales,
            isSystemGenerated: state.payscales.originalIsSystemGenerated,
          },
          errors: {
            [field]: message,
          },
        };
      },
    ],
    [
      loadCountriesDependenciesPending,
      (state) => ({
        ...state,
        payscales: {
          ...state.payscales,
          isLoadingCountries: true,
        },
      }),
    ],
    [
      loadCountriesDependenciesSuccess,
      (state, action) => {
        const { payload } = action;

        return {
          ...state,
          payscales: {
            ...state.payscales,
            countries: payload,
            isLoadingCountries: false,
          },
        };
      },
    ],
    [
      loadCountriesDependenciesError,
      (state) => ({
        ...state,
        payscales: {
          ...state.payscales,
          isLoadingCountries: false,
        },
      }),
    ],
    [
      saveSystemPayscales,
      (state, action) => {
        const {
          ready,
          payload: { response },
          error,
        } = action;

        if (!ready) {
          return {
            ...state,
            payscales: {
              ...state.payscales,
              isLoading: true,
            },
          };
        }

        if (error) {
          return {
            ...state,
            payscales: {
              ...state.payscales,
              isLoading: false,
            },
          };
        }

        const countryCode = R.path(['countryCode'], response);

        const transformedResponse = {
          ...state.payscales.systemPayscales[countryCode],
          rows: updateSystemPayscalesRows(
            state.payscales.systemPayscales[countryCode].rows,
            transformSystemPayscalesPayload(response.updatedRows)
          ),
        };

        return {
          ...state,
          payscales: {
            ...state.payscales,
            systemPayscales: {
              ...state.payscales.systemPayscales,
              [countryCode]: {
                ...transformedResponse,
              },
            },
            isLoading: false,
          },
        };
      },
    ],
    [
      getSystemPayscales,
      (state, action) => {
        const {
          ready,
          payload: { response },
          error,
        } = action;

        if (!ready) {
          return {
            ...state,
            payscales: {
              ...state.payscales,
              isLoading: true,
            },
          };
        }

        if (error) {
          return {
            ...state,
            payscales: {
              ...state.payscales,
              isLoading: false,
            },
          };
        }

        const countryCode = R.path(['countryCode'], response);
        const transformedResponse = {
          ...response,
          rows: transformSystemPayscalesPayload(response.rows),
        };
        return {
          ...state,
          payscales: {
            ...state.payscales,
            systemPayscales: {
              ...state.payscales.systemPayscales,
              [countryCode]: transformedResponse,
            },
            isLoading: false,
          },
        };
      },
    ],
    [
      updateSystemMode,
      (state, action) => {
        return {
          ...state,
          payscales: {
            ...state.payscales,
            isSystemGenerated: action.payload.isSystemGenerated,
          },
        };
      },
    ],
    [
      setIsCustomPayscaleEditable,
      (state) => {
        return {
          ...state,
          payscales: {
            ...state.payscales,
            isCustomPayscaleEditable: !state.payscales.isCustomPayscaleEditable,
          },
        };
      },
    ],
    [
      setDisableToggleState,
      (state, action) => {
        const {
          payload: { toggleState },
        } = action;

        let disableStates = {
          isDimensionsToggleDisabled: true,
          isSystemGeneratedToggleDisabled: true,
          isTableToggleDisabled: true,
        };

        if (R.equals(toggleState, TOGGLE_STATES.ALL)) {
          R.forEachObjIndexed((value, key) => {
            disableStates[key] = false;
          }, disableStates);
        } else if (R.find(R.propEq(toggleState, TOGGLE_STATES))) {
          disableStates = {
            ...disableStates,
            [toggleState]: false,
          };
        }

        return {
          ...state,
          payscales: {
            ...state.payscales,
            isDimensionsToggleDisabled: disableStates.isDimensionsToggleDisabled,
            isSystemGeneratedToggleDisabled: disableStates.isSystemGeneratedToggleDisabled,
            isTableToggleDisabled: disableStates.isTableToggleDisabled,
          },
        };
      },
    ],
    [resetStore, () => initialState],
  ]),
  initialState
);

export default hrReducer;
