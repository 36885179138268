import userModules from '../userModules';
import { TextOnly } from '../../../modules/common/Text';

const profiles = () => [
  {
    title: '',
    // title will be replaced with user name
    key: [],
    items: [
      {
        title: TextOnly({ path: 'header.EMPLOYEE_STATEMENT' }),
        items: [],
        key: [userModules.EMPLOYEE_DASHBOARD],
        route: '/employee-statement',
      },
      {
        title: TextOnly({ path: 'header.LOGOUT' }),
        items: [],
        key: [],
        route: '/logout',
        id: 'logout',
      },
    ],
    route: '/',
    id: 'profile',
    itemProps: {
      align: 'align-right',
    },
  },
];

export default profiles;
