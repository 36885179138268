export default {
  ADD_EMAIL_SUBJECT_AND_BODY_DESCRIPTION:
    'The email will be sent to employee upon the submission of their choices.',
  PROVIDE_DISCLAIMER: 'Provide Disclaimer',
  PROVIDE_DISCLAIMER_DESCRIPTION:
    'The disclaimer will be displayed on the printed version of the Employee Statement for each employee.',
  AGREEMENT_TITLE: 'Agreement checkbox',
  AGREEMENT_DESCRIPTION:
    'The text will be displayed on the checkbox that employees must tick before submitting their choices',
  ADD_EMAIL_SUBJECT: 'Send email upon employee submission',
  ADD_EMAIL_SUBJECT_DESCRIPTION:
    'The email will be sent to the employee upon the submission of their choices.',
  EMAIL_INFORMATION: 'Email information',
  EMAIL_SUBJECT: 'Email subject',
  EMAIL_CONTENT: 'Email content',
  EMAIL_HELPERTEXT:
    'To give the employee a link to their submission page within the email, you have to write a word in a #word# format. (e.g., Click #here# to view your submission)',
};
