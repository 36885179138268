import { isBlank } from '@trs/utils';
import userModules from 'config/userManager/userModules';
import { getLookupData } from 'modules/benchmarking/components/common/benchmarkPopulation/helpers';
import { filterCountriesByAccess } from 'modules/common/helpers';
import * as R from 'ramda';
import { handleActions } from 'redux-actions';
import {
  eligibilityChange,
  etagUpdate,
  formReset,
  formSave,
  getArticlesCategories,
  getArticlesCountries,
  getArticlesList,
  getEligibilityOperators,
  getFormInputChange,
  getSystemVariables,
  getVariablesLookupData,
  getVideoPlatforms,
  redirectToRoot,
  storeReset,
  updateEligibilityData,
  updateEligibilityError,
} from '../actions';
import { mapEligibilityOperators, mapSystemVariables, processEligibilityChange } from './helpers';
import initialState from './initialState';

const articlesReducer = handleActions(
  new Map([
    [
      getFormInputChange,
      (state, action) => {
        const { field, modifiedValue, other } = action.payload;

        return {
          ...state,
          form: {
            ...state.form,
            ...other,
            [field]: modifiedValue,
          },
        };
      },
    ],
    [
      getVideoPlatforms,
      (state, action) => {
        return {
          ...state,
          videoPlatforms: action.payload,
        };
      },
    ],
    [
      getSystemVariables,
      (state, action) => ({
        ...state,
        systemVariables: mapSystemVariables(R.prop('payload', action)),
      }),
    ],
    [
      getEligibilityOperators,
      (state, action) => ({
        ...state,
        eligibilityOperators: mapEligibilityOperators(R.prop('payload', action)),
      }),
    ],
    [
      getVariablesLookupData,
      (state, action) => {
        const { lookupData, url } = action.payload;

        return {
          ...state,
          lookUpValues: {
            ...state.lookUpValues,
            ...getLookupData(lookupData, url),
          },
        };
      },
    ],
    [
      eligibilityChange,
      (state, action) => ({
        ...state,
        rules: processEligibilityChange(R.clone(state.rules), R.prop('payload', action)),
      }),
    ],
    [
      updateEligibilityError,
      (state, action) => {
        const newState = state;
        return R.assocPath(
          ['eligibilityErrors', ...action.payload.path],
          action.payload.data,
          newState
        );
      },
    ],
    [
      getArticlesCategories,
      (state, action) => {
        const categories = action.payload;
        const categoriesOptions = [];

        categories.map((category) =>
          categoriesOptions.push({
            value: category,
            label: category,
          })
        );

        const selectedCategory = categoriesOptions ? categoriesOptions[0].value : null;

        return {
          ...state,
          categories: categoriesOptions,
          form: { ...state.form, category: selectedCategory },
          originalForm: {
            ...state.originalForm,
            category: selectedCategory,
          },
        };
      },
    ],
    [
      getArticlesList,
      (state, action) => {
        return { ...state, list: R.prop('payload', action) };
      },
    ],
    [
      getArticlesCountries,
      (state, action) => {
        const { error, countries, user } = action.payload;

        if (error) {
          return {
            ...state,
            serverError: true,
          };
        }

        const { authorizedModules, profile } = user;
        const countriesOptions = [];

        filterCountriesByAccess({
          countries,
          module: userModules.ARTICLES_MANAGEMENT,
          authorizedModules,
        }).map((country) =>
          countriesOptions.push({
            value: country.name,
            label: country.name,
            code: country.code,
          })
        );

        if (isBlank(countriesOptions)) {
          countriesOptions.push({ value: profile.country, label: profile.country });
        }

        const selectedCountry =
          R.prop('value')(R.find(R.propEq('value', profile.country))(countriesOptions)) || '';

        return {
          ...state,
          form: {
            ...state.form,
            country: selectedCountry,
          },
          originalForm: {
            ...state.originalForm,
            country: selectedCountry,
          },
          countries: countriesOptions,
        };
      },
    ],
    [
      etagUpdate,
      (state, action) => {
        return {
          ...state,
          savedArticleEtag: action.payload,
        };
      },
    ],
    [
      updateEligibilityData,
      (state, action) => {
        const { etag, populationId } = action.payload;
        return {
          ...state,
          populationId,
          eligibilityEtag: etag,
        };
      },
    ],
    [
      formSave,
      (state, action) => {
        const { response } = action.payload;
        const savedFormData = {
          ...state.form,
          name: response.name,
          country: response.country,
          category: response.categoryName,
          startDate: response.startDate,
          endDate: response.endDate,
          content: response.content,
          contentBanner: response.contentBanner,
          contentMedia: response.contentMedia,
        };
        return {
          ...state,
          status: response.statusName,
          savedArticleId: response.id,
          form: savedFormData,
          originalForm: savedFormData,
        };
      },
    ],
    [
      formReset,
      (state) => {
        return {
          ...state,
          rules: state.originalRules,
          form: state.originalForm,
        };
      },
    ],
    [
      redirectToRoot,
      (state) => {
        return {
          ...state,
          redirectToRoot: true,
        };
      },
    ],
    [storeReset, () => ({ ...initialState })],
  ]),
  initialState
);

export default articlesReducer;
