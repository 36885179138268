"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
var _utils = require("@trs/utils");
var _templateObject, _templateObject2;
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
var StyledGenericErrorPage = _styledComponents["default"].div.withConfig({
  displayName: "GenericErrorPagestyle__StyledGenericErrorPage",
  componentId: "sc-1yvt2de-0"
})(["", ";"], function (props) {
  var _props$theme = props.theme,
    color = _props$theme.palette,
    typography = _props$theme.typography,
    spacing = _props$theme.spacing;
  return (0, _styledComponents.css)(["margin:", " auto;", " ", " h1{color:", ";text-transform:uppercase;margin-bottom:", ";}.error-page-description{color:", ";font-size:", ";font-weight:normal;}"], spacing.xxLarge, _utils.media.tablet(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n        margin-top: 25%;\n      "]))), _utils.media.tabletLandscape(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n        margin-top: 20%;\n      "]))), color.gray.charlie, spacing.xLarge, color.gray.alpha, typography.size.h4);
});
StyledGenericErrorPage.displayName = 'StyledGenericErrorPage';
var _default = StyledGenericErrorPage;
exports["default"] = _default;