import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Paragraph } from '@trs/components';
import { processDate } from '@trs/utils';
import { DATE_TIME_FORMAT } from '@trs/config';
import StyledNotificationHeader from './NotificationHeader.style';

const NotificationHeader = ({ createdDate, title, isError, dataTestId }) => (
  <StyledNotificationHeader isError={isError} data-testid={dataTestId}>
    <Paragraph className="notification-title">{title}</Paragraph>
    <Paragraph className="notification-date">
      {processDate(createdDate, DATE_TIME_FORMAT)}
    </Paragraph>
  </StyledNotificationHeader>
);

NotificationHeader.defaultProps = {
  isError: false,
  dataTestId: 'notification-header',
};

NotificationHeader.propTypes = {
  isError: PropTypes.bool,
  createdDate: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  dataTestId: PropTypes.string,
};

export default memo(NotificationHeader);
