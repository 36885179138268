import {
  TABLE_PAGE_CHANGE,
  TABLE_SORT_CHANGE,
  TABLE_SEARCH_SUBMIT,
  TABLE_FILTERS_SUBMIT,
  TABLE_SELECT_ROW,
  TABLE_SELECT_ROWS,
  TABLE_SELECT_ALL,
  TABLE_STORE_RESET,
  TABLE_CLEAR_SELECTED,
  TABLE_SET_SHOW_ONLY_SELECTED,
  TABLE_DIRTY_CHANGE,
  TABLE_SEARCH_TEXT_CHANGE,
  TABLE_SET_CHECKED_FILTERS,
} from './actionTypes';

export function pageChange(options) {
  return {
    options,
    type: TABLE_PAGE_CHANGE,
  };
}

export function sortChange(tableKey, sorting) {
  return {
    type: TABLE_SORT_CHANGE,
    payload: {
      tableKey,
      sorting,
    },
  };
}

export function searchSubmit(options) {
  return {
    options,
    type: TABLE_SEARCH_SUBMIT,
  };
}

export function filtersSubmit(options) {
  return {
    options,
    type: TABLE_FILTERS_SUBMIT,
  };
}

export function resetStore() {
  return {
    type: TABLE_STORE_RESET,
  };
}

export function setShowOnlySelectedCheck(value) {
  return {
    type: TABLE_SET_SHOW_ONLY_SELECTED,
    value,
  };
}

export function selectItems(checked, itemIds) {
  return {
    type: TABLE_SELECT_ROWS,
    checked,
    itemIds,
  };
}

export function selectAll(checked, itemIds) {
  return dispatch => {
    dispatch({
      type: TABLE_SELECT_ALL,
      checked,
    });
    dispatch(selectItems(checked, itemIds));
  };
}

export function selectRow(itemId, checked = false) {
  return {
    type: TABLE_SELECT_ROW,
    itemId,
    checked,
  };
}

export function onClear() {
  return {
    type: TABLE_CLEAR_SELECTED,
  };
}

export function changeSearchText(value) {
  return {
    type: TABLE_SEARCH_TEXT_CHANGE,
    payload: {
      value,
    },
  };
}

export function setCheckedFilters(checkedFilters) {
  return {
    type: TABLE_SET_CHECKED_FILTERS,
    payload: {
      checkedFilters,
    },
  };
}

/**
 * This method is only used in user management for now
 */

export function applyDirtyChange(itemIds, checked) {
  return {
    type: TABLE_DIRTY_CHANGE,
    payload: {
      itemIds,
      checked,
    },
  };
}
