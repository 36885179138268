const actions = {
  PACKAGES_FETCH_START: 'benefits_envelope/fetch_start',
  PACKAGES_FETCH_SUCCESS: 'benefits_envelope/fetch_success',
  PACKAGES_FETCH_FAILED: 'benefits_envelope/fetch_failed',
  REWARDS_FETCH_START: 'benefits_envelope_rewards/fetch_start',
  REWARDS_FETCH_SUCCESS: 'benefits_envelope_rewards/fetch_success',
  REWARDS_FETCH_FAILED: 'benefits_envelope_rewards/fetch_failed',
  ALL_REWARDS_FETCH_START: 'benefits_envelope_all_rewards/fetch_start',
  ALL_REWARDS_FETCH_SUCCESS: 'benefits_envelope_all_rewards/fetch_success',
  ALL_REWARDS_FETCH_FAILED: 'benefits_envelope_all_rewards/fetch_failed',
  PACKAGES_CLEAR: 'benefits_envelope/clear_data',
  REWARDS_SELECTION_CHANGE: 'benefits_envelope/rewards_change',
  REWARDS_SELECTION_SUBMIT: 'benefits_envelope/rewards_submit',
  REWARDS_SELECTION_SUBMIT_SUCCESS: 'benefits_envelope/rewards_submit_success',
  REWARDS_SELECTION_SUBMIT_FAILED: 'benefits_envelope/rewards_save_failed',
  RESET_DATA: 'benefits_envelope/reset_data',
  FORECAST_REWARDS_CHANGE: 'FORECAST_REWARDS_CHANGE',
  FILE_UPLOAD: 'benefits_envelope/report_upload_start',
  FILE_UPLOAD_SUCCESS: 'benefits_envelope/report_upload_success',
  FILE_UPLOAD_ERROR: 'benefits_envelope/report_upload_error',
  FILE_UPLOAD_DIALOG_TOGGLE: 'benefits_envelope/upload_dialog_toggle',
  UPDATE_EIB_CONFIGURATION: 'benefits_envelope/update_eib_configuration',
  UPDATE_EIB_CONFIGURATION_SUCCESS: 'benefits_envelope/update_eib_configuration_success',
  UPDATE_EIB_CONFIGURATION_FAILED: 'benefits_envelope/update_eib_configuration_failed',
  FORECAST_REWARDS_STATUS: 'benefits_envelope/forecast_rewards_status',
  GET_ALL_REWARDS: 'benefits_envelope/get_all_rewards',
  GET_ALL_REWARDS_SUCCESS: 'benefits_envelope/get_all_rewards_success',
  GET_ALL_REWARDS_FAILED: 'benefits_envelope/get_all_rewards_failed',
  FORECAST_REWARDS_START: 'benefits_envelope/forecast_rewards_start',
  FORECAST_REWARDS_SUCCESS: 'benefits_envelope/forecast_rewards_success',
  FORECAST_REWARDS_FAILED: 'benefits_envelope/forecast_rewards_failed',
};

export default actions;
