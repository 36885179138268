import * as R from 'ramda';

// MIO: this should be split into separate files.
const environment = process && process.env && process.env.NODE_ENV;
const apiEnv = (process && process.env && process.env.REACT_APP_API_ENV) || 'dev';
const apiTenant = (process && process.env && process.env.REACT_APP_API_TENANT_NAME) || 'unilever';
const apiTenantAbbr = (process && process.env && process.env.REACT_APP_API_TENANT_ABBR) || 'unl';

const apiTenantDomain = process && process.env && process.env.REACT_APP_TENANT_DOMAIN;
const apiTenantClientId = process && process.env && process.env.REACT_APP_CLIENT_ID;
const apiPostLogoutClientRedirect =
  process && process.env && process.env.REACT_APP_POST_LOGOUT_CLIENT_REDIRECT;
const apiGoogleTrackingID = process && process.env && process.env.REACT_APP_GOOGLE_TRACKING_ID;

export const API_VERSIONS = {
  V1: 'v1',
  V2: 'v2',
};

export const BASE_URI = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;

export const BASE_URI_LOGIN = `${window.location.protocol}//${window.location.hostname}`;

export const DEFAULT_CONFIG = {
  API: {
    authority: `https://${apiTenantAbbr}-${apiEnv}-apim.azure-api.net/sts`,
    organization: `https://${apiEnv}-api-${apiTenant}.uflexreward.com/org/v1`,
    articles: `https://${apiEnv}-api-${apiTenant}.uflexreward.com/articles/v1`,
    rewards: `https://${apiEnv}-api-${apiTenant}.uflexreward.com/rewards/v1`,
    rewardsV2: `https://${apiEnv}-api-${apiTenant}.uflexreward.com/rewards/v2`,
    cms: `https://${apiEnv}-api-${apiTenant}.uflexreward.com/cms/v1`,
    auth: `https://${apiEnv}-api-${apiTenant}.uflexreward.com/auth/v1`,
    shares: `https://${apiEnv}-api-${apiTenant}.uflexreward.com/shares/v1`,
    modeller: `https://${apiEnv}-api-${apiTenant}.uflexreward.com/modeller/v1`,
    modellerV2: `https://${apiEnv}-api-${apiTenant}.uflexreward.com/modeller/v2`,
    mobility: `https://${apiEnv}-api-${apiTenant}.uflexreward.com/mobility/v1`,
    configuration: `https://${apiEnv}-api-${apiTenant}.uflexreward.com/config/v1`,
    configurationV2: `https://${apiEnv}-api-${apiTenant}.uflexreward.com/config/v2`,
    notifications: `https://${apiEnv}-api-${apiTenant}.uflexreward.com/notifications/v1`,
    fx: `https://${apiEnv}-api-${apiTenant}.uflexreward.com/foreignExchange/v1`,
    feedback: `https://${apiEnv}-api-${apiTenant}.uflexreward.com/feedback/v1`,
    fixedCosts: `https://${apiEnv}-api-${apiTenant}.uflexreward.com/fixed-costs`,
    ltiPlans: `https://${apiEnv}-api-${apiTenant}.uflexreward.com/lti`,
    workspace: `https://${apiEnv}-api-${apiTenant}.uflexreward.com/workspace/v1`,
    benchmark: `https://${apiEnv}-api-${apiTenant}.uflexreward.com/benchmark/v1`,
    reports: `https://${apiEnv}-api-${apiTenant}.uflexreward.com/reports/v1`,
    reportsV2: `https://${apiTenantAbbr}-${apiEnv}-apim.azure-api.net/reports-v2/v1`,
    audit: `https://${apiEnv}-api-${apiTenant}.uflexreward.com/audit/v1`,
    connectors: `https://${apiEnv}-api-${apiTenant}.uflexreward.com/connectors/v1`,
    benefitsEnvelope: `https://${apiEnv}-api-${apiTenant}.uflexreward.com/benefits-envelope/v1`,
    benefitsEnvelopeAudit: `https://${apiEnv}-api-${apiTenant}.uflexreward.com/benefits-envelope-audit/v1`,
    payscales: `https://${apiEnv}-api-${apiTenant}.uflexreward.com/payscales/v1`,
  },
  AUTH: {
    client_id: apiTenantClientId,
    redirect_uri: `${BASE_URI}/callback`,
    response_type: 'token id_token',
    scope: 'openid juno-api',
    automaticSilentRenew: true,
    silent_redirect_uri: `${BASE_URI}/renew`,
    post_logout_redirect_uri: BASE_URI,
    tenant_domain: '',
    postLogoutClientRedirect: BASE_URI,
  },
  defaultTheme: 'base',
  platformName: 'Total Rewards System',
  GoogleTrackingID: '',
};

const AUTH_LOGIN = {
  client_id: apiTenantClientId,
  redirect_uri: `${BASE_URI_LOGIN}/callback`,
  response_type: 'token id_token',
  scope: 'openid juno-api',
  automaticSilentRenew: true,
  silent_redirect_uri: `${BASE_URI_LOGIN}/renew`,
  post_logout_redirect_uri: BASE_URI_LOGIN,
  tenant_domain: apiTenantDomain,
  postLogoutClientRedirect: apiPostLogoutClientRedirect,
};
export const CONFIG_AUTH =
  environment === 'development' || environment === 'test' ? DEFAULT_CONFIG.AUTH : AUTH_LOGIN;

export const CONFIG =
  environment === 'development' || environment === 'test' ? DEFAULT_CONFIG : window.JUNO_CONFIG;

export const trackingID = apiGoogleTrackingID;

const organizationBaseApi = R.path(['API', 'organization'], CONFIG);
const articlesBaseApi = R.path(['API', 'articles'], CONFIG);
const rewardsBaseApi = R.path(['API', 'rewards'], CONFIG);
const rewardsV2BaseApi = R.path(['API', 'rewardsV2'], CONFIG);
const cmsBaseApi = R.path(['API', 'cms'], CONFIG);
const foreignExchangeBaseApi = R.path(['API', 'fx'], CONFIG);
const authBaseApi = R.path(['API', 'auth'], CONFIG);
const sharesBaseApi = R.path(['API', 'shares'], CONFIG);
const modellerBaseApi = R.path(['API', 'modeller'], CONFIG);
const modellerV2BaseApi = R.path(['API', 'modellerV2'], CONFIG);
const authorityBaseApi = R.path(['API', 'authority'], CONFIG);
const mobilityBaseApi = R.path(['API', 'mobility'], CONFIG);
const configurationBaseApi = R.path(['API', 'configuration'], CONFIG);
const configurationV2BaseApi = R.path(['API', 'configurationV2'], CONFIG);
const notificationsBaseApi = R.path(['API', 'notifications'], CONFIG);
const feedbackBaseApi = R.path(['API', 'feedback'], CONFIG);
const fixedCostsBaseApi = R.path(['API', 'fixedCosts'], CONFIG);
const ltiPlansBaseApi = R.path(['API', 'ltiPlans'], CONFIG);
const workspaceBaseApi = R.path(['API', 'workspace'], CONFIG);
const benchmarkBaseApi = R.path(['API', 'benchmark'], CONFIG);
const reportsBaseApi = R.path(['API', 'reports'], CONFIG);
const reportsV2BaseApi = R.path(['API', 'reportsV2'], CONFIG);
const auditBaseApi = R.path(['API', 'audit'], CONFIG);
const connectorsBaseApi = R.path(['API', 'connectors'], CONFIG);
const benefitsEnvelopeBaseApi = R.path(['API', 'benefitsEnvelope'], CONFIG);
const benefitsEnvelopeAuditBaseApi = R.path(['API', 'benefitsEnvelopeAudit'], CONFIG);
const payscalesBaseApi = R.path(['API', 'payscales'], CONFIG);

export const articles = {
  base: articlesBaseApi,
  categories: `${articlesBaseApi}/articles/categories`,
  list: `${articlesBaseApi}/articles/search`,
  articlesRoot: `${articlesBaseApi}/articles`,
  publish: (id) => `${articlesBaseApi}/articles/${id}/publish`,
  revertToDraft: (id) => `${articlesBaseApi}/articles/${id}/revertToDraft`,
};

export const rewards = {
  base: rewardsBaseApi,
  variables: `${rewardsBaseApi}/variable`,
  category: `${rewardsBaseApi}/category`,
  categories: `${rewardsBaseApi}/categories`,
  rewardsRoot: `${rewardsBaseApi}/rewards`,
  rewardsV2Root: `${rewardsV2BaseApi}/rewards`,
  allRewards: (id) => `${rewardsBaseApi}/flexPackages/${id}/allRewards`,
  rewardsExceptions: `${rewardsBaseApi}/analytics/overview/exceptions`,
  rewardsOverview: `${rewardsBaseApi}/analytics/overview/rewards`,
  systemVariables: `${rewardsBaseApi}/systemVariable`,
  linkableRewards: `${rewardsBaseApi}/rewards/rules/linkedReward/operands`,
  formulaOperand: `${rewardsBaseApi}/rewards/rules/formulas/operands`,
  formulaOperators: `${rewardsBaseApi}/rewards/rules/formula/operators`,
  exceptions: `${rewardsBaseApi}/exceptions`,
  exceptionsErrorsDownload: `${rewardsBaseApi}/exceptions/errors`,
  exceptionsPublish: `${rewardsBaseApi}/exceptions/publish`,
  exceptionsDelete: `${rewardsBaseApi}/exceptions/delete`,
  flex: `${rewardsBaseApi}/flexPackages`,
  employeeRewardsCategories: `${rewardsBaseApi}/employeeReward`,
  versioning: `${rewardsBaseApi}/rewards/version-group`,
  typesCategories: `${rewardsBaseApi}/employee/typesCategories`,
  compensationGradeProfiles: `${rewardsBaseApi}/employee/compensationGradeProfiles`,
  overallImpact: `${rewardsBaseApi}/overallImpact`,
  downloadCsv: `${rewardsBaseApi}/overallImpact/DownloadEmployeesCsv`,
  mobilityLineItems: `${rewardsBaseApi}/lineItems`,
  employeeFlex: `${rewardsBaseApi}/employeeFlexPackage`,
  employeeFlexibleRewards: `${rewardsBaseApi}/employeeFlexibleRewards`,
  eligibilityRules: `${rewardsBaseApi}/eligibilityRules`,
  eligibilityOperators: `${rewardsBaseApi}/eligibilityRules/lookup/operators`,
  eligibilityOperands: `${rewardsBaseApi}/eligibilityRules/lookup/operands`,
  employeeFlexibleStatement: `${rewardsBaseApi}/employeeFlexibleStatements`,
  downloadEnrollmentWindowsReport: (filename) =>
    `${rewardsBaseApi}/employeeFlexPackage/enrollmentWindow/downloadReport/${filename}`,
};

export const auth = {
  base: authBaseApi,
  users: `${authBaseApi}/user`,
  roles: `${authBaseApi}/Role`,
  validate: `${authBaseApi}/tenants/validate`,
};

export const organization = {
  base: organizationBaseApi,
  employeeProfile: `${organizationBaseApi}/employee/profileInfo`,
  employeeProfileById: `${organizationBaseApi}/employee/profile`,
  employeeProfileSettings: `${organizationBaseApi}/employee/profileSettings`,
  lookupCountries: `${organizationBaseApi}/lookup/countries`,
  featureModules: `${organizationBaseApi}/lookup/featureModules`,
  profileData: `${organizationBaseApi}/lookup/profileData`,
  employeeSearch: `${organizationBaseApi}/employee/search`,
  settings: `${organizationBaseApi}/settings`,
  currency: `${organizationBaseApi}/organization/currency`,
  countries: `${organizationBaseApi}/settings/countries`,
  employeeValidations: `${organizationBaseApi}/employeeValidations`,
};

export const authority = {
  base: authorityBaseApi,
  tenant: 'unilver',
};

export const foreignExchange = {
  base: foreignExchangeBaseApi,
  fxSources: `${foreignExchangeBaseApi}/foreignExchange/sources`,
  lookupCurrencies: `${foreignExchangeBaseApi}/foreignExchange/currencies`,
};

export const cms = {
  sharesUploadTemplate: `${cmsBaseApi}/SharesUploadTemplate`,
  exceptionTemplates: `${cmsBaseApi}/ExceptionTemplates?templateName=`,
  exchangeRatesTemplate: `${cmsBaseApi}/ExchangeRatesTemplate`,
  labels: `${cmsBaseApi}/text`,
  theme: `${cmsBaseApi}/themeConfig`,
  assets: `${cmsBaseApi}/assets`,
  videoPlatforms: `${cmsBaseApi}/videoPlatforms`,
};

export const shares = {
  base: sharesBaseApi,
  employees: `${sharesBaseApi}/employees`,
  configuration: `${sharesBaseApi}/configuration`,
  providers: `${sharesBaseApi}/providers`,
};

export const modeller = {
  base: modellerBaseApi,
  variables: `${modellerBaseApi}/variables/metadata`,
  employeeVariables: `${modellerBaseApi}/variables`,
  employeeVariablesV2: `${modellerV2BaseApi}/variables`,
  employeeSearch: `${modellerBaseApi}/employee/search`,
  model: `${modellerBaseApi}/modeller/model`,
  modelV2: `${modellerV2BaseApi}/modeller/model`,
  hypoEmployeeVariables: `${modellerBaseApi}/variables/hypo-employee`,
  hypoEmployeeVariablesV2: `${modellerV2BaseApi}/variables/hypo-employee`,
  downloadOutput: `${modellerBaseApi}/modeller/download`,
  configuration: `${modellerBaseApi}/modeller/configuration`,
  employeeDimensions: `${modellerBaseApi}/employee/employeeDimensions`,
  employeeDefaultDimensionValues: `${modellerBaseApi}/modeller/defaultDimensionValues`,
  employeeDimensionValuesByDefaultWithCorrelation: (dimensionId) =>
    `${modellerBaseApi}/modeller/dimensionValues/${dimensionId}`,
  employeeDimensionValuesByDefault: (dimensionId) =>
    `${modellerBaseApi}/modeller/dimensionValuesByDefault/${dimensionId}`,
};

export const mobility = {
  base: mobilityBaseApi,
  locations: `${mobilityBaseApi}/assignments/locations`,
  upload: `${mobilityBaseApi}/assignments/upload`,
};

export const configuration = {
  base: configurationBaseApi,
  authentication: `${configurationBaseApi}/authentication`,
  hrSystem: `${configurationBaseApi}/workday`,
  currency: `${configurationBaseApi}/currency`,
  countries: `${configurationBaseApi}/countries`,
  mobility: `${configurationBaseApi}/mobility`,
  shares: `${configurationBaseApi}/shares`,
  validation: `${configurationBaseApi}/validation`,
  featureToggles: `${configurationV2BaseApi}/featureToggles`,
  configurations: `${configurationV2BaseApi}/Configurations/inactivityTimeout`,
};

export const notifications = {
  base: `${notificationsBaseApi}/notifications`,
  ws: `${notificationsBaseApi}/hubs/notifications`,
};

export const feedback = {
  base: `${feedbackBaseApi}`,
  getTotalScore: `${feedbackBaseApi}/feedback/getTotalScore`,
  getRewardsFeedback: `${feedbackBaseApi}/feedback/getRewardsFeedback`,
  getRewardFeedback: `${feedbackBaseApi}/feedback/getRewardFeedback`,
  submitFeedback: `${feedbackBaseApi}/feedback/submit`,
  getFeedbackStats: `${feedbackBaseApi}/Statistic`,
  getRewardScore: `${feedbackBaseApi}/feedback/getRewardScore`,
  searchRewardFeedback: `${feedbackBaseApi}/feedback/search`,
  exportFeedbackStatsCSV: `${feedbackBaseApi}/feedback/report/`,
};

export const fixedCosts = ({ version = API_VERSIONS.V1 } = {}) =>
  (() => {
    const serviceRoot = `${fixedCostsBaseApi}/${version}/fixedCosts`;
    return {
      serviceRoot,
      search: `${serviceRoot}/search`,
      rules: (id) => `${serviceRoot}/${id}/rules`,
      publish: (id) => `${serviceRoot}/${id}/publish`,
      moveToDraft: (id) => `${serviceRoot}/${id}/moveToDraft`,
      getFixedCostById: (id) => `${serviceRoot}/${id}`,
    };
  })();

export const ltiPlans = ({ version = API_VERSIONS.V1 } = {}) =>
  (() => {
    const serviceRoot = `${ltiPlansBaseApi}/${version}/LongTermIncentivePlans`;
    const employeeLtiPlansServiceRoot = `${ltiPlansBaseApi}/${version}/EmployeeLongTermIncentivePlans`;

    return {
      serviceRoot,
      search: `${serviceRoot}/search`,
      rules: (id) => `${serviceRoot}/${id}/rules`,
      publish: (id) => `${serviceRoot}/${id}/publish`,
      moveToDraft: (id) => `${serviceRoot}/${id}/moveToDraft`,
      getDistribution: (id) => `${serviceRoot}/${id}/distribution`,
      getLtiPlanById: (id) => `${serviceRoot}/${id}`,
      getEmployeeLtiPlans: (employeeId) => `${employeeLtiPlansServiceRoot}/${employeeId}`,
    };
  })();

export const workspace = {
  base: `${workspaceBaseApi}`,
};

export const benchmark = {
  base: `${benchmarkBaseApi}`,
  globalSettings: `${benchmarkBaseApi}/globalSettings`,
  globalCurrent: `${benchmarkBaseApi}/globalSettings/current`,
  categoriesMappings: `${benchmarkBaseApi}/globalSettings/categoriesMappings`,
  benchmarking: `${benchmarkBaseApi}/benchmarking`,
  identifiers: `${benchmarkBaseApi}/globalSettings/identifiers`,
  initiateNew: `${benchmarkBaseApi}/globalSettings/new`,
};

export const reports = {
  base: `${reportsBaseApi}`,
  getReports: `${reportsBaseApi}/reports`,
  exportReport: `${reportsBaseApi}/reports`,
  countryRewardsExport: `${reportsBaseApi}/CountryRewardsReport`,
  employeeElections: `${reportsBaseApi}/FlexibleRewardsReports/employeeElections`,
  executiveReportSummary: `${reportsBaseApi}/ExecutiveReport/summary`,
  details: `${reportsBaseApi}/ExecutiveReport/details`,
  download: `${reportsBaseApi}/ExecutiveReport/download`,
  externalReport: `${reportsBaseApi}/ExternalReport`,
  flexReport: `${reportsV2BaseApi}/reports`,
  flexEmbedReport: `${reportsV2BaseApi}/reports/embed`,
  configuration: `${reportsBaseApi}/reports/configuration`,
};

export const audit = {
  base: `${auditBaseApi}`,
  getAudit: `${auditBaseApi}/audit`,
  userSearch: `${auditBaseApi}/audit/search`,
  getAuditRowFile: `${auditBaseApi}/audit/download`,
  generateLineFile: `${auditBaseApi}/audit/generateFile/line`,
  generateLinesFile: `${auditBaseApi}/audit/generateFile/lines`,
};

export const connectors = {
  employeesUpload: `${connectorsBaseApi}/employeesUpload`,
  employeesUploadError: `${connectorsBaseApi}/employeesUpload/errors`,
  employeesUploadTemplate: `${connectorsBaseApi}/employeesUpload/template`,
  sharesUpload: `${connectorsBaseApi}/sharesUpload`,
  sharesUploadError: `${connectorsBaseApi}/sharesUpload/errors`,
};

export const benefitsEnvelope = {
  getPackages: `${benefitsEnvelopeBaseApi}/packages/filtered`,
  getPackageRewards: (id) => `${benefitsEnvelopeBaseApi}/packages/${id}`,
  getAllRewards: (id) => `${benefitsEnvelopeBaseApi}/packages/${id}/rewards`,
  submitRewards: (id) => `${benefitsEnvelopeBaseApi}/packages/${id}`,
  generateReport: (id, type) => `${benefitsEnvelopeBaseApi}/packages/${id}/generateReport/${type}`,
  downloadReport: (filename) => `${benefitsEnvelopeBaseApi}/packages/downloadReport/${filename}`,
  downloadForecastReport: (filename) =>
    `${benefitsEnvelopeBaseApi}/forecastRewards/downloadReport/${filename}`,
  downloadTemplate: (id, type) =>
    `${benefitsEnvelopeBaseApi}/packages/${id}/configurationTemplate/${type}`,
  reportConfiguration: (id, type) =>
    `${benefitsEnvelopeBaseApi}/packages/${id}/configuration/${type}`,
  forecastRewards: `${benefitsEnvelopeBaseApi}/forecastRewards`,
  eibConfiguration: (id) => `${benefitsEnvelopeBaseApi}/packages/${id}/eibConfiguration`,
};

export const benefitsEnvelopeAudit = {
  getAudits: `${benefitsEnvelopeAuditBaseApi}/audits/filtered`,
  getReports: `${benefitsEnvelopeAuditBaseApi}/reports/report`,
};

export const payscales = (() => {
  const serviceRoot = `${payscalesBaseApi}/payscales`;

  return {
    getDimensions: `${serviceRoot}/dimensions`,
    handleConfiguration: `${serviceRoot}/configuration`,
    getSystemPayscales: (countryCode) => `${serviceRoot}/payscale/${countryCode}`,
    exportPayscale: (countryCode) => `${serviceRoot}/export/${countryCode}`,
    uploadPayscale: (countryCode) => `${serviceRoot}/import/${countryCode}`,
  };
})();
