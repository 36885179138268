import * as R from 'ramda';
import { transformPdfTextContent } from 'modules/common/helpers';

export const transformPdfOutputTextContent = (rewards) => {
  const transformedSelectedRewards = R.clone(rewards);
  R.keys(rewards).forEach((rewardId) => {
    transformedSelectedRewards[rewardId] = {
      ...rewards[rewardId],
      name: transformPdfTextContent(rewards[rewardId].name),
    };
  });
  return transformedSelectedRewards;
};

export default transformPdfOutputTextContent;
