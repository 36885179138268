"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.TrackingManager = exports.PREFIX_CUSTOM_EVENTS = void 0;
var _reactGa = _interopRequireDefault(require("react-ga4"));
var _objects = require("../objects");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
var PREFIX_CUSTOM_EVENTS = '[Custom]';
exports.PREFIX_CUSTOM_EVENTS = PREFIX_CUSTOM_EVENTS;
var TrackingManager = {
  init: function init() {
    var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      trackingId = _ref.trackingId,
      options = _ref.options;
    _reactGa["default"].initialize(trackingId, options);
  },
  setConfig: function setConfig() {
    var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      trackingId = _ref2.trackingId,
      options = _ref2.options;
    if (!_reactGa["default"].isInitialized) return;
    _reactGa["default"].gtag('config', trackingId, options);
  },
  trackPage: function trackPage() {
    var _ref3 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      _ref3$pagePath = _ref3.pagePath,
      pagePath = _ref3$pagePath === void 0 ? '' : _ref3$pagePath,
      _ref3$pageLocation = _ref3.pageLocation,
      pageLocation = _ref3$pageLocation === void 0 ? '' : _ref3$pageLocation,
      pageTitle = _ref3.pageTitle,
      _ref3$options = _ref3.options,
      options = _ref3$options === void 0 ? {} : _ref3$options;
    if (!_reactGa["default"].isInitialized) return;
    _reactGa["default"].send(_objectSpread({
      hitType: 'pageview',
      page: pagePath,
      location: pageLocation,
      title: pageTitle
    }, options));
  },
  trackPageWithoutAutomaticSendPageView: function trackPageWithoutAutomaticSendPageView() {
    var _ref4 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      trackingId = _ref4.trackingId,
      _ref4$pagePath = _ref4.pagePath,
      pagePath = _ref4$pagePath === void 0 ? '' : _ref4$pagePath,
      _ref4$pageLocation = _ref4.pageLocation,
      pageLocation = _ref4$pageLocation === void 0 ? '' : _ref4$pageLocation,
      pageTitle = _ref4.pageTitle,
      options = _ref4.options;
    if (!_reactGa["default"].isInitialized) return;
    _reactGa["default"].gtag('config', trackingId, {
      send_page_view: false
    });
    _reactGa["default"].send(_objectSpread({
      hitType: 'pageview',
      page: pagePath,
      title: pageTitle,
      location: pageLocation
    }, options));
  },
  trackEvent: function trackEvent() {
    var _ref5 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      eventType = _ref5.eventType,
      eventDescription = _ref5.eventDescription,
      _ref5$prefixEvent = _ref5.prefixEvent,
      prefixEvent = _ref5$prefixEvent === void 0 ? PREFIX_CUSTOM_EVENTS : _ref5$prefixEvent,
      options = _ref5.options;
    var actionDescription = (0, _objects.isBlank)(prefixEvent) ? eventDescription : "".concat(prefixEvent, " ").concat(eventDescription);
    if (!_reactGa["default"].isInitialized) return;
    _reactGa["default"].event(_objectSpread({
      category: eventType,
      action: actionDescription
    }, options));
  }
};
exports.TrackingManager = TrackingManager;
var _default = TrackingManager;
exports["default"] = _default;