"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var StyledControlCard = _styledComponents["default"].div.withConfig({
  displayName: "ControlCardstyle__StyledControlCard",
  componentId: "sc-18olm88-0"
})(["", ";"], function (props) {
  var checked = props.checked;
  var _props$theme = props.theme,
    color = _props$theme.palette,
    spacing = _props$theme.spacing,
    typography = _props$theme.typography,
    genericBorderRadius = _props$theme.extra.genericBorderRadius;
  return (0, _styledComponents.css)([".control-card{display:flex;margin-bottom:", ";border:1px solid ", ";box-shadow:0px 1px 8px rgba(0,0,0,0.04);border-radius:", ";background-color:", ";overflow:hidden;.control-card-checkbox{display:flex;flex-direction:column;justify-content:center;padding:", " ", ";background-color:", ";i{font-size:", ";}}.control-card-content{padding:", " calc(", " - 2px);.control-card-title{margin-bottom:", ";color:", ";font-size:", ";font-weight:", ";}.control-card-description{color:", ";font-size:", ";font-weight:", ";}}}"], checked ? spacing.base : spacing.small, color.accent.tau, genericBorderRadius, color.basic.white, spacing.smallPlus, spacing.xxSmall, color.utility.bodyBackground, typography.size.h2, spacing.smallPlus, spacing.smallPlus, spacing.tiny, color.gray.alpha, typography.size.base, typography.weight.bold, color.basic.gray, typography.size.table, typography.weight.normal);
});
StyledControlCard.displayName = 'StyledControlCard';
var _default = StyledControlCard;
exports["default"] = _default;