import * as R from 'ramda';
import { offsetTimezone } from '@trs/utils';
import { toBinary } from '@trs/utils/lib/strings';
import { propNames } from '../api/baseValues';

const { annualSalary, currency, exchangeSource } = propNames;
const IS_DATETIME_TYPE = 'datetime';

const createModels = (mainState, instancesReducer) => {
  const {
    countries,
    employeeDimensions: { dimensions, dimensionsData },
  } = mainState;
  const { instanceFields } = instancesReducer;
  const models = {
    employees: [],
  };
  const { id: defaultDimensionId } = R.find(R.propEq('isDefault', true))(dimensions);

  Object.keys(instanceFields).forEach((instanceKey) => {
    const fields = instanceFields[instanceKey];
    const { selectedCountry } = dimensionsData[instanceKey];
    const countryValuesForDefault = R.find(R.propEq('code', selectedCountry))(countries);
    const employeeModel = {
      annualSalary: 0,
      employee: {},
      currencyDetails: {},
      employeeCurrency: R.path(['instances', instanceKey, 'defaultCurrency'], instancesReducer),
    };

    R.forEach((key) => {
      const { value: fieldValue, id } = R.prop(key, fields);

      if (R.equals(id, defaultDimensionId)) {
        employeeModel.employee.Currency = countryValuesForDefault.defaultCurrency;
        employeeModel.employee[currency] = countryValuesForDefault.defaultCurrency;
      }

      switch (id) {
        case annualSalary: {
          employeeModel.annualSalary = fieldValue;
          employeeModel.employee[id] = fieldValue;
          break;
        }
        case currency: {
          employeeModel.currencyDetails.currencyCode = fieldValue;
          break;
        }
        case exchangeSource: {
          employeeModel.currencyDetails.fxSourceId = fieldValue;
          break;
        }
        default:
          employeeModel.employee[id] =
            R.path([key, 'type'], fields) === IS_DATETIME_TYPE
              ? offsetTimezone(fieldValue)
              : fieldValue;
          break;
      }
    }, R.keys(fields));

    R.forEach((key) => {
      employeeModel.employee[key] =
        typeof employeeModel.employee[key] === 'string'
          ? toBinary(employeeModel.employee[key])
          : toBinary(employeeModel.employee[key].toString());
    }, R.keys(employeeModel.employee));

    models.employees.push(employeeModel);
  });

  return models;
};

export default createModels;
