import * as R from 'ramda';
import { CONFLICT_STATUS_CODE } from '@trs/config';
import { getErrorStatus } from '@trs/utils';
import { showBanner } from '../../../../actions/globalActions';
import { TextOnly } from '../../../common/Text';

const BM_STATUS = {
  ALL: 'ALL',
  NOT_SUBMITTED: 'NOT_SUBMITTED',
  SUBMITTED: 'SUBMITTED',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
};

export const ROUTES_FOR_ID = {
  localPreparation: {
    url: '/plan/local-preparation',
    step: {
      stepName: 'localPreparation',
      rejectionTitleLabel: 'LOCAL_PREPARATION_REJECTION_TITLE',
    },
  },
  salaryIncrease: {
    url: '/plan/salary-increase',
    step: {
      stepName: 'salaryIncrease',
      rejectionTitleLabel: 'SALARY_INCREASE_REJECTION_TITLE',
    },
  },
  payScaleIncrease: {
    url: '/plan/payscale-increase',
    step: {
      stepName: 'payscaleIncrease',
      rejectionTitleLabel: 'PAYSCALE_INCREASE_REJECTION_TITLE',
    },
  },
};

const getTextOnlyError = (error) => {
  const message = R.path(['response', 'data', 'errors', 0, 'message'], error);
  return TextOnly({ path: `benchmarking.${message}` }) || message;
};

export const dispatchError = (error, dispatch) => {
  let errorMessage = getTextOnlyError(error) || R.prop('message', error);
  const status = getErrorStatus(error);

  if (status === CONFLICT_STATUS_CODE) {
    errorMessage = TextOnly({ path: 'generic.CONFLICT_BANNER' });
  }

  dispatch(
    showBanner({
      type: 'error',
      content: errorMessage,
    })
  );
};

export default BM_STATUS;
