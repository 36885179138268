import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Sticky from 'react-sticky-el';
import Layout from '../layout/Layout';
import StyledStickyContainer from './StickyContainer.style';

const DEFAULT_RECHECK_INTERVAL = 100;

const StickyContainer = ({ children, position, className, isFullWidth, noMargin, ...rest }) => (
  <StyledStickyContainer
    position={position}
    isFullWidth={isFullWidth}
    className={className}
    noMargin={noMargin}
  >
    <Sticky
      positionRecheckInterval={DEFAULT_RECHECK_INTERVAL}
      mode={position}
      {...rest}
      className="element-sticky"
    >
      <Layout className="layout">{children}</Layout>
    </Sticky>
  </StyledStickyContainer>
);

StickyContainer.defaultProps = {
  position: 'bottom',
  isFullWidth: true,
  className: '',
  noMargin: false,
};

StickyContainer.propTypes = {
  className: PropTypes.string,
  isFullWidth: PropTypes.bool,
  children: PropTypes.node.isRequired,
  position: PropTypes.oneOf(['bottom', 'top']),
  noMargin: PropTypes.bool,
};

export default memo(StickyContainer);
