import * as R from 'ramda';
import { Log } from '@trs/utils';

const getFileNameByContentDisposition = (headers) => {
  const disposition = R.path(['content-disposition'], headers);
  if (!disposition) return null;

  let fileName = '';
  const matches = R.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/, disposition);
  if (!R.isEmpty(matches) && matches[1]) {
    fileName = R.replace(/['"]/g, '', matches[1]);
  }

  return fileName;
};

export const processFile = (response, fileName, action = null) => {
  try {
    const blob = new Blob([response.data], {
      type: response.headers['content-type'],
    });

    const name = getFileNameByContentDisposition(response.headers) || fileName;

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, name);
    } else {
      const objectUrl = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = objectUrl;
      link.setAttribute('download', name); // or any other extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      if (typeof action === 'function') {
        action({ name });
      }
    }
  } catch (exception) {
    Log.warn(
      `We have encountered an issue while processing file: ${fileName}. The error body is: ${exception}`
    );
  }
};

export default {
  processFile,
};
