"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var statisticLarge = '40px';
var statisticSmall = '32px';
var StyledStatistic = _styledComponents["default"].div.withConfig({
  displayName: "Statisticstyle__StyledStatistic",
  componentId: "sc-14qq94w-0"
})(["", ""], function (props) {
  var color = props.color,
    labelColor = props.labelColor,
    size = props.size,
    _props$theme = props.theme,
    typography = _props$theme.typography,
    spacing = _props$theme.spacing;
  var _typography$size = typography.size,
    h2 = _typography$size.h2,
    table = _typography$size.table,
    base = _typography$size.base;
  var countFontSize = size === 'large' ? statisticLarge : statisticSmall;
  var labelFontSize = 0;
  switch (size) {
    case 'large':
      labelFontSize = h2;
      break;
    case 'medium':
      labelFontSize = base;
      break;
    default:
      labelFontSize = table;
  }
  return (0, _styledComponents.css)(["h3{font-size:", ";color:", ";margin-bottom:", ";}p{font-size:", ";color:", ";line-height:", ";margin-bottom:0;}"], countFontSize, color, spacing.xTiny, labelFontSize, labelColor, typography.lineHeight.paragraphSmall);
});
StyledStatistic.displayName = 'StyledStatistic';
var _default = StyledStatistic;
exports["default"] = _default;