const types = {
  FETCH_COUNTRIES_PENDING: 'FETCH_COUNTRIES_PENDING',
  FETCH_COUNTRIES_SUCCESS: 'FETCH_COUNTRIES_SUCCESS',
  FETCH_CURRENCIES_PENDING: 'FETCH_CURRENCIES_PENDING',
  FETCH_CURRENCIES_SUCCESS: 'FETCH_CURRENCIES_SUCCESS',
  COUNTRIES_SHOW_ONLY_SELECTED: 'COUNTRIES_SHOW_ONLY_SELECTED',
  COUNTRIES_SELECT_ALL: 'COUNTRIES_SELECT_ALL',
  COUNTRIES_SELECT_ROW: 'COUNTRIES_SELECT_ROW',
  COUNTRIES_SORT_CHANGE: 'COUNTRIES_SORT_CHANGE',
  SET_COUNTRY_CURRENCY: 'SET_COUNTRY_CURRENCY',
};

export default types;
