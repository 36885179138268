"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toolbarConfig = exports.removeEmptyParagraph = exports.getToolbarConfig = exports.getMarkupFromEditorState = exports.getEditorState = exports.checkLink = void 0;
var _utils = require("@trs/utils");
var _draftJs = require("draft-js");
var _draftjsToHtml = _interopRequireDefault(require("draftjs-to-html"));
var _htmlToDraftjs = _interopRequireDefault(require("html-to-draftjs"));
var R = _interopRequireWildcard(require("ramda"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
var removeEmptyParagraph = function removeEmptyParagraph() {
  var markup = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return markup.replace('<p></p>', '');
};
exports.removeEmptyParagraph = removeEmptyParagraph;
var getEditorState = function getEditorState(value) {
  try {
    if (!value) return _draftJs.EditorState.createEmpty();
    var blocksFromHTML = (0, _htmlToDraftjs["default"])((0, _utils.safeTrim)(value));
    var state = _draftJs.ContentState.createFromBlockArray(blocksFromHTML.contentBlocks);
    return _draftJs.EditorState.createWithContent(state);
  } catch (ex) {
    _utils.Log.warn('Rich text area exception occurred: ', ex);
    return null;
  }
};
exports.getEditorState = getEditorState;
var unsafeLinksList = ['<iframe', '</iframe>', '<script', '</script>', '<html', '</html>', 'javascript'];
var checkLink = function checkLink(_ref) {
  var target = _ref.target,
    targetOption = _ref.targetOption,
    title = _ref.title;
  var targetCopy = target;
  unsafeLinksList.map(function (item) {
    if (target.includes(item)) {
      targetCopy = targetCopy.replace(item, '');
    }
    return targetCopy;
  });
  return {
    target: targetCopy,
    targetOption: targetOption,
    title: title
  };
};
exports.checkLink = checkLink;
var toolbarConfig = {
  options: ['inline', 'blockType', 'list', 'link'],
  blockType: {
    inDropdown: false,
    options: []
  },
  inline: {
    inDropdown: false,
    options: ['bold', 'italic', 'underline']
  },
  list: {
    inDropdown: false,
    options: ['unordered', 'ordered']
  },
  link: {
    showOpenOptionOnHover: false,
    linkCallback: checkLink
  }
};
exports.toolbarConfig = toolbarConfig;
var getToolbarConfig = function getToolbarConfig(newProps) {
  return R.isNil(newProps) || R.isEmpty(newProps) ? toolbarConfig : _objectSpread(_objectSpread(_objectSpread({}, toolbarConfig), newProps), {}, {
    options: [].concat(_toConsumableArray(toolbarConfig.options), _toConsumableArray(newProps.options))
  });
};
exports.getToolbarConfig = getToolbarConfig;
var getMarkupFromEditorState = function getMarkupFromEditorState(editorState) {
  var rawContentState = (0, _draftJs.convertToRaw)(editorState.getCurrentContent());
  return (0, _utils.safeTrim)(removeEmptyParagraph((0, _draftjsToHtml["default"])(rawContentState)));
};
exports.getMarkupFromEditorState = getMarkupFromEditorState;