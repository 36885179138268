import { isBlank, shortenText } from '@trs/utils';

export default (min, max, isEditable) => {
  if (!isEditable || (isBlank(min) && isBlank(max))) {
    return false;
  }
  return true;
};

// extract plain text from html string
const plainText = text => text.replace(/<[^>]+>/g, '');

const hasHtmlStyling = text =>
  ['<ol>', '<ul>', '<li>', '<strong>', '<em>', '<ins>', 'a href=', '<ins>', '<span style='].some(
    str => text && text.indexOf(str) > 0
  );

const MAX_LENGTH_TEXT_VALUE = 20;

// 'More' button is shown if text is longer than MAX_LENGTH_TEXT_VALUE
// or it includes html styling (lists, links)
export const showMoreButton = (text, maxLength = MAX_LENGTH_TEXT_VALUE) => {
  return (text && plainText(text).length > maxLength) || (text && hasHtmlStyling(text));
};

export const getPlainText = (text, maxLength = 1000) => {
  const plaintext = plainText(text);

  return text && plaintext.length > maxLength
    ? `${shortenText(plaintext, maxLength)}...`
    : plaintext;
};
