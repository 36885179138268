import { createUserManager } from 'redux-oidc';
import { WebStorageStateStore } from 'oidc-client';
import { sessionStorageManager } from '@trs/utils';
import { authority, CONFIG_AUTH } from '../api';

const userManager = createUserManager({
  ...CONFIG_AUTH,
  authority: authority.base,
  filterProtocolClaims: true,
  userStore: new WebStorageStateStore({ store: window.sessionStorage }),
  loadUserInfo: false,
  acr_values: authority.tenant,
  silentRequestTimeout: 30000,
  revokeAccessTokenOnSignout: true,
  metadata: {
    issuer: authority.base,
    authorization_endpoint: `${authority.base}/connect/authorize`,
    revocation_endpoint: `${authority.base}/connect/revocation`,
    userinfo_endpoint: `${authority.base}/connect/userinfo`,
    end_session_endpoint: `${authority.base}/connect/endsession`,
    jwks_uri: `${authority.base}/.well-known/openid-configuration/jwks`,
    check_session_iframe: `${authority.base}/connect/checksession`,
  },
});

export const permissions = {
  MODELLER: {
    REWARD_IMPACT_UPDATE: 'REWARD_IMPACT_UPDATE',
    MODELLER_CONFIGURATION_UPDATE: 'MODELLER_CONFIGURATION_UPDATE',
    MODELLER_CONFIGURATION_READ: 'MODELLER_CONFIGURATION_READ',
    REWARD_PACKAGE_UPDATE: 'REWARD_PACKAGE_UPDATE',
  },
  REPORTS: {
    REPORTS_EXTERNAL_UPDATE: 'REPORTS_EXTERNAL_UPDATE',
    REPORTS_EXTERNAL_READ: 'REPORTS_EXTERNAL_READ',
    REPORTS_CONFIGURATION_UPDATE: 'REPORTS_CONFIGURATION_UPDATE',
    REPORTS_CONFIGURATION_READ: 'REPORTS_CONFIGURATION_READ',
  },
};

userManager.clearTRSData = () => {
  sessionStorageManager.removeItem('profile');
  sessionStorageManager.removeItem('rmProfile');
  sessionStorageManager.removeItem('authorization');
  sessionStorageManager.removeItem('returnPage');
  sessionStorageManager.removeItem(window.location.host);
};

userManager.setProfileData = (data) => {
  sessionStorageManager.setItem('profile', data);
};

userManager.getProfileData = () => sessionStorageManager.getItem('profile');

userManager.setReportsModules = (data) => {
  let objectAuthorization = sessionStorageManager.getItem('authorization');
  objectAuthorization = {
    ...objectAuthorization,
    [permissions.REPORTS.REPORTS_EXTERNAL_UPDATE]: data,
  };
  sessionStorageManager.setItem('authorization', objectAuthorization);
};

userManager.getReportsModules = () => {
  const objectAuthorization = sessionStorageManager.getItem('authorization');
  return objectAuthorization
    ? objectAuthorization[permissions.REPORTS.REPORTS_EXTERNAL_UPDATE]
    : null;
};

userManager.setAuthorizedModules = (data) => {
  sessionStorageManager.setItem('authorization', data);
};

userManager.getAuthorizedModules = () => sessionStorageManager.getItem('authorization');

userManager.setModellerModules = (data) => {
  let objectAuthorization = sessionStorageManager.getItem('authorization');
  objectAuthorization = {
    ...objectAuthorization,
    [permissions.MODELLER.MODELLER_CONFIGURATION_UPDATE]: data,
  };
  sessionStorageManager.setItem('authorization', objectAuthorization);
};

userManager.getModellerModules = () => {
  const objectAuthorization = sessionStorageManager.getItem('authorization');
  return objectAuthorization
    ? objectAuthorization[permissions.MODELLER.MODELLER_CONFIGURATION_UPDATE]
    : null;
};

userManager.setMimicProfile = (data) => {
  const profile = sessionStorageManager.getItem('profile');
  sessionStorageManager.setItem('profile', data);
  sessionStorageManager.setItem('rmProfile', profile);
};

userManager.hasMimicProfile = () =>
  sessionStorageManager.getItem('profile') && sessionStorageManager.getItem('rmProfile');

userManager.clearMimicProfile = () => {
  const rewardManagerProfile = sessionStorageManager.getItem('rmProfile');
  sessionStorageManager.setItem('profile', rewardManagerProfile);
  sessionStorageManager.removeItem('rmProfile');
};

userManager.setCurentLocation = (pathname) => {
  const redirectOnLogoutPage = window.JUNO_CONFIG.redirectOnLogout.find((path) =>
    pathname.includes(path)
  );
  if (redirectOnLogoutPage) {
    sessionStorageManager.setItem('returnPage', pathname);
  }
};

userManager.getCurentLocation = () => sessionStorageManager.getItem('returnPage');

export default userManager;
