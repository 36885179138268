import * as R from 'ramda';
import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import basicInfo from 'modules/cms/labels/flexibleRewards/index';
import { mapResponseToModel } from './helpers';
import userModules from '../../../config/userManager/userModules';
import { filterCountriesByAccess } from '../../common/helpers';
import employeeFlexibleRewards from '../components/sideBySide/reducer';
import enrollmentCard from '../components/enrollmentCard/reducer';
import enrollmentWindow from '../components/enrollmentWindow/reducer';
import {
  syncToReducer,
  getRewardCategories,
  getFlexById,
  getFlexibleRewards,
  getAvailableCountries,
  getSystemVariablesLookup,
  getFormulaOperators,
  getSystemVariables,
  getEligibilityOperators,
  getElectionRewards,
  getElectionSelectedRewards,
  disablePackage,
  countryValueUpdate,
  enrolmentWindowUpdate,
  onBasicInfoRevert,
  storeReset,
  getAllRewards,
} from '../actions';
import initialState from './initialState';

const main = handleActions(
  new Map([
    [
      syncToReducer,
      (state, action) => {
        const { payload } = action;

        return {
          ...state,
          ...payload,
        };
      },
    ],
    [
      getRewardCategories,
      (state, action) => {
        const { ready, payload, error } = action;

        if (!ready) {
          return {
            ...state,
            isFetchingCategories: true,
          };
        }

        if (error) {
          return {
            ...state,
            isFetchingCategories: false,
          };
        }

        return {
          ...state,
          isFetchingCategories: false,
          categories: payload,
        };
      },
    ],
    [
      getFlexById,
      (state, action) => {
        const { ready, payload, error } = action;

        if (!ready) {
          return {
            ...state,
            fetchingById: true,
          };
        }

        if (error) {
          return {
            ...state,
            fetchingById: false,
          };
        }

        const flexData = mapResponseToModel(
          R.path(['data', 'response'], payload),
          R.path(['headers', 'etag'], payload)
        );
        return {
          ...state,
          ...flexData,
          fetchingById: false,
        };
      },
    ],
    [
      getFlexibleRewards,
      (state, action) => {
        const { ready, payload, error } = action;

        if (!ready) {
          return {
            ...state,
            isLoadingPackages: true,
          };
        }

        if (error) {
          return {
            ...state,
            isLoadingPackages: false,
          };
        }

        const nationalFlexPackages = [];
        const globalFlexPackages = [];
        if (Array.isArray(payload)) {
          payload.forEach(pack => {
            if (pack.country === basicInfo.GLOBAL) {
              globalFlexPackages.push(pack);
            } else {
              nationalFlexPackages.push(pack);
            }
          });
        }

        const flexPackages = {
          nationalFlexPackages,
          globalFlexPackages,
        };

        return {
          ...state,
          isLoadingPackages: false,
          flexPackages,
        };
      },
    ],
    [
      getAvailableCountries,
      (state, action) => {
        const { ready, payload, error } = action;

        if (!ready || error) {
          return { ...state };
        }

        const countries = [];
        const defaultCurrencyMappings = filterCountriesByAccess({
          countries: payload.countries,
          module: userModules.FLEXIBLE_MANAGEMENT,
          authorizedModules: R.prop('authorizedModules', payload.user),
        });

        defaultCurrencyMappings.map(item =>
          countries.push({
            value: item.name || item.country,
            label: item.name || item.country,
            code: item.code || item.countryCode,
          })
        );

        return {
          ...state,
          countries,
        };
      },
    ],
    [
      getSystemVariablesLookup,
      (state, action) => {
        const { ready, payload, error } = action;

        if (!ready || error) {
          return { ...state };
        }

        const { lookupData, url } = payload;
        const lookUpValues = {};
        lookUpValues[url] = [];

        if (lookupData.data) {
          lookupData.data.map(item =>
            lookUpValues[url].push({
              label: item,
              value: item,
            })
          );
          lookUpValues[url] = R.sortBy(R.prop('value'))(lookUpValues[url]);
        } else {
          lookUpValues[url] = [];
        }

        return {
          ...state,
          lookUpValues: { ...state.lookUpValues, ...lookUpValues },
        };
      },
    ],
    [
      getFormulaOperators,
      (state, action) => {
        const { ready, payload, error } = action;

        if (!ready || error) {
          return { ...state };
        }

        return {
          ...state,
          formulaOperators: payload,
        };
      },
    ],
    [
      getSystemVariables,
      (state, action) => {
        const { ready, payload, error } = action;

        if (!ready || error) {
          return { ...state };
        }

        const eligibilityRuleLabels = {};
        let systemVariables = [];

        payload.data.map(({ displayName, propertyName, type, valuesUrl }) => {
          systemVariables.push({
            value: propertyName,
            label: displayName,
            type,
            valuesUrl,
          });
          eligibilityRuleLabels[propertyName] = displayName;
          return false;
        });
        systemVariables = R.sortBy(R.prop('value'))(systemVariables);

        return {
          ...state,
          systemVariables,
          eligibilityRuleLabels,
        };
      },
    ],
    [
      getEligibilityOperators,
      (state, action) => {
        const { ready, payload, error } = action;

        if (!ready || error) {
          return { ...state };
        }

        const eligibilityOperators = [];

        payload.data.map(({ displayName, name, allowsMultipleSelect }) =>
          eligibilityOperators.push({
            value: name,
            label: displayName,
            allowsMultipleSelect,
          })
        );

        return {
          ...state,
          eligibilityOperators,
        };
      },
    ],
    [
      getElectionRewards,
      (state, action) => {
        const { ready, payload, error } = action;

        if (!ready) {
          return {
            ...state,
            enrollmentRewards: {
              ...state.enrollmentRewards,
              fetchingList: true,
            },
          };
        }

        if (error) {
          return {
            ...state,
            enrollmentRewards: {
              ...state.enrollmentRewards,
              fetchingList: false,
            },
          };
        }

        const rewardsCount = payload.count;
        const filterOptions = R.map(
          item => ({
            title: item.key,
            key: item.key,
            list: item.options,
          }),
          payload.filterOptions || []
        );

        return {
          ...state,
          enrollmentRewards: {
            ...state.enrollmentRewards,
            fetchingList: false,
            list: payload.rewards,
            filterOptions,
            totalResults: rewardsCount || 0,
          },
        };
      },
    ],
    [
      getAllRewards,
      (state, action) => {
        const { ready, payload, error } = action;

        if (!ready) {
          return {
            ...state,
            allRewards: {
              ...state.allRewards,
              fetchingList: true,
            },
          };
        }

        if (error) {
          return {
            ...state,
            allRewards: {
              ...state.allRewards,
              fetchingList: false,
            },
            isLoadingSelection: false,
          };
        }

        const rewardsCount = payload.count;
        const filterOptions = R.map(
          item => ({
            title: item.key,
            key: item.key,
            list: item.options,
          }),
          payload.filterOptions || []
        );

        return {
          ...state,
          allRewards: {
            ...state.allRewards,
            fetchingList: false,
            list: payload.rewards,
            filterOptions,
            totalResults: rewardsCount || 0,
          },
          isLoadingSelection: false,
        };
      },
    ],
    [
      getElectionSelectedRewards,
      (state, action) => {
        const { ready, payload, error } = action;
        const { optionalRewards, rewardIdsIncludedInBudget, rewardsIdsExcluded } = payload;

        if (!ready) {
          return { ...state };
        }

        if (error) {
          return {
            ...state,
            isLoadingSelection: false,
          };
        }

        const rewardsSelection = R.reduce(
          (acc, item) => {
            acc[item.id] = item;
            return acc;
          },
          {},
          optionalRewards
        );

        return {
          ...state,
          isLoadingSelection: false,
          rewardsSelection,
          rewardIdsIncludedInBudget,
          rewardsIdsExcluded,
        };
      },
    ],
    [
      disablePackage,
      (state, action) => {
        const { ready, error } = action;

        if (!ready) {
          return {
            ...state,
            isDeactivatingPackage: true,
          };
        }

        if (error) {
          return {
            ...state,
            isDeactivatingPackage: false,
          };
        }

        return {
          ...state,
          isDeactivatingPackage: false,
        };
      },
    ],
    [
      countryValueUpdate,
      (state, action) => {
        const { payload } = action;

        return {
          ...state,
          bannerCountry: payload,
        };
      },
    ],
    [
      enrolmentWindowUpdate,
      (state, action) => {
        const { payload } = action;
        const { type, date } = payload;

        return {
          ...state,
          [type]: date,
        };
      },
    ],
    [
      onBasicInfoRevert,
      state => {
        return {
          ...state,
          bannerCountry: R.path(['country', 'value'], state),
          bannerEnrolmentWindowStartDate: R.prop('from', state),
          bannerEnrolmentWindowEndDate: R.prop('to', state),
        };
      },
    ],
    [
      storeReset,
      state => ({
        ...initialState,
        categories: state.categories,
      }),
    ],
  ]),
  initialState
);

export default combineReducers({
  main,
  employeeFlexibleRewards,
  enrollmentCard,
  enrollmentWindow,
});
