import React from 'react';
import styled, { css } from 'styled-components';
import { Input } from '@trs/components';
import { handleEnterKeypress, getEventValue, decimalTransform } from '@trs/utils';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { EDITABLE_INPUT_TYPE, INPUT_MAX } from '../../helpers';

const SLIDER_INPUT_CLASS = 'slider-handle-input';
const StyledHandle = styled.div`
  ${(props) => {
    const { palette: color, spacing } = props.theme;
    const { percent } = props;

    return css`
      background-color: ${color.basic.white};
      border-radius: 50%;
      border: 6px solid ${color.secondary};
      cursor: pointer;
      height: ${spacing.smallPlus};
      left: ${percent}%;
      top: -5px;
      position: absolute;
      margin-left: -10px;
      width: ${spacing.smallPlus};
      z-index: 2;
    `;
  }};
`;

StyledHandle.displayName = 'StyledHandle';

class Handle extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      innerValue: props.isIntegerOnly
        ? R.path(['handle', 'value'], props)
        : decimalTransform(R.path(['handle', 'value'], props)),
    };
  }

  onChange = (ev) => {
    let innerValue = getEventValue(ev);

    if (this.props.isIntegerOnly) {
      innerValue = Math.round(innerValue);
    }
    this.setState({ innerValue });
  };

  render() {
    const {
      domain: [min, max],
      handle: { id, value, percent },
      getHandleProps,
      onChange,
    } = this.props;
    const { innerValue } = this.state;
    const handleProps = getHandleProps(id);

    return (
      <StyledHandle
        role="slider"
        aria-valuemin={min}
        aria-valuemax={max}
        aria-valuenow={value}
        percent={percent}
        {...handleProps}
        onMouseDown={(ev) => {
          const targetClass = R.path(['target', 'className'], ev);
          if (targetClass && targetClass.includes(SLIDER_INPUT_CLASS)) return false;
          return handleProps.onMouseDown(ev);
        }}
      >
        <Input
          className={SLIDER_INPUT_CLASS}
          onChange={this.onChange}
          maxLength={INPUT_MAX}
          type={EDITABLE_INPUT_TYPE}
          // eslint-disable-next-line no-restricted-globals
          value={isNaN(innerValue) ? min : innerValue}
          onBlur={() => onChange(decimalTransform(innerValue))}
          onKeyDown={handleEnterKeypress(() => onChange(innerValue))}
        />
      </StyledHandle>
    );
  }
}
Handle.propTypes = {
  domain: PropTypes.arrayOf(PropTypes.number).isRequired,
  handle: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.number,
    percent: PropTypes.number,
  }).isRequired,
  getHandleProps: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  isIntegerOnly: PropTypes.bool.isRequired,
};

Handle.defaultProps = {};

export default Handle;
