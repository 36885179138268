import styled, { css } from 'styled-components';
import APP_CONFIG from '@trs/config';

const { breakpoints } = APP_CONFIG;

const StyledRewardCategory = styled.section`
  ${props => {
    const { spacing, palette: color, typography } = props.theme;

    return css`
      header.reward-category {
        color: ${color.gray.solo};
        display: block;
        font-size: ${typography.size.paragraphPlus};
        font-weight: ${typography.weight.bold};
        line-height: ${typography.lineHeight.default};
        margin-bottom: ${spacing.tiny};
        text-transform: uppercase;
        padding: 0 ${spacing.small};
      }
      .statement-row {
        padding: ${spacing.xSmall} ${spacing.small};
        align-items: center;
      }
      .odd-row {
        background-color: ${color.utility.bodyBackground};
      }
      .statement-row-title {
        font-size: ${typography.size.base};
        font-weight: ${typography.weight.normal};
        line-height: ${typography.lineHeight.high};
        position: relative;
        > span,
        > div {
          vertical-align: middle;
        }

        > div {
          margin-top: -${spacing.xTiny};
        }

        &--highlight {
          display: block;
          margin-left: ${spacing.base};
          color: ${color.gray.bravo};
          font-size: ${typography.size.small};
        }

        i {
          margin-right: ${spacing.tiny};
        }
      }
      .reward-text-only-description {
        display: flex;
        flex-direction: row;
        position: relative;
        justify-content: flex-start;
      }
      @media (min-width: ${breakpoints.tablet}) {
        .reward-text-only-description {
          justify-content: flex-end;
        }
      }
      .text-only-description {
        width: 70%;
        word-break: break-word;
      }

      .reward-without-dialog {
        padding: ${spacing.xSmall};
      }

      .reward-with-dialog {
        margin-top: 2px;
      }
    `;
  }};
`;

StyledRewardCategory.displayName = 'StyledRewardCategory';
export default StyledRewardCategory;
