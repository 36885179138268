"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var StyledRadio = _styledComponents["default"].label.withConfig({
  displayName: "Radiostyle__StyledRadio",
  componentId: "sc-12jwcre-0"
})(["", ";"], function (props) {
  var _props$disabled = props.disabled,
    disabled = _props$disabled === void 0 ? false : _props$disabled,
    checked = props.checked,
    theme = props.theme,
    naked = props.naked;
  var palette = theme.palette,
    spacing = theme.spacing,
    typography = theme.typography;
  return (0, _styledComponents.css)(["&.radio-wrapper{display:flex;align-items:center;justify-content:center;width:40px;height:40px;border-radius:50%;border:1px solid ", ";margin-right:", ";cursor:pointer;flex-direction:row;.radio-label{margin-left:", ";}.text-as-icon{color:", ";font-weight:", ";font-size:", ";}", " ", " ", " input{display:none;opacity:0;position:absolute;}i{color:", ";font-size:18px;line-height:1.2;transition:linear color 0.2s;", " ", " ", "}.radio-icon{color:red;}}"], palette.accent.tau, spacing.basePlus, spacing.tiny, palette.gray.bravo, typography.weight.thick, typography.size.misc, checked && (0, _styledComponents.css)(["background-color:", ";border:0;.text-as-icon{color:", ";}"], disabled ? palette.gray.bravo : palette.secondary, palette.basic.white), disabled && (0, _styledComponents.css)(["cursor:not-allowed;opacity:0.5;"]), naked && (0, _styledComponents.css)(["background-color:transparent;border:0;width:auto;height:auto;"]), palette.gray.bravo, naked && (0, _styledComponents.css)(["font-size:24px;"]), checked && (0, _styledComponents.css)(["color:", ";line-height:1.2;"], palette.basic.white), naked && checked && (0, _styledComponents.css)(["color:", ";"], disabled ? palette.gray.bravo : palette.secondary));
});
StyledRadio.displayName = 'StyledRadio';
var _default = StyledRadio;
exports["default"] = _default;