import { handleActions } from 'redux-actions';
import { feedbackStats, resetStore } from '../actions';
import {
  getStatusByScore,
  convertDecimalToPercentage,
} from '../../../../../../feedback/components/helpers';
import {
  DEFAULT_FACE,
  NOT_SELECTED_RATING,
  FEEDBACK_MOODS,
} from '../../../../../../feedback/constants';
import constructFeedbackObject from '../components/helpers';

export const initialState = {
  stats: {
    averageSatisfaction: 0,
    totalEmployees: 0,
    status: DEFAULT_FACE,
    hasFeedback: false,
    submittedFeedback: {
      [FEEDBACK_MOODS.verySad]: 0,
      [FEEDBACK_MOODS.sad]: 0,
      [FEEDBACK_MOODS.neutral]: 0,
      [FEEDBACK_MOODS.happy]: 0,
      [FEEDBACK_MOODS.veryHappy]: 0,
    },
  },
  isFetchingFeedbackStats: false,
};

const feedbackStatsReducer = handleActions(
  new Map([
    [
      feedbackStats,
      (state, action) => {
        const { ready, payload, error } = action;
        if (!ready) {
          return {
            ...state,
            isFetchingFeedbackStats: true,
          };
        }

        if (error) {
          return {
            ...state,
            isFetchingFeedbackStats: false,
          };
        }

        return {
          ...state,
          stats: {
            averageSatisfaction: convertDecimalToPercentage(payload.averageSatisfaction),
            totalEmployees: payload.totalFeedbackCount,
            status: getStatusByScore(payload.averageSatisfaction),
            submittedFeedback: constructFeedbackObject(payload.feedbackSentiments),
            hasFeedback: payload.averageSatisfaction !== NOT_SELECTED_RATING,
          },
          isFetchingFeedbackStats: false,
        };
      },
    ],
    [resetStore, () => initialState],
  ]),
  initialState
);

export default feedbackStatsReducer;
