import React from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { Doughnut } from '@trs/components';
import { isBlank } from '@trs/utils';
import StyledColumnHeader from './ColumnHeader.style';

export const DOUGHNUT_PADDING_ANGLE = 7;
export const DATA_KEY = 'value';
export const LABEL_KEY = 'displayName';
export const DOUGHNUT_HEIGHT = 80;

const ColumnHeader = ({
  title,
  hasDoughnut,
  isComputingStatement,
  naked,
  theme,
  data,
  className,
  hideOnMobile,
  subtitle,
  date,
}) => {
  const filteredData = R.reject(item => !R.prop('value', item), data);
  const indexes = R.pluck('orderIndex', filteredData);
  const colors = Object.values(
    R.pick(indexes, R.path(['extra', 'rewardsCategories', 'categories'], theme))
  );

  return (
    <StyledColumnHeader naked={naked} className={className} hideOnMobile={hideOnMobile}>
      {title ? (
        <div className="header">{title}</div>
      ) : (
        <div className="column-header-details">
          <strong>{subtitle}</strong>
          <span>{date}</span>
        </div>
      )}

      {hasDoughnut && !isBlank(filteredData) && (
        <div className="doughnut-container">
          {!isComputingStatement && (
            <Doughnut
              paddingAngle={DOUGHNUT_PADDING_ANGLE}
              dataKey={DATA_KEY}
              labelKey={LABEL_KEY}
              data={filteredData}
              height={DOUGHNUT_HEIGHT}
              colors={colors}
            />
          )}
        </div>
      )}
    </StyledColumnHeader>
  );
};

ColumnHeader.propTypes = {
  date: PropTypes.string,
  subtitle: PropTypes.string,
  hideOnMobile: PropTypes.bool,
  className: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({})),
  title: PropTypes.string,
  hasDoughnut: PropTypes.bool,
  naked: PropTypes.bool,
  theme: PropTypes.shape({}).isRequired,
  isComputingStatement: PropTypes.bool,
};

ColumnHeader.defaultProps = {
  title: '',
  isComputingStatement: false,
  date: '',
  subtitle: '',
  hideOnMobile: false,
  className: '',
  data: [],
  hasDoughnut: false,
  naked: false,
};

export default withTheme(ColumnHeader);
