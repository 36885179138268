export default {
  RESOURCE_NOT_FOUND: 'Resource not found',
  ERROR_REQUIRED: '%FIELD% is required.',
  GENERIC_ERROR_REQUIRED: 'This field is required.',
  ERROR_GREATER_THAN: '%FIELD1% must be greater than %FIELD2%.',
  ERROR_MIN_LENGTH: '%FIELD% should have at least %LENGTH% characters.',
  ERROR_MAX_LENGTH: '%FIELD% should have a maximum of %LENGTH% characters.',
  ERROR_MIN_NUMBERS_LENGTH: '%FIELD% should have at least %LENGTH% numbers.',
  ERROR_MAX_NUMBERS_LENGTH: '%FIELD% should have a maximum of %LENGTH% numbers.',
  BANNER_FORM_ERROR_GENERIC: 'Please correct the errors highlighted on the page.',
  BANNER_ERROR_GENERIC: 'An unexpected error has occurred. %FIELD%',
  BANNER_FILE_ERROR_GENERIC: 'The file could not be exported. Please try again later.',
  BANNER_NUMBER_OF_ERRORS: 'Showing %COUNT% out of %LENGTH%',
  BANNER_FORM_OPTIONS_SELECTED_ERROR: 'Please make sure you have selected every option!',
  ERROR_OUT_OF_RANGE: '%FIELD% should have a value between %MIN% and %MAX%',
  ERROR_DOWNLOAD: 'An error has occured while trying to download.',
  FILE_ERROR_MAX_SIZE_EXCEEDED: '%FIELD% file exceeds the maximum of %MAX_SIZE%.',
  FILE_ERROR_INCORRECT_FILE_FORMAT: '%FIELD% file format is incorrect.',
  FILE_ERROR_INCORRECT_FILE_NAME: '%FIELD% file name is incorrect.',
  GENERIC_PAGE_ERROR: 'Sorry, there was a problem loading the page',
  ERROR_DOWNLOADING_CSV_EXPORT: 'An error has occurred while trying to download CSV.',
  ERROR_ITEM_LIMIT: 'The limit of %SIZE% items has been exceeded.',
  FILE_GENERIC_ERROR: 'A file error occurred. Please try again.',
  FILE_INVALID_CHARACTERS_ERROR: 'The file name contains invalid characters.',
  DUPLICATED_VALUES_FOUND: 'Found duplicated values at line(s): %EXTRA_INFO%',
  TRY_LATER_GENERIC: 'We have encountered an error. Please try again later.',
  SPECIAL_SYMBOLS_ARE_FORBIDDEN: 'Special symbols are forbidden.',
  SPECIAL_SYMBOLS_AT_START: 'Special symbols are forbidden at the beginning of name.',
  SOMETHING_WENT_WRONG: 'Something went wrong',
  INVALID_URL: 'Invalid URL',
};
