"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var StyledCustomMenuList = _styledComponents["default"].div.withConfig({
  displayName: "CustomMenuListstyle__StyledCustomMenuList",
  componentId: "sc-1j2dpo2-0"
})(["", ";"], function (props) {
  var _props$theme = props.theme,
    typography = _props$theme.typography,
    palette = _props$theme.palette,
    spacing = _props$theme.spacing,
    genericBorderRadius = _props$theme.extra.genericBorderRadius;
  return (0, _styledComponents.css)(["position:relative;.content-toggler{cursor:pointer;display:inline-block;width:", ";height:", ";border-radius:", ";text-align:center;&:hover,&.active{background:", ";}i{font-size:", ";line-height:", ";}}.menu-list{position:absolute;width:220px;right:0;box-shadow:0 1px 8px 0 rgba(0,0,0,0.1);border-radius:", ";background:", ";border:1px solid ", ";z-index:9;margin:0;padding:0;list-style-type:none;top:20px;right:10px;.report-menu-close-icon{color:", ";position:absolute;cursor:pointer;top:-10%;left:95%;}}.menu-item{display:flex;align-items:center;&:hover{background:", ";a,button,i{color:", ";}}&.disabled{a,button,i{color:", ";}}i{line-height:", ";margin-right:", ";padding:", " 0 ", " ", ";}a,button{color:", ";font-size:", ";padding:", " ", " ", " 0;text-decoration:none;width:100%;height:100%;text-align:left;}}"], spacing.basePlus, spacing.basePlus, spacing.small, palette.accent.bravo, typography.size.h2, spacing.basePlus, genericBorderRadius, palette.basic.white, palette.utility.border, palette.primary, palette.accent.bravo, palette.secondary, palette.gray.bravo, spacing.base, spacing.tiny, spacing.tiny, spacing.tiny, spacing.xxSmall, palette.gray.alpha, typography.size.table, spacing.tiny, spacing.xxSmall, spacing.tiny);
});
StyledCustomMenuList.displayName = 'StyledCustomMenuList';
var _default = StyledCustomMenuList;
exports["default"] = _default;