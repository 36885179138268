import * as R from 'ramda';
import {
  loadTargetMarketGroup,
  loadTargetMarketGroupIndustries,
  updateField,
  toggleAdd,
  addNewCompany,
  targetMarketGroupCellChange,
  toggleSavingTMG,
  saveTargetMarketGroupSuccess,
} from '../actions';
import { mapResponseToOptions, hasEmptyRequiredValues } from './helpers';

const defaultNewCompanyInfo = {
  company: '',
  industry: '',
  revenue: null,
  numberOfEmployees: null,
};

export default [
  [
    loadTargetMarketGroup,
    (state, action) => {
      const { ready, payload } = action;

      if (!ready) {
        return {
          ...state,
          isLoadingTargetMarketGroup: true,
        };
      }

      return {
        ...state,
        isLoadingTargetMarketGroup: false,
        executiveSummary: R.assocPath(['targetMarketGroup'], payload, state.executiveSummary),
        disableSave: true,
      };
    },
  ],
  [
    loadTargetMarketGroupIndustries,
    (state, action) => {
      const { ready } = action;
      if (!ready) {
        return {
          ...state,
          isLoadingTMGIndustries: true,
        };
      }
      return {
        ...state,
        targetMarketGroupIndustries: mapResponseToOptions(action.payload),
        isLoadingTMGIndustries: false,
      };
    },
  ],
  [
    updateField,
    (state, action) => ({
      ...state,
      newCompanyInfo: R.assocPath(
        [action.payload.field],
        action.payload.data,
        state.newCompanyInfo
      ),
      disableSave: hasEmptyRequiredValues(
        R.assocPath([action.payload.field], action.payload.data, state.newCompanyInfo)
      ),
    }),
  ],
  [
    toggleAdd,
    state => ({
      ...state,
      inAddingMode: !state.inAddingMode,
      newCompanyInfo: defaultNewCompanyInfo,
      disableSave: true,
    }),
  ],
  [
    addNewCompany,
    state => ({
      ...state,
      newCompanyInfo: defaultNewCompanyInfo,
      disableSave: true,
    }),
  ],
  [
    targetMarketGroupCellChange,
    (state, action) => {
      const { rowIndex, field, value } = action.payload;
      const { executiveSummary } = state;

      return {
        ...state,
        executiveSummary: R.assocPath(
          ['targetMarketGroup', rowIndex, field],
          value,
          executiveSummary
        ),
      };
    },
  ],
  [
    toggleSavingTMG,
    state => ({
      ...state,
      isSavingTargetMarketGroup: !state.isSavingTargetMarketGroup,
    }),
  ],
  [
    saveTargetMarketGroupSuccess,
    state => ({
      ...state,
      executiveSummarySnapshot: state.executiveSummary,
    }),
  ],
];
