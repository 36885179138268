const actionTypes = {
  VALIDATE_MODELLER: 'VALIDATE_MODELLER',
  LOAD_MODELLER_OUTPUT: 'LOAD_MODELLER_OUTPUT',
  SET_REQUEST_ID: 'SET_REQUEST_ID',
  TOGGLE_SEARCH_DIALOG: 'TOGGLE_SEARCH_DIALOG',
  LOAD_VARIABLES: 'LOAD_VARIABLES',
  FETCH_EMPLOYEES_LIST_PENDING: 'FETCH_EMPLOYEES_LIST_PENDING',
  FETCH_EMPLOYEES_LIST_SUCCESS: 'FETCH_EMPLOYEES_LIST_SUCCESS',
  FETCH_EMPLOYEES_LIST_ERROR: 'FETCH_EMPLOYEES_LIST_ERROR',
  RESET_STORE: 'RESET_STORE',
  TOGGLE_MODELLER_PROFILE: 'TOGGLE_MODELLER_PROFILE',
  GET_CATEGORIES_MAPPING: 'GET_CATEGORIES_MAPPING',
  SUBMIT_CATEGORIES_MAPPING_PENDING: 'SUBMIT_CATEGORIES_MAPPING_PENDING',
  SUBMIT_CATEGORIES_MAPPING_SUCCESS: 'SUBMIT_CATEGORIES_MAPPING_SUCCESS',
  SUBMIT_CATEGORIES_MAPPING_ERROR: 'SUBMIT_CATEGORIES_MAPPING_ERROR',
  CHANGE_CATEGORIES_MAPPING: 'CHANGE_CATEGORIES_MAPPING',
  RESET_CATEGORIES_MAPPING: 'RESET_CATEGORIES_MAPPING',
  GET_MODELLER_PERMISSIONS: 'GET_MODELLER_PERMISSIONS',
};

export default actionTypes;
