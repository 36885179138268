import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';

const StyledHomepage = styled.div`
  text-align: center;
`;

StyledHomepage.displayName = 'StyledHomepage';

const Homepage = ({ label, landingPage }) => (
  <StyledHomepage>
    {label.HOMEPAGE_TEXT}

    {landingPage && <Redirect to={{ pathname: landingPage }} />}
  </StyledHomepage>
);

Homepage.defaultProps = {
  landingPage: null,
};

Homepage.propTypes = {
  label: PropTypes.shape({
    HOMEPAGE_TEXT: PropTypes.string,
  }).isRequired,
  landingPage: PropTypes.string,
};

const mapStateToProps = (state) => ({
  label: state.cms.generic.data,
  landingPage: state.user.landingPage,
});

export default connect(mapStateToProps)(Homepage);
