export default {
  MAIN_PAGE: 'Home',
  PAGE_TITLE: 'Tell us your opinion so we can improve',
  WIDGET_TITLE_WITH_STATUS:
    'Overall, you are %NO% satisfied with the reward elements you have provided feedback on!',

  WIDGET_TITLE_WITHOUT_STATUS: "We don't know how you feel about your rewards.",
  WIDGET_DESCRIPTION:
    'Tell us your opinion about the rewards from your statement so we can improve them later.',
  BUTTON_SEND: 'Send Feedback',
  BUTTON_GIVE_FEEDBACK: 'Give Feedback',
  BUTTON_ADJUST: 'Adjust Feedback',
  TEXTAREA_PLACEHOLDER: 'Write your opinion about this reward',
  TEXTAREA_VALIDATION_MESSAGE: 'You can add up to %NO% characters with spaces.',
  TEXTAREA_VALIDATION_MESSAGE_2: 'Text input is required when selecting a face.',
  TEXTAREA_VALIDATION_MESSAGE_3: "Text input can't contain only special characters.",
  UNSAVED_CHANGES_TITLE: 'Are you sure you want to cancel?',
  UNSAVED_CHANGES_SUBTITLE: "Your changes will be lost if you don't save them.",
  TOTAL_SATISFACTION_EMPTY_STATE:
    '<strong>None</strong> of the employees gave any feedback until now.',
  TOTAL_SATISFACTION_TEXT: 'The average employee satisfaction rate is <strong>%NO%</strong>.',
  SUCCESS_MESSAGE: 'Your feedback has been submitted successfully!',
  FEEDBACK_STATS_COL_DATE: 'Date',
  FEEDBACK_STATS_COL_COUNTRY: 'Employee Country',
  FEEDBACK_STATS_COL_COMMENT: 'Comment',
  FEEDBACK_STATS_VIEW_FEEDBACK: 'View',
  NO_FEEDBACK_FOR_REWARD: 'There is no feedback for this reward',
  EMPLOYEE_SATISFACTION: 'Employee satisfaction',
  MODAL_TITLE: 'Employee feedback',
  DATE: 'Date',
  EMPLOYEE: 'Employee',
  REWARD_FEEDBACK_SUBTITLE: 'How well does this reward meet your needs?',
};
