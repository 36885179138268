const initialState = {
  rewardId: null,
  list: [],
  totalResults: 0,
  fetchingList: true,
  filterOptions: [],
  tableTools: {
    filters: [],
    pagingSkip: 0,
    pagingTop: 10,
    searchText: '',
    sortAsc: true,
    sortBy: 'uploadedDate',
  },
  publishing: false,
  error: null,
};

export default initialState;
