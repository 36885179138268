import React from 'react';
import { Route, Switch } from 'react-router';
import PropTypes from 'prop-types';
import PrivateRoute from './helpers/PrivateRoute';
import NotFoundPage from './static/notFound';
import { privateRoutes, publicRoutes } from './config';

const AppRoutes = (props) => (
  <Switch>
    {props.privateRoutes.map((route) => (
      <PrivateRoute key={route.id} {...route} />
    ))}
    {props.publicRoutes.map((route) => (
      <Route key={route.id} {...route} />
    ))}

    <Route key="404-page" component={NotFoundPage} />
  </Switch>
);

AppRoutes.defaultProps = {
  publicRoutes,
  privateRoutes,
};

AppRoutes.propTypes = {
  privateRoutes: PropTypes.arrayOf(PropTypes.shape({})),
  publicRoutes: PropTypes.arrayOf(PropTypes.shape({})),
};

export default AppRoutes;
