import * as R from 'ramda';
import {
  groupAvailableOptions,
  getGroupedCalculatedStatement,
  getSelectedRewards,
} from '../../sideBySide/reducer/helpers';
import { reworkAvailableOptions } from '../../sideBySide/actions/helpers';

const mapPayload = ({ payload, categories }) => {
  const availableOptionsPayload = R.prop('availableOptions', payload) || [];
  const availableOptions = reworkAvailableOptions(availableOptionsPayload);
  const currentStatement = R.prop('currentStatement', payload) || {};

  const currencies = [
    !R.isNil(R.prop('currentStatement', payload))
      ? R.path(['metaData', 'currencyCode'], currentStatement)
      : R.path(['metaData', 'currencyCode'], payload),
    R.path(['metaData', 'currencyCode'], payload),
  ];

  return {
    ...payload,
    availableOptions: groupAvailableOptions(availableOptions, categories),
    selectedRewards: getSelectedRewards(availableOptions),
    currentStatement,
    currencies,
    categories,
    data: getGroupedCalculatedStatement({
      current: R.prop('rewards', currentStatement) || [],
      future: R.prop('futureRewards', payload),
      categories,
      currencies,
    }),
  };
};

export default mapPayload;
