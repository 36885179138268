import * as R from 'ramda';

export const isLoginPage = history =>
  R.prop('pathname', history.location).includes('login') ||
  R.prop('pathname', history.location).includes('callback');

const menuFilter = menu => {
  const filteredItems = R.filter(item => item !== false)(menu);
  filteredItems.forEach((item, index) => {
    if (item.items.length > 0) {
      filteredItems[index].items = menuFilter(item.items);
    }
  });
  return filteredItems;
};

export const menuGenerator = (model, serverResponse) =>
  menuFilter(
    R.map(item => {
      if (R.prop('hidden', item)) return false;

      let hasModule = !item.key.length;
      // account for empty keys from menuModel
      item.key.map(singleKey => {
        if (R.contains(singleKey, R.keys(serverResponse))) {
          hasModule = true;
        }
        return null;
      });
      if (hasModule) {
        if (item.items && item.items.length > 0) {
          return {
            title: item.title,
            key: item.key,
            items: menuGenerator(item.items, serverResponse),
            id: item.id,
            itemProps: item.itemProps,
          };
        }
        return item;
      }
      return false;
    }, model)
  );
