import styled, { css } from 'styled-components';

const StyledProcessTriggeringNotification = styled.div`
  ${props => {
    const { spacing } = props.theme;

    return css`
      &&& .notification-process-triggering {
        display: block;
        margin-top: ${spacing.tiny};
        margin-left: 0;
        text-transform: uppercase;
      }
    `;
  }};
`;

StyledProcessTriggeringNotification.displayName = 'StyledProcessTriggeringNotification';
export default StyledProcessTriggeringNotification;
