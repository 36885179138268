/* eslint-disable max-lines-per-function */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import cmsKeys from 'modules/cms/keys';
import RewardStatusBanner from 'modules/rewards/components/rewardStatusBanner';
import { fetchCmsData, getPdfLogo } from 'modules/cms/actions';
import { isBlank } from '@trs/utils';
import { Paragraph, PageTitle, RichTextarea, Checkbox, PageLoader } from '@trs/components';
import { Row, Col, Container } from 'react-grid-system';
import * as R from 'ramda';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getEmployeePersonalDetails } from '../../../../employeeStatement/actions';
import Text, { TextOnly } from '../../../../common/Text';
import StyledSubmissionPage from './SubmissionPage.style';
import getPackageForEmail from '../actions/index';
import StyledSideBySide from '../../sideBySide/components/SideBySide.style';
import StatementSides from '../../sideBySide/components/statementSides';
import AvailableOptions from '../../sideBySide/components/availableOptions';
import { changeAgreementTos } from '../../sideBySide/actions';
import { loadRewardCategories } from '../../../actions';
import { getTopBannerData } from '../../sideBySide/components/helpers';
import StickyContainer from '../../../../common/StickyContainer';
import RemainingBudget from '../../sideBySide/components/remainingBudget';
import SideActions from '../../sideBySide/components/sideActions';

class SubmissionPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };

    this.elemRef = React.createRef();
    this.handleError = this.handleError.bind(this);
  }

  handleError() {
    this.setState({
      hasError: true,
    });
  }

  componentDidMount() {
    const {
      actions: { loadCategories, getPackage, fetchLabels, fetchPdfLogo },
      match,
    } = this.props;

    this.props.actions.getEmployeePersonalDetails();

    loadCategories().then(() => {
      fetchPdfLogo();
      getPackage(match.params.packageId, this.handleError);
    });

    fetchLabels([{ name: 'resourceGroup', value: 'EmployeeStatement' }], cmsKeys.employeeStatement);
    fetchLabels([{ name: 'resourceGroup', value: 'FlexibleRewards' }], cmsKeys.flexibleRewards);
  }

  render() {
    const {
      user,
      packageDetails,
      match: {
        params: { packageId },
      },
      loading,
      actions,
    } = this.props;
    const { availableOptions, selectedRewards, metaData, flexPackageInfo, data, submissionDate } =
      packageDetails;

    const remainingBudget = R.prop('remainingBudget', metaData);
    const excessBudget = R.prop('excessBudget', metaData);
    const minimumUsageBudget = R.prop('minimumUsageBudget', metaData);

    const agreedTos = R.prop('agreedTos', metaData);

    if (loading) {
      return <PageLoader />;
    }

    if (this.state.hasError) {
      return (
        <p>
          {TextOnly({
            path: 'generic.SOMETHING_WENT_WRONG',
          })}
        </p>
      );
    }

    return (
      <StyledSubmissionPage>
        <div className="submission-page">
          <Link to="/employee-statement">
            <Text path="generic.NAVIGATE_TO_MY_STATEMENT" />
          </Link>
          <StyledSideBySide key={packageId}>
            <div ref={this.elemRef}>
              <div className="package-title">
                <PageTitle title={R.prop('title', flexPackageInfo)} />
              </div>
              {R.prop('message', flexPackageInfo) && (
                <div className="description">
                  <RichTextarea
                    value={R.prop('message', flexPackageInfo)}
                    readOnly
                    ariaDescribedBy="pageTitle"
                  />
                </div>
              )}
              <Row className="sides-main-view">
                <Col sm={12} md={12} xl={8}>
                  <RewardStatusBanner className="status-banner__info">
                    {getTopBannerData(user)}
                  </RewardStatusBanner>
                </Col>
              </Row>

              <Container fluid style={{ padding: 0 }} className="side-by-side-content">
                <Row className="sides-main-view">
                  <Col sm={12} md={12} xl={8}>
                    <StatementSides
                      isComputingStatement={false}
                      data={data}
                      hasAvailableOptions={!isBlank(availableOptions)}
                      currentStatementDate={submissionDate}
                      newStatementDate={R.prop('newStatementDate', flexPackageInfo)}
                      showOnlyNewStatement={R.prop('isFutureStatementOnly', flexPackageInfo)}
                      budgetLabel={R.prop('budgetLabel', metaData)}
                    />
                  </Col>

                  <Col sm={12} md={12} xl={4}>
                    {!isBlank(remainingBudget) && (
                      <StickyContainer
                        className="sticky-options"
                        position="top"
                        isFullWidth={false}
                        stickyStyle={{
                          left: 'auto',
                        }}
                      >
                        <RemainingBudget
                          excessBudget={excessBudget}
                          amount={remainingBudget}
                          code={R.prop('currencyCode', metaData)}
                          title={R.prop('remainingBudgetLabel', metaData)}
                          minimumUsageBudget={minimumUsageBudget}
                        />
                      </StickyContainer>
                    )}
                    <AvailableOptions
                      disabled
                      availableOptions={availableOptions}
                      selectedRewards={selectedRewards}
                      budgetLabel={R.prop('budgetLabel', metaData)}
                      hasDecimals
                    />
                  </Col>
                </Row>
                {R.prop('disclaimer', flexPackageInfo) && (
                  <Row>
                    <Col sm={12} md={12} xl={12}>
                      <Paragraph className="disclaimer">
                        <strong id="disclaimer">
                          <Text path="flexibleRewards.DISCLAIMER" />:{' '}
                        </strong>
                        <RichTextarea
                          value={R.prop('disclaimer', flexPackageInfo)}
                          ariaDescribedBy="disclaimer"
                          readOnly
                        />
                      </Paragraph>
                    </Col>
                  </Row>
                )}
                {R.prop('agreement', flexPackageInfo) && (
                  <Row>
                    <Col sm={12} md={12} xl={12}>
                      <Paragraph className="agreementWrapper">
                        <Checkbox
                          hasError={false}
                          id="agreement"
                          checked={agreedTos}
                          disabled
                          onChange={(event, isChecked) => {
                            actions.changeAgreementTos(isChecked);
                          }}
                        />
                        <RichTextarea
                          value={R.prop('agreement', flexPackageInfo)}
                          ariaDescribedBy="agreement"
                          readOnly
                        />
                      </Paragraph>
                    </Col>
                  </Row>
                )}
                <StickyContainer>
                  <Row className="sides-actions">
                    <Col sm={12} md={12} lg={12}>
                      <SideActions
                        isSaving={false}
                        isEditing={false}
                        containerRef={this.elemRef}
                        bannerDetails={flexPackageInfo}
                        hasChanges={false}
                        isComputingStatement={false}
                        hasValidationErrors={false}
                        isMimic={false}
                        save={this.save}
                        submit={this.submitHandler}
                        accessFlags={{}}
                        isSubmissionPage
                        currentStatementDate={submissionDate}
                        showOnlyNewStatement={R.prop('isFutureStatementOnly', flexPackageInfo)}
                        isCheckedAgreement={agreedTos}
                        user={user}
                      />
                    </Col>
                  </Row>
                </StickyContainer>
              </Container>
            </div>
          </StyledSideBySide>
        </div>
      </StyledSubmissionPage>
    );
  }
}

SubmissionPage.propTypes = {
  user: PropTypes.shape({
    isLoggedIn: PropTypes.bool.isRequired,
    profile: PropTypes.shape({
      country: PropTypes.string.isRequired,
      currency: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      level: PropTypes.string.isRequired,
    }).isRequired,
  }),
  packageDetails: PropTypes.shape({
    pending: PropTypes.bool,
    submissionDate: PropTypes.string,
    availableOptions: PropTypes.shape({}),
    metaData: PropTypes.shape({}),
    selectedRewards: PropTypes.shape({}),
    currentStatement: PropTypes.shape({}),
    currencies: PropTypes.arrayOf(PropTypes.string),
    data: PropTypes.shape({}),
    flexPackageInfo: {
      title: PropTypes.string,
      message: PropTypes.string,
      isFutureStatementOnly: PropTypes.bool,
      newStatementDate: PropTypes.string,
      agreement: PropTypes.string,
      disclaimer: PropTypes.string,
    },
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      packageId: PropTypes.string,
    }),
  }).isRequired,
  actions: PropTypes.shape({
    loadCategories: PropTypes.func,
    getPackage: PropTypes.func,
    fetchLabels: PropTypes.func,
    changeAgreementTos: PropTypes.func,
    getPersonalDetails: PropTypes.func,
    getEmployeePersonalDetails: PropTypes.func,
    fetchPdfLogo: PropTypes.func,
  }).isRequired,
  loading: PropTypes.bool,
};

SubmissionPage.defaultProps = {
  user: {
    personalDetailsSetting: PropTypes.arrayOf(PropTypes.shape({})),
  },
  loading: false,
  packageDetails: {
    pending: false,
    submissionDate: '',
    availableOptions: {},
    metaData: {},
    selectedRewards: {},
    currentStatement: {},
    currencies: [],
    flexPackageInfo: {
      title: '',
      message: '',
      isFutureStatementOnly: false,
      newStatementDate: '',
      agreement: '',
      disclaimer: '',
    },
    data: {},
  },
};

const mapStateToProps = (state) => {
  const {
    flexibleRewards: { main },
    employeeStatement,
    packageDetails,
  } = state;
  return {
    employeeStatement,
    packageDetails,
    user: {
      ...state.user,
      personalDetailsSetting: state.employeeStatement.personalDetailsSetting,
    },
    loading: R.prop('pending', packageDetails) || R.prop('isFetchingCategories', main),
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      loadCategories: loadRewardCategories,
      getPackage: getPackageForEmail,
      fetchLabels: fetchCmsData,
      fetchPdfLogo: getPdfLogo,
      changeAgreementTos,
      getEmployeePersonalDetails,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubmissionPage);
