import React from 'react';
import { Slider, Rail, Handles, Tracks, Ticks } from 'react-compound-slider';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import StyledSlider from './Slider.style';
import Track from './components/Track';
import Handle from './components/Handle';
import Tick from './components/Tick';

/* To be moved to components once component stabilizes and is used more than once */

const SliderComponent = ({ onChange, min, max, value, isPercentage, isIntegerOnly }) => {
  return (
    <StyledSlider className="slider-container">
      <Slider
        className="slider-component"
        mode={1}
        step={isIntegerOnly ? 1 : 0.01}
        domain={[min, max]}
        onChange={(valueList) => {
          // eslint-disable-next-line no-restricted-globals
          if (!isNaN(valueList)) {
            onChange(R.prop(0, valueList));
          } else {
            onChange(min);
          }
        }}
        disabled={min === max}
        values={[value]}
      >
        <Rail>
          {({ getRailProps }) => (
            <div className="slider-rail" {...getRailProps()}>
              <div className="track-inner" />
            </div>
          )}
        </Rail>
        <Handles>
          {({ handles, getHandleProps }) => (
            <div className="slider-handles">
              {handles.map((handle) => (
                <Handle
                  key={value} // force re-mount of handle on value change
                  handle={handle}
                  domain={[min, max]}
                  getHandleProps={getHandleProps}
                  onChange={onChange}
                  isIntegerOnly={isIntegerOnly}
                />
              ))}
            </div>
          )}
        </Handles>
        <Tracks right={false}>
          {({ tracks, getTrackProps }) => (
            <div className="slider-tracks">
              {tracks.map(({ id, source, target }) => (
                <Track key={id} source={source} target={target} getTrackProps={getTrackProps} />
              ))}
            </div>
          )}
        </Tracks>
        <Ticks values={[min, max]}>
          {({ ticks }) => (
            <div className="slider-ticks">
              {ticks.map((tick) => (
                <Tick key={tick.id} tick={tick} isPercentage={isPercentage} />
              ))}
            </div>
          )}
        </Ticks>
      </Slider>
    </StyledSlider>
  );
};

SliderComponent.propTypes = {
  isPercentage: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  isIntegerOnly: PropTypes.bool.isRequired,
};

SliderComponent.defaultProps = {};

export default React.memo(SliderComponent);
