import * as R from 'ramda';
import { v4 as uuid } from 'uuid';
import { isBlank } from '@trs/utils';
import initialState from './initialState';
import { FILTER_TYPES } from '../routes/utils';

const addUuidsToFormulaItems = (formulaItems) =>
  formulaItems.map((formulaItem) =>
    formulaItem.arguments
      ? {
          key: uuid(),
          ...formulaItem,
          arguments: [
            addUuidsToFormulaItems(R.path(['arguments', 0], formulaItem)),
            addUuidsToFormulaItems(R.path(['arguments', 1], formulaItem)),
          ],
        }
      : {
          ...formulaItem,
          key: uuid(),
        }
  );

const addUuidsToEligibilityItems = (items) => {
  const newRules = items;

  if (isBlank(newRules)) return null;

  newRules.uid = uuid();

  if (R.prop('eligibilityRows', newRules)) {
    newRules.eligibilityRows.map((item) => addUuidsToEligibilityItems(item));
  }

  return newRules;
};

export const mapBudgetRulesToRequestModel = (budgetRules) =>
  budgetRules.map((rule) => {
    const payload = {
      ...rule,
      key: uuid(),
      eligibility: R.merge({}, addUuidsToEligibilityItems(rule.eligibility)),
      formula: addUuidsToFormulaItems(rule.formulaItems || []),
      excessFormula: isBlank(rule.excessFormulaItems)
        ? null
        : addUuidsToFormulaItems(rule.excessFormulaItems),
      minimumUsageFormula: isBlank(rule.minimumUsageFormulaItems)
        ? null
        : addUuidsToFormulaItems(rule.minimumUsageFormulaItems),
      budgetLabel: rule.budgetLabel,
      remainingBudgetLabel: rule.remainingBudgetLabel,
    };

    delete payload.formulaItems;

    return payload;
  });

export const mapPopulationRulesToRequestModel = (populationRules) =>
  populationRules.map((rule) => {
    const payload = {
      ...rule,
      key: uuid(),
      eligibility: R.merge({}, addUuidsToEligibilityItems(rule.eligibility)),
    };

    return payload;
  });

export const mapResponseToModel = (response, etag) => {
  const includeBudget = R.path(['budget', 'included'], response);
  const includeDisclaimer = R.path(['disclaimer', 'included'], response);
  const includeAgreement = R.path(['agreement', 'included'], response);
  const disclaimer = R.path(['disclaimer', 'content'], response);
  const agreement = R.path(['agreement', 'content'], response);
  const rules = R.path(['budget', 'rules'], response);
  const country = R.prop('country', response);
  const countryCode = R.prop('countryCode', response);
  const startDate = R.path(['enrollmentWindow', 'startDate'], response);
  const endDate = R.path(['enrollmentWindow', 'endDate'], response);
  const emailData = R.prop('email', response);
  const packageType = R.path(['packageType', 'type'], response);
  const message = R.path(['message'], response);
  const descriptionPlainText =
    !R.isNil(message) && !R.isEmpty(message) ? message.replace(/<[^>]+>/g, '') : '';

  const isSubmission = packageType.toLowerCase() === FILTER_TYPES.SUBMISSION;

  return {
    ...response,
    bannerCountry: {
      value: country,
      label: country,
      code: countryCode,
    },
    bannerEnrolmentWindowStartDate: startDate,
    bannerEnrolmentWindowEndDate: endDate,
    country: {
      value: country,
      label: country,
      code: countryCode,
    },
    etag,
    from: startDate,
    to: endDate,
    includeBudget: !!includeBudget,
    rules: includeBudget ? mapBudgetRulesToRequestModel(rules) : R.prop('rules', initialState),
    populationRules: isBlank(R.prop('populationRules', response))
      ? R.prop('populationRules', initialState)
      : mapPopulationRulesToRequestModel(R.prop('populationRules', response)),
    includeDisclaimer: !!includeDisclaimer,
    disclaimer,
    includeAgreement: isSubmission ? !!includeAgreement : false,
    agreement: isSubmission ? agreement : null,
    includeEmail: R.propOr(false, 'included', emailData),
    emailSubject: R.propOr('', 'subject', emailData),
    emailContent: R.propOr('', 'content', emailData),
    plainText: descriptionPlainText,
  };
};

export default {
  mapResponseToModel,
};
