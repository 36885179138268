import styled, { css } from 'styled-components';
import { media } from '@trs/utils';

const StyledRewardStatusBanner = styled.ul`
  ${(props) => {
    const { spacing, palette, typography } = props.theme;
    return css`
      display: flex;
      color: ${palette.gray.alpha};
      justify-content: flex-start;
      list-style-type: none;
      padding: 0;
      flex-direction: column;
      ${media.tabletLandscape`
        flex-direction: row;
     `}

      li {
        margin-right: ${spacing.xLarge};
        margin-bottom: ${spacing.tiny};
        button {
          margin-top: 0;
          margin-left: calc(${spacing.tiny} - ${spacing.xLarge});
        }

        p {
          margin: 0;

          > span:first-child::after {
            content: ':';
            margin-right: 5px;
          }
        }
      }
      .banner-status-pill .pill-value {
        font-size: ${typography.size.paragraph};
      }

      .profile-details__info {
        display: flex;
        flex-flow: wrap;
        margin-right: 80px;
        margin-block-start: 0em;
        margin-block-end: 0em;
      }

      .info-prop {
        align-items: center;
      }

      .status-pill-value {
        margin-right: 80px;
      }

      .revert-label {
        margin-left: -50px;
        align-items: normal;
      }

      .profile-detail-value {
        margin-left: ${spacing.tiny};
      }
    `;
  }};
`;

StyledRewardStatusBanner.displayName = 'StyledRewardStatusBanner';
export default StyledRewardStatusBanner;
