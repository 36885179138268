const actionTypes = {
  BENCHMARKING_GET_SALARY_INCREASE_DATA: 'BENCHMARKING/GET_SALARY_INCREASE_DATA',
  BENCHMARKING_SALARY_INCREASE_CELL_CHANGE: 'BENCHMARKING/SALARY_INCREASE_CELL_CHANGE',
  BENCHMARKING_SAVE_PROPOSAL_PENDING: 'BENCHMARKING/SAVE_PROPOSAL/PENDING',
  BENCHMARKING_SAVE_PROPOSAL_SUCCESS: 'BENCHMARKING/SAVE_PROPOSAL/SUCCESS',
  BENCHMARKING_SAVE_PROPOSAL_ERROR: 'BENCHMARKING/SAVE_PROPOSAL/ERROR',
  BENCHMARKING_GET_PREMERIT: 'BENCHMARKING/LOAD_PREMERIT',
  BENCHMARKING_CALCULATE_PREMERIT: 'BENCHMARKING/CALCULATE_PREMERIT',
  BENCHMARKING_FINISH_CALCULATE: 'BENCHMARKING/FINISH_CALCULATE',
  BENCHMARKING_MERIT_RESET: 'BENCHMARKING/MERIT/RESET',
  BENCHMARKING_GET_PREVIOUS: 'BENCHMARKING/MERIT/PREVIOUS',
};

export default actionTypes;
