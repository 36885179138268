"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var StyledCollapsibleTrigger = _styledComponents["default"].div.withConfig({
  displayName: "CollapsibleTriggerstyle__StyledCollapsibleTrigger",
  componentId: "sc-1ifp64h-0"
})(["", ";"], function (props) {
  var _props$theme = props.theme,
    palette = _props$theme.palette,
    spacing = _props$theme.spacing,
    typography = _props$theme.typography,
    genericBorderRadius = _props$theme.extra.genericBorderRadius;
  return (0, _styledComponents.css)(["border-radius:", ";background-color:", ";color:", ";font-size:", ";line-height:", ";padding:", " ", " ", " ", ";i{cursor:pointer;float:right;margin-top:-5px;padding:0 ", ";&:first-child{padding-right:0px;}}.collapsible-trigger-delete{margin-top:0;padding:0 ", ";}"], genericBorderRadius, palette.primary, palette.basic.white, typography.size.paragraph, typography.lineHeight["default"], spacing.small, spacing.small, spacing.small, spacing.basePlus, spacing.small, spacing.tiny);
});
StyledCollapsibleTrigger.displayName = 'StyledCollapsibleTrigger';
var _default = StyledCollapsibleTrigger;
exports["default"] = _default;