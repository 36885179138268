import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@trs/components';

import Text from '../../common/Text';
import StyledMimicEmployeeViewBanner from './MimicEmployeeViewBanner.style';

const MimicEmployeeViewBanner = ({ profile, mimicEmployeeExit }) => (
  <StyledMimicEmployeeViewBanner>
    <span>
      <Text
        path="employeeStatement.MIMIC_EMPLOYEE_BANNER"
        transform={(label = '') =>
          label
            .replace('%EMPLOYEE_NAME%', `${profile.firstName} ${profile.lastName}`)
            .replace('%EMPLOYEE_ID%', profile.id)
        }
      />
    </span>
    <Button type="link" action={mimicEmployeeExit}>
      <Text path="employeeStatement.MIMIC_EMPLOYEE_EXIT_LABEL" />
    </Button>
  </StyledMimicEmployeeViewBanner>
);

MimicEmployeeViewBanner.propTypes = {
  profile: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
  mimicEmployeeExit: PropTypes.func.isRequired,
};

export default MimicEmployeeViewBanner;
