import * as R from 'ramda';
import { TextOnly } from '../../../../common/Text';

export const getBenchmarkingSteps = () => ({
  LOCAL_PREPARATION: {
    url: 'localPreparation',
    reducer: ['localPreparation'],
    submitSuccessMessage: TextOnly({ path: 'benchmarking.LOCAL_PREPARATION_SUBMIT_SUCCESS' }),
    approveSuccessMessage: TextOnly({ path: 'benchmarking.LOCAL_PREPARATION_APROVED_SUCCESS' }),
    rejectSuccessMessage: TextOnly({ path: 'benchmarking.LOCAL_PREPARATION_REJECT_SUCCESS' }),
  },
  SALARY_INCREASE: {
    url: 'salaryIncrease',
    reducer: ['salaryIncrease', 'proposal'],
    submitSuccessMessage: TextOnly({ path: 'benchmarking.SALARY_INCREASE_SUBMIT_SUCCESS' }),
    approveSuccessMessage: TextOnly({ path: 'benchmarking.SALARY_INCREASE_APROVED_SUCCESS' }),
    rejectSuccessMessage: TextOnly({ path: 'benchmarking.SALARY_INCREASE_REJECT_SUCCESS' }),
  },
  PAYSCALE_INCREASE: {
    url: 'payscaleIncrease',
    reducer: ['payscaleIncrease'],
    submitSuccessMessage: TextOnly({ path: 'benchmarking.PAYSCALE_INCREASE_SUBMIT_SUCCESS' }),
    approveSuccessMessage: TextOnly({ path: 'benchmarking.PAYSCALE_INCREASE_APROVED_SUCCESS' }),
    rejectSuccessMessage: TextOnly({ path: 'benchmarking.PAYSCALE_INCREASE_REJECT_SUCCESS' }),
  },
});

export const getNameFromCode = countryCode =>
  R.compose(
    R.prop('name'),
    R.find(R.propEq('code', countryCode)),
    R.propOr([], 'countries')
  );

export const getLocalSettingsId = state =>
  R.path(['benchmarking', 'local', 'main', 'statusData', 'id'], state);

export default {
  getBenchmarkingSteps,
  getNameFromCode,
  getLocalSettingsId,
};
