"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = function _default(theme) {
  return {
    banner: {
      warning: theme.palette.validation.warning,
      success: theme.palette.validation.valid,
      error: theme.palette.validation.errorHeavy,
      info: theme.palette.validation.info
    },
    header: {
      background: theme.palette.basic.white,
      text: theme.palette.gray.alpha,
      accentColor: theme.palette.primary,
      disabled: theme.palette.gray.bravo
    },
    button: {}
  };
};
exports["default"] = _default;