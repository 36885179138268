export default {
  INACTIVE_COUNTRIES: 'Inactive Countries',
  ACTIVE_COUNTRIES: 'Active Countries',
  WAITING_FOR_APPROVAL: 'Waiting for Approval',
  CONFIGURE_SETTINGS: 'Configure settings',
  CONFIGURE_GLOBAL_SETTINGS: 'Configure global settings',
  SETUP_NOT_FINISHED:
    'You have not submitted any category mappings. Click on the button below to start configuring.',
  TOTAL_COUNTRIES: 'Total Countries',
  COMPLETED_BENCHMARKINGS: 'Completed Plan',
  COUNTRIES_WAITING_FOR_APPROVAL: 'Countries Waiting for Approval',
  MAP_CATEGORIES: 'Map Categories',
  CATEGORIES_MAPPING: 'Categories Mapping',
  COLUMN_CUSTOM_TYPES: 'Custom Types',
  COLUMN_NOT_INCLUDED: 'Not Included',
  CONFIRM_INITIATE_NEW_TITLE: 'Initiate New Plan',
  CONFIRM_INITIATE_NEW_DESCRIPTION:
    'You are about to begin a new plan process, please make sure you have read and understood the consequences of this action.',
  INITIATE_NEW_BUTTON: 'Initiate new plan',
  LOADING_BENCHMARKING_DATA: 'Loading Plan Data',
  INITIATING_NEW_BENCHMARKING: 'Initiating New Plan for %YEAR%',
  INITIATE_PARA_TITLE: 'Please note the following:',
  INITIATE_LIST_ITEM1: 'All countries will be deactivated.',
  INITIATE_LIST_ITEM2: 'Plan data will be cleared.',
  INITIATE_LIST_ITEM3:
    'Global setup will be kept, please update FX Rate Date and Ageing Date before submission.',
  GLOBAL_SETTINGS_CAN_NOT_BE_INITIATED: 'Global settings can not be initiated',
  INITIATE_SUCCESS: 'Plan process initiated successfully!',
  CONTINUE_TO_GLOBAL_SETTINGS: 'Continue to Global Settings',
};
