const types = {
  GET_AUDIT_DATA: 'GET_AUDIT_DATA',
  SET_AUDIT_FILTER: 'SET_AUDIT_FILTER',
  CHANGE_TABLE_PAGE: 'CHANGE_TABLE_PAGE',
  FILTER_AUDIT_DATA: 'FILTER_AUDIT_DATA',
  GENERATE_LINE_DATA_FILE: 'GENERATE_LINE_DATA_FILE',
  GENERATE_LINES_DATA_FILE: 'GENERATE_LINES_DATA_FILE',
  RESET_STORE: 'RESET_STORE',
};

export default types;
