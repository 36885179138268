import React, { memo, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Paragraph, Button } from '@trs/components';
import Text from 'modules/common/Text';
import FileDownloadNotification from 'modules/common/FileDownloadNotification';
import { STATUSES } from 'modules/onboarding/components/common/DataLoad/helpers';
import NotificationHeader from '../../header/NotificationHeader';
import Provider from './components/Provider';
import { getTitleMap, getLinkMap, getDataLoadingContent } from './helpers';
import StyledDataLoading from './DataLoading.style';

const DataLoading = ({ item, markNotification, onFileDownload, onFileDownloaded }) => {
  const {
    id,
    createdDate,
    typeId,
    content: { fileName, errorFileName, status, lastUploaded, inValidationMode, providerName },
  } = item;
  const isError = status === STATUSES.exceptionOccurred;
  const TITLE_MAP = getTitleMap();
  const CONTENT = getDataLoadingContent({ fileName, lastUploaded, inValidationMode });
  const LINK_MAP = getLinkMap(errorFileName);
  const statusType = isError ? 'exceptionOccurred' : 'processedSuccessfully';

  return (
    <StyledDataLoading data-testid="data-loading">
      {status === STATUSES.processedWithErrors ? (
        <FileDownloadNotification
          title={TITLE_MAP[typeId]}
          content={
            <Fragment>
              {CONTENT[STATUSES.processedWithErrors]}
              {providerName && <Provider name={providerName} />}
            </Fragment>
          }
          notificationId={id}
          notificationDate={createdDate}
          markNotification={markNotification}
          onFileDownload={onFileDownload}
          onFileDownloaded={onFileDownloaded}
          downloadLink={LINK_MAP[typeId]}
          item={item}
        />
      ) : (
        <Fragment>
          <NotificationHeader
            createdDate={createdDate}
            title={TITLE_MAP[typeId]}
            isError={isError}
          />
          <Paragraph className="notification-content">
            <span>
              {CONTENT[STATUSES[statusType]]}
              {providerName && <Provider name={providerName} />}
            </span>
            {!item.isRead && (
              <Button type="link" className="notification-link" action={() => markNotification(id)}>
                <Text path="notifications.MARK_AS_READ" />
              </Button>
            )}
          </Paragraph>
        </Fragment>
      )}
    </StyledDataLoading>
  );
};

DataLoading.propTypes = {
  item: PropTypes.shape({
    isRead: PropTypes.bool,
    id: PropTypes.string.isRequired,
    content: PropTypes.shape({
      fileName: PropTypes.string,
      errorFileName: PropTypes.string,
      status: PropTypes.string,
      lastUploaded: PropTypes.string,
      inValidationMode: PropTypes.string,
      providerId: PropTypes.string,
      providerName: PropTypes.string,
    }).isRequired,
    createdDate: PropTypes.string.isRequired,
    typeId: PropTypes.string.isRequired,
  }).isRequired,
  markNotification: PropTypes.func.isRequired,
  onFileDownload: PropTypes.func.isRequired,
  onFileDownloaded: PropTypes.func.isRequired,
};

export default memo(DataLoading);
