import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isBlank } from '@trs/utils';
import StyledMenu from './Menu.style';
import * as userActions from '../../../actions/userActions';
import ConnectedMenuNavigator from './MenuNavigator';
import Layout from '../../common/layout/Layout';

import { mimicEmployeeExit } from '../../rmDashboard/components/dashboard/actions';
import MimicEmployeeViewBanner from './MimicEmployeeViewBanner';
import { getMenuModel } from '../../../config/userManager/menuConfig';
import { menuGenerator } from './helpers';
import Logo from './Logo';
import SubmissionMenu from './SubmissionMenu';

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: R.path(['history', 'location', 'pathname'], props),
    };
  }

  componentDidMount() {
    const { history } = this.props;
    this.handleMimicRedirects();

    this.unlisten = history.listen(() => {
      this.setState({
        currentPage: R.path(['location', 'pathname'])(history),
      });
      this.handleMimicRedirects();
    });
  }

  componentDidUpdate() {
    this.handleMimicRedirects();
  }

  componentWillUnmount() {
    this.unlisten();
  }

  handleMimicRedirects = () => {
    const {
      user: { mimic },
      history,
    } = this.props;
    const currentPage = R.path(['location', 'pathname'])(history);

    if (mimic.isMimicView && !currentPage.includes('employee-statement')) {
      history.push({ pathname: '/employee-statement', state: { isMimicView: mimic.isMimicView } });
      return;
    }

    if (mimic.redirectToRmDashboard && !currentPage.includes('home')) {
      history.push('/home');
    }
  };

  generateFilteredMenu = () => {
    const { user, featureToggles, modules } = this.props;

    const menuModelList = getMenuModel(featureToggles, modules);
    let menu = menuGenerator(menuModelList, modules);

    const profileLink = R.find(R.propEq('id', 'profile'))(menu);
    const logoutLink = profileLink.items[1];
    if (isBlank(R.path(['profile', 'firstName'], user))) {
      if (!profileLink.items[0]) {
        menu = R.without(profileLink, menu);
        menu.push(logoutLink);
      }
    } else {
      profileLink.title = R.path(['profile', 'firstName'], user);
    }

    return menu;
  };

  render() {
    const { user } = this.props;
    const filteredMenu = this.generateFilteredMenu();

    const { currentPage } = this.state;
    const shouldShowMenu = R.prop('isLoggedIn', user) && !currentPage.includes('callback');
    const isMimicView = R.path(['mimic', 'isMimicView'], user);
    const isSubmissionPage = currentPage.includes('/employee-statement/view/');

    return (
      <header className="App-header">
        <StyledMenu isMimicView={isMimicView}>
          <Layout>
            <div className="Menu-Wrapper">
              {isMimicView && (
                <MimicEmployeeViewBanner
                  profile={user.profile}
                  mimicEmployeeExit={this.props.actions.mimicEmployeeExit}
                />
              )}
              <Logo />
              {!isSubmissionPage ? (
                shouldShowMenu && (
                  <ConnectedMenuNavigator menuModel={filteredMenu} currentPage={currentPage} />
                )
              ) : (
                <SubmissionMenu />
              )}
            </div>
          </Layout>
        </StyledMenu>
      </header>
    );
  }
}

Header.defaultProps = {
  user: null,
};

Header.propTypes = {
  user: PropTypes.shape({
    mimic: PropTypes.shape({
      isMimicView: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
      redirectToRmDashboard: PropTypes.bool,
    }),
    authorizedModules: PropTypes.shape({}),
    landingPage: PropTypes.string,
    isLoggedIn: PropTypes.bool.isRequired,
    profile: PropTypes.shape({
      firstName: PropTypes.string,
    }).isRequired,
  }),
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    listen: PropTypes.func.isRequired,
  }).isRequired,
  label: PropTypes.shape({}).isRequired,
  actions: PropTypes.shape({
    mimicEmployeeExit: PropTypes.func.isRequired,
  }).isRequired,
  featureToggles: PropTypes.arrayOf(PropTypes.string).isRequired,
  modules: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  label: state.cms.generic.data,
  featureToggles: state.configuration.featureToggles,
  modules: state.user.authorizedModules,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...userActions, mimicEmployeeExit }, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
