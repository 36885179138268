export default {
  NEW_VARIABLE_PAGE_TITLE: 'New Variable',
  EDIT_VARIABLE_PAGE_TITLE: 'Edit Variable',
  DEFINE_VARIABLE_CARD_TITLE: 'Define variable',
  COUNTRY_LABEL: 'Country',
  NAME_LABEL: 'Name',
  VALUE_LABEL: 'Value',
  TYPE_LABEL: 'Type',
  COUNTRY_PLACEHOLDER: 'Select a country',
  NAME_PLACEHOLDER: 'Name of the variable',
  VALUE_PLACEHOLDER: 'Amount',
  VALUE_PLACEHOLDER_PERCENT: 'Amount in percentage',
  VALUE_PLACEHOLDER_NUMBERS: 'Amount in numbers',
  DIALOG_SAVE_AFFECTED_REWARDS_SUBTITLE:
    'The changes on this variable will affect %TOTAL% items. Please bear in mind that you may not have access to some of the areas affected. Are you sure you want to continue?',
  DIALOG_SAVE_AFFECTED_REWARDS_CALCULATING:
    'You cannot save your changes while rewards are calculating. Please wait a few minutes, refresh the page, and then try again.',
  VALIDATION_CAPTION:
    'The variable name can have only letters, numbers, spaces, dashes and underscores. It can only start with a letter.',
  LIST_TYPE_LABEL: 'List',
  UPLOAD_FILE: 'Upload file',
  VARIABLED_SAVED: 'Variable %NAME% has been saved successfully!',
  COUNT_VALUES_LABEL: 'Value(s)',
  SEARCH_PLACEHOLDER: 'Search Values',
  DIALOG_VARIABLE_TYPE_SWITCH_TITLE: 'Confirm variable type switch',
  DIALOG_VARIABLE_TYPE_SWITCH_BODY:
    'The variable %FIELD% will be cleared when switching the variable type.',
  DIALOG_EXIT: 'Are you sure you want to exit?',
  USAGE_REWARDS: 'Rewards',
  USAGE_FLEX: 'Flex Packages',
  USAGE_BENCHMARKING: 'Plan',
  USAGES_FAILED:
    'Could not retrieve variable usages. Please be advised that this variable may be in use.',
  AFFECTED_POPULATION_IS_FORBIDDEN: 'Your role permissions do not allow updating this variable.',
};
