import * as R from 'ramda';
import { FormattedNumber } from '@trs/components';
import { isBlank } from '@trs/utils';
import APP_CONFIG, { MAX_DECIMALS } from '@trs/config';
import getTextOnlyComponent from './rewardCategory/TextOnlyComponent';

const noValue = '-';

export const getRewardValueOutput = (rew, rewIndex, valueIndex, currency, hasDecimals) => {
  const isTextOnlyReward = !R.isNil(R.path([rewIndex, 'textValue'], rew));

  let value = R.path([rewIndex, 'value', valueIndex], rew);
  if (isTextOnlyReward) {
    value = !R.isNil(value) ? getTextOnlyComponent(value, 20) : '-';

    return value;
  }

  const { TEMPLATE_TYPES } = APP_CONFIG;

  return isBlank(value)
    ? noValue
    : `${FormattedNumber({
        value,
        decimals: MAX_DECIMALS,
        hasDecimals,
      })} ${rew[0] && rew[0].template === TEMPLATE_TYPES.percentage ? '%' : currency}`;
};

export const getCategoryTotal = (data, index) => {
  const firstGroup = R.path(['groups', 0], data);
  const secondGroup = R.path(['groups', 1], data);
  if (isBlank(firstGroup)) return [];

  const bulkData = isBlank(secondGroup) ? firstGroup : firstGroup.concat(secondGroup);
  return R.map(
    (item) => ({
      value: R.path(['total', index], item),
      orderIndex: item.orderIndex,
    }),
    bulkData
  );
};

export const getStatementsValuesIndexes = (rew) => {
  let valuesIndexes = {
    currentIndex: -1,
    newIndex: -1,
  };

  rew.forEach(({ value }, index) => {
    const currentStatementValue = value[0];
    const newStatementValue = value[1];

    if (!isBlank(newStatementValue)) {
      valuesIndexes = { ...valuesIndexes, ...{ newIndex: index } };
    }

    if (!isBlank(currentStatementValue)) {
      valuesIndexes = { ...valuesIndexes, ...{ currentIndex: index } };
    }
  });

  return valuesIndexes;
};

export default {
  getRewardValueOutput,
  getCategoryTotal,
  getStatementsValuesIndexes,
};
