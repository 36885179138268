import * as R from 'ramda';

export const transformSystemPayscalesPayload = (payload = {}) => {
  return R.map(row => {
    return R.omit(['dimensions'], {
      code: payload.countryCode,
      ...row.dimensions,
      ...row,
    });
  }, payload || []);
};

export const updateSystemPayscalesRows = (currRows, updatedRows) => {
  return currRows.reduce((acc, curr) => {
    const stored = updatedRows.find(({ id }) => id === curr.id);

    if (stored) acc.push(stored);
    else acc.push(curr);

    return acc;
  }, []);
};

export default { transformSystemPayscalesPayload };
