import React from 'react';
import { H5, Card, Collapse, CollapseItem, CollapseItemTitle, Icon } from '@trs/components';
import { isBlank } from '@trs/utils';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import CategoryTiles from './categoryTiles/CategoryTiles';
import StyledAvailableOptions from './AvailableOptions.style';
import { TextOnly } from '../../../../../common/Text';

class AvailableOptions extends React.Component {
  onStepperChange = (updatedReward) => {
    this.props.changeSelectedStepperOption(updatedReward);
  };

  render() {
    const {
      availableOptions,
      selectedRewards,
      disabled,
      toggleIsEditing,
      handleShowStepperOptions,
      budgetLabel,
      hasDecimals,
    } = this.props;

    return (
      <StyledAvailableOptions className="available-options">
        {isBlank(availableOptions) ? (
          <Card>{TextOnly({ path: 'flexibleRewards.NO_OPTIONS' })}</Card>
        ) : (
          R.values(
            R.mapObjIndexed(
              (subcategories, categoryName) => (
                <section className="category-section" key={categoryName}>
                  <Collapse openItems={[0]}>
                    {({ toggle, openItems, ...other }) => (
                      <CollapseItem {...other}>
                        <CollapseItemTitle>
                          <H5 className="category-title">
                            <Icon
                              className="category-toggle-icon"
                              onClick={() => toggle(0)}
                              icon={`ion-chevron-${openItems.includes(0) ? 'down' : 'right'}`}
                              aria-label={TextOnly({
                                path: 'flexibleRewards.TOGGLE_CATEGORY',
                                transform: (label = '') =>
                                  label.replace('%CATEGORY%', categoryName),
                              })}
                            />
                            <span
                              onClick={() => toggle(0)}
                              onKeyPress={() => toggle(0)}
                              role="presentation"
                            >
                              {categoryName}
                            </span>
                          </H5>
                        </CollapseItemTitle>
                        {openItems.includes(0) && (
                          <CategoryTiles
                            disabled={disabled}
                            onSelectionChange={this.props.onSelectionChange}
                            changeRewardWithWarningMsg={this.props.changeRewardWithWarningMsg}
                            onStepperChange={this.onStepperChange}
                            selectedRewards={selectedRewards}
                            subcategories={subcategories}
                            toggleIsEditing={toggleIsEditing}
                            handleShowStepperOptions={handleShowStepperOptions}
                            budgetLabel={budgetLabel}
                            hasDecimals={hasDecimals}
                          />
                        )}
                      </CollapseItem>
                    )}
                  </Collapse>
                </section>
              ),
              availableOptions
            )
          )
        )}
      </StyledAvailableOptions>
    );
  }
}

AvailableOptions.propTypes = {
  disabled: PropTypes.bool,
  selectedRewards: PropTypes.shape({}).isRequired,
  availableOptions: PropTypes.shape({}).isRequired,
  computeStatement: PropTypes.func.isRequired,
  changeSelectedRewards: PropTypes.func.isRequired,
  changeRewardWithWarningMsg: PropTypes.func.isRequired,
  onSelectionChange: PropTypes.func.isRequired,
  toggleIsEditing: PropTypes.func,
  changeSelectedStepperOption: PropTypes.func,
  handleShowStepperOptions: PropTypes.func.isRequired,
  budgetLabel: PropTypes.string,
  hasDecimals: PropTypes.bool,
};

AvailableOptions.defaultProps = {
  disabled: false,
  hasDecimals: false,
  toggleIsEditing: () => null,
  changeSelectedStepperOption: () => null,
  budgetLabel: '',
};

export default AvailableOptions;
