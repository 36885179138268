import * as R from 'ramda';
import { createAction } from 'redux-actions';
import { GET, POST, PUT } from 'config/http';
import { shares } from 'config/api';
import { toBinary } from '@trs/utils';
import types from './types';
import { showBanner } from '../../../../../actions/globalActions';
import { TextOnly } from '../../../../common/Text';
import getProviersOptions from './helpers';

export const getSharesProvidersAction = createAction(types.GET_SHARES_PROVIDERS);
export const getProvidersAction = createAction(types.GET_PROVIDERS);
export const getProvidersData = createAction(types.GET_PROVIDER_DATA);

export const loadSharesProviders = () => (dispatch) =>
  dispatch(
    getSharesProvidersAction(
      GET(shares.configuration, {}, { showErrorModal: true, dispatch })
        .then((response) => {
          const providersOptions = getProviersOptions(R.path(['data', 'providers'], response));

          return {
            providersOptions,
            selectedProvider: R.head(providersOptions),
          };
        })
        .catch((error) => {
          return Promise.reject(error);
        })
    )
  );

export const getProviders = () => (dispatch) => {
  dispatch(
    getProvidersAction(
      GET(shares.providers, {}, { showErrorModal: true, dispatch })
        .then((response) => R.prop('data', response))
        .catch((error) => {
          return Promise.reject(error);
        })
    )
  );
};

export const getProvidersShares = (id) => (dispatch) => {
  dispatch(
    getProvidersData(
      GET(`${shares.providers}/${id}`, {}, { showErrorModal: true, dispatch })
        .then((response) => {
          const providerData = R.prop('data', response);
          return {
            ...providerData,
            nameCopy: R.prop('name', providerData),
            shares: Array.isArray(providerData.shares)
              ? providerData.shares.map((share) => ({
                  ...share,
                  saved: true,
                }))
              : [],
          };
        })
        .catch((error) => {
          return Promise.reject(error);
        })
    )
  );
};

export const updateProviderData = (providerId, field, value) => (dispatch) => {
  dispatch({
    type: types.UPDATE_PROVIDER_DATA,
    payload: {
      providerId,
      field,
      value,
    },
  });
};

export const saveProviderData = (id) => (dispatch, getState) => {
  const {
    onboarding: {
      sharesProviders: { providers },
    },
  } = getState();

  const providerData = R.find(R.propEq('id', id))(providers);

  const payload = {
    ...providerData,
    name: toBinary(providerData.name),
    nameCopy: toBinary(providerData.nameCopy),
    originalName: toBinary(providerData.originalName),
    shares: providerData.shares.map((share) => {
      const newShare = { ...share };
      if (share.shareName) {
        newShare.shareName = toBinary(share.shareName);
      }
      return newShare;
    }),
  };

  const request = R.isNil(id)
    ? POST(shares.providers, payload)
    : PUT(`${shares.providers}/${id}`, payload);
  dispatch({ type: types.SAVE_PROVIDER_DATA });

  return request
    .then(() => {
      dispatch(
        showBanner({
          type: 'success',
          content: TextOnly({ path: 'onboarding.DIALOG_SAVE_SUCCESS' }),
        })
      );
      dispatch(getProviders());
      dispatch(loadSharesProviders());

      return true;
    })
    .catch((body) => {
      const errorBody = R.path(['response', 'data'], body);
      const errors = R.prop('errors', errorBody);

      errors.forEach((err) => {
        const { field, message } = err;
        dispatch({
          type: types.SAVE_PROVIDER_DATA_FAIL,
          payload: {
            field,
            message,
          },
        });
      });

      return R.path('isSuccessful', errorBody);
    });
};

export const addNewProvider = () => (dispatch) => {
  dispatch({
    type: types.ADD_NEW_PROVIDER,
  });
};

export const clearProvider = () => (dispatch) => {
  dispatch({
    type: types.CLEAR_PROVIDER,
  });
};
