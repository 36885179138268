import { handleActions } from 'redux-actions';
import * as R from 'ramda';
import { compareAsc } from 'date-fns';

import {
  getEmployeePackagesPending,
  getEmployeePackagesSuccess,
  getEmployeePackagesError,
  enrollmentCardReset,
} from '../actions';

export const initialState = {
  employeePackages: {},
  isGettingEmployeePackages: false,
};

const reducer = handleActions(
  new Map([
    [
      getEmployeePackagesPending,
      (state) => ({
        ...state,
        isGettingEmployeePackages: true,
      }),
    ],
    [enrollmentCardReset, () => R.clone(initialState)],
    [
      getEmployeePackagesError,
      (state, action) => ({
        ...state,
        isGettingEmployeePackages: false,
        error: action.payload,
      }),
    ],
    [
      getEmployeePackagesSuccess,
      (state, action) => {
        return {
          ...state,
          employeePackages: action.payload.sort((a, b) => {
            const endDate = (item) => new Date(R.path(['enrollmentWindow', 'endDate'], item));
            return compareAsc(endDate(a), endDate(b));
          }),
          isGettingEmployeePackages: false,
        };
      },
    ],
  ]),
  R.clone(initialState)
);

export default reducer;
