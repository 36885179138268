import styled, { css } from 'styled-components';

const StyledCountryItem = styled.div`
  ${({ theme, isActive }) => {
    const { palette: color, spacing, typography } = theme;

    return css`
      display: flex;
      padding: 0;
      position: relative;
      align-items: center;
      color: ${!isActive ? color.gray.silver : ''};

      i,
      a {
        margin-right: ${spacing.tiny};
      }
      i {
        width: ${spacing.small};
        &.chevron {
          cursor: pointer;
        }
      }
      a {
        color: ${color.gray.shark};
      }
      i.completed-checkmark {
        color: ${color.validation.valid};
      }
      .manual-configuration {
        font-size: ${typography.size.misc};
        font-weight: ${typography.weight.normal};
        color: ${color.gray.silver};
        margin-left: ${spacing.tiny};
      }
    `;
  }}
`;

StyledCountryItem.displayName = 'StyledCountryItem';
export default StyledCountryItem;
