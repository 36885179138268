"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
var _TabHeader = _interopRequireDefault(require("./TabHeader.style"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var StyledPillContainer = (0, _styledComponents["default"])(_TabHeader["default"]).withConfig({
  displayName: "StyledPillContainerstyle__StyledPillContainer",
  componentId: "sc-1pu86uw-0"
})(["", ""], function (props) {
  var _props$theme = props.theme,
    typography = _props$theme.typography,
    color = _props$theme.palette,
    spacing = _props$theme.spacing;
  return (0, _styledComponents.css)(["display:flex;height:", ";border-radius:", ";background-color:", ";margin:0;&&{.tab-wrapper{.tab-element{font-weight:", ";border-bottom:0;padding:0 ", ";color:", ";text-transform:capitalize;transition:0.2s linear all;text-align:center;&.selected{border-radius:", ";box-shadow:0 1px ", " 0 rgba(0,0,0,0.04);background-color:", ";color:", ";}}}}"], spacing.large, spacing.xBase, color.accent.bravo, typography.weight.bold, spacing.base, color.gray.bravo, spacing.xBase, spacing.tiny, color.basic.white, color.secondary);
});
StyledPillContainer.displayName = 'StyledPillContainer';
var _default = StyledPillContainer;
exports["default"] = _default;