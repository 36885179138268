export default {
  PAYSCALE_INCREASE_TITLE: 'Payscale Increase',
  PAYSCALE_INCREASE_PROPOSED: 'Proposed Payscale ',
  CURRENT_YEAR_PP: 'Current 100% PP',
  PAY_POINT_MOVEMENT: 'PP Movement %',
  MIN_PAY_POINT: 'Min PP',
  MIDDLE_PAY_POINT: '100% PP',
  MAX_PAY_POINT: 'Max PP',
  PROGRESSION: 'Progression (%)',
  PAY_OVERLAP: 'Pay Overlap (%)',
  PAYSCALE_PROPOSAL_SAVE_SUCCESS: 'Payscale Proposal was saved successfully',
  PAYSCALE_INCREASE_SUBMIT_SUCCESS: 'Payscale Increase was submitted successfully',
  PAYSCALE_INCREASE_APROVED_SUCCESS: 'Payscale Increase was successfully approved.',
  PAYSCALE_INCREASE_REJECT_SUCCESS: 'Payscale Increase was rejected.',
  PAYSCALE_INCREASE_REJECTION_TITLE: '"Payscale Increase" has been rejected.',
  TAB_HEADER_BASE_PAY_MARKET_COMPARISON: 'Base Pay Market Comparison',
  TAB_HEADER_COMPA_RATIO: 'Compa Ratio',
  TAB_HEADER_COST_TO_BRING_TO_MINIMUM: 'Cost to Bring to Minimum',
  COLUMN_MARKET_POSITION_P50: 'Market Position at P50 %',
  COLUMN_MARKET_POSITION_P75: 'Market Position at P75 %',
  COLUMN_EMPLOYEES_BELOW_MIN: 'No of Employees below Min',
  COLUMN_COST_ANNUAL_BASE_PAY_TO_MIN: 'Cost to bring Annual Base Pay to Min',
  COLUMN_COST_ANNUAL_BONUS_INCREASE: 'Cost from Annual Bonus Increase',
  COLUMN_TOTAL_COST_TO_MIN: 'Cost to bring Base Pay and Annual Bonus to minimum',
  COLUMN_TOTAL_NUMBER_OF_EMPLOYEES: 'Total Number of Employees',
  COLUMN_CURRENT_YEAR: 'Current Year (%)',
  COLUMN_NEXT_YEAR_PREMERIT: 'Next Year Pre Merit (%)',
  COLUMN_NEXT_YEAR_POSTMERIT: 'Next Year Post Merit (%)',
  COLUMN_GRAND_TOTAL_LABEL: 'Weighted Average',
  COLUMN_COMPA_RATIO_TOTAL: 'Weighted Average',
};
