export const USERS_REQUESTED = 'USERS_REQUESTED';
export const USERS_GET = 'USERS_GET';
export const USERS_ROLE_FIELD_CHANGE = 'USERS_ROLE_FIELD_CHANGE';
export const USERS_ROLE_SELECTED_CHANGE = 'USERS_ROLE_SELECTED_CHANGE';
export const USER_RESET_DEFAULT = 'USER_RESET_DEFAULT';
export const USER_SELECT_TOGGLE = 'USER_SELECT_TOGGLE';
export const USERS_SELECTED_CLEAR = 'USERS_SELECTED_CLEAR';
export const USERS_SELECTED_ALL = 'USERS_SELECTED_ALL';
export const USER_MANAGEMENT_BANNER_TOGGLE = 'USER_MANAGEMENT_BANNER_TOGGLE';
export const USERS_GET_ONLY_SELECTED = 'USERS_GET_ONLY_SELECTED';
export const USERS_SELECTED_CLEAR_PAGE = 'USERS_SELECTED_CLEAR_PAGE';
export const USERS_CLEAR_ALL = 'USERS_CLEAR_ALL';
export const USERS_RESET_DEFAULT = 'USER_RESET_DEFAULT';
export const USERS_MANAGEMENT_BANNER_TOGGLE = 'USER_MANAGEMENT_BANNER_TOGGLE';
export const USERS_DELETE_ROLE = 'USERS_DELETE_ROLE';
export const USERS_UPDATE_SNAPSHOT = 'USERS_UPDATE_SNAPSHOT';
export const USERS_UPDATE_USERS_SNAPSHOT = 'USERS_UPDATE_USERS_SNAPSHOT';
export const USERS_RESET_USERSLIST_DEFAULT = 'USERS_RESET_USERSLIST_DEFAULT';
export const USERS_ONLY_SELECTED_TOGGLE = 'USERS_ONLY_SELECTED_TOGGLE';
export const ROLES_REQUESTED = 'ROLES_REQUESTED';
export const ROLES_GET = 'ROLES_GET';
export const ROLES_GET_ALL = 'ROLES_GET_ALL';
export const ROLES_SELECTED_CLEAR = 'ROLES_SELECTED_CLEAR';
export const ROLES_GET_BY_ID = 'ROLES_GET_BY_ID';
export const ROLES_GET_BY_ID_PENDING = 'ROLES_GET_BY_ID_PENDING';
export const ROLES_GET_BY_ID_ERROR = 'ROLES_GET_BY_ID_ERROR';
export const ROLES_GET_USERS_BY_ID = 'ROLES_GET_USERS_BY_ID';
export const RESET_HAS_CHANGES = 'RESET_HAS_CHANGES';
export const USER_MANAGEMENT_GET_COUNTRIES = 'USER_MANAGEMENT_GET_COUNTRIES';
export const USER_GET_USER_BY_ID = 'USER_GET_USER_BY_ID';
export const USER_SET_AS_NON_EMPLOYEE = 'USER_SET_AS_NON_EMPLOYEE';
export const USER_GET_MODULE_PERMISSIONS = 'USER_GET_MODULE_PERMISSIONS';
export const USER_GET_MODULE_PERMISSIONS_PENDING = 'USER_GET_MODULE_PERMISSIONS_PENDING';
export const USER_GET_MODULE_PERMISSIONS_ERROR = 'USER_GET_MODULE_PERMISSIONS_ERROR';

export const STORE_RESET = 'STORE_RESET';
export const REDIRECT_TO_ROOT = 'REDIRECT_TO_ROOT';
