import styled, { css } from 'styled-components';
import { media } from '@trs/utils';
import { flexBudgetShadows } from '../../../../../../assets/images';

const StyledRemainingBudget = styled.aside`
  ${props => {
    const { spacing, palette: color, typography } = props.theme;
    const { isNegative } = props;
    return css`
      color: ${color.basic.white};
      font-size: ${typography.size.table};
      .budget-country-code {
        margin-left: ${spacing.xTiny};
      }
      .remaining-budget-container {
        padding: ${spacing.xSmall} ${spacing.small};
        margin-bottom: ${spacing.base};
        border: 0;
        background:${color.gray.solo} url("${flexBudgetShadows}") no-repeat bottom right;

        ${media.mobileLandscape`
          align-items: center;
          display: flex;
          flex-wrap: wrap;
        `}

        ${media.desktop`
          display: block;
        `}
      }
      h5 {
        opacity: 0.8;
        font-weight: ${typography.weight.normal};
        color: ${color.basic.white};
        line-height: ${typography.lineHeight.medium};
      }
      .remaining-budget-content {
        font-size: ${typography.size.paragraphBig};
        margin-bottom: ${spacing.tiny};
        font-weight: ${typography.weight.bold};
        line-height: normal;
        color: ${isNegative ? color.validation.errorMedium : color.utility.highlight};
        word-break: break-all;
        word-break: break-word;

        ${media.mobileLandscape`
          font-size: ${typography.size.paragraphMedium};
          margin-bottom: 0;
          margin-left: ${spacing.small};
        `}
        
        ${media.desktop`
          font-size: ${typography.size.paragraphBig};
          margin-bottom: ${spacing.tiny};
          margin-left: 0;
        `}

        span {
          font-size: ${typography.size.table};
        }
      }
      .remaining-budget-footer {
        font-weight: ${typography.weight.normal};
        line-height: ${typography.lineHeight.medium};

        ${media.mobileLandscape`
          display: flex;
          flex-basis: 100%;
          margin-top: ${spacing.xTiny};

          .exceeding-budget {
            margin-right: ${spacing.small};
          }
        `}

        ${media.tabletLandscape`
          flex-basis: auto;
          margin-left: ${spacing.small};
        `}

        ${media.desktop`
          display: block;
          margin-left: 0;

          .exceeding-budget {
            margin-right: 0;
          }
        `}

        .footer-item-title , .footer-item-value {
          opacity: 0.5;
          margin-right: ${spacing.xTiny};
        }
        .exceeding-tooltip,
        .minimum-usage-tooltip {
          margin-left: ${spacing.tiny};
          position: relative;
          vertical-align: top;
          .tooltip-text {
            width: 180px;
            .wrapper-class{
              width: 160px;
            }
          }
        }
      }
    `;
  }};
`;

StyledRemainingBudget.displayName = 'StyledRemainingBudget';
export default StyledRemainingBudget;
