import mobility from './mobility';
import authentication from './authentication';
import hr from './hr';
import countries from './countries';
import currency from './currency';
import shares from './shares';
import validation from './validation';
import customisation from './customisation';
import loadDataCard from './loadDataCard';
import moduleSettings from './moduleSettings';
import rewardsConfiguration from './rewardsConfiguration';

export default {
  PAGE_TITLE: 'Welcome to %PLATFORM%',
  PAGE_SUBTITLE:
    'We restricted the access to the features of the %PLATFORM% until all the mandatory information is configured. After all mandatory sections are completed and the information is imported in the system you can start using %PLATFORM%',
  ACCESS_BUTTON: 'Access %PLATFORM%',
  VIEW_BUTTON: 'View',
  EDIT_BUTTON: 'Edit',
  MANDATORY_STATUS: 'Mandatory information',
  OPTIONAL_STATUS: 'Optional information',
  PENDING_STATUS: 'Setting up',
  COMPLETED_STATUS: 'Setup completed',
  DOWNLOAD_TEMPLATE_FOR: 'Download Template for %NAME%',
  ...mobility,
  ...authentication,
  ...hr,
  ...countries,
  ...currency,
  ...shares,
  ...validation,
  ...customisation,
  ...moduleSettings,
  ...loadDataCard,
  ...rewardsConfiguration,
};
