"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
var _helpers = require("../charts/doughnut/helpers");
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var StyledLegendItemBoxColor = _styledComponents["default"].span.withConfig({
  displayName: "LegendItemBoxColorstyle__StyledLegendItemBoxColor",
  componentId: "sc-91se74-0"
})(["", ""], function (props) {
  var boxColor = props.boxColor,
    active = props.active,
    isNegative = props.isNegative;
  var spacing = props.theme.spacing;
  return (0, _styledComponents.css)(["display:inline-block;width:", ";height:", ";margin-right:", ";vertical-align:bottom;background-color:", ";", ""], spacing.base, spacing.base, spacing.small, boxColor, isNegative && (0, _styledComponents.css)(["background-color:", ";border:", ";"], active ? (0, _helpers.getLighterColor)(boxColor, 0.45) : (0, _helpers.getLighterColor)(boxColor, 0.98), active ? 0 : "1px dashed ".concat(boxColor)));
});
StyledLegendItemBoxColor.displayName = 'StyledLegendItemBoxColor';
var _default = StyledLegendItemBoxColor;
exports["default"] = _default;