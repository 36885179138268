import styled, { css } from 'styled-components';
import { media } from '@trs/utils';

const StyledSubmissionPage = styled.section`
  ${({ theme }) => {
    return css`
      margin-top: 30px;

      .submission-page {
        .status-banner__info {
          width: 100%;

          .info-label {
            max-width: 125px;
            
            ${media.mobile`
              max-width: 150px;
            `}
            ${media.mobileLandscape`
              max-width: 250px;
            `}
            ${media.tablet`
              max-width: 320px;
            `}
            ${media.desktop`
              max-width: 125px;
            `}
          }
          
          .info-value {
            max-width: 125px;

            ${media.mobile`
              max-width: 150px;
            `}
            ${media.mobileLandscape`
              max-width: 250px;
            `}
            ${media.tablet`
              max-width: 320px;
            `}

            ${media.desktop`
              max-width: 125px;
            `}
          }
        }
      }

      .description {
        margin-bottom: ${theme.spacing.basePlus};
      }

      .package-title {
        margin-top: ${theme.spacing.basePlus};
      }
    `;
  }};
`;

StyledSubmissionPage.displayName = 'StyledSubmissionPage';
export default StyledSubmissionPage;
