import APP_CONFIG from '@trs/config';

const { GENERAL_STATUSES } = APP_CONFIG;

export const DEFAULT_PILL_SIZE = 16;

export const ARTICLE_NAME_FIELD_MAX_LENGTH = 100;
export const ARTICLE_NAME_FIELD_MIN_LENGTH = 3;
export const ARTICLE_CONTENT_FIELD_MAX_LENGTH = 5000;
export const ARTICLE_CONTENT_FIELD_MIN_LENGTH = 1;

export const ELIGIBILITY_INDEX = 0;

export const ARTICLE_LIST_RESULTS_PER_PAGE = 9;

export const ARTICLE_REVERTABLE_STATUSES = [
  GENERAL_STATUSES.LIVE,
  GENERAL_STATUSES.PUBLISHED,
  GENERAL_STATUSES.EXPIRED,
  GENERAL_STATUSES.ERROR,
];

export const ARTICLE_SECTION_LABELS = {
  category: 'articles.GENERAL_LABEL_CATEGORY',
  country: 'articles.COUNTRY',
  startDate: 'articles.START_DATE',
  endDate: 'articles.END_DATE',
};

export const ARTICLE_LIST_LABELS = {
  category: 'articles.CATEGORY_LABEL',
  startDate: 'articles.START_DATE',
  endDate: 'articles.END_DATE',
};
