import Loadable from 'react-loadable';
import { PageLoader } from '@trs/components';

const OnboardingRoot = Loadable({
  loader: () => import('../../modules/onboarding/routes/Root'),
  loading: PageLoader,
});

const FlexibleRewardRoot = Loadable({
  loader: () => import('../../modules/flexibleRewards/routes/Root'),
  loading: PageLoader,
});

const ModellerRoot = Loadable({
  loader: () => import('../../modules/modeller/routes/Root'),
  loading: PageLoader,
});

const ImpactAnalysisRoot = Loadable({
  loader: () => import('../../modules/modeller/routes/RewardImpactRoot'),
  loading: PageLoader,
});

const VariablesRoot = Loadable({
  loader: () => import('../../modules/variables/routes/Root'),
  loading: PageLoader,
});

const ArticlesRoot = Loadable({
  loader: () => import('../../modules/articles/routes/Root'),
  loading: PageLoader,
});

const RewardsRoot = Loadable({
  loader: () => import('../../modules/rewards/routes/Root'),
  loading: PageLoader,
});

const ForeignExchangeRoot = Loadable({
  loader: () => import('../../modules/foreignExchange/routes/Root'),
  loading: PageLoader,
});

const RmDashboardRoot = Loadable({
  loader: () => import('../../modules/rmDashboard/routes/Root'),
  loading: PageLoader,
});

const UserManagementRoot = Loadable({
  loader: () => import('../../modules/userManagement/routes/Root'),
  loading: PageLoader,
});

const ExceptionsRoot = Loadable({
  loader: () => import('../../modules/exceptions/routes/Root'),
  loading: PageLoader,
});

const EmployeeStatementRoot = Loadable({
  loader: () => import('../../modules/employeeStatement/routes/Root'),
  loading: PageLoader,
});

const WorkspaceRoot = Loadable({
  loader: () => import('../../modules/workspace/routes/Root'),
  loading: PageLoader,
});

const BenchmarkingRoot = Loadable({
  loader: () => import('../../modules/benchmarking/routes/Root'),
  loading: PageLoader,
});

const ReportsRoot = Loadable({
  loader: () => import('../../modules/flexible-reports/routes/Root'),
  loading: PageLoader,
});

const BenefitsEnvelopeRoot = Loadable({
  loader: () => import('../../modules/benefitsEnvelope/routes/Root'),
  loading: PageLoader,
});

const BenefitsEnvelopeAuditRoot = Loadable({
  loader: () => import('../../modules/benefitsEnvelopeAudit/routes/Root'),
  loading: PageLoader,
});

const FixedCostsRoot = Loadable({
  loader: () => import('../../modules/fixedCosts/routes/Root'),
  loading: PageLoader,
});

const LtiPlansRoot = Loadable({
  loader: () => import('../../modules/ltiPlans/routes/Root'),
  loading: PageLoader,
});

export {
  FlexibleRewardRoot,
  ModellerRoot,
  ImpactAnalysisRoot,
  VariablesRoot,
  ArticlesRoot,
  RewardsRoot,
  ForeignExchangeRoot,
  RmDashboardRoot,
  UserManagementRoot,
  ExceptionsRoot,
  EmployeeStatementRoot,
  OnboardingRoot,
  WorkspaceRoot,
  BenchmarkingRoot,
  ReportsRoot,
  BenefitsEnvelopeRoot,
  BenefitsEnvelopeAuditRoot,
  FixedCostsRoot,
  LtiPlansRoot,
};
