import styled, { css } from 'styled-components';

const StyledReport = styled.div`
  ${() => {
    return css`
      .download-link {
        a {
          margin-left: 0;
        }
      }
    `;
  }};
`;

StyledReport.displayName = 'StyledReport';

export default StyledReport;
