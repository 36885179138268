"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "PageLoader", {
  enumerable: true,
  get: function get() {
    return _PageLoader["default"];
  }
});
Object.defineProperty(exports, "ParagraphPlaceholder", {
  enumerable: true,
  get: function get() {
    return _ParagraphLoader["default"];
  }
});
var _PageLoader = _interopRequireDefault(require("./PageLoader"));
var _ParagraphLoader = _interopRequireDefault(require("./ParagraphLoader"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }