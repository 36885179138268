const initialState = {
  benchmarkingId: null,
  country: null,
  etag: null,
  isLoading: true,
  isSaving: false,
  disableSave: true,
  proposedPayscales: [],
  isDataValid: false,
  countryName: '',
  basePayMarketComparison: [],
  compaRatio: {
    compaRatioRows: [],
    total: {},
  },
  costToBringToMinimum: {
    costToBringToMinimumRows: [],
    total: {},
  },
  isCalculating: false,
  hasError: false,
  calculateError: false,
  costToBringToMinimumCalculationInProgress: false,
  costToBringToMinimumCalculationError: false,
};

export default initialState;
