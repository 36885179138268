import * as R from 'ramda';
import { extractTimeFromDate } from '@trs/utils';
import { ROLES_SELECT_DATA } from '../config/constants';

export const processList = rows =>
  R.reduce(
    (result, row) => {
      const arr = result;
      arr.push({
        ...row,
        executionDateLocal: R.prop('executionDate', row),
      });
      return arr;
    },
    [],
    rows
  );

export const setRoleObject = role => {
  if (!R.has('value', role)) {
    return R.find(R.propEq('value', role))(ROLES_SELECT_DATA);
  }
  return role;
};

export const buildFilter = ({ userId, employeeId, from, to, role, page }) => {
  const fromTimeObject = extractTimeFromDate(from);
  const toTimeObject = extractTimeFromDate(to);

  return {
    userId,
    employeeId,
    fromDate: from,
    fromTime: R.prop('label', fromTimeObject),
    toDate: to,
    toTime: R.prop('label', toTimeObject),
    role: setRoleObject(role),
    page: Number(page),
  };
};

export const mapResponseToModel = (response, params) => {
  const { id, name, email, country, rows, rowsCount } = response;

  return {
    id: id || '',
    name: name || '',
    email: email || '',
    country: country || '',
    list: processList(rows),
    totalResults: rowsCount,
    filter: buildFilter(params),
  };
};
