import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { processDate } from '@trs/utils';
import * as userActions from '../../../actions/userActions';

const SubmissionMenu = (props) => {
  const {
    profile: { firstName, lastName },
    packageDetails: { submissionDate },
  } = props;
  const date = processDate(submissionDate);

  return (
    date && (
      <ul className="menu-list">
        <li className="subList-item align-center">
          <p>
            {firstName} {lastName}
          </p>
        </li>
        <li className="subList-item align-right">
          <p>Submission Date: {date}</p>
        </li>
      </ul>
    )
  );
};

SubmissionMenu.defaultProps = {
  packageDetails: {
    submissionDate: new Date(),
  },
};

SubmissionMenu.propTypes = {
  packageDetails: PropTypes.shape({
    submissionDate: PropTypes.string,
  }),
  profile: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => {
  return {
    packageDetails: R.path(['packageDetails'], state),
    profile: R.path(['user', 'profile'], state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...userActions }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubmissionMenu);
