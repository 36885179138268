import * as actions from '../actions/actionTypes';

const initialState = {
  dialog: {
    visible: false,
    dialogButtonLabels: {
      confirmLabel: '',
      cancelLabel: '',
    },
  },
  approvedCookiePolicy: JSON.parse(sessionStorage.getItem('approvedCookiePolicy'))
    ? JSON.parse(sessionStorage.getItem('approvedCookiePolicy'))
    : false,
};

export default function globalReducer(state = initialState, action) {
  switch (action.type) {
    case actions.ADD_BANNER:
      return {
        ...state,
      };

    case actions.REMOVE_BANNER:
      return {
        ...state,
      };

    case actions.SHOW_DIALOG:
      return {
        ...state,
        dialog: {
          ...state.dialog,
          ...action.options,
          visible: true,
        },
      };

    case actions.HIDE_DIALOG:
      return {
        ...state,
        dialog: {
          visible: false,
        },
      };
    case actions.REMOVE_ALL_BANNERS:
      return {
        ...state,
      };

    case actions.SET_COOKIE_POLICY:
      return {
        ...state,
        approvedCookiePolicy: action.approvedCookiePolicy,
      };

    default:
      return state;
  }
}
