import { isBlank } from '@trs/utils';
import * as actions from '../actions/actionTypes';

const initialState = {
  countries: [],
  modules: [],
};

export default function lookupReducer(state = initialState, action) {
  switch (action.type) {
    case actions.LOOKUP_COUNTRIES: {
      let countries = [];

      const { data } = action.response;

      if (!isBlank(data)) {
        countries = data.map(item => ({ ...item, name: item.name }));
      }

      return {
        ...state,
        countries,
      };
    }

    case actions.LOOKUP_FEATURE_MODULES: {
      let modules = [];

      const { data } = action.response;

      if (!isBlank(data)) {
        modules = data.map(item => ({ ...item, name: item.name }));
      }

      return {
        ...state,
        modules,
      };
    }

    default:
      return state;
  }
}
