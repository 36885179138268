"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var StyledTable = _styledComponents["default"].table.withConfig({
  displayName: "Tablestyle__StyledTable",
  componentId: "sc-6yb1c6-0"
})(["", ";"], function (props) {
  var _props$theme = props.theme,
    color = _props$theme.palette,
    typography = _props$theme.typography;
  var align = props.align,
    borderless = props.borderless,
    hoverable = props.hoverable,
    striped = props.striped;
  return (0, _styledComponents.css)(["background-color:transparent;border-collapse:collapse;font-size:", ";width:100%;max-width:100%;& *{vertical-align:middle;}", " ", " ", " ", ""], typography.size.table, align && (0, _styledComponents.css)(["text-align:", ";"], align), borderless && (0, _styledComponents.css)(["> tbody > tr{th,td{border:none;}}"]), striped && (0, _styledComponents.css)(["tbody tr:nth-of-type(odd){background-color:", ";}"], color.accent.delta), hoverable && (0, _styledComponents.css)(["tbody tr:hover td{background-color:", ";}"], color.accent.charlie));
});
StyledTable.displayName = 'StyledTable';
var _default = StyledTable;
exports["default"] = _default;