import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isBlank } from '@trs/utils';
import { shouldHideFeature } from 'modules/common/helpers';
import NotFoundPage from 'routes/static/notFound';

const withFeatureToggle =
  ({ withNotFound = true } = {}) =>
  (ChildComponent, key) => {
    if (isBlank(ChildComponent)) {
      throw new Error(
        'No component provided to HOC. ex: withFeatureToggle({withNotFound: true})(Audit, "Audit")'
      );
    }
    if (isBlank(key)) {
      throw new Error(
        'No key provided to HOC. ex: withFeatureToggle({withNotFound: true})(Audit, "Audit")'
      );
    }
    class FeatureToggleComponent extends PureComponent {
      render() {
        const {
          configuration: { featureToggles },
        } = this.props;

        if (shouldHideFeature(key, featureToggles)) {
          return withNotFound ? NotFoundPage : null;
        }

        if (ChildComponent) {
          return <ChildComponent {...this.props} />;
        }

        return null;
      }
    }

    const mapStateToProps = (state) => ({
      configuration: state.configuration,
    });

    FeatureToggleComponent.propTypes = {
      configuration: PropTypes.shape({
        featureToggles: PropTypes.arrayOf(PropTypes.string).isRequired,
      }).isRequired,
    };

    return connect(mapStateToProps, null)(FeatureToggleComponent);
  };

export default withFeatureToggle;
