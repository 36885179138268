"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.strings = exports.sortListDescendingByProp = exports.sortListAscendingByProp = exports.safeJSONParse = exports.rejectEmptyValues = exports.objArrayToDict = exports.isNegative = exports.isBlank = exports.flattenObject = exports.filterNull = exports.fillArray = exports.dictToArray = void 0;
var R = _interopRequireWildcard(require("ramda"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i["return"] && (_r = _i["return"](), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
var fillArray = function fillArray(array, length) {
  var filledArray = R.concat(array, R.slice(0, length - array.length, array));
  if (filledArray.length < length) {
    fillArray(filledArray, length);
  }
  return filledArray;
};
exports.fillArray = fillArray;
var isBlank = function isBlank(p) {
  return R.isNil(p) || R.isEmpty(p);
};
exports.isBlank = isBlank;
var isNegative = function isNegative(p) {
  return R.lt(p, 0) || p[0] === '-';
};
exports.isNegative = isNegative;
var rejectEmptyValues = R.reject(isBlank);
exports.rejectEmptyValues = rejectEmptyValues;
var flattenObject = function flattenObject(obj) {
  var go = function go(obj_) {
    return R.chain(function (_ref) {
      var _ref2 = _slicedToArray(_ref, 2),
        k = _ref2[0],
        v = _ref2[1];
      if (R.type(v) === 'Object' || R.type(v) === 'Array') {
        return R.map(function (_ref3) {
          var _ref4 = _slicedToArray(_ref3, 2),
            k_ = _ref4[0],
            v_ = _ref4[1];
          return ["".concat(k, ".").concat(k_), v_];
        }, go(v));
      }
      return [[k, v]];
    }, R.toPairs(obj_));
  };
  return R.fromPairs(go(obj));
};
exports.flattenObject = flattenObject;
var strings = function strings(source) {
  return R.values(source).filter(function (item) {
    return typeof item === 'string';
  });
};
exports.strings = strings;
var filterNull = R.reject(function (item) {
  return R.isNil(item);
});
exports.filterNull = filterNull;
var safeJSONParse = function safeJSONParse(str) {
  try {
    return JSON.parse(str);
  } catch (e) {
    return {};
  }
};
exports.safeJSONParse = safeJSONParse;
var sortListAscendingByProp = R.curry(function (prop, list) {
  if (isBlank(prop) || isBlank(list)) return [];
  return R.sort(R.ascend(R.prop(prop)))(list);
});
exports.sortListAscendingByProp = sortListAscendingByProp;
var sortListDescendingByProp = R.curry(function (prop, list) {
  if (isBlank(prop) || isBlank(list)) return [];
  return R.sort(R.descend(R.prop(prop)))(list);
});
exports.sortListDescendingByProp = sortListDescendingByProp;
var objArrayToDict = function objArrayToDict(list, keyProp) {
  return list.reduce(function (dict, el) {
    return _objectSpread(_objectSpread({}, dict), {}, _defineProperty({}, el[keyProp], el));
  }, {});
};
exports.objArrayToDict = objArrayToDict;
var dictToArray = function dictToArray(dict) {
  return Object.keys(dict).reduce(function (arr, key) {
    return [].concat(_toConsumableArray(arr), [dict[key]]);
  }, []);
};
exports.dictToArray = dictToArray;