export const FEEDBACK_RATING_ICON_SIZE_SMALL = 48;
export const FEEDBACK_RATING_ICON_SIZE_LARGE = 100;
export const FEEDBACK_RATING_ICON_SIZE_MEDIUM = 64;
export const MAX_FEEDBACK_LENGTH = 1000;
export const MIN_FEEDBACK_LENGTH = 1;
export const FEEDBACK_RATING_ICON_SIZE_TINY = 32;
export const NOT_SELECTED_RATING = -1;
export const DEFAULT_FACE = 'ion-no-feedback';
export const contextMenuIcon = 'face-small-black';
export const FEEDBACK_MOODS = {
  verySad: 'feedback-rating-1',
  sad: 'feedback-rating-2',
  neutral: 'feedback-rating-3',
  happy: 'feedback-rating-4',
  veryHappy: 'feedback-rating-5',
};

export const RATING_ICONS = [
  {
    id: 1,
    iconName: FEEDBACK_MOODS.verySad,
    iconSize: FEEDBACK_RATING_ICON_SIZE_SMALL,
    scale: 0.125,
    score: 0,
  },
  {
    id: 2,
    iconName: FEEDBACK_MOODS.sad,
    iconSize: FEEDBACK_RATING_ICON_SIZE_SMALL,
    scale: 0.375,
    score: 0.25,
  },
  {
    id: 3,
    iconName: FEEDBACK_MOODS.neutral,
    iconSize: FEEDBACK_RATING_ICON_SIZE_SMALL,
    scale: 0.625,
    score: 0.5,
  },
  {
    id: 4,
    iconName: FEEDBACK_MOODS.happy,
    iconSize: FEEDBACK_RATING_ICON_SIZE_SMALL,
    scale: 0.875,
    score: 0.75,
  },
  {
    id: 5,
    iconName: FEEDBACK_MOODS.veryHappy,
    iconSize: FEEDBACK_RATING_ICON_SIZE_SMALL,
    scale: 1,
    score: 1,
  },
];
export const FEEDBACK_STATS_CSV_EXPORT = 'FeedbackStats.csv';
