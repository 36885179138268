/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import ROUTE_PATHS from '../routePaths';
import { hasRouteAccess } from '../../config/userManager/menuConfig';

const PrivateRoute = ({
  user: {
    authorizedModules,
    isLoggedIn,
    mimic: { isMimicView },
  },
  component: Component,
  ...otherProps
}) => {
  const hasAccess = hasRouteAccess(authorizedModules, otherProps.path);

  return (
    <Route
      {...otherProps}
      render={(props) => {
        if (!isLoggedIn) {
          return <Redirect to={{ pathname: ROUTE_PATHS.LOGIN }} />;
        }

        if (isMimicView && !hasAccess) {
          <Redirect to={{ pathname: ROUTE_PATHS.EMPLOYEE_STATEMENT }} />;
        }

        if (!hasAccess) {
          <Redirect to={{ pathname: ROUTE_PATHS.UNAUTHORIZED }} />;
        }

        return <Component {...props} />;
      }}
    />
  );
};

PrivateRoute.defaultProps = {
  location: {},
};

PrivateRoute.propTypes = {
  user: PropTypes.shape({
    authorizedModules: PropTypes.shape({}),
    isLoggedIn: PropTypes.bool,
    mimic: PropTypes.shape({
      isMimicView: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape({})]),
    }),
  }).isRequired,
  component: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func]).isRequired,
  location: PropTypes.shape({}),
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(PrivateRoute);
