import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { Banner } from '@trs/components';
import { isBlank } from '@trs/utils';
import StyledBanners from './Banners.style';
import 'react-toastify/dist/ReactToastify.min.css';
import { TextOnly } from '../Text';

const BANNERS_AUTOHIDE_TIME = 10000;

export const BANNER_CONFIG = {
  closeOnClick: false,
  closeButton: false,
  draggable: false,
  position: 'top-right',
};

const getAutoClose = ({ doNotAutoclose, autoHideTime }) => {
  if (doNotAutoclose) {
    return false;
  }

  return !isBlank(autoHideTime) ? autoHideTime : BANNERS_AUTOHIDE_TIME;
};

export const removeAll = () => toast.dismiss();

export const createBanner = (options) => {
  const toastId = `${options.type}-${options.content}`;
  const opts = { autoClose: getAutoClose(options), toastId };

  if (!toast.isActive(toastId)) {
    toast(
      <Banner
        {...options}
        closeTrigger={() => {
          toast.dismiss(toastId);
        }}
      >
        <div className="banner-message-container">
          {options.content && typeof options.content === 'string'
            ? options.content
            : TextOnly({
                path: 'generic.BANNER_ERROR_GENERIC',
                transform: (label = '') => label.replace('%FIELD%', ''),
              })}
        </div>
      </Banner>,
      opts
    );
  } else {
    toast.update(toastId, opts);
  }
};

export const Banners = () => {
  return (
    <StyledBanners>
      <ToastContainer className="banners" {...BANNER_CONFIG} />
    </StyledBanners>
  );
};

export default Banners;
