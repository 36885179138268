import * as R from 'ramda';
import { GET, POST } from '../../../config/http';
import * as actions from './types';
import { feedback, rewards, shares, organization, ltiPlans } from '../../../config/api';
import { loadRewardCategories } from '../../rewards/actions';

export function clearEmployeeRewardDetails() {
  return {
    type: actions.EMPLOYEE_CLEAR_REWARD_DETAILS,
  };
}

export function resetToInitialFeedback() {
  return {
    type: actions.RESET_TO_INITIAL_FEEDBACK,
  };
}

export const loadEmployeeRewardDetails = (employeeId, rewardId) => (dispatch) => {
  dispatch({
    type: actions.EMPLOYEE_GET_REWARD_DETAILS,
    payload: GET(`${rewards.employeeRewardsCategories}/${employeeId}/rewards/${rewardId}`, {
      Pragma: 'no-cache',
    }).then((response) => response.data),
  });
};

export function storeReset() {
  return {
    type: actions.STORE_RESET,
  };
}

export function loadEmployeeRewardsCategories(id) {
  return (dispatch) =>
    GET(
      `${rewards.employeeRewardsCategories}/${id}/rewards/categories`,
      { Pragma: 'no-cache' },
      { showErrorModal: true, dispatch }
    )
      .then((data) =>
        dispatch(loadRewardCategories())
          .then((categoryMappings) => {
            dispatch({
              type: actions.EMPLOYEE_GET_REWARDS_CATEGORIES,
              rewardsCategories: data,
              categoryMappings,
            });
          })
          .catch(() =>
            dispatch({
              type: actions.EMPLOYEE_GET_REWARDS_CATEGORIES,
              rewardsCategories: data,
            })
          )
      )
      .catch(() => {
        dispatch({
          type: actions.CATEGORIES_GET_FAIL,
        });
      });
}

export function loadEmployeeCategoryDrilldown(id, category) {
  return (dispatch) =>
    GET(`${rewards.employeeRewardsCategories}/${id}/rewards/categories/${category}`, {
      Pragma: 'no-cache',
    }).then((data) => {
      dispatch({
        type: actions.EMPLOYEE_GET_CATEGORY_DRILLDOWN,
        categoryDrilldown: data,
      });
    });
}

export function loadEmployeeShares(id, currencyCode) {
  return (dispatch) => {
    GET(`${shares.employees}/${id}/shares?employeeCurrencyCode=${currencyCode}`, {
      Pragma: 'no-cache',
    }).then((data) => {
      dispatch({
        type: actions.EMPLOYEE_GET_SHARES,
        shareData: data,
      });
    });
  };
}

export function loadEmployeeSharesSummary(id, currencyCode) {
  return (dispatch) => {
    GET(`${shares.employees}/${id}/shares/summary?employeeCurrencyCode=${currencyCode}`, {
      Pragma: 'no-cache',
    })
      .then((data) => {
        dispatch({
          type: actions.EMPLOYEE_GET_SHARES_SUMMARY,
          shareSummary: data,
        });
      })
      .catch((error) =>
        dispatch({
          type: actions.EMPLOYEE_GET_SHARES_SUMMARY_ERROR,
          shareSummary: error,
        })
      );
  };
}

export function resetEmployeeStatement() {
  return {
    type: actions.EMPLOYEE_RESET_STATEMENT,
  };
}

export const changeRewardFeedbackScore = (score) => ({
  type: actions.CHANGE_REWARD_FEEDBACK_SCORE,
  payload: score,
});

export const changeRewardFeedbackText = (text) => ({
  type: actions.CHANGE_REWARD_FEEDBACK_TEXT,
  payload: text,
});

export const loadRewardFeedback = (rewardId) => (dispatch) => {
  dispatch({
    type: actions.GET_REWARD_FEEDBACK_PENDING,
  });

  return GET(
    `${feedback.getRewardFeedback}/${rewardId}`,
    { Pragma: 'no-cache' },
    { showErrorModal: true, dispatch }
  )
    .then((response) => {
      dispatch({
        type: actions.GET_REWARD_FEEDBACK_SUCCESS,
        payload: response.data,
      });
    })
    .catch(() => {
      dispatch({
        type: actions.GET_REWARD_FEEDBACK_ERROR,
      });
    });
};

export const submitFeedback = () => (dispatch, getState) => {
  dispatch({
    type: actions.SEND_FEEDBACK_REWARD_PENDING,
  });

  const rewardId = R.path(
    ['employeeCategoryDetails', 'rewardDetails', 'response', 'rewardId'],
    getState()
  );
  const rewardFeedback = R.path(['employeeCategoryDetails', 'rewardFeedback'], getState());
  const reward = [{ ...rewardFeedback, rewardId }];

  return POST(`${feedback.submitFeedback}`, reward, {}, { showErrorModal: false, dispatch })
    .then(() => {
      dispatch({
        type: actions.SEND_FEEDBACK_REWARD_SUCCESS,
      });
    })
    .catch((err) => {
      const errors = R.path(['response', 'data', 'errors'], err);

      dispatch({
        type: actions.SEND_FEEDBACK_REWARD_ERROR,
      });

      return errors;
    });
};

export const getEmployeePersonalDetails = () => (dispatch) => {
  dispatch({
    type: actions.EMPLOYEE_GET_PERSONAL_DETAILS_PENDING,
  });

  return GET(`${organization.employeeProfileSettings}`, {
    Pragma: 'no-cache',
  })
    .then((response) => {
      dispatch({
        type: actions.EMPLOYEE_GET_PERSONAL_DETAILS,
        payload: R.prop('data', response),
      });
    })
    .catch((error) => Promise.reject(error));
};

export function loadEmployeeLtiPlans(employeeId) {
  return (dispatch) => {
    dispatch({
      type: actions.EMPLOYEE_GET_LTI_PLANS_PENDING,
      payload: true,
    });

    return GET(`${ltiPlans().getEmployeeLtiPlans(employeeId)}`, {
      Pragma: 'no-cache',
    })
      .then((response) => {
        const responseData = R.path(['data', 'response'], response);

        if (R.length(R.prop('plans', responseData)) > 0) {
          dispatch({
            type: actions.EMPLOYEE_GET_LTI_PLANS,
            payload: responseData,
          });
        }
        dispatch({
          type: actions.EMPLOYEE_GET_LTI_PLANS_PENDING,
          payload: false,
        });

        return responseData;
      })
      .catch((e) => {
        dispatch({
          type: actions.EMPLOYEE_GET_LTI_PLANS_PENDING,
          payload: false,
        });
        dispatch(e, dispatch);

        return Promise.reject(e);
      });
  };
}

export function resetEmployeeLtiPlans() {
  return {
    type: actions.EMPLOYEE_RESET_LTI_PLANS,
  };
}
