import * as R from 'ramda';
import { createAction } from 'redux-actions';
import { GET, POST, DELETE, PATCH } from '../../../../../../../../../../../config/http';
import { benchmark } from '../../../../../../../../../../../config/api';
import { showBanner } from '../../../../../../../../../../../actions/globalActions';
import { etagUpdate } from '../../../../../actions/helpers';
import { TextOnly } from '../../../../../../../../../../common/Text';

const actionTypes = {
  BENCHMARKING_LOAD_TARGET_MARKET_GROUP: 'BENCHMARKING_LOAD_TARGET_MARKET_GROUP',
  BENCHMARKING_LOAD_TARGET_MARKET_GROUP_INDUSTRIES:
    'BENCHMARKING_LOAD_TARGET_MARKET_GROUP_INDUSTRIES',
  BENCHMARKING_TARGET_MARKET_GROUP_FIELD_CHANGE: 'BENCHMARKING_TARGET_MARKET_GROUP_FIELD_CHANGE',
  BENCHMARKING_TARGET_MARKET_GROUP_ADD_COMPANY: 'BENCHMARKING_TARGET_MARKET_GROUP_ADD_COMPANY',
  BENCHMARKING_TARGET_MARKET_GROUP_ADD_TOGGLE: 'BENCHMARKING_TARGET_MARKET_GROUP_ADD_TOGGLE',
  BENCHMARKING_LOCAL_PREPARATIONS_TMG_CELL_CHANGE:
    'BENCHMARKING_LOCAL_PREPARATIONS_TMG_CELL_CHANGE',
  BENCHMARKING_LOCAL_PREPARATIONS_TMG_SAVE: 'BENCHMARKING_LOCAL_PREPARATIONS_TMG_SAVE',
  BENCHMARKING_LOCAL_PREPARATIONS_TMG_SAVE_SUCCESS:
    'BENCHMARKING_LOCAL_PREPARATIONS_TMG_SAVE_SUCCESS',
};

export const loadTargetMarketGroup = createAction(
  actionTypes.BENCHMARKING_LOAD_TARGET_MARKET_GROUP
);
export const loadTargetMarketGroupIndustries = createAction(
  actionTypes.BENCHMARKING_LOAD_TARGET_MARKET_GROUP_INDUSTRIES
);
export const updateField = createAction(actionTypes.BENCHMARKING_TARGET_MARKET_GROUP_FIELD_CHANGE);
export const addNewCompany = createAction(actionTypes.BENCHMARKING_TARGET_MARKET_GROUP_ADD_COMPANY);
export const toggleAdd = createAction(actionTypes.BENCHMARKING_TARGET_MARKET_GROUP_ADD_TOGGLE);
export const targetMarketGroupCellChange = createAction(
  actionTypes.BENCHMARKING_LOCAL_PREPARATIONS_TMG_CELL_CHANGE
);
export const toggleSavingTMG = createAction(actionTypes.BENCHMARKING_LOCAL_PREPARATIONS_TMG_SAVE);
export const saveTargetMarketGroupSuccess = createAction(
  actionTypes.BENCHMARKING_LOCAL_PREPARATIONS_TMG_SAVE_SUCCESS
);

export const getTargetMarketGroup = () => (dispatch, getState) => {
  const { benchmarkingId, localId } = R.path(
    ['benchmarking', 'local', 'localPreparation'],
    getState()
  );

  return dispatch(
    loadTargetMarketGroup(
      GET(
        `${
          benchmark.benchmarking
        }/${benchmarkingId}/localPreparations/${localId}/targetMarketGroup`,
        {},
        { showErrorModal: true, dispatch }
      ).then(response => R.path(['data', 'response'], response))
    )
  );
};

export const getTargetMarketGroupIndustries = () => dispatch =>
  dispatch(
    loadTargetMarketGroupIndustries(
      GET(
        `${benchmark.benchmarking}/targetMarketGroupIndustries`,
        {},
        { showErrorModal: true, dispatch }
      ).then(response => R.path(['data', 'response'], response))
    )
  );

export const addCompany = () => (dispatch, getState) => {
  const { benchmarkingId, localId, etag, newCompanyInfo } = R.path(
    ['benchmarking', 'local', 'localPreparation'],
    getState()
  );
  const payload = newCompanyInfo;

  return POST(
    `${benchmark.benchmarking}/${benchmarkingId}/localPreparations/${localId}/targetMarketGroup`,
    payload,
    {
      ETag: etag,
    },
    { showErrorModal: true, dispatch }
  ).then(response => {
    dispatch(
      showBanner({
        type: 'success',
        content: TextOnly({ path: 'benchmarking.TMG_ADD_COMPANY_SUCCESS' }),
      })
    );
    dispatch(etagUpdate(R.path(['headers', 'etag'], response)));
    dispatch(toggleAdd());
    dispatch(addNewCompany());
    dispatch(getTargetMarketGroup());
  });
};

export const deleteCompany = companyName => (dispatch, getState) => {
  const { benchmarkingId, localId, etag } = R.path(
    ['benchmarking', 'local', 'localPreparation'],
    getState()
  );

  return DELETE(
    `${
      benchmark.benchmarking
    }/${benchmarkingId}/localPreparations/${localId}/targetMarketGroup/${companyName}`,
    {
      ETag: etag,
    },
    { showErrorModal: true, dispatch }
  ).then(response => {
    dispatch(
      showBanner({
        type: 'success',
        content: TextOnly({ path: 'benchmarking.DELETE_TMG_COMPANY_SUCCESS' }),
      })
    );
    dispatch(etagUpdate(R.path(['headers', 'etag'], response)));
    dispatch(getTargetMarketGroup());
  });
};

export const saveTargetMarketGroup = () => (dispatch, getState) => {
  const { benchmarkingId, localId, etag, executiveSummary } = R.path(
    ['benchmarking', 'local', 'localPreparation'],
    getState()
  );
  const payload = R.prop('targetMarketGroup', executiveSummary);
  dispatch(toggleSavingTMG());

  return PATCH(
    `${benchmark.benchmarking}/${benchmarkingId}/localPreparations/${localId}/targetMarketGroup`,
    payload,
    {
      ETag: etag,
    },
    { showErrorModal: true, dispatch }
  )
    .then(response => {
      dispatch(
        showBanner({
          type: 'success',
          content: TextOnly({ path: 'benchmarking.SAVE_TMG_SUCCESS' }),
        })
      );
      dispatch(etagUpdate(R.path(['headers', 'etag'], response)));
      dispatch(saveTargetMarketGroupSuccess());
      dispatch(toggleSavingTMG());
    })
    .catch(error => {
      dispatch(toggleSavingTMG());
      return Promise.reject(error);
    });
};
