export default {
  TAB_RULES_BUILDER: 'Rules Logic Builder',
  RULES_BUILDER_LABEL_NAME: 'Rule name',
  RULES_BUILDER_LABEL_TYPE: 'Rule type',
  RULES_BUILDER_LOADING_TEXT: 'Saving',
  RULES_BUILDER_NEW_RULE_LABEL: 'New Rule',
  RULES_BUILDER_EMPTY_FORMULA_ERROR_LABEL: 'Make sure you have at least one value or function',
  RULES_BUILDER_INVALID_FORMULA_ERROR_LABEL: 'Please check the syntax of your formula.',
  RULES_BUILDER_FORMULA_INVALID_ERROR_LABEL: 'Your formula is invalid',
  RULES_BUILDER_RULE_ALREADY_EXISTS: 'Resource already exists',
  RULES_BUILDER_RULE_INVALID_TYPE: 'Invalid type.',
  RULES_BUILDER_ELIGIBILITY_SECTION_TITLE: 'Eligibility rule',
  RULES_BUILDER_FORMULA_SECTION_TITLE: 'Reward for',
  RULES_BUILDER_RULE_TYPE_PLACEHOLDER: 'Select type',
  RULES_BUILDER_ADD_RULE: 'Add rule',
  RULES_BUILDER_DUPLICATE_RULE: 'Duplicate rule',
  RULES_BUILDER_ADD_PLAN: 'Add plan',
  RULES_BUILDER_DUPLICATE_PLAN: 'Duplicate plan',
  RULES_BUILDER_ADD: 'Add',
  RULES_BUILDER_DUPLICATE: 'Duplicate',
  RULES_BUILDER_TEXT_ONLY_REWARD_PLACEHOLDER: 'Please add the reward details',
  RULES_BUILDER_SERVER_ERROR: 'Please check the syntax of your reward',
  RULES_BUILDER_VARIABLE_MISSING: 'Expression is required.',
  RULES_BUILDER_DETAILS_MISSING: 'Reward details are required.',
  RULES_BUILDER_OPERATOR_MISSING: 'Operator is required.',
  RULES_BUILDER_RULE_LABEL: 'Rule',
  RULES_BUILDER_PLAN_LABEL: 'Plan',
  RULES_BUILDER_PACKAGE_LABEL: 'Package',
  RULES_BUILDER_WIDGET_EXCEPTIONS_TITLE: 'Exceptions',
  RULES_BUILDER_WIDGET_OVERALL_IMPACT_FIRST_PARAGRAPH:
    'Press calculate to see the number of employees that will receive this reward and the total cost of the reward.',
  RULES_BUILDER_WIDGET_OVERALL_IMPACT_SECOND_PARAGRAPH:
    'After the calculation, a detailed view will be available.',
  RULES_BUILDER_WIDGET_OVERALL_IMPACT_TITLE: 'Overall impact',
  RULES_BUILDER_WIDGET_OVERALL_IMPACT_CHART_LABEL: 'Employees',
  RULES_BUILDER_WIDGET_OVERALL_IMPACT_CALCULATING: 'Calculating overall impact',
  RULES_BUILDER_WIDGET_OVERALL_IMPACT_DETAIL_LABEL: ' out of ',
  RULES_BUILDER_WIDGET_OVERALL_IMPACT_DETAIL_SECOND_LABEL: ' employees',
  RULES_BUILDER_WIDGET_OVERALL_IMPACT_CALCULATE_BUTTON: 'Calculate',
  RULES_BUILDER_WIDGET_OVERALL_IMPACT_UPDATE_BUTTON: 'Update',
  OVERALL_IMPACT_PAGE_INFO:
    'This screen represents the instance of the reward at the moment the overall impact calculation was requested.',
  OVERALL_IMPACT_CALCULATING_MESSAGE:
    "We are calculating the impact. You will be notified when it's finished.",
  OVERALL_IMPACT_DETAILS_GRADE_LABEL: 'Level',
  OVERALL_IMPACT_DETAILS_EMPLOYEES_LABEL: 'Employees',
  OVERALL_IMPACT_DETAILS_AMOUNT_LABEL: 'Amount',
  RULES_BUILDER_WIDGET_EXCEPTIONS_ADD_BUTTON: 'add exceptions',
  RULES_BUILDER_WIDGET_EXCEPTIONS_MANAGE_BUTTON: 'manage exceptions',
  RULES_BUILDER_WIDGET_EXCEPTIONS_SIDE_NOTE: `
      To upload multiple exceptions in the "Exceptions" page,
      please copy and paste the reward ID in the template.
    `,
  RULES_BUILDER_WIDGET_EXCEPTIONS_COPY_REWARD: 'Copy reward ID',
  RULES_BUILDER_WIDGET_EXCEPTIONS_COPY_REWARD_SUCCESS:
    'The reward ID was succesfully copied to clipboard.',
  RULES_BUILDER_REVERT_TO_DRAFT_BUTTON: 'Revert to draft',
  RULES_BUILDER_REVERT_TO_DRAFT_POPUP_TITLE: 'Revert %CURRENT_STATUS% reward to draft?',
  RULES_BUILDER_REVERT_TO_DRAFT_LOSE_CHANGES:
    'You will lose any unsaved changes when reverting to draft.',
  RULES_BUILDER_REVERT_TO_DRAFT_SUCCESS:
    'Reward successfully moved to draft. Please wait until the operation completes.',
  RULES_BUILDER_WIDGET_OVERALL_IMPACT_DETAILS_LINK: 'Detailed view',
  RULES_BUILDER_WIDGET_OVERALL_IMPACT_DOWNLOAD_TITLE: 'Download detailed report',
  RULES_BUILDER_WIDGET_OVERALL_IMPACT_DOWNLOAD_SUMMARY:
    'The report will be exported in csv format and will contain the details of all\n' +
    '              employees.',
  RULES_BUILDER_WIDGET_OVERALL_IMPACT_GRADE_EMPTY: 'Empty',
  RULES_BUILDER_WIDGET_OVERALL_IMPACT_LABEL_EMPLOYEES: 'Employees',
  RULES_BUILDER_GLOBAL_PREFIX: 'Global',
  RULES_BUILDER_LOCAL_PREFIX: 'Local',
  RULES_BUILDER_WIDGET_OVERALL_IMPACT_COUNTING: 'Counting',
};
