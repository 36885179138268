export default {
  DIALOG_PUBLISH_TITLE: 'Publish reward?',
  DIALOG_PUBLISH_SUBTITLE:
    'You are about to publish this reward to the system. The reward ' +
    'will be calculated on its effective date (%Effective Date%) and then will ' +
    'automatically become LIVE for the affected employees.',
  DIALOG_PUBLISH_EXPIRED_SUBTITLE:
    'You are about to publish this reward to the system with the expired status.',
  DIALOG_LIVE_EDIT_TITLE: 'Edit live reward?',
  DIALOG_LIVE_EDIT_SUBTITLE:
    'These changes will have an immediate effect on the statements of the employees ' +
    'that receive this reward.',
  DIALOG_LIVE_TO_PUBLISH_SUBTITLE:
    'Editing this live reward will change its status to published and will affect its ' +
    'visibility on the Employee Statement',
  DIALOG_TO_EXPIRED_TITLE: 'Expired reward',
  DIALOG_TO_EXPIRED_SUBTITLE: "The changes you've made will set this reward status to expired.",
  DIALOG_LIVE_REWARD: 'Live Reward',
  DIALOG_SAVE_TO_LIVE:
    'These changes will have an immediate effect on the statements of the employees that receive this reward.',
};
