"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var R = _interopRequireWildcard(require("ramda"));
var _color = _interopRequireDefault(require("color"));
var _base = _interopRequireDefault(require("./base"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
var baseTheme = R.clone(_base["default"]);
var primary = '#FF5F00';
var secondary = '#F79E1B';
var accent = {
  alpha: (0, _color["default"])(secondary).lighten(0.2).string(),
  charlie: (0, _color["default"])(secondary).lighten(0.4).string(),
  delta: (0, _color["default"])(secondary).lighten(0.8).string(),
  foxtrot: (0, _color["default"])(secondary).lighten(0.5).string()
};

// lighten 02 04 05 08
var components = {
  card: {
    bottomBorder: {
      color: primary,
      weight: '10px'
    }
  },
  header: {
    background: '#64625e',
    text: baseTheme.palette.basic.white,
    accentColor: baseTheme.palette.basic.white,
    disabled: baseTheme.palette.accent.sigma
  },
  footer: {
    routes: {
      contact: 'https://www.mastercard.co.uk/en-gb.html',
      support: 'https://www.mastercard.co.uk/en-gb.html',
      privacyPolicy: 'https://www.mastercard.co.uk/en-gb.html',
      terms: 'https://www.mastercard.co.uk/en-gb.html',
      copyright: 'https://www.mastercard.co.uk/en-gb.html',
      facebook: 'https://www.mastercard.co.uk/en-gb.html',
      youtube: 'https://www.mastercard.co.uk/en-gb.html',
      twitter: 'https://twitter.com/uflexreward?lang=en',
      linkedIn: 'https://www.linkedin.com/company/uflexreward/'
    }
  },
  logo: {
    size: '70px'
  }
};
var extra = {
  rewardsCategories: {
    "default": secondary,
    extra: '#050950',
    categories: ['#00a3ad', '#f8a641', '#953bb9', '#bf0d3e', '#98cc02', '#6AE1C4', '#4A4A4A', '#E27370', '#EAEBFE', '#5F618F', '#B580E2', '#F5A623', '#4A11DD', '#AC9E0D', '#DC718A', '#6AE1C4', '#4A4A4A', '#E27370', '#EAEBFE']
  },
  sharesColorMap: {
    vested: primary,
    unvested: secondary
  }
};
baseTheme.palette.primary = primary;
baseTheme.palette.secondary = secondary;
baseTheme.palette.accent = accent;
baseTheme.logoName = 'masterCardLogo';
baseTheme.components = _objectSpread(_objectSpread({}, baseTheme.components), components);
baseTheme.extra = _objectSpread(_objectSpread({}, baseTheme.extra), extra);
baseTheme.name = 'MasterCard Theme';
baseTheme.displayName = 'MasterCard Theme';
baseTheme.identifier = 'masterCard';
var _default = baseTheme;
exports["default"] = _default;