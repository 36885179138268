const types = {
  ARTICLES_FORM_INPUT_CHANGE: 'ARTICLES_FORM_INPUT_CHANGE',
  ARTICLES_FORM_SAVE: 'ARTICLES_FORM_SAVE',
  ARTICLES_SYSTEM_VARIABLES: 'ARTICLES_SYSTEM_VARIABLES',
  ARTICLES_SYSTEM_VARIABLES_LOOKUP: 'ARTICLES_SYSTEM_VARIABLES_LOOKUP',
  ARTICLES_ELIGIBILITY_OPERATORS: 'ARTICLES_ELIGIBILITY_OPERATORS',
  ARTICLES_ELIGIBILITY_CHANGE: 'ARTICLES_ELIGIBILITY_CHANGE',
  ARTICLES_ELIGIBILITY_ERROR: 'ARTICLES_ELIGIBILITY_ERROR',
  ARTICLES_GET_CATEGORIES: 'ARTICLES_GET_CATEGORIES',
  ARTICLES_GET_COUNTRIES: 'ARTICLES_GET_COUNTRIES',
  ARTICLES_GET_LIST: 'ARTICLES_GET_LIST',
  ARTICLES_REDIRECT_TO_ROOT: 'ARTICLES_REDIRECT_TO_ROOT',
  ARTICLES_ETAG_UPDATE: 'ARTICLES_ETAG_UPDATE',
  ARTICLES_VIDEO_PLATFORMS_ACCEPTED: 'ARTICLES_VIDEO_PLATFORMS_ACCEPTED',
  LOAD_ELIGIBILITY_SUCCESS: 'LOAD_ELIGIBILITY_SUCCESS',
  LOAD_ELIGIBILITY_PENDING: 'LOAD_ELIGIBILITY_PENDING',
  LOAD_ELIGIBILITY_ERROR: 'LOAD_ELIGIBILITY_ERROR',
  UPDATE_ELIGIBILITY_DATA: 'UPDATE_ELIGIBILITY_DATA',
  ARTICLES_FORM_RESET: 'ARTICLES_FORM_RESET',
  RESET_STORE: 'RESET_STORE',
};

export default types;
