"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
var _utils = require("@trs/utils");
var _templateObject, _templateObject2, _templateObject3;
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
var StyledDoughnut = _styledComponents["default"].div.withConfig({
  displayName: "Doughnutstyle__StyledDoughnut",
  componentId: "sc-1yym13y-0"
})(["", ";"], function (props) {
  var spacing = props.theme.spacing;
  var legend = props.legend,
    minWidth = props.minWidth;
  return legend && (0, _styledComponents.css)(["", " .doughnut-chart{display:none;margin-bottom:", ";.recharts-pie-sector{cursor:pointer;text{transform:translateY(-", ");}}", " ", ";}"], _utils.media.tablet(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n        display: flex;\n        &:after {\n          clear: both;\n          content: ' ';\n          display: block;\n          visibility: hidden;\n        }\n      "]))), spacing.large, spacing.tiny, _utils.media.tablet(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n        align-self: center;\n        display: block;\n        margin: 0;\n        max-width: 220px;\n        ", "\n      "])), minWidth && (0, _styledComponents.css)(["min-width:", "px;"], minWidth)), _utils.media.desktop(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n        margin: 0 0 0 24px;\n      "]))));
});
StyledDoughnut.displayName = 'StyledDoughnut';
var _default = StyledDoughnut;
exports["default"] = _default;