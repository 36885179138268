export default {
  CUSTOMISATION_TITLE: 'Customisation',
  CUSTOMISATION_CARD_SUBTITLE: 'Please provide customisation details',
  CUSTOMISATION_CAPTION:
    'Customise the overall look and feel of the application and elements to be displayed.',
  CONGRATULATIONS_DIALOG_TITLE: 'Congratulations!',
  CONGRATULATIONS_DIALOG_CAPTION:
    'You are all setup. You can start using the application or you can customise first the look and feel of the application.',
  CUSTOMISATION_SUBTITLE: 'My Statement Page',
  CUSTOMISATION_SUBTITLE_EXPLANATION: 'Customise elements to be displayed on My Statement page.',
  CUSTOMISATION_EMPLOYEE_PROFILE: 'Employee Profile',
  CUSTOMISATION_EMPLOYEE_PROFILE_EXPLANATION:
    'Employee Profile settings will be used to display information about users Personal Details on My Statement Page.',
  ADD_EMPLOYEE_DETAIL: 'Add Employee Detail',
  EMPLOYEE_DETAIL_TITLE: 'Employee Detail %INDEX%',
  CUSTOMISATION_EMPLOYEE_ID: 'Employee ID',
  CUSTOMISATION_UPDATED: 'Employee profile has been updated.',
  CUSTOMISATION_FIELD_TITLE: 'Field',
  CUSTOMISATION_LABEL_TITLE: 'Label',
  CUSTOMISATION_FIELD_LENGTH_ERROR: 'This field should have a maximum of %MAX_LENGTH% characters.',
  CUSTOMISATION_LABEL_UNIQUE_ERROR: 'Label must be unique.',
  CUSTOMISATION_FIELD_UNIQUE_ERROR: 'Selected fields must be unique.',
  CUSTOMISATION_SHARES_DATA: 'Shares Data',
  CUSTOMISATION_SHARES_DATA_EXPLANATION:
    'Shares data settings will be used to display information about current values of vested/unvested shares on My Statement Page.',
  VESTED_SHARES_LABEL: 'Display value of Vested Shares',
  UNVESTED_SHARES_LABEL: 'Display value of Unvested Shares',
  SHARES_UPDATE: 'Shares Data has been updated',
};
