import * as R from 'ramda';
import APP_CONFIG from '@trs/config';

import * as actions from './types';
import userManager from '../../../../../config/userManager/userManager';
import { organization, rewards, audit } from '../../../../../config/api';
import { POST, GET, CancelToken } from '../../../../../config/http';
import { generateMenu } from '../../../../../actions/userActions';
import { withCache } from '../../../../common/timedCache';

let cancelAuditSearchCall;
let cancelMimicSearchCall;
const { GLOBAL_KEYWORD, EXPIRY_TIMES } = APP_CONFIG;
const cachedGet = withCache(GET, EXPIRY_TIMES.HH);
const fetchExceptionsOverview = country =>
  GET(
    `${rewards.rewardsExceptions}/${country}`,
    { Pragma: 'no-cache' },
    { redirectToUnauthorized: false }
  );

const fetchRewardsOverview = country =>
  GET(
    `${rewards.rewardsOverview}/${country}`,
    { Pragma: 'no-cache' },
    { redirectToUnauthorized: false }
  );

export const selectCountry = country => ({
  type: actions.SELECT_COUNTRY_BEGIN,
  country,
});

export const getEmployees = (searchText, sorting = null, country = null) => () => {
  const payload = {
    search: {
      searchText,
    },
  };

  if (sorting) {
    payload.sort = {
      sortAsc: sorting.asc,
      sortBy: sorting.by,
    };
  }

  if (country && country !== GLOBAL_KEYWORD) {
    payload.filters = [
      {
        fieldName: 'countryCode',
        values: [country],
      },
    ];
  }

  if (cancelMimicSearchCall) cancelMimicSearchCall.cancel('Formula Operand call cancelled.');
  cancelMimicSearchCall = CancelToken.source();

  return POST(organization.employeeSearch, payload, {
    Pragma: 'no-cache',
    cancelToken: cancelMimicSearchCall.token,
  });
};

export const getAuditEmployees = (searchText, country = null) => () => {
  const payload = {
    search: {
      searchText,
    },
  };

  if (country && country !== GLOBAL_KEYWORD) {
    payload.filters = [
      {
        fieldName: 'countryCode',
        values: [country],
      },
    ];
  }

  if (cancelAuditSearchCall) cancelAuditSearchCall.cancel('Formula Operand call cancelled.');
  cancelAuditSearchCall = CancelToken.source();

  return POST(audit.userSearch, payload, {
    Pragma: 'no-cache',
    cancelToken: cancelAuditSearchCall.token,
  });
};

export function getExceptionsInfo(country) {
  return dispatch => {
    dispatch({
      type: actions.WIDGET_EXCEPTIONS_START,
    });

    if (country) {
      fetchExceptionsOverview(country).then(data => {
        dispatch({
          type: actions.WIDGET_EXCEPTIONS_FINISH,
          exceptions: data,
        });
      });
    }
  };
}

export function getRewardsOverviewInfo(country) {
  return dispatch => {
    dispatch({
      type: actions.WIDGET_REWARDS_OVERVIEW_START,
    });

    if (country) {
      fetchRewardsOverview(country).then(data => {
        dispatch({
          type: actions.WIDGET_REWARDS_OVERVIEW_FINISH,
          rewardsOverview: data,
        });
      });
    }
  };
}

export function mimicEmployee(employeeId) {
  return (dispatch, getState) => {
    const {
      onboarding: {
        dashboard: { isSetupCompleted },
      },
    } = getState();
    dispatch({
      type: actions.MIMIC_EMPLOYEE_GET_INFO,
    });

    GET(`${organization.employeeProfileById}/${employeeId}`).then(response => {
      userManager.setMimicProfile(response.data);
      dispatch({
        type: actions.MIMIC_EMPLOYEE_BEGIN,
        employee: response.data,
      });
      dispatch(generateMenu(isSetupCompleted));
    });
  };
}

export function mimicEmployeeExit() {
  return (dispatch, getState) => {
    userManager.clearMimicProfile();
    const profile = userManager.getProfileData();
    const {
      onboarding: {
        dashboard: { isSetupCompleted },
      },
    } = getState();
    dispatch({
      type: actions.MIMIC_EMPLOYEE_EXIT,
      profile,
    });
    dispatch(generateMenu(isSetupCompleted));
  };
}

export function resetMimicViewRedirect() {
  return {
    type: actions.MIMIC_EMPLOYEE_RESET_REDIRECT,
  };
}

export function getAuthorizedCountries() {
  return (dispatch, getState) => {
    const {
      user: { authorizedModules },
    } = getState();

    cachedGet(R.path(['lookupCountries'], organization)).then(response => {
      dispatch({
        type: actions.GET_DASHBOARD_COUNTRIES,
        countries: response.data,
        authorizedModules,
      });
    });
  };
}

export const dashboardReset = () => {
  return {
    type: actions.DASHBOARD_RESET,
  };
};
