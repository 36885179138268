export default {
  MOBILITY_TITLE: 'Mobility',
  MOBILITY_SUBTITLE: 'Please provide mobility information',
  MOBILITY_CAPTION:
    'Mobility features allow you to create rewards and estimate relocation costs for your employees. This information is optional and can be setup later.',
  MOBILITY_BODY:
    'Mobility features allow you to create rewards and estimate relocation costs for your employees. We are using AirINC to import employee and location data. This information is optional and can be setup later.',
  MOBILITY_AIRINC_URL: 'AirINC Url',
  MOBILITY_AIRINC_URL_PLACEHOLDER: 'AirINC Url',
  MOBILITY_AIRINC_ACCOUNT: 'AirINC Account',
  MOBILITY_AIRINC_ACCOUNT_PLACEHOLDER: 'AirINC Account',
  MOBILITY_COMPANY_USER: 'Company Username',
  MOBILITY_COMPANY_USER_PLACEHOLDER: 'Company Username',
  MOBILITY_PASSWORD: 'Password',
  MOBILITY_PASSWORD_PLACEHOLDER: 'Password',
  UPLOAD_FORM_BUTTON_TEXT: 'Browse',
  MOBILITY_CARD_TITLE: 'Mobility Package',
  DOWNLOAD_TEMPLATE: 'Download template',
  MOBILITY_LOCATION_INFORMATION: 'Location information',
};
