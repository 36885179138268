import { createAction } from 'redux-actions';
import * as R from 'ramda';
import { isBlank, ceil, getUTCOffset } from '@trs/utils';
import { GET, POST, addGetParameters } from '../../../../../config/http';
import { audit } from '../../../../../config/api';
import { showBanner } from '../../../../../actions/globalActions';
import { TextOnly } from '../../../../common/Text';
import { getArgs, generateLineFilePayload, generateLinesFilePayload } from './helpers';
import types from './types';

export const getAuditData = createAction(types.GET_AUDIT_DATA);
export const setAuditFilter = createAction(types.SET_AUDIT_FILTER);
export const changeTablePage = createAction(types.CHANGE_TABLE_PAGE);
export const filterAuditData = createAction(types.FILTER_AUDIT_DATA);
export const generateLineDataFile = createAction(types.GENERATE_LINE_DATA_FILE);
export const generateLinesDataFile = createAction(types.GENERATE_LINES_DATA_FILE);
export const resetStore = createAction(types.RESET_STORE);

const dispatchFileGenerationPending = (dispatch) =>
  dispatch(
    showBanner({
      type: 'success',
      content: TextOnly({ path: 'rmDashboard.SYSTEM_AUDIT_FILE_GENERATION_PENDING' }),
    })
  );

const dispatchFileGenerationInProgress = (dispatch) =>
  dispatch(
    showBanner({
      type: 'success',
      content: TextOnly({ path: 'rmDashboard.SYSTEM_AUDIT_FILE_GENERATION_IN_PROGRESS' }),
    })
  );

const getDataRequest = (params, dispatch) =>
  GET(
    addGetParameters(audit.getAudit, getArgs(params)),
    { Pragma: 'no-cache' },
    { showErrorModal: true, dispatch }
  )
    .then(({ data: { response } }) => {
      const { rows, rowsCount } = response;
      if (rowsCount > 0 && isBlank(rows)) {
        dispatch(
          showBanner({
            type: 'warning',
            content: TextOnly({
              path: 'generic.PAGINATION_EXCEEDED',
              transform: (label) => label.replace('%NR%', ceil(rowsCount / R.prop('top', params))),
              withHtml: true,
            }),
          })
        );
      }
      return { response, params };
    })
    .catch((error) => {
      return Promise.reject(error);
    });

export const loadAuditData = (query) => (dispatch, getState) => {
  const { filter } = R.path(['rmDashboard', 'audit'], getState());
  return dispatch(getAuditData(getDataRequest({ ...filter, ...query }, dispatch)));
};

export const filterAudit = (tableFilter) => (dispatch, getState) => {
  const { filter } = R.path(['rmDashboard', 'audit'], getState());
  const page = 1;
  dispatch(setAuditFilter({ tableFilter, page }));
  return dispatch(filterAuditData(getDataRequest({ ...filter, ...tableFilter, page }, dispatch)));
};

export const onTablePageChange = (tableFilter) => (dispatch, getState) => {
  const { filter } = R.path(['rmDashboard', 'audit'], getState());
  dispatch(changeTablePage({ page: R.prop('page', tableFilter) }));
  return dispatch(filterAuditData(getDataRequest({ ...filter, ...tableFilter }, dispatch)));
};

export const generateLineFile = (id) => (dispatch, getState) => {
  const { name, email, filter } = R.path(['rmDashboard', 'audit'], getState());
  const payload = generateLineFilePayload({ id, name, email, filter });
  dispatchFileGenerationPending(dispatch);
  dispatch(
    generateLineDataFile(
      POST(
        audit.generateLineFile,
        payload,
        { Pragma: 'no-cache', UTCOffset: getUTCOffset(new Date()) },
        { showErrorModal: true, dispatch }
      ).then(() => dispatchFileGenerationInProgress(dispatch))
    )
  );
};

export const generateLinesFile = () => (dispatch, getState) => {
  const { filter } = R.path(['rmDashboard', 'audit'], getState());
  const payload = generateLinesFilePayload(filter);
  dispatchFileGenerationPending(dispatch);
  dispatch(
    generateLinesDataFile(
      POST(
        audit.generateLinesFile,
        payload,
        { Pragma: 'no-cache', UTCOffset: getUTCOffset(new Date()) },
        { showErrorModal: true, dispatch }
      ).then(() => dispatchFileGenerationInProgress(dispatch))
    )
  );
};
