import * as R from 'ramda';
import APP_CONFIG from '@trs/config';

const { GLOBAL_KEYWORD } = APP_CONFIG;

export const transformCountriesToOptions = countries =>
  countries.map(country => ({
    label: `${country.name} (${country.code})`,
    value: country.name,
    code: country.code,
    defaultCurrency: country.defaultCurrency,
  }));

export const transformCurrenciesToOptions = currencies =>
  currencies.map(currency => ({
    label: currency.code,
    value: currency.code,
  }));

export const transformSourcesToOptions = sources =>
  sources.map(source => ({
    label: source.name,
    value: source.id,
  }));

export const transformCategoriesToOptions = categories =>
  categories.reduce(
    (acc, source) => ({
      ...acc,
      [source.id]: {
        label: source.displayName,
        orderIndex: source.orderIndex,
      },
    }),
    {}
  );

const getLocationAndCode = ({ code, locationName }) => ({
  label: locationName,
  value: code,
});

export const transformLocationsToOptions = locations => R.map(R.map(getLocationAndCode), locations);

export const mapValuesToKeys = values => ({
  variables: R.path([0, 'data'], values),
  countries: R.filter(
    country => country.code !== GLOBAL_KEYWORD,
    transformCountriesToOptions(R.path([1, 'data'], values))
  ),
  currencies: transformCurrenciesToOptions(R.path([2, 'data'], values)),
  sources: transformSourcesToOptions(R.path([3, 'data'], values)),
  categories: transformCategoriesToOptions(R.path([4, 'data'], values)),
  locations: transformLocationsToOptions(R.path([5, 'data', 'response'], values)),
});
