export default {
  PAGE_TITLE: 'Variables',
  PAGE_SUBTITLE: `This section contains the user-defined variables
     used when creating rewards. Please note that you will not be able to delete a 
     variable if it is used in rewards. You can see the number of rewards which use that variable in the “Usage” column.`,
  NEW_BUTTON: 'New Variable',
  NAME: 'name',
  COUNTRY: 'Country',
  USAGE: 'usage',
  VALUE: 'value',
  MENU_LIST_EDIT: 'Edit variable',
  MENU_LIST_DUPLICATE: 'Duplicate variable',
  MENU_LIST_DELETE: 'Delete variable',
  NO_VARIABLES: "There aren't any Variables in the system. You can start by adding a variable.",
  FILTER_TITLE: 'Filtering options',
  FILTER_NO_RESULTS:
    'Your search did not match any variable. Please try different search criteria.',
  FILTER_NAME_LABEL: 'Variable name',
  FILTER_NAME_PLACEHOLDER: 'Search by name',
  PAGE_GROUP: 'Variable',
};
