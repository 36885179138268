import * as R from 'ramda';
import { createAction } from 'redux-actions';
import { isBlank, upperFirstLetter } from '@trs/utils';
import { REWARD_STATUSES } from '@trs/config';
import { POST, GET, PATCH } from '../../../../../../../../../config/http';
import { benchmark, rewards } from '../../../../../../../../../config/api';
import { showBanner } from '../../../../../../../../../actions/globalActions';
import { loadLocalBenchmarkingStatuses } from '../../../../../actions';
import { getLocalSettingsId } from '../../../../../actions/helpers';
import { TextOnly } from '../../../../../../../../common/Text';
import { etagUpdate, mapRewardsToSearch } from '../../../actions/helpers';
import { dispatchError } from '../../../../../../common/helpers';

const actionTypes = {
  BENCHMARKING_REWARDS_PENDING: 'BENCHMARKING_REWARDS_PENDING',
  BENCHMARKING_REWARDS_SUCCESS: 'BENCHMARKING_REWARDS_SUCCESS',
  BENCHMARKING_REWARDS_ERROR: 'BENCHMARKING_REWARDS_ERROR',
  BENCHMARKING_REWARDS_DELETE_SUCCESS: 'BENCHMARKING_REWARDS_DELETE_SUCCESS',
};

export const getExcludedRewardsPending = createAction(actionTypes.BENCHMARKING_REWARDS_PENDING);
export const getExcludedRewardsSuccess = createAction(actionTypes.BENCHMARKING_REWARDS_SUCCESS);
export const getExcludedRewardsError = createAction(actionTypes.BENCHMARKING_REWARDS_ERROR);
export const deleteExcludedRewardSuccess = createAction(
  actionTypes.BENCHMARKING_REWARDS_DELETE_SUCCESS
);

export const getExcludedRewards = rewardIds => dispatch => {
  dispatch(getExcludedRewardsPending());

  return GET(`${rewards.rewardsRoot}?id=${R.join('&id=', rewardIds)}`)
    .then(response => {
      dispatch(getExcludedRewardsSuccess(R.path(['data', 'response'], response)));
    })
    .catch(error => {
      dispatch(getExcludedRewardsError());
      dispatchError(error, dispatch);
    });
};

export const deleteExcludedReward = id => (dispatch, getState) => {
  const { benchmarkingId, localId, rewardIds, etag } = R.path(
    ['benchmarking', 'local', 'localPreparation'],
    getState()
  );
  const localSettingsId = getLocalSettingsId(getState());

  const payload = {
    rewardIds: R.without(id, rewardIds),
  };

  return PATCH(
    `${benchmark.benchmarking}/${benchmarkingId}/localPreparations/${localId}`,
    payload,
    { ETag: etag }
  )
    .then(response => {
      const responseBody = R.path(['data', 'response'], response);

      dispatch(etagUpdate(R.prop('etag', responseBody)));
      dispatch(loadLocalBenchmarkingStatuses(localSettingsId));
      dispatch(deleteExcludedRewardSuccess(R.prop('rewardIds', responseBody)));
      const newRewardIds = R.prop('rewardIds', responseBody);
      if (!isBlank(newRewardIds)) {
        dispatch(getExcludedRewards(newRewardIds));
      }
      dispatch(
        showBanner({
          type: 'success',
          content: TextOnly({ path: 'benchmarking.REWARD_DELETED_SUCCESS' }),
        })
      );
    })
    .catch(error => {
      dispatchError(error, dispatch);
    });
};

export const searchRewards = searchText => (dispatch, getState) => {
  const { country } = R.path(['benchmarking', 'local', 'localPreparation'], getState());
  const payload = {
    sort: {
      sortAsc: true,
      sortBy: 'name',
    },
    paging: {
      top: 20,
      skip: 0,
    },
    search: {
      searchText,
    },
    filters: [
      {
        fieldName: 'statusName',
        values: [
          upperFirstLetter(REWARD_STATUSES.live),
          upperFirstLetter(REWARD_STATUSES.published),
        ],
      },
      {
        fieldName: 'countryCode',
        values: [R.toUpper(country), 'Global'],
      },
    ],
    searchFields: ['name'],
  };

  return POST(`${rewards.rewardsRoot}/search`, payload)
    .then(response => {
      return mapRewardsToSearch(response.data.rewards);
    })
    .catch(error => {
      dispatchError(error, dispatch);
    });
};

export const addExcludedReward = excludedReward => (dispatch, getState) => {
  const rewardId = R.prop('id', excludedReward);
  const { rewardIds, benchmarkingId, localId, etag } = R.path(
    ['benchmarking', 'local', 'localPreparation'],
    getState()
  );
  const localSettingsId = getLocalSettingsId(getState());

  rewardIds.push(rewardId);
  const payload = {
    rewardIds: R.uniq(rewardIds),
  };

  return PATCH(
    `${benchmark.benchmarking}/${benchmarkingId}/localPreparations/${localId}`,
    payload,
    { ETag: etag }
  )
    .then(response => {
      const responseBody = R.path(['data', 'response'], response);

      dispatch(etagUpdate(R.prop('etag', responseBody)));
      dispatch(loadLocalBenchmarkingStatuses(localSettingsId));
      dispatch(deleteExcludedRewardSuccess(R.prop('rewardIds', responseBody)));
      const newRewardIds = R.prop('rewardIds', responseBody);
      dispatch(getExcludedRewards(newRewardIds));
      dispatch(
        showBanner({
          type: 'success',
          content: TextOnly({ path: 'benchmarking.REWARD_ADDED_SUCCESS' }),
        })
      );
    })
    .catch(error => {
      dispatchError(error, dispatch);
    });
};
