import * as R from 'ramda';
import { createAction } from 'redux-actions';
import { GET, POST, DELETE, RequestFactory } from '../../../config/http';
import { workspace } from '../../../config/api';
import { showBanner } from '../../../actions/globalActions';
import { processFile } from '../../common/DownloadLink/helpers';
import { TextOnly } from '../../common/Text';

const actionTypes = {
  GET_FOLDER_LISTING: 'GET_FOLDER_LISTING',
  SORT_LIST: 'SORT_LIST',
  GET_PROCESS_LISTING: 'GET_PROCESS_LISTING',
  RUN_PROCESS: 'RUN_PROCESS',
  FILE_CHECK_ON_SERVER: 'FILE_CHECK_ON_SERVER',
  FILE_CHECK_RESET: 'FILE_CHECK_RESET',
  RESET_STORE: 'RESET_STORE',
};

const DOWNLOAD_START_AUTOHIDE = 5000;

function dispatchError(error, dispatch) {
  const errorMessage =
    R.path(['response', 'data', 0, 'message'], error) || R.prop('message', error);

  dispatch(
    showBanner({
      type: 'error',
      content: errorMessage,
    })
  );
}

export const getFolderData = createAction(actionTypes.GET_FOLDER_LISTING);
export const sortList = createAction(actionTypes.SORT_LIST);
export const getProcessData = createAction(actionTypes.GET_PROCESS_LISTING);
export const startProcess = createAction(actionTypes.RUN_PROCESS);
export const fileCheckOnServer = createAction(actionTypes.FILE_CHECK_ON_SERVER);
export const fileCheckReset = createAction(actionTypes.FILE_CHECK_RESET);
export const resetStore = createAction(actionTypes.RESET_STORE);

export const resetFileCheck = () => dispatch => dispatch(fileCheckReset());

export const getFolderListing = folderId => dispatch =>
  dispatch(
    getFolderData(
      GET(
        `${workspace.base}/workspace/${folderId}`,
        { Pragma: 'no-cache' },
        { showErrorModal: true, dispatch }
      ).then(response => R.path(['data', 'response'], response))
    )
  );

export const sortBy = by => dispatch => dispatch(sortList(by));

export const downloadFile = (folderId, fileName) => dispatch => {
  showBanner({
    type: 'success',
    autoHideTime: DOWNLOAD_START_AUTOHIDE,
    content: TextOnly({
      path: 'generic.DOWNLOAD_IN_PROGRESS',
      transform: label => label.replace('%FILENAME%', fileName),
    }),
  });

  return RequestFactory(
    'GET',
    `${workspace.base}/workspace/${folderId}/${fileName}`,
    {},
    { showErrorModal: true, dispatch },
    'blob'
  ).then(response => {
    dispatch(
      showBanner({
        type: 'success',
        content: TextOnly({
          path: 'generic.DOWNLOAD_FINISHED',
          transform: label => label.replace('%FILENAME%', fileName),
        }),
      })
    );
    return processFile(response, fileName);
  });
};

export const uploadFile = (folderId, payload) => dispatch =>
  POST(`${workspace.base}/workspace/${folderId}`, payload, {}, { showErrorModal: false, dispatch })
    .then(() => {
      dispatch(
        showBanner({
          type: 'success',
          content: TextOnly({ path: 'workspace.FILE_UPLOAD_SUCCESS' }),
        })
      );
      dispatch(getFolderListing(folderId));
      dispatch(resetFileCheck());
    })
    .catch(err => {
      const errors = R.path(['response', 'data', 'errors'], err);

      showBanner({
        type: 'error',
        content:
          R.path(['message'], errors[0]) ||
          TextOnly({
            path: 'generic.FILE_INVALID_CHARACTERS_ERROR',
          }),
      });
    });

export const deleteFile = (folderId, fileName) => dispatch =>
  DELETE(
    `${workspace.base}/workspace/${folderId}/${fileName}`,
    {},
    { showErrorModal: true, dispatch }
  ).then(() => {
    const workspaceFileLabel = TextOnly({ path: 'workspace.WORKSPACE_FILE_LABEL' });
    dispatch(
      showBanner({
        type: 'success',
        content: TextOnly({
          path: 'generic.BANNER_DELETE',
          transform: label => label.replace('%FIELD%', workspaceFileLabel),
        }),
      })
    );
    dispatch(getFolderListing(folderId));
  });

export const getProcessListing = folderId => dispatch =>
  dispatch(
    getProcessData(
      GET(
        `${workspace.base}/processes/${folderId}`,
        { Pragma: 'no-cache' },
        { showErrorModal: true, dispatch }
      ).then(response => R.path(['data', 'response'], response))
    )
  );

export const runProcess = processHash => dispatch => {
  dispatch(
    startProcess(
      POST(`${workspace.base}/processes/${processHash}/start`)
        .then(() => {
          dispatch(
            showBanner({
              type: 'success',
              content: TextOnly({ path: 'workspace.PROCESS_HAS_STARTED' }),
            })
          );
          return processHash;
        })
        .catch(e => {
          dispatchError(e, dispatch);
          return Promise.reject(e);
        })
    )
  );
};

export const checkFileOnServer = (folderId, fileName) => dispatch =>
  dispatch(
    fileCheckOnServer(
      GET(
        `${workspace.base}/workspace/${folderId}/validate/${fileName}`,
        { Pragma: 'no-cache' },
        { showErrorModal: true, dispatch }
      ).then(response => R.path(['data', 'response'], response))
    )
  );
