import * as R from 'ramda';
import {
  uploadFilePending,
  uploadFileSuccess,
  uploadFileError,
  ageDataPending,
  ageDataSuccess,
  ageDataError,
  updateSurveyReferenceDate,
  toggleManualLTI,
  toggleManualBenefits,
  toggleFixedAllowances,
  updateMarketData,
  saveMarketDataSuccess,
  uploadLastYearMarketDataSuccess,
  getLastYearInputData,
} from '../actions';

export default [
  [
    uploadFilePending,
    state => {
      return {
        ...state,
        isUploadInProgress: true,
        isLastYearUploadInProgress: true,
      };
    },
  ],
  [
    uploadFileError,
    state => {
      return {
        ...state,
        isUploadInProgress: false,
        isLastYearUploadInProgress: false,
      };
    },
  ],
  [
    uploadFileSuccess,
    (state, action) => {
      const newMarketData = R.assocPath(['inputData'], action.payload, state.marketData);
      return {
        ...state,
        isUploadInProgress: false,
        isLastYearUploadInProgress: false,
        marketData: newMarketData,
        marketDataSnapshop: newMarketData,
      };
    },
  ],
  [
    ageDataPending,
    state => ({
      ...state,
      isAgingData: true,
    }),
  ],
  [
    ageDataSuccess,
    (state, action) => {
      const newMarketData = R.assocPath(['agedData'], action.payload, state.marketData);
      return {
        ...state,
        isAgingData: false,
        marketData: newMarketData,
      };
    },
  ],
  [
    ageDataError,
    state => ({
      ...state,
      isAgingData: false,
    }),
  ],
  [
    updateSurveyReferenceDate,
    (state, action) => {
      const newMarketData = R.assocPath(['surveyReferenceDate'], action.payload, state.marketData);
      return {
        ...state,
        marketData: newMarketData,
      };
    },
  ],
  [
    toggleManualLTI,
    state => {
      const manualLti = !R.path(['marketData', 'manualLti'], state);
      return {
        ...state,
        marketData: R.assocPath(['manualLti'], manualLti, state.marketData),
      };
    },
  ],
  [
    toggleManualBenefits,
    state => {
      const manualBenefits = !R.path(['marketData', 'manualBenefits'], state);
      return {
        ...state,
        marketData: R.assocPath(['manualBenefits'], manualBenefits, state.marketData),
      };
    },
  ],
  [
    toggleFixedAllowances,
    state => {
      const includeFixedAllowances = !state.marketData.includeFixedAllowances;
      return {
        ...state,
        marketData: R.assocPath(
          ['includeFixedAllowances'],
          includeFixedAllowances,
          state.marketData
        ),
      };
    },
  ],
  [
    updateMarketData,
    (state, action) => {
      const { rowIndex, marketValue, field, value } = action.payload;
      const { marketData } = state;
      const intValue = parseInt(value, 10);
      const newValue = !Number.isNaN(intValue) ? intValue : null;

      return {
        ...state,
        marketData: R.assocPath(['inputData', rowIndex, marketValue, field], newValue, marketData),
      };
    },
  ],
  [
    uploadLastYearMarketDataSuccess,
    (state, action) => {
      const { inputDataPercentageChange } = action.payload;
      let { marketData } = state;
      marketData = R.assocPath(
        ['inputDataPercentageChange'],
        inputDataPercentageChange,
        marketData
      );
      return {
        ...state,
        marketData,
        isLastYearUploadInProgress: false,
        isUploadInProgress: false,
      };
    },
  ],
  [
    saveMarketDataSuccess,
    (state, action) => ({
      ...state,
      marketDataSnapshot: action.payload,
      marketData: {
        ...state.marketData,
        ...action.payload,
      },
    }),
  ],
  [
    getLastYearInputData,
    (state, action) => {
      const {
        ready,
        error,
        payload: { data, canUploadForCurrentYear },
      } = action;

      if (!ready) {
        return {
          ...state,
          isLoadingLastYear: true,
        };
      }
      if (error) {
        return {
          ...state,
          isLoadingLastYear: false,
        };
      }

      return {
        ...state,
        isLoadingLastYear: false,
        marketData: {
          ...state.marketData,
          lastYearInputData: data,
          canUploadForCurrentYear,
        },
      };
    },
  ],
];
