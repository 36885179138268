import * as R from 'ramda';

const getProviersOptions = providers =>
  R.reduce(
    (acc, { id, name, abbreviation }) =>
      acc.concat({
        id,
        label: name,
        value: name,
        abbreviation,
      }),
    [],
    providers
  );

export default getProviersOptions;
