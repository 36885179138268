export default {
  PAGE_TITLE: 'FX Sources',
  PAGE_GROUP: 'Exchange rates source',
  PAGE_SUBTITLE: `This section contains the Foreign Exchange Rate (FX) data used for converting currency amounts from one to another. The rates are differentiated by the FX source and the period / frequency of refresh. The base or ‘from’ currency must always be the Company Currency. The system will then automatically calculate the conversion where required.`,
  TABLE_NAME_LABEL: 'FX source name',
  UPLOAD_FORM_TITLE: 'Create FX source',
  UPLOAD_FORM_FILE_LABEL: 'Select file',
  UPLOAD_FORM_SOURCE_PLACEHOLDER: 'Add a name',
  UPLOAD_FORM_BUTTON_TEXT: 'Browse',
  MENU_LIST_VIEW: 'View source',
  NO_FX_SOURCES:
    "There aren't any FX sources in the system. You can start by uploading a new source.",
  ERROR_SOURCE_NAME_EXISTS: 'FX source name already exists',
  FX_TABLE_EFFECTIVE_DATE: 'Effective From',
  FX_TABLE_FROM_CURRENCY: 'From',
  FX_TABLE_TO_CURRENCY: 'To',
  FX_TABLE_VALUE: 'Value',
  FX_RATES_APPEND_BUTTON: 'Append new rates',
  FX_RATES_APPEND_DIALOG_TITLE: 'Append FX rates',
  FX_RATES_NO_RESULTS:
    "There aren't any FX rates for this source. You can start by uploading new rates.",
  FX_RATES_NO_FILTER_RESULTS: 'No results found. Please change your filtering criteria.',
  FX_RATES_FILTER_DATE_LABEL: 'Select effective date',
  FX_RATES_FILTER_DATE_PLACEHOLDER: 'From',
  FX_RATES_FILTER_CURRENCIES_LABEL: 'Filter by currencies',
  FX_RATES_FILTER_CURRENCIES_FROM: 'From currency',
  FX_RATES_FILTER_CURRENCIES_TO: 'To currency',
  FX_RATES_FILTER_APPLY: 'Apply',
  FX_RATES_FILTER_CLEAR: 'Clear filters',
  FX_DOWNLOAD_TEMPLATE: 'Download template',
};
