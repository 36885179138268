/* eslint-disable max-lines-per-function */
import * as R from 'ramda';
import * as actionTypes from '../actions/actionTypes';
import {
  MIMIC_EMPLOYEE_BEGIN,
  MIMIC_EMPLOYEE_EXIT,
  MIMIC_EMPLOYEE_RESET_REDIRECT,
  MIMIC_EMPLOYEE_GET_INFO,
} from '../modules/rmDashboard/components/dashboard/actions/types';
import userManager, { permissions } from '../config/userManager/userManager';
import { LANDING_PAGES } from '../config/userManager/menuConfig';
import getLandingPage from './helpers';

const initialState = {
  profile: {},
  authenticationInProgress: true,
  isLoggedIn: false,
  /** True if user is found in the AD */
  isValid: false,
  email: '',
  pendingModules: false,
  authorizedModules: {},
  landingPage: null,
  statusCode: 200,
  mimic: {
    fetchingInfo: false,
    isMimicView: userManager.hasMimicProfile() || false,
    redirectToRmDashboard: false,
  },
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.USER_VALIDATE: {
      const { isValid, email } = action;

      return {
        ...state,
        isValid,
        email,
      };
    }

    case actionTypes.USER_DECLINE_DISCLAIMER: {
      return {
        ...state,
        isLoggedIn: false,
        isValid: false,
        profile: {},
        email: '',
      };
    }
    case actionTypes.USER_LOGOUT: {
      return {
        isLoggedIn: false,
        isValid: false,
        profile: {},
        email: '',
        landingPage: null,
        mimic: {
          fetchingInfo: false,
          isMimicView: false,
          redirectToRmDashboard: false,
        },
      };
    }

    case actionTypes.USER_LOGOUT_ERROR: {
      return {
        ...state,
        error: action.err,
      };
    }

    case actionTypes.USER_REDIRECT_TO_LOGIN: {
      userManager.signinRedirect({ extraQueryParams: { domain_hint: action.domainHint } });
      return {
        ...state,
      };
    }
    case actionTypes.USER_GET_PROFILE: {
      return {
        ...state,
        profile: action.payload,
      };
    }

    case actionTypes.USER_LOGIN_ERROR: {
      const { statusCode } = action;
      if (statusCode === 404) {
        return {
          ...state,
          landingPage: LANDING_PAGES.ACCESS_DENIED,
          statusCode,
        };
      }

      return {
        ...state,
        profile: {},
        statusCode,
        isLoggedIn: false,
      };
    }

    case actionTypes.USER_NO_PROFILE: {
      return {
        ...state,
        landingPage: LANDING_PAGES.ACCESS_DENIED,
      };
    }

    case actionTypes.USER_GET_AUTHORIZED_MODULES_PENDING: {
      return {
        ...state,
        pendingModules: action.payload.pending,
      };
    }

    case actionTypes.USER_GET_AUTHORIZED_MODULES: {
      return {
        ...state,
        authorizedModules: action.payload,
        pendingModules: false,
      };
    }

    case actionTypes.GET_MODELLER_PERMISSIONS: {
      return {
        ...state,
        authorizedModules: {
          ...state.authorizedModules,
          [permissions.MODELLER.MODELLER_CONFIGURATION_UPDATE]: action.payload.permissions,
        },
      };
    }

    case actionTypes.GET_MODELLER_PERMISSIONS_BETA: {
      return {
        ...state,
        authorizedModules: {
          ...state.authorizedModules,
          [permissions.MODELLER.MODELLER_CONFIGURATION_UPDATE]: action.payload.permissions,
        },
      };
    }

    case actionTypes.GET_REPORT_PERMISSIONS: {
      return {
        ...state,
        authorizedModules: {
          ...state.authorizedModules,
          [permissions.REPORTS.REPORTS_EXTERNAL_UPDATE]: action.payload.permissions,
        },
      };
    }

    case actionTypes.USER_GENERATE_MENU: {
      let landingPage = '';

      if (!action.isSetupCompleted && R.has(['SETTINGS'], state.authorizedModules)) {
        landingPage = '/settings';
      } else {
        landingPage = state.landingPage
          ? state.landingPage
          : getLandingPage(
              R.keys(state.authorizedModules),
              R.path(['profile', 'isNonEmployee'], state)
            );
      }

      return {
        ...state,
        landingPage,
      };
    }

    case MIMIC_EMPLOYEE_BEGIN: {
      return {
        ...state,
        profile: action.employee,
        mimic: {
          ...state.mimic,
          fetchingInfo: false,
          isMimicView: true,
        },
      };
    }

    case MIMIC_EMPLOYEE_GET_INFO: {
      return {
        ...state,
        mimic: {
          ...state.mimic,
          fetchingInfo: true,
        },
      };
    }

    case MIMIC_EMPLOYEE_EXIT: {
      return {
        ...state,
        profile: action.profile,
        mimic: {
          fetchingInfo: false,
          isMimicView: false,
          redirectToRmDashboard: true,
        },
      };
    }

    case MIMIC_EMPLOYEE_RESET_REDIRECT: {
      return {
        ...state,
        mimic: {
          ...state.mimic,
          redirectToRmDashboard: false,
        },
      };
    }

    case actionTypes.AUTHENTICATION_IN_PROGRESS: {
      return {
        ...state,
        authenticationInProgress: true,
        isLoggedIn: false,
      };
    }

    case actionTypes.AUTHENTICATION_SUCCEEDED: {
      return {
        ...state,
        authenticationInProgress: false,
        isLoggedIn: true,
      };
    }

    case actionTypes.AUTHENTICATION_ERROR: {
      return {
        ...state,
        isLoggedIn: false,
        authenticationInProgress: false,
        err: false,
      };
    }

    case actionTypes.USER_CLEAR_DATA: {
      return R.clone(initialState);
    }

    default:
      return state;
  }
}
