import { actionTypes } from '../../actions/exceptionsListActions';
import initialState from './initialState';
import { transformExceptions, transformFilters, mapSetPending } from './helpers';

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.EXCEPTIONS_SET_REWARDID:
      return { ...state, rewardId: action.payload };
    case actionTypes.LOAD_EXCEPTIONS_LIST_PENDING:
      return { ...state, fetchingList: true };
    case actionTypes.LOAD_EXCEPTIONS_LIST_SUCCESS: {
      const { data, tableTools } = action;
      const { exceptions, filterOptions, count } = data;

      return {
        ...state,
        list: transformExceptions(exceptions),
        filterOptions: transformFilters(filterOptions),
        fetchingList: false,
        totalResults: count,
        tableTools,
      };
    }
    case actionTypes.LOAD_EXCEPTIONS_LIST_ERROR:
      return { ...initialState, fetchingList: false, error: action.err };
    case actionTypes.DELETE_EXCEPTION_PENDING:
    case actionTypes.DELETE_EXCEPTIONS_PENDING:
      return {
        ...state,
        list: state.list.map(mapSetPending(action.id)),
      };
    case actionTypes.DELETE_EXCEPTION_ERROR:
    case actionTypes.DELETE_EXCEPTIONS_ERROR:
      return {
        ...state,
        list: state.list.map(mapSetPending(action.id, false)),
        error: action.err,
      };
    case actionTypes.PUBLISH_EXCEPTION_PENDING:
    case actionTypes.PUBLISH_EXCEPTIONS_PENDING:
      return { ...state };
    case actionTypes.PUBLISH_SELECTED_EXCEPTIONS_PENDING:
      return { ...state, publishing: true };
    case actionTypes.PUBLISH_EXCEPTIONS_SUCCESS:
    case actionTypes.DELETE_EXCEPTIONS_SUCCESS:
      return { ...state, publishing: false };
    case actionTypes.PUBLISH_EXCEPTION_ERROR:
    case actionTypes.PUBLISH_EXCEPTIONS_ERROR:
      return { ...state, publishing: false, err: action.err };
    case actionTypes.EXCEPTIONS_RESET_STORE:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
