import * as R from 'ramda';
import { isBlank, upperFirstLetter, rejectEmptyValues } from '@trs/utils';
import { v4 as uuid } from 'uuid';
import { TextOnly } from '../../../../common/Text';
import { getError } from '../../../../../config/errors';

export const validateEligibilitySimplePopulation = (populationObject) =>
  R.mapObjIndexed((item, key) => {
    let keyLabel;
    switch (key) {
      case 'variable':
        keyLabel = TextOnly({ path: 'rewards.ELIGIBILITY_RULE_VARIABLE_NAME' });
        break;
      case 'operator':
        keyLabel = TextOnly({ path: 'rewards.ELIGIBILITY_RULE_OPERATOR' });
        break;
      case 'values':
        keyLabel = TextOnly({ path: 'rewards.ELIGIBILITY_RULE_VALUE' });
        break;
      default:
        keyLabel = key;
        break;
    }

    return getError(TextOnly({ path: 'generic.ERROR_REQUIRED' }), upperFirstLetter(keyLabel));
  }, R.filter(R.isEmpty, populationObject));

export const getEligibilityErrors = (eligibility, errors = {}) => {
  const errorObject = errors;
  if (eligibility.eligibilityRows) {
    R.forEach(
      (eligibilityRow) => getEligibilityErrors(eligibilityRow, errorObject),
      eligibility.eligibilityRows
    );
  }

  if (!isBlank(R.prop('eligibilityRowsOperator', eligibility))) {
    return errorObject;
  }

  const populationErrors = validateEligibilitySimplePopulation({
    variable: R.prop('variable', eligibility),
    operator: R.prop('operator', eligibility),
    values: R.prop('values', eligibility),
  });

  if (!isBlank(populationErrors)) {
    errorObject[eligibility.uid] = populationErrors;
  }

  return errorObject;
};

export const getPopulationNameErrors = (name, existingRuleNames = []) => {
  if (isBlank(name)) {
    return getError(
      TextOnly({ path: 'generic.ERROR_REQUIRED' }),
      TextOnly({ path: 'benchmarking.POPULATION_NAME_FIELD' })
    );
  }

  if (isBlank(existingRuleNames) || !R.contains(name, existingRuleNames)) return {};

  return TextOnly({ path: 'benchmarking.NAME_UNIQUE' });
};

export const validatePopulation = (population, names) => {
  const { eligibility, name } = population;

  const errors = {
    name: getPopulationNameErrors(name, names),
    eligibility: getEligibilityErrors(eligibility),
  };
  return isBlank(errors.name) && isBlank(rejectEmptyValues(R.values(errors.eligibility)))
    ? {}
    : errors;
};

export const validatePopulations = (populations) => {
  const errors = [];
  const names = [];
  populations.forEach((pop, index) => {
    errors[index] = validatePopulation(pop, names);
    names.push(pop.name);
  });
  return errors;
};

export const processPopulationChange = (statePopulations, payload) => {
  const populations = statePopulations;

  populations[payload.index] = {
    ...populations[payload.index],
    [payload.field]: payload.value,
    ...payload.other,
  };

  return populations;
};

export const mapEligibilityOperators = (payload) =>
  payload.map(({ displayName, name, allowsMultipleSelect }) => ({
    value: name,
    label: displayName,
    allowsMultipleSelect,
  }));

export const mapSystemVariables = (payload) => {
  let systemVariables = payload.map(({ displayName, propertyName, type, valuesUrl }) => ({
    value: propertyName,
    label: displayName,
    type,
    valuesUrl,
  }));

  systemVariables = R.sortBy(R.prop('value'))(systemVariables);
  return systemVariables;
};

export const getNewPopulation = (id, index) => ({
  key: id,
  name: `Population ${index + 1}`,
  id,
  eligibility: {
    eligibilityRows: [
      {
        variable: '',
        operator: '',
        values: [],
        uid: uuid(),
      },
    ],
    uid: uuid(),
  },
});

export const getLookupData = (lookupData, url) => {
  const lookUpValues = {};
  lookUpValues[url] = [];

  if (lookupData) {
    lookupData.map((item) =>
      lookUpValues[url].push({
        label: item,
        value: item,
      })
    );
    lookUpValues[url] = R.sortBy(R.prop('value'))(lookUpValues[url]);
  }

  return lookUpValues;
};
