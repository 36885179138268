import * as R from 'ramda';
import types from '../actions/types';
import { defaultProvider } from './helpers';

const initialState = {
  isLoading: false,
  providers: [],
  errors: {},
};

const sharesProviderReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_PROVIDERS: {
      const { ready, payload, error } = action;

      if (!ready) {
        return {
          ...state,
          isLoading: true,
        };
      }

      if (error) {
        return {
          ...state,
          isLoading: false,
        };
      }

      const { providers } = payload;
      return {
        ...state,
        isLoading: false,
        providers: providers.map((provider) => ({
          ...provider,
          originalName: provider.name,
        })),
      };
    }

    case types.GET_PROVIDER_DATA: {
      const { ready, payload, error } = action;

      if (!ready) {
        return {
          ...state,
          isLoading: true,
        };
      }

      if (error) {
        return {
          ...state,
          isLoading: false,
        };
      }

      return {
        ...state,
        isLoading: false,
        errors: {},
        providers: state.providers.map((provider) => {
          if (provider.id === payload.id) {
            return {
              ...provider,
              ...payload,
              hasChanges: false,
            };
          }
          return provider;
        }),
      };
    }

    case types.UPDATE_PROVIDER_DATA: {
      const { providerId, field, value } = action.payload;

      return {
        ...state,
        isLoading: false,
        providers: state.providers.map((provider) => {
          if (provider.id === providerId) {
            return {
              ...provider,
              hasChanges: true,
              [field]: value,
            };
          }
          return provider;
        }),
      };
    }

    case types.ADD_NEW_PROVIDER: {
      return {
        ...state,
        errors: {},
        providers: [
          ...state.providers,
          {
            ...defaultProvider,
            hasChanges: false,
          },
        ],
      };
    }

    case types.CLEAR_PROVIDER: {
      return {
        ...state,
        providers: state.providers.filter((prov) => !R.isNil(prov.id)),
      };
    }

    case types.SAVE_PROVIDER_DATA_FAIL: {
      const { field, message } = action.payload;

      return {
        ...state,
        isLoading: false,
        errors: {
          [field]: message,
        },
      };
    }

    case types.SAVE_PROVIDER_DATA: {
      return {
        ...state,
        isLoading: true,
      };
    }

    default:
      return state;
  }
};

export default sharesProviderReducer;
