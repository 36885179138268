import * as R from 'ramda';
import APP_CONFIG from '@trs/config';
import { toLower, processDate, safeJSONParse } from '@trs/utils';
import { connectors } from 'config/api';
import { STATUSES } from 'modules/onboarding/components/common/DataLoad/helpers';
import { TextOnly } from 'modules/common/Text';
import { NOTIFICATION_TYPE } from '../../../constants';

const { DATE_TIME_FORMAT } = APP_CONFIG;
const BOOLEAN_MAP = {
  true: true,
  false: false,
};

export const getTitleMap = () => ({
  [NOTIFICATION_TYPE.EMPLOYEE_DATA_LOADING]: TextOnly({
    path: 'notifications.NOTIFICATION_EMPLOYEE_DATA_LOADING_TITLE',
  }),
  [NOTIFICATION_TYPE.SHARES_DATA_LOADING]: TextOnly({
    path: 'notifications.NOTIFICATION_SHARES_DATA_LOADING_TITLE',
  }),
});

export const getLinkMap = errorFileName => ({
  [NOTIFICATION_TYPE.EMPLOYEE_DATA_LOADING]: `${connectors.employeesUploadError}/${errorFileName}`,
  [NOTIFICATION_TYPE.SHARES_DATA_LOADING]: `${connectors.sharesUploadError}/${errorFileName}`,
});

export const getDataLoadingContent = ({ fileName, lastUploaded, inValidationMode }) => {
  const FILE_NAME = fileName || '';
  const LAST_UPLOADED =
    processDate(R.prop('date', safeJSONParse(lastUploaded)), DATE_TIME_FORMAT) || '';
  const TYPE_LABEL =
    TextOnly({
      path: `generic.${BOOLEAN_MAP[toLower(inValidationMode)] ? 'VALIDATED' : 'PROCESSED'}`,
    }) || '';
  return {
    [STATUSES.processedSuccessfully]: TextOnly({
      path: 'notifications.NOTIFICATION_DATA_LOADING_CONTENT_SUCCESS',
      withHtml: true,
      transform: (label = '') =>
        label
          .replace('%FILENAME%', FILE_NAME)
          .replace('%TYPE%', TYPE_LABEL)
          .replace('%DATE%', LAST_UPLOADED),
    }),
    [STATUSES.processedWithErrors]: TextOnly({
      path: 'notifications.NOTIFICATION_DATA_LOADING_CONTENT_ERRORS',
      withHtml: true,
      transform: (label = '') =>
        label.replace('%FILENAME%', FILE_NAME).replace('%TYPE%', TYPE_LABEL),
    }),
    [STATUSES.exceptionOccurred]: TextOnly({
      path: 'generic.BANNER_ERROR_GENERIC',
      withHtml: true,
      transform: (label = '') => label.replace('%FIELD%', ''),
    }),
  };
};

export default {
  getTitleMap,
  getLinkMap,
  getDataLoadingContent,
};
