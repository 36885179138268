export const REWARDS_GET_CATEGORIES = 'REWARDS_GET_CATEGORIES';
export const REWARDS_SAVE = 'REWARDS_SAVE';
export const REWARDS_PUBLISHED = 'REWARDS_PUBLISHED';
export const REWARDS_DUPLICATE_RULE_BLOCK = 'REWARDS_DUPLICATE_RULE_BLOCK';
export const REWARDS_ADD_RULE_BLOCK = 'REWARDS_ADD_RULE_BLOCK';
export const REWARDS_DELETE_RULE_BLOCK = 'REWARDS_DELETE_RULE_BLOCK';
export const REWARDS_DELETE_RULE_FORMULAS = 'REWARDS_DELETE_RULE_FORMULAS';
export const REWARDS_DELETE_RULE_RANGE = 'REWARDS_DELETE_RULE_RANGE';
export const REWARDS_SEARCH = 'REWARDS_SEARCH';
export const REWARDS_IMPACT_SEARCH = 'REWARDS_IMPACT_SEARCH';
export const REWARDS_GET_IMPACT_ERROR = 'REWARDS_GET_IMPACT_ERROR';
export const REWARDS_VERSIONS_REQUESTED = 'REWARDS_VERSIONS_REQUESTED';
export const REWARDS_VERSIONS_SEARCH = 'REWARDS_VERSIONS_SEARCH';
export const REWARDS_REQUESTED = 'REWARDS_REQUESTED';
export const REWARDS_GET_ERROR = 'REWARDS_GET_ERROR';
export const REWARDS_GET_COUNTRIES = 'REWARDS_GET_COUNTRIES';
export const REWARDS_GET_CURRENCIES = 'REWARDS_GET_CURRENCIES';
export const REWARDS_GET_FX_SOURCES = 'REWARDS_GET_FX_SOURCES';
export const GET_VIDEO_PLATFORMS_ACCEPTED = 'GET_VIDEO_PLATFORMS_ACCEPTED';
export const REWARDS_GET_TAXABILITY_VALUES = 'REWARDS_GET_TAXABILITY_VALUES';
export const REWARDS_GET_BY_ID = 'REWARDS_GET_BY_ID';
export const REWARDS_GET_STATUS = 'REWARDS_GET_STATUS';
export const REWARDS_REVERT_TO_DRAFT = 'REWARDS_REVERT_TO_DRAFT';
export const FETCHING_REWARD_BY_ID = 'FETCHING_REWARD_BY_ID';

export const LOOKUP_COUNTRIES = 'LOOKUP_COUNTRIES';
export const LOOKUP_FEATURE_MODULES = 'LOOKUP_FEATURE_MODULES';

export const BASIC_INFORMATION_CHANGE = 'BASIC_INFORMATION_CHANGE';
export const BASIC_INFORMATION_RESET = 'BASIC_INFORMATION_RESET';
export const DESCRIPTION_CHANGE = 'DESCRIPTION_CHANGE';

export const RULES_LOGIC_BUILDER_SAVE = 'RULES_LOGIC_BUILDER_SAVE';
export const ETAG_UPDATE = 'ETAG_UPDATE';
export const STORE_RESET = 'STORE_RESET';

export const SYSTEM_VARIABLES_GET = 'SYSTEM_VARIABLES_GET';

export const ELIGIBILITY_OPERATORS_GET = 'ELIGIBILITY_OPERATORS_GET';
export const SYSTEM_VARIABLES_LOOKUP_GET = 'SYSTEM_VARIABLES_LOOKUP_GET';

export const REDIRECT_TO_ROOT = 'REDIRECT_TO_ROOT';
export const REDIRECT_TO_EXCEPTIONS = 'REDIRECT_TO_EXCEPTIONS';

export const ADD_BANNER = 'BANNER_SHOW';
export const REMOVE_BANNER = 'BANNER_HIDE';
export const REMOVE_ALL_BANNERS = 'REMOVE_ALL_BANNERS';

export const SHOW_DIALOG = 'SHOW_DIALOG';
export const HIDE_DIALOG = 'HIDE_DIALOG';

export const RULES_BUILDER_RESET = 'RULES_BUILDER_RESET';
export const RULE_CHANGE = 'RULE_CHANGE';

export const USER_VALIDATE = 'USER_VALIDATE';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_LOGIN = 'USER_LOGIN';

export const FORMULA_OPERATORS_GET = 'FORMULA_OPERATORS_GET';
export const FX_RATE_GET_DATES = 'FX_RATE_GET_DATES';

export const SET_REWARD_FEEDBACK = 'SET_REWARD_FEEDBACK';
export const SET_IS_LOADING_REWARD_FEEDBACK = 'SET_IS_LOADING_REWARD_FEEDBACK';

export const LINKED_REWARDS_DELETE_RULE = 'LINKED_REWARDS_DELETE_RULE';
export const LINKED_REWARDS_GET = 'LINKED_REWARDS_GET';
export const LINKED_REWARDS_OPERANDS_REQUESTED = 'LINKED_REWARDS_OPERANDS_REQUESTED';
