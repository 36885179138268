"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var StyledAge = _styledComponents["default"].div.withConfig({
  displayName: "Agestyle__StyledAge",
  componentId: "sc-jf3mfm-0"
})(["", ";"], function (props) {
  var isFocused = props.isFocused,
    theme = props.theme;
  var palette = theme.palette;
  return (0, _styledComponents.css)(["align-items:normal;display:inline-flex;border-bottom:1px solid ", ";", " input,.Select .Select-control{border-bottom:none;}.age-select{margin-right:20px;width:130px;}.aod-select{margin-right:20px;width:230px;}.aod-datepicker{width:140px;i.input-icon{padding:0;top:-5px;}}"], palette.gray.bravo, isFocused && (0, _styledComponents.css)(["border-bottom:1px solid ", ";"], palette.secondary));
});
StyledAge.displayName = 'StyledAge';
var _default = StyledAge;
exports["default"] = _default;