import { STATUSES } from '../../common/DataLoad/helpers';

const initialState = {
  isLoading: false,
  isDataLoadLoading: false,
  providersOptions: [],
  selectedProvider: {},
  isUploadInProgress: false,
  isValidateInProgress: false,
  selectedFile: null,
  error: null,

  overallStatus: STATUSES.notUploaded,
  fileName: '',
  lastUploaded: '',
  runInValidationMode: false,
  errorFilePath: '',
};

export default initialState;
