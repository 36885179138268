import * as R from 'ramda';
import { isBlank } from '@trs/utils';
import { createAction } from 'redux-actions';
import { GET, PATCH, noCacheHeader } from '../../../config/http';
import { showBanner } from '../../../actions/globalActions';
import { notifications } from '../../../config/api';
import { publish } from '../../common/Observer';

const actionTypes = {
  LOAD_NOTIFICATIONS_PENDING: 'LOAD_NOTIFICATIONS_PENDING',
  LOAD_NOTIFICATIONS_SUCCESS: 'LOAD_NOTIFICATIONS_SUCCESS',
  LOAD_NOTIFICATIONS_ERROR: 'LOAD_NOTIFICATIONS_ERROR',
  NOTIFICATIONS_STORE_RESET: 'NOTIFICATIONS_STORE_RESET',
  NOTIFICATIONS_WIDGET_TOGGLE: 'NOTIFICATIONS_WIDGET_TOGGLE',
  NOTIFICATIONS_MARK_AS_READ: 'NOTIFICATIONS_MARK_AS_READ',
};

export const loadNotificationsPending = createAction(actionTypes.LOAD_NOTIFICATIONS_PENDING);
export const loadNotificationsSuccess = createAction(actionTypes.LOAD_NOTIFICATIONS_SUCCESS);
export const loadNotificationsError = createAction(actionTypes.LOAD_NOTIFICATIONS_ERROR);
export const notificationsStoreReset = createAction(actionTypes.NOTIFICATIONS_STORE_RESET);
export const toggleNotificationsWidget = createAction(actionTypes.NOTIFICATIONS_WIDGET_TOGGLE);

function dispatchError(error, dispatch) {
  const errorMessage = R.path(['response', 'data', 'errors', 0, 'message'], error);

  dispatch(loadNotificationsError());
  dispatch(
    showBanner({
      type: 'error',
      content: errorMessage,
    })
  );
}
export const loadNotifications = () => dispatch => {
  dispatch(loadNotificationsPending());

  return GET(`${notifications.base}`, noCacheHeader)
    .then(response => {
      dispatch(loadNotificationsSuccess({ list: R.path(['data', 'response', 'list'], response) }));
    })
    .catch(error => {
      dispatchError(error, dispatch);
      return Promise.reject(error);
    });
};

export const markNotification = notificationId => dispatch => {
  const payload = {
    isRead: true,
  };

  dispatch(toggleNotificationsWidget());

  return PATCH(`${notifications.base}/${notificationId}`, payload)
    .then(() => {
      dispatch(loadNotifications());
    })
    .catch(error => {
      dispatchError(error, dispatch);
      return Promise.reject(error);
    });
};

export const onWSMessage = data => (dispatch, getState) => {
  const newList = R.path(['response', 'list'], data) || [];
  const existingList = R.path(['notifications', 'list'], getState()) || [];
  if (isBlank(newList)) return false;

  const newNotifications = [...existingList, ...newList];
  dispatch(loadNotificationsSuccess({ list: R.uniqBy(R.prop('id'), newNotifications) }));

  R.map(notification => {
    publish(R.prop('typeId', notification), notification);
    return false;
  }, newList);
  return false;
};
