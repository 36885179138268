export default {
  BUDGET_LABEL: 'Budget',
  SET_A_BUDGET: 'Set a budget',
  SET_A_BUDGET_DESCRIPTION:
    'Check this box to begin setting up a budget for this flexible package. If you leave this unchecked then there will be no budget available for any of the eligible employees.',
  BUDGET_TITLE: 'Budget Title',
  BUDGET_TITLE_PLACEHOLDER: 'Specific budget title',
  BUDGET_CATEGORY: 'Budget Category',
  BUDGET_CATEGORY_PLACEHOLDER: 'Select budget category',
  BUDGET_DEFINITION: 'Budget Definition',
  BUDGET_CATEGORISATION: 'Budget Categorisation',
  BUDGET_CATEGORISATION_SUBTITLE:
    'Please define the title of the remaining budget and its category. Based on this selection it will be displayed as a line in the simulation or selection output of the employee statement.',
  FORMULA_SIMPLE_PLACEHOLDER: 'Start by inserting a %value field%.',
  EXCESS_FORMULA_EXPAND_LABEL: 'Set maximum for exceeded budget',
  EXCESS_FORMULA_RETRACT_LABEL: 'Remove maximum for exceeded budget',
  EXCESS_FORMULA_TITLE: 'Allow employee to exceed the budget',
  MINIMUM_USAGE_FORMULA_TITLE: 'Set a minimum usage for employee budget',
  REMAINING_BUDGET: 'Remaining budget',
  REMAINING_BUDGET_CUSTOM_LABEL_TITLE: 'Set custom labels',
  REMAINING_BUDGET_HELPER_TEXT:
    "If the default value of this label is used, 'Budget' in this label will be replaced with the custom Budget label.",
  BUDGET_EMPTY_CUSTOM_TITLE_ERROR_LABEL: 'Make sure you have defined a label for the budget',
  REMAINING_BUDGET_EMPTY_CUSTOM_TITLE_ERROR_LABEL:
    'Make sure you have defined a label for the remaining budget',
  BUDGET_LENGTH_CUSTOM_TITLE_ERROR_LABEL: 'The custom label cannot be longer than 30 characters.',
  BUDGET_LENGTH_CUSTOM_TITLE_LABEL: 'Custom budget label',
};
