import * as R from 'ramda';
import { createAction } from 'redux-actions';
import { isBlank, offsetTimezone } from '@trs/utils';
import { TextOnly } from '../../../../common/Text';
import { validatePopulations } from '../../common/benchmarkPopulation/helpers';
import { ErrorComponent, getErrors } from '../../../../common/Form/FormOrchestrator/helpers';
import { validateCountryDetails } from '../components/countryDetails/helpers';

const BENCHMARKING_ERROR = 'BENCHMARKING_ERROR';

export const getRequestPayload = data => {
  return data;
};

export const updateBenchmarkingError = createAction(BENCHMARKING_ERROR);

const getPayScaleIntervalsPayload = population => {
  const payScaleIntervals = R.path(['payScaleIntervals'], population);
  const populations = R.path(['populations'], population);
  return R.keys(payScaleIntervals).map(key => ({
    populationId: key,
    populationName: R.path(['name'], R.find(R.propEq('id', key))(populations)),
    min: R.path([key, 'min'], payScaleIntervals),
    max: R.path([key, 'max'], payScaleIntervals),
  }));
};

export const getGlobalRequestPayload = ({ populationsId, population, main }) => ({
  populationsId,
  referenceData: {
    ageingDate: offsetTimezone(main.ageingDate),
    fxSourceId: main.fxSourceId,
    fxRatesDate: offsetTimezone(main.fxRatesDate),
  },
  countries: R.map(
    item => ({
      code: item.code,
      isManualSetup: item.isManualSetup,
      isActive: item.isActive,
      targetMarketPosition: {
        basePay: item.basePay,
        fixedPay: item.fixedPay,
        variablePay: item.variablePay,
        benefits: item.benefits,
      },
      contact: {
        name: item.contactName,
        email: item.email,
      },
    }),
    R.values(main.countriesMaping)
  ),
  payScaleIntervals: getPayScaleIntervalsPayload(population),
});

export const getValidationRules = () => ({
  fxSourceId: [
    [
      isBlank,
      TextOnly({
        path: 'generic.GENERIC_ERROR_REQUIRED',
      }),
    ],
  ],
  fxRatesDate: [
    [
      isBlank,
      TextOnly({
        path: 'generic.GENERIC_ERROR_REQUIRED',
      }),
    ],
  ],
  ageingDate: [
    [
      isBlank,
      TextOnly({
        path: 'generic.GENERIC_ERROR_REQUIRED',
      }),
    ],
  ],
});

const validatePayScaleIntervals = (populations, payScaleIntervals) => {
  const errors = {};
  populations.map(({ id }) => {
    errors[id] = {
      min: isBlank(R.path([id, 'min'], payScaleIntervals))
        ? TextOnly({ path: 'generic.GENERIC_ERROR_REQUIRED' })
        : '',
      max: isBlank(R.path([id, 'max'], payScaleIntervals))
        ? TextOnly({ path: 'generic.GENERIC_ERROR_REQUIRED' })
        : '',
    };
    return true;
  });
  return R.reject(item => isBlank(item.min) && isBlank(item.max), errors);
};

export const getGlobalSettingsErrors = ({ population, main }) => {
  const errors = { population: [] };
  const { populations, payScaleIntervals } = population;
  const { countriesMaping } = main;

  errors.population = validatePopulations(populations);
  errors.countryDetails = validateCountryDetails(countriesMaping);
  errors.payScaleIntervals = validatePayScaleIntervals(populations, payScaleIntervals);
  errors.referenceData = R.reject(
    isBlank,
    R.map(
      ErrorComponent,
      getErrors({})(R.pick(['fxSourceId', 'fxRatesDate', 'ageingDate'], main), getValidationRules())
    )
  );
  return errors;
};

export default {
  getRequestPayload,
  getValidationRules,
  getGlobalSettingsErrors,
};
