import styled, { css } from 'styled-components';
import { media } from '@trs/utils';

const StyledTabularLegend = styled.div`
  ${({ theme: { spacing } }) => css`
    padding-bottom: 0;
    position: relative;
    width: 100%;
    margin-left: ${spacing.large};
    ${media.desktop`
      display: flex;
      flex-flow: column;
      flex-wrap: wrap;
      height: auto;
      padding-bottom: 0;
      &.doughnut-legend {
        height: 240px;
      }
     `}
    &.doughnut-legend {
      max-height: 220px;
      overflow-y: auto;
    }
  `};
`;

StyledTabularLegend.displayName = 'StyledTabularLegend';
export default StyledTabularLegend;
