export default {
  BANNER_CREATE: '%FIELD% has been created.',
  BANNER_UPDATE: '%FIELD% has been updated.',
  BANNER_DELETE: 'The %FIELD% was successfully deleted.',
  BUTTON_MODEL: 'Model',
  BUTTON_SEARCH: 'Search',
  BUTTON_SAVE: 'Save',
  BUTTON_CONFIRM: 'Confirm',
  BUTTON_NO_SAVE: "Don't Save",
  BUTTON_CANCEL: 'Cancel',
  BUTTON_CLOSE: 'Close',
  BUTTON_DELETE: 'Delete',
  BUTTON_DELETE_ALL: 'Delete All',
  BUTTON_DELETE_FILTERED: 'Delete Filtered',
  BUTTON_DELETE_SELECTED: 'Delete Selected',
  BUTTON_RESET: 'Revert',
  BUTTON_CREATE: 'Create',
  BUTTON_ADD: 'Add',
  BUTTON_EXIT: 'Exit',
  BUTTON_BACK: 'Back',
  BUTTON_NEXT: 'Next',
  BUTTON_LOADING: 'Loading',
  BUTTON_UPLOADING: 'Uploading',
  BUTTON_PROCESSING: 'Processing',
  BUTTON_VALIDATING: 'Validating',
  BUTTON_CLEAR: 'Clear',
  BUTTON_SAVING: 'Saving',
  BUTTON_PUBLISHING: 'Publishing',
  BUTTON_YES: 'Yes',
  BUTTON_NO: 'No',
  BUTTON_OK: 'Ok',
  BUTTON_STAY: 'Stay',
  BUTTON_LEAVE: 'Leave',
  BUTTON_SELECT: 'Select',
  BUTTON_CONTINUE: 'Continue',
  BUTTON_DECLINE: 'Decline',
  BUTTON_DOWNLOAD_PDF: 'Download Pdf',
  BUTTON_DOWNLOADING_PDF: 'Downloading Pdf',
  BUTTON_DOWNLOAD: 'Download',
  BUTTON_DOWNLOADING: 'Downloading',
  BUTTON_DOWNLOAD_FILE: 'Download File',
  BUTTON_APPLY: 'Apply',
  BUTTON_REVERT: 'Revert',
  BUTTON_REVERT_TO_DEFAULT: 'Revert to Default',
  BUTTON_CUSTOMISATION: 'Customise',
  BUTTON_OPEN: 'Open',
  BUTTON_VIEW: 'View',
  BUTTON_PUBLISH: 'Publish',
  BUTTON_PUBLISH_ALL: 'Publish All',
  BUTTON_PUBLISH_FILTERED: 'Publish Filtered',
  BUTTON_PUBLISH_SELECTED: 'Publish Selected',
  FILE_UPLOAD_SUCCESS: '%FIELD% file was successfully uploaded.',
  BUTTON_UPLOAD: 'Upload',
  BUTTON_UPLOAD_FILE: 'Upload file',
  BUTTON_VALIDATE_FILE: 'Validate file',
  BUTTON_BROWSE: 'Browse...',
  BUTTON_EDIT: 'Edit',
  BUTTON_RUN: 'Run',
  BUTTON_RE_RUN: 'Re-Run',
  BUTTON_APPROVE: 'Approve',
  BUTTON_REJECT: 'Reject',
  BUTTON_CALCULATE: 'Calculate',
  BUTTON_SUBMIT: 'Submit',
  BUTTON_SAVE_AND_CONTINUE: 'Save and Continue',
  BUTTON_CHANGE: 'Change',
  BUTTON_DOWNLOAD_EXCEL: 'Download Excel',
  BUTTON_SAVE_DETAILS: 'Save Details',
  BUTTON_REMOVE: 'Remove',
  BUTTON_EXPORT: 'Export',
};
