export default {
  FETCH_OPTIONS_PENDING: 'flex/options/pending',
  FETCH_OPTIONS_SUCCESS: 'flex/options/success',
  FETCH_OPTIONS_ERROR: 'flex/options/error',
  REWARDS_SELECTION_CHANGE: 'flex/options/selectionChange',
  REWARDS_AGREEMENT_TOS_CHANGE: 'flex/options/agreementTosChange',
  COMPUTE_STATEMENT_PENDING: 'flex/statement/pending',
  COMPUTE_STATEMENT_ERROR: 'flex/statement/error',
  COMPUTE_STATEMENT_SUCCESS: 'flex/statement/success',
  SAVE_STATEMENT_PENDING: 'flex/statement/save/pending',
  SAVE_STATEMENT_ERROR: 'flex/statement/save/error',
  SAVE_STATEMENT_SUCCESS: 'flex/statement/save/success',
  UPDATE_ETAG: 'flex/statement/update/etag',
  SBS_RESET: 'flex/statement/reset',
  SUBMIT_STATEMENT: 'flex/statement/submit',
  SUBMIT_COMMENTS_CHANGE: 'flex/statement/commentsChange',
  INITIATE_STATEMENT: 'flex/statement/initiate',
  UPDATE_AVAILABLE_OPTIONS: 'flex/statement/update-available-options',
};
