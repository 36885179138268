import { differenceInSeconds } from 'date-fns';
import * as R from 'ramda';
import { isBlank } from '@trs/utils';

export const resetCache = (object) => {
  if (!isBlank(object)) {
    R.keys(object).forEach((key) => {
      // eslint-disable-next-line no-param-reassign
      delete object[key];
    });
  }
};

// we were caching the response previously but ideally we should be caching
// the request.

/**
 *
 * @param {function} promiseFn - promise function
 * @param {number} expiryTime - expiry time of cache in seconds
 *
 */

export const cache = {};

export const withCache = (promiseFn, expiryTime = 600) => {
  return (...args) => {
    const key = JSON.stringify(args);
    const now = Date.now();
    if (cache[key]) {
      // request not yet finished, return the promise.
      if (!cache[key].timestamp) {
        return cache[key];
      }

      const diff = differenceInSeconds(now, cache[key].timestamp);

      if (diff < expiryTime) {
        return Promise.resolve(cache[key].value);
      }

      cache[key] = promiseFn(...args).then((result) => {
        if (cache[key]) cache[key] = {};
        cache[key].value = result;
        cache[key].timestamp = now;

        return result;
      });

      return cache[key];
    }
    cache[key] = promiseFn(...args).then((result) => {
      if (cache[key]) cache[key] = {};
      cache[key].value = result;
      cache[key].timestamp = now;
      return result;
    });
    return cache[key];
  };
};

export default withCache;
