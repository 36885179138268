"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var StyledDatepicker = _styledComponents["default"].div.withConfig({
  displayName: "Datepickerstyle__StyledDatepicker",
  componentId: "sc-wj9zu2-0"
})(["", ";"], function (props) {
  var position = props.position,
    hasError = props.hasError,
    disabled = props.disabled;
  var _props$theme = props.theme,
    palette = _props$theme.palette,
    typography = _props$theme.typography,
    _props$theme$extra = _props$theme.extra,
    genericBorderRadius = _props$theme$extra.genericBorderRadius,
    boxShadow = _props$theme$extra.boxShadow,
    spacing = _props$theme.spacing;
  var positioningProps;
  switch (position) {
    case 'top':
      positioningProps = 'bottom: calc(100% + 10px);';
      break;
    case 'bottom':
      positioningProps = 'top: 42px;';
      break;
    case 'right':
      positioningProps = 'right: 0px;';
      break;
    case 'left':
    default:
      positioningProps = 'left: 0px;';
      break;
  }
  return (0, _styledComponents.css)(["position:relative;input{cursor:pointer;}.icon-ion-android-calendar{cursor:pointer;padding:0;pointer-events:none;top:8px;}.react-calendar{background-color:", ";border:none;border-radius:", ";box-shadow:", ";margin-top:-1px;min-width:300px;position:absolute;", " z-index:10;button{font-family:", ";&:enabled:hover,&:enabled:focus{background-color:", ";}}.react-calendar__month-view__days__day--weekend{color:", ";}.react-calendar__tile--active{background-color:", ";color:", ";&:enabled:hover,&:enabled:focus{background-color:", ";}}.react-calendar__tile:disabled,.react-calendar__navigation button[disabled]{background-color:inherit;color:", ";cursor:not-allowed;opacity:0.5;}}@keyframes fadein{from{opacity:0;}to{opacity:1;}}.datepicker-clear-zone{animation:fadein 200ms;color:", ";cursor:", ";outline:none;position:absolute;right:", ";text-align:center;top:", ";vertical-align:middle;width:17px;&:hover{color:", ";}.datepicker-clear{line-height:0;font-size:22px;display:inline-block;}}.tooltip-container{right:40px;}"], palette.accent.delta, genericBorderRadius, boxShadow, positioningProps, typography.font.primary, palette.accent.charlie, palette.secondary, palette.secondary, palette.basic.white, palette.accent.charlie, palette.gray.bravo, palette.gray.bravo, disabled ? 'not-allowed' : 'pointer', hasError ? '64px' : '44px', spacing.xSmall, disabled ? palette.gray.bravo : palette.basic.black);
});
StyledDatepicker.displayName = 'StyledDatepicker';
var _default = StyledDatepicker;
exports["default"] = _default;