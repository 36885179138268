export default {
  FORMULA_SHARE_NO_COUNTRY_PLACEHOLDER:
    'Please select a country in order to start creating the reward formula.',
  FORMULA_SHARE_PLACEHOLDER: 'Start by inserting a %value% or a %text-only reward% field.',
  // FORMULA_SHARE_PLACEHOLDER:
  //   'Start by inserting a %value% or a %text-only reward% field or a %stepper%.',
  FORMULA_SHARE_VIEW_FIXED: 'Fixed',
  FORMULA_SHARE_VIEW_PERCENTAGE: 'Percentage',
  FORMULA_SHARE_VIEW_CONTRIBUTION: 'Contribution',
  FORMULA_SHARE_VIEW_OF: 'of',
  GLOBAL_RULE_IS_REQUIRED: 'At least one Global rule is required',
};
