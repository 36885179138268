import * as R from 'ramda';
import APP_CONFIG from '@trs/config';
import { roundFunctionTypes, DATA_TYPES } from '@trs/components';
import { toLower, isBlank } from '@trs/utils';
import { v4 as uuid } from 'uuid';
import { checkIsTextOnly } from '../components/rulesBuilder/helpers';
import { getStatusByScore, convertDecimalToPercentage } from '../../feedback/components/helpers';
import { contextMenuIcon } from '../../feedback/constants';
import { hasLocalAccess } from '../../variables/helpers';
import { ACTIVE_TYPES, FX_RATE_DATE_CUSTOM } from '../constants';

const { REWARD_STATUSES } = APP_CONFIG;
const RECURRENCE_OPTIONS = ['months', 'years'];
const { TEMPLATE_TYPES } = APP_CONFIG;
export const getPath = (from, key) => R.path(['basicInformation', key], from);

const thresholdInteraction = (data) => {
  const value = R.clone(data);
  if (!value) return '';
  if (value.min && value.max) return value;

  if (value.min) {
    value.operator = value.isInclusive ? '>=' : '>';
    value.member = value.min;
  } else if (value.max) {
    value.operator = value.isInclusive ? '<=' : '<';
    value.member = value.max;
  } else {
    delete value.min;
    delete value.max;
  }
  return value;
};

const addDataType = ({ formulaItem, argument }) => {
  const dataType =
    formulaItem && roundFunctionTypes.includes(formulaItem.value)
      ? DATA_TYPES.NUMERIC
      : DATA_TYPES.MIXED;

  return R.map(R.assoc('dataType', dataType))(argument);
};

const addUuidsToFormulaItems = (formulaItems) =>
  formulaItems.map((formulaItem) =>
    formulaItem.arguments
      ? {
          key: uuid(),
          ...formulaItem,
          arguments: [
            addUuidsToFormulaItems(R.path(['arguments', 0], formulaItem)),
            addUuidsToFormulaItems(
              addDataType({ formulaItem, argument: R.path(['arguments', 1], formulaItem) })
            ),
          ],
        }
      : {
          ...formulaItem,
          key: uuid(),
        }
  );

const addUuidsToEligibilityItems = (items) => {
  const newRules = items;

  if (isBlank(newRules)) return null;

  newRules.uid = uuid();

  if (R.prop('eligibilityRows', newRules)) {
    newRules.eligibilityRows.map((item) => addUuidsToEligibilityItems(item));
  }

  return newRules;
};

const addUuidsToLinkedRewardsItems = (items) => {
  const newRules = items;

  if (isBlank(newRules)) return null;

  newRules.uid = uuid();

  if (R.prop('eligibilityRows', newRules)) {
    newRules.eligibilityRows = newRules.eligibilityRows.map((item) => ({
      ...item,
      uid: addUuidsToLinkedRewardsItems(item),
      variable: item.variable ? item.variable.replace('Reward.', '') : undefined,
      eligibilityRows: item.eligibilityRows
        ? item.eligibilityRows.map((child) => ({
            ...child,
            variable: child.variable ? child.variable.replace('Reward.', '') : undefined,
          }))
        : null,
    }));
  }

  return newRules;
};

const mapThresholdToModel = (thresholds) =>
  thresholds.map((threshold) => {
    const data = R.clone(threshold);
    const rows = R.prop('thresholdRows', data);
    if (R.isEmpty(rows) || R.isNil(rows)) return threshold;

    data.thresholdRows = data.thresholdRows.map((item) => thresholdInteraction(item));
    return data;
  });

const emptyComplexObject = {
  activeType: ACTIVE_TYPES.fixed,
  [ACTIVE_TYPES.fixed]: {
    contribution: [],
    minimum: [],
    maximum: [],
  },
  [ACTIVE_TYPES.percentage]: {
    contribution: [],
    additionalFormulaItems: [],
    minimum: [],
    maximum: [],
  },
};

const buildFixedFormula = (rule, template) => {
  const minimum = R.path(['outputInterval', 'minimum'], rule);
  const maximum = R.path(['outputInterval', 'maximum'], rule);
  return {
    activeType: ACTIVE_TYPES.fixed,
    [ACTIVE_TYPES.fixed]: {
      contribution:
        toLower(template) === TEMPLATE_TYPES.share && !checkIsTextOnly(rule.formulaItems)
          ? addUuidsToFormulaItems(rule.formulaItems)
          : [],
      minimum: minimum ? addUuidsToFormulaItems(minimum) : [],
      maximum: maximum ? addUuidsToFormulaItems(maximum) : [],
    },
    [ACTIVE_TYPES.percentage]: {
      ...emptyComplexObject[ACTIVE_TYPES.percentage],
    },
  };
};

const buildPercentageFormula = (rule) => {
  const minimum = R.path(['outputInterval', 'minimum'], rule);
  const maximum = R.path(['outputInterval', 'maximum'], rule);
  return {
    activeType: ACTIVE_TYPES.percentage,
    [ACTIVE_TYPES.fixed]: {
      ...emptyComplexObject[ACTIVE_TYPES.fixed],
    },
    [ACTIVE_TYPES.percentage]: {
      contribution: addUuidsToFormulaItems(rule.formulaItems),
      additionalFormulaItems: addUuidsToFormulaItems(rule.additionalFormulaItems),
      minimum: minimum ? addUuidsToFormulaItems(minimum) : [],
      maximum: maximum ? addUuidsToFormulaItems(maximum) : [],
    },
  };
};

export const mapRulesToRequestModel = (rules, template) =>
  rules.map((rule) => {
    const payload = {
      ...rule,
      key: uuid(),
      eligibility: R.merge({}, addUuidsToEligibilityItems(rule.eligibility)),
      additionalRewardsEligibility: R.merge(
        {},
        addUuidsToLinkedRewardsItems(rule.additionalRewardsEligibility)
      ),
      threshold: rule.thresholds ? mapThresholdToModel(rule.thresholds) : [],
    };

    if (toLower(template) === TEMPLATE_TYPES.share) {
      payload.formula = checkIsTextOnly(rule.formulaItems)
        ? addUuidsToFormulaItems(rule.formulaItems)
        : [];
      payload.complex = rule.additionalFormulaItems
        ? buildPercentageFormula(rule)
        : buildFixedFormula(rule, template);
    } else {
      payload.formula = addUuidsToFormulaItems(rule.formulaItems);
      payload.complex = buildFixedFormula(rule, template);
    }

    delete payload.formulaItems;
    delete payload.additionalFormulaItems;
    delete payload.outputInterval;

    return payload;
  });

export const mapResponseToBasicInfoModel = (reward) => {
  const recurrenceFrequency = R.path(['recurrence', 'frequency'], reward);
  const recurrenceCount = R.path(['recurrence', 'every'], reward);
  const fxRateCustomDate = R.path(['foreignExchange', 'fixedCustomDate'], reward);
  const model = {
    country: reward.country,
    currency: reward.currencyCode,
    fxSource: R.path(['foreignExchange', 'sourceId'], reward),
    fxRateDate: R.path(['foreignExchange', 'fixedDateVariableName'], reward),
    fxRateCustomDate: fxRateCustomDate || '',
    template: reward.template,
    displayName: reward.displayName,
    description: reward.description,
    descriptionBanner: reward.descriptionBanner,
    descriptionMedia: reward.descriptionMedia,
    category: R.path(['category', 'level1Id'], reward),
    subcategory: R.path(['category', 'level2Id'], reward),
    type: R.path(['category', 'level3Id'], reward),
    includeInEmployeeView: R.path(['settings', 'includeInEmployeeView'], reward),
    includeInTheOverallTotal: R.path(['settings', 'includeInTheOverallTotal'], reward),
    taxability: R.path(['settings', 'mobilityMappingId'], reward),
    effectiveDate: reward.effectiveDate,
    status: reward.status,
    recurrence: isBlank(reward.recurrence) ? 'no' : 'yes',
    recurrenceCount: recurrenceCount || null,
    recurrenceFrequency: recurrenceFrequency && RECURRENCE_OPTIONS[recurrenceFrequency - 1],
    taxableReward: R.path(['settings', 'taxableOption'], reward),
    contributionType: R.path(['settings', 'isGross'], reward) ? 'gross' : 'net',
    isInternal: R.path(['settings', 'isInternal'], reward),
    expiryDate:
      !isBlank(reward.expiryDate) && reward.expiryDate.indexOf('9999') > -1
        ? null
        : reward.expiryDate,
  };

  if (R.path(['fxRateDate'], model) === null && R.path(['fxRateCustomDate'], model)) {
    model.fxRateDate = FX_RATE_DATE_CUSTOM.value;
  }

  return model;
};

export const transformRewardsList = (data, authorizedModules) => {
  if (isBlank(data)) return [];

  return data.map((item) => {
    const row = item;
    const hasLocal = hasLocalAccess(authorizedModules, row.country);
    const isShareReward = toLower(row.template) === TEMPLATE_TYPES.share;
    const isDraftReward = toLower(row.status) === REWARD_STATUSES.draft;
    const isEditable = !hasLocal || (isShareReward && isDraftReward);
    row.statusIconName = `${getStatusByScore(row.feedbackScore)}`;
    row.menuListProps = {
      viewFeedback: {
        disabled: !row.hasFeedback,
      },
    };
    row.score = convertDecimalToPercentage(item.feedbackScore);

    return {
      ...row,
      displayName: row.displayName,
      menuTransform: (menuList, dataItem) => {
        let menu = menuList;
        const feedbackIndex = R.findIndex(R.propEq('key', 'viewFeedback'))(menuList);
        if (feedbackIndex !== -1) {
          const feedbackMenuItem = menu[feedbackIndex];
          feedbackMenuItem.icon = contextMenuIcon;
          feedbackMenuItem.link = `/rewards/feedback/${dataItem.id}`;
          menu[feedbackIndex] = feedbackMenuItem;
        }
        menu = !isEditable ? R.remove(0, 1, menu) : menu;
        return menu;
      },
    };
  });
};
