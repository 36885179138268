"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
var StyledStepperValue = _styledComponents["default"].div.withConfig({
  displayName: "StepperValuestyle__StyledStepperValue",
  componentId: "sc-8aniww-0"
})([".share-view-label{margin-right:20px;vertical-align:top;&.share-view-label-member{margin-left:20px;}}.stepper-button-add{width:100%;margin-top:32px;border-style:dotted;}"]);
StyledStepperValue.displayName = 'StyledStepperValue';
var _default = StyledStepperValue;
exports["default"] = _default;