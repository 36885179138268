export default {
  EXECUTIVE_REPORT_PAGE_TITLE: 'Executive Report',
  EXECUTIVE_REPORT_EXPORT: 'Export to Excel',
  EXECUTIVE_REPORT_TABLE_VIEW: 'Table View',
  EXECUTIVE_REPORT_CHART_VIEW: 'Chart View',
  EXECUTIVE_REPORT_CURRENCY: 'Currency',
  EXECUTIVE_REPORT_SUMMARY_GET_ERROR:
    'We have encountered an error while getting the summary for the executive report.',
  EXECUTIVE_REPORT_ALL_CATEGORIES: 'All Categories',
  EXECUTIVE_REPORT_COUNTRY_TAB: 'Country',
  EXECUTIVE_REPORT_JOB_LEVEL_TAB: 'Level',
  EXECUTIVE_REPORT_SITE_TAB: 'Site',
  EXECUTIVE_REPORT_TOOLTIP_TOTAL: 'Total: ',
  EXECUTIVE_REPORT_DETAILS_GET_ERROR: 'We have encountered an error while getting the details data',
  EXECUTIVE_REPORT_CURRENCIES_GET_ERROR:
    'We have encountered an error while getting the currency data',
  EXECUTIVE_REPORT_LOADING_DATA: 'Loading Executive Report',
  EXECUTIVE_REPORT_LOADING_GENERIC: 'Loading Data',
  EXECUTIVE_REPORT_ERROR_TITLE: 'Executive report page load error',
  EXECUTIVE_REPORT_ERROR_BODY:
    'We have encountered an error while loading the executive report. Please try again later or contact support.',
  EXECUTIVE_REPORT_ERROR_SUBTITLE: 'Error Body:',
};
