export default {
  GLOBAL_SETUP_TITLE: 'Global Settings for %YEAR%',
  REFERENCE_DATA_SECTION_TITLE: 'Reference Data',
  POPULATION_SECTION_TITLE: 'Population',
  POPULATION_ADD_NEW: 'Add new population',
  POPULATION_NAME_FIELD: 'Population name',
  PAYSCALE_MIN_LABEL: 'Min Pay Point',
  PAYSCALE_MAX_LABEL: 'Max Pay Point',
  PAYSCALE_MIN_PLACEHOLDER: 'Min PP',
  PAYSCALE_MAX_PLACEHOLDER: 'Max PP',
  REF_DATA_FX_SOURCE: 'FX Source',
  REF_DATA_FX_SOURCE_PLACEHOLDER: 'Select FX Source',
  REF_DATA_FX_RATE_DATE: 'FX Rate Date',
  REF_DATA_FX_RATE_DATE_PLACEHOLDER: 'Select FX Rate Date',
  REF_DATA_AGEING_DATE: 'Ageing Date',
  REF_DATA_AGEING_DATE_PLACEHOLDER: 'Select Ageing Date',
  SAVE_ERROR: 'Your changes could not be saved.',
  SAVE_SUCCESS: 'Your changes have been saved successfully!',
  NAME_UNIQUE: 'Population name should be unique',
  SUBMIT_GLOBAL: 'Submit Global Settings',
  SUBMIT_GLOBAL_SUCCESS: 'Global Settings were submitted successfully!',
  SUBMIT_GLOBAL_SUBTITLE: 'Are you sure you want to submit the Global Settings?',
};
