import styled, { css } from 'styled-components';
import { media } from '@trs/utils';
import TOTAL_VARIANTS from '../constants';

const StyledTotal = styled.div`
  ${props => {
    const { spacing, palette: color, typography } = props.theme;
    const { variant } = props;

    return css`
      background-color: ${color.secondary};
      color: ${color.basic.white};
      padding: ${spacing.small};
      padding-left: ${spacing.smallPlus};
      display: flex;
      justify-content: space-between;
      .statement-row-title {
        font-size: ${typography.size.table};

        ${media.mobileLandscape`
            font-size: ${typography.paragraphPlus};
          `}
      }
      ${variant === TOTAL_VARIANTS.naked &&
        css`
          color: ${color.gray.alpha};
          background-color: ${color.basic.white};
          padding: ${spacing.small} 0;
          && .statement-row-title {
            ${media.mobileLandscape`
              font-size: ${typography.size.base};
            `}
            font-weight: ${typography.weight.semiBold};
          }
        `}
      ${variant === TOTAL_VARIANTS.subtotal &&
        css`
          opacity: 0.55;
          .statement-row-title {
            font-size: ${typography.size.base};
          }
        `}
        ${variant === TOTAL_VARIANTS.regular &&
          css`
            .statement-row-title {
              font-size: 18px;
            }
          `}
    `;
  }};
`;

StyledTotal.displayName = 'StyledTotal';
export default StyledTotal;
