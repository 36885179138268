import { createAction } from 'redux-actions';
import * as R from 'ramda';
import { benchmark } from '../../../../../../../../../config/api';
import { GET } from '../../../../../../../../../config/http';
import { isNoContent, HEATMAP_CALC_STATUS } from '../helpers';
import actionTypes from './types';

export { loadPremerit, calculateMerit } from './permitAction';

export const finishCalculate = createAction(actionTypes.BENCHMARKING_FINISH_CALCULATE);
export const meritReset = createAction(actionTypes.BENCHMARKING_MERIT_RESET);

export const getPrevious = createAction(actionTypes.BENCHMARKING_GET_PREVIOUS);

export const loadPreviousMerit = () => (dispatch, getState) => {
  const state = getState();
  const globalId = R.path(['benchmarking', 'global', 'main', 'identifiers', 'id'], state);
  const localId = R.path(['benchmarking', 'local', 'main', 'statusData', 'id'], state);

  const uri = `${benchmark.base}/benchmarking/${globalId}/localSettings/${localId}/previous/calculations`;

  return dispatch(
    getPrevious(
      GET(uri).then((response) => {
        const responseStatus = R.prop('status', response);
        const calculationStatus = R.path(['data', 'response', 'metadata', 'status'], response);
        if (isNoContent(responseStatus) || calculationStatus !== HEATMAP_CALC_STATUS.COMPLETED) {
          return {
            data: {},
            metadata: {},
          };
        }
        const metadata = R.path(['data', 'response', 'metadata'], response);
        return {
          data: R.path(['data', 'response', 'payload'], response),
          metadata,
        };
      })
    )
  );
};
