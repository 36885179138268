import * as R from 'ramda';
import { lowerFirstLetter } from '@trs/utils';
import { FEEDBACK_MOODS } from '../../../../../../feedback/constants';

const constructFeedbackObject = obj => {
  const newObject = {};

  R.forEachObjIndexed((value, sentiment) => {
    newObject[FEEDBACK_MOODS[lowerFirstLetter(sentiment)]] = value;
  }, obj);

  return newObject;
};

export default constructFeedbackObject;
