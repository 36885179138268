import processBlock from './processBlock';

export default {
  PAGE_TITLE: 'Workspace',
  TABLE_NAME_LABEL: 'Name',
  TABLE_LAST_UPDATED_LABEL: 'Last updated',
  TABLE_SIZE_LABEL: 'Size',
  TABLE_NO_RESULTS: "There aren't any files in this directory.",
  FILE_OVERWRITE_WARNING:
    'Warning! A file with the same name already exists in this directory. The file you are trying to upload will overwrite the existing file.',
  FILE_UPLOAD_SUCCESS: 'File upload has started.',
  WORKSPACE_FILE_LABEL: 'Workspace file',
  ...processBlock,
};
