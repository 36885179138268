import { createAction } from 'redux-actions';
import * as R from 'ramda';
import { rewards } from '../../../../../config/api';
import { GET } from '../../../../../config/http';

const actionTypes = {
  FETCH_VERSIONS_PENDING: 'FETCH_VERSIONS_PENDING',
  FETCH_VERSIONS_SUCCESS: 'FETCH_VERSIONS_SUCCESS',
  FETCH_VERSIONS_ERROR: 'FETCH_VERSIONS_ERROR',
  VERSIONING_STORE_RESET: 'VERSIONING_STORE_RESET',
};

export const fetchVersionsPending = createAction(actionTypes.FETCH_VERSIONS_PENDING);
export const fetchVersionsSuccess = createAction(actionTypes.FETCH_VERSIONS_SUCCESS);
export const fetchVersionsError = createAction(actionTypes.FETCH_VERSIONS_ERROR);
export const versioningStoreReset = createAction(actionTypes.VERSIONING_STORE_RESET);

export function loadVersions({ country, groupId }) {
  return dispatch => {
    dispatch(fetchVersionsPending());
    return GET(`${rewards.versioning}/${country}/${groupId}`, { Pragma: 'no-cache' })
      .then(payload => {
        dispatch(fetchVersionsSuccess({ versions: R.path(['data', 'response'], payload) }));
      })
      .catch(() => {
        dispatch(fetchVersionsError());
      });
  };
}
