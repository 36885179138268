import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Paragraph, Button } from '@trs/components';
import * as R from 'ramda';
import NotificationHeader from '../header/NotificationHeader';
import Text, { TextOnly } from '../../../common/Text';
import { reports } from '../../../../config/api';
import DownloadLink from '../../../common/DownloadLink';
import StyledReport from './Report.style';

const Report = ({ item, markNotification, onFileDownload, onFileDownloaded }) => {
  const name = R.prop('name', item);
  return (
    <StyledReport>
      <NotificationHeader createdDate={R.prop('createdDate', item)} title={name} />
      <Paragraph className="notification-content">
        <Text path="notifications.REPORT_EXPORTED" />
      </Paragraph>
      <Paragraph className="download-link">
        <DownloadLink
          className="link"
          id={`download-${name}`}
          type="link"
          capitalization="capitalize"
          beforeHook={() =>
            onFileDownload(
              TextOnly({
                path: 'generic.DOWNLOAD_IN_PROGRESS',
                transform: label => label.replace('%FILENAME%', name),
              })
            )
          }
          action={({ err }) => {
            markNotification(R.prop('name'), item);
            onFileDownloaded(
              err,
              TextOnly({ path: 'generic.ERROR_DOWNLOADING_CSV_EXPORT' }),
              TextOnly({
                path: 'generic.DOWNLOAD_FINISHED',
                transform: label => label.replace('%FILENAME%', name),
              })
            );
          }}
          href={`${reports.exportReport}/${encodeURIComponent(name)}`}
          download={name}
        >
          <Text path="notifications.DOWNLOAD_REPORT" />
        </DownloadLink>
        {!item.isRead && (
          <Button
            type="link"
            className="notification-link"
            action={() => markNotification(item.id)}
          >
            <Text path="notifications.MARK_AS_READ" />
          </Button>
        )}
      </Paragraph>
    </StyledReport>
  );
};

Report.propTypes = {
  item: PropTypes.shape({
    typeId: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    isRead: PropTypes.bool.isRequired,
    content: PropTypes.shape({}).isRequired,
    name: PropTypes.string.isRequired,
    createdDate: PropTypes.string.isRequired,
  }).isRequired,
  markNotification: PropTypes.func.isRequired,
  onFileDownload: PropTypes.func.isRequired,
  onFileDownloaded: PropTypes.func.isRequired,
};

export default memo(Report);
