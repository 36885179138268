import React, { memo, Fragment } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { Paragraph, Button } from '@trs/components';
import Text, { TextOnly } from '../Text';
import DownloadLink from '../DownloadLink';
import NotificationHeader from '../../notifications/components/header/NotificationHeader';
import StyledFileDownloadNotification from './FileDownloadNotification.style';

const FileDownloadNotification = ({
  isError,
  title,
  content,
  notificationId,
  notificationDate,
  markNotification,
  onFileDownload,
  onFileDownloaded,
  downloadLink,
  name,
  item,
}) => (
  <StyledFileDownloadNotification>
    <NotificationHeader createdDate={notificationDate} title={title} isError={isError} />
    <Paragraph className="notification-content">
      {isError ? (
        <Fragment>
          <Text path="generic.BANNER_FILE_ERROR_GENERIC" />
          {!item.isRead && (
            <Button
              type="link"
              className="notification-link"
              action={() => markNotification(notificationId)}
            >
              <Text path="notifications.MARK_AS_READ" />
            </Button>
          )}
        </Fragment>
      ) : (
        <Fragment>
          {content || <Text path="notifications.REPORT_EXPORTED" />}
          <DownloadLink
            id={`download-${notificationId}`}
            type="link"
            className="notification-link"
            beforeHook={() => {
              const file = TextOnly({ path: 'generic.FILE' });
              onFileDownload(
                TextOnly({
                  path: 'generic.DOWNLOAD_IN_PROGRESS',
                  transform: label => label.replace('%FILENAME%', file),
                })
              );
            }}
            action={({ filename, err, ...props }) => {
              markNotification(notificationId);
              onFileDownloaded(
                err,
                TextOnly({ path: 'generic.ERROR_DOWNLOAD' }),
                TextOnly({
                  path: 'generic.DOWNLOAD_FINISHED',
                  transform: label =>
                    label.replace('%FILENAME%', name || filename || R.prop('name', props)),
                })
              );
            }}
            href={downloadLink}
            download={name}
          >
            <Text path="notifications.DOWNLOAD_REPORT" />
          </DownloadLink>
          {!item.isRead && (
            <Button
              type="link"
              className="notification-link"
              action={() => markNotification(notificationId)}
            >
              <Text path="notifications.MARK_AS_READ" />
            </Button>
          )}
        </Fragment>
      )}
    </Paragraph>
  </StyledFileDownloadNotification>
);

FileDownloadNotification.defaultProps = {
  content: '',
  isError: false,
  onFileDownload: () => {},
  onFileDownloaded: () => {},
  name: '',
};

FileDownloadNotification.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  notificationId: PropTypes.string.isRequired,
  notificationDate: PropTypes.string.isRequired,
  downloadLink: PropTypes.string.isRequired,
  markNotification: PropTypes.func.isRequired,
  onFileDownload: PropTypes.func,
  onFileDownloaded: PropTypes.func,
  isError: PropTypes.bool,
  name: PropTypes.string,
  item: PropTypes.shape({
    isRead: PropTypes.bool.isRequired,
  }).isRequired,
};

export default memo(FileDownloadNotification);
