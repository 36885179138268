"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "SearchableDeprecated", {
  enumerable: true,
  get: function get() {
    return _Searchable2["default"];
  }
});
Object.defineProperty(exports, "default", {
  enumerable: true,
  get: function get() {
    return _Searchable["default"];
  }
});
var _Searchable = _interopRequireDefault(require("./Searchable"));
var _Searchable2 = _interopRequireDefault(require("./Searchable.DEPRECATED"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }