export default {
  ADD_MEDIA: 'Click to add media',
  EMBED_LINK: 'Embed link',
  ENTER_LINK_HERE: 'Enter link here',
  IMAGE_RECOMMENDED_SIZE: 'Recommended size',
  ENTER_TEXT_HERE: 'Enter text here',
  MEDIA_IMAGE_ALT_TEXT: 'Alt text',
  MEDIA_VIDEO_TITLE: 'Title',
  CANCEL_MEDIA_LINK: 'Cancel',
  ADD_MEDIA_LINK: 'Add',
  TEXT_PLACEHOLDER: 'Type your description here',
};
