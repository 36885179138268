/* eslint-disable */
import * as R from 'ramda';
import types from '../actions/types';
import {
  NEW_CATEGORY_ID_PREFIX,
  appendNewCategory,
  updateCategoryValue,
  discardChanges,
  deleteCategory,
  getSiblingsCategories,
  getFieldError,
  cleanUpCategories,
  updateOrderIndex,
} from '../helpers';

export const initialState = {
  pending: false,
  categories: [],
  categoryIdOnEditMode: null,
  hasError: false,
  hasUpdates: false,
};

export default function rewardsCategoriesReducer(state = initialState, action) {
  switch (action.type) {
    case types.REWARDS_CONFIGURATION_GET_CATEGORIES_PENDING: {
      return {
        ...state,
        pending: true,
        hasUpdates: false,
      };
    }
    case types.REWARDS_CONFIGURATION_GET_CATEGORIES: {
      return {
        ...state,
        pending: false,
        categories: action.payload,
      };
    }
    case types.ADD_MAIN_CATEGORY: {
      return {
        ...state,
        hasUpdates: true,
        categories: [
          ...state.categories,
          {
            categories: [],
            editMode: true,
            /** temporary ID */
            id: `${NEW_CATEGORY_ID_PREFIX}${Math.floor(Math.random() * 1000)}`,
            displayName: '',
            isUsed: '-',
            isDefault: false,
            level: 0,
            orderIndex: R.prop('orderIndex', state.categories[state.categories.length - 1]) + 1,
          },
        ],
      };
    }
    case types.ADD_CATEGORY: {
      const { id } = action.payload;
      const { categoryIdOnEditMode, hasError } = state;
      let categoriesUpdated = state.categories;

      if (categoryIdOnEditMode) {
        if (hasError) {
          if (categoryIdOnEditMode.startsWith(NEW_CATEGORY_ID_PREFIX)) {
            categoriesUpdated = deleteCategory(categoryIdOnEditMode, categoriesUpdated);
          } else {
            categoriesUpdated = discardChanges(categoryIdOnEditMode, categoriesUpdated);
          }
        } else {
          categoriesUpdated = updateCategoryValue(
            categoryIdOnEditMode,
            'editMode',
            false,
            categoriesUpdated
          );
        }
      }

      categoriesUpdated = appendNewCategory(id, categoriesUpdated);

      return {
        ...state,
        categories: categoriesUpdated,
        categoryIdOnEditMode: id,
        hasUpdates: true,
      };
    }
    case types.EDIT_CATEGORY: {
      const { id } = action.payload;
      const { categoryIdOnEditMode, hasError } = state;
      let categoriesUpdated = state.categories;

      if (categoryIdOnEditMode) {
        if (hasError) {
          if (categoryIdOnEditMode.startsWith(NEW_CATEGORY_ID_PREFIX)) {
            categoriesUpdated = deleteCategory(categoryIdOnEditMode, categoriesUpdated);
          } else {
            categoriesUpdated = discardChanges(categoryIdOnEditMode, categoriesUpdated);
          }
        } else {
          /** update previous category which was on edit mode */
          categoriesUpdated = updateCategoryValue(
            categoryIdOnEditMode,
            'editMode',
            false,
            categoriesUpdated
          );
        }
      }
      /** update category to be on edit mode */
      categoriesUpdated = updateCategoryValue(id, 'editMode', true, categoriesUpdated);

      return {
        ...state,
        categoryIdOnEditMode: id,
        categories: categoriesUpdated,
        hasUpdates: true,
      };
    }
    case types.DELETE_CATEGORY: {
      const { id } = action.payload;
      const categories = deleteCategory(id, state.categories);

      return {
        ...state,
        categoryIdOnEditMode: null,
        categories,
        hasUpdates: true,
      };
    }
    case types.ON_EDIT_CATEGORY: {
      const { id, value } = action.payload;
      const categories = updateCategoryValue(id, 'displayName', value, state.categories);

      return {
        ...state,
        categories,
        categoryIdOnEditMode: id,
      };
    }
    case types.ON_VALIDATE_FIELD: {
      const { id, value, ongoingEditing } = action.payload;

      let categoriesUpdated = state.categories;

      const skipEmptyCheck = id.startsWith(NEW_CATEGORY_ID_PREFIX);

      if (R.isEmpty(R.trim(value)) && !ongoingEditing) {
        /** if field is empty and it's a new category, delete it */
        if (skipEmptyCheck) {
          categoriesUpdated = deleteCategory(id, categoriesUpdated);
        } else {
          /** if field is empty and it's an old category, discardChanges */
          categoriesUpdated = discardChanges(id, categoriesUpdated);
        }

        return {
          ...state,
          categories: categoriesUpdated,
          hasError: false,
        };
      }
      const siblingsCategories = getSiblingsCategories(id, state.categories);
      const fieldErr = getFieldError(value, siblingsCategories, skipEmptyCheck);

      categoriesUpdated = updateCategoryValue(id, 'error', fieldErr, categoriesUpdated);

      if (R.isNil(fieldErr) && !ongoingEditing) {
        categoriesUpdated = updateCategoryValue(id, 'editMode', false, categoriesUpdated);
      }

      return {
        ...state,
        categories: categoriesUpdated,
        hasError: !R.isNil(fieldErr),
      };
    }
    case types.SUBMIT_REWARDS_CATEGORIES_PENDING: {
      return {
        ...state,
        pending: true,
      };
    }
    case types.SUBMIT_REWARDS_CATEGORIES_SUCCESS: {
      const categories = cleanUpCategories(state.categories);

      return {
        ...state,
        pending: false,
        categories,
        hasUpdates: false,
      };
    }
    case types.SUBMIT_REWARDS_CATEGORIES_ERROR: {
      return {
        ...state,
        pending: false,
      };
    }
    case types.RESET_CATEGORIES: {
      return initialState;
    }
    case types.ON_UPDATE_ORDER: {
      const categories = updateOrderIndex(action.payload.categories);

      return {
        ...state,
        hasUpdates: true,
        categories,
      };
    }
    default: {
      return state;
    }
  }
}
