"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "MediaObject", {
  enumerable: true,
  get: function get() {
    return _MediaObject["default"];
  }
});
exports["default"] = void 0;
var _Template = _interopRequireDefault(require("./Template"));
var _MediaObject = _interopRequireDefault(require("./MediaObject"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
var _default = _Template["default"];
exports["default"] = _default;