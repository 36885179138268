export default {
  PAGE_TITLE: 'Reward Package',
  PAGE_SUBTITLE2:
    'The information asked in simulations is dynamic. The fields which are asked, depend on the rewards existing in the system.',
  PAGE_SUBTITLE: `Welcome to the MyReward Package Modelling Tool!<br>
    If you’re stuck on something and would like to refer back to the Training, please click on the Degreed Training link here, scrolling down to Section 6.
    <br>Please do not model the following counties due to hyper-inflation: Venezuela and Zimbabwe.
    <br>Also note that for GM packages, only model STAs or Global Assignees and for STA’s Home Based, please select Home country, and for Policy Type please enter “NA”
    <br>In addition, the Location Allowance value for STAs is still in progress, so please contact GM for this value.
    <br>We hope you enjoy the tool.`,
  MODEL_LABEL: 'Model',
  OUTPUT_LABEL: 'Output',
  CARD_TITLE: 'PROFILE %NR%',
  CARD_INFO:
    'You can model a new employee or start by searching an existing employee or standard profile.',
  CARD_INFO_LINK: 'Search employee',
  CALL_TO_ACTION_SEARCH:
    'Once you have input the basic profile above please %ACTION% and complete the detailed profile',
  CALL_TO_ACTION_SEARCH_WARNING:
    'If you change one of the fields from above, the information below will be removed.',
  ANNUAL_SALARY_CAPTION_INFO: 'The annual salary currency is %FIELD%',
  FTE_CAPTION_INFO:
    'By changing the FTE the Annual salary will adjust accordingly. The allowances amount will adjust when you click "Model"',
  COMPARE_LABEL: 'COMPARE',
  VIEW_VALUESIN: 'Show values in',

  VIEW_VALUESIN_OPTION_GROSS: 'Gross',
  VIEW_VALUESIN_OPTION_NET: 'Net',
  VIEW_VALUESIN_OPTION_BOTH: 'Both',
  VIEW_PERIOD: 'Assignment period',
  VIEW_PERIOD_OPTION_TOTAL: 'Total',
  VIEW_PERIOD_OPTION_AVERAGE: 'Average',
  VIEW_PERIOD_OPTION_YEAR: 'Year(s)',
  VIEW_PERIOD_OPTION_YEAR_EXPORT: 'Year',
  VIEW_PERIOD_OPTION_YEAR_ERROR: 'At least one year must be selected.',
  VIEW_TYPE: 'View type',
  VIEW_TYPE_OPTION_ADMIN: 'Admin',
  VIEW_TYPE_OPTION_EMPLOYEE: 'Employee',
  STATEMENT_DETAILS: 'Statement details',
  COMPARE_STATEMENTS: 'Compare statements',
  STATEMENT_INSTANCE: 'Statement %FIELD%',
  GROSS: 'Gross',
  NET: 'Net',
  DELTA: 'Delta',
  STATEMENT_CATEGORY_TOTAL: 'Total %FIELD%',
  STATEMENT_TOTAL_REWARDS: 'Total Rewards',
  STATEMENT_CATEGORY_EXTRA: '%FIELD% (EXTRA)',
  STATEMENT_TOTAL_EXTRA: 'Total Extra',
  STATEMENT_TOTAL_TAXES: 'Total Taxes',
  STATEMENT_TAXES: 'TAXES',
  STATEMENT_EMPLOYER_TAXES: 'Employer taxes',
  STATEMENT_OVERALL_TOTAL: 'OVERALL TOTAL',
  STATEMENT_TEXT_REWARDS: 'TEXT REWARDS',
  STATEMENT_TAXES_RATES: 'TAXES RATES',
  STATEMENT_TAXES_GROSS: 'Gross item rate',
  STATEMENT_TAXES_NET: 'Net item rate',
  STATEMENT_TAXES_BLENDED: 'Blended rate',
  OUTPUT_ERROR: 'An error has occured when trying to get rewards available for the simulation.',
  FX_OUTOUT_ERROR:
    'Rewards in the statement cannot be displayed because there is no effective exchange rate to convert %FIELD1% to %FIELD2% available in the system.',
  BACK_BUTTON: 'BACK',
  NO_REWARDS: 'No rewards available for the simulation',
  FILE_NAME_PLACEHOLDER: 'Enter file name',
  CONFIGURATION_PAGE_TITLE: 'Reward Package Configuration',
  IMPACT_ANALYSIS: 'Impact Analysis',
  COLUMN_CUSTOM_TYPES: 'Custom Types',
  COLUMN_INCLUDED: 'Included',
  COLUMN_NOT_INCLUDED: 'Not Included',
  SAVE: 'Save',
  MODEL_REWARD: 'Model %REWARD_NAME%',
  MODEL_DIMENSIONS_RECALCULATING:
    'Pay Scales data is recalculating. You will be notified when it is ready.',
};
