const userModules = {
  AUDIT: 'AUDIT',
  EMPLOYEE_DASHBOARD: 'EMPLOYEE_DASHBOARD',
  EXCEPTIONS_MANAGEMENT: 'EXCEPTIONS_MANAGEMENT',
  FLEXIBLE_MANAGEMENT: 'FLEXIBLE_MANAGEMENT',
  BENEFITS_ENVELOPE: 'BENEFITS_ENVELOPE',
  FX_MANAGEMENT: 'FX_MANAGEMENT',
  MIMIC_EMPLOYEE: 'MIMIC_EMPLOYEE',
  MODELLER: 'MODELLER',
  CONFIGURATION: 'CONFIGURATION',
  REPORTS: 'REPORTS',
  REWARDS_MANAGEMENT: 'REWARDS_MANAGEMENT',
  ARTICLES_MANAGEMENT: 'ARTICLES_MANAGEMENT',
  USER_MANAGEMENT: 'USER_MANAGEMENT',
  SETTINGS: 'SETTINGS',
  FEEDBACK_STATS: 'FEEDBACK_STATS',
  WORKSPACE: 'WORKSPACE',
  DATA_VALIDATION: 'DATA_VALIDATION',
  BENCHMARKING: 'BENCHMARKING',
  FIXED_COSTS_MANAGEMENT: 'FIXED_COSTS_MANAGEMENT',
  LTI_PLANS_MANAGEMENT: 'LTI_PLANS_MANAGEMENT',
};

export const routes = [
  {
    route: '/',
    access: [
      userModules.AUDIT,
      userModules.MIMIC_EMPLOYEE,
      userModules.REWARDS_MANAGEMENT,
      userModules.REPORTS,
      userModules.MODELLER,
      userModules.FLEXIBLE_MANAGEMENT,
      userModules.EXCEPTIONS_MANAGEMENT,
      userModules.USER_MANAGEMENT,
      userModules.SETTINGS,
      userModules.FX_MANAGEMENT,
      userModules.ARTICLES_MANAGEMENT,
      userModules.FIXED_COSTS_MANAGEMENT,
      userModules.LTI_PLANS_MANAGEMENT,
    ],
  },
  {
    route: '/home',
    access: [
      userModules.AUDIT,
      userModules.MIMIC_EMPLOYEE,
      userModules.REWARDS_MANAGEMENT,
      userModules.REPORTS,
      userModules.FLEXIBLE_MANAGEMENT,
      userModules.EXCEPTIONS_MANAGEMENT,
      userModules.USER_MANAGEMENT,
      userModules.SETTINGS,
      userModules.FX_MANAGEMENT,
      userModules.ARTICLES_MANAGEMENT,
      userModules.FIXED_COSTS_MANAGEMENT,
      userModules.LTI_PLANS_MANAGEMENT,
    ],
  },
  {
    route: '/articles',
    access: [userModules.ARTICLES_MANAGEMENT],
  },
  {
    route: '/rewards',
    access: [userModules.REWARDS_MANAGEMENT],
  },
  {
    route: '/rewards/view/:rewardId/impacts/view/:impactId',
    access: [userModules.MODELLER, userModules.REWARDS_MANAGEMENT],
  },
  {
    route: '/rewards/edit/:rewardId/impacts/view/:impactId',
    access: [userModules.MODELLER, userModules.REWARDS_MANAGEMENT],
  },
  {
    route: '/variables',
    access: [userModules.REWARDS_MANAGEMENT],
  },
  {
    route: '/exceptions',
    access: [userModules.EXCEPTIONS_MANAGEMENT],
  },
  {
    route: '/foreign-exchange',
    access: [userModules.FX_MANAGEMENT],
  },
  {
    route: '/flexible-rewards',
    access: [userModules.FLEXIBLE_MANAGEMENT],
  },
  {
    route: '/benefits-envelope',
    access: [userModules.BENEFITS_ENVELOPE],
  },
  {
    route: '/benefits-envelope-audit',
    access: [userModules.BENEFITS_ENVELOPE, userModules.MIMIC_EMPLOYEE],
  },
  {
    route: '/flexible-rewards/enrolment-window',
    access: [userModules.FLEXIBLE_MANAGEMENT],
  },
  {
    route: '/model',
    access: [userModules.MODELLER],
  },
  {
    route: '/reward-impact',
    access: [userModules.MODELLER],
  },
  {
    route: '/report',
    access: [userModules.REPORTS],
  },
  {
    route: '/workspace',
    access: [userModules.WORKSPACE],
  },
  {
    route: '/plan',
    access: [userModules.BENCHMARKING],
  },
  {
    route: '/users',
    access: [userModules.USER_MANAGEMENT],
  },
  {
    route: '/settings',
    access: [userModules.SETTINGS],
  },
  {
    route: '/employee-statement',
    access: [userModules.EMPLOYEE_DASHBOARD],
  },
  {
    route: '/fixed-costs',
    access: [userModules.FIXED_COSTS_MANAGEMENT],
  },
  {
    route: '/lti-plans',
    access: [userModules.LTI_PLANS_MANAGEMENT],
  },
  {
    route: '/logout',
    access: [],
  },
];

export default userModules;
