import * as R from 'ramda';
import { ARTICLE_LIST_RESULTS_PER_PAGE } from 'modules/articles/constants';

export const mapEligibilityOperators = (payload) =>
  payload.map(({ displayName, name, allowsMultipleSelect }) => ({
    value: name,
    label: displayName,
    allowsMultipleSelect,
  }));

export const mapSystemVariables = (payload) => {
  let systemVariables = payload.map(({ displayName, propertyName, type, valuesUrl }) => ({
    value: propertyName,
    label: displayName,
    type,
    valuesUrl,
  }));

  systemVariables = R.sortBy(R.prop('value'))(systemVariables);
  return systemVariables;
};

export const getLookupData = (lookupData, url) => {
  const lookUpValues = {};
  lookUpValues[url] = [];

  if (lookupData) {
    lookupData.map((item) =>
      lookUpValues[url].push({
        label: item,
        value: item,
      })
    );
    lookUpValues[url] = R.sortBy(R.prop('value'))(lookUpValues[url]);
  }

  return lookUpValues;
};

export const processEligibilityChange = (stateRules, payload) => {
  const rules = stateRules;

  rules[payload.index] = {
    ...rules[payload.index],
    [payload.field]: payload.value,
    ...payload.other,
  };

  return rules;
};

export const requestArticlesList = (searchText = '', currentPage = 1) => ({
  paging: {
    skip: (currentPage - 1) * ARTICLE_LIST_RESULTS_PER_PAGE,
    top: ARTICLE_LIST_RESULTS_PER_PAGE,
  },
  search: {
    searchText,
  },
});
