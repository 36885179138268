export default {
  LAST_RUN_ON: 'Last run on %DATE%',
  PROCESS_NEVER_RAN: 'Process never ran',
  READY_TO_START: 'Ready to start',
  IN_PROGRESS: 'In progress',
  FAILED: 'Failed',
  SUCCESS: 'Success',
  ERRORS: 'Errors',
  WARNINGS: 'Warnings',
  VIEW_ERRORS: 'View errors',
  VIEW_WARNINGS: 'View warnings',
  PROCESS_NAME: 'Process name: <strong>%NAME%</strong>',
  SUBPROCESS_NAME: 'Subprocess name: <strong>%NAME%</strong>',
  NO_PROCESSES_IN_DIRECTORY: "There aren't any processes in this directory.",
  PROCESS_HAS_STARTED: 'Process has started',
};
