"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var StyledSelectedItem = _styledComponents["default"].div.withConfig({
  displayName: "SelectedItemstyle__StyledSelectedItem",
  componentId: "sc-1q4f437-0"
})(["", ";"], function (props) {
  var _props$theme = props.theme,
    color = _props$theme.palette,
    typography = _props$theme.typography,
    spacing = _props$theme.spacing,
    genericBorderRadius = _props$theme.extra.genericBorderRadius;
  return (0, _styledComponents.css)(["display:block;border:1px solid ", ";margin-right:4px;overflow:hidden;float:left;margin-bottom:3px;position:relative;.selected-item-value{display:inline-block;padding:4px;padding-left:", ";font-size:", ";line-height:", ";position:relative;white-space:pre;}.selected-item-value:before{display:block;content:'';width:14px;background-color:", ";border-radius:", " 0 0 ", ";position:absolute;top:0;bottom:0;left:0;z-index:0;}.selected-item-icon{cursor:pointer;color:", ";position:absolute;top:50%;transform:translateY(-50%);left:2px;z-index:1;}& + .Select-input{display:block;float:left;input{font-size:", ";}}"], color.accent.charlie, spacing.small, typography.size.table, typography.lineHeight["default"], color.accent.charlie, genericBorderRadius, genericBorderRadius, color.secondary, typography.size.table);
});
StyledSelectedItem.displayName = 'StyledSelectedItem';
var _default = StyledSelectedItem;
exports["default"] = _default;