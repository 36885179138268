const types = {
  REWARDS_CONFIGURATION_GET_CATEGORIES_PENDING: 'REWARDS_CONFIGURATION_GET_CATEGORIES_PENDING',
  REWARDS_CONFIGURATION_GET_CATEGORIES: 'REWARDS_CONFIGURATION_GET_CATEGORIES',
  ADD_MAIN_CATEGORY: 'ADD_MAIN_CATEGORY',
  ADD_CATEGORY: 'ADD_CATEGORY',
  EDIT_CATEGORY: 'EDIT_CATEGORY',
  DELETE_CATEGORY: 'DELETE_CATEGORY',
  SUBMIT_REWARDS_CATEGORIES_ERROR: 'SUBMIT_REWARDS_CATEGORIES_ERROR',
  SUBMIT_REWARDS_CATEGORIES_SUCCESS: 'SUBMIT_REWARDS_CATEGORIES_SUCCESS',
  SUBMIT_REWARDS_CATEGORIES_PENDING: 'SUBMIT_REWARDS_CATEGORIES_PENDING',
  RESET_REWARDS_CATEGORIES_SUCCESS: 'RESET_REWARDS_CATEGORIES_SUCCESS',
  ON_EDIT_CATEGORY: 'ON_EDIT_CATEGORY',
  ON_VALIDATE_FIELD: 'ON_VALIDATE_FIELD',
  RESET_CATEGORIES: 'RESET_CATEGORIES',
  ON_UPDATE_ORDER: 'ON_UPDATE_ORDER',
};

export default types;
