"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var StyledCollapsible = _styledComponents["default"].div.withConfig({
  displayName: "Collapsiblestyle__StyledCollapsible",
  componentId: "sc-k62fn8-0"
})(["", ";"], function (props) {
  var _props$theme = props.theme,
    palette = _props$theme.palette,
    spacing = _props$theme.spacing,
    _props$theme$extra = _props$theme.extra,
    genericBorderRadius = _props$theme$extra.genericBorderRadius,
    boxShadow = _props$theme$extra.boxShadow;
  return (0, _styledComponents.css)(["border-radius:", ";box-shadow:", ";margin-bottom:", ";> div{outline:none;}&.nested{margin-bottom:0px;.CollapsibleHeader{border-radius:0;}}.nested-content > .content-wrapper{background-color:", ";padding:", ";}.content-wrapper{position:relative;}.toggle-icon{transition:all 0.2s ease;}&.expanded .toggle-icon{transform:rotate(180deg);}.collapsible-trigger{outline:none;}"], genericBorderRadius, boxShadow, spacing.basePlus, palette.basic.white, spacing.tiny);
});
StyledCollapsible.displayName = 'StyledCollapsible';
var _default = StyledCollapsible;
exports["default"] = _default;