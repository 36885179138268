import styled, { css } from 'styled-components';
import { media } from '@trs/utils';

const StyledColumnBody = styled.div`
  ${props => {
    const { showOnlyNewStatement, theme } = props;
    const { palette: color, spacing } = theme;
    return css`
      &&& .Card {
        padding: ${spacing.base} 0 0;
        margin-bottom: 0;
        border-radius: 0;
        border-width: 0 0 0 4px;
        > div:nth-of-type(odd):not(.statement-row--highlight) {
          background-color: ${color.utility.bodyBackground};
        }
      }

      }
      ${!props.disableAnimation &&
        css`
          animation: fadeInFromNone 0.5s ease-out;
        `}
      ${media.mobileLandscape`
        ${!showOnlyNewStatement &&
          css`
            .Card :nth-child(2).statement-row > :nth-child(2):after {
              content: '';
              position: absolute;
              border-right: 1px solid ${color.gray.foxtrot};
              top: 0;
              height: 100%;
              margin-left: ${spacing.xSmall};
            }
          `}
      `}
      @keyframes fadeInFromNone {
        0% {
          opacity: 0;
        }

        100% {
          opacity: 1;
        }
      }

      .extraStatements {
        width: 100%;
        height: 8px;
        background-color: ${color.secondary};
      }
    `;
  }}
`;

StyledColumnBody.displayName = 'StyledColumnBody';
export default StyledColumnBody;
