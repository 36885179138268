"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "Collapsible", {
  enumerable: true,
  get: function get() {
    return _Collapsible["default"];
  }
});
Object.defineProperty(exports, "CollapsibleTrigger", {
  enumerable: true,
  get: function get() {
    return _CollapsibleTrigger["default"];
  }
});
var _Collapsible = _interopRequireDefault(require("./Collapsible"));
var _CollapsibleTrigger = _interopRequireDefault(require("./CollapsibleTrigger"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }