import { REDUCER_KEY_MAP } from '../actions';

export default {
  isGettingSummary: true,
  isGettingDetails: true,
  isGettingCurrencies: true,
  pageLevelError: null,
  currencies: [],
  summary: {},
  details: {
    [REDUCER_KEY_MAP.country]: [],
    [REDUCER_KEY_MAP.jobLevel]: [],
    [REDUCER_KEY_MAP.site]: [],
  },
};
