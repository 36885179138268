import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import html2pdf from 'html2pdf.js';
import { Button } from '@trs/components';
import { noop } from '@trs/utils';
import Text, { TextOnly } from '../Text';
import StyledSnapshotPdf from './index.style';
import { getSnapshotOptions } from './helpers';

class SnapshotPdf extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isDownloadInProgress: false,
    };
  }

  downloadSnapshotPdf = () => {
    const { current } = this.props.elemRef;
    const { options, pdfName } = this.props;

    this.setState({
      isDownloadInProgress: true,
    });

    options
      .callback()
      .then((response) => {
        const opts = getSnapshotOptions({
          ...options,
          offsetWidth: options.offsetWidth || R.prop('offsetWidth', current),
          offsetHeight: R.prop('offsetHeight', current),
          pdfName,
          beforeDownload: (root) => options.beforeDownload(root, response),
        });

        html2pdf()
          .from(current)
          .set(opts)
          .save()
          .then(() => {
            this.setState({
              isDownloadInProgress: false,
            });
            options.afterDownload();
          })
          .catch((errors) => {
            this.setState({
              isDownloadInProgress: false,
            });
            options.afterDownload();
            return Promise.reject(errors);
          });
      })
      .catch(() => {
        this.setState({
          isDownloadInProgress: false,
        });
        return Promise.reject();
      });
  };

  render() {
    const { isDownloadInProgress } = this.state;
    const { buttonTextPath, buttonType, loadingContentPath, disabled, withDownloadIcon, pdfName } =
      this.props;
    return (
      <StyledSnapshotPdf className="snapshot-pdf" hasPdfName={pdfName}>
        <Button
          className="downloadSnapshot"
          data-testid="downloadSnapshot"
          type={buttonType}
          icon={withDownloadIcon ? 'ion-ios-download-outline' : ''}
          id="downloadSnapshotPdf"
          action={this.downloadSnapshotPdf}
          loading={isDownloadInProgress}
          loadingContent={TextOnly({ path: loadingContentPath })}
          disabled={!pdfName || disabled}
        >
          <Text path={buttonTextPath} />
        </Button>
      </StyledSnapshotPdf>
    );
  }
}

SnapshotPdf.propTypes = {
  disabled: PropTypes.bool,
  options: PropTypes.shape({
    offsetWidth: PropTypes.number,
    orientation: PropTypes.string,
    beforeDownload: PropTypes.func,
    afterDownload: PropTypes.func,
    ignoredTagNames: PropTypes.arrayOf(PropTypes.string),
    callback: PropTypes.func,
  }),
  loadingContentPath: PropTypes.string,
  buttonType: PropTypes.oneOf(['primary', 'naked', 'link']),
  buttonTextPath: PropTypes.string,
  pdfName: PropTypes.string,
  withDownloadIcon: PropTypes.bool,
  // returns an html element to print
  elemRef: PropTypes.shape({
    current: PropTypes.shape({
      offsetWidth: PropTypes.number,
    }),
  }).isRequired,
};

SnapshotPdf.defaultProps = {
  disabled: false,
  options: {
    offsetWidth: null,
    orientation: '',
    beforeDownload: noop,
    afterDownload: noop,
    ignoredTagNames: [],
  },
  loadingContentPath: 'generic.BUTTON_LOADING',
  buttonTextPath: 'generic.BUTTON_DOWNLOAD_PDF',
  buttonType: 'primary',
  withDownloadIcon: false,
  pdfName: '',
};

export default SnapshotPdf;
