"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.shareViewOptions = exports.menuListItems = exports.isStepper = exports.isFormula = exports.isContribution = exports.initialComplexFormula = exports.ACTIVE_TYPES = void 0;
var R = _interopRequireWildcard(require("ramda"));
var _utils = require("@trs/utils");
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
var ACTIVE_TYPES = {
  fixed: 'FIXED',
  percentage: 'PERCENTAGE'
};
exports.ACTIVE_TYPES = ACTIVE_TYPES;
var shareViewOptions = function shareViewOptions(labels) {
  return [{
    name: 'share-block-view',
    value: ACTIVE_TYPES.fixed,
    id: 'share-option-fixed',
    naked: true,
    iconChecked: 'ion-android-radio-button-on',
    iconUnchecked: 'ion-android-radio-button-off',
    label: labels.FORMULA_SHARE_VIEW_FIXED
  }, {
    name: 'share-block-view',
    value: ACTIVE_TYPES.percentage,
    id: 'share-option-percentage',
    iconChecked: 'ion-android-radio-button-on',
    iconUnchecked: 'ion-android-radio-button-off',
    naked: true,
    label: labels.FORMULA_SHARE_VIEW_PERCENTAGE
  }];
};
exports.shareViewOptions = shareViewOptions;
var menuListItems = function menuListItems(_ref) {
  var labels = _ref.labels,
    actions = _ref.actions,
    ruleIndex = _ref.ruleIndex,
    setHasChanges = _ref.setHasChanges;
  return [{
    name: labels.BUTTON_DELETE,
    icon: 'ion-trash-b',
    onClick: function onClick() {
      actions.deleteRuleFormulas(ruleIndex);
      setHasChanges(true);
    }
  }];
};
exports.menuListItems = menuListItems;
var isFormula = function isFormula(rule) {
  return !(0, _utils.isBlank)(R.prop('formula', rule));
};
exports.isFormula = isFormula;
var isStepper = function isStepper(_ref2) {
  var stepper = _ref2.stepper;
  return !(0, _utils.isBlank)(R.path([ACTIVE_TYPES.fixed, 'steps'], stepper)) || !(0, _utils.isBlank)(R.path([ACTIVE_TYPES.percentage, 'steps'], stepper));
};
exports.isStepper = isStepper;
var isContribution = function isContribution(rule) {
  return !(0, _utils.isBlank)(R.path(['complex', ACTIVE_TYPES.fixed, 'contribution'], rule)) || !(0, _utils.isBlank)(R.path(['complex', ACTIVE_TYPES.percentage, 'contribution'], rule));
};
exports.isContribution = isContribution;
var initialComplexFormula = function initialComplexFormula(_ref3) {
  var _objectSpread2;
  var formula = _ref3.formula,
    rule = _ref3.rule;
  return _objectSpread(_objectSpread({}, rule.complex), {}, (_objectSpread2 = {}, _defineProperty(_objectSpread2, ACTIVE_TYPES.fixed, _objectSpread(_objectSpread({}, R.path(['complex', ACTIVE_TYPES.fixed], rule)), {}, {
    contribution: formula
  })), _defineProperty(_objectSpread2, ACTIVE_TYPES.percentage, _objectSpread(_objectSpread({}, R.path(['complex', ACTIVE_TYPES.percentage], rule)), {}, {
    contribution: formula,
    additionalFormulaItems: formula
  })), _objectSpread2));
};
exports.initialComplexFormula = initialComplexFormula;