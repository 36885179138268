export default {
  SECTION_TITLE: 'Country Details',
  SEARCH_PLACEHOLDER: 'Search By Country',
  MARKET_CLUSTER: 'Market Cluster',
  TOTAL_ACTIVE: '<strong>%NUMBER%</strong> active countries out of <strong>%TOTAL%</strong>',
  NO_RESULTS: 'No results found.',
  COLUMN_STATUS: 'Status',
  COLUMN_COUNTRY: 'Country',
  COLUMN_BASE_PAY: 'Base Pay',
  COLUMN_FIXED_PAY: 'Fixed Pay',
  COLUMN_VARIABLE_PAY: 'Variable Pay',
  COLUMN_BENEFITS: 'Benefits',
  COLUMN_CURRENCY: 'Currency',
  COLUMN_FX_RATE: 'FX Rate',
  COLUMN_CONTACT_EMAIL: 'Contact Email',
  COLUMN_CONTACT_NAME: 'Contact Name',
  INVALID_EMAIL: 'Email format incorrect',
  COLUMN_MANUAL: 'Manual',
  COUNTRY_ACTIVE_LABEL: 'Active',
  COUNTRY_INACTIVE_LABEL: 'Inactive',
};
