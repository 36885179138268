export default {
  isLoading: true,
  globalSettings: {
    setupFinished: true,
    data: {},
  },
  clusters: [],
  clusterCountries: [],
  canInitiate: false,
  isInitiatingBenchmarking: false,
  globalBenchmarkingId: null,
};
