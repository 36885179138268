import * as R from 'ramda';

export const propNames = {
  annualSalary: 'annualSalary',
  gradeProfileCurrency: 'employee.Calculated.gradeProfileCurrency',
  minAnnualSalary: 'employee.Calculated.gradeProfileMinimum',
  midAnnualSalary: 'employee.Calculated.gradeProfileMidpoint',
  maxAnnualSalary: 'employee.Calculated.gradeProfileSegment3Top',
  fullTimeEquivalent: 'employee.fullTimeEquivalent',
  payPoint: 'payPoint',
  targetBonus: 'employee.Calculated.bonusTargetPercent',
  compensationPlanAmount: 'employee.compensationPlanAmount',
  compensationPlanCurrency: 'employee.compensationPlanCurrency',
  employeeId: 'employee.id',
  assignmentStart: 'employee.assignments.assignmentStart',
  assignmentEnd: 'employee.assignments.assignmentEnd',
  assignmentDurationInMonths: 'employee.assignments.assignmentDurationInMonths',
  familySize: 'employee.assignments.familySize',
  homeLocationId: 'assignments.homeLocationId',
  hostLocationId: 'assignments.hostLocationId',
  splitFamily: 'employee.assignments.splitFamily',
  totalAnnualBasePay: 'employee.totalAnnualBasePay',
  currency: 'outputCurrency',
  exchangeSource: 'outputExchangeSource',
  mobility: 'isMobility',
};

export const baseFields = {
  isMobility: undefined,
};

export const advancedFields = {
  isMobility: undefined,
  annualSalary: undefined,
  'employee.Calculated.gradeProfileMinimum': undefined,
  'employee.Calculated.gradeProfileMidpoint': undefined,
  'employee.Calculated.gradeProfileSegment3Top': undefined,
  'employee.fullTimeEquivalent': undefined,
  payPoint: undefined,
  'employee.Calculated.bonusTargetPercent': undefined,
  outputCurrency: undefined,
  outputExchangeSource: undefined,
};

export const hardcodedIsMobilityId = 'isMobility';

const baseMetadataFields = () => [
  {
    id: hardcodedIsMobilityId,
    dataSource: 'hardcoded',
    propertyName: 'isMobility',
    displayName: 'Mobility',
    type: 'hardcoded',
    description: 'Mobility',
    trigger: true,
    options: [
      {
        label: 'Local',
        value: false,
      },
      {
        label: 'Mobile',
        value: true,
      },
    ],
    valuesUrl: null,
  },
];

const advancedMetadataFields = (modellerState) => [
  {
    id: propNames.annualSalary,
    dataSource: 'hardcoded',
    description: 'Annual Salary',
    propertyName: propNames.annualSalary,
    type: 'decimal',
    valuesUrl: null,
  },
  {
    id: propNames.minAnnualSalary,
    propertyName: propNames.minAnnualSalary,
    type: 'decimal',
    disabled: true,
  },
  {
    id: propNames.midAnnualSalary,
    propertyName: propNames.midAnnualSalary,
    type: 'decimal',
    disabled: true,
  },
  {
    id: propNames.maxAnnualSalary,
    propertyName: propNames.maxAnnualSalary,
    type: 'decimal',
    disabled: true,
  },
  {
    id: propNames.fullTimeEquivalent,
    propertyName: propNames.fullTimeEquivalent,
    rangedValue: true,
  },
  {
    id: propNames.payPoint,
    dataSource: 'hardcoded',
    description: 'Pay point',
    propertyName: propNames.payPoint,
    type: 'decimal',
    valuesUrl: null,
  },
  {
    id: propNames.targetBonus,
    propertyName: propNames.targetBonus,
    type: 'decimal',
  },
  {
    id: propNames.compensationPlanCurrency,
    propertyName: propNames.compensationPlanCurrency,
    type: 'hardcoded',
    options: modellerState.currencies,
    valuesUrl: null,
  },
  { id: propNames.employeeId, propertyName: propNames.employeeId, disabled: true },
];

const mobilityMetadataFields = (modellerState) => [
  { id: propNames.assignmentStart, propertyName: propNames.assignmentStart },
  {
    id: propNames.assignmentEnd,
    propertyName: propNames.assignmentEnd,
  },
  {
    id: propNames.familySize,
    propertyName: propNames.familySize,
  },
  {
    id: propNames.homeLocationId,
    dataSource: 'hardcoded',
    description: 'Home Location',
    propertyName: propNames.homeLocationId,
    type: 'hardcoded',
    options: R.path(['locations', 'homeLocations'], modellerState),
    valuesUrl: null,
  },
  {
    id: propNames.hostLocationId,
    dataSource: 'hardcoded',
    description: 'Host Location',
    propertyName: propNames.hostLocationId,
    type: 'hardcoded',
    options: R.path(['locations', 'hostLocations'], modellerState),
    valuesUrl: null,
  },
  {
    id: propNames.splitFamily,
    propertyName: propNames.splitFamily,
    type: 'boolean',
    options: [
      {
        label: 'Yes',
        value: 'true',
      },
      {
        label: 'No',
        value: 'false',
      },
    ],
    valuesUrl: null,
  },
];

const hardCodedMetadataFields = (modellerState) => [
  {
    id: propNames.currency,
    dataSource: 'hardcoded',
    description: 'Currency',
    propertyName: propNames.currency,
    type: 'hardcoded',
    options: modellerState.currencies,
    valuesUrl: null,
  },
  {
    id: propNames.exchangeSource,
    dataSource: 'hardcoded',
    description: 'Exchange Source',
    propertyName: propNames.exchangeSource,
    type: 'hardcoded',
    options: modellerState.sources,
    valuesUrl: null,
  },
];

export const baseMetadataFn = (modellerState) => [
  ...baseMetadataFields(modellerState),
  ...advancedMetadataFields(modellerState),
  ...mobilityMetadataFields(modellerState),
  ...hardCodedMetadataFields(modellerState),
];
