import * as R from 'ramda';
import { createAction } from 'redux-actions';
import { TextOnly } from 'modules/common/Text';
import globalErrors from 'modules/cms/labels/errors';
import { toBinary } from '@trs/utils';
import * as actions from './types';
import { GET, POST, PUT, DELETE } from '../../../config/http';
import { reports, auth } from '../../../config/api';
import { SORT_BY_EMPLOYEE_ID, DEFAULT_SORT } from '../constants';
import { showBanner } from '../../../actions/globalActions';
import { dispatchError } from '../../common/helpers';
import userManager from '../../../config/userManager/userManager';

const triggerExport = createAction(actions.TRIGGER_COUNTRY_REWARDS_EXPORT);
const getReportUserPermissions = createAction(actions.GET_REPORT_PERMISSIONS);
export const getReportsCategoriesMapping = createAction(actions.GET_CATEGORIES_MAPPING_REPORT);
export const changeCategoriesMapping = createAction(actions.CHANGE_CATEGORIES_MAPPING_REPORT);
export const resetCategoriesMapping = createAction(actions.RESET_CATEGORIES_MAPPING_REPORT);

export const submitActions = {
  pending: createAction(actions.SUBMIT_CATEGORIES_MAPPING_PENDING),
  success: createAction(actions.SUBMIT_CATEGORIES_MAPPING_SUCCESS),
  error: createAction(actions.SUBMIT_CATEGORIES_MAPPING_ERROR),
};

export const loadCategoriesMapping = () => (dispatch) => {
  dispatch(
    getReportsCategoriesMapping(
      GET(reports.configuration).then((response) => ({
        categoriesMappings: R.path(['data', 'categories'], response),
        status: R.path(['data', 'response', 'status'], response),
        etag: R.path(['headers', 'etag'], response),
      }))
    )
  );
};

const mapModelToPayload = (mappings) =>
  R.keys(mappings).map((key) => {
    const [, , rewardLevel3CategoryId] = key.split('/');
    const { isIncluded } = mappings[key];
    return { rewardLevel3CategoryId, isIncluded };
  });

export const submitCategoriesMapping = () => (dispatch, getState) => {
  const { etag, mappings: categoriesMappings } = R.path(
    ['reports', 'configurationReducer'],
    getState()
  );

  const categories = mapModelToPayload(categoriesMappings);

  const categoriesMappingsLabel = TextOnly({ path: 'flexibleReports.CONFIGURATION_PAGE_TITLE' });

  dispatch(submitActions.pending());

  return POST(reports.configuration, { categories }, { Etag: etag })
    .then((response) => {
      dispatch(
        showBanner({
          type: 'success',
          content: TextOnly({
            path: 'generic.SUBMIT_SUCCESS',
            transform: (label) => label.replace('%NAME%', categoriesMappingsLabel),
          }),
        })
      );
      return dispatch(submitActions.success({ id: R.path(['data', 'response', 'id'], response) }));
    })
    .catch((err) => {
      dispatch(showBanner({ type: 'error' }));
      dispatch(submitActions.error());
      return Promise.reject(err);
    });
};

export const getReports = () => (dispatch) => {
  dispatch({
    type: actions.GET_REPORTS_PENDING,
  });

  return GET(`${reports.getReports}`, { Pragma: 'no-cache' }, { showErrorModal: true, dispatch })
    .then((response) => {
      dispatch({
        type: actions.GET_REPORTS_SUCCESS,
        payload: response.data,
      });
    })
    .catch(() => {
      dispatch({
        type: actions.GET_REPORTS_FAILURE,
      });
    });
};

export const getReport = (country, params) => (dispatch, getState) => {
  dispatch({
    type: actions.GET_REPORT_PENDING,
  });

  const { sortAsc, pagingTop: top, pagingSkip: skip, searchText } = params;
  const { sortBy } = params;

  const payload = {
    sort: { sortAsc, sortBy: sortBy === DEFAULT_SORT ? SORT_BY_EMPLOYEE_ID : sortBy },
    paging: { top, skip },
    search: { searchText },
    filters: [
      {
        fieldName: 'CountryOfEmployment',
        values: [country],
      },
    ],
  };

  return POST(
    `${reports.exportReport}/country-rewards`,
    payload,
    {},
    { showErrorModal: true, dispatch }
  ).then((response) => {
    dispatch({
      type: actions.GET_REPORT_SUCCESS,
      payload: {
        ...response.data,
        cmsLabels: R.path(['cms', 'flexibleReports', 'data'], getState()),
      },
    });
  });
};

export const updateCountries = () => (dispatch, getState) => {
  const state = getState();
  return dispatch({
    type: actions.UPDATE_COUNTRIES,
    payload: {
      countries: R.path(['rmDashboard', 'dashboard', 'countries'], state),
      authorizedModules: R.path(['user', 'authorizedModules'], state),
    },
  });
};

export const selectCountry = (country) => ({
  type: actions.SELECT_COUNTRY,
  payload: country,
});

export const clearReportData = () => ({
  type: actions.CLEAR_REPORT_DATA,
});

export const triggerCountryRewardsExport = () => (dispatch, getState) => {
  const state = getState();
  const selectedCountry = R.path(['reports', 'main', 'selectedCountry', 'value'], state);
  const inProgressBanner = R.path(
    ['cms', 'flexibleReports', 'data', 'EXPORT_IN_PROGRESS_BANNER'],
    state
  );
  return dispatch(
    triggerExport(
      POST(`${reports.countryRewardsExport}/trigger`, { countries: [selectedCountry] }, {})
        .then(() => {
          dispatch(
            showBanner({
              type: 'info',
              content: inProgressBanner,
            })
          );
        })
        .catch((error) => {
          dispatchError(error, () => {}, dispatch);
          return Promise.reject(error);
        })
    )
  );
};

export const handleReportEdit = (field, value) => (dispatch) => {
  dispatch({
    type: actions.CHANGE_REPORT_DEFINITION,
    payload: {
      field,
      value,
    },
  });
};

export const clearReportDefinition = () => (dispatch) => {
  dispatch({
    type: actions.CLEAR_REPORT_DEFINITION,
  });
};

export const getExternalReports = () => (dispatch) => {
  dispatch({
    type: actions.GET_EXTERNAL_REPORTS_PENDING,
  });
  GET(reports.externalReport)
    .then((response) => {
      dispatch({
        type: actions.GET_EXTERNAL_REPORTS_SUCCESS,
        payload: R.path(['data', 'response'], response),
      });
    })
    .catch(() => {
      dispatch({
        type: actions.GET_EXTERNAL_REPORTS_FAILURE,
      });
    });
};

export const getFlexReports = () => (dispatch) => {
  dispatch({
    type: actions.GET_FLEX_REPORTS_PENDING,
  });
  GET(reports.flexReport)
    .then((response) => {
      dispatch({
        type: actions.GET_FLEX_REPORTS_SUCCESS,
        payload: R.path(['data', 'response'], response),
      });
    })
    .catch(() => {
      dispatch({
        type: actions.GET_FLEX_REPORTS_FAILURE,
      });
    });
};

export const getFlexEmbedReportConfiguration = (reportId) => (dispatch) => {
  dispatch({
    type: actions.GET_FLEX_EMBED_REPORTS_PENDING,
  });

  return GET(`${reports.flexEmbedReport}/${reportId}`)
    .then((response) => {
      dispatch({
        type: actions.GET_FLEX_EMBED_REPORTS_SUCCESS,
      });

      return R.path(['data', 'response'], response);
    })
    .catch(() => {
      dispatch(
        showBanner({
          type: 'error',
          content: globalErrors.SOMETHING_WENT_WRONG,
        })
      );
      dispatch({
        type: actions.GET_FLEX_EMBED_REPORTS_FAILURE,
      });
    });
};

export const saveReport = (reportId) => (dispatch, getState) => {
  const {
    reports: {
      main: {
        newReport: { name, url },
      },
    },
  } = getState();

  const payload = {
    reportName: toBinary(name),
    reportUrl: url,
  };

  const request = R.isNil(reportId)
    ? POST(reports.externalReport, payload)
    : PUT(`${reports.externalReport}/${reportId}`, payload);

  dispatch({
    type: actions.ADD_EXTERNAL_REPORT_PENDING,
  });

  return request
    .then((response) => {
      const isSuccessful = R.path(['data', 'isSuccessful'], response);

      if (isSuccessful) {
        dispatch({
          type: actions.ADD_EXTERNAL_REPORT_SUCCESS,
        });
        dispatch(
          showBanner({
            type: 'success',
            content: TextOnly({
              path: R.isNil(reportId)
                ? 'flexibleReports.REPORT_CREATED_SUCCESSFULLY'
                : 'flexibleReports.REPORT_EDITED_SUCCESSFULLY',
            }),
          })
        );
        dispatch(getExternalReports());
      }
      return isSuccessful;
    })
    .catch((body) => {
      const errors = R.path(['response', 'data', 'errors'], body);
      dispatch({
        type: actions.ADD_EXTERNAL_REPORT_FAILURE,
      });
      dispatch(
        showBanner({
          type: 'error',
          content: errors
            ? R.prop('message', errors[0])
            : TextOnly({
                path: 'general.SOMETHING_WENT_WRONG',
              }),
        })
      );

      return R.path(['response', 'isSuccessful'], body);
    });
};

export const deleteReport = (reportId) => (dispatch) => {
  DELETE(`${reports.externalReport}/${reportId}`)
    .then(() => {
      dispatch(
        showBanner({
          type: 'success',
          content: TextOnly({
            path: 'flexibleReports.REPORT_DELETED_SUCCESSFULLY',
          }),
        })
      );
      dispatch(getExternalReports());
    })
    .catch((body) => {
      const errors = R.path(['response', 'data', 'errors'], body);
      dispatch(
        showBanner({
          type: 'error',
          content: errors
            ? R.prop('message', errors[0])
            : TextOnly({
                path: 'general.SOMETHING_WENT_WRONG',
              }),
        })
      );
    });
};

export const loadUserReportsPermissions = (dispatch) => {
  return dispatch(
    getReportUserPermissions(
      GET(`${auth.users}/permissions/REPORTS`).then((response) => {
        const permissions = R.path(['data'], response);

        userManager.setReportsModules(permissions);

        return {
          permissions,
        };
      })
    )
  );
};

export const setLauncherScreenState = (isOpened) => (dispatch) => {
  dispatch({
    type: actions.SET_LAUNCHER_SCREEN_VISIBILITY,
    payload: isOpened,
  });
};

export default {
  getReports,
  getReport,
  updateCountries,
  selectCountry,
  clearReportData,
  handleReportEdit,
  getExternalReports,
  getFlexReports,
  getFlexEmbedReportConfiguration,
  saveReport,
  getReportUserPermissions,
  setLauncherScreenState,
};
