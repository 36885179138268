import { DEFAULT_FACE, NOT_SELECTED_RATING } from '../constants';

const initialState = {
  status: DEFAULT_FACE,
  feedbackScore: NOT_SELECTED_RATING,
  employeesStatus: DEFAULT_FACE,
  employeesTotalScore: NOT_SELECTED_RATING,
  rewardsFeedbackInitial: [],
  rewardsFeedback: [],
  hasUnsavedChanges: false,
  isFetchingRewardsFeedback: false,
  isFeedbackSubmitted: false,
  isFetchingTotalScore: false,
  isFetchingEmployeesTotalScore: true,
  isFeedbackSubmitPending: false,
};

export default initialState;
