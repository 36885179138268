import * as R from 'ramda';

export const getAllCountries = payload =>
  payload.map(item => ({
    ...item,
    id: item.code,
    isActionDisabled: !item.isEnabled,
  }));

export const getSelectedCountries = payload => {
  const selectedCountries = [];
  R.forEach(item => {
    if (item.isEnabled) {
      selectedCountries.push(item.code);
    }
  }, payload);

  return selectedCountries;
};
