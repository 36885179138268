// this is the order index at which the side by side will display a subtotal.
export const CATEGORY_SPLIT_INDEX = 3;

export const TILE_TYPES = {
  RADIO: 'radio',
  CHECKBOX: 'checkbox',
};

export const RADIO_ICONS = {
  iconChecked: 'ion-android-radio-button-on',
  iconUnchecked: 'ion-android-radio-button-off',
};

/**
 * DEFAULT=0 : value rewards(other types)
 * PERCENTAGE=1 : percentage rewards
 * TEXT_ONLY=2 : text only rewards
 */
export const REWARD_CATEGORIES_TYPES = {
  DEFAULT: 0,
  PERCENTAGE: 1,
  TEXT_ONLY: 2,
};
