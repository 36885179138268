export default {
  PACKAGE_DETAILS: 'Package Details',
  PACKAGE_DETAILS_COUNTRY: 'Country',
  PACKAGE_DETAILS_COUNTRY_PLACEHOLDER: 'Select a country',
  PACKAGE_DETAILS_TITLE: 'Package Title',
  PACKAGE_DETAILS_TITLE_PLACEHOLDER: 'Specific title for this package',
  PACKAGE_DETAILS_DESCRIPTION: 'Package Description',
  PACKAGE_DETAILS_DESCRIPTION_PLACEHOLDER: 'Specific description for this package',
  PACKAGE_DETAILS_TYPE: 'Package Type',
  PACKAGE_DETAILS_TYPE_MODELLING_INFO:
    'Modelling allows the user to view and modify the statement from a future date.',
  PACKAGE_DETAILS_TYPE_SUBMISSION_INFO:
    'The employees will be able to select and submit their reward choices. You will be able to configure whether they should receive an e-mail confirmation upon submission and you will be able to access a report with submission details.',
  NEW_STATEMENT_DATE: 'New Statement Date',
  NEW_STATEMENT_DATE_INFO: 'Will become effective at the selected date, 00:00 [UTC]',
  ENROLLMENT_WINDOW: 'Enrolment Window',
  ENROLMENT_WINDOW_START_DATE: 'Start date',
  ENROLMENT_WINDOW_END_DATE: 'End date',
  ENROLMENT_WINDOW_INFO:
    'Please select a start and end date for enrolment window. This will become effective based on the [UTC] timezone.',
  GENERAL_INFORMATION_CARD_CAPTION: 'General information',
  BANNER_INFORMATION_CARD_CAPTION: 'Banner information',
  BANNER_SUBTITLE:
    'Please provide a short description of the rewards package which will be displayed in the employee statement',
  CATEGORY: 'Category',
  NEW_STATEMENT_DATE_CHANGED_TITLE: 'New Statement Date Change Confirmation',
  NEW_STATEMENT_DATE_CHANGED_SUBTITLE:
    'Do note that any changes to new statement date will clear the selected optional rewards.',
  SAVE_TO_LIVE_TITLE: 'Update to LIVE Status',
  SAVE_TO_LIVE_SUBTITLE: "Your changes will turn this package's status to LIVE. Please confirm.",
  SAVE_TO_EXPIRED_TITLE: 'Expire Package?',
  SAVE_TO_EXPIRED_SUBTITLE:
    "Your changes will turn this package's status to EXPIRED. Please confirm",
  ONLY_SHOW_NEW_STATEMENT: 'Only show new statement',
};
