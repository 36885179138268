import header from '../header';

export default {
  ...header,
  PAGE_TITLE: 'Benefits Envelope Audit',
  PAGE_SUBTITLE:
    'Here you can track all the requets made to the Benefits Envelope API and the calculated outputs for each employee. You can select individual or multiple employees with the tick-boxes to see the extended details of the process.',
  EMPLOYEE_ID: 'Employee id',
  REQUEST_IP: 'Request IP',
  CALCULATED_ON_DATE: 'calculated on date (UTC)',
  REQUEST: 'request (UTC)',
  RESPONSE: 'response (UTC)',
  STATUS: 'Status',
  SEE_DETAILS: 'See details',
  NO_DATA: 'No data',
  REQUEST_FAILED: 'Unable to load data',
  CLEAR_SELECTION: 'Clear selection',
  REPORT_NAME: 'audits-report',
  RECORDS_NAME: 'employees selected',
  RECORD_NAME_UNIQUE: 'employee selected',
  RECORD_LIMIT_ERROR: 'You have reached the maximum number of selected rewards.',
};
