"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var StyledCustomLabel = _styledComponents["default"].div.withConfig({
  displayName: "CustomLabelstyle__StyledCustomLabel",
  componentId: "sc-1ymo2to-0"
})(["", ";"], function (props) {
  var _props$theme = props.theme,
    genericBorderRadius = _props$theme.extra.genericBorderRadius,
    spacing = _props$theme.spacing,
    palette = _props$theme.palette;
  var isOpen = props.isOpen;
  return (0, _styledComponents.css)(["margin:", " ", " ", ";background-color:white;.custom-label-card{border-radius:", ";margin-bottom:0;.control-card-content{width:100%;}.custom-label-header{justify-content:space-between;display:flex;}}.input-component{display:block;padding:", ";input{width:320px;}}.custom-label-info{color:", ";padding-left:", ";padding-bottom:", ";}"], spacing.tiny, spacing.tiny, spacing.small, isOpen ? "".concat(genericBorderRadius, " ").concat(genericBorderRadius, " 0 0") : genericBorderRadius, spacing.small, palette.basic.gray, spacing.small, spacing.small);
});
StyledCustomLabel.displayName = 'StyledCustomLabel';
var _default = StyledCustomLabel;
exports["default"] = _default;