const actionTypes = {
  ADD_COMPARE: 'ADD_COMPARE',
  REMOVE_COMPARE: 'REMOVE_COMPARE',
  SET_INITIAL_FIELDS: 'SET_INITIAL_FIELDS',
  RE_INITIALIZE_FIELDS: 'RE_INITIALIZE_FIELDS',
  LOAD_DATA_SUCCESS: 'LOAD_DATA_SUCCESS',
  HANDLE_CHANGE: 'HANDLE_CHANGE',
  CORRELATE_FIELDS: 'CORRELATE_FIELDS',
  RESET_STORE: 'RESET_STORE',
};

export default actionTypes;
