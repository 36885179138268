import * as R from 'ramda';
import { handleActions } from 'redux-actions';
import { isBlank } from '@trs/utils';
import { filterCountriesByAccess } from 'modules/common/helpers';
import { getFilteredCountries } from 'modules/fixedCosts/common/helpers';
import {
  requestAllFixedCosts,
  getFixedCostsSearch,
  getFixedCostsById,
  getSavedLogicBuilderById,
  getFixedCostsCurrencies,
  getFixedCostsCountries,
  setFixedCostsFilteredCountries,
  getFixedCostsCategories,
  getFixedCostsFxSources,
  getFixedCostsCompanyDefaultCurrency,
  getFixedCostsBasicInformationChange,
  basicInformationReset,
  basicInformationSave,
  fixedCostsEtagUpdate,
  fixedCostsFetchingById,
  getFixedCostsFormulaOperators,
  fixedCostsRuleChange,
  changeDistributionType,
  setDistributionTableRowsAndTotal,
  cellChange,
  rulesBuilderRevert,
  rulesBuilderReset,
  storeReset,
  redirectToRoot,
} from '../actions';
import userModules from '../../../config/userManager/userModules';
import {
  mapResponseToBasicInfoModel,
  mapResponseToRuleBuilderRuleModel,
  mapResponseRuleBuilderDistributionModel,
  transformResponseGetAllFixedCosts,
  transformDirtyRowsData,
} from './helpers.js';
import initialState from './initialState';

const fixedCostsReducer = handleActions(
  new Map([
    [
      requestAllFixedCosts,
      (state, action) => {
        return {
          ...state,
          fetchingList: action.payload,
        };
      },
    ],
    [
      getFixedCostsSearch,
      (state, action) => {
        const fixedCostsCount = action.payload.response.data.count;
        const data = action.payload.response.data.fixedCosts;
        const filterOptions = R.map(
          (item) => ({
            title: item.key,
            key: item.key,
            list: item.options,
          }),
          action.payload.response.data.filterOptions || []
        );

        return {
          ...state,
          list: transformResponseGetAllFixedCosts(data),
          filterOptions,
          totalResults: fixedCostsCount || 0,
          fetchingList: false,
        };
      },
    ],
    [
      fixedCostsFetchingById,
      (state, action) => {
        return {
          ...state,
          fetchingById: action.payload,
        };
      },
    ],
    [
      getFixedCostsById,
      (state, action) => {
        const response = action.payload;
        const { id, status } = response;
        const { allCountries } = state;

        const basicInformation = mapResponseToBasicInfoModel(response);
        const filteredCountries = getFilteredCountries(allCountries, response.countries);
        const rules = mapResponseToRuleBuilderRuleModel(response, initialState);
        const distribution = mapResponseRuleBuilderDistributionModel(response, state, initialState);

        return {
          ...state,
          savedFixedCostId: id,
          status,
          basicInformation,
          swapBasicInformation: basicInformation,
          rules,
          distribution,
          filteredCountries,
        };
      },
    ],
    [
      getSavedLogicBuilderById,
      (state, action) => {
        const response = action.payload;

        const rules = mapResponseToRuleBuilderRuleModel(response, initialState);
        const distribution = mapResponseRuleBuilderDistributionModel(response, state, initialState);

        return {
          ...state,
          savedFixedCostId: response.id,
          status: response.status,
          rules,
          distribution,
        };
      },
    ],
    [
      getFixedCostsCurrencies,
      (state, action) => {
        const { currencies } = action.payload;
        const currenciesOptions = [];

        currencies.map((currency) =>
          currenciesOptions.push({
            value: currency.code,
            label: currency.code,
          })
        );

        return {
          ...state,
          currencies: currenciesOptions,
        };
      },
    ],
    [
      getFixedCostsCompanyDefaultCurrency,
      (state, action) => {
        const { defaultCurrency } = action.payload;

        return {
          ...state,
          basicInformation: {
            ...state.basicInformation,
            currency: defaultCurrency,
          },
          swapBasicInformation: {
            ...state.swapBasicInformation,
            currency: defaultCurrency,
          },
          companyDefaultCurrency: defaultCurrency,
        };
      },
    ],
    [
      getFixedCostsCountries,
      (state, action) => {
        const {
          user: { authorizedModules, profile },
          countries,
          error,
        } = action.payload;

        if (error) {
          return {
            ...state,
            serverError: true,
          };
        }

        const countriesOptions = [];
        const defaultCurrencyMappings = filterCountriesByAccess({
          countries,
          module: userModules.FIXED_COSTS_MANAGEMENT,
          authorizedModules,
        });

        defaultCurrencyMappings.map((country) =>
          countriesOptions.push({
            value: country.name,
            label: country.name,
            code: country.code,
          })
        );

        if (isBlank(countriesOptions)) {
          countriesOptions.push({ value: profile.country, label: profile.country });
        }

        return {
          ...state,
          allCountries: countriesOptions,
          filteredCountries: countriesOptions,
          defaultCurrencyMappings,
        };
      },
    ],
    [
      setFixedCostsFilteredCountries,
      (state, action) => {
        return {
          ...state,
          filteredCountries: action.payload,
        };
      },
    ],
    [
      getFixedCostsFxSources,
      (state, action) => {
        const { fxSources } = action.payload;
        const fxSourcesOptions = [];

        if (fxSources) {
          fxSources.map((source) =>
            fxSourcesOptions.push({
              value: source.id,
              label: source.name,
            })
          );
        }
        return {
          ...state,
          fxSources: fxSourcesOptions,
        };
      },
    ],
    [
      getFixedCostsCategories,
      (state, action) => {
        const {
          payload: { categories },
        } = action;

        return {
          ...state,
          categories,
        };
      },
    ],
    [
      getFixedCostsBasicInformationChange,
      (state, action) => {
        const { field, value, other } = action.payload;

        return {
          ...state,
          basicInformation: {
            ...state.basicInformation,
            [field]: value,
            ...other,
          },
        };
      },
    ],
    [
      basicInformationSave,
      (state, action) => {
        const { response } = action.payload;

        return {
          ...state,
          swapBasicInformation: mapResponseToBasicInfoModel(response),
          savedFixedCostId: response.id,
          status: response.status,
        };
      },
    ],
    [
      fixedCostsEtagUpdate,
      (state, action) => {
        return {
          ...state,
          savedFixedCostsEtag: action.payload,
          swapBasicInformation: R.clone(state.basicInformation),
          swapRules: R.clone(state.rules),
        };
      },
    ],
    [
      getFixedCostsFormulaOperators,
      (state, action) => {
        return {
          ...state,
          formulaOperators: action.payload,
        };
      },
    ],
    [
      fixedCostsRuleChange,
      (state, action) => {
        const {
          ruleData: { index, field, value, other },
        } = action.payload;
        const rules = R.clone(state.rules);

        rules[index] = {
          ...rules[index],
          [field]: value,
          ...other,
        };

        return {
          ...state,
          rules,
        };
      },
    ],
    [
      changeDistributionType,
      (state) => {
        const isAutomatic = !state.distribution.isAutomatic;
        return {
          ...state,
          distribution: {
            ...state.distribution,
            isAutomatic,
          },
        };
      },
    ],
    [
      setDistributionTableRowsAndTotal,
      (state, action) => {
        const { rows, total } = action.payload;

        const {
          distribution: { isAutomatic },
        } = state;
        const swapKey = isAutomatic ? 'swapAutomatic' : 'swapManual';

        return {
          ...state,
          distribution: {
            ...state.distribution,
            tableConfigWillLoad: false,
            table: {
              ...state.distribution.table,
              rows,
              total,
              dirtyRows: [],
            },
            [swapKey]: {
              table: {
                ...state.distribution[swapKey].table,
                rows,
                total,
              },
            },
          },
        };
      },
    ],
    [
      cellChange,
      (state, action) => {
        const {
          dirtyRow: { id, value },
          total,
        } = action.payload;

        const dataForDirtyRows = transformDirtyRowsData({
          data: state.distribution.table.dirtyRows,
          id,
          value,
        });

        return {
          ...state,
          distribution: {
            ...state.distribution,
            table: {
              ...state.distribution.table,
              dirtyRows: dataForDirtyRows,
              total,
            },
          },
        };
      },
    ],
    [
      basicInformationReset,
      (state) => {
        return {
          ...state,
          basicInformation: state.swapBasicInformation,
        };
      },
    ],
    [
      rulesBuilderRevert,
      (state) => {
        const {
          distribution: { swapIsAutomatic, swapAutomatic, swapManual },
        } = state;
        const swapDistribution = swapIsAutomatic ? swapAutomatic : swapManual;

        return {
          ...state,
          rules: state.swapRules,
          distribution: {
            ...state.distribution,
            isAutomatic: swapIsAutomatic,
            table: {
              ...state.distribution.table,
              dirtyRows: [],
              ...swapDistribution.table,
            },
            tableConfigWillLoad: !swapIsAutomatic,
          },
        };
      },
    ],
    [
      rulesBuilderReset,
      (state) => {
        return {
          ...state,
          rules: initialState.rules,
          distribution: initialState.distribution,
        };
      },
    ],
    [
      redirectToRoot,
      (state) => {
        return {
          ...state,
          redirectToRoot: true,
        };
      },
    ],
    [storeReset, () => ({ ...initialState })],
  ]),
  initialState
);

export default fixedCostsReducer;
