import { combineReducers } from 'redux';
import { handleActions } from 'redux-actions';
import * as R from 'ramda';
import localPreparation from '../components/localPreparation/reducer';
import salaryIncrease from '../components/salaryIncrease/reducer';
import payscaleIncrease from '../components/payscaleIncrease/reducer';
import dashboard from '../components/dashboard/reducer';
import {
  loadSettingsStatuses,
  loadMainSettingsStatuses,
  statusesReset,
  updateStepStatus,
} from '../actions';

export const initialMainState = {
  isLoadingLocalStatuses: true,
  statusData: {},
  isManualSetup: null,
  countryName: '',
  statusError: false,
};

const main = handleActions(
  new Map([
    [statusesReset, () => R.clone(initialMainState)],
    [
      loadSettingsStatuses,
      (state, action) => {
        const {
          ready,
          error,
          payload: { statusData },
        } = action;

        if (!ready) {
          return {
            ...state,
          };
        }

        if (error) {
          return {
            ...state,
            statusError: error,
          };
        }

        return {
          ...state,
          statusData,
        };
      },
    ],
    [
      loadMainSettingsStatuses,
      (state, action) => {
        const {
          ready,
          error,
          payload: { countryName, statusData, isManualSetup },
        } = action;

        if (!ready) {
          return {
            ...state,
            isLoadingLocalStatuses: true,
          };
        }

        if (error) {
          return {
            ...state,
            statusError: error,
            isLoadingLocalStatuses: false,
          };
        }

        return {
          ...state,
          statusData,
          countryName,
          isManualSetup,
          isLoadingLocalStatuses: false,
        };
      },
    ],
    [
      updateStepStatus,
      (state, action) => {
        const { ready, error, payload } = action;

        if (!ready) {
          return {
            ...state,
            isSaving: true,
          };
        }

        if (error) {
          return {
            ...state,
            isSaving: false,
          };
        }

        return {
          ...state,
          statusData: payload.data,
          isSaving: false,
        };
      },
    ],
  ]),
  R.clone(initialMainState)
);

export default combineReducers({
  main,
  localPreparation,
  salaryIncrease,
  payscaleIncrease,
  dashboard,
});
