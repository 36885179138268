"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _reactTextareaAutosize = _interopRequireDefault(require("react-textarea-autosize"));
var _styledComponents = _interopRequireWildcard(require("styled-components"));
var _Input = _interopRequireDefault(require("./Input.style"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
var DEFAULT_MAX_HEIGHT = '226px';
var StyledInputAsTextarea = _Input["default"].withComponent(_reactTextareaAutosize["default"]);
var StyledTextarea = (0, _styledComponents["default"])(StyledInputAsTextarea).withConfig({
  displayName: "Textareastyle__StyledTextarea",
  componentId: "sc-74ogk2-0"
})(["", ";"], function (props) {
  var haserrors = props.haserrors,
    palette = props.theme.palette,
    scrollable = props.scrollable;
  return (0, _styledComponents.css)(["background-color:transparent;display:block;resize:none;border-bottom:1px solid ", ";overflow-y:hidden !important;", ""], haserrors ? palette.validation.error : palette.gray.bravo, scrollable && (0, _styledComponents.css)(["overflow-y:auto !important;max-height:", ";"], DEFAULT_MAX_HEIGHT));
});
StyledTextarea.displayName = 'StyledTextarea';
var _default = StyledTextarea;
exports["default"] = _default;