import header from '../header';

export default {
  FORECAST_REWARDS: 'Forecast Rewards',
  FORECAST_REWARDS_SUBTITLE: 'Configuration',
  PROVIDE_DETAILS:
    'You can simulate the list of rewards owned by an employee on a date in the future by providing the following details:',
  TYPE: 'Type',
  TYPE_TEXT: 'Text',
  TYPE_VARIABLE_LIST: 'Variable List',
  EMPLOYEE_LIST: 'Employee List',
  EMPLOYEE_LIST_TEXTAREA_PLACEHOLDER: 'Insert one ID or more, separated by new lines.',
  EMPLOYEE_LIST_MAX_LENGTH_MESSAGE:
    'Employee List should have a maximum of %LENGTH% employees IDs.',
  VARIABLE_LIST_PLACEHOLDER: 'Type variable list',
  LIST: 'List',
  DATE: 'Calculate On Date',
  DATE_INFO: 'Calculate the rewards owned by the employee at this date.',
  CALCULATE: 'Calculate',
  CALCULATION_IN_PROGRESS: 'Calculation in progress',
  SEARCH_VARIABLES_PLACEHOLDER: 'Search variables name',
  NO_VARIABLES_FOUND: 'No variables found',
  SEARCHING_LABEL: 'Searching..',
  ...header,
};
