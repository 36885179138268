const DEFAULT_POPULATIONS = [
  {
    key: 0,
    id: 0,
    name: 'Rule0',
    eligibility: {},
  },
];

export default {
  populations: DEFAULT_POPULATIONS,
  populationsSnapshot: DEFAULT_POPULATIONS,
  etag: '',
  systemVariables: [],
  eligibilityOperators: [],
  lookUpValues: {},
  errors: [],
};
