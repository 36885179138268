"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var StyledErrorPage = _styledComponents["default"].div.withConfig({
  displayName: "ErrorPagestyle__StyledErrorPage",
  componentId: "sc-udvw0i-0"
})(["", ";"], function (_ref) {
  var theme = _ref.theme;
  var spacing = theme.spacing,
    typography = theme.typography,
    palette = theme.palette;
  return (0, _styledComponents.css)(["width:100%;height:100%;display:flex;flex-direction:column;align-items:center;.header{padding:", " 0;.logo-with-text{display:flex;align-items:center;font-size:", ";color:", ";width:180px;span{font-size:", ";border-left:2px solid ", ";padding-left:", ";margin-left:", ";}}}.content{display:flex;flex-direction:column;align-items:center;margin:", " 0;h1{color:", ";margin:", " 0 ", ";}.text{p{text-align:center;color:", ";max-width:560px;}a{font-weight:", ";}}button{margin-top:", ";}}"], spacing.basePlus, typography.paragraphPlus, palette.primary, typography.size.table, palette.primary, spacing.tiny, spacing.small, spacing.large, palette.primary, spacing.tiny, spacing.tiny, palette.gray.alpha, typography.weight.bold, spacing.base);
});
StyledErrorPage.displayName = 'StyledErrorPage';
var _default = StyledErrorPage;
exports["default"] = _default;