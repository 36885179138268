import React, { Component, Fragment } from 'react';
import * as R from 'ramda';
import {
  Button,
  LinkButton,
  Card,
  PageLoader,
  Doughnut,
  Paragraph,
  Currency,
} from '@trs/components';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';
import StyledOverallImpact from './OverallImpact.style';
import { showBanner, removeAllBanners } from '../../../../../actions/globalActions';
import { calculateRewardOverallImpact, overallImpactStoreReset } from '../actions';
import { exportPieChart, calculatePercent } from './helpers';
import ImpactDetailsDialog from './ImpactDetailsDialog';

export const REQUEST_CONTEXT = {
  REWARDS: 'rewards',
  REWARDS_IMPACT: 'rewards-impact',
};

class OverallImpact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDialog: false,
    };
  }

  componentWillUnmount() {
    this.props.actions.removeAllBanners();
  }

  calculateImpact = () => {
    const { rewardId, payload, savedRewardEtag } = this.props;
    if (this.props.validation()) {
      this.props.actions.calculateRewardOverallImpact(rewardId, payload, REQUEST_CONTEXT.REWARDS, {
        headers: { ETag: savedRewardEtag },
      });
    }
  };

  showDetails = () => {
    this.setState({
      showDialog: true,
    });
  };

  hideDetails = () => {
    this.setState({
      showDialog: false,
    });
  };

  render() {
    const { error, disabled, rewardId, labels, impact, theme, calculateAction } = this.props;
    const hasDetails = R.path(['overallImpact', 'detail'], impact);
    const calculated = R.path(['overallImpact', 'calculated'], impact);
    const isCalculated = calculated !== undefined ? calculated : true;
    const currency = R.path(['overallImpact', 'summary', 'rewardCurrency'], impact);
    const eligibilityNumber = R.path(['overallImpact', 'summary', 'employeesAffected'], impact);

    return (
      <StyledOverallImpact hasRewardID={!!rewardId} data-testid="overall-impact-wrapper">
        <Card title={labels.RULES_BUILDER_WIDGET_OVERALL_IMPACT_TITLE}>
          {impact.calculatingOverallImpact || error ? (
            <PageLoader
              error={error}
              className="tile-spinner"
              message={labels.RULES_BUILDER_WIDGET_OVERALL_IMPACT_CALCULATING}
            />
          ) : (
            <Fragment>
              {!impact.calculatedOverallImpact ? (
                <div className="impact-description">
                  <div className="impact-first-paragraph">
                    {labels.RULES_BUILDER_WIDGET_OVERALL_IMPACT_FIRST_PARAGRAPH}
                  </div>
                  <div className="impact-second-paragraph">
                    {labels.RULES_BUILDER_WIDGET_OVERALL_IMPACT_SECOND_PARAGRAPH}
                  </div>
                </div>
              ) : (
                <Fragment>
                  <div className="doughnut-wrapper">
                    <Doughnut
                      data={exportPieChart(impact.overallImpact)}
                      height={220}
                      colors={R.path(['extra', 'overallImpactColorMap'], theme)}
                    />
                    <div className="percentage">
                      <span>
                        {isCalculated
                          ? `${calculatePercent(impact.overallImpact)}%`
                          : labels.RULES_BUILDER_WIDGET_OVERALL_IMPACT_COUNTING}
                      </span>
                      <p className="percentage-label">
                        {labels.RULES_BUILDER_WIDGET_OVERALL_IMPACT_CHART_LABEL}
                      </p>
                    </div>
                  </div>
                  {isCalculated && (
                    <div className="overall-impact-details">
                      <Paragraph>
                        <Currency currency="" className="employees-affected">
                          {R.path(['overallImpact', 'summary', 'employeesAffected'], impact)}
                        </Currency>
                        <span> {labels.RULES_BUILDER_WIDGET_OVERALL_IMPACT_DETAIL_LABEL} </span>
                        <Currency currency="" className="total-employees">
                          {R.path(['overallImpact', 'summary', 'totalEmployees'], impact)}
                        </Currency>
                        <span>
                          {' '}
                          {labels.RULES_BUILDER_WIDGET_OVERALL_IMPACT_DETAIL_SECOND_LABEL}
                        </span>
                      </Paragraph>
                    </div>
                  )}
                  {hasDetails && isCalculated && (
                    <div className="overall-impact-details">
                      <Paragraph>
                        <Currency currency={currency} className="total-employees">
                          {R.path(['overallImpact', 'summary', 'totalRewardCost'], impact)}
                        </Currency>
                      </Paragraph>
                    </div>
                  )}
                </Fragment>
              )}
              <div className="impact-action">
                <Button
                  action={calculateAction || this.calculateImpact}
                  disabled={disabled || impact.calculatingOverallImpact}
                >
                  {impact.calculatedOverallImpact
                    ? labels.RULES_BUILDER_WIDGET_OVERALL_IMPACT_UPDATE_BUTTON
                    : labels.RULES_BUILDER_WIDGET_OVERALL_IMPACT_CALCULATE_BUTTON}
                </Button>
              </div>
              {hasDetails && (
                <div className="impact-detail-view">
                  <LinkButton
                    onClick={
                      eligibilityNumber > 0
                        ? this.showDetails
                        : (e) => {
                            e.preventDefault();
                          }
                    }
                    disabled={!eligibilityNumber > 0}
                  >
                    {labels.RULES_BUILDER_WIDGET_OVERALL_IMPACT_DETAILS_LINK}
                  </LinkButton>
                </div>
              )}
            </Fragment>
          )}
        </Card>
        {this.state.showDialog && (
          <ImpactDetailsDialog impact={impact} theme={theme} onExit={this.hideDetails} />
        )}
      </StyledOverallImpact>
    );
  }
}

OverallImpact.defaultProps = {
  disabled: false,
  validation: R.always(),
  payload: [],
  savedRewardEtag: '',
  calculateAction: null,
};

OverallImpact.propTypes = {
  error: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  rewardId: PropTypes.string.isRequired,
  impact: PropTypes.shape({
    calculatingOverallImpact: PropTypes.bool,
    calculatedOverallImpact: PropTypes.bool,
    overallImpact: PropTypes.shape({}),
  }).isRequired,
  labels: PropTypes.shape({
    RULES_BUILDER_WIDGET_OVERALL_IMPACT_TITLE: PropTypes.string,
    RULES_BUILDER_WIDGET_OVERALL_IMPACT_CALCULATING: PropTypes.string,
    RULES_BUILDER_WIDGET_OVERALL_IMPACT_FIRST_PARAGRAPH: PropTypes.string,
    RULES_BUILDER_WIDGET_OVERALL_IMPACT_SECOND_PARAGRAPH: PropTypes.string,
    RULES_BUILDER_WIDGET_OVERALL_IMPACT_COUNTING: PropTypes.string,
    RULES_BUILDER_WIDGET_OVERALL_IMPACT_CHART_LABEL: PropTypes.string,
    RULES_BUILDER_WIDGET_OVERALL_IMPACT_DETAIL_LABEL: PropTypes.string,
    RULES_BUILDER_WIDGET_OVERALL_IMPACT_DETAIL_SECOND_LABEL: PropTypes.string,
    RULES_BUILDER_WIDGET_OVERALL_IMPACT_UPDATE_BUTTON: PropTypes.string,
    RULES_BUILDER_WIDGET_OVERALL_IMPACT_CALCULATE_BUTTON: PropTypes.string,
    RULES_BUILDER_WIDGET_OVERALL_IMPACT_DETAILS_LINK: PropTypes.string,
  }).isRequired,
  theme: PropTypes.shape({}).isRequired,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  validation: PropTypes.func,
  payload: PropTypes.arrayOf(PropTypes.shape({})),
  savedRewardEtag: PropTypes.string,
  calculateAction: PropTypes.func,
};

const mapStateToProps = (state) => ({
  error: state.overallImpact.error,
  impact: state.overallImpact,
  rewardId: state.rewards.savedRewardId,
  labels: state.cms.rewards.data,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    { calculateRewardOverallImpact, overallImpactStoreReset, showBanner, removeAllBanners },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(OverallImpact));
