"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
var _utils = require("@trs/utils");
var _Icon = _interopRequireDefault(require("../icon/Icon.style"));
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
var StyledLegendPagination = _styledComponents["default"].div.withConfig({
  displayName: "LegendPaginationstyle__StyledLegendPagination",
  componentId: "sc-87ttb6-0"
})(["", " .legend-previous-page{margin-right:10px;}"], function (props) {
  var _props$theme = props.theme,
    color = _props$theme.palette,
    spacing = _props$theme.spacing;
  var nextPage = props.nextPage,
    previousPage = props.previousPage;
  return (0, _styledComponents.css)(["position:absolute;bottom:", ";right:50%;transform:translateX(50%);", " ", "{border 1px solid ", ";height:", ";width:", ";display:inline-block;position:relative;cursor:pointer;&:before{position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);}}", " ", ""], spacing.small, _utils.media.tablet(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n      right: ", ";\n      transform: translate(0%, 0%);\n  "])), spacing.small), _Icon["default"], color.gray.alpha, spacing.basePlus, spacing.basePlus, !nextPage && (0, _styledComponents.css)([".legend-next-page{border-color:", ";color:", ";}"], color.gray.charlie, color.gray.charlie), !previousPage && (0, _styledComponents.css)([".legend-previous-page{border-color:", ";color:", ";}"], color.gray.charlie, color.gray.charlie));
});
StyledLegendPagination.displayName = 'StyledLegendPagination';
var _default = StyledLegendPagination;
exports["default"] = _default;