const initialState = {
  isFetchingReports: false,
  isFetchingExternalReports: false,
  isFetchingFlexReports: false,
  isLoadingFlexEmbedReport: false,
  isAddingExternalReport: false,
  isFlexReportPBIVisible: false,
  reports: [],
  externalReports: [],
  flexReports: [],
  exportCountries: [],
  selectedCountry: {},
  isExportingCountryRewardsReport: false,
  isSubmitting: false,
  isLoading: false,
  report: {
    list: [],
    columnDefinition: {},
    name: '',
    filterOptions: [],
    totalResults: 0,
    fetchingList: false,
  },
  newReport: {
    name: '',
    url: '',
  },
};

export default initialState;
