import { createAction } from 'redux-actions';
import * as R from 'ramda';
import { GET, POST } from 'config/http';
import { connectors } from 'config/api';
import { showBanner } from 'actions/globalActions';
import { TextOnly } from 'modules/common/Text';
import types from './types';

export {
  getProviders,
  getProvidersShares,
  updateProviderData,
  saveProviderData,
  addNewProvider,
  clearProvider,
  loadSharesProviders,
} from './sharesProviders';

export const changeProvider = createAction(types.CHANGE_PROVIDER);
export const getSharesDataLoadStatus = createAction(types.SHARES_DATA_LOAD_STATUS);
export const selectSharesDataFile = createAction(types.SHARES_FILE_SELECTED);
export const saveShareFileData = createAction(types.SHARES_SAVE_FILE_DATA);
export const validateShareFileData = createAction(types.SHARES_VALIDATE_FILE_DATA);
export const resetStore = createAction(types.RESET_STORE);

export const loadSharesDataLoadStatus = (provider) => (dispatch) =>
  dispatch(
    getSharesDataLoadStatus(
      GET(`${connectors.sharesUpload}/${provider}`, {}, { showErrorModal: true, dispatch })
        .then((response) => R.path(['data', 'response'], response))
        .catch((error) => {
          return Promise.reject(error);
        })
    )
  );

const postDataFileRequest = (inValidationMode = false, dispatch, getState) => {
  const {
    onboarding: {
      shares: { selectedProvider, selectedFile },
    },
  } = getState();
  const formData = new FormData();

  formData.append(
    'file',
    new Blob([selectedFile], { type: 'text/csv' }),
    R.prop('name', selectedFile)
  );

  return POST(
    `${connectors.sharesUpload}/${R.prop(
      'id',
      selectedProvider
    )}?RunInValidationMode=${inValidationMode}`,
    formData,
    {},
    { showErrorModal: true, dispatch }
  )
    .then((response) => {
      dispatch(
        showBanner({
          type: 'success',
          content: TextOnly({
            path: inValidationMode
              ? 'onboarding.LOAD_DATA_UPLOADED_FILE_VALIDATION_STARTED'
              : 'generic.UPLOAD_FILE_SUCCESS',
          }),
        })
      );
      return R.path(['data', 'response'], response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const saveEmployeesData = () => (dispatch, getState) =>
  dispatch(saveShareFileData(postDataFileRequest(false, dispatch, getState)));

export const validateEmployeesData = () => (dispatch, getState) =>
  dispatch(validateShareFileData(postDataFileRequest(true, dispatch, getState)));
