import { createAction } from 'redux-actions';
import * as R from 'ramda';
import { isBlank } from '@trs/utils';
import { benchmark } from '../../../../../../../config/api';
import { GET, POST } from '../../../../../../../config/http';
import { loadGlobalIdentifiers } from '../../../actions';
import { dispatchError } from '../../../../common/helpers';
import { showBanner } from '../../../../../../../actions/globalActions';
import { TextOnly } from '../../../../../../common/Text';

export const actionTypes = {
  BENCHMARKING_GET_GLOBAL_DASHBOARD_DATA: 'BENCHMARKING/GET_GLOBAL_DASHBOARD_DATA',
  BENCHMARKING_GET_COUNTRIES_FROM_CLUSTER: 'BENCHMARKING_GET_COUNTRIES_FROM_CLUSTER',
  BENCHMARKING_CAN_INITIATE: 'BENCHMARKING/CAN_INITIATE',
  BENCHMARKING_INITIATE: 'BENCHMARKING/INITIATE',
};

export const getGlobalDashboardData = createAction(
  actionTypes.BENCHMARKING_GET_GLOBAL_DASHBOARD_DATA
);

export const getCountriesFromCluster = createAction(
  actionTypes.BENCHMARKING_GET_COUNTRIES_FROM_CLUSTER
);

export const getCanInitiate = createAction(actionTypes.BENCHMARKING_CAN_INITIATE);
export const initiateNewBenchmarking = createAction(actionTypes.BENCHMARKING_INITIATE);

export const loadGlobalDashboardData = () => dispatch =>
  dispatch(
    getGlobalDashboardData(
      dispatch(loadGlobalIdentifiers(true)).then(response => {
        const { id } = response;

        if (isBlank(id)) return {};

        return GET(`${benchmark.benchmarking}/${id}/countries`).then(resp => {
          const data = R.path(['data', 'response'], resp);
          return { data, globalBenchmarkingId: id };
        });
      })
    )
  );

export const loadCountriesFromCluster = cluster => dispatch =>
  dispatch(
    getCountriesFromCluster(
      dispatch(loadGlobalIdentifiers(true)).then(response => {
        const { id } = response;
        if (response.status === 204) return {};

        return GET(
          `${benchmark.benchmarking}/${id}/countries?clusterName=${encodeURIComponent(cluster)}`
        ).then(resp => {
          const data = R.path(['data', 'response'], resp);
          return { data, globalBenchmarkingId: id };
        });
      })
    )
  );

export const initiateNew = () => (dispatch, getState) => {
  const globalBenchmarkingId = R.path(
    ['benchmarking', 'global', 'dashboard', 'globalBenchmarkingId'],
    getState()
  );
  return dispatch(
    initiateNewBenchmarking(
      POST(`${benchmark.initiateNew}?sourceId=${globalBenchmarkingId}`)
        .then(() => {
          dispatch(
            showBanner({
              type: 'success',
              content: TextOnly({ path: 'benchmarking.INITIATE_SUCCESS' }),
            })
          );
          dispatch(getCanInitiate(Promise.resolve({ canInitiate: false })));

          dispatch(loadGlobalDashboardData());
        })
        .catch(error => {
          dispatchError(error, dispatch);
          return Promise.reject(error);
        })
    )
  );
};

export const loadCanInitiate = () => dispatch =>
  dispatch(
    getCanInitiate(
      GET(`${benchmark.globalSettings}/can-initiate`).then(body => {
        const canInitiate = R.path(['data', 'response', 'canInitiate'], body) || false;
        return { canInitiate };
      })
    )
  );
