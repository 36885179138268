"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var StyledTimepicker = _styledComponents["default"].div.withConfig({
  displayName: "Timepickerstyle__StyledTimepicker",
  componentId: "sc-1q2avso-0"
})(["", ";"], function (props) {
  var position = props.position;
  var _props$theme = props.theme,
    spacing = _props$theme.spacing,
    palette = _props$theme.palette,
    typography = _props$theme.typography,
    _props$theme$extra = _props$theme.extra,
    genericBorderRadius = _props$theme$extra.genericBorderRadius,
    boxShadow = _props$theme$extra.boxShadow;
  var positioningProps;
  switch (position) {
    case 'top':
      positioningProps = 'bottom: calc(100% + 10px);  left: 0; right: 0;';
      break;
    case 'bottom':
      positioningProps = 'top: 40px; left: 0; right: 0;';
      break;
    case 'right':
      positioningProps = 'right: calc(-100% + 30px);';
      break;
    case 'left':
    default:
      positioningProps = 'left: -100%;';
      break;
  }
  return (0, _styledComponents.css)(["display:inline-block;position:relative;input{cursor:pointer;}.icon-ion-android-time{top:-5px;padding:0;cursor:pointer;pointer-events:none;}.timepicker-dropdown{position:absolute;", " z-index:10;padding-bottom:", ";margin-top:-1px;border:none;border-radius:", ";box-shadow:", ";background-color:", ";.header{padding:0 6px;h3{display:flex;align-items:center;justify-content:center;height:44px;font:inherit;font-weight:", ";}.Tabs-container{padding-bottom:", ";.tab-headers{height:", ";.tab-wrapper{> div{padding:0 ", ";font-size:", ";}}}.tabs-active-content{display:none;}}}button{padding:0;color:inherit;font:inherit;outline:inherit;cursor:pointer;border:none;background:none;&:hover,&:focus{background-color:", ";}&.active{background-color:", ";color:", ";&:hover,&:focus{background-color:", ";}}}}"], positioningProps, spacing.xSmall, genericBorderRadius, boxShadow, palette.accent.delta, typography.weight.thick, spacing.small, spacing.base, spacing.base, typography.size.misc, palette.accent.charlie, palette.secondary, palette.basic.white, palette.accent.charlie);
});
StyledTimepicker.displayName = 'StyledTimepicker';
var _default = StyledTimepicker;
exports["default"] = _default;