"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var StyleButton = _styledComponents["default"].button.withConfig({
  displayName: "Buttonstyle__StyleButton",
  componentId: "sc-1xdbh20-0"
})(["", ";"], function (props) {
  var _props$theme = props.theme,
    palette = _props$theme.palette,
    spacing = _props$theme.spacing,
    typography = _props$theme.typography,
    genericBorderRadius = _props$theme.extra.genericBorderRadius;
  var disabled = props.disabled,
    fullWidth = props.fullWidth,
    variant = props.variant,
    medium = props.medium,
    icon = props.icon,
    capitalization = props.capitalization;
  var colors = {};
  switch (variant) {
    case 'naked':
      {
        colors = {
          normal: {
            border: palette.secondary,
            background: 'transparent',
            text: palette.secondary
          },
          hover: {
            border: palette.primary,
            background: palette.accent.delta,
            text: palette.primary
          }
        };
        break;
      }
    case 'link':
      {
        colors = {
          normal: {
            border: 'none',
            background: 'transparent',
            text: palette.secondary
          }
        };
        colors.hover = colors.normal;
        break;
      }
    case 'primary':
    default:
      {
        colors = {
          normal: {
            border: palette.secondary,
            background: palette.secondary,
            text: palette.basic.white
          },
          hover: {
            border: palette.primary,
            background: palette.primary,
            text: palette.basic.white
          }
        };
        break;
      }
  }
  if (disabled) {
    colors = {
      normal: {
        border: palette.gray.charlie,
        background: palette.gray.charlie,
        text: palette.basic.white
      }
    };
    colors.hover = colors.normal;
  }
  return (0, _styledComponents.css)(["cursor:pointer;width:", ";outline:none;", " ", ";", ";", ";", ";"], fullWidth ? '100%' : 'auto', variant === 'link' && (0, _styledComponents.css)(["background:none;border:none;color:", ";font-family:inherit;font-size:inherit;font-style:inherit;padding:0;text-decoration:underline;text-transform:inherit;", ";"], palette.secondary, disabled && (0, _styledComponents.css)(["color:", ";"], palette.gray.charlie)), variant !== 'link' && variant !== 'text' && (0, _styledComponents.css)(["background-color:", ";border:1px solid ", ";border-radius:", ";color:", ";font-family:", ";font-size:", ";font-weight:", ";min-width:96px;padding:", " ", ";text-transform:", ";transition:background-color .2s ease-in-out;", " ", " ", ""], colors.normal.background, colors.normal.border, genericBorderRadius, colors.normal.text, typography.font.primary, typography.size.paragraph, typography.weight.semiBold, spacing.xxSmall, spacing.base, capitalization, medium && (0, _styledComponents.css)(["min-width:", ";padding:", " ", ";"], spacing.basePlus, spacing.tiny, spacing.small), icon && (0, _styledComponents.css)([".button-icon{margin-right:", ";line-height:0.5;position:relative;top:2px;}"], spacing.small), !disabled && (0, _styledComponents.css)(["&:hover{background-color:", ";border-color:", ";color:", ";}"], colors.hover.background, colors.hover.border, colors.hover.text)), variant === 'text' && (0, _styledComponents.css)(["color:", ";background-color:'transparent';.button-icon{margin-right:", ";line-height:0.5;position:relative;top:2px;}"], palette.gray.charlie, spacing.tiny), variant === 'naked' && (0, _styledComponents.css)(["", ";"], disabled && (0, _styledComponents.css)(["color:", ";border-color:", ";background-color:", ";"], palette.gray.charlie, palette.gray.charlie, palette.basic.white)), disabled && (0, _styledComponents.css)(["cursor:not-allowed;"]));
});
StyleButton.displayName = 'StyleButton';
var _default = StyleButton;
exports["default"] = _default;