import { STATUSES } from '../../common/DataLoad/helpers';

const initialState = {
  isDataLoadLoading: false,
  isUploadInProgress: false,
  isValidateInProgress: false,
  selectedFile: null,
  error: null,

  overallStatus: STATUSES.notUploaded,
  fileName: '',
  lastUploaded: '',
  runInValidationMode: false,
  errorFilePath: '',

  payscaleConfigLoading: true,
  dimensionsLoading: false,
  configurationLoading: false,
  isCalculating: true,
  dimensionsOptions: [],
  dimensionsData: [],
  originalDimensionsData: [],
  payscales: {
    ETag: '',
    isLoading: true,
    isUploadInProgress: false,
    dirtyRows: [],
    isSystemGenerated: true,
    originalIsSystemGenerated: true,
    isCustomPayscaleEditable: false,
    isDimensionsToggleDisabled: false,
    isSystemGeneratedToggleDisabled: false,
    isTableToggleDisabled: false,
  },
};

export default initialState;
