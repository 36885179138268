import * as R from 'ramda';
import { isBlank } from '@trs/utils';
import { v4 as uuid } from 'uuid';

const mapEmployeesFromPayload = (employeeList) => ({
  list: R.map(
    (item) => ({
      ...item,
      id: uuid(),
      isCheckboxDisabled: item.isWindowOpen || !item.isEditable,
      isActionDisabled: !item.isEditable,
      menuTransform: (menuList) => {
        if (item.isWindowOpen) {
          return R.reject((menuItem) => R.prop('key', menuItem) === 'open', menuList);
        }
        return R.reject((menuItem) => R.prop('key', menuItem) === 'close', menuList);
      },
    }),
    R.path(['payload', 'employees', 'list'], employeeList) || []
  ),
  filterOptions: R.map(
    (item) => ({
      title: item.key,
      key: item.key,
      list: item.options,
    }),
    R.path(['payload', 'employees', 'filterOptions'], employeeList) || []
  ),
  fetchingList: false,
  totalResults: isBlank(R.path(['payload', 'employees', 'count'], employeeList))
    ? 0
    : R.path(['payload', 'employees', 'count'], employeeList),
});

export default mapEmployeesFromPayload;
