import * as R from 'ramda';
import APP_CONFIG from '@trs/config';
import { isBlank, toBinary } from '@trs/utils';
import { sortAscendingByOrderIndex } from 'modules/modeller/util';

const { GLOBAL_KEYWORD } = APP_CONFIG;

export const transformCountriesToOptions = (countries) =>
  countries.map((country) => ({
    label: `${country.name} (${country.code})`,
    value: country.name,
    code: country.code,
    defaultCurrency: country.defaultCurrency,
  }));

export const transformCurrenciesToOptions = (currencies) =>
  currencies.map((currency) => ({
    label: currency.code,
    value: currency.code,
  }));

export const transformSourcesToOptions = (sources) =>
  sources.map((source) => ({
    label: source.name,
    value: source.id,
  }));

export const transformCategoriesToOptions = (categories) =>
  categories.reduce(
    (acc, source) => ({
      ...acc,
      [source.id]: {
        label: source.displayName,
        orderIndex: source.orderIndex,
      },
    }),
    {}
  );

const getLocationAndCode = ({ code, locationName }) => ({
  label: locationName,
  value: code,
});

export const transformLocationsToOptions = (locations) =>
  R.map(R.map(getLocationAndCode), locations);

export const transformDefaultValuesToOptions = ({ id, defaultDimensionValues }, countries) => {
  const data = defaultDimensionValues.map((field) => ({
    value: field.code,
    label: field.displayName,
    code: field.code,
    defaultCurrency: R.prop('defaultCurrency', R.find(R.propEq('code', field.code))(countries)),
  }));
  return { [id]: data };
};

export const transformEmployeeDimensionsWithPreviousDimension = (dimensions) => {
  const updatedDimensions = [...dimensions];
  dimensions.forEach((dimension, index) => {
    if (dimension.isDefault || isBlank(dimension.nextDimension)) {
      updatedDimensions[index] = { ...updatedDimensions[index], previousDimension: '' };
    }

    if (!dimension.isDefault && !isBlank(dimension.nextDimension)) {
      updatedDimensions[index] = {
        ...updatedDimensions[index],
        previousDimension: R.equals(index, 1) ? '' : updatedDimensions[index - 1].id,
      };
    }
  });

  return updatedDimensions;
};

export const getCorrelatedDimensions = ({ dimensionId, baseDimensions }) => {
  const index = R.findIndex(R.propEq('id', dimensionId))(baseDimensions);
  const slicedBaseDimensions = R.slice(0, index + 1)(baseDimensions);

  return R.reduce(
    (acc, dimension) =>
      isBlank(dimension.previousDimension) ? acc : acc.concat(dimension.previousDimension),
    [],
    slicedBaseDimensions
  );
};

export const getDimensionsForReset = ({ dimensionId, baseDimensions }) => {
  const index = R.findIndex(R.propEq('id', dimensionId))(baseDimensions);
  return R.slice(index + 1, baseDimensions.length - 1)(baseDimensions);
};

export const transformCorrelatedDimensionsPayload = ({
  dimensionId,
  dimensionValue,
  isDefault,
  baseDimensions,
  instanceFields,
  instanceId,
}) => {
  const correlatedDimensions = getCorrelatedDimensions({ dimensionId, baseDimensions });

  let payload = R.reduce(
    (result, dimId) => ({
      ...result,
      [dimId]: toBinary(R.path([instanceId, dimId, 'value'], instanceFields)),
    }),
    {}
  )(correlatedDimensions);

  if (!isDefault) {
    // add selected dimension value
    payload = { ...payload, [dimensionId]: toBinary(dimensionValue) };
  }

  return payload;
};

const firstInstanceId = 1;
export const mapValuesToKeys = (values) => ({
  variables: R.path([0, 'data'], values),
  countries: R.filter(
    (country) => country.code !== GLOBAL_KEYWORD,
    transformCountriesToOptions(R.path([1, 'data'], values))
  ),
  currencies: transformCurrenciesToOptions(R.path([2, 'data'], values)),
  sources: transformSourcesToOptions(R.path([3, 'data'], values)),
  categories: transformCategoriesToOptions(R.path([4, 'data'], values)),
  locations: transformLocationsToOptions(R.path([5, 'data', 'response'], values)),
  employeeDimensions: {
    dimensions: transformEmployeeDimensionsWithPreviousDimension(
      sortAscendingByOrderIndex(R.path([6, 'data', 'response', 'employeeDimensions'], values))
    ),
    dimensionsData: {
      [firstInstanceId]: {
        dimensionsOptions: transformDefaultValuesToOptions(
          R.path([7, 'data', 'response'], values),
          R.path([1, 'data'], values)
        ),
      },
    },
  },
});
