"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "H1", {
  enumerable: true,
  get: function get() {
    return _H["default"];
  }
});
Object.defineProperty(exports, "H2", {
  enumerable: true,
  get: function get() {
    return _H2["default"];
  }
});
Object.defineProperty(exports, "H3", {
  enumerable: true,
  get: function get() {
    return _H3["default"];
  }
});
Object.defineProperty(exports, "H4", {
  enumerable: true,
  get: function get() {
    return _H4["default"];
  }
});
Object.defineProperty(exports, "H5", {
  enumerable: true,
  get: function get() {
    return _H5["default"];
  }
});
var _H = _interopRequireDefault(require("./H1"));
var _H2 = _interopRequireDefault(require("./H2"));
var _H3 = _interopRequireDefault(require("./H3"));
var _H4 = _interopRequireDefault(require("./H4"));
var _H5 = _interopRequireDefault(require("./H5"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }