import * as R from 'ramda';
import { GLOBAL_KEYWORD } from '@trs/config';
import { isBlank } from '@trs/utils';
import userModules from 'config/userManager/userModules';
import { filterCountriesByAccess } from 'modules/common/helpers';

export const filteredAuthCountries = ({ authorizedModules, profile, countries }) => {
  let defaultCountriesMappings = filterCountriesByAccess({
    countries,
    module: userModules.SETTINGS,
    authorizedModules,
  });

  defaultCountriesMappings = R.reject(
    country => country.code === GLOBAL_KEYWORD,
    defaultCountriesMappings
  );

  const authCountries = [];
  defaultCountriesMappings.forEach(country => {
    authCountries.push({
      value: country.name,
      label: country.name,
      code: country.code,
    });
  });

  if (isBlank(authCountries)) {
    authCountries.push({ value: profile.country, label: profile.country });
  }

  return authCountries;
};

export default filteredAuthCountries;
