import styled, { css } from 'styled-components';

const StyledFileDownloadNotification = styled.div`
  ${props => {
    const { spacing, typography } = props.theme;

    return css`
      &&& .notification-link {
        display: block;
        margin-top: ${spacing.tiny};
        margin-left: 0;
        text-align: left;
        font-weight: ${typography.weight.bold};
      }
    `;
  }};
`;

StyledFileDownloadNotification.displayName = 'StyledFileDownloadNotification';
export default StyledFileDownloadNotification;
