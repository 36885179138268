import { v4 as uuid } from 'uuid';
import * as R from 'ramda';
import { decimalTransform, isBlank } from '@trs/utils';

export const mapBEToFE = (proposedPayscales, payscaleIntervals) =>
  R.map(
    (item) => ({
      ...item,
      id: uuid(),
      populationMinPP: R.prop(
        'min',
        R.find(R.propEq('populationId', item.populationId))(payscaleIntervals)
      ),
      populationMaxPP: R.prop(
        'max',
        R.find(R.propEq('populationId', item.populationId))(payscaleIntervals)
      ),
    }),
    proposedPayscales
  );

export const isZeroDenominator = (number) =>
  number === 0 || number === '0' || Number.isNaN(number) || isBlank(number);

export const computePayPoint = (row, nextRow) => {
  const currentRow = row;

  if (isZeroDenominator(currentRow.middlePayPoint)) {
    currentRow.minPayPoint = 'N/A';
    currentRow.maxPayPoint = 'N/A';
  } else {
    currentRow.minPayPoint = decimalTransform(
      (currentRow.populationMinPP / 100) * currentRow.middlePayPoint,
      0
    );
    currentRow.maxPayPoint = decimalTransform(
      (currentRow.populationMaxPP / 100) * currentRow.middlePayPoint,
      0
    );
  }

  if (isZeroDenominator(currentRow.currentYearPayPoint)) {
    currentRow.payPointMovement = 'N/A';
  } else {
    currentRow.payPointMovement = decimalTransform(
      (currentRow.middlePayPoint / currentRow.currentYearPayPoint - 1) * 100
    );
  }

  if (!isBlank(nextRow)) {
    if (isZeroDenominator(currentRow.middlePayPoint)) {
      currentRow.progression = 'N/A';
    } else {
      currentRow.progression = decimalTransform(
        (nextRow.middlePayPoint / currentRow.middlePayPoint - 1) * 100,
        0
      );
    }

    if (isZeroDenominator(nextRow.minPayPoint)) {
      currentRow.payOverlap = 'N/A';
    } else {
      currentRow.payOverlap = decimalTransform(
        (currentRow.maxPayPoint / nextRow.minPayPoint - 1) * 100,
        0
      );
    }
  }

  return currentRow;
};

export const hasEmptyRequiredValues = (list) => {
  let hasEmptyValues = false;
  R.forEach((item) => {
    if (isBlank(item.middlePayPoint)) {
      hasEmptyValues = true;
    }
  }, list);
  return hasEmptyValues;
};

export const mapCalculatedData = (data) => ({
  basePayMarketComparison: R.propOr([], 'basePayMarketComparison', data).map((item) => ({
    ...item,
    id: item.populationId,
  })),
  compaRatio: {
    compaRatioRows: R.pathOr([], ['compaRatio', 'compaRatioRows'], data),
    total: R.pathOr({}, ['compaRatio', 'total'], data),
  },
  costToBringToMinimum: R.prop('costToBringToMinimumCalculationInProgress', data)
    ? {
        costToBringToMinimumRows: [],
        total: {},
      }
    : {
        costToBringToMinimumRows: R.pathOr(
          [],
          ['costToBringToMinimum', 'costToBringToMinimumRows'],
          data
        ),
        total: R.pathOr([], ['costToBringToMinimum', 'total'], data),
      },
});

export default { mapBEToFE, computePayPoint, hasEmptyRequiredValues, isZeroDenominator };
