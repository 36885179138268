import * as R from 'ramda';
import React from 'react';
import styled, { css } from 'styled-components';
import { Icon } from '@trs/components';
import { benchmark } from '../../../../../../config/api';
import CountryItem from '../../../common/dashboard/countryItem/CountryItem';
import Text, { TextOnly } from '../../../../../common/Text';
import DownloadLink from '../../../../../common/DownloadLink';
import { getStatusChip } from '../../../common/dashboard/helpers';

const StyledCheckmark = styled.div`
  ${({ theme, isDisabled }) => {
    const { palette: color } = theme;
    return css`
      i {
        color: ${isDisabled ? color.gray.silver : color.validation.valid};
      }
    `;
  }}
`;

/* eslint-disable react/jsx-filename-extension */
export const getColumnConfig = () => ({
  name: {
    id: 'name',
    title: TextOnly({ path: 'benchmarking.DASHBOARD_COLUMN_COUNTRY' }),
    width: 300,
    cellRenderer: (text, _1, _2, collapseProps, row) => {
      return (
        <CountryItem
          localSettingsId={R.path(['approvalFlow', 'localSettingsId'], row)}
          collapsible
          isActive={R.prop('isActive', row)}
          country={text}
          collapseProps={collapseProps}
          isManualSetup={R.prop('isManualSetup', row)}
          isCompleted={R.path(['approvalFlow', 'isCompleted'], row)}
        />
      );
    },
    alignment: 'left',
  },
  isActive: {
    id: 'isActive',
    title: TextOnly({ path: 'benchmarking.DASHBOARD_COLUMN_GLOBAL' }),
    width: 170,
    cellRenderer: (_1, _2_, _3, _4, row) => {
      return (
        <StyledCheckmark isDisabled={!R.prop('isActive', row)}>
          <Icon icon="ion-checkmark" size={24} />
        </StyledCheckmark>
      );
    },
    alignment: 'center',
  },
  localPreparation: {
    id: 'localPreparation',
    title: TextOnly({ path: 'benchmarking.DASHBOARD_COLUMN_LOCAL' }),
    cellRenderer: getStatusChip,
    alignment: 'center',
  },
  salaryIncrease: {
    id: 'salaryIncrease',
    title: TextOnly({ path: 'benchmarking.DASHBOARD_COLUMN_SALARY' }),
    cellRenderer: getStatusChip,
    alignment: 'center',
  },
  payScaleIncrease: {
    id: 'payScaleIncrease',
    title: TextOnly({ path: 'benchmarking.DASHBOARD_COLUMN_PAY_SCALE' }),
    cellRenderer: getStatusChip,
    alignment: 'center',
  },
  report: {
    id: 'report',
    title: TextOnly({ path: 'benchmarking.DASHBOARD_REPORT' }),
    cellRenderer: (_0, _1, _2, _3, row) => (
      <DownloadLink
        className="download-report"
        type="link"
        href={`${benchmark.benchmarking}/${R.prop(
          'globalBenchmarkingId',
          row
        )}/countrySummary?countryCode=${R.prop('code', row)}`}
        download={`${R.prop('name', row)}_Summary.xlsx`}
        disabled={!R.path(['approvalFlow', 'canDownloadSummaryReport'], row)}
      >
        <Icon className="icon-ion-ios-download-outline" size={20} />
        <Text path="benchmarking.DASHBOARD_EXPORT_SUMMARY" />
      </DownloadLink>
    ),
    alignment: 'center',
  },
});

export const mapResponseToColumns = (countries, globalBenchmarkingId) =>
  R.map(
    item => ({
      ...item,
      localPreparation: R.path(['approvalFlow', 'localPreparation', 'status'], item),
      salaryIncrease: R.path(['approvalFlow', 'salaryIncrease', 'status'], item),
      payScaleIncrease: R.path(['approvalFlow', 'payScaleIncrease', 'status'], item),
      globalBenchmarkingId,
    }),
    countries
  );

export default {
  getStatusChip,
  getColumnConfig,
  mapResponseToColumns,
};
