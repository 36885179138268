"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setColorAlphaChannel = exports.getRandomHexColor = exports.getLighterColor = exports.getExtendedColors = exports.getDarkerColor = void 0;
var _color = _interopRequireDefault(require("color"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
var getLighterColor = function getLighterColor(fullColor) {
  var perc = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0.9;
  return (0, _color["default"])(fullColor).fade(perc).string();
};
exports.getLighterColor = getLighterColor;
var getDarkerColor = function getDarkerColor(fullColor) {
  var perc = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0.9;
  return (0, _color["default"])(fullColor).darken(perc).hex();
};
exports.getDarkerColor = getDarkerColor;
var setColorAlphaChannel = function setColorAlphaChannel(fullColor) {
  var perc = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0.9;
  return (0, _color["default"])(fullColor).alpha(perc).string();
};
exports.setColorAlphaChannel = setColorAlphaChannel;
var getRandomHexColor = function getRandomHexColor() {
  return "#".concat(Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0'));
};
exports.getRandomHexColor = getRandomHexColor;
var getExtendedColors = function getExtendedColors(max, themeColors) {
  if (max < themeColors.length) {
    return themeColors;
  }
  return [].concat(_toConsumableArray(themeColors), _toConsumableArray(Array.from(Array(max - themeColors.length + 1)).map(function () {
    return getRandomHexColor();
  })));
};
exports.getExtendedColors = getExtendedColors;