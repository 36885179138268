import userModules from '../userModules';
import { TextOnly } from '../../../modules/common/Text';
import userManager, { permissions } from '../userManager';
import { checkForPermission } from '../../../modules/common/helpers';

const modeller = () => {
  const modellerItems = [];

  let modellerModules = {};
  if (userManager.getModellerModules()) {
    modellerModules = userManager.getModellerModules();
  }

  if (modellerModules) {
    if (checkForPermission(modellerModules, permissions.MODELLER.REWARD_IMPACT_UPDATE)) {
      modellerItems.push({
        title: TextOnly({ path: 'header.REWARD_IMPACT' }),
        key: [userModules.MODELLER],
        items: [],
        route: '/model/reward-impact',
      });
    }
    if (checkForPermission(modellerModules, permissions.MODELLER.REWARD_PACKAGE_UPDATE)) {
      modellerItems.push({
        title: TextOnly({ path: 'header.REWARD_PACKAGE' }),
        key: [userModules.MODELLER],
        items: [],
        route: '/model/reward-package',
      });
    }
    if (
      checkForPermission(modellerModules, permissions.MODELLER.MODELLER_CONFIGURATION_UPDATE) ||
      checkForPermission(modellerModules, permissions.MODELLER.MODELLER_CONFIGURATION_READ)
    ) {
      modellerItems.push({
        title: TextOnly({ path: 'header.CONFIGURATION' }),
        key: [userModules.MODELLER],
        items: [],
        route: '/model/reward-package/configuration',
      });
    }
  }

  return [
    {
      title: TextOnly({ path: 'header.MODELLER' }),
      key: [userModules.MODELLER],
      items: modellerItems,
      route: '',
    },
  ];
};
export default modeller;
