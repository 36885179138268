/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CallbackComponent } from 'redux-oidc';
import { bindActionCreators } from 'redux';
import { PageLoader } from '@trs/components';
import styled from 'styled-components';
import { Log, isBlank } from '@trs/utils';
import Disclaimer from 'modules/login/routes/Disclaimer';
import Layout from 'modules/common/layout/Layout';
import { fetchCmsData } from 'modules/cms/actions';
import cmsKeys from 'modules/cms/keys';
import userManager from './userManager';
import * as userActions from '../../actions/userActions';

const cmsStateKey = cmsKeys.userManagement;
const StyledCallback = styled.div`
  text-align: center;
  color: green;
`;

StyledCallback.displayName = 'StyledCallback';

class CallbackPage extends React.Component {
  componentDidMount() {
    const {
      actions: { fetchData },
    } = this.props;
    const params = [{ name: 'resourceGroup', value: 'UserManagement' }];
    fetchData(params, cmsStateKey);
  }

  success = () => {
    const {
      actions: { loginUser },
      history,
      isSetupCompleted,
    } = this.props;
    loginUser(isSetupCompleted)
      .then(() => {
        const returnUrl = userManager.getCurentLocation();
        if (!isBlank(returnUrl)) {
          history.push(returnUrl);
        } else {
          history.push('/callback'); // remove the token information from the url
        }
      })
      .catch((error) => {
        Log.error('Login user has failed with error ', error);
        history.push('/unauthorized');
      });
  };

  error = (error) => {
    const { history } = this.props;

    Log.error(error, 'Login'); // investigate login error on devci

    history.push('/unauthorized');
  };

  render() {
    const { user, genericLabel, history, isLoadingUserManagementLabels } = this.props;

    return (
      <CallbackComponent
        userManager={userManager}
        successCallback={this.success}
        errorCallback={this.error}
      >
        <Layout>
          {isLoadingUserManagementLabels ? (
            <PageLoader />
          ) : (
            <Disclaimer
              user={user}
              genericLabel={genericLabel}
              landingPage={user.landingPage}
              history={history}
            />
          )}
        </Layout>
      </CallbackComponent>
    );
  }
}

CallbackPage.propTypes = {
  isLoadingUserManagementLabels: PropTypes.bool.isRequired,
  actions: PropTypes.shape({
    loginUser: PropTypes.func.isRequired,
    fetchData: PropTypes.func,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  user: PropTypes.shape({
    landingPage: PropTypes.string,
  }).isRequired,
  genericLabel: PropTypes.shape({}).isRequired,
  isSetupCompleted: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  genericLabel: state.cms.generic.data,
  isLoadingUserManagementLabels: state.cms.userManagement.loading,
  isSetupCompleted: state.onboarding.dashboard.isSetupCompleted,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...userActions, fetchData: fetchCmsData }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(CallbackPage);
