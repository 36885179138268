export default {
  SYSTEM_AUDIT_FOR_USER: 'for %USER%',
  SYSTEM_AUDIT_ID: 'ID',
  SYSTEM_AUDIT_EMAIL: 'Email',
  SYSTEM_AUDIT_COUNTRY: 'Country',
  NO_RESULTS: 'No results found.',
  SYSTEM_AUDIT_MAXIMUM_INTERVAL: 'Maximum interval is 90 days',
  SYSTEM_AUDIT_TIMESTAMP_INTERVAL_FROM: 'Timestamp Interval From',
  SYSTEM_AUDIT_TO: 'To',
  SYSTEM_AUDIT_ROLES: 'Roles',
  SYSTEM_AUDIT_APPLY: 'Apply',
  SYSTEM_AUDIT_RESET_FILTERS: 'Reset Filters',
  SYSTEM_AUDIT_DOWNLOAD_RESULTS: 'Download Results',
  SYSTEM_AUDIT_TIME_UTC: 'Time (UTC)',
  SYSTEM_AUDIT_TIME_LOCAL: 'Time (Local)',
  SYSTEM_AUDIT_LAST_MODIFIED_BY: 'Last Modified By',
  SYSTEM_AUDIT_USER_IP: 'User IP',
  SYSTEM_AUDIT_ELEMENT: 'Element',
  SYSTEM_AUDIT_MODULE: 'Module',
  SYSTEM_AUDIT_EVENT: 'Event',
  SYSTEM_AUDIT_ACTION: 'Action',
  SYSTEM_AUDIT_FIELD: 'Field',
  SYSTEM_AUDIT_OLD_VALUE: 'Old Value',
  SYSTEM_AUDIT_NEW_VALUE: 'New Value',
  SYSTEM_AUDIT_INFO_IN_FILE: 'Info in file',
  SYSTEM_AUDIT_FILE_GENERATION_PENDING: 'File Generation Pending',
  SYSTEM_AUDIT_FILE_GENERATION_IN_PROGRESS: 'File Generation In Progress',
};
