export default {
  employeeStatement: 'employeeStatement',
  generic: 'generic',
  articles: 'articles',
  footer: 'footer',
  header: 'header',
  rewards: 'rewards',
  variables: 'variables',
  exceptions: 'exceptions',
  modeller: 'modeller',
  foreignExchange: 'foreignExchange',
  userManagement: 'userManagement',
  flexibleRewards: 'flexibleRewards',
  rmDashboard: 'rmDashboard',
  onboarding: 'onboarding',
  customisation: 'customisation',
  notifications: 'notifications',
  feedback: 'feedback',
  workspace: 'workspace',
  reports: 'reports',
  flexibleReports: 'flexibleReports',
  platformConfig: 'platformConfig',
  benefitsEnvelope: 'benefitsEnvelope',
  fixedCosts: 'fixedCosts',
};
