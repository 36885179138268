import * as R from 'ramda';
import { isBlank, toLower, toBinary } from '@trs/utils';
import APP_CONFIG from '@trs/config';
import { NUMERIC_TYPES } from '../../../../common/EditableField/helpers';
import { FILTER_TYPES } from '../../../routes/utils';

export const CONTEXT = {
  MIMIC: 'mimic',
  DEFAULT: 'default',
};

const getNumeric = (payload) => {
  const isPercentage = R.prop('type', payload) === NUMERIC_TYPES.percentage;
  const val = R.prop('value', payload);
  const min = R.prop('min', payload);
  const max = R.prop('max', payload);
  const type = R.prop('type', payload);
  const textValue = toBinary(R.prop('textValue', payload));
  return {
    value: isPercentage ? val / 100 : val,
    min: min !== null && isPercentage ? min / 100 : min,
    max: max !== null && isPercentage ? max / 100 : max,
    type,
    textValue,
  };
};

const getValue = (isPercentage, val, addValue) => {
  let value = val;
  if (isBlank(val)) return value;
  if (addValue) value += 0.001;
  return isPercentage ? value * 100 : value;
};

export const getProcessedValue = (payload, description) => {
  const isPercentage = payload.type === NUMERIC_TYPES.percentage;
  let isMinGreaterThanMax = false;
  if (
    !isBlank(payload.min) &&
    !isBlank(payload.max) &&
    typeof payload.min === 'number' &&
    typeof payload.max === 'number' &&
    payload.min > payload.max
  ) {
    isMinGreaterThanMax = true;
  }
  let minEqualsMax = false;
  if (payload.min === payload.max) {
    minEqualsMax = true;
  }
  return {
    ...payload,
    value: getValue(isPercentage, payload.value),
    displayMin: isMinGreaterThanMax ? 0 : getValue(isPercentage, payload.min),
    min: getValue(isPercentage, payload.min),
    displayMax: isMinGreaterThanMax ? 0.0001 : getValue(isPercentage, payload.max, minEqualsMax),
    max: getValue(isPercentage, payload.max),
    description,
  };
};

export const getTransformedPayload = (payload) => ({
  optInRewards:
    R.values(
      R.map((item) => {
        const itemPayload = R.prop('payload', item);
        // the logic here says we should send the value if it has been changed now, or (it's selected and it's changeable - from save)
        // this is done in order not to trigger a re-calculation of all changeable rewards
        const shouldGetValue =
          (R.path(['flexOptions', 'isChangeableByEmployee'], item) && R.prop('isSelected', item)) ||
          R.prop('hasChanged', itemPayload);
        const valuePayload = shouldGetValue
          ? { value: R.prop('value', getNumeric(itemPayload)) }
          : {};

        return { rewardId: item.id, ...valuePayload };
      }, payload)
    ) || [],
});

export const getTransformedSavePayload = (payload, employeeId, packageId) => ({
  employeeId,
  packageId,
  options: R.values(
    R.map(
      (item) => ({
        rewardId: item.id,
        valueModel: getNumeric(R.prop('payload', item)),
      }),
      payload
    )
  ),
});

export const getMimicContextHeader = (isMimic) => ({
  Context: isMimic ? CONTEXT.MIMIC : CONTEXT.DEFAULT,
});

export const getSaveToasterMessagePath = (type = '') => {
  if (isBlank(type)) return 'flexibleRewards.SAVE_OPTIONS_SUCCESS_MODELLING';

  return toLower(type) === FILTER_TYPES.SUBMISSION
    ? 'flexibleRewards.SAVE_OPTIONS_SUCCESS_SUBMISSION'
    : 'flexibleRewards.SAVE_OPTIONS_SUCCESS_MODELLING';
};

export const transformPayloadValuesToIntegers = (option) => {
  const newOption = { ...option };
  newOption.payload.value = Math.round(newOption.payload.value);

  newOption.payload.min = newOption.payload.min !== null ? Math.ceil(newOption.payload.min) : null;
  newOption.payload.max = newOption.payload.max !== null ? Math.floor(newOption.payload.max) : null;
  newOption.payload.displayMin =
    newOption.payload.displayMin !== null ? Math.ceil(newOption.payload.displayMin) : null;
  newOption.payload.displayMax =
    newOption.payload.displayMax !== null ? Math.floor(newOption.payload.displayMax) : null;

  const isSelectedByDefault = R.path(['flexOptions', 'isSelectedByDefault'], option);
  if (newOption.payload.min !== null && newOption.payload.max !== null && !isSelectedByDefault) {
    if (newOption.payload.max < newOption.payload.value) {
      newOption.payload.value = newOption.payload.max;
    } else if (newOption.payload.min > newOption.payload.value) {
      newOption.payload.value = newOption.payload.min;
    }
  }

  if (newOption.isStepper) {
    newOption.availableOptions = newOption.availableOptions.map((availableOption) => {
      const newAvailableOption = { ...availableOption };
      newAvailableOption.payload.value = Math.round(newAvailableOption.payload.value);
      return newAvailableOption;
    });
  }
  return newOption;
};

export const reworkRewards = (rewards) =>
  rewards.map((reward) => {
    const reworkedReward = { ...reward };
    if (reworkedReward.isIntegerOnly) {
      reworkedReward.value = Math.round(reworkedReward.value);
    }
    return reworkedReward;
  });

export const reworkAvailableOptions = (availableOptions) =>
  availableOptions.map((availableOption) => {
    let newOption = { ...availableOption };
    const { TEMPLATE_TYPES } = APP_CONFIG;

    if (newOption.isStepper === undefined) {
      newOption.showStepperOptions = false;
    }

    if (newOption.template === TEMPLATE_TYPES.percentage) {
      newOption.currency = '%';
    }

    newOption.payload = getProcessedValue(newOption.payload, newOption.description);

    if (newOption.flexOptions.isIntegerOnly) {
      newOption = transformPayloadValuesToIntegers(newOption);
    }

    return newOption;
  });

export const transformSelectedRewardsData = (selectedRewardsObj) => {
  const rewardsDetails = [];

  Object.keys(selectedRewardsObj).forEach((key) => {
    const isChangeable = selectedRewardsObj[key].flexOptions.isChangeableByEmployee;

    rewardsDetails.push({
      isHiddenWhenSelected: selectedRewardsObj[key].flexOptions.isHiddenWhenSelected,
      isIntegerOnly: selectedRewardsObj[key].flexOptions.isIntegerOnly,
      selectedOption: {
        rewardId: selectedRewardsObj[key].id,
        ...(isChangeable && { valueModel: getNumeric(selectedRewardsObj[key].payload) }),
      },
    });
  });
  return rewardsDetails;
};

export default {
  getTransformedPayload,
  getTransformedSavePayload,
  getMimicContextHeader,
  getSaveToasterMessagePath,
  CONTEXT,
};
