import { v4 as uuid } from 'uuid';
import { DEFAULT_TOTAL_DISTRIBUTION } from '../constants';

export const basicInformationState = {
  displayName: '',
  countries: [],
  variablesCountryCodes: [],
  currency: '',
  fxSource: '',
  category: null,
  subcategory: null,
  type: null,
  description: '',
  startDate: null,
  endDate: null,
  recurrence: 'no',
  recurrenceCount: null,
  recurrenceFrequency: null,
  plainText: '',
};

export const distributionTableState = {
  table: {
    rows: [],
    dirtyRows: [],
    total: DEFAULT_TOTAL_DISTRIBUTION,
    defaultTotal: DEFAULT_TOTAL_DISTRIBUTION,
  },
};

export const rulesBuilderState = {
  rules: [
    {
      key: uuid(),
      formula: [],
    },
  ],
  swapRules: [
    {
      key: uuid(),
      formula: [],
    },
  ],
  formulaOperators: [],
  hasSavedLogicBuilder: false,
  distribution: {
    isAutomatic: true,
    swapIsAutomatic: true,
    tableConfigWillLoad: false,
    ...distributionTableState,
    swapAutomatic: {
      table: {
        rows: [],
        total: DEFAULT_TOTAL_DISTRIBUTION,
      },
    },
    swapManual: {
      table: {
        rows: [],
        total: DEFAULT_TOTAL_DISTRIBUTION,
      },
    },
  },
};

const initialState = {
  status: '',
  savedFixedCostId: '',
  savedFixedCostsEtag: '',
  basicInformation: basicInformationState,
  swapBasicInformation: basicInformationState,
  ...rulesBuilderState,
  allCountries: [],
  filteredCountries: [],
  currencies: [],
  companyDefaultCurrency: '',
  defaultCurrencyMappings: [],
  fxSources: [],
  categories: [],
  redirectToRoot: false,
  serverError: false,
  filterOptions: [],
  totalResults: 0,
  list: [],
  fetchingList: false,
  fetchingById: false,
};

export default initialState;
