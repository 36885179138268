export default {
  ENROLLMENT_WINDOW_PAGE_TITLE: 'Enrolment Window',
  ENROLLMENT_WINDOW_PAGE_SUBTITLE:
    'Here you can manage the dates that individual employees are able to use the flexible package modelling. You can select individual or multiple users with the tick-boxes and then define the date range that they will have access.',
  ENROLLMENT_WINDOW_OPEN_BUTTON: 'Open',
  ENROLLMENT_WINDOW_DATATABLE_EXPORT_PACKAGE: 'Export Package',
  ENROLLMENT_WINDOW_DATATABLE_OPEN_BUTTON: 'Open window',
  ENROLLMENT_WINDOW_DATATABLE_CLOSE_BUTTON: 'Close window',
  ENROLLMENT_WINDOW_DATATABLE_EMPLOYEE_ID: 'Employee ID',
  ENROLLMENT_WINDOW_DATATABLE_EMPLOYEE_NAME: 'Employee name',
  ENROLLMENT_WINDOW_DATATABLE_COUNTRY: 'Country',
  ENROLLMENT_WINDOW_DATATABLE_PACKAGE_TYPE: 'Package type',
  ENROLLMENT_WINDOW_DATATABLE_TITLE: 'Title',
  ENROLLMENT_WINDOW_DATATABLE_STATUS: 'Status',
  ENROLLMENT_WINDOW_DATATABLE_ACTION: 'Action',
  ENROLLMENT_WINDOW_DATATABLE_NO_DATA: 'There are no employees to display',
  ENROLLMENT_WINDOW_MODAL_TITLE: 'Enrolment Window',
  ENROLLMENT_WINDOW_MODAL_START_DATE: 'Start date',
  ENROLLMENT_WINDOW_MODAL_END_DATE: 'End date',
  ENROLLMENT_WINDOW_SAVE_SUCCESS: 'The enrolment window dates were saved successfully.',
  ENROLLMENT_WINDOW_CONFIRM_TITLE: 'Close enrolment window',
  ENROLLMENT_WINDOW_CONFIRM_DESC:
    'Are you sure you want to close the enrolment window for the selected user?',
  ENROLLMENT_WINDOW_CLOSE_SUCCESS: 'The enrolment window was closed successfully.',
  NEW_STATEMENT_DATE_SHOULD_BE_GREATER_THAN_OR_EQUAL_TO_CURRENT_DATE:
    'New statement date should be greater than or equal to current date',
  EXPORT_WINDOWS_DIALOG_TITLE: 'Export Windows for Selected Package',
  EXPORT_WINDOWS_SUCCESS: 'Export Enrolment Windows in progress',
  SELECT_PACKAGE: 'Select Package',
};
