import * as R from 'ramda';
import * as actions from '../actions/types';

import initialState from './initialState';
import {
  mapRequestToSharesModel,
  mapCategoryNameToId,
  sortTextOnlyAlphabetically,
} from './helpers';

export default function employeeStatementReducer(state = initialState, action) {
  switch (action.type) {
    case 'REDIRECT_TO_ROOT': {
      return {
        ...state,
        redirectToRoot: true,
      };
    }
    case actions.EMPLOYEE_RESET_STATEMENT: {
      return {
        ...initialState,
      };
    }

    case actions.EMPLOYEE_GET_REWARDS_CATEGORIES: {
      const employeeCategories = R.path(['rewardsCategories', 'data', 'response'], action);

      const categoryMappings = R.path(['categoryMappings'], action);

      return {
        ...state,
        rewardsCategories: mapCategoryNameToId({ employeeCategories, categoryMappings }),
        fetchingCategories: false,
      };
    }

    case actions.EMPLOYEE_GET_CATEGORY_DRILLDOWN: {
      return {
        ...state,
        categoryDrilldown: sortTextOnlyAlphabetically(
          R.path(['categoryDrilldown', 'data', 'response'], action)
        ),
      };
    }
    case actions.EMPLOYEE_GET_SHARES: {
      const { shares, granted } = R.path(['shareData', 'data'], action);
      const rejectSharesCb = (share) =>
        !R.path(['unvested', 'count'])(share) && !R.path(['vested', 'count'])(share);

      const rejectGrantedCb = (share) => !R.prop('count')(share);

      return {
        ...state,
        fetchingShares: false,
        shareData: {
          shares: mapRequestToSharesModel(R.reject(rejectSharesCb, shares)),
          granted: {
            ...granted,
            shares: R.reject(rejectGrantedCb, R.prop('shares')(granted)),
          },
        },
      };
    }

    case actions.EMPLOYEE_GET_SHARES_SUMMARY: {
      const shares = R.path(['shareSummary', 'data', 'shares'], action);
      const rejectCb = (share) =>
        !R.prop('unvestedCount')(share) && !R.path(['vested', 'count'])(share);

      return {
        ...state,
        shareSummary: {
          shares: R.reject(rejectCb, shares),
        },
      };
    }

    case actions.EMPLOYEE_GET_SHARES_SUMMARY_ERROR: {
      return {
        ...state,
        shareSummary: {
          error: R.path(['shareSummary', 'data'])(action),
        },
      };
    }
    case actions.EMPLOYEE_GET_LTI_PLANS: {
      return {
        ...state,
        ltiPlans: {
          ...state.ltiPlans,
          ...action.payload,
        },
      };
    }
    case actions.EMPLOYEE_GET_LTI_PLANS_PENDING: {
      return {
        ...state,
        isLoadingLtiPlans: action.payload,
      };
    }

    case actions.EMPLOYEE_RESET_LTI_PLANS: {
      return {
        ...state,
        ltiPlans: {
          ...initialState.ltiPlans,
        },
      };
    }

    case actions.CATEGORIES_GET_FAIL: {
      return {
        ...state,
        fetchingCategories: false,
      };
    }

    case actions.EMPLOYEE_GET_PERSONAL_DETAILS_PENDING: {
      return {
        ...state,
        isLoadingDetails: true,
      };
    }

    case actions.EMPLOYEE_GET_PERSONAL_DETAILS: {
      return {
        ...state,
        isLoadingDetails: false,
        personalDetailsSetting: [...action.payload],
      };
    }

    default:
      return state;
  }
}
