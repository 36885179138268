import React, { Fragment } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SanitizedHTML from 'react-sanitized';
import { Icon, Button } from '@trs/components';
import { scrollTo, getYearFromDate } from '@trs/utils';
import * as globalActions from '../../../actions/globalActions';
import Layout from '../../common/layout/Layout';
import { TextOnly } from '../../common/Text';
import Logo from '../../header/components/Logo';
import StyledFooter from './Footer.style';
import StyledFooterBanner from './FooterBanner.style';

const Footer = ({ label, genericLabel, actions, approvedCookiePolicy }) => (
  <Fragment>
    {!approvedCookiePolicy && (
      <StyledFooterBanner>
        <Layout className="layout">
          <p>{genericLabel.COOKIE_POLICY}</p>
          <Button
            medium
            type="naked"
            className="btn"
            action={() => {
              actions.setCookiePolicy('approvedCookiePolicy', true);
            }}
          >
            {genericLabel.BUTTON_OK}
          </Button>
        </Layout>
      </StyledFooterBanner>
    )}

    <StyledFooter className="App-footer">
      <Layout className="layout">
        <div className="logo-wrapper">
          <Logo />
          <div className="contact-info">
            <a
              href={TextOnly({ path: 'platformConfig.PLATFORM_CONTACT' })}
              target="_blank"
              rel="noopener noreferrer"
              className="contact-info-card"
              aria-label={label.CONTACT_LINK}
            >
              <Icon className="icon icon-contact" size={24} icon="ion-email" />
              <span>{label.CONTACT_LINK}</span>
            </a>

            <span className="separator" />

            <a
              href={TextOnly({ path: 'platformConfig.PLATFORM_SUPPORT' })}
              target="_blank"
              rel="noopener noreferrer"
              className="contact-info-card"
              aria-label={label.SUPPORT_LINK}
            >
              <Icon className="icon icon-support" size={24} icon="ion-help-buoy" />
              <span>{label.SUPPORT_LINK}</span>
            </a>
          </div>
          <span className="back-to-top" onClick={() => scrollTo()} role="presentation">
            <Icon className="icon icon-arrow" size={18} icon="ion-arrow-up-c" />
          </span>
        </div>
      </Layout>

      <div className="footer-links-wrapper">
        <Layout className="layout">
          <ul className="footer-links">
            <li>
              <a
                id="privacy-policy"
                href={TextOnly({ path: 'platformConfig.PLATFORM_PRIVACY_POLICY' })}
                target="_blank"
                rel="noopener noreferrer"
                aria-label={label.PRIVACY_LINK}
              >
                {label.PRIVACY_LINK}
              </a>
            </li>

            <li>
              <a
                id="terms"
                href={TextOnly({ path: 'platformConfig.PLATFORM_TERMS' })}
                target="_blank"
                rel="noopener noreferrer"
                aria-label={label.TERMS_LINK}
              >
                {label.TERMS_LINK}
              </a>
            </li>
          </ul>

          <span className="copyright-container">
            <a
              href={TextOnly({ path: 'platformConfig.PLATFORM_COPYRIGHT' })}
              target="_blank"
              rel="noopener noreferrer"
              aria-label={genericLabel.COPYRIGHT_LABEL}
            >
              &copy;
              {getYearFromDate()} &nbsp;
              <span>
                <SanitizedHTML html={label.COPYRIGHT} />
              </span>
            </a>
          </span>

          {(TextOnly({ path: 'platformConfig.PLATFORM_TWITTER' }) !== 'n/a' ||
            TextOnly({ path: 'platformConfig.PLATFORM_LINKEDIN' }) !== 'n/a') && (
            <ul className="social-links">
              <li>{label.FOLLOW_US}</li>
              {TextOnly({ path: 'platformConfig.PLATFORM_TWITTER' }) !== 'n/a' && (
                <li>
                  <a
                    id="twitter"
                    href={TextOnly({ path: 'platformConfig.PLATFORM_TWITTER' })}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label={genericLabel.TWITTER_LABEL}
                  >
                    <Icon className="icon icon-twitter" size={18} icon="ion-social-twitter" />
                  </a>
                </li>
              )}
              {TextOnly({ path: 'platformConfig.PLATFORM_LINKEDIN' }) !== 'n/a' && (
                <li>
                  <a
                    id="linkedin"
                    href={TextOnly({ path: 'platformConfig.PLATFORM_LINKEDIN' })}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label={genericLabel.LINKEDIN_LABEL}
                  >
                    <Icon className="icon icon-linkedin" size={18} icon="ion-social-linkedin" />
                  </a>
                </li>
              )}
            </ul>
          )}
        </Layout>
      </div>
    </StyledFooter>
  </Fragment>
);

Footer.propTypes = {
  actions: PropTypes.shape({
    setCookiePolicy: PropTypes.func,
  }).isRequired,
  label: PropTypes.shape({
    FOLLOW_US: PropTypes.string,
    COPYRIGHT: PropTypes.string,
    TERMS_LINK: PropTypes.string,
    PRIVACY_LINK: PropTypes.string,
    SUPPORT_LINK: PropTypes.string,
    CONTACT_LINK: PropTypes.string,
  }).isRequired,
  genericLabel: PropTypes.shape({
    COOKIE_POLICY: PropTypes.string,
    LINKEDIN_LABEL: PropTypes.string,
    TWITTER_LABEL: PropTypes.string,
    COPYRIGHT_LABEL: PropTypes.string,
    BUTTON_OK: PropTypes.string,
  }).isRequired,
  approvedCookiePolicy: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  label: state.cms.footer.data,
  genericLabel: state.cms.generic.data,
  approvedCookiePolicy: state.global.approvedCookiePolicy,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(globalActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
