"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.StyledToolboxWrapper = exports.StyledToolbox = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var StyledToolbox = _styledComponents["default"].div.withConfig({
  displayName: "Toolboxstyle__StyledToolbox",
  componentId: "sc-1i3nmcx-0"
})(["z-index:101;"]);
exports.StyledToolbox = StyledToolbox;
StyledToolbox.displayName = 'StyledToolbox';
var StyledToolboxWrapper = _styledComponents["default"].div.withConfig({
  displayName: "Toolboxstyle__StyledToolboxWrapper",
  componentId: "sc-1i3nmcx-1"
})(["", ";"], function (props) {
  var _props$theme = props.theme,
    color = _props$theme.palette,
    spacing = _props$theme.spacing,
    boxShadow = _props$theme.extra.boxShadow;
  var hasNone = props.hasNone;
  if (hasNone) return '';
  return (0, _styledComponents.css)(["display:flex;background-color:white;padding:0;box-shadow:", ";justify-content:center;button{padding:", ";min-width:0;font-weight:bold;margin:0px !important;border:0;color:", ";&.toolbox-formula-round-up,&.toolbox-formula-round-down{white-space:nowrap;text-align:center;overflow:hidden;max-width:100%;}}h4{color:", ";margin:", " 0;}.toolbox-group{margin-right:0;}&:after{content:'\f104';font-family:'ionicons';bottom:-15px;position:absolute;left:24px;font-size:21px;color:white;text-shadow:0px 3px 4px ", ";}"], boxShadow, spacing.xSmall, color.primary, color.primary, spacing.small, color.gray.charlie);
});
exports.StyledToolboxWrapper = StyledToolboxWrapper;
StyledToolboxWrapper.displayName = 'StyledToolboxWrapper';
var _default = {
  StyledToolbox: StyledToolbox,
  StyledToolboxWrapper: StyledToolboxWrapper
};
exports["default"] = _default;