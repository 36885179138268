import { handleActions } from 'redux-actions';
import * as R from 'ramda';
import { ramdaMapIndexed } from '@trs/utils';
import initialState from './initialState';
import {
  loadPayscaleIncreaseSuccess,
  cancelEditable,
  saveProposal,
  cellChange,
  getPayscaleProposal,
  resetPayscale,
  validateDataForSubmit,
  getCostToBringToMinimum,
} from '../actions';
import { mapBEToFE, computePayPoint, hasEmptyRequiredValues, mapCalculatedData } from './helpers';

const payscaleIncrease = handleActions(
  new Map([
    [resetPayscale, () => R.clone(initialState)],
    [
      loadPayscaleIncreaseSuccess,
      (state, action) => {
        const { ready, error, payload } = action;

        if (!ready) {
          return {
            ...state,
            isLoading: true,
          };
        }

        if (error) {
          return {
            ...state,
            hasError: true,
            isLoading: false,
          };
        }
        const data = R.prop('data', payload);
        const { costToBringToMinimumCalculationInProgress } = data;
        return {
          ...state,
          benchmarkingId: R.prop('benchmarkingId', data),
          payscaleId: R.prop('id', data),
          country: R.prop('countryCode', data),
          etag: R.prop('etag', data),
          proposedPayscales: mapBEToFE(
            R.prop('proposedPayscales', data),
            R.prop('payscaleIntervals', data)
          ),
          proposedPayscalesSnapshot: mapBEToFE(
            R.prop('proposedPayscales', data),
            R.prop('payscaleIntervals', data)
          ),
          isLoading: false,
          isDataValid: R.prop('isDataValid', data),
          disableSave: hasEmptyRequiredValues(R.prop('proposedPayscales', data)),
          hasError: false,
          costToBringToMinimumCalculationInProgress,
          ...mapCalculatedData(data),
        };
      },
    ],
    [
      validateDataForSubmit,
      (state, action) => ({
        ...state,
        isDataValid: action.payload,
      }),
    ],
    [
      cancelEditable,
      state => ({
        ...state,
        proposedPayscales: state.proposedPayscalesSnapshot,
      }),
    ],
    [saveProposal.pending, state => ({ ...state, isSaving: true })],
    [
      saveProposal.success,
      (state, action) => ({
        ...state,
        isSaving: false,
        etag: R.path(['payload', 'Etag'], action),
        proposedPayscales: mapBEToFE(
          R.path(['payload', 'newPayscales'], action),
          R.path(['payload', 'payscaleIntervals'], action)
        ),
        proposedPayscalesSnapshot: mapBEToFE(
          R.path(['payload', 'newPayscales'], action),
          R.path(['payload', 'payscaleIntervals'], action)
        ),
        basePayMarketComparison: [],
        compaRatio: {
          compaRatioRows: [],
          total: {},
        },
        costToBringToMinimum: {
          costToBringToMinimumRows: [],
          total: {},
        },
        calculateError: false,
      }),
    ],
    [saveProposal.error, state => ({ ...state, isSaving: false })],
    [
      cellChange,
      (state, action) => {
        const { rowIndex, field, value } = action.payload;
        const { proposedPayscales } = state;
        let newProposedPayscales = [];
        newProposedPayscales = R.assocPath([rowIndex, field], value, proposedPayscales);
        const rowCalc = computePayPoint(
          R.prop(rowIndex, newProposedPayscales),
          R.prop(rowIndex + 1, newProposedPayscales)
        );
        newProposedPayscales = R.assocPath([rowIndex], rowCalc, newProposedPayscales);

        ramdaMapIndexed((row, index) => {
          return computePayPoint(row, R.prop(index + 1, newProposedPayscales));
        }, newProposedPayscales);

        return {
          ...state,
          proposedPayscales: newProposedPayscales,
          disableSave: hasEmptyRequiredValues(newProposedPayscales),
        };
      },
    ],
    [
      getPayscaleProposal,
      (state, action) => {
        const { ready, error, payload } = action;

        if (!ready) {
          return {
            ...state,
            isCalculating: true,
            costToBringToMinimumCalculationInProgress: true,
          };
        }

        if (error) {
          return {
            ...state,
            isCalculating: false,
            costToBringToMinimumCalculationInProgress: false,
            calculateError: true,
            basePayMarketComparison: [],
            compaRatio: {
              compaRatioRows: [],
              total: {},
            },
            costToBringToMinimum: {
              costToBringToMinimumRows: [],
              total: {},
            },
          };
        }

        return {
          ...state,
          isCalculating: false,
          calculateError: false,
          etag: R.prop('etag', payload),
          ...mapCalculatedData(R.prop('data', payload)),
        };
      },
    ],
    [
      getCostToBringToMinimum,
      (state, action) => {
        const { ready, error, payload } = action;

        if (!ready) {
          return {
            ...state,
            costToBringToMinimumCalculationInProgress: true,
          };
        }

        if (error) {
          return {
            ...state,
            costToBringToMinimumCalculationError: true,
            costToBringToMinimumCalculationInProgress: false,
            costToBringToMinimum: {
              costToBringToMinimumRows: [],
              total: {},
            },
          };
        }

        const { costToBringToMinimum, isDataValid, etag } = payload;

        return {
          ...state,
          costToBringToMinimum: {
            costToBringToMinimumRows:
              R.prop('costToBringToMinimumRows', costToBringToMinimum) || [],
            total: R.prop('total', costToBringToMinimum) || [],
          },
          costToBringToMinimumCalculationInProgress: false,
          etag,
          isDataValid,
        };
      },
    ],
  ]),
  R.clone(initialState)
);

export default payscaleIncrease;
