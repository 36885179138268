import React from 'react';
import { FormattedNumber } from '@trs/components';
import { MAX_DECIMALS } from '@trs/config';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import TOTAL_VARIANTS from '../constants';
import StyledTotal from './Total.style';

const Total = ({
  values,
  label,
  variant,
  currencies,
  showOnlyNewStatement,
  classes,
  hasDecimals,
}) => {
  return (
    <StyledTotal variant={variant} className={`${classes} statement-row statement-total`}>
      <strong className="statement-row-title">{label}</strong>
      {!showOnlyNewStatement && (
        <strong className="statement-row-value">
          <FormattedNumber
            value={R.prop(0, values)}
            decimals={MAX_DECIMALS}
            hasDecimals={hasDecimals}
          />
          <span> {R.prop(0, currencies)}</span>
        </strong>
      )}
      <strong className="statement-row-value">
        <FormattedNumber
          value={R.prop(1, values)}
          decimals={MAX_DECIMALS}
          hasDecimals={hasDecimals}
        />
        <span> {R.prop(1, currencies)}</span>
      </strong>
    </StyledTotal>
  );
};

Total.propTypes = {
  label: PropTypes.string.isRequired,
  variant: PropTypes.string,
  values: PropTypes.arrayOf(PropTypes.number).isRequired,
  currencies: PropTypes.arrayOf(PropTypes.string).isRequired,
  showOnlyNewStatement: PropTypes.bool.isRequired,
  classes: PropTypes.string,
  hasDecimals: PropTypes.bool,
};

Total.defaultProps = {
  variant: TOTAL_VARIANTS.regular,
  classes: '',
  hasDecimals: false,
};

export default Total;
