import APP_CONFIG from '@trs/config';
import * as R from 'ramda';

const { REWARD_STATUSES } = APP_CONFIG;

const mapRequestToRewardsOverviewModel = data => {
  const pieChartData = [
    {
      rewardsOverviewStatus: R.prop('live', REWARD_STATUSES),
      value: R.path(['response', 'live'], data) || 0,
    },
    {
      rewardsOverviewStatus: R.prop('published', REWARD_STATUSES),
      value: R.path(['response', 'published'], data) || 0,
    },
    {
      rewardsOverviewStatus: R.prop('draft', REWARD_STATUSES),
      value: R.path(['response', 'draft'], data) || 0,
    },
    {
      rewardsOverviewStatus: R.prop('expired', REWARD_STATUSES),
      value: R.path(['response', 'expired'], data) || 0,
    },
    {
      rewardsOverviewStatus: R.prop('error', REWARD_STATUSES),
      value: R.path(['response', 'error'], data) || 0,
    },
  ];

  const rewardsOverview = {
    totalRewards: R.path(['response', 'total'], data),
    expireNextDayRewards: R.path(['response', 'expireNextDay'], data),
    liveNextDayRewards: R.path(['response', 'liveNextDay'], data),
    pieChartData,
  };

  return rewardsOverview;
};

export default mapRequestToRewardsOverviewModel;
