"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _palette = _interopRequireDefault(require("./palette"));
var _typography = _interopRequireDefault(require("./typography"));
var _components = _interopRequireDefault(require("./components"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
var typographyBase = 16;
var primary = '#8261D8';
var secondary = '#EAEBFE';
var feedbackIconColors = {
  verySad: '#D68C03',
  sad: '#CCA109',
  neutral: '#B8BA04',
  happy: '#7FC613',
  veryHappy: '#64BF59'
};
var theme = {
  identifier: 'base',
  name: 'Base Theme',
  displayName: 'Base Theme',
  space: [4, 8, 10, 12, 16, 20, 24, 32],
  palette: _palette["default"],
  spacing: {
    xTiny: '4px',
    tiny: '8px',
    xSmall: '12px',
    xxSmall: '14px',
    small: '16px',
    smallPlus: '20px',
    base: '24px',
    xBase: '28px',
    basePlus: '32px',
    xxxBase: '40px',
    large: '48px',
    xLarge: '64px',
    xxLarge: '80px',
    xxxLarge: '96px'
  },
  logoName: 'baseLogo',
  extra: {
    rewardsCategories: {
      "default": _palette["default"].primary,
      extra: '#050950',
      categories: ['#B580E2', '#F5A623', '#4A11DD', '#AC9E0D', '#DC718A', '#6AE1C4', '#4A4A4A', '#E27370', '#5F618F', '#00a3ad', '#953bb9', '#bf0d3e', '#98cc02', '#EAEBFE', '#9900ff', '#cfe2f3', '#76a5af', '#674ea7', '#741b47', '#0c343d', '#f1c232', '#1155cc', '#20124d', '#6aa84f', '#a61c00', '#b45f06', '#274e13', '#6d9eeb', '#351c75', '#d0e0e3', '#f4cccc', '#bf9000', '#cc0000', '#ff00ff', '#00ffff', '#00ff00', '#980000', '#e69138', '#1c4587', '#38761d', '#93c47d', '#a4c2f4', '#a2c4c9', '#f9cb9c', '#e06666', '#7f6000', '#c9daf8', '#ead1dc', '#a64d79', '#f8a641']
    },
    rewardsStatus: {
      live: '#62D2BA',
      published: '#7BAEEB',
      draft: '#5B4894',
      expired: '#F08909',
      error: '#E55672',
      calculating: '#D8D8D8'
    },
    genericStatus: {
      status_live: '#62D2BA',
      status_published: '#7BAEEB',
      status_draft: '#5B4894',
      status_expired: '#F08909',
      status_error: '#E55672',
      status_calculating: '#D8D8D8'
    },
    chips: {
      primary: '#1d7fd1',
      secondary: '#856dcb',
      success: '#4caf50',
      warning: '#f08909',
      error: '#e6453a',
      "default": '#8b8b8b'
    },
    linkColor: '#0073a3',
    feedbackStatus: {
      size: {
        mobile: '32px',
        small: '48px',
        medium: '64px',
        large: '100px'
      },
      veryHappy: feedbackIconColors.veryHappy,
      happy: feedbackIconColors.happy,
      neutral: feedbackIconColors.neutral,
      sad: feedbackIconColors.sad,
      verySad: feedbackIconColors.verySad,
      "default": '#BDC6CD',
      contextMenu: '#4A4A4A',
      feedbackIcons: function feedbackIcons() {
        return "\n          &.feedback-rating-5 {\n              path {\n                &:nth-child(1) {\n                  fill: #88BD48;\n                }\n                &:nth-child(2) {\n                  fill: #649935;\n                }\n                &:nth-child(3) {\n                  fill: #649935;\n                }\n                &:nth-child(4) {\n                  fill: #649935;\n                }\n              }\n            }\n            &.feedback-rating-4 {\n              path {\n                &:nth-child(1) {\n                  fill: #C6D537;\n                }\n                &:nth-child(2) {\n                  fill: #A1B10C;\n                }\n              }\n            }\n            &.feedback-rating-3 {\n              path {\n                &:nth-child(1) {\n                  fill: #F8BC07;\n                }\n                &:nth-child(2) {\n                  fill: #E98400;\n                }\n              }\n            }\n            &.feedback-rating-2 {\n              path {\n                &:nth-child(1) {\n                  fill: #FB7E18;\n                }\n                &:nth-child(2) {\n                  fill: #E33D0C;\n                }\n              }\n            }\n            &.feedback-rating-1 {\n              path {\n                &:nth-child(1) {\n                  fill: #F1583F;\n                }\n                &:nth-child(2) {\n                  fill: #D82323;\n                }\n              }\n            }\n        ";
      }
    },
    sharesColorMap: {
      vested: primary,
      unvested: secondary
    },
    overallImpactColorMap: {
      active: primary,
      inactive: secondary,
      categories: ['#E53935', '#741DA9', '#5063CD', '#18BEE1', '#00897B', '#7CB342', '#F4AB00', '#F15524', '#754B3D']
    },
    delimiterTypes: {
      small: {
        width: '64px',
        height: '2px'
      },
      medium: {
        width: '160px',
        height: '1px'
      }
    },
    boxShadow: "0 2px 4px 0 ".concat(_palette["default"].gray.charlie),
    genericBorderRadius: '4px'
  }
};
theme.typography = (0, _typography["default"])(typographyBase);
theme.components = (0, _components["default"])(theme);
var _default = theme;
exports["default"] = _default;