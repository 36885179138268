"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledMediaHandler = exports.StyledMediaContainer = exports.StyledMedia = exports.StyledBanner = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
var _utils = require("@trs/utils");
var _templateObject, _templateObject2, _templateObject3;
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
var StyledBanner = _styledComponents["default"].div.withConfig({
  displayName: "StyledBannerstyle__StyledBanner",
  componentId: "sc-1galppm-0"
})(["", ";"], function (_ref) {
  var theme = _ref.theme,
    readOnly = _ref.readOnly;
  return (0, _styledComponents.css)(["display:flex;flex-direction:column;align-items:center;position:relative;", ";.media-object-title{padding:", " 0px;}", ""], readOnly ? (0, _styledComponents.css)(["margin:0px -24px;"]) : (0, _styledComponents.css)(["margin:0px;"]), theme.spacing.small, _utils.media.tabletLandscape(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n        margin: 0px;\n\n        ", ";\n  \n        ", "\n      "])), readOnly && (0, _styledComponents.css)([".media-object-title{background-color:white;}"]), !readOnly && (0, _styledComponents.css)(["margin:0px;"])));
});
exports.StyledBanner = StyledBanner;
StyledBanner.displayName = 'StyledBanner';
var StyledMediaHandler = _styledComponents["default"].div.withConfig({
  displayName: "StyledBannerstyle__StyledMediaHandler",
  componentId: "sc-1galppm-1"
})(["", ";"], function (_ref2) {
  var theme = _ref2.theme;
  var palette = theme.palette,
    spacing = theme.spacing;
  return (0, _styledComponents.css)(["display:flex;flex-direction:row;align-items:center;justify-content:center;background-color:", ";cursor:pointer;padding-top:", ";padding-bottom:", ";border:3px dotted ", ";width:100%;color:", ";&.disabled{cursor:no-drop;color:", ";}.media-helper-icon{width:", ";height:", ";}"], palette.basic.white, spacing.small, spacing.small, palette.utility.border, palette.primary, palette.gray.charlie, spacing.xLarge, spacing.xLarge);
});
exports.StyledMediaHandler = StyledMediaHandler;
StyledMediaHandler.displayName = 'StyledMediaHandler';
var StyledMediaContainer = _styledComponents["default"].div.withConfig({
  displayName: "StyledBannerstyle__StyledMediaContainer",
  componentId: "sc-1galppm-2"
})(["", ";"], function () {
  return (0, _styledComponents.css)(["position:relative;display:flex;justify-content:center;max-height:200px;width:100%;", ";"], _utils.media.tabletLandscape(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n      max-height: 300px;\n    "]))));
});
exports.StyledMediaContainer = StyledMediaContainer;
StyledMediaContainer.displayName = 'StyledMediaContainer';
var StyledMedia = _styledComponents["default"].div.withConfig({
  displayName: "StyledBannerstyle__StyledMedia",
  componentId: "sc-1galppm-3"
})(["", ";"], function (_ref3) {
  var theme = _ref3.theme;
  return (0, _styledComponents.css)(["position:relative;display:flex;justify-content:flex-end;width:inherit;img{width:100%;height:inherit;object-fit:cover;object-position:center;}.more-icon{cursor:pointer;position:absolute;z-index:10;margin:14px;padding:", " ", ";border-radius:50%;color:", ";background-color:", ";}.media{width:auto;width:100%;height:200px;}", ""], theme.spacing.tiny, theme.spacing.smallPlus, theme.palette.basic.white, theme.palette.basic.black, _utils.media.tabletLandscape(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n      .media {\n        height: 300px;\n      }\n      "]))));
});
exports.StyledMedia = StyledMedia;
StyledMedia.displayName = 'StyledMedia';