import * as R from 'ramda';
import { toLower, processDate } from '@trs/utils';
import { DATE_FORMAT } from '@trs/config';

export const transformExceptions = exceptions => {
  if (exceptions) {
    return exceptions.map(exception => ({
      ...exception,
      uploadedDate: processDate(exception.uploadedDate, DATE_FORMAT),
      menuListProps: {
        publish: {
          disabled: toLower(exception.status) === 'published',
        },
      },
      pending: false,
    }));
  }
  return [];
};

export const transformFilters = filterOptions =>
  R.map(
    item => ({
      title: item.key,
      key: item.key,
      list: item.options,
    }),
    filterOptions || []
  );

export const mapSetPending = (exceptionId, pending = true) => exception => {
  if (exception.id === exceptionId) {
    return {
      ...exception,
      pending,
    };
  }
  return exception;
};
