import { createAction } from 'redux-actions';
import * as R from 'ramda';
import APP_CONFIG from '@trs/config';
import { GET } from '../../../../../config/http';
import { reports, foreignExchange } from '../../../../../config/api';
import { showBanner } from '../../../../../actions/globalActions';
import { TextOnly } from '../../../../common/Text';
import { withCache } from '../../../../common/timedCache';

const { EXPIRY_TIMES } = APP_CONFIG;
const cachedGet = withCache(GET, EXPIRY_TIMES.H);

const actionTypes = {
  EXEC_REPORT_GET_SUMMARY: 'reports/executiveReport/summary/get',
  EXEC_GET_DETAILS: 'reports/executiveReport/details/get',
  EXEC_GET_CURRENCIES: 'reports/executiveReport/currencies/get',
  EXEC_RESET: 'reports/executiveReport/reset',
};

export const getSummary = createAction(
  actionTypes.EXEC_REPORT_GET_SUMMARY,
  data => data.promise,
  data => data.colors
);

export const getDetailedChartData = createAction(
  actionTypes.EXEC_GET_DETAILS,
  data => data.promise,
  data => data.key
);

export const getCurrencies = createAction(actionTypes.EXEC_GET_CURRENCIES);
export const resetExecutiveReport = createAction(actionTypes.EXEC_RESET);

export const REDUCER_KEY_MAP = {
  country: 'country',
  jobLevel: 'jobLevel',
  site: 'site',
};

export const loadCurrencies = () => dispatch =>
  dispatch(
    getCurrencies(
      cachedGet(foreignExchange.lookupCurrencies).catch(err => {
        dispatch(
          showBanner({
            type: 'error',
            content: TextOnly({ path: 'flexibleReports.EXECUTIVE_REPORT_CURRENCIES_GET_ERROR' }),
          })
        );
        return Promise.reject(err);
      })
    )
  );

export const loadSummary = currencyCode => (dispatch, getState) =>
  dispatch(
    getSummary({
      promise: GET(
        `${reports.executiveReportSummary}${currencyCode ? `?currency=${currencyCode}` : ''}`
      ).catch(err => {
        dispatch(
          showBanner({
            type: 'error',
            content: TextOnly({ path: 'flexibleReports.EXECUTIVE_REPORT_SUMMARY_GET_ERROR' }),
          })
        );
        return Promise.reject(err);
      }),
      colors: R.path(
        ['cms', 'theme', 'data', 'extra', 'rewardsCategories', 'categories'],
        getState()
      ),
    })
  );

export const loadKeyedTabData = key => (dispatch, getState) =>
  dispatch(
    getDetailedChartData({
      promise: cachedGet(
        `${reports.details}/${key}?currency=${R.path(
          ['reports', 'executiveReport', 'summary', 'currency'],
          getState()
        )}`
      ).catch(err => {
        dispatch(
          showBanner({
            type: 'error',
            content: TextOnly({ path: 'flexibleReports.EXECUTIVE_REPORT_DETAILS_GET_ERROR' }),
          })
        );
        return Promise.reject(err);
      }),
      key,
    })
  );

export default { getSummary, loadKeyedTabData };
