import * as R from 'ramda';
import { isBlank, getErrorMessage } from '@trs/utils';
import { POST, DELETE } from '../../../config/http';
import { showBanner } from '../../../actions/globalActions';
import { rewards } from '../../../config/api';
import { TextOnly } from '../../common/Text';

export const actionTypes = {
  EXCEPTIONS_SET_REWARDID: 'EXCEPTIONS_SET_REWARDID',
  LOAD_EXCEPTIONS_LIST_PENDING: 'LOAD_EXCEPTIONS_LIST_PENDING',
  LOAD_EXCEPTIONS_LIST_SUCCESS: 'LOAD_EXCEPTIONS_LIST_SUCCESS',
  LOAD_EXCEPTIONS_LIST_ERROR: 'LOAD_EXCEPTIONS_LIST_ERROR',
  PUBLISH_EXCEPTION_PENDING: 'PUBLISH_EXCEPTION_PENDING',
  PUBLISH_EXCEPTION_SUCCESS: 'PUBLISH_EXCEPTION_SUCCESS',
  PUBLISH_EXCEPTION_ERROR: 'PUBLISH_EXCEPTION_ERROR',
  PUBLISH_SELECTED_EXCEPTIONS_PENDING: 'PUBLISH_SELECTED_EXCEPTIONS_PENDING',
  PUBLISH_EXCEPTIONS_PENDING: 'PUBLISH_EXCEPTIONS_PENDING',
  PUBLISH_EXCEPTIONS_SUCCESS: 'PUBLISH_EXCEPTIONS_SUCCESS',
  PUBLISH_EXCEPTIONS_ERROR: 'PUBLISH_EXCEPTIONS_ERROR',
  DELETE_EXCEPTION_PENDING: 'DELETE_EXCEPTION_PENDING',
  DELETE_EXCEPTION_ERROR: 'DELETE_EXCEPTION_ERROR',
  DELETE_EXCEPTIONS_PENDING: 'DELETE_EXCEPTIONS_PENDING',
  DELETE_EXCEPTIONS_SUCCESS: 'DELETE_EXCEPTIONS_SUCCESS',
  DELETE_EXCEPTIONS_ERROR: 'DELETE_EXCEPTIONS_ERROR',
  EXCEPTIONS_RESET_STORE: 'EXCEPTIONS_RESET_STORE',
};

const dispatchError = (error, dispatch) => {
  const errorMessage = getErrorMessage(error) || R.path(['response', 'data', 'errors', 0], error);

  dispatch(
    showBanner({
      type: 'error',
      content:
        R.path(['response', 'status'], error) === 404
          ? TextOnly({ path: 'generic.RESOURCE_NOT_FOUND' })
          : errorMessage,
    })
  );
};

export const buildQueryPayload = (params, rewardId) => {
  const { sortAsc, pagingTop: top, pagingSkip: skip, searchText, filters } = params;
  let { sortBy } = params;
  const fetchFilters = [...filters];

  if (rewardId) {
    fetchFilters.push({
      fieldName: 'rewardId',
      values: [rewardId],
    });
  }

  if (sortBy === 'displayName') {
    sortBy = rewardId ? 'encodedEmployeeName' : 'encodedRewardName';
  }

  if (sortBy === 'employeeName') {
    sortBy = 'encodedEmployeeName';
  }

  if (sortBy === 'rewardName') {
    sortBy = 'encodedRewardName';
  }

  return {
    sort: {
      sortAsc,
      sortBy,
    },
    paging: {
      top,
      skip,
    },
    search: {
      searchText,
    },
    filters: fetchFilters,
  };
};

export const loadExceptions = (params) => (dispatch, getState) => {
  const state = getState().exceptionsList;
  const { rewardId } = state;
  const payload = buildQueryPayload(params, rewardId);

  dispatch({
    type: actionTypes.LOAD_EXCEPTIONS_LIST_PENDING,
  });

  const fetchUri = rewardId
    ? `${rewards.exceptions}/search/${rewardId}`
    : `${rewards.exceptions}/search`;

  return POST(fetchUri, payload, {
    Pragma: 'no-cache',
  })
    .then(({ data }) => {
      dispatch({
        type: actionTypes.LOAD_EXCEPTIONS_LIST_SUCCESS,
        data,
        tableTools: params,
      });
    })
    .catch((err) => {
      dispatchError(err, dispatch);
      dispatch({
        type: actionTypes.LOAD_EXCEPTIONS_LIST_ERROR,
        err,
      });
    });
};

export const publishException = (exceptionId) => (dispatch, getState) => {
  const state = getState().exceptionsList;
  const { tableTools } = state;

  dispatch({
    type: actionTypes.PUBLISH_EXCEPTION_PENDING,
    id: exceptionId,
  });

  return POST(`${rewards.exceptions}/${exceptionId}/publish`)
    .then((result) =>
      dispatch({
        type: actionTypes.PUBLISH_EXCEPTION_SUCCESS,
        payload: result,
        id: exceptionId,
      })
    )
    .then(() => dispatch(loadExceptions(tableTools)))
    .catch((err) => {
      dispatchError(err, dispatch);
      dispatch({
        type: actionTypes.PUBLISH_EXCEPTION_ERROR,
        err,
        id: exceptionId,
      });
    });
};

export const deleteException = (exceptionId) => (dispatch, getState) => {
  const state = getState().exceptionsList;
  const { tableTools } = state;

  dispatch({
    type: actionTypes.DELETE_EXCEPTION_PENDING,
    id: exceptionId,
  });

  return DELETE(`${rewards.exceptions}/${exceptionId}`)
    .then(() => dispatch(loadExceptions(tableTools)))
    .catch((err) => {
      dispatchError(err, dispatch);
      dispatch({
        type: actionTypes.DELETE_EXCEPTION_ERROR,
        err,
        id: exceptionId,
      });
      return Promise.reject(err);
    });
};

export const publishSelectedExceptions =
  (selectedExceptions = []) =>
  (dispatch, getState) => {
    const state = getState().exceptionsList;
    const { tableTools, rewardId } = state;

    const fetchUrl = `${rewards.exceptionsPublish}${rewardId ? `?rewardId=${rewardId}` : ''}`;

    if (isBlank(selectedExceptions)) {
      dispatch({ type: actionTypes.PUBLISH_EXCEPTIONS_PENDING });
    } else {
      dispatch({ type: actionTypes.PUBLISH_SELECTED_EXCEPTIONS_PENDING });
    }

    return POST(fetchUrl, selectedExceptions)
      .then(() => {
        dispatch({ type: actionTypes.PUBLISH_EXCEPTIONS_SUCCESS });
        dispatch(loadExceptions(tableTools));
      })
      .catch((err) => {
        dispatchError(err, dispatch);
        dispatch({
          type: actionTypes.PUBLISH_EXCEPTIONS_ERROR,
          err,
        });
      });
  };

export const publishFilteredExceptions = () => (dispatch, getState) => {
  const state = getState().exceptionsList;
  const { tableTools, rewardId } = state;
  const payload = buildQueryPayload(tableTools, rewardId);

  dispatch({ type: actionTypes.PUBLISH_EXCEPTIONS_PENDING });

  return POST(`${rewards.exceptionsPublish}/filtered`, payload)
    .then(() => {
      dispatch({ type: actionTypes.PUBLISH_EXCEPTIONS_SUCCESS });
      dispatch(loadExceptions(tableTools));
    })
    .catch((err) => {
      dispatchError(err, dispatch);
      dispatch({
        type: actionTypes.PUBLISH_EXCEPTIONS_ERROR,
        err,
      });
      return Promise.reject(err);
    });
};

export const deleteSelectedExceptions =
  (selectedExceptions = []) =>
  (dispatch, getState) => {
    const state = getState().exceptionsList;
    const { tableTools, rewardId } = state;

    const fetchUrl = `${rewards.exceptionsDelete}${rewardId ? `?rewardId=${rewardId}` : ''}`;

    dispatch({ type: actionTypes.DELETE_EXCEPTIONS_PENDING });

    return POST(fetchUrl, selectedExceptions)
      .then(() => {
        dispatch({ type: actionTypes.DELETE_EXCEPTIONS_SUCCESS });
        dispatch(loadExceptions(tableTools));
      })
      .catch((err) => {
        dispatchError(err, dispatch);
        dispatch({
          type: actionTypes.DELETE_EXCEPTIONS_ERROR,
          err,
        });
      });
  };

export const deleteFilteredExceptions = () => (dispatch, getState) => {
  const state = getState().exceptionsList;
  const { tableTools, rewardId } = state;
  const payload = buildQueryPayload(tableTools, rewardId);

  dispatch({ type: actionTypes.DELETE_EXCEPTIONS_PENDING });

  return POST(`${rewards.exceptionsDelete}/filtered`, payload)
    .then(() => {
      dispatch({ type: actionTypes.DELETE_EXCEPTIONS_SUCCESS });
      dispatch(loadExceptions(tableTools));
    })
    .catch((err) => {
      dispatchError(err, dispatch);
      dispatch({
        type: actionTypes.DELETE_EXCEPTIONS_ERROR,
        err,
      });
      return Promise.reject(err);
    });
};

export const resetExceptionListStore = () => ({
  type: actionTypes.EXCEPTIONS_RESET_STORE,
});

export const setRewardId = (rewardId) => ({
  type: actionTypes.EXCEPTIONS_SET_REWARDID,
  payload: rewardId,
});

export const actions = {
  setRewardId,
  loadExceptions,
  publishException,
  publishSelectedExceptions,
  publishFilteredExceptions,
  deleteException,
  deleteSelectedExceptions,
  deleteFilteredExceptions,
  resetExceptionListStore,
};
