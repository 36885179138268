import * as R from 'ramda';
import { Log, isBlank } from '@trs/utils';
import { fetchStaticAsset } from 'config/http';
import { cms } from 'config/api';

export const LOGO_ASSET_NAME = 'logo.svg';

let loggingGroup = null;
const logWarningMessage = ({ section, key, value }) => {
  if (!loggingGroup) {
    Log.startGroup(section);
    loggingGroup = true;
  }
  Log.warn(`Missing value for: ${key}. Default: ${value}`, 'CMS');
};

const isLocalEnvironment = (isIntegration) =>
  isIntegration || (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test');

export const getProcessedLabels = (labels) => {
  // I'm leaving this one here so we can easily get the cms text equivalent
  const processedLabels = R.mapObjIndexed((value, key) => `${key}|${value.default}`, labels);
  window.console.log(JSON.stringify(R.values(processedLabels)));
};

export const mapCmsLabels = ({ serverLabels = {}, localLabels = {}, section = '' }) => {
  const isLocal = isLocalEnvironment();
  const mergedLabels = R[isLocal ? 'mergeDeepLeft' : 'mergeDeepRight'](localLabels, serverLabels);
  const transformedLabels = R.map((item) => item.value || item, mergedLabels);
  // we are merging these as we have BE sent validation errors.

  const labelMap = Object.keys(transformedLabels).reduce((agg, key) => {
    const localLabel = R.prop(key, localLabels);
    if (isLocal && localLabel && !R.path([key, 'value'], serverLabels)) {
      logWarningMessage({ section, key, value: localLabel });
    }

    return {
      ...agg,
      [key]: R.prop(key, transformedLabels),
    };
  }, {});
  if (loggingGroup) {
    Log.groupEnd();
    loggingGroup = null;
  }
  return labelMap;
};

const CUSTOM_ASSETS_MAPPING = [
  { selector: 'favicon', asset: 'favicon.ico' },
  { selector: 'favicon-double', asset: 'favicon-32x32.png' },
  { selector: 'manifest', asset: 'manifest.json' },
  { selector: 'apple-touch-icon', asset: 'apple-touch-icon.png' },
  { selector: 'mask-icon', asset: 'safari-pinned-tab.svg' },
];

export const getStaticAssetPath = (asset) => `${cms.assets}/${asset}`;

export const overrideCustomAssets = () => {
  fetchStaticAsset(`${cms.assets}/names`)
    .then(({ data }) => {
      if (isBlank(data)) return;
      const assets = R.filter((item) => data.includes(item.asset), CUSTOM_ASSETS_MAPPING);
      assets.map((item) => {
        const assetPath = getStaticAssetPath(item.asset);
        return fetchStaticAsset(assetPath)
          .then(() => {
            const element = document.getElementById(item.selector);
            if (!element) return;
            element.href = assetPath;
          })
          .catch((e) => Promise.reject(e));
      });
    })
    .catch((e) => Promise.reject(e));
};

export default mapCmsLabels;
