import * as R from 'ramda';

export const propNames = {
  country: 'Country',
  countryCode: 'CountryCode',
  employeeType: 'employeeType',
  compensationGrade: 'compensationGrade',
  compensationGradeProfile: 'compensationGradeProfile',
  annualSalary: 'annualSalary',
  gradeProfileCurrency: 'Calculated.grade Profile Currency',
  minAnnualSalary: 'Calculated.grade Profile Minimum',
  midAnnualSalary: 'Calculated.grade Profile Midpoint',
  maxAnnualSalary: 'Calculated.grade Profile Segment 3 Top',
  fullTimeEquivalent: 'fullTimeEquivalent',
  payPoint: 'payPoint',
  targetBonus: 'Calculated.bonus Target Percent',
  compensationPlanAmount: 'compensationPlanAmount',
  compensationPlanCurrency: 'compensationPlanCurrency',
  employeeId: 'id',
  assignmentStart: 'assignments.assignmentStart',
  assignmentEnd: 'assignments.assignmentEnd',
  assignmentDurationInMonths: 'assignments.assignmentDurationInMonths',
  familySize: 'assignments.familySize',
  homeLocationId: 'assignments.homeLocationId',
  hostLocationId: 'assignments.hostLocationId',
  splitFamily: 'assignments.splitFamily',
  totalAnnualBasePay: 'totalAnnualBasePay',
  currency: 'Currency',
  exchangeSource: 'ExchangeSource',
  mobility: 'isMobility',
};

export const baseFields = {
  Country: undefined,
  CountryCode: undefined,
  employeeType: undefined,
  compensationGrade: undefined,
  compensationGradeProfile: undefined,
  isMobility: undefined,
};

export const advancedFields = {
  Country: undefined,
  CountryCode: undefined,
  isMobility: undefined,
  employeeType: undefined,
  compensationGrade: undefined,
  compensationGradeProfile: undefined,
  annualSalary: undefined,
  'Calculated.grade Profile Minimum': undefined,
  'Calculated.grade Profile Midpoint': undefined,
  'Calculated.grade Profile Segment 3 Top': undefined,
  fullTimeEquivalent: undefined,
  payPoint: undefined,
  'Calculated.bonus Target Percent': undefined,
  Currency: undefined,
  ExchangeSource: undefined,
};

const baseMetadataFields = modellerState => [
  {
    propertyName: propNames.countryCode,
    type: 'hidden',
    weight: 0,
  },
  {
    dataSource: 'hardcoded',
    description: 'Country',
    displayName: 'Country',
    propertyName: propNames.country,
    type: 'hardcoded',
    valuesUrl: null,
    options: modellerState.countries,
    trigger: true,
    weight: 1000,
  },
  {
    propertyName: propNames.employeeType,
    trigger: true,
    weight: 999,
  },
  {
    dataSource: 'hardcoded',
    propertyName: 'isMobility',
    displayName: 'Mobility',
    type: 'hardcoded',
    description: 'Mobility',
    weight: 998,
    trigger: true,
    options: [
      {
        label: 'Local',
        value: false,
      },
      {
        label: 'Mobile',
        value: true,
      },
    ],
    valuesUrl: null,
  },
  {
    dataSource: 'hardcoded',
    description: 'Compensation Grade',
    displayName: 'Compensation Grade',
    propertyName: propNames.compensationGrade,
    type: 'hardcoded',
    valuesUrl: null,
    trigger: true,
    weight: 997,
  },
  {
    dataSource: 'hardcoded',
    description: 'Compensation Grade Profile',
    displayName: 'Compensation Grade Profile',
    propertyName: propNames.compensationGradeProfile,
    type: 'hardcoded',
    valuesUrl: null,
    trigger: true,
    weight: 996,
  },
];

const advancedMetadataFields = modellerState => [
  {
    dataSource: 'hardcoded',
    description: 'Annual Salary',
    displayName: 'Annual Salary',
    propertyName: propNames.annualSalary,
    type: 'decimal',
    valuesUrl: null,
    weight: 995,
  },
  {
    displayName: 'Min Annual Salary',
    propertyName: propNames.minAnnualSalary,
    type: 'decimal',
    disabled: true,
    weight: 994,
  },
  {
    displayName: 'Mid Annual Salary',
    propertyName: propNames.midAnnualSalary,
    type: 'decimal',

    disabled: true,
    weight: 993,
  },
  {
    displayName: 'Max Annual Salary',
    propertyName: propNames.maxAnnualSalary,
    type: 'decimal',
    disabled: true,
    weight: 992,
  },
  {
    propertyName: propNames.fullTimeEquivalent,
    rangedValue: true,
    type: 'decimal',
    weight: 991,
  },
  {
    dataSource: 'hardcoded',
    description: 'Pay point',
    displayName: 'Pay point (%)',
    propertyName: propNames.payPoint,
    type: 'decimal',
    valuesUrl: null,
    weight: 990,
  },
  {
    propertyName: propNames.targetBonus,
    type: 'decimal',
    weight: 989,
  },
  {
    propertyName: propNames.compensationPlanCurrency,
    type: 'hardcoded',
    options: modellerState.currencies,
    valuesUrl: null,
  },
  {
    propertyName: propNames.employeeId,
    disabled: true,
  },
];

const mobilityMetadataFields = modellerState => [
  {
    propertyName: propNames.assignmentStart,
    weight: 988,
  },
  {
    propertyName: propNames.assignmentEnd,
    weight: 987,
  },
  {
    propertyName: propNames.familySize,
    type: 'decimal',
  },
  {
    dataSource: 'hardcoded',
    description: 'Home Location',
    displayName: 'Home Location',
    propertyName: propNames.homeLocationId,
    type: 'hardcoded',
    options: R.path(['locations', 'homeLocations'], modellerState),
    valuesUrl: null,
  },
  {
    dataSource: 'hardcoded',
    description: 'Host Location',
    displayName: 'Host Location',
    propertyName: propNames.hostLocationId,
    type: 'hardcoded',
    options: R.path(['locations', 'hostLocations'], modellerState),
    valuesUrl: null,
  },
  {
    propertyName: propNames.splitFamily,
    type: 'boolean',
    options: [
      {
        label: 'Yes',
        value: 'true',
      },
      {
        label: 'No',
        value: 'false',
      },
    ],
    valuesUrl: null,
  },
];

const hardCodedMetadataFields = modellerState => [
  {
    dataSource: 'hardcoded',
    description: 'Currency',
    displayName: 'Output Currency',
    propertyName: propNames.currency,
    type: 'hardcoded',
    options: modellerState.currencies,
    valuesUrl: null,
    weight: -2,
  },
  {
    dataSource: 'hardcoded',
    description: 'Exchange Source',
    displayName: 'Output Exchange Source',
    propertyName: propNames.exchangeSource,
    type: 'hardcoded',
    options: modellerState.sources,
    valuesUrl: null,
    weight: -3,
  },
];

export const baseMetadataFn = modellerState => [
  ...baseMetadataFields(modellerState),
  ...advancedMetadataFields(modellerState),
  ...mobilityMetadataFields(modellerState),
  ...hardCodedMetadataFields(modellerState),
];
