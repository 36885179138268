import { handleActions } from 'redux-actions';
import * as R from 'ramda';
import {
  loadEmployeesPending,
  loadEmployeesSuccess,
  loadEmployeesError,
  setStartDate,
  setEndDate,
  openEnrollmentWindowSuccess,
  enrollmentWindowStoreReset,
} from '../actions';
import mapEmployeesFromPayload from './helpers';

export const initialState = {
  employees: {
    list: [],
    totalResults: 0,
    fetchingList: false,
    errors: [],
    filterOptions: [],
  },
  startDate: null,
  endDate: null,
};

const reducer = handleActions(
  new Map([
    [
      loadEmployeesPending,
      state => ({
        ...state,
        employees: {
          ...state.employees,
          fetchingList: true,
        },
      }),
    ],
    [
      loadEmployeesError,
      state => ({
        ...state,
        employees: {
          ...state.employees,
          fetchingList: false,
        },
      }),
    ],
    [
      loadEmployeesSuccess,
      (state, action) => ({
        ...state,
        employees: mapEmployeesFromPayload(action),
      }),
    ],
    [
      setStartDate,
      (state, action) => ({
        ...state,
        startDate: R.prop('payload', action),
      }),
    ],
    [
      setEndDate,
      (state, action) => ({
        ...state,
        endDate: R.prop('payload', action),
      }),
    ],
    [
      openEnrollmentWindowSuccess,
      state => ({
        ...state,
        startDate: null,
        endDate: null,
      }),
    ],
    [enrollmentWindowStoreReset, () => R.clone(initialState)],
  ]),
  initialState
);

export default reducer;
