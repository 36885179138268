import { combineReducers } from 'redux';
import * as R from 'ramda';
import { handleActions } from 'redux-actions';
import actionTypes from '../actions/types';
import initialState from './initialState';
import instancesReducer from '../components/model/reducer';
import BM_STATUS from '../../../common/helpers';
import mapModellerPayloadToCategoryMappings, { INCLUDED } from './helpers';
import {
  getModellerCategoriesMapping,
  changeCategoriesMapping,
  submitActions,
  resetCategoriesMapping,
  getModellerUserPermissions,
} from '../actions/index';
import { mapPayloadToTableStructure } from '../../../benchmarking/components/global/components/dashboard/components/mapCategories/reducer/helpers';
import { MODELLER_PROFILE_TABINDEX, MODELLER_OUTPUT_TABINDEX } from '../../util/modellerTabs';

const mainReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.VALIDATE_MODELLER: {
      const {
        payload: { isModellerValid },
      } = action;
      return {
        ...state,
        isModellerValid,
      };
    }
    case actionTypes.LOAD_MODELLER_OUTPUT: {
      const { error } = action;
      return {
        ...state,
        error: {
          isError: error,
          response: error ? R.path(['payload', 'response', 'data', 'errors', 0], action) : '',
          status: error ? R.path(['payload', 'response', 'status'], action) : null,
          statusText: error ? R.path(['payload', 'response', 'statusText'], action) : '',
        },
        isLoading: !action.ready,
        modellerTabIndex: action.ready ? MODELLER_OUTPUT_TABINDEX : MODELLER_PROFILE_TABINDEX,
        type: R.path(['response', 'type'], action.payload),
      };
    }
    case actionTypes.SET_REQUEST_ID: {
      return {
        ...state,
        requestId: action.payload,
      };
    }
    case actionTypes.MODELLER_LOADED:
      return {
        ...state,
        modellerLoaded: true,
      };
    case actionTypes.LOAD_VARIABLES: {
      const { error } = action;
      return {
        ...state,
        error: {
          isError: action.error,
          response: error ? R.path(['payload', 'response', 'data', 'errors', 0], action) : '',
          status: error ? R.path(['payload', 'response', 'status'], action) : null,
          statusText: error ? R.path(['payload', 'response', 'statusText'], action) : '',
        },
        loadingVariables: !action.ready,
        ...action.payload,
      };
    }
    case actionTypes.TOGGLE_MODELLER_PROFILE:
      return {
        ...state,
        err: undefined,
        isModellerValid: false,
        modellerTabIndex: MODELLER_PROFILE_TABINDEX,
      };
    case actionTypes.TOGGLE_SEARCH_DIALOG: {
      return {
        ...state,
        isSearchDialogVisible: !state.isSearchDialogVisible,
        selectedInstance: action.payload,
      };
    }
    case actionTypes.FETCH_EMPLOYEES_LIST_PENDING:
      return {
        ...state,
        isSearchInProgress: true,
      };
    case actionTypes.FETCH_EMPLOYEES_LIST_SUCCESS:
      return {
        ...state,
        isSearchInProgress: false,
        error: null,
      };
    case actionTypes.FETCH_EMPLOYEES_LIST_ERROR:
      return {
        ...initialState,
        isSearchInProgress: false,
        error: action.err,
      };
    case actionTypes.RESET_STORE:
      return {
        ...initialState,
        countries: state.countries,
        sources: state.sources,
        currencies: state.currencies,
        variables: state.variables,
        selectedInstance: state.selectedInstance,
        loadingVariables: false,
      };
    default:
      return state;
  }
};

const submitHandler = [
  [submitActions.pending, state => ({ ...state, isSubmitting: true })],
  [
    submitActions.success,
    state => ({
      ...state,
      isSubmitting: false,
      status: BM_STATUS.SUBMITTED,
      hasChanges: false,
    }),
  ],
  [submitActions.error, state => ({ ...state, isSubmitting: false })],
];

const configurationReducer = handleActions(
  new Map([
    [
      getModellerCategoriesMapping,
      (state, action) => {
        const { payload, ready, error } = action;

        if (!ready) {
          return {
            ...state,
            isLoading: true,
          };
        }

        if (error) {
          return {
            ...state,
            isLoading: false,
            categories: {},
            mappings: {},
          };
        }
        const { categoriesMappings, etag, status } = payload;

        return {
          ...state,
          hasChanges: false,
          isLoading: false,
          loadingVariables: !action.ready,
          categories: mapPayloadToTableStructure(categoriesMappings),
          etag,
          status,
          mappings: mapModellerPayloadToCategoryMappings(categoriesMappings),
        };
      },
    ],
    [
      changeCategoriesMapping,
      (state, { payload: { key, newMapping } }) => {
        return {
          ...state,
          hasChanges: true,
          mappings: {
            ...state.mappings,
            [key]: { ...newMapping, isIncluded: newMapping.columnId === INCLUDED },
          },
        };
      },
    ],
    [
      getModellerUserPermissions,
      (state, action) => {
        const { payload } = action;
        return {
          ...state,
          payload,
        };
      },
    ],
    [resetCategoriesMapping, () => R.clone(initialState)],
    ...submitHandler,
  ]),
  R.clone(initialState)
);

export default combineReducers({
  mainReducer,
  instancesReducer,
  configurationReducer,
});
