import React from 'react';
import { Link } from 'react-router-dom';

import { ErrorPage, Paragraph, Button, LinkButton } from '@trs/components';
import Text, { TextOnly } from '../../../modules/common/Text';
import * as images from '../../../assets/images';
import { SUPPORT_EMAIL } from '../../constants';

const NotFoundPage = () => (
  <ErrorPage
    imageSrc={images.pageNotFound}
    title={TextOnly({ path: 'generic.NOT_FOUND_TITLE' })}
    text={
      <Paragraph>
        <Text path="generic.NOT_FOUND_TEXT" withHtml />
        &nbsp;
        <LinkButton href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</LinkButton>
        <span> ) for more details.</span>
      </Paragraph>
    }
    button={
      <Link to="/">
        <Button>
          <Text path="generic.ERROR_PAGE_BUTTON" />
        </Button>
      </Link>
    }
  />
);

export default NotFoundPage;
