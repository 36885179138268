import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { silentRenew } from '../../../actions/userActions';

class Renew extends React.PureComponent {
  componentDidMount() {
    this.props.actions.silentRenew();
  }

  render() {
    return null;
  }
}

Renew.defaultProps = {
  user: {},
};

Renew.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  user: PropTypes.shape({
    isLoggedIn: PropTypes.bool,
  }),
};

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ silentRenew }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Renew);
