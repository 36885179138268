/* eslint-disable max-lines-per-function */

import styled, { css } from 'styled-components';
import * as R from 'ramda';
import { media } from '@trs/utils';

const StyledFooter = styled.footer`
  ${props => {
    const {
      palette,
      spacing,
      typography,
      components: { header, footer },
      extra: { boxShadow },
    } = props.theme;

    const accentColor = R.prop('accentColor', header) || palette.primary;
    const headerBackground = R.prop('background', header) || palette.secondary;
    const footerBackground = R.prop('background', footer) || headerBackground;
    const footerLinksBackground = R.prop('cookieNoticeBackground', footer) || palette.primary;
    const footerLinksText = R.prop('cookieNoticeText', footer) || palette.basic.white;

    return css`
      background-color: ${footerBackground};
      box-shadow: 0 -1px 5px 0 ${palette.gray.charlie};

      a {
        text-decoration: none;
      }

      ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
      }

      .layout {
        margin-bottom: 0;
      }

      .logo-wrapper {
        position: relative;
        display: flex;
        padding: ${spacing.base} 0;

        ${media.tablet`
          padding: ${spacing.base} 0;
        `} ${media.desktop`
          padding: ${spacing.basePlus} 0;
        `};
      }

      .contact-info {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }

      .contact-info-card {
        text-align: center;

        .icon,
        span {
          display: block;
          color: ${accentColor};
        }

        span {
          font-size: ${typography.size.misc};
          line-height: ${typography.lineHeight.misc};
        }
      }

      .separator {
        border-left: 2px solid ${accentColor};
        height: ${spacing.basePlus};
        margin: 0 ${spacing.small};
      }

      .back-to-top {
        position: absolute;
        right: ${spacing.tiny};
        top: -${spacing.small};
        width: ${spacing.basePlus};
        height: ${spacing.basePlus};
        background: ${palette.basic.white};
        border-radius: 50%;
        box-shadow: ${boxShadow};
        text-align: center;
        color: ${palette.primary};
        line-height: ${typography.lineHeight.h4};
        cursor: pointer;
      }

      .logo-container {
        position: static;
        top: auto;
      }

      .footer-links-wrapper {
        background: ${footerLinksBackground};
        color: ${footerLinksText};
        padding: ${spacing.small} 0;
        font-size: ${typography.size.misc};

        .layout {
          display: flex;
          align-items: flex-start;
          flex-wrap: wrap;
          padding: 0 ${spacing.small};
        }
      }

      .footer-links-wrapper a {
        color: ${footerLinksText};
      }

      .footer-links {
        li {
          margin-bottom: ${spacing.base};
        }
      }

      .copyright-container {
        flex-basis: 100%;
        order: 3;
      }

      .social-links {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-grow: 1;

        li:first-child {
          display: none;
        }
        li:not(:first-child) {
          margin-left: ${spacing.basePlus};
        }
      }

      ${media.mobileLandscape`
        .footer-links-wrapper {
          padding: ${spacing.tiny} 0;

          .layout {
            align-items: center;
            flex-wrap: nowrap;
          }
        }
        .separator {
          margin: 0 ${spacing.basePlus};
        }

        .copyright-container {
          order: 0;
          flex-grow: 1;
          flex-basis: auto;
        }

        .social-links {
          flex-grow: 0;
        }

        .footer-links {
          display: flex;
          align-items: center;
          li {
            padding-right: ${spacing.small};
            margin-right: ${spacing.small};
            margin-bottom: 0;
          }
        }
      `};

      ${media.desktop`
        .back-to-top {
          right: 0;
        }
        .contact-info-card {
          span {
            font-size: ${typography.size.icon};
            margin-top: ${spacing.tiny};
          }
        }
        .separator {
          height: ${spacing.large};
        }

        .footer-links-wrapper {
          font-size: ${typography.size.table};

          .layout {
            padding: 0 ${spacing.tiny};
          }
        }

        .footer-links {
          li:last-child {
            padding-right: 0;
            margin-right: 0;
            border-right: 0;
          }
        }

        .copyright-container {
          text-align: center;
        }

        .social-links {
          li:first-child {
            display: block;
            margin-right: ${spacing.base};
          }
          li:not(:first-child) {
            margin-left: ${spacing.small};
          }
        }
      `};
    `;
  }}
`;
StyledFooter.displayName = 'StyledFooter';
export default StyledFooter;
