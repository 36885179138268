import * as R from 'ramda';
import { createAction } from 'redux-actions';
import { PATCH } from '../../../../../../../../../config/http';
import { benchmark } from '../../../../../../../../../config/api';
import { showBanner } from '../../../../../../../../../actions/globalActions';
import { loadLocalBenchmarkingStatuses } from '../../../../../actions';
import { getLocalSettingsId } from '../../../../../actions/helpers';
import { etagUpdate } from '../../../actions/helpers';
import { validateDataForSubmit } from '../../../actions';
import { dispatchError } from '../../../../../../common/helpers';

const actionTypes = {
  BENCHMARKING_LOCAL_PREPARATIONS_COUNTRY_CELL_CHANGE:
    'BENCHMARKING/LOCAL_PREPARATIONS_COUNTRY_CELL_CHANGE',
  BENCHMARKING_LOCAL_PREPARATIONS_COMPANY_CELL_CHANGE:
    'BENCHMARKING/LOCAL_PREPARATIONS_COMPANY_CELL_CHANGE',
  BENCHMARKING_EXECUTIVE_SUMMARY_SUCCESS: 'BENCHMARKING_EXECUTIVE_SUMMARY_SUCCESS',
  BENCHMARKING_ADDITIONAL_INFO_CHANGE: 'BENCHMARKING_ADDITIONAL_INFO_CHANGE',
  BENCHMARKING_TOGGLE_EDIT_MODE: 'BENCHMARKING_TOGGLE_EDIT_MODE',
  BENCHMARKING_UPDATE_MARKET_DATA: 'BENCHMARKING_UPDATE_MARKET_DATA',
};

export const countryCellChange = createAction(
  actionTypes.BENCHMARKING_LOCAL_PREPARATIONS_COUNTRY_CELL_CHANGE
);
export const companyCellChange = createAction(
  actionTypes.BENCHMARKING_LOCAL_PREPARATIONS_COMPANY_CELL_CHANGE
);
export const saveExecutiveSummarySuccess = createAction(
  actionTypes.BENCHMARKING_EXECUTIVE_SUMMARY_SUCCESS
);
export const additionalInfoChange = createAction(actionTypes.BENCHMARKING_ADDITIONAL_INFO_CHANGE);
export const toggleEditMode = createAction(actionTypes.BENCHMARKING_TOGGLE_EDIT_MODE);
export const updateMarketIndicatorsForMarketData = createAction(
  actionTypes.BENCHMARKING_UPDATE_MARKET_DATA
);

export const saveExecutiveSummary = (executiveSummaryPayload, successMessage, location) => (
  dispatch,
  getState
) => {
  const { benchmarkingId, localId, country, etag } = R.path(
    ['benchmarking', 'local', 'localPreparation'],
    getState()
  );
  const localSettingsId = getLocalSettingsId(getState());
  const payload = {
    countryCode: country,
    executiveSummary: executiveSummaryPayload,
  };
  return dispatch(
    saveExecutiveSummarySuccess(
      PATCH(`${benchmark.benchmarking}/${benchmarkingId}/localPreparations/${localId}`, payload, {
        ETag: etag,
      })
        .then(response => {
          const responseBody = R.path(['data', 'response'], response);

          dispatch(etagUpdate(R.prop('etag', responseBody)));
          dispatch(loadLocalBenchmarkingStatuses(localSettingsId));
          dispatch(validateDataForSubmit(R.prop('isDataValid', responseBody)));
          dispatch(updateMarketIndicatorsForMarketData(R.prop('marketData', responseBody)));

          dispatch(
            showBanner({
              type: 'success',
              content: successMessage,
            })
          );
          return location;
        })
        .catch(error => {
          dispatchError(error, dispatch);
          return Promise.reject(error);
        })
    )
  );
};
