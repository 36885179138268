import * as R from 'ramda';
import { toISOString } from '@trs/utils';
import { FROM_DATE, TO_DATE, ROLES_MAP, ROLES_CORRELATION_MAP } from '../config/constants';

const getSkipValue = (page, top) => (page - 1) * top;

export const getSelectedRole = role => {
  let selectedRole = ROLES_MAP.userGenerated;

  if (R.has('value', role)) {
    selectedRole = R.prop('value', role);
  } else if (ROLES_CORRELATION_MAP[role]) {
    selectedRole = role;
  }

  return selectedRole;
};

export const getArgs = ({ userId, employeeId, from, to, role, page, top }) => {
  const selectedRole = getSelectedRole(role);

  return [
    { name: 'UserId', value: userId },
    { name: 'EmployeeId', value: employeeId },
    { name: 'From', value: toISOString(from) },
    { name: 'To', value: toISOString(to) },
    {
      name: 'IncludeSystemActions',
      value: R.path([selectedRole, 'includeSystemActions'], ROLES_CORRELATION_MAP),
    },
    {
      name: 'IncludeUserActions',
      value: R.path([selectedRole, 'includeUserActions'], ROLES_CORRELATION_MAP),
    },
    { name: 'Skip', value: getSkipValue(page, top) },
    { name: 'Top', value: top },
  ];
};

export const generateLineFilePayload = ({ id, name, email, filter }) => ({
  id,
  userName: name || R.prop('employeeId', filter) || email || '-',
  from: new Date(R.prop(FROM_DATE, filter)).toISOString(),
  to: new Date(R.prop(TO_DATE, filter)).toISOString(),
});

export const generateLinesFilePayload = ({
  userId,
  employeeId,
  fromDate,
  toDate,
  role,
  page,
  top,
}) => {
  const selectedRole = getSelectedRole(role);
  return {
    userId,
    employeeId,
    from: new Date(fromDate).toISOString(),
    to: new Date(toDate).toISOString(),
    includeSystemActions: R.path([selectedRole, 'includeSystemActions'], ROLES_CORRELATION_MAP),
    includeUserActions: R.path([selectedRole, 'includeUserActions'], ROLES_CORRELATION_MAP),
    skip: getSkipValue(page, top),
    top,
  };
};
