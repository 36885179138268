import * as R from 'ramda';
import { isBlank, objArrayToDict } from '@trs/utils';
import { createAction } from 'redux-actions';
import { TextOnly } from 'modules/common/Text';
import actionTypes from './types';
import {
  auth,
  modeller,
  organization,
  foreignExchange,
  rewards,
  mobility,
} from '../../../../config/api';
import createModels from '../util/createModels';
import { GET, POST, CancelToken } from '../../../../config/http';
import { withCache } from '../../../common/timedCache';
import {
  mapValuesToKeys,
  transformCorrelatedDimensionsPayload,
  getDimensionsForReset,
} from './utils';
import { validateModellerInstances, transformOptions, dispatchError } from './helpers';
import { showBanner } from '../../../../actions/globalActions';
import userManager from '../../../../config/userManager/userManager';

const cachedGet = withCache(GET, 600);
const ANNUAL_SALARY = 'Annual salary';
const ANNUAL_SALARY_ID = '111';

export const getModellerCategoriesMapping = createAction(actionTypes.GET_CATEGORIES_MAPPING);
export const getModellerUserPermissions = createAction(actionTypes.GET_MODELLER_PERMISSIONS);
export const changeCategoriesMapping = createAction(actionTypes.CHANGE_CATEGORIES_MAPPING);
export const resetCategoriesMapping = createAction(actionTypes.RESET_CATEGORIES_MAPPING);
export const submitActions = {
  pending: createAction(actionTypes.SUBMIT_CATEGORIES_MAPPING_PENDING),
  success: createAction(actionTypes.SUBMIT_CATEGORIES_MAPPING_SUCCESS),
  error: createAction(actionTypes.SUBMIT_CATEGORIES_MAPPING_ERROR),
};

export const loadModellerOutput = () => (dispatch, getState) => {
  const {
    modellerBeta: { mainReducer, instancesReducer },
  } = getState();

  return dispatch({
    type: actionTypes.LOAD_MODELLER_OUTPUT,
    payload: POST(modeller.modelV2, createModels(mainReducer, instancesReducer), {
      RequestId: mainReducer.requestId,
    }).then((response) => response.data),
  });
};

export const validateModeller = () => (dispatch, getState) => {
  const {
    modellerBeta: {
      instancesReducer: { instances, instanceFields },
    },
  } = getState();
  const modellerValidation = validateModellerInstances(instances, instanceFields);

  dispatch({ type: actionTypes.VALIDATE_MODELLER, payload: modellerValidation });

  if (modellerValidation.isModellerValid) {
    dispatch(loadModellerOutput());
  } else {
    showBanner({
      type: 'error',
      content: TextOnly({ path: 'generic.BANNER_FORM_ERROR_GENERIC' }),
    });
  }
};

const resetCorrelatedDimensionsData =
  ({ dimensionId, baseDimensions, instanceId }) =>
  (dispatch) => {
    const { nextDimension } = R.find(R.propEq('id', dimensionId))(baseDimensions);
    if (nextDimension) {
      dispatch({
        type: actionTypes.RESET_MODELLER_EMPLOYEE_CORRELATED_DIMENSIONS,
        payload: {
          resetDimensions: getDimensionsForReset({ dimensionId, baseDimensions }),
        },
        instanceId,
      });
    }
  };

export const getEmployeeDimensionValuesByDefaultWithCorrelation =
  ({ dimensionId, dimensionValue, isDefaultDimension, joinedInstanceFields, instanceId }) =>
  (dispatch, getState) => {
    const {
      modellerBeta: {
        mainReducer: {
          employeeDimensions: { dimensions: baseDimensions },
        },
        instancesReducer: { instanceFields },
      },
    } = getState();

    const instanceFieldsSlice =
      !isBlank(joinedInstanceFields) && !isBlank(joinedInstanceFields[instanceId])
        ? joinedInstanceFields
        : instanceFields;
    const { id: defaultDimensionId } = R.find(R.propEq('isDefault', true))(baseDimensions);
    const { nextDimension, isDefault } = R.find(R.propEq('id', dimensionId))(baseDimensions);
    const defaultDimensionValue = isDefaultDimension
      ? dimensionValue
      : R.path([instanceId, defaultDimensionId, 'value'], instanceFieldsSlice);

    return POST(modeller.employeeDimensionValuesByDefaultWithCorrelation(defaultDimensionValue), {
      forDimension: nextDimension,
      dimensionValues: transformCorrelatedDimensionsPayload({
        dimensionId,
        dimensionValue,
        isDefault,
        baseDimensions,
        instanceFields: instanceFieldsSlice,
        instanceId,
      }),
    })
      .then((response) => {
        if (!isBlank(response)) {
          const { isCalculating } = R.path(['data', 'response'], response);
          if (isCalculating) {
            showBanner({
              type: 'info',
              content: TextOnly({
                path: 'modeller.MODEL_DIMENSIONS_RECALCULATING',
              }),
            });
            dispatch({
              type: actionTypes.LOAD_DIMENSIONS_BY_DEFAULT_SUCCESS,
              payload: { baseField: {}, fields: {}, isCalculating },
              instanceId,
            });
          }
        }

        return {
          ...R.path(['data', 'response'], response),
          defaultDimensionId,
          defaultValue: defaultDimensionValue,
          forDimensionId: nextDimension,
          instanceId,
        };
      })
      .catch((error) => {
        dispatchError(error, dispatch, getState());
        return Promise.reject(error);
      });
  };

export const loadEmployeeDimensionValuesByDefaultWithCorrelation =
  ({
    dimensionId,
    dimensionValue,
    isDefaultDimension,
    joinedInstanceFields,
    shouldReset,
    instanceId,
  }) =>
  (dispatch, getState) => {
    dispatch({ type: actionTypes.LOAD_DIMENSIONS_BY_DEFAULT_PENDING, instanceId });

    return getEmployeeDimensionValuesByDefaultWithCorrelation({
      dimensionId,
      dimensionValue,
      isDefaultDimension,
      joinedInstanceFields,
      instanceId,
    })(dispatch, getState)
      .then((responseData) => {
        if (!isBlank(responseData)) {
          const {
            modellerBeta: {
              mainReducer: {
                employeeDimensions: { dimensions: baseDimensions },
              },
            },
          } = getState();

          const { isCalculating, dimensionValues, forDimensionId } = responseData;

          if (!isCalculating) {
            const dimensionsWithOptions = {
              [forDimensionId]: transformOptions(dimensionValues),
            };

            isBlank(shouldReset) &&
              resetCorrelatedDimensionsData({
                dimensionId,
                baseDimensions,
                instanceId,
              })(dispatch);

            dispatch({
              type: actionTypes.SET_MODELLER_EMPLOYEE_DIMENSIONS_VALUES,
              payload: {
                isCalculating,
                defaultValue: dimensionValue,
                isDefaultDimension,
                dimensionsWithOptions,
              },
              instanceId,
            });
            dispatch({
              type: actionTypes.LOAD_DIMENSIONS_BY_DEFAULT_SUCCESS,
              payload: {
                baseFields: objArrayToDict(baseDimensions, 'id'),
                fieldsOptions: dimensionsWithOptions,
                isCalculating,
              },
              instanceId,
            });
          }
        }
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.LOAD_DIMENSIONS_BY_DEFAULT_ERROR,
          error,
          instanceId,
        });
        return Promise.reject(error);
      });
  };

export const loadBaseVariables = () => (dispatch) => {
  const getVariables = cachedGet(modeller.variables);
  const getCountries = cachedGet(organization.lookupCountries);
  const getCurrencies = cachedGet(foreignExchange.lookupCurrencies);
  const getSources = cachedGet(foreignExchange.fxSources);
  const getCategories = GET(rewards.category);
  const getLocations = cachedGet(mobility.locations);
  const getEmployeeDimensions = GET(modeller.employeeDimensions);
  const getEmployeeDimensionValuesForDefaultDimension = GET(
    modeller.employeeDefaultDimensionValues
  );

  return dispatch({
    type: actionTypes.LOAD_VARIABLES,
    payload: Promise.all([
      getVariables,
      getCountries,
      getCurrencies,
      getSources,
      getCategories,
      getLocations,
      getEmployeeDimensions,
      getEmployeeDimensionValuesForDefaultDimension,
    ])
      .then((values) => mapValuesToKeys(values))
      .catch((error) => {
        const err = R.path(['response', 'data', 'errors'], error);
        return Promise.reject(err);
      }),
  });
};

export const setRequestId = (requestId) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_REQUEST_ID,
    payload: requestId,
  });
};

export function toggleSearchDialog(instanceId) {
  return {
    type: actionTypes.TOGGLE_SEARCH_DIALOG,
    payload: instanceId,
  };
}

export const toggleModellerProfile = () => ({
  type: actionTypes.TOGGLE_MODELLER_PROFILE,
});

let cancelOperandCall;

export const getEmployees = (requestID, searchText) => () => {
  const payload = {
    search: {
      searchText,
    },
  };

  if (cancelOperandCall) cancelOperandCall.cancel('Modeller getEmployee call cancelled.');

  cancelOperandCall = CancelToken.source();

  return POST(modeller.employeeSearch, payload, {
    Pragma: 'no-cache',
    RequestId: requestID,
    cancelToken: cancelOperandCall.token,
  });
};

export const resetStore = () => ({
  type: actionTypes.RESET_STORE,
});

const mapModelToPayload = (mappings) => {
  let categories = R.keys(mappings).map((key) => {
    if (mappings[key].mappingName !== ANNUAL_SALARY) {
      const [, , rewardLevel3CategoryId] = key.split('/');
      const { isIncluded } = mappings[key];
      return { rewardLevel3CategoryId, isIncluded };
    }
    return null;
  });

  categories = categories.filter(
    (category) => category !== null && category.rewardLevel3CategoryId !== ANNUAL_SALARY_ID
  );
  return categories;
};

export const submitCategoriesMapping = () => (dispatch, getState) => {
  const { etag, mappings: categoriesMappings } = R.path(
    ['modellerBeta', 'configurationReducer'],
    getState()
  );

  const categories = mapModelToPayload(categoriesMappings);

  const categoriesMappingsLabel = TextOnly({ path: 'modeller.CONFIGURATION_PAGE_TITLE' });

  dispatch(submitActions.pending());

  return POST(modeller.configuration, { categories }, { Etag: etag })
    .then((response) => {
      dispatch(
        showBanner({
          type: 'success',
          content: TextOnly({
            path: 'generic.SUBMIT_SUCCESS',
            transform: (label) => label.replace('%NAME%', categoriesMappingsLabel),
          }),
        })
      );
      return dispatch(submitActions.success({ id: R.path(['data', 'response', 'id'], response) }));
    })
    .catch((err) => {
      dispatch(showBanner({ type: 'error' }));
      dispatch(submitActions.error());
      return Promise.reject(err);
    });
};

export const loadCategoriesMapping = () => (dispatch) => {
  dispatch(
    getModellerCategoriesMapping(
      GET(modeller.configuration).then((response) => ({
        categoriesMappings: R.path(['data', 'response', 'categories'], response),
        status: R.path(['data', 'response', 'status'], response),
        etag: R.path(['headers', 'etag'], response),
      }))
    )
  );
};

export const loadUserPermissions = (dispatch) => {
  dispatch(
    getModellerUserPermissions(
      GET(`${auth.users}/permissions/MODELLER`).then((response) => {
        const permissions = R.path(['data'], response);

        userManager.setModellerModules(permissions);

        return {
          permissions,
        };
      })
    )
  );
};

export const actions = {
  toggleSearchDialog,
  getEmployees,
  resetStore,
  toggleModellerProfile,
  getModellerCategoriesMapping,
  getModellerUserPermissions,
  loadEmployeeDimensionValuesByDefaultWithCorrelation,
};
