import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import APP_CONFIG from '@trs/config';
import { PageLoader, Card } from '@trs/components';
import { isBlank, processDate } from '@trs/utils';
import StyledStatementSides from './StatementSides.style';
import ColumnHeader from '../../columnHeader/ColumnHeader';
import Text, { TextOnly } from '../../../../../../common/Text';
import ColumnBody from '../columnBody/ColumnBody';
import { getCategoryTotal } from '../helpers';

const { DATE_FORMAT } = APP_CONFIG;

const StatementSides = ({
  isComputingStatement,
  data,
  currentStatementDate,
  newStatementDate,
  disableAnimation,
  showOnlyNewStatement,
  budgetLabel,
  isPdfOutput,
}) => {
  return (
    <StyledStatementSides showOnlyNewStatement={showOnlyNewStatement}>
      <section id="myStatementTable">
        <Card className="statement-row statement-header-row">
          <ColumnHeader
            className="statement-row-title"
            title={TextOnly({ path: 'flexibleRewards.SIDE_NAME_TITLE' })}
            hideOnMobile
          />
          {!showOnlyNewStatement && (
            <ColumnHeader
              className="statement-row-value"
              hasDoughnut
              date={processDate(currentStatementDate) || processDate(new Date(), DATE_FORMAT)}
              subtitle={TextOnly({ path: 'flexibleRewards.SIDE_CURRENT_TITLE' })}
              data={getCategoryTotal(data, 0)}
            />
          )}
          <ColumnHeader
            className="statement-row-value"
            data={getCategoryTotal(data, 1)}
            subtitle={TextOnly({ path: 'flexibleRewards.SIDE_FUTURE_TITLE' })}
            date={processDate(new Date(newStatementDate), DATE_FORMAT)}
            hasDoughnut
            isComputingStatement={isComputingStatement}
          />
        </Card>
        <div className="statement-body">
          {isComputingStatement ? (
            <PageLoader message={TextOnly({ path: 'flexibleRewards.PROCESSING_REWARDS' })} />
          ) : (
            <div>
              {isBlank(R.prop('groups', data)) ? (
                <div className="statement-empty">
                  <Text path="flexibleRewards.NO_CALCULATED_REWARDS" />
                </div>
              ) : (
                <>
                  <ColumnBody
                    data={data}
                    disableAnimation={disableAnimation}
                    showOnlyNewStatement={showOnlyNewStatement}
                    isExtra={false}
                    budgetLabel={budgetLabel}
                    isPdfOutput={isPdfOutput}
                  />
                  <ColumnBody
                    data={data}
                    disableAnimation={disableAnimation}
                    showOnlyNewStatement={showOnlyNewStatement}
                    isExtra
                    budgetLabel={budgetLabel}
                    isPdfOutput={isPdfOutput}
                  />
                </>
              )}
            </div>
          )}
        </div>
      </section>
    </StyledStatementSides>
  );
};

StatementSides.propTypes = {
  isComputingStatement: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    currencies: PropTypes.arrayOf(PropTypes.string),
    overallTotal: PropTypes.arrayOf(PropTypes.number),
    subtotal: PropTypes.arrayOf(PropTypes.number),
    groups: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({}))),
  }).isRequired,
  currentStatementDate: PropTypes.string,
  newStatementDate: PropTypes.string,
  disableAnimation: PropTypes.bool,
  showOnlyNewStatement: PropTypes.bool,
  budgetLabel: PropTypes.string,
  isPdfOutput: PropTypes.bool,
};

StatementSides.defaultProps = {
  currentStatementDate: '',
  newStatementDate: '',
  showOnlyNewStatement: false,
  disableAnimation: false,
  budgetLabel: '',
  isPdfOutput: false,
};

export default StatementSides;
