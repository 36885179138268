import styled, { css } from 'styled-components';

const StyledOverallImpact = styled.div`
  ${props => {
    const { palette: color, typography, spacing } = props.theme;

    return css`
      font-family: ${typography.font.primary};

      .impact-action {
        margin-bottom: ${spacing.small};
        text-align: center;
      }

      .impact-first-paragraph,
      .impact-second-paragraph {
        color: ${color.gray.alpha};
        font-size: ${typography.size.paragraph};
        line-height: ${typography.lineHeight.paragraph};
        text-align: center;
      }

      .impact-description {
        margin: ${spacing.large} 0;
      }

      .impact-first-paragraph {
        margin-top: ${spacing.xLarge};
        margin-bottom: ${spacing.small};
      }

      .impact-second-paragraph {
        margin-top: ${spacing.tiny};
        margin-bottom: ${spacing.xxxLarge};
      }

      .tile-spinner {
        margin-top: ${spacing.xLarge};
        margin-bottom: ${spacing.xxxLarge};
        position: relative;
      }

      .spinner-message {
        left: auto;
        transform: none;
        width: 100%;
        text-align: center;
        margin-top: ${spacing.large};
      }

      .doughnut-wrapper {
        position: relative;
        margin-bottom: ${spacing.small};
      }

      .doughnut-chart {
        display: flex;
        justify-content: center;
        margin: 0;
        max-width: none;
      }

      .percentage {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: ${typography.size.h3};
        word-break: break-all;
        width: 95px;
        font-weight: ${typography.weight.bold};
        text-align: center;
        &-label {
          font-size: ${typography.size.table};
          font-weight: ${typography.weight.normal};
          margin: 0;
        }
      }

      /* genius way to prevent hovering
       * please don't do it again!!!   
      */
      .doughnut-chart {
        pointer-events: none;
      }

      .impact-detail-view {
        text-align: center;
      }

      .overall-impact-details {
        margin-left: ${spacing.tiny};

        span.employees-affected,
        span.total-employees {
          font-weight: ${typography.weight.bold};
        }

        p {
          text-align: center;
          &:last-child {
            margin-bottom: ${spacing.base};
          }
        }
      }
    `;
  }};
`;

StyledOverallImpact.displayName = 'StyledOverallImpact';

export default StyledOverallImpact;
