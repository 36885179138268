import { handleActions } from 'redux-actions';
import * as R from 'ramda';
import {
  changeProvider,
  getSharesDataLoadStatus,
  selectSharesDataFile,
  saveShareFileData,
  validateShareFileData,
  resetStore,
} from '../actions';
import { getSharesProvidersAction } from '../actions/sharesProviders';
import initialState from './initialState';

export { default as sharesProviders } from './sharesProvider';

const sharesReducer = handleActions(
  new Map([
    [
      getSharesProvidersAction,
      (state, action) => {
        const { ready, payload, error } = action;

        if (!ready) {
          return {
            ...state,
            isLoading: true,
          };
        }

        if (error) {
          return {
            ...state,
            isLoading: false,
          };
        }

        const { providersOptions, selectedProvider } = payload;

        return {
          ...state,
          isLoading: false,
          providersOptions,
          selectedProvider,
        };
      },
    ],
    [
      changeProvider,
      (state, action) => {
        return {
          ...state,
          selectedProvider: action.payload,
          selectedFile: null,
          error: null,
        };
      },
    ],
    [
      getSharesDataLoadStatus,
      (state, action) => {
        const { ready, payload, error } = action;

        if (!ready) {
          return {
            ...state,
            isDataLoadLoading: true,
          };
        }

        if (error) {
          return {
            ...state,
            isDataLoadLoading: false,
          };
        }

        return {
          ...state,
          ...payload,
          isDataLoadLoading: false,
        };
      },
    ],
    [
      selectSharesDataFile,
      (state, action) => {
        const {
          payload: { selectedFile, error },
        } = action;
        return {
          ...state,
          selectedFile,
          error,
          fileName: R.prop('name', selectedFile),
        };
      },
    ],
    [
      saveShareFileData,
      (state, action) => {
        const { ready, payload, error } = action;

        if (!ready) {
          return {
            ...state,
            isUploadInProgress: true,
          };
        }

        if (error) {
          return {
            ...state,
            isUploadInProgress: false,
          };
        }

        return {
          ...state,
          ...payload,
          isUploadInProgress: false,
          selectedFile: null,
          error: null,
        };
      },
    ],
    [
      validateShareFileData,
      (state, action) => {
        const { ready, payload, error } = action;

        if (!ready) {
          return {
            ...state,
            isValidateInProgress: true,
          };
        }

        if (error) {
          return {
            ...state,
            isValidateInProgress: false,
          };
        }

        return {
          ...state,
          ...payload,
          isValidateInProgress: false,
          selectedFile: null,
          error: null,
        };
      },
    ],
    [resetStore, () => initialState],
  ]),
  initialState
);

export default sharesReducer;
