import React, { PureComponent } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { PageLoader } from '@trs/components';
import { upperFirstLetter, noop } from '@trs/utils';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { configureInterceptors } from 'config/http';
import Header from 'modules/header/components';
import Footer from 'modules/footer/components';
import Banners from 'modules/common/Banners';
import Dialog from 'modules/common/Dialog';
import InactivityTimeout from 'modules/common/InactivityTimeout';
import WrappedLayout from 'modules/common/layout/Layout';
import NotificationsSidebar from 'modules/notifications/components/sidebar/NotificationsSidebar';
import GoogleAnalytics from 'modules/common/GoogleAnalytics';
import withFeatureToggle from 'modules/common/hooks/withFeatureToggle';
import AppRoutes from 'routes';
import {
  fetchCmsData,
  fetchThemeOverride,
  themeSwitch,
  themeFetchEmpty,
  fetchThemeLogo,
} from 'modules/cms/actions';
import { logoutUser, authenticateUser } from 'actions/userActions';
import cmsKeys from 'modules/cms/keys';
import { overrideCustomAssets } from 'modules/cms/util';
import ConnectedACLControl from 'modules/common/ACLControl';
import { MODULES_WITH_NOTIFICATIONS } from 'modules/notifications/constants';
import FEATURE_TOGGLE_KEYS from './config/featureToggleKeys';

const GoogleAnalyticsWithToggle = withFeatureToggle({ withNotFound: false })(
  GoogleAnalytics,
  FEATURE_TOGGLE_KEYS.ANALYTICS
);

const environment = process && process.env && process.env.NODE_ENV;
const genericLabelsStateKey = cmsKeys.generic;
const footerLabelsStateKey = cmsKeys.footer;
const headerLabelsStateKey = cmsKeys.header;
const notificationsLabelsStateKey = cmsKeys.notifications;
const platformConfigLabelsStateKey = cmsKeys.platformConfig;

if (environment) {
  window.R = R;
}

class App extends PureComponent {
  componentDidMount() {
    const {
      actions: { logout },
      history,
    } = this.props;

    if (environment !== 'test') {
      configureInterceptors({ history, logout });
    }

    this.fetchContent();
  }

  fetchContent() {
    const {
      actions: { fetchData, authenticate, switchTheme, fetchTheme, fetchEmptyTheme, fetchLogo },
      isSetupCompleted,
    } = this.props;

    fetchLogo();
    overrideCustomAssets();
    fetchTheme()
      .then(() => switchTheme())
      .catch(() => fetchEmptyTheme());

    const headerParams = [{ name: 'resourceGroup', value: 'Header' }];
    fetchData(headerParams, headerLabelsStateKey, true)
      .then(() => authenticate(isSetupCompleted))
      .catch(noop);

    const neededResourceGroups = R.compose(
      R.values,
      R.pick(['generic', 'footer', 'platformConfig', 'notifications'])
    )(cmsKeys);

    neededResourceGroups.map((resourceGroup) => {
      const params = [{ name: 'resourceGroup', value: upperFirstLetter(resourceGroup) }];
      return fetchData(params, resourceGroup);
    });
  }

  render() {
    const {
      themeOverride: { loading: isLoadingTheme },
      genericLabel: { loading: loadingGenericLabels },
      footerLabel: { loading: loadingFooterLabels },
      headerLabel: { loading: loadingHeaderLabels },
      notificationsLabel: { loading: loadingNotificationsLabels },
      platformConfigLabel: { loading: loadingPlatformConfigLabels },
      user: { authenticationInProgress: authenticating },
      isFeatureTogglesLoading,
    } = this.props;

    const labelLoading =
      loadingGenericLabels ||
      loadingFooterLabels ||
      loadingHeaderLabels ||
      loadingPlatformConfigLabels ||
      loadingNotificationsLabels;

    const loading = labelLoading || authenticating || isFeatureTogglesLoading || isLoadingTheme;
    if (loading) return <PageLoader />;

    return (
      <div className="App">
        <div className="content">
          <InactivityTimeout />
          <GoogleAnalyticsWithToggle />
          <Header />
          <ConnectedACLControl acl={MODULES_WITH_NOTIFICATIONS}>
            <NotificationsSidebar />
          </ConnectedACLControl>
          <Banners />
          <Dialog />
          <WrappedLayout className="layout-wrapper">
            <AppRoutes />
          </WrappedLayout>
        </div>
        <Footer />
      </div>
    );
  }
}

App.defaultProps = {
  isSetupCompleted: false,
};

App.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  actions: PropTypes.shape({
    fetchData: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
    authenticate: PropTypes.func.isRequired,
    switchTheme: PropTypes.func.isRequired,
    fetchTheme: PropTypes.func.isRequired,
    fetchEmptyTheme: PropTypes.func.isRequired,
    fetchLogo: PropTypes.func.isRequired,
  }).isRequired,
  genericLabel: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
  }).isRequired,
  footerLabel: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
  }).isRequired,
  headerLabel: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
  }).isRequired,
  notificationsLabel: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
  }).isRequired,
  platformConfigLabel: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
  }).isRequired,
  themeOverride: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
  }).isRequired,
  user: PropTypes.shape({
    isLoggedIn: PropTypes.bool.isRequired,
    authenticationInProgress: PropTypes.bool.isRequired,
  }).isRequired,
  isSetupCompleted: PropTypes.bool,
  isFeatureTogglesLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  genericLabel: state.cms[genericLabelsStateKey],
  footerLabel: state.cms[footerLabelsStateKey],
  headerLabel: state.cms[headerLabelsStateKey],
  notificationsLabel: state.cms[notificationsLabelsStateKey],
  platformConfigLabel: state.cms[platformConfigLabelsStateKey],
  themeOverride: state.cms.themeOverride,
  user: state.user,
  isSetupCompleted: state.onboarding.dashboard.isSetupCompleted,
  featureToggles: state.configuration.featureToggles,
  isFeatureTogglesLoading: state.configuration.isFeatureTogglesLoading,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      fetchData: fetchCmsData,
      logout: logoutUser,
      authenticate: authenticateUser,
      fetchTheme: fetchThemeOverride,
      fetchEmptyTheme: themeFetchEmpty,
      switchTheme: themeSwitch,
      fetchLogo: fetchThemeLogo,
    },
    dispatch
  ),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
