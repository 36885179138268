const initialState = {
  hasChangedUsers: false,
  allRoles: [],
  redirect: false,
  errors: [],
  redirectToRoot: false,
  bannerMessage: '',
  savedRole: {
    name: '',
    countries: [],
    populationId: null,
    availableModules: [],
    isEditable: false,
    isLoading: false,
  },
  userForEdit: {
    name: '',
    email: '',
    curentRoles: '',
    isNonEmployee: false,
  },
  roleSnapShot: {
    name: '',
    countries: [],
    availableModules: [],
  },
  savedRoleId: '',
  selectedRoles: [],
  selectedUsers: [],
  selectedUsersSnapshot: [],
  loadingModulePermissions: false,
  modulePermissions: {},
};

export default initialState;
