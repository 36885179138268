"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toISOString = exports.setTimeInDate = exports.processDateUTC = exports.processDate = exports.offsetTimezone = exports.isMaxDate = exports.getYearFromDate = exports.getUTCOffset = exports.getLastDayOfTheYear = exports.getEndOfDay = exports.formatUTCByGivenDate = exports.formatTimeDigit = exports.extractTimeFromDate = exports.dateDaysDiff = exports.addDays = exports.MAX_DATE = void 0;
var R = _interopRequireWildcard(require("ramda"));
var _dateFns = require("date-fns");
var _config = _interopRequireDefault(require("@trs/config"));
var _objects = require("../objects");
var _helpers = _interopRequireDefault(require("./helpers"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var DATE_FORMAT = _config["default"].DATE_FORMAT;
var MERIDIEM_TYPE = {
  AM: 'AM',
  PM: 'PM'
};
var MAX_DATE = '9999-12-31T23:59:59.9999999';
exports.MAX_DATE = MAX_DATE;
var formatTimeDigit = function formatTimeDigit(hour) {
  return hour < 10 ? "0".concat(hour) : hour;
};
exports.formatTimeDigit = formatTimeDigit;
var formatUTC = function formatUTC(date, dateFormat, withOffset) {
  var currentOffset = date.getTimezoneOffset() > 0 ? date.getTimezoneOffset() : 0;
  var offset = withOffset ? date.getTimezoneOffset() : currentOffset;
  var dateOnly = new Date(date.valueOf() + offset * 60 * 1000);
  return (0, _dateFns.format)(dateOnly, dateFormat);
};
var formatUTCByGivenDate = function formatUTCByGivenDate(date, dateFormat) {
  var originalDate = new Date(date);
  var modifiedDate = new Date(originalDate.valueOf() + originalDate.getTimezoneOffset() * 60 * 1000);
  return (0, _dateFns.format)(modifiedDate, dateFormat);
};
exports.formatUTCByGivenDate = formatUTCByGivenDate;
var processDate = function processDate(date) {
  var dateFormat = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : DATE_FORMAT;
  var offset = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  var dateObject = new Date(date);
  if ((0, _objects.isBlank)(date) || !dateObject.valueOf()) return '';
  return formatUTC(dateObject, dateFormat, dateFormat !== DATE_FORMAT && offset);
};
exports.processDate = processDate;
var processDateUTC = function processDateUTC(date) {
  var withSeconds = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  if ((0, _objects.isBlank)(date)) return '';
  var dateObject = new Date(date);
  var processedDate = processDate(date);
  var hours = formatTimeDigit(dateObject.getUTCHours());
  var minutes = formatTimeDigit(dateObject.getUTCMinutes());
  var seconds = formatTimeDigit(dateObject.getUTCSeconds());
  var secondsLabel = ":".concat(seconds);
  return "".concat(processedDate, " ").concat(hours, ":").concat(minutes).concat(withSeconds ? secondsLabel : '');
};
exports.processDateUTC = processDateUTC;
var offsetTimezone = function offsetTimezone(date) {
  var temp = date ? new Date(date) : new Date(new Date().setHours(0, 0, 0, 0));
  temp.setTime(temp.getTime() - temp.getTimezoneOffset() * 60000);
  return temp.toISOString();
};
exports.offsetTimezone = offsetTimezone;
var getLastDayOfTheYear = function getLastDayOfTheYear() {
  return new Date(new Date().getFullYear(), 11, 31);
};
exports.getLastDayOfTheYear = getLastDayOfTheYear;
var addDays = function addDays(date, days) {
  if (!date || !days && days !== 0) return null;
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};
exports.addDays = addDays;
var getYearFromDate = function getYearFromDate(date) {
  return date ? new Date(date).getFullYear() : new Date().getFullYear();
};
exports.getYearFromDate = getYearFromDate;
var isMaxDate = function isMaxDate() {
  var date = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return date.toString().includes('9999');
};

/*
  getTimezoneOffset returns the opposite sign that is used for UTC, thus we set the - in front of date
  e.g. for UTC+3 Romania time, getTimezoneOffset returns - and we need +
*/
exports.isMaxDate = isMaxDate;
var getUTCOffset = function getUTCOffset(date) {
  return -date.getTimezoneOffset() / 60;
};
exports.getUTCOffset = getUTCOffset;
var toISOString = function toISOString(date) {
  return new Date(date).toISOString();
};
exports.toISOString = toISOString;
var dateDaysDiff = function dateDaysDiff(dateLeft, dateRight) {
  return Math.round(Math.ceil((0, _dateFns.differenceInMilliseconds)(new Date(dateLeft), new Date(dateRight)) / (24 * 60 * 60 * 1000)));
};
exports.dateDaysDiff = dateDaysDiff;
var setTimeInDate = function setTimeInDate(date, time) {
  if (!date) return '';
  var timeObject = (0, _helpers["default"])(time);
  var hours = timeObject.hours;
  var minutes = timeObject.minutes,
    meridiem = timeObject.meridiem;
  if (R.has(meridiem, MERIDIEM_TYPE)) {
    if (meridiem === MERIDIEM_TYPE.PM && hours !== '12') {
      hours = (parseInt(hours, 10) + 12).toString();
    } else if (meridiem === MERIDIEM_TYPE.AM && hours === '12') {
      hours -= 12;
    }
  }
  date.setHours(hours || 0);
  date.setMinutes(minutes || 0);
  return date;
};
exports.setTimeInDate = setTimeInDate;
var extractTimeFromDate = function extractTimeFromDate(date) {
  if ((0, _objects.isBlank)(date)) return null;
  var hh = formatTimeDigit(date.getHours());
  var mm = formatTimeDigit(date.getMinutes());
  return {
    hours: hh,
    minutes: mm,
    label: "".concat(hh, ":").concat(mm)
  };
};
exports.extractTimeFromDate = extractTimeFromDate;
var getEndOfDay = function getEndOfDay(date) {
  var temp = new Date(new Date(date).setHours(23, 59, 59, 999));
  return temp.toISOString();
};
exports.getEndOfDay = getEndOfDay;