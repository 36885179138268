/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import * as R from 'ramda';
import { Chip } from '@trs/components';
import Text, { TextOnly } from '../../../../common/Text';
import BM_STATUS from '../helpers';

export const CHIP_STATUSES = {
  [BM_STATUS.NOT_SUBMITTED]: 'default',
  [BM_STATUS.SUBMITTED]: 'primary',
  [BM_STATUS.APPROVED]: 'success',
  [BM_STATUS.REJECTED]: 'error',
};

const ROUTES_FOR_ID = {
  localPreparation: '/plan/local-preparation',
  salaryIncrease: '/plan/salary-increase',
  payScaleIncrease: '/plan/payscale-increase',
};

const StyledChip = styled.div`
  a {
    text-decoration: none;
  }
`;

StyledChip.displayName = 'StyledChip';

export const getStatusChip = (status, _1, id, _2, data) => {
  const route = R.prop(id, ROUTES_FOR_ID) || ROUTES_FOR_ID.localPreparation;
  const localSettingsId = R.path(['approvalFlow', 'localSettingsId'], data);
  const isActive = R.prop('isActive', data);
  const bmStatus = BM_STATUS[status] || BM_STATUS.NOT_SUBMITTED;
  const chipStatus = CHIP_STATUSES[bmStatus];
  const isNotSubmitted = bmStatus === BM_STATUS.NOT_SUBMITTED;

  return (
    <StyledChip>
      {isActive ? (
        <Link to={`${route}/${localSettingsId}`}>
          <Chip className="status-chip" type={chipStatus} outlined={isNotSubmitted}>
            <Text path={`benchmarking.STATUS_${bmStatus}`} />
          </Chip>
        </Link>
      ) : (
        <Chip className="status-chip" type={chipStatus} outlined={isNotSubmitted}>
          <Text path={`benchmarking.STATUS_${bmStatus}`} />
        </Chip>
      )}
    </StyledChip>
  );
};

export const getFilterOptions = () => [
  { value: BM_STATUS.ALL, label: TextOnly({ path: 'benchmarking.BM_STATUS_ALL' }) },
  {
    value: BM_STATUS.NOT_SUBMITTED,
    label: TextOnly({ path: 'benchmarking.BM_STATUS_NOT_SUBMITTED' }),
  },
  { value: BM_STATUS.SUBMITTED, label: TextOnly({ path: 'benchmarking.BM_STATUS_SUBMITTED' }) },
  { value: BM_STATUS.APPROVED, label: TextOnly({ path: 'benchmarking.BM_STATUS_APPROVED' }) },
  { value: BM_STATUS.REJECTED, label: TextOnly({ path: 'benchmarking.BM_STATUS_REJECTED' }) },
];

export const filterData = R.memoizeWith(R.identity, (activeView, dashboardCountries) =>
  R.filter(
    item =>
      item.localPreparation === activeView ||
      item.salaryIncrease === activeView ||
      item.payScaleIncrease === activeView,
    dashboardCountries
  )
);

export default {
  getFilterOptions,
  filterData,
  getStatusChip,
};
