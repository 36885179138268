"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var StyledInput = _styledComponents["default"].input.withConfig({
  displayName: "Inputstyle__StyledInput",
  componentId: "sc-cbkkij-0"
})(["", ";"], function (props) {
  var _props$theme = props.theme,
    color = _props$theme.palette,
    typography = _props$theme.typography,
    spacing = _props$theme.spacing;
  var fullWidth = props.fullWidth,
    disableUnderline = props.disableUnderline,
    hasErrors = props.hasErrors,
    icon = props.icon,
    clearable = props.clearable;
  return (0, _styledComponents.css)(["font-family:", ";font-size:", ";font-weight:400;line-height:", ";color:", ";border:0;box-sizing:border-box;outline:0;padding:0 ", " ", ";", " ", " &::placeholder{color:", ";font-size:16px;font-weight:400;opacity:1;}&::-ms-clear{display:none;}&:disabled{border-color:", ";background:none;color:", ";-webkit-text-fill-color:", ";cursor:not-allowed;&::placeholder{color:", ";}}& + i,& + i + i{padding:5px;position:absolute;color:", ";cursor:pointer;&:hover{color:", ";}&.input-icon{font-size:24px;}&.input-clear-icon{font-size:16px;}}", ";", ";"], typography.font.primary, typography.size.paragraph, typography.lineHeight["default"], color.gray.alpha, spacing.tiny, spacing.small, fullWidth && (0, _styledComponents.css)(["width:100%;"]), !disableUnderline && (0, _styledComponents.css)(["border-bottom:1px solid ", ";transition:border 0.2s ease-out;&:focus{border-bottom-color:", ";& + i,& + i + i{color:", ";}}"], hasErrors ? color.validation.error : color.gray.bravo, color.accent.alpha, color.secondary), color.gray.bravo, hasErrors ? color.validation.error : color.gray.charlie, color.gray.charlie, color.gray.charlie, color.gray.charlie, color.gray.bravo, color.secondary, (icon || clearable) && (0, _styledComponents.css)(["padding-right:35px;& + i{margin-left:-30px;}"]), icon && clearable && (0, _styledComponents.css)(["padding-right:60px;& + i.input-icon{margin-left:-30px;top:-5px;}& + i.input-icon + i.input-clear-icon{margin-left:-58px;}"]));
});
StyledInput.displayName = 'StyledInput';
var _default = StyledInput;
exports["default"] = _default;