"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var StyledSpinner = _styledComponents["default"].div.withConfig({
  displayName: "PageSpinnerstyle__StyledSpinner",
  componentId: "sc-11wv5e0-0"
})(["", ";"], function (props) {
  var _props$theme = props.theme,
    palette = _props$theme.palette,
    typography = _props$theme.typography,
    spacing = _props$theme.spacing;
  return (0, _styledComponents.css)(["min-height:150px;.spinner{position:absolute;top:50%;left:50%;width:", ";height:", ";border-radius:50%;border:6px solid ", ";border-top-color:", ";animation:spinner 1s linear infinite;@-webkit-keyframes spinner{0%{transform:translate(-50%,-50%) rotate(0deg);}100%{transform:translate(-50%,-50%) rotate(360deg);}}@keyframes spinner{0%{transform:translate(-50%,-50%) rotate(0deg);}100%{transform:translate(-50%,-50%) rotate(360deg);}}}.spinner-message{position:absolute;top:50%;left:50%;margin:0;margin-top:90px;color:", ";font-size:", ";line-height:19px;transform:translate(-50%,-50%);}"], spacing.xLarge, spacing.xLarge, palette.gray.charlie, palette.gray.alpha, palette.primary, typography.size.paragraph);
});
StyledSpinner.displayName = 'StyledSpinner';
var _default = StyledSpinner;
exports["default"] = _default;