export const FROM_DATE = 'fromDate';
export const FROM_TIME = 'fromTime';
export const TO_DATE = 'toDate';
export const TO_TIME = 'toTime';
export const ROLE = 'role';
export const PAGE = 'page';
export const TOP = 'top';
export const DEFAULT_FROM_DAYS = 6;
export const MAX_INTERVAL_DAYS = 89;
export const FROM_PAST_MONTHS = 24;

export const ROLES_MAP = {
  all: 'All',
  userGenerated: 'User Generated',
  systemGenerated: 'System Generated',
};

export const ROLES_CORRELATION_MAP = {
  [ROLES_MAP.all]: {
    includeSystemActions: true,
    includeUserActions: true,
  },
  [ROLES_MAP.userGenerated]: {
    includeSystemActions: false,
    includeUserActions: true,
  },
  [ROLES_MAP.systemGenerated]: {
    includeSystemActions: true,
    includeUserActions: false,
  },
};

export const ROLES_SELECT_DATA = [
  { label: ROLES_MAP.all, value: ROLES_MAP.all },
  { label: ROLES_MAP.userGenerated, value: ROLES_MAP.userGenerated },
  { label: ROLES_MAP.systemGenerated, value: ROLES_MAP.systemGenerated },
];

export default {
  FROM_DATE,
  FROM_TIME,
  TO_DATE,
  TO_TIME,
  ROLE,
  MAX_INTERVAL_DAYS,
  ROLES_MAP,
  ROLES_CORRELATION_MAP,
  ROLES_SELECT_DATA,
};
