import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import App from './App';

const history = createBrowserHistory();

const AppProvider = ({ theme }) => (
  <ThemeProvider theme={theme}>
    <Router history={history}>
      <App />
    </Router>
  </ThemeProvider>
);

AppProvider.propTypes = {
  theme: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => ({
  theme: state.cms.theme.data,
});

export default connect(mapStateToProps)(AppProvider);
