import { createAction } from 'redux-actions';
import * as R from 'ramda';
import { benchmark } from '../../../../../../../../../config/api';
import { GET, POST, PATCH } from '../../../../../../../../../config/http';
import { showBanner } from '../../../../../../../../../actions/globalActions';
import { TextOnly } from '../../../../../../../../common/Text';

export const actionTypes = {
  BENCHMARKING_GET_CATEGORIES_MAPPING: 'BENCHMARKING/GET_CATEGORIES_MAPPING',
  BENCHMARKING_CHANGE_CATEGORIES_MAPPING: 'BENCHMARKING/CHANGE_CATEGORIES_MAPPING',
  BENCHMARKING_RESET_CATEGORIES_MAPPING: 'BENCHMARKING/RESET_CATEGORIES_MAPPING',
};

export const submitActions = {
  pending: createAction(`BENCHMARKING/SUBMIT_CATEGORIES_MAPPING_PENDING`),
  success: createAction(`BENCHMARKING/SUBMIT_CATEGORIES_MAPPING_SUCCESS`),
  error: createAction(`BENCHMARKING/SUBMIT_CATEGORIES_MAPPING_ERROR`),
};

export const getCategoriesMapping = createAction(actionTypes.BENCHMARKING_GET_CATEGORIES_MAPPING);
export const resetCategoriesMapping = createAction(
  actionTypes.BENCHMARKING_RESET_CATEGORIES_MAPPING
);
export const changeCategoriesMapping = createAction(
  actionTypes.BENCHMARKING_CHANGE_CATEGORIES_MAPPING
);

const mapModelToPayload = (mappings) =>
  R.keys(mappings).map((key) => {
    const [, , rewardLevel3CategoryId] = key.split('/');
    const { mappingId, mappingName } = mappings[key];
    return { mappingId, mappingName, rewardLevel3CategoryId };
  });

export const submitCategoriesMapping =
  (globalSettingsId = null) =>
  (dispatch, getState) => {
    const { etag, mappings: categoriesMappings } = R.path(
      ['benchmarking', 'global', 'categoriesMappings'],
      getState()
    );
    const mappings = mapModelToPayload(categoriesMappings);
    const categoriesMappingsLabel = TextOnly({ path: 'benchmarking.CATEGORIES_MAPPING' });

    dispatch(submitActions.pending());

    if (globalSettingsId) {
      return PATCH(
        `${benchmark.base}/globalSettings/${globalSettingsId}/categoriesMappings`,
        { mappings },
        { Etag: etag }
      )
        .then((response) => {
          dispatch(
            showBanner({
              type: 'success',
              content: TextOnly({
                path: 'generic.SUBMIT_SUCCESS',
                transform: (label) => label.replace('%NAME%', categoriesMappingsLabel),
              }),
            })
          );
          return dispatch(
            submitActions.success({ id: R.path(['data', 'response', 'id'], response) })
          );
        })
        .catch((err) => {
          dispatch(showBanner({ type: 'error' }));
          dispatch(submitActions.error());
          return Promise.reject(err);
        });
    }

    return POST(benchmark.categoriesMappings, { mappings }, { Etag: etag })
      .then((response) => {
        dispatch(
          showBanner({
            type: 'success',
            content: TextOnly({
              path: 'generic.SUBMIT_SUCCESS',
              transform: (label) => label.replace('%NAME%', categoriesMappingsLabel),
            }),
          })
        );
        return dispatch(
          submitActions.success({ id: R.path(['data', 'response', 'id'], response) })
        );
      })
      .catch((err) => {
        dispatch(showBanner({ type: 'error' }));
        dispatch(submitActions.error());
        return Promise.reject(err);
      });
  };

export const loadCategoriesMapping = () => (dispatch) =>
  dispatch(
    getCategoriesMapping(
      GET(benchmark.categoriesMappings, {}, { showErrorModal: true, dispatch }).then((response) => {
        return {
          categoriesMappings: R.path(['data', 'response', 'categoryMappingRows'], response),
          globalSettingsId: R.path(['data', 'response', 'globalSettingsId'], response),
          status: R.path(['data', 'response', 'status'], response),
          etag: R.path(['headers', 'etag'], response),
        };
      })
    )
  );
