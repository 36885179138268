import { createSuffixedActions } from 'modules/config';

const actionSuffix = '_BETA';
const actionTypesName = [
  'ADD_COMPARE',
  'REMOVE_COMPARE',
  'SET_INITIAL_FIELDS',
  'RE_INITIALIZE_FIELDS',
  'LOAD_DATA_SUCCESS',
  'LOAD_DIMENSIONS_BY_DEFAULT_SUCCESS',
  'HANDLE_CHANGE',
  'CORRELATE_FIELDS',
  'RESET_MODELLER_EMPLOYEE_CORRELATED_DIMENSIONS',
  'RESET_STORE',
];

const actionTypes = createSuffixedActions(actionTypesName, actionSuffix);

export default actionTypes;
