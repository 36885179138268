export const types = {
  FIXED_COSTS_GET_SEARCH: ' FIXED_COSTS_GET_SEARCH',
  FIXED_COSTS_GET_COUNTRIES: 'FIXED_COSTS_GET_COUNTRIES',
  FIXED_COSTS_SET_FILTERED_COUNTRIES: 'FIXED_COSTS_SET_FILTERED_COUNTRIES',
  FIXED_COSTS_GET_CURRENCIES: 'FIXED_COSTS_GET_CURRENCIES',
  FIXED_COSTS_GET_DEFAULT_CURRENCY: 'FIXED_COSTS_GET_DEFAULT_CURRENCY',
  FIXED_COSTS_GET_FX_SOURCES: 'FIXED_COSTS_GET_FX_SOURCES',
  FIXED_COSTS_REQUEST_ALL: 'FIXED_COSTS_REQUEST_ALL',
  FIXED_COSTS_GET_CATEGORIES: 'FIXED_COSTS_GET_CATEGORIES',
  FIXED_COSTS_BASIC_INFORMATION_CHANGE: 'FIXED_COSTS_BASIC_INFORMATION_CHANGE',
  FIXED_COSTS_BASIC_INFORMATION_RESET: 'FIXED_COSTS_BASIC_INFORMATION_RESET',
  FIXED_COSTS_BASIC_INFORMATION_SAVE: 'FIXED_COSTS_BASIC_INFORMATION_SAVE',
  FIXED_COSTS_ETAG_UPDATE: 'FIXED_COSTS_ETAG_UPDATE',
  FIXED_COSTS_FETCHING_DATA: 'FIXED_COSTS_FETCHING_DATA',
  FIXED_COSTS_FETCHING_BY_ID: 'FIXED_COSTS_FETCHING_BY_ID',
  FIXED_COSTS_GET_BY_ID: 'FIXED_COSTS_GET_BY_ID',
  FIXED_COSTS_RULES_BUILDER_REVERT: 'FIXED_COSTS_RULES_BUILDER_REVERT',
  FIXED_COSTS_RULES_BUILDER_RESET: 'FIXED_COSTS_RULES_BUILDER_RESET',
  FIXED_COSTS_RULES_BUILDER_RESET_DISTRIBUTION_CONFIG:
    'FIXED_COSTS_RULES_BUILDER_RESET_DISTRIBUTION_CONFIG',
  FIXED_COSTS_GET_FORMULA_OPERATORS: 'FIXED_COSTS_GET_FORMULA_OPERATORS',
  FIXED_COSTS_RULE_CHANGE: 'FIXED_COSTS_RULE_CHANGE',
  FIXED_COSTS_CHANGE_DISTRIBUTION_TYPE: 'FIXED_COSTS_CHANGE_DISTRIBUTION_TYPE',
  FIXED_COSTS_SET_DISTRIBUTION_TABLE_CONFIG: 'FIXED_COSTS_SET_DISTRIBUTION_TABLE_CONFIG',
  FIXED_COSTS_GET_SAVED_LOGIC_BUILDER: 'FIXED_COSTS_GET_SAVED_LOGIC_BUILDER',
  FIXED_COSTS_TABLE_CELL_CHANGE: 'FIXED_COSTS_TABLE_CELL_CHANGE',
  FIXED_COSTS_REDIRECT_TO_ROOT: 'FIXED_COSTS_REDIRECT_TO_ROOT',
  FIXED_COSTS_RESET_STORE: 'FIXED_COSTS_RESET_STORE',
};
export default types;
