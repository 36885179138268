import * as R from 'ramda';
import { GET, addGetParameters, fetchStaticAsset } from 'config/http';
import { cms } from 'config/api';
import labels from '../labels';
import { mapCmsLabels, getStaticAssetPath, LOGO_ASSET_NAME } from '../util';
import actions from './types';
import { withCache } from '../../common/timedCache';

const cachedGet = withCache(GET, 3600);

export const themeFetchEmpty = () => ({
  type: actions.THEME_EMPTY_GET,
});

export const themeSwitch = () => ({
  type: actions.THEME_SWITCH,
});

export const fetchThemeOverride = () => (dispatch) => {
  return cachedGet(cms.theme).then((body) => {
    dispatch({
      type: actions.THEME_GET,
      data: JSON.parse(R.path(['data', 'response', 'configuration'], body) || null),
    });
  });
};

export const fetchThemeLogo = () => (dispatch) => {
  const logoPath = getStaticAssetPath(LOGO_ASSET_NAME);
  return fetchStaticAsset(logoPath)
    .then(() => {
      dispatch({
        type: actions.LOGO_GET,
        payload: { logo: logoPath },
      });
    })
    .catch((e) => {
      dispatch({
        type: actions.LOGO_GET_ERROR,
      });
      return Promise.reject(e);
    });
};

export const getPdfLogo = () => (dispatch) => {
  fetchStaticAsset(`${cms.assets}/logo.png`, 'blob')
    .then(
      (response) =>
        new Promise((resolve, reject) => {
          /** Convert the image to a base64 representation  */
          const blob = new Blob([response.data], {
            type: 'image/png',
          });
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        })
    )
    .then((pdfLogo) =>
      dispatch({
        type: actions.PDF_LOGO_GET,
        payload: { pdfLogo },
      })
    );
};

const cmsFetchSuccess = (section, result) => ({
  type: actions.FETCH_OK,
  key: section,
  data: mapCmsLabels({
    serverLabels: R.path(['data', 'resources'], result),
    localLabels: labels[section],
    section,
  }),
});

const cmsFetchErr = (key, err) => ({
  type: actions.FETCH_FAIL,
  key,
  data: err,
});

export const fetchCmsData = (params, key) => (dispatch, getState) => {
  const state = getState();
  const populated = R.keys(R.path(['cms', key, 'data'], state)).length > 0;
  if (populated) {
    return dispatch({
      type: actions.FETCH_HAS_DATA,
      key,
    });
  }

  dispatch({
    type: actions.FETCH_START,
    key,
  });
  return cachedGet(addGetParameters(cms.labels, params))
    .then((result) => {
      dispatch(cmsFetchSuccess(key, result));
      return result;
    })
    .catch((err) => {
      dispatch(cmsFetchErr(key, err));
      dispatch(cmsFetchSuccess(key, []));
      return Promise.reject(err);
    });
};

export const switchTheme = (key) => ({
  type: actions.THEME_SWITCH,
  key,
});

export default fetchCmsData;
