import React from 'react';
import { pure } from 'recompose';

import { H1, GenericErrorPage } from '@trs/components';
import Layout from '../../../modules/common/layout/Layout';
import Text from '../../../modules/common/Text';

const AccessDeniedPage = () => (
  <Layout>
    <GenericErrorPage>
      <H1>
        <Text path="generic.ACCESS_DENIED_TITLE" />
      </H1>
      <p className="error-page-description">
        <Text path="generic.NO_EMPLOYEE_ID" />
      </p>
      <p className="error-page-description">
        <Text path="generic.PLEASE_CONTACT_HR" />
      </p>
    </GenericErrorPage>
  </Layout>
);

export default pure(AccessDeniedPage);
