"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
var _utils = require("@trs/utils");
var _LegendItem = _interopRequireDefault(require("./LegendItem.style"));
var _templateObject, _templateObject2, _templateObject3;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
var StyledLegend = _styledComponents["default"].div.withConfig({
  displayName: "Legendstyle__StyledLegend",
  componentId: "sc-1s1quna-0"
})(["", ";"], function (_ref) {
  var _ref$theme = _ref.theme,
    spacing = _ref$theme.spacing,
    palette = _ref$theme.palette,
    hasPagination = _ref.hasPagination,
    accentColor = _ref.accentColor;
  return (0, _styledComponents.css)(["padding-bottom:", ";width:100%;", " &.doughnut-legend{justify-content:flex-start;}&.dashboard-legend{", "{margin-left:0;margin-bottom:", ";max-width:100%;.legend-label{color:", ";text-decoration:underline;}.legend-value{padding-left:0;}", " ", ";}}", "{margin-bottom:", ";}"], hasPagination ? spacing.xLarge : 0, _utils.media.desktop(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n      display: flex;\n      flex-flow: column;\n      flex-wrap: wrap;\n      height: ", ";\n      padding-bottom: 0;\n      &.doughnut-legend {\n        height: ", ";\n        max-height: 350px;\n        overflow-y: auto;\n      }\n    "])), hasPagination ? '305px' : 'auto', hasPagination ? '305px' : 'auto'), _LegendItem["default"], spacing.basePlus, palette.secondary, _utils.media.tablet(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n        padding: ", " ", ";\n      "])), spacing.tiny, spacing.base), _utils.media.desktop(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n        margin-bottom: ", ";\n        border-left: 4px solid transparent;\n        transition: all 0.2s ease;\n\n        &:hover {\n          background-color: ", ";\n          border-left: 4px solid ", ";\n        }\n      "])), spacing.tiny, palette.gray.echo, accentColor), _LegendItem["default"], spacing.base);
});
StyledLegend.displayName = 'StyledLegend';
var _default = StyledLegend;
exports["default"] = _default;