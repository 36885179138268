"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.checkboxTypes = exports.TRACK_COLOR = exports.THUMB_COLOR = exports.ICON_UNCHECKED = exports.ICON_CHECKED = void 0;
var checkboxTypes = {
  normal: 'normal',
  "switch": 'switch'
};
exports.checkboxTypes = checkboxTypes;
var TRACK_COLOR = '#13ba97';
exports.TRACK_COLOR = TRACK_COLOR;
var THUMB_COLOR = '#FFF';
exports.THUMB_COLOR = THUMB_COLOR;
var ICON_CHECKED = 'ion-android-checkbox';
exports.ICON_CHECKED = ICON_CHECKED;
var ICON_UNCHECKED = 'ion-android-checkbox-outline-blank';
exports.ICON_UNCHECKED = ICON_UNCHECKED;
var _default = {
  TRACK_COLOR: TRACK_COLOR,
  THUMB_COLOR: THUMB_COLOR,
  checkboxTypes: checkboxTypes,
  ICON_CHECKED: ICON_CHECKED,
  ICON_UNCHECKED: ICON_UNCHECKED
};
exports["default"] = _default;