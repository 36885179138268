import React from 'react';
import { pure } from 'recompose';

import { H1, GenericErrorPage } from '@trs/components';
import Layout from '../../../modules/common/layout/Layout';
import Text from '../../../modules/common/Text';

const UnexpectedError = () => (
  <Layout>
    <GenericErrorPage>
      <H1>
        <Text path="generic.GENERIC_PAGE_ERROR" />
      </H1>
      <h4 className="error-page-description">
        <Text path="generic.NOT_FOUND_DESC" />
      </h4>
    </GenericErrorPage>
  </Layout>
);

export default pure(UnexpectedError);
