import React from 'react';
import ReadMoreButton from '../../../../../../common/ReadMoreButton';
import {
  showMoreButton,
  getPlainText,
} from '../../availableOptions/categoryTiles/optionTile/helpers';

const getTextOnlyComponent = (textValue, trimNumber) => {
  const hasMoreInfo = textValue && showMoreButton(textValue);
  return (
    <span className="reward-text-only-description">
      <div
        className="text-only-description"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: getPlainText(textValue, trimNumber),
        }}
      />
      {hasMoreInfo && <ReadMoreButton className="option-tile-tooltip" content={textValue} />}
    </span>
  );
};

export default getTextOnlyComponent;
