import * as R from 'ramda';
import { createAction } from 'redux-actions';
import { isBlank } from '@trs/utils';
import { GET } from '../../../../../../../config/http';
import { benchmark } from '../../../../../../../config/api';
import { loadGlobalIdentifiers } from '../../../../global/actions';
import * as commonPopulationsActions from '../../../../common/benchmarkPopulation/actions';
import * as localPopulationActions from '../components/population/actions';
import { dispatchError } from '../../../../common/helpers';
import { getExcludedRewards } from '../components/rewards/actions';

const actionTypes = {
  BENCHMARKING_LOAD_LOCAL_PREPARATIONS: 'BENCHMARKING/LOAD/LOCAL_PREPARATIONS',
  BENCHMARKING_LOAD_LOCAL_PREPARATIONS_ERROR: 'BENCHMARKING/LOAD/LOCAL_PREPARATIONS_ERROR',
  BENCHMARKING_LOCAL_PREPARATIONS_CANCEL_EDITABLE:
    'BENCHMARKING_LOCAL_PREPARATIONS_CANCEL_EDITABLE',
  BENCHMARKING_LOCAL_SETTINGS_SUBMIT: 'BENCHMARKING_LOCAL_SETTINGS_SUBMIT',
  BENCHMARKING_LOCAL_PREP_LOAD_STATUS: 'BENCHMARKING_LOCAL_PREP_LOAD_STATUS',
  BENCHMARKING_RESET_LOCAL_PREPARATION: 'BENCHMARKING/RESET/LOCAL-PREPARATION',
  BENCHMARKING_LOCAL_DATA_VALID: 'BENCHMARKING_LOCAL_DATA_VALID',
};

export const loadLocalPreparations = createAction(actionTypes.BENCHMARKING_LOAD_LOCAL_PREPARATIONS);
export const loadLocalPreparationStatus = createAction(
  actionTypes.BENCHMARKING_LOCAL_PREP_LOAD_STATUS
);
export const loadLocalPreparationsError = createAction(
  actionTypes.BENCHMARKING_LOAD_LOCAL_PREPARATIONS_ERROR
);
export const cancelEditable = createAction(
  actionTypes.BENCHMARKING_LOCAL_PREPARATIONS_CANCEL_EDITABLE
);
export const resetLocalPreparation = createAction(actionTypes.BENCHMARKING_RESET_LOCAL_PREPARATION);
export const validateDataForSubmit = createAction(actionTypes.BENCHMARKING_LOCAL_DATA_VALID);

export const getLocalPreparations = () => (dispatch, getState) =>
  dispatch(loadGlobalIdentifiers()).then(globalBody => {
    const globalBenchmarkingId = R.prop('id', globalBody);
    const localPrepId = R.path(
      ['benchmarking', 'local', 'main', 'statusData', 'localPreparation', 'id'],
      getState()
    );

    return GET(
      `${benchmark.benchmarking}/${globalBenchmarkingId}/localPreparations/${localPrepId}`,
      {
        Pragma: 'no-cache',
      }
    )
      .then(body => {
        dispatch(commonPopulationsActions.loadSystemVariables());
        dispatch(commonPopulationsActions.loadEligibilityOperators());
        dispatch(loadLocalPreparations({ data: R.path(['data', 'response'], body) }));
        const rewardIds = R.path(['data', 'response', 'rewardIds'], body);
        if (!isBlank(rewardIds)) {
          dispatch(getExcludedRewards(rewardIds));
        }
        const exceptionsId = R.path(['data', 'response', 'exceptionsId'], body);
        if (!isBlank(exceptionsId)) {
          dispatch(localPopulationActions.getPopulationExceptions(exceptionsId));
        }
      })
      .catch(error => {
        dispatch(loadLocalPreparationsError());
        dispatchError(error, dispatch);
      });
  });
