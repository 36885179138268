import * as R from 'ramda';

export const INCLUDED = 'included';
const NOT_INCLUDED = 'notIncluded';

const mapModellerPayloadToCategoryMappings = payload =>
  R.reduce(
    (acc, item) => {
      const { categoryId, subcategoryId, typeId, isIncluded, isEditable, category } = item;
      const key = `${categoryId}/${subcategoryId}/${typeId}`;

      acc[key] = {
        isIncluded,
        isEditable,
        mappingName: category,
        columnId: isIncluded ? INCLUDED : NOT_INCLUDED,
      };
      return acc;
    },
    {},
    payload
  );

export default mapModellerPayloadToCategoryMappings;
