import styled, { css } from 'styled-components';

const StyledFace = styled.div`
  ${props => {
    const { disabled } = props;

    return css`
      ${disabled &&
        `
        cursor: not-allowed;
      `}
    `;
  }};
`;

StyledFace.displayName = 'StyledFace';
export default StyledFace;
