import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isBlank } from '@trs/utils';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  StyledDropdown,
  Collapse,
  CollapseItem,
  CollapseItemTitle,
  CollapseItemBody,
  Icon,
} from '@trs/components';

import * as userActions from '../../../actions/userActions';
import MenuSublistItem from './MenuSublistItem';

const isOnCurrentPage = (currentPage, item) => {
  if (currentPage.includes(R.prop('route', item))) {
    return true;
  }

  const items = R.prop('items', item);
  if (isBlank(items)) {
    return false;
  }
  if (R.any((subItem) => currentPage.startsWith(R.prop('route', subItem)), items)) {
    return true;
  }
  return false;
};

export class MenuNavigator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileExpanded: false,
    };
  }

  toggleMobileMenu = () => {
    this.setState((prevState) => ({ mobileExpanded: !prevState.mobileExpanded }));
  };

  renderElementItems = (elem) => {
    return elem.itemProps && elem.itemProps.component
      ? elem.itemProps.component
      : elem.route && (
          <Link className="subList-item" to={elem.route} onClick={this.toggleMobileMenu}>
            <span className="header-item">{elem.title}</span>
          </Link>
        );
  };

  render() {
    const { menuModel, currentPage } = this.props;
    const { mobileExpanded } = this.state;

    return (
      <Fragment>
        <ul className={`menu-list ${mobileExpanded ? 'menu-expanded' : 'menu-collapsed'}`}>
          {menuModel.map(
            (elem, index) =>
              elem && (
                <li
                  key={elem.title}
                  className={`menu-item ${elem.id ? elem.id : ''} ${
                    elem.itemProps && elem.itemProps.align ? elem.itemProps.align : ''
                  } ${isOnCurrentPage(currentPage, elem) ? 'menu-item-active' : ''}`}
                >
                  {!elem.items.length ? (
                    this.renderElementItems(elem)
                  ) : (
                    <Collapse multi externalCollapse>
                      {({ toggle, openItems }) => (
                        <CollapseItem key={elem.title}>
                          <Fragment>
                            <CollapseItemTitle
                              className={`menu-item-title ${
                                openItems.includes(index) ? 'active' : 'inactive'
                              }`}
                              expanded={openItems.includes(index)}
                              onClick={() => toggle(index)}
                            >
                              <span className="header-item">{elem.title}</span>
                              <Icon
                                className="button-icon dropdown-icon"
                                size={24}
                                icon="ion-chevron-down"
                              />
                            </CollapseItemTitle>
                            <CollapseItemBody
                              className={`menu-item-body ${
                                openItems.includes(index) ? 'open' : 'closed'
                              }`}
                            >
                              <div className="menulist-wrapper">
                                <StyledDropdown className="sublist-dropdown">
                                  <MenuSublistItem
                                    elem={elem}
                                    toggle={() => {
                                      toggle(index);
                                      if (mobileExpanded) {
                                        this.toggleMobileMenu();
                                      }
                                    }}
                                  />
                                </StyledDropdown>
                              </div>
                            </CollapseItemBody>
                          </Fragment>
                        </CollapseItem>
                      )}
                    </Collapse>
                  )}
                </li>
              )
          )}
        </ul>

        <div className="mobile-burger-menu">
          {mobileExpanded ? (
            <Icon
              className="close-icon"
              size={32}
              icon="ion-android-close"
              onClick={this.toggleMobileMenu}
              aria-label="Close menu"
            />
          ) : (
            <Icon
              className="menu-icon"
              size={32}
              icon="ion-android-menu"
              onClick={this.toggleMobileMenu}
              aria-label="Expand menu"
            />
          )}
        </div>
      </Fragment>
    );
  }
}
MenuNavigator.propTypes = {
  currentPage: PropTypes.string,
  menuModel: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};
MenuNavigator.defaultProps = {
  currentPage: '',
};
const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...userActions }, dispatch),
});

export default connect(null, mapDispatchToProps)(MenuNavigator);
