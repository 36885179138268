"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.onViewTypeChange = exports.menuListItems = exports.isStepper = exports.initialStepperFormula = exports.emptyRule = exports.emptyPercentageRule = exports.ACTIVE_TYPES = void 0;
var _uuid = require("uuid");
var _utils = require("@trs/utils");
var R = _interopRequireWildcard(require("ramda"));
var _helpers = require("../helpers");
var _constants = require("../constants");
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
var ACTIVE_TYPES = {
  fixed: 'FIXED',
  percentage: 'PERCENTAGE'
};
exports.ACTIVE_TYPES = ACTIVE_TYPES;
var emptyRule = function emptyRule() {
  return {
    value: '',
    label: null,
    key: (0, _uuid.v4)()
  };
};
exports.emptyRule = emptyRule;
var isStepper = function isStepper(_ref) {
  var stepper = _ref.stepper;
  return !(0, _utils.isBlank)(R.path([ACTIVE_TYPES.fixed, 'steps'], stepper)) || !(0, _utils.isBlank)(R.path([ACTIVE_TYPES.percentage, 'steps'], stepper));
};
exports.isStepper = isStepper;
var menuListItems = function menuListItems(_ref2) {
  var labels = _ref2.labels,
    actions = _ref2.actions,
    ruleIndex = _ref2.ruleIndex,
    setHasChanges = _ref2.setHasChanges;
  return [{
    name: labels.BUTTON_DELETE,
    icon: 'ion-trash-b',
    onClick: function onClick() {
      actions.deleteRuleFormulas(ruleIndex);
      setHasChanges(true);
    }
  }];
};
exports.menuListItems = menuListItems;
var onViewTypeChange = function onViewTypeChange(event, rule) {
  var value = event.target.value;
  var stepper = _objectSpread(_objectSpread({}, rule.stepper), {}, {
    activeType: value
  });
  if ((0, _utils.isBlank)(R.path(['stepper', value, 'steps'], rule))) {
    stepper = _objectSpread(_objectSpread({}, stepper), {}, _defineProperty({}, value, {
      steps: [emptyRule(), emptyRule()]
    }));
    if (value === ACTIVE_TYPES.percentage) {
      stepper[value].steps = [_objectSpread(_objectSpread({}, emptyRule()), {}, {
        aditionalFormulaItems: (0, _helpers.createInitialFormula)(_objectSpread({}, _constants.extendedPlaceholdersTypeMap[1]))
      }), _objectSpread(_objectSpread({}, emptyRule()), {}, {
        aditionalFormulaItems: (0, _helpers.createInitialFormula)(_objectSpread({}, _constants.extendedPlaceholdersTypeMap[1]))
      })];
    }
  }
  return stepper;
};
exports.onViewTypeChange = onViewTypeChange;
var emptyPercentageRule = function emptyPercentageRule() {
  return _objectSpread(_objectSpread({}, emptyRule()), {}, {
    of: ''
  });
};
exports.emptyPercentageRule = emptyPercentageRule;
var initialStepperFormula = function initialStepperFormula(_ref3) {
  var _ref4;
  var rule = _ref3.rule;
  return rule.length > 0 ? rule : (_ref4 = {
    activeType: ACTIVE_TYPES.fixed
  }, _defineProperty(_ref4, ACTIVE_TYPES.fixed, {
    steps: [emptyRule(), emptyRule()]
  }), _defineProperty(_ref4, ACTIVE_TYPES.percentage, {
    steps: []
  }), _ref4);
};
exports.initialStepperFormula = initialStepperFormula;