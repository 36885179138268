import styled, { css } from 'styled-components';
import { media } from '@trs/utils';
import APP_CONFIG from '@trs/config';

const { breakpoints, breakpointsNumeric } = APP_CONFIG;
const StyledStatementSides = styled.div`
  ${(props) => {
    const { showOnlyNewStatement, theme } = props;
    const {
      spacing,
      typography,
      palette: color,
      extra: { genericBorderRadius },
    } = theme;
    return css`
      .statement-body {
        background-color: ${color.basic.white};
        min-height: 200px;
        border: solid 1px ${color.utility.border};
        border-bottom-right-radius: ${genericBorderRadius};
        margin-bottom: ${spacing.base};

        .statement-empty {
          margin: ${spacing.small};
        }
      }
      .page-spinner {
        position: relative;
      }
      .statement-row {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        section {
          margin-bottom: 0;
        }
        &.statement-header-row {
          margin-bottom: 0;
          border-radius: ${genericBorderRadius} ${genericBorderRadius} 0 0;
          border-bottom-width: 2px;
          padding: ${spacing.tiny} ${spacing.small};
          @media (min-width: ${breakpoints.tablet}) and (max-width: ${`${
              breakpointsNumeric.desktop - 1
            }px`}) {
            .statement-row-value {
              align-items: flex-end;
              flex-direction: column;
              padding: 0;
            }
          }
        }

        ${media.mobileLandscape`
          flex-wrap: nowrap;
        `}
        &-title {
          overflow-wrap: break-word;
          flex: 0 0 100%;
          margin-bottom: ${spacing.small};
          word-break: break-word;
          font-weight: ${typography.weight.thick};
          ${media.mobileLandscape`
            flex: 0 0 40%;
            margin-bottom: 0;
          `}
        }
        &-value {
          flex: 0 0 50%;
          text-align: left;
          justify-content: flex-start;
          word-break: break-all;

          ${media.mobileLandscape`
              flex: ${showOnlyNewStatement ? 1 : '0 0 30%'};
              text-align: right;
              justify-content: flex-end;
          `}
        }
      }
    `;
  }}
`;

StyledStatementSides.displayName = 'StyledStatementSides';
export default StyledStatementSides;
