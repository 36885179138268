import * as R from 'ramda';
import * as actions from '../actions/types';

const initialState = {
  isVestedChecked: false,
  isUnvestedChecked: false,
};

export default function customisation(state = initialState, action) {
  switch (action.type) {
    case actions.EMPLOYEE_PERSONAL_DETAILS_PENDING:
      return {
        ...state,
        isFetching: true,
      };
    case actions.EMPLOYEE_PERSONAL_DETAILS:
      return {
        ...state,
        isFetching: false,
        initialPersonalDetails: !R.isEmpty(action.response)
          ? action.response
          : [
              {
                displayOrder: 0,
                defaultLabel: '',
                mappingField: null,
              },
            ],
      };
    case actions.UPDATE_EMPLOYEE_PERSONAL_DETAILS: {
      const updatedDetails = state.initialPersonalDetails.map((detail) => {
        const updatedDetail = detail;
        if (
          detail.mappingField === action.payload.mappingField &&
          detail.displayOrder === action.payload.displayOrder
        )
          updatedDetail.isEnabled = !detail.isEnabled;

        return updatedDetail;
      });
      return {
        ...state,
        isFetching: false,
        initialPersonalDetails: updatedDetails,
      };
    }
    case actions.GET_PROFILE_DATA:
      return {
        ...state,
        profileData: action.response,
      };
    case actions.PERSONAL_DETAIL_VALUE_CHANGE: {
      const { mappingField, displayOrder, value } = action.payload;

      return {
        ...state,
        initialPersonalDetails: state.initialPersonalDetails.map((detail) => {
          if (detail.mappingField === mappingField && detail.displayOrder === displayOrder) {
            return {
              ...detail,
              label: value,
            };
          }
          return detail;
        }),
      };
    }
    case actions.PERSONAL_DETAIL_CHANGE: {
      const { displayOrder, defaultLabel, mappingField } = action.payload;

      const updatedDetails = state.initialPersonalDetails;

      updatedDetails[displayOrder] = {
        ...updatedDetails[displayOrder],
        displayOrder,
        label: defaultLabel,
        mappingField,
      };

      return {
        ...state,
        initialPersonalDetails: updatedDetails,
      };
    }
    case actions.PERSONAL_DETAIL_DELETE: {
      const { initialPersonalDetails } = state;
      initialPersonalDetails.splice(action.payload, 1);

      return {
        ...state,
        initialPersonalDetails: initialPersonalDetails.map((detail, index) => ({
          ...detail,
          displayOrder: index,
        })),
      };
    }
    case actions.VESTED_TOGGLE_VALUE: {
      const value = action.payload;

      return {
        ...state,
        isVestedChecked: value,
      };
    }
    case actions.UNVESTED_TOGGLE_VALUE: {
      const value = action.payload;

      return {
        ...state,
        isUnvestedChecked: value,
      };
    }
    case actions.GET_SHARES_DATA: {
      const response = action.payload;

      return {
        ...state,
        isSharesDataFetching: false,
        isVestedChecked: response.vestedAmount,
        isUnvestedChecked: response.unvestedAmount,
      };
    }
    case actions.GET_SHARES_DATA_PENDING:
      return {
        ...state,
        isSharesDataFetching: true,
      };
    case actions.GET_SHARES_DATA_ERROR:
      return {
        ...state,
        isSharesDataFetching: false,
      };
    default:
      return state;
  }
}
