import * as R from 'ramda';
import { camelcase, isBlank } from '@trs/utils';

export const loadSortedData = (searchText, filters, page, sort, callback) => {
  const params = {
    sortAsc: sort.direction === 'asc',
    searchText,
    filters,
    sortBy: sort.by,
    pagingTop: page.resultsPerPage,
    pagingSkip: (page.currentPage - 1) * page.resultsPerPage,
  };
  callback(params);
};

export const mapServerErrorToFields = error => {
  const serverErrors = R.path(['response', 'data', 'errors'], error);
  if (!isBlank(serverErrors)) {
    return R.mergeAll(
      R.map(
        ({ field, message }) => ({ [camelcase(field)]: { serverError: message } }),
        serverErrors
      )
    );
  }
  return {};
};

export const menuListContextKeys = {
  delete: 'DELETE',
};
