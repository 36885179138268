"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.mediaHeight = exports.StyledMediaHandler = exports.StyledMediaContainer = exports.StyledMedia = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
var _utils = require("@trs/utils");
var _helpers = require("./helpers");
var _templateObject, _templateObject2, _templateObject3, _templateObject4;
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
var StyledMedia = _styledComponents["default"].div.withConfig({
  displayName: "StyledMediastyle__StyledMedia",
  componentId: "sc-9iu2ch-0"
})(["", ";"], function (_ref) {
  var theme = _ref.theme;
  return (0, _styledComponents.css)(["display:flex;flex-direction:column;z-index:3;position:relative;", " ", " .media-title{word-break:break-word;}"], _utils.media.mobile(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n        width: 100%;\n        order: 2;\n        padding-left: -", ";\n\n        .media-list{\n          background-color:  ", ";\n          padding: 0px ", ";\n          border: 2px solid ", "; \n          transition: border 0.2s ease-out 0s;\n          box-shadow: ", ";\n          border-radius: ", ";\n        }\n      "])), theme.spacing.smallPlus, theme.palette.basic.white, theme.spacing.xSmall, theme.palette.gray.foxtrot, theme.extra.boxShadow, theme.spacing.xTiny), _utils.media.tabletLandscape(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n        width: 45%;\n        float: right;\n        margin-top: 0px;\n        margin-left: ", ";\n        background-color: ", ";\n\n        .media-list{\n          background-color: transparent;\n          padding: 0px;\n          border: none; \n          transition: none;\n          box-shadow: none;\n          border-radius: 0;\n        }\n      "])), theme.spacing.smallPlus, theme.palette.basic.white));
});
exports.StyledMedia = StyledMedia;
StyledMedia.displayName = 'StyledMedia';
var StyledMediaHandler = _styledComponents["default"].div.withConfig({
  displayName: "StyledMediastyle__StyledMediaHandler",
  componentId: "sc-9iu2ch-1"
})(["", ";"], function (_ref2) {
  var theme = _ref2.theme;
  var palette = theme.palette,
    spacing = theme.spacing;
  return (0, _styledComponents.css)(["display:flex;flex-direction:row;align-items:center;justify-content:center;cursor:pointer;padding-top:", ";padding-bottom:", ";border-radius:5px;background-color:", ";border:3px dotted ", ";color:", ";&.disabled{cursor:no-drop;color:", ";}.media-helper-icon{width:", ";height:", ";}"], spacing.small, spacing.small, palette.basic.white, palette.utility.border, palette.primary, palette.gray.charlie, spacing.xLarge, spacing.xLarge);
});
exports.StyledMediaHandler = StyledMediaHandler;
StyledMediaHandler.displayName = 'StyledMediaHandler';
var mediaHeight = 270;
exports.mediaHeight = mediaHeight;
var StyledMediaContainer = _styledComponents["default"].div.withConfig({
  displayName: "StyledMediastyle__StyledMediaContainer",
  componentId: "sc-9iu2ch-2"
})(["", ";"], function (_ref3) {
  var theme = _ref3.theme;
  return (0, _styledComponents.css)(["width:100%;display:flex;justify-content:flex-end;align-items:flex-start;background-color:transparent;margin-top:", ";position:relative;", " ", " .more-icon{cursor:pointer;position:absolute;z-index:10;margin:14px;padding:", " ", ";border-radius:50%;color:", ";background-color:", ";}.media{height:inherit;width:100%;img{height:100%;}}"], theme.spacing.small, _utils.media.mobile(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n        height: auto;\n      "]))), _utils.media.tabletLandscape(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n        height: ", "px;\n      "])), _helpers.RECOMMENDED_SIZE[_helpers.MEDIA_COMPONENTS.MEDIA].height), theme.spacing.tiny, theme.spacing.smallPlus, theme.palette.basic.white, theme.palette.basic.black);
});
exports.StyledMediaContainer = StyledMediaContainer;
StyledMediaContainer.displayName = 'StyledMediaContainer';