import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Paragraph, Button } from '@trs/components';
import { Link } from 'react-router-dom';
import Text, { TextOnly } from '../../../../common/Text';
import NotificationHeader from '../../../../notifications/components/header/NotificationHeader';
import StyledProcessTriggeringNotification from './index.style';

const ProcessTriggeringNotification = ({ item, markNotification }) => {
  const { id, content, createdDate } = item;
  const isStatusSuccess = content.status === 'Success';
  return (
    <StyledProcessTriggeringNotification>
      <NotificationHeader
        createdDate={createdDate}
        title={TextOnly({
          path: 'notifications.NOTIFICATION_WORKSPACE_PROCESS_TRIGGERING_TITLE',
          transform: (label) => label.replace('%STATUS%', content.status),
        })}
      />
      <Paragraph className="notification-process">
        <Text
          path={
            isStatusSuccess
              ? 'notifications.NOTIFICATION_WORKSPACE_PROCESS_TRIGGERING_CONTENT_SUCCESS'
              : 'notifications.NOTIFICATION_WORKSPACE_PROCESS_TRIGGERING_CONTENT_FAILED'
          }
          transform={(label) => label.replace('%PROCESS_NAME%', content.processName)}
        />
        <Link
          to={`/workspace/${content.directoryId}`}
          className="notification-process-triggering"
          onClick={() => markNotification(id)}
        >
          <Text
            path={
              isStatusSuccess
                ? 'notifications.NOTIFICATION_GO_TO_FILE'
                : 'notifications.NOTIFICATION_GO_TO_PROCESS'
            }
          />
        </Link>
      </Paragraph>
      {!item.isRead && (
        <Button type="link" className="notification-link" action={() => markNotification(item.id)}>
          <Text path="notifications.MARK_AS_READ" />
        </Button>
      )}
    </StyledProcessTriggeringNotification>
  );
};

ProcessTriggeringNotification.propTypes = {
  item: PropTypes.shape({
    typeId: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    isRead: PropTypes.bool.isRequired,
    content: PropTypes.shape({
      status: PropTypes.string,
      directoryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      processName: PropTypes.string,
    }).isRequired,
    createdDate: PropTypes.string.isRequired,
  }).isRequired,
  markNotification: PropTypes.func.isRequired,
};

export default memo(ProcessTriggeringNotification);
