import { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { matchRoles } from './helpers';

export class ACLControl extends PureComponent {
  checkAcl = () => {
    const { acl, modules } = this.props;
    if (typeof acl === 'function') {
      return acl(modules);
    }

    if (Array.isArray(acl)) {
      return matchRoles(acl, modules);
    }

    throw new Error('acl param is either a function or an array of roles');
  };

  render() {
    const { children, alt } = this.props;
    const hasACL = this.checkAcl();

    return hasACL ? children : alt;
  }
}

ACLControl.propTypes = {
  acl: PropTypes.oneOfType([PropTypes.array, PropTypes.func]).isRequired,
  modules: PropTypes.shape({}).isRequired,
  children: PropTypes.node.isRequired,
  alt: PropTypes.node,
};

ACLControl.defaultProps = {
  alt: null,
};

const mapStateToProps = (state) => ({
  modules: state.user.authorizedModules,
});

export default connect(mapStateToProps)(ACLControl);
