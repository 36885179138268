export default {
  PLATFORM_NAME: 'Total Rewards System',
  PLATFORM_CONTACT: 'mailto:support@uflexreward.com',
  PLATFORM_SUPPORT: 'https://unilever.service-now.com/esc',
  PLATFORM_PRIVACY_POLICY: 'https://www.uflexreward.com/',
  PLATFORM_TERMS: 'https://www.uflexreward.com/terms-and-conditions/',
  PLATFORM_COPYRIGHT: 'https://www.uflexreward.com/',
  PLATFORM_FACEBOOK: 'https://www.uflexreward.com/',
  PLATFORM_YOUTUBE: 'https://www.uflexreward.com/',
  PLATFORM_TWITTER: 'https://twitter.com/uflexreward?lang=en',
  PLATFORM_LINKEDIN: 'https://www.linkedin.com/company/uflexreward/',
  PLATFORM_SUPPORT_EMAIL: 'Info@uflexreward.com',
};
