import * as R from 'ramda';
import { TYPES } from '@trs/utils';
import { LANDING_PAGES } from '../config/userManager/menuConfig';
import userModules from '../config/userManager/userModules';

export const getTypeFromPayload = payload => {
  const isBetaFeature = R.path(['isBetaFeature'], payload);

  if (isBetaFeature) return TYPES.ON;

  return TYPES.OFF;
};

const getLandingPage = (authorizedModules, isNonEmployee) => {
  let landingPage = null;

  const authorizedModulesCount = R.length(authorizedModules);
  if (authorizedModulesCount === 0) {
    landingPage = LANDING_PAGES.NO_MODULES;
  } else if (authorizedModulesCount === 1 && isNonEmployee) {
    landingPage = LANDING_PAGES.ACCESS_DENIED;
    /**
     * if user has only EMPLOYEE_DASHBOARD rights, landing page should be /employee-statement
     */
  } else if (
    authorizedModulesCount === 1 &&
    authorizedModules.includes(userModules.EMPLOYEE_DASHBOARD)
  ) {
    landingPage = LANDING_PAGES.EMPLOYEE_DASHBOARD;
  } else {
    /**
     * for every other access rights, landing page should be home
     */
    landingPage = LANDING_PAGES.RM_DASHBOARD;
  }

  return landingPage;
};

export default getLandingPage;
