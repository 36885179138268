"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var StyledBanner = _styledComponents["default"].div.withConfig({
  displayName: "Bannerstyle__StyledBanner",
  componentId: "sc-14suncj-0"
})(["", ";"], function (props) {
  var _props$theme = props.theme,
    spacing = _props$theme.spacing,
    typography = _props$theme.typography,
    extra = _props$theme.extra,
    components = _props$theme.components,
    palette = _props$theme.palette;
  var type = props.type;
  var color = components.banner[type];
  return (0, _styledComponents.css)(["align-items:flex-start;background-color:", ";box-sizing:border-box;color:", ";border:1px solid ", ";border-radius:", ";display:flex;font-size:", ";margin-bottom:", ";line-height:", ";padding:", ";width:100%;.banner-icon,.banner-close,p{color:", ";}.banner-icon{margin-right:", ";}.banner-dismiss-button{cursor:pointer;margin-left:", ";color:", ";}.banner-message-container{flex:1;strong{display:inline-block;overflow:hidden;text-overflow:ellipsis;vertical-align:top;}}"], palette.basic.white, color, color, extra.genericBorderRadius, typography.size.table, spacing.small, typography.lineHeight["default"], spacing.base, color, spacing.small, spacing.tiny, color);
});
StyledBanner.displayName = 'StyledBanner';
var _default = StyledBanner;
exports["default"] = _default;