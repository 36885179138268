const types = {
  GET_SHARES_PROVIDERS: 'GET_SHARES_PROVIDERS',
  CHANGE_PROVIDER: 'CHANGE_PROVIDER',
  SHARES_DATA_LOAD_STATUS: 'SHARES_DATA_LOAD_STATUS',
  SHARES_FILE_SELECTED: 'SHARES_FILE_SELECTED',
  SHARES_SAVE_FILE_DATA: 'SHARES_SAVE_FILE_DATA',
  SHARES_VALIDATE_FILE_DATA: 'SHARES_VALIDATE_FILE_DATA',
  RESET_STORE: 'RESET_STORE',
  GET_PROVIDERS: 'GET_PROVIDERS',
  GET_PROVIDER_DATA: 'GET_PROVIDER_DATA',
  UPDATE_PROVIDER_DATA: 'UPDATE_PROVIDER_DATA',
  SAVE_PROVIDER_DATA: 'SAVE_PROVIDER_DATA',
  ADD_NEW_PROVIDER: 'ADD_NEW_PROVIDER',
  CLEAR_PROVIDER: 'CLEAR_PROVIDER',
  SAVE_PROVIDER_DATA_FAIL: 'SAVE_PROVIDER_DATA_FAIL',
};

export default types;
