import React from 'react';
import * as R from 'ramda';
import { processDate, decimalTransform, isBlank } from '@trs/utils';
import { DATE_TIME_FORMAT } from '@trs/config';
import { getItemId } from '../components/helpers';
import DisplayName from '../components/DisplayName';

export const getItemSize = (item) => {
  if (isBlank(item.sizeInMb) || item.sizeInMb < 0) return '-';
  return `${decimalTransform(item.sizeInMb)} MB`;
};

export const getItemLastUpdated = ({ lastUpdated }) =>
  isBlank(lastUpdated) ? '-' : processDate(lastUpdated, DATE_TIME_FORMAT, false);

export const mapPayloadToModel = (items, hasWriteAccess) => {
  return items.map((item) => ({
    ...item,
    id: getItemId(item),
    displayName: <DisplayName item={item} withTooltip />,
    name: item.displayName,
    sizeInMb: getItemSize(item),
    lastUpdated: getItemLastUpdated(item),
    menuListProps: {
      delete: {
        disabled: !hasWriteAccess,
      },
    },
  }));
};

export const DEFAULT_SORTING_STATE = {
  by: 'displayName',
  direction: 'asc',
};

export const getSortConfig = (by, sorting) => {
  let newSorting = {};

  if (sorting.by !== by) {
    newSorting = {
      by,
      direction: 'asc',
    };
  } else if (sorting.direction === 'asc') {
    newSorting = {
      by,
      direction: 'desc',
    };
  } else if (sorting.direction === 'desc') {
    newSorting = DEFAULT_SORTING_STATE;
  } else {
    newSorting = {
      by,
      direction: 'asc',
    };
  }

  const field = by === 'displayName' ? R.compose(R.toLower, R.prop('name')) : R.prop(by);
  const sortingFn = newSorting.direction === 'asc' ? R.ascend(field) : R.descend(field);

  return { newSorting, sortingFn: R.sortWith([sortingFn]) };
};

export const emptyCurrentProcessSteps = (processHash, processList) =>
  processList.reduce((result, currentObj) => {
    if (currentObj.hash === processHash) {
      result.push({ ...currentObj, steps: [] });
    } else {
      result.push(currentObj);
    }
    return result;
  }, []);
