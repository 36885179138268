import * as R from 'ramda';
import { isBlank } from '@trs/utils';

export const mapResponseToOptions = list =>
  R.map(
    item => ({
      value: item,
      label: item,
    }),
    list
  );

export const hasEmptyRequiredValues = object => {
  const keys = R.keys(object);
  let hasEmptyValues = false;
  R.forEach(item => {
    if (isBlank(object[item])) {
      hasEmptyValues = true;
    }
  }, keys);
  return hasEmptyValues;
};

export default { mapResponseToOptions, hasEmptyRequiredValues };
