import React from 'react';
import PropTypes from 'prop-types';
import { MAX_DECIMALS } from '@trs/config';
import { FormattedNumber } from '@trs/components';
import { getjsx } from '@trs/utils';
import { TextOnly } from '../../../../../../../common/Text';
import DescriptionDialog from '../../../../../../../common/DescriptionDialog';
import ReadMoreButton from '../../../../../../../common/ReadMoreButton';
import { showMoreButton, getPlainText } from './helpers';

const textOnlyRewTextLen = 40;

const RewardCaption = ({
  name,
  min,
  max,
  showSlider,
  description,
  descriptionBanner,
  descriptionMedia,
  isPercentage,
  textValue,
}) => {
  const hasMoreInfo = showMoreButton(textValue, textOnlyRewTextLen);
  const hasDescription = description || descriptionBanner || descriptionMedia;

  return (
    <div className="label-with-caption">
      {!!name && (
        <span className="reward-name">
          {hasDescription && (
            <DescriptionDialog
              className="option-tile-tooltip"
              content={{
                title: name,
                text: description,
                descriptionBanner,
                descriptionMedia,
              }}
            />
          )}
          {name}
        </span>
      )}
      {textValue && (
        <span className="reward-text-only-description">
          <div
            className="text-only-description"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: getPlainText(textValue, textOnlyRewTextLen),
            }}
          />
          {hasMoreInfo && <ReadMoreButton className="option-tile-tooltip" content={textValue} />}
        </span>
      )}
      {showSlider && (
        <span className="reward-caption">
          {getjsx({
            label: TextOnly({ path: 'flexibleRewards.AVAILABLE_OPTIONS_TILE_CAPTION' }),
            map: {
              MIN: (
                <FormattedNumber
                  key={`min-${description}`}
                  value={min}
                  isPercentage={isPercentage}
                  decimals={MAX_DECIMALS}
                />
              ),
              MAX: (
                <FormattedNumber
                  value={max}
                  key={`max-${description}`}
                  isPercentage={isPercentage}
                  decimals={MAX_DECIMALS}
                />
              ),
            },
          })}
        </span>
      )}
    </div>
  );
};

RewardCaption.propTypes = {
  showSlider: PropTypes.bool.isRequired,
  description: PropTypes.string,
  name: PropTypes.string.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  isPercentage: PropTypes.bool.isRequired,
  textValue: PropTypes.string,
  descriptionBanner: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
    type: PropTypes.string,
  }),
  descriptionMedia: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
      type: PropTypes.string,
    })
  ),
};

RewardCaption.defaultProps = {
  min: null,
  description: null,
  max: null,
  textValue: null,
  descriptionBanner: null,
  descriptionMedia: null,
};

export default RewardCaption;
