const initialState = {
  loadingDependencies: true,
  savingBenchmarking: false,
  fxSourceId: null,
  fxRatesDate: null,
  ageingDate: null,
  fxSources: null,
  countriesMaping: {},
  countriesByCluster: {},
  activeCounter: 0,
  countryList: [],
  payScaleIntervals: {},
  reloadingFx: false,
  identifiers: {},
  errors: {
    population: [],
    referenceData: {},
    countryDetails: {},
  },
};

export default initialState;
