export default {
  VARIABLES_LINK_TITLE: 'Variables',
  REWARDS_LINK_TITLE: 'Rewards',
  EXCEPTIONS_LINK_TITLE: 'Exceptions',
  EMPLOYEE_STATEMENT_LINK_TITLE: 'Employee Statement',
  FOREIGN_EXCHANGE_LINK_TITLE: 'Foreign Exchange',
  USER_MANAGEMENT_LINK_TITLE: 'User Management',
  FLEXIBLE_REWARDS_LINK_TITLE: 'Flexible Rewards',
  RM_DASHBOARD_LINK_TITLE: 'RM Dashboard',
  HOMEPAGE_TEXT: 'Homepage',
  LOGIN_TEXT: 'Login',
  NOT_FOUND_TITLE: '404! Page not found',
  NOT_FOUND_TEXT: `We're sorry, the page you requested could not be found. </br>Please go back to the homepage to continue using the system.</br>If you have any queries, please contact the system administrator </br>(`,
  ERROR_PAGE_BUTTON: 'Go to homepage',
  NOT_FOUND_DESC: 'Use the navigation to reach the desired section.',
  NOT_AUTHORIZED_TITLE: 'Access denied for this module',
  NO_MODULES_ASSIGNED_TITLE: 'Thank you for coming to %PLATFORM%.',
  NO_ACCESS_TO_MODULE:
    'Your personalised %PLATFORM% statement is not yet ready, please come back later.',
  PLEASE_CONTACT_SUPPORT:
    'If you have specific queries at this time on your Total Reward, please contact your system administrator.<br><br>Best regards,<br>%PLATFORM%  Support Team',
  ACCESS_DENIED_TITLE: 'Access denied for this module',
  NO_EMPLOYEE_ID: 'We could not identify your Employee ID in the system.',
  PLEASE_CONTACT_HR: 'Please contact the HR department for additional information.',
  NOT_AUTHORIZED_DESC: `You do not have permission to access this page. </br>Please go back to the homepage to continue using the system.</br>If you have any queries, please contact the system administrator </br>(`,
};
