import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  isBlank,
  sessionStorageManager,
  INACTIVITY_TIMEOUT_KEY,
  DEFAULT_TIME_OUT,
  WARNING_SIGN_OUT_TIME_SECONDS,
} from '@trs/utils';
import { logoutUser } from 'actions/userActions';
import { resetInactivityTimer, clearInactivityData } from 'actions/inactivityTimeout';
import { TextOnly } from 'modules/common/Text';
import IdleTimer from 'modules/common/IdleTimer';
import InactivityDialog from 'modules/common/InactivityDialogWithTimer';

class InactivityTimeout extends React.PureComponent {
  constructor(props) {
    super(props);

    this.idleTimer = null;
    this.inactivityTimeout = isBlank(sessionStorageManager.getItem(INACTIVITY_TIMEOUT_KEY))
      ? DEFAULT_TIME_OUT
      : sessionStorageManager.getItem(INACTIVITY_TIMEOUT_KEY);

    this.state = {
      showWarningInactivity: false,
    };
  }

  componentDidMount() {
    const { user } = this.props;

    this.idleTimer = this.setupIdleTimer({
      timeout: this.inactivityTimeout,
      warningTimeOut: WARNING_SIGN_OUT_TIME_SECONDS,
      preventStartTimer: !user.isLoggedIn,
    });

    if (user.isLoggedIn) {
      this.idleTimer.start();
    }
  }

  componentDidUpdate(prevProps) {
    this.onTimerComponentDidUpdate(prevProps);
  }

  componentWillUnmount() {
    if (!isBlank(this.idleTimer)) {
      this.idleTimer.cleanup();
    }
  }

  onTimerComponentDidUpdate(prevProps) {
    if (
      this.props.user.isLoggedIn !== prevProps.user.isLoggedIn ||
      (this.props.inactivityTimeout.shouldResetInactivityTimer !==
        prevProps.inactivityTimeout.shouldResetInactivityTimer &&
        this.props.inactivityTimeout.shouldResetInactivityTimer)
    ) {
      const sessionInactivityTimeOut = sessionStorageManager.getItem(INACTIVITY_TIMEOUT_KEY);
      if (this.inactivityTimeout !== sessionInactivityTimeOut) {
        this.idleTimer.setTimeout(sessionInactivityTimeOut);
      }

      this.idleTimer.restart();
      this.props.actions.resetInactivityTimer(false);
    }
  }

  handleLogout = () => {
    sessionStorageManager.removeItem(INACTIVITY_TIMEOUT_KEY);
    this.props.actions.logoutUser();
    this.props.actions.clearInactivityData();
  };

  setupIdleTimer = ({ timeout, warningTimeOut, preventStartTimer }) => {
    return new IdleTimer({
      timeout,
      warningTimeOut,
      preventStartTimer,
      callbacks: {
        onTimeout: () => {
          this.handleLogout();
        },
        onExpired: () => {
          this.handleLogout();
        },
        onWarningInactivity: () => {
          this.setState({ showWarningInactivity: true });
        },
        resetWarningInactivity: () => {
          this.setState({ showWarningInactivity: false });
        },
      },
    });
  };

  render() {
    const { inactivityTimeout } = this.props;
    const { showWarningInactivity } = this.state;
    const showDialog = inactivityTimeout.isWarningInactivityActive && showWarningInactivity;

    return (
      <div>
        {showDialog && (
          <InactivityDialog
            expiryTimestamp={120}
            title={TextOnly({ path: 'generic.EXPIRE_TITLE' })}
            buttonAction={() => {
              this.setState({ showWarningInactivity: false });
              this.props.actions.resetInactivityTimer(true);
            }}
            labelContinue={TextOnly({ path: 'generic.BUTTON_CONTINUE' })}
          />
        )}
      </div>
    );
  }
}

InactivityTimeout.defaultProps = {};

InactivityTimeout.propTypes = {
  inactivityTimeout: PropTypes.shape({
    inactivityTimeout: PropTypes.number,
    shouldResetInactivityTimer: PropTypes.bool,
    isWarningInactivityActive: PropTypes.bool,
  }).isRequired,
  actions: PropTypes.shape({
    logoutUser: PropTypes.func,
    resetInactivityTimer: PropTypes.func,
    clearInactivityData: PropTypes.func,
  }).isRequired,
  user: PropTypes.shape({
    isLoggedIn: PropTypes.bool.isRequired,
  }).isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  inactivityTimeout: state.inactivityTimeout,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      logoutUser,
      resetInactivityTimer,
      clearInactivityData,
    },
    dispatch
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(InactivityTimeout);
