import styled, { css } from 'styled-components';
import { media } from '@trs/utils';

const StyledColumnHeader = styled.div`
  ${props => {
    const { spacing, palette: color, typography } = props.theme;
    const { hideOnMobile } = props;
    return css`
      align-items: center;
      height: 140px;
      display: flex;
      padding: ${spacing.large} 0;
      ${media.desktop`
        height: ${spacing.xxLarge};
      `}
      ${hideOnMobile
        ? css`
            display: none;
            ${media.mobileLandscape`
              display: flex;
            `}
          `
        : ''}
      .header {
        font-size: ${typography.size.h2};
        color: ${color.gray.solo};
        font-weight: ${typography.weight.normal};
      }

      .column-header-details {
        display: flex;
        flex-direction: column;
        word-break: break-word;
        margin-right: ${spacing.tiny};
        order: 1;

        ${media.desktop`
          order: 0;
        `}
        span {
          font-size: ${typography.size.paragraph};
          color: ${color.basic.gray};
          line-height: 1.5;
        }
        strong {
          color: ${color.gray.solo};
          line-height: ${typography.lineHeight.regular};
          font-size: ${typography.size.base};
          font-weight: ${typography.weight.bold};
        }
      }
      .doughnut-container {
        display: none;
        pointer-events: none;
        width: ${spacing.xxLarge};
        ${media.tablet`
          display: block;
          width: ${spacing.xLarge};
        `}
        .doughnut-chart {
          margin: 0;
          min-width: auto;
        }
      }
    `;
  }};
`;

StyledColumnHeader.displayName = 'StyledColumnHeader';
export default StyledColumnHeader;
