export default {
  isLoading: true,
  error: null,
  proposalId: null,
  etag: null,
  tableData: [],
  isSaving: false,
  merit: [],
  weightedAverage: {},
  isLoadingPremerit: true,
  isLoadingThresholds: true,
  salaryIncreaseLoadError: false,
};
