import { Component } from 'react';
import PropTypes from 'prop-types';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

const mainSubject = new Subject();

export const publish = (topic, data) => {
  mainSubject.next({ topic, data });
};

export class Observer extends Component {
  unsub = null;

  constructor(props) {
    super(props);
    const { topic, listener } = props;
    this.state = { topic, data: null };
    this.unsub = mainSubject.pipe(filter(f => f.topic === this.state.topic)).subscribe(s => {
      this.setState({ data: s.data });
      if (listener) listener({ data: s.data });
    });
  }

  componentWillUnmount() {
    this.unsub.unsubscribe();
  }

  render() {
    return this.props.children(this.state.data);
  }
}

Observer.propTypes = {
  children: PropTypes.func.isRequired,
  topic: PropTypes.string.isRequired,
  listener: PropTypes.func.isRequired,
};

export default Observer;
