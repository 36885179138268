import * as R from 'ramda';
import { convertDecimalToPercentage } from '@trs/utils';
import { REDUCER_KEY_MAP } from '../actions';

export const mapPayload = (data, colors) => {
  const orderedCategories = R.sortBy(R.prop('orderIndex'), data.categories);
  const allCategoriesOption = {
    currency: data.currencyCode,
    index: -1,
    label: 'All categories',
    value: 'All Categories',
    amount: data.total,
    absoluteAmount: Math.abs(data.total),
  };

  const processedData = orderedCategories.map((item, index) => ({
    ...item,
    amount: item.amount,
    currency: data.currencyCode,
    percentage: convertDecimalToPercentage(item.percentage, 2),
    index,
    value: item.categoryId,
    label: item.categoryName,
    absoluteAmount: Math.abs(item.amount),
    color: R.prop(item.orderIndex, colors),
    isNegative: item.amount < 0,
  }));
  const doughnutData = R.assocPath([-1], allCategoriesOption, processedData);
  const selectData = R.insert(0, allCategoriesOption, processedData);
  const legendData = processedData;

  return {
    tota: data.total,
    currency: data.currencyCode,
    doughnutData,
    selectData,
    legendData,
  };
};

const mapCategories = R.reduce(
  (acc, item) => R.assoc(`amount${item.categoryId}`, item.amount, acc),
  {}
);

const getNamePerKey = (nameKey, item) => {
  switch (nameKey) {
    case REDUCER_KEY_MAP.country:
      return item.country;
    case REDUCER_KEY_MAP.jobLevel:
      return item.jobLevel;
    case REDUCER_KEY_MAP.site:
      return `${item.siteName} (${item.countryCode})`;
    default:
      return item.country;
  }
};

const mapCountryData = (nameKey, data) =>
  R.reduce(
    (acc, item) => {
      acc.push({
        name: getNamePerKey(nameKey, item),
        total: item.total,
        currency: 'GBP',
        ...mapCategories(item.categories),
      });
      return acc;
    },
    [],
    data
  );

export const mapDetailsPayload = (key, data) => {
  const mappedData = mapCountryData(key, data);
  return R.sortWith([R.descend(R.prop('total'))], mappedData);
};

export const mapCurrencies = data => R.map(item => ({ label: item.code, value: item.code }), data);

export default {
  mapPayload,
  mapDetailsPayload,
  mapCurrencies,
};
