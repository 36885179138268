const FEATURE_TOGGLE_KEYS = {
  ARTICLES: 'Articles',
  AUDIT: 'Audit',
  FEEDBACK: 'Feedback',
  MODELLER: 'Modeller',
  MODELLER_SEARCH_EMPLOYEE: 'ModellerSearchEmployee',
  DATA_VALIDATION: 'DataValidation',
  BENCHMARKING: 'Plan',
  REPORTS: 'Reports',
  DYNAMIC_MODELLER: 'DynamicModeller',
  SHARES_WIDGET_DISABLED: 'SharesWidgetDisabled',
  ANALYTICS: 'GoogleAnalytics',
  PAYSCALES: 'Payscales',
  LIVE_FX: 'LiveFx',
  SHARES_DATA_SETTINGS: 'SharesDataSettings',
  FIXED_COSTS: 'FixedCosts',
  LTI_PLANS: 'LTIPlans',
};

export default FEATURE_TOGGLE_KEYS;
