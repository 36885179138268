import * as R from 'ramda';
import { transformPdfTextContent } from 'modules/common/helpers';
import { TextOnly } from '../../../../../../common/Text';

export const getCategory = (category, isExtra) =>
  !isExtra
    ? category
    : {
        ...category,
        displayName: TextOnly({
          path: 'flexibleRewards.EXTRA',
          transform: (label = '') => label.replace('%categoryTitle%', `${category.displayName}`),
        }),
      };

export const transformPdfOutputTextContent = (rewards) => {
  return R.map((reward) => {
    return {
      ...reward,
      rewardName: transformPdfTextContent(reward.rewardName),
    };
  }, rewards);
};

export default getCategory;
