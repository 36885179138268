import { handleActions } from 'redux-actions';
import * as R from 'ramda';
import initialState from './initialState';
import {
  loadLocalPreparations,
  loadLocalPreparationsError,
  cancelEditable,
  resetLocalPreparation,
  validateDataForSubmit,
} from '../actions';
import { etagUpdate } from '../actions/helpers';

import executiveSummaryHandlers from '../components/executiveSummary/reducer';
import marketDataHandlers from '../components/marketData/reducer';
import populationHandlers from '../components/population/reducer';
import rewardsHandlers from '../components/rewards/reducer';

const localPreparation = handleActions(
  new Map([
    [resetLocalPreparation, () => R.clone(initialState)],
    [
      loadLocalPreparations,
      (state, action) => {
        const {
          payload: { data },
        } = action;

        return {
          ...state,
          executiveSummary: R.prop('executiveSummary', data),
          executiveSummarySnapshot: R.prop('executiveSummary', data),
          targetMarketPosition: R.prop('targetMarketPosition', data),
          marketData: R.prop('marketData', data),
          marketDataSnapshot: R.prop('marketData', data),
          benchmarkingId: R.prop('benchmarkingId', data),
          localId: R.prop('id', data),
          country: R.prop('countryCode', data),
          ageingDate: R.prop('ageingDate', data),
          populationsId: R.prop('populationsId', data),
          payScaleIntervals: R.prop('payScaleIntervals', data),
          etag: R.prop('etag', data),
          rewardIds: R.prop('rewardIds', data),
          exceptionsId: R.prop('exceptionsId', data),
          isDataValid: R.prop('isDataValid', data),
          isLoading: false,
        };
      },
    ],
    [
      loadLocalPreparationsError,
      state => ({
        ...state,
        isLoading: false,
      }),
    ],
    [
      validateDataForSubmit,
      (state, action) => ({
        ...state,
        isDataValid: action.payload,
      }),
    ],
    [
      cancelEditable,
      state => ({
        ...state,
        executiveSummary: state.executiveSummarySnapshot,
        marketData: state.marketDataSnapshot,
      }),
    ],
    [
      etagUpdate,
      (state, action) => ({
        ...state,
        etag: action.payload,
      }),
    ],
    ...executiveSummaryHandlers,
    ...marketDataHandlers,
    ...populationHandlers,
    ...rewardsHandlers,
  ]),
  R.clone(initialState)
);

export default localPreparation;
