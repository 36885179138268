import * as R from 'ramda';
import APP_CONFIG from '@trs/config';
import {
  upperFirstLetter,
  flattenObject,
  isBlank,
  getCountryCode,
  toLower,
  toBinary,
} from '@trs/utils';
import { parseScript } from 'esprima';
import { encodeValuesInRules } from 'modules/common/helpers';
import { getError } from '../../../../config/errors';
import { ACTIVE_TYPES } from '../../constants';

const { TEMPLATE_TYPES, GLOBAL_KEYWORD } = APP_CONFIG;

export const INVALID_ITEM_TYPE_ERROR_MESSAGE = 'Invalid Item Type';

const isComplex = (complex) =>
  !isBlank(R.path([ACTIVE_TYPES.fixed, 'contribution'], complex)) ||
  !isBlank(R.path([ACTIVE_TYPES.percentage, 'contribution'], complex));

export const isStepperWithData = ({ stepper }) =>
  !isBlank(R.path([ACTIVE_TYPES.fixed, 'steps'], stepper)) ||
  !isBlank(R.path([ACTIVE_TYPES.percentage, 'steps'], stepper));

export const checkIsTextOnly = R.compose(R.propEq('type', 'text-only'), R.head);

export const validateEligibilitySimpleRule = (ruleObject, rewardsLabels, genericLabels, other) =>
  R.mapObjIndexed((item, key) => {
    let keyLabel;
    switch (key) {
      case 'variable':
        keyLabel = R.prop('isLinkedReward', other)
          ? rewardsLabels.LINKED_REWARDS_VARIABLE_RULE_VARIABLE_NAME
          : rewardsLabels.ELIGIBILITY_RULE_VARIABLE_NAME;
        break;
      case 'operator':
        keyLabel = rewardsLabels.ELIGIBILITY_RULE_OPERATOR;
        break;
      case 'values':
        keyLabel = rewardsLabels.ELIGIBILITY_RULE_VALUE;
        break;
      default:
        keyLabel = key;
        break;
    }

    return getError(genericLabels.ERROR_REQUIRED, upperFirstLetter(keyLabel));
  }, R.filter(R.isEmpty, ruleObject));

export const getFormulaString = (formula) =>
  formula
    .map((item) => {
      if (item.type === 'variable' || item.type === 'numeric' || item.type === 'percentage') {
        return `"${item.value}"`;
      }

      if (item.type === 'function') {
        const firstArgumentFormula = getFormulaString(item.arguments[0]);
        const secondArgumentFormula = getFormulaString(item.arguments[1]);

        return `${item.value}(${firstArgumentFormula}, ${secondArgumentFormula})`;
      }

      return item.value;
    })
    .join('');

export const getFormulaErrors = (formula) => {
  const errors = {};
  const flattened = flattenObject(formula);
  R.forEach((item) => {
    if (item.indexOf('value') !== -1 && R.isEmpty(flattened[item])) {
      errors[item.replace('.value', '')] = true;
    }
  }, R.keys(flattened));

  return errors;
};

export const getRulesErrors = (rules, errors, rewardsLabels, genericLabels, other) => {
  const errorObject = errors;
  if (rules.eligibilityRows) {
    R.forEach(
      (rule) => getRulesErrors(rule, errorObject, rewardsLabels, genericLabels, other),
      rules.eligibilityRows
    );
  }

  if (!isBlank(R.prop('eligibilityRowsOperator', rules))) {
    return errorObject;
  }

  const ruleErrors = validateEligibilitySimpleRule(
    {
      variable: R.prop('variable', rules),
      operator: R.prop('operator', rules),
      values: R.prop('values', rules),
    },
    rewardsLabels,
    genericLabels,
    other
  );

  if (!R.isEmpty(ruleErrors)) {
    errorObject[rules.uid] = ruleErrors;
  }
  return errorObject;
};

export const syntaxValidateFormula = (formulaString, isTextOnly, rewardLabel) => {
  if (isTextOnly) {
    return R.isEmpty(formulaString)
      ? { generic: rewardLabel.RULES_BUILDER_INVALID_FORMULA_ERROR_LABEL }
      : {};
  }

  try {
    parseScript(formulaString);
    return {};
  } catch (error) {
    return { generic: rewardLabel.RULES_BUILDER_INVALID_FORMULA_ERROR_LABEL };
  }
};

export const validateFormula = ({ formula, isTextOnly, rewardLabel }) => {
  if (R.isEmpty(formula)) {
    return { generic: rewardLabel.RULES_BUILDER_EMPTY_FORMULA_ERROR_LABEL };
  }

  const formulaErrors = getFormulaErrors(formula);

  if (R.isEmpty(formulaErrors)) {
    return syntaxValidateFormula(getFormulaString(formula), isTextOnly, rewardLabel);
  }
  return formulaErrors;
};

export const validateThreshold = ({ threshold, rewardLabel }) => {
  const error = {
    thresholdRows: [],
  };

  const contributionFormulaErrors = validateFormula({
    formula: R.path(['contribution', 'formulaItems'], threshold),
    isTextOnly: false,
    rewardLabel,
  });

  if (!isBlank(contributionFormulaErrors)) {
    error.contribution = {
      formulaItems: contributionFormulaErrors,
    };
  }

  threshold.thresholdRows.forEach((item, index) => {
    error.thresholdRows[index] = {};

    if (item.isBetween) {
      if (!item.min) error.thresholdRows[index].min = rewardLabel.RULES_BUILDER_VARIABLE_MISSING;
      if (!item.max) error.thresholdRows[index].max = rewardLabel.RULES_BUILDER_VARIABLE_MISSING;
    } else if (
      (typeof item.max === 'undefined' && typeof item.min === 'undefined') ||
      (item.min && item.max)
    ) {
      return {};
    } else {
      if (!item.operator) {
        error.thresholdRows[index].operator = rewardLabel.RULES_BUILDER_OPERATOR_MISSING;
      }
      if (!item.member) {
        error.thresholdRows[index].member = rewardLabel.RULES_BUILDER_VARIABLE_MISSING;
      }
    }

    return {};
  });

  if (R.isEmpty(R.reject(R.isEmpty, error.thresholdRows))) delete error.thresholdRows;
  if (R.isEmpty(error)) return false;
  return error;
};

export const validateThresholds = ({ thresholds, rewardLabel }) => {
  const errors = {};
  if (!thresholds) return errors;
  thresholds.forEach((item, index) => {
    const error = validateThreshold({ threshold: item, rewardLabel });
    if (error) errors[index] = error;
  });

  return errors;
};

const mapAdditionalValues = (items) =>
  items.map((item) => {
    if (item.eligibilityRows) {
      return {
        ...item,
        eligibilityRows: mapAdditionalValues(item.eligibilityRows),
      };
    }

    if (item.variable)
      return {
        ...item,
        variable: `Reward.${item.variable}`,
      };
    return item;
  });

export const mapformula = (formula) =>
  formula.map((item) => {
    if (item !== undefined) {
      let value = '';
      if (!R.isEmpty(item)) {
        const type = R.prop('type', item);
        if (type === 'text-only') {
          value = toBinary(R.prop('value', item));
        } else {
          value = R.prop('value', item);
        }
      }
      return {
        ...item,
        value,
      };
    }
    return item;
  });

export const mapFormulaToRequestModel = (rules, countries = []) =>
  rules.map((rule, index) => {
    const country = R.path(['localOverride', 'country'], rule);
    const isComplexFormula = isComplex(R.prop('complex', rule));
    const { stepper } = rule;
    const isStepper = isStepperWithData(rule);

    const additionalFormulaItems = R.path(
      ['complex', R.path(['complex', 'activeType'], rule), 'additionalFormulaItems'],
      rule
    );

    const minimum = R.path(['complex', R.path(['complex', 'activeType'], rule), 'minimum'], rule);
    const maximum = R.path(['complex', R.path(['complex', 'activeType'], rule), 'maximum'], rule);
    const payload = {
      name: `rule-${index}${isBlank(country) ? '' : `-${country}`}`,
      formulaItems: isComplexFormula
        ? R.path(['complex', R.path(['complex', 'activeType'], rule), 'contribution'], rule)
        : mapformula(rule.formula),
    };

    const countryCode = R.equals(country, GLOBAL_KEYWORD)
      ? GLOBAL_KEYWORD
      : getCountryCode({ country, countries });

    if (!R.isEmpty(rule.eligibility)) {
      payload.eligibility = encodeValuesInRules(rule.eligibility);
    }

    if (!isBlank(R.prop('additionalRewardsEligibility', rule))) {
      payload.additionalRewardsEligibility = {
        ...rule.additionalRewardsEligibility,
        eligibilityRows: mapAdditionalValues(rule.additionalRewardsEligibility.eligibilityRows),
      };

      payload.additionalRewardsEligibility = encodeValuesInRules(
        payload.additionalRewardsEligibility
      );
    }

    if (isComplexFormula && R.length(additionalFormulaItems)) {
      payload.additionalFormulaItems = additionalFormulaItems;
    }

    if (!R.isEmpty(rule.threshold)) {
      payload.thresholds = rule.threshold;
    }
    if (isStepper) {
      payload.isStepper = true;
      payload.stepper = stepper;
    }

    if (!isBlank(country) && R.length(countries)) {
      payload.localOverride = {
        country,
        countryCode,
      };
    }

    if (!isBlank(minimum) || !isBlank(maximum)) {
      payload.outputInterval = {
        minimum,
        maximum,
      };
    }

    return payload;
  });

export const validateCountry = (country, genericLabel, rewardLabel) =>
  !R.length(country)
    ? getError(genericLabel.ERROR_REQUIRED, rewardLabel.BASIC_INFO_COUNTRY_LABEL)
    : null;

const validateComplex = (complex, rewardLabel) => {
  const tempObj = {
    [complex.activeType]: {
      contribution: isComplex(complex)
        ? validateFormula({
            formula: R.path([complex.activeType, 'contribution'], complex),
            isTextOnly: false,
            rewardLabel,
          })
        : {},
      minimum: R.length(R.path([complex.activeType, 'minimum'], complex))
        ? validateFormula({
            formula: R.path([complex.activeType, 'minimum'], complex),
            isTextOnly: false,
            rewardLabel,
          })
        : {},
      maximum: R.length(R.path([complex.activeType, 'maximum'], complex))
        ? validateFormula({
            formula: R.path([complex.activeType, 'maximum'], complex),
            isTextOnly: false,
            rewardLabel,
          })
        : {},
    },
  };
  if (complex.activeType === ACTIVE_TYPES.percentage) {
    tempObj[complex.activeType].additionalFormulaItems = isComplex(complex)
      ? validateFormula({
          formula: R.path([complex.activeType, 'additionalFormulaItems'], complex),
          isTextOnly: false,
          rewardLabel,
        })
      : {};
  }
  return tempObj;
};

const validateStepper = (stepper, rewardLabel) => {
  const errors = {
    [ACTIVE_TYPES.fixed]: [],
    [ACTIVE_TYPES.percentage]: [],
  };
  if (!isStepperWithData({ stepper })) return errors;

  if (stepper.activeType === ACTIVE_TYPES.fixed) {
    stepper[ACTIVE_TYPES.fixed].steps.forEach((item, index) => {
      if (item.label !== null && item.label.length === 0)
        errors[ACTIVE_TYPES.fixed][index] = R.assocPath(
          ['label'],
          rewardLabel.STEP_ERROR_LABEL_MESSAGE,
          errors[index]
        );

      if (item.value.length === 0)
        errors[ACTIVE_TYPES.fixed][index] = R.assocPath(
          ['value'],
          rewardLabel.STEP_ERROR_VALUE_MESSAGE,
          errors[index]
        );
    });
  }

  return errors;
};

export const validateRule = ({ rule, rewardLabel, genericLabel, template }) => {
  const errors = R.map(
    () => true,
    R.filter((item) => !item, rule)
  );
  const {
    eligibility,
    additionalRewardsEligibility,
    formula,
    threshold,
    localOverride,
    complex,
    stepper,
  } = rule;
  const isComplexFormula = isComplex(complex);
  const stepperHasData = isStepperWithData(rule);

  errors.eligibility = getRulesErrors(eligibility, {}, rewardLabel, genericLabel);
  errors.additionalRewardsEligibility = getRulesErrors(
    additionalRewardsEligibility,
    {},
    rewardLabel,
    genericLabel,
    { isLinkedReward: true }
  );

  errors.formula = isComplexFormula
    ? []
    : validateFormula({
        formula,
        isTextOnly: R.isEmpty(formula) ? false : checkIsTextOnly(formula),
        rewardLabel,
      });

  errors.complex = validateComplex(complex, rewardLabel);
  errors.stepper = validateStepper(stepper, rewardLabel);
  if (stepperHasData) errors.formula = [];

  if (toLower(template) === TEMPLATE_TYPES.pension) {
    errors.threshold = validateThresholds({ thresholds: threshold, rewardLabel });
  }

  if (toLower(template) === TEMPLATE_TYPES.share) {
    errors.localOverride = validateCountry(
      R.prop('country', localOverride),
      genericLabel,
      rewardLabel
    );
  }

  if (
    !R.isEmpty(errors.eligibility) ||
    !R.isEmpty(errors.additionalRewardsEligibility) ||
    !R.isEmpty(R.filter((item) => !R.isEmpty(item), errors.stepper)) ||
    (!isComplexFormula && !R.isEmpty(errors.formula) && !stepperHasData) ||
    (isComplexFormula &&
      (!isBlank(R.path(['complex', complex.activeType, 'contribution'], errors)) ||
        !isBlank(R.path(['complex', complex.activeType, 'additionalFormulaItems'], errors)))) ||
    !R.isEmpty(R.path(['complex', complex.activeType, 'minimum'], errors)) ||
    !R.isEmpty(R.path(['complex', complex.activeType, 'maximum'], errors)) ||
    (toLower(template) === TEMPLATE_TYPES.pension && !R.isEmpty(errors.threshold)) ||
    (toLower(template) === TEMPLATE_TYPES.share && !R.isNil(errors.localOverride))
  ) {
    return errors;
  }
  return {};
};

export default {
  mapFormulaToRequestModel,
  validateEligibilitySimpleRule,
  validateRule,
};
