import { combineReducers } from 'redux';
import user from './userReducer';
import lookup from './lookupReducer';
import inactivityTimeout from './inactivityTimeoutReducer';
import employeeStatement from '../modules/employeeStatement/reducer';
import employeeCategoryDetails from '../modules/employeeStatement/reducer/details';
import exceptions from '../modules/exceptions/reducer';
import userManagement from '../modules/userManagement/reducer';
import roles from '../modules/userManagement/reducer/roles';
import users from '../modules/userManagement/reducer/users';
import exceptionsList from '../modules/exceptions/reducer/exceptionsList';
import global from './globalReducer';
import enhancedDataTable from './enhancedDataTableReducer';
import modeller from '../modules/modeller/live/reducer';
import modellerBeta from '../modules/modeller/beta/reducer';
import flexibleRewards from '../modules/flexibleRewards/reducer';
import variables from '../modules/variables/reducer';
import articles from '../modules/articles/reducer';
import rewards from '../modules/rewards/reducer';
import cms from '../modules/cms/reducer';
import foreignExchange from '../modules/foreignExchange/reducer';
import rmDashboard from '../modules/rmDashboard/reducer';
import onboarding from '../modules/onboarding/reducer';
import customisation from '../modules/customisation/reducer';
import notifications from '../modules/notifications/reducer';
import feedback from '../modules/feedback/reducer';
import workspace from '../modules/workspace/reducer';
import benchmarking from '../modules/benchmarking/reducer';
import configuration from './configurationReducer';
import reports from '../modules/flexible-reports/reducer';
import benefitsEnvelope from '../modules/benefitsEnvelope/reducer';
import benefitsEnvelopeAudit from '../modules/benefitsEnvelopeAudit/reducer';
import packageDetails from '../modules/flexibleRewards/components/submissionPage/reducer';
import fixedCosts from '../modules/fixedCosts/reducer';
import ltiPlans from '../modules/ltiPlans/reducer';

/* I'm adding this temporarily as I will be splitting the rewards reducer and incorporating this one there.
 for now i want it to be as decoupled as possible
 */
import versioning from '../modules/rewards/components/versioning/reducer';
import overallImpact from '../modules/rewards/components/overallImpact/reducer';
import permissions from '../modules/userManagement/components/permissions/reducer';

const createReducer = (asyncReducers) =>
  combineReducers({
    versioning,
    overallImpact,
    variables,
    articles,
    rewards,
    user,
    userManagement,
    permissions,
    roles,
    users,
    employeeStatement,
    employeeCategoryDetails,
    foreignExchange,
    exceptions,
    cms,
    lookup,
    exceptionsList,
    global,
    modeller,
    modellerBeta,
    flexibleRewards,
    enhancedDataTable,
    rmDashboard,
    onboarding,
    customisation,
    notifications,
    feedback,
    workspace,
    benchmarking,
    configuration,
    reports,
    benefitsEnvelope,
    benefitsEnvelopeAudit,
    packageDetails,
    inactivityTimeout,
    fixedCosts,
    ltiPlans,
    ...asyncReducers,
  });

export default createReducer;
