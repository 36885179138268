import * as R from 'ramda';
import { createAction } from 'redux-actions';
import { isBlank, offsetTimezone, rejectEmptyValues } from '@trs/utils';
import { RequestFactory, GET, PUT } from '../../../../../config/http';
import { benchmark, foreignExchange, organization } from '../../../../../config/api';
import { showBanner } from '../../../../../actions/globalActions';
import { TextOnly } from '../../../../common/Text';
import {
  getRequestPayload,
  getGlobalRequestPayload,
  getGlobalSettingsErrors,
  updateBenchmarkingError,
} from './helpers';
import { withCache } from '../../../../common/timedCache';
import { dispatchError } from '../../common/helpers';

import {
  loadSystemVariables,
  loadEligibilityOperators,
  savePopulation,
  loadPopulationsById,
  loadPayScaleIntervals,
  updatePopulationError,
} from '../../common/benchmarkPopulation/actions';

const cachedGet = withCache(GET, 3600);

export const actionTypes = {
  BENCHMARKING_SAVE_PENDING: 'BENCHMARKING/SAVE/PENDING',
  BENCHMARKING_SAVE_SUCCESS: 'BENCHMARKING/SAVE/SUCCESS',
  BENCHMARKING_SAVE_ERROR: 'BENCHMARKING/SAVE/ERROR',
  BENCHMARKING_ERROR: 'BENCHMARKING/ERROR',
  BENCHMARKING_GET_SETTINGS: 'BENCHMARKING/GET/SETTINGS',
  BENCHMARKING_FX_SOURCES_PENDING: 'BENCHMARKING_FX_SOURCES_PENDING',
  BENCHMARKING_FX_SOURCES_ERROR: 'BENCHMARKING_FX_SOURCES_ERROR',
  BENCHMARKING_FX_SOURCES_SUCCESS: 'BENCHMARKING_FX_SOURCES_SUCCESS',
  BENCHMARKING_UPDATE_FIELD: 'BENCHMARKING_UPDATE_FIELD',
  BENCHMARKING_DEPENDENCIES_PENDING: 'BENCHMARKING_DEPENDENCIES_PENDING',
  BENCHMARKING_DEPENDENCIES_ERROR: 'BENCHMARKING_DEPENDENCIES_ERROR',
  BENCHMARKING_DEPENDENCIES_SUCCESS: 'BENCHMARKING_DEPENDENCIES_SUCCESS',
  BENCHMARKING_COUNTRIES_UPDATE: 'BENCHMARKING/COUNTRIES/UPDATE',
  BENCHMARKING_COUNTRIES_LOAD: 'BENCHMARKING/COUNTRIES/LOAD',
  BENCHMARKING_COUNTRIES_FX_RATE: 'BENCHMARKING/COUNTRIES/FX_RATE',
  BENCHMARKING_RELOAD_FX_PENDING: 'BENCHMARKING/COUNTRIES/RELOAD/PENDING',
  BENCHMARKING_RELOAD_FX_SUCCESS: 'BENCHMARKING/COUNTRIES/RELOAD/SUCCESS',
  BENCHMARKING_RELOAD_FX_ERROR: 'BENCHMARKING/COUNTRIES/RELOAD/ERROR',
  BENCHMARKING_GLOBAL_SUBMITTED: 'BENCHMARKING/GLOBAL/SUBMITTED',
  BENCHMARKING_GLOBAL_SUBMIT_PENDING: 'BENCHMARKING/GLOBAL/SUBMIT/PENDING',
  BENCHMARKING_GLOBAL_IDENTIFIERS: 'BENCHMARKING/GLOBAL/IDENTIFIERS',
};

export const loadDependenciesPending = createAction(actionTypes.BENCHMARKING_DEPENDENCIES_PENDING);
export const loadDependenciesSuccess = createAction(actionTypes.BENCHMARKING_DEPENDENCIES_SUCCESS);
export const loadDependenciesError = createAction(actionTypes.BENCHMARKING_DEPENDENCIES_ERROR);

export const saveBenchmarkingPending = createAction(actionTypes.BENCHMARKING_SAVE_PENDING);
export const saveBenchmarkingSuccess = createAction(actionTypes.BENCHMARKING_SAVE_SUCCESS);
export const saveBenchmarkingError = createAction(actionTypes.BENCHMARKING_SAVE_ERROR);

export const getBenchmarkingSettings = createAction(actionTypes.BENCHMARKING_GET_SETTINGS);
export const FxSourcesSuccess = createAction(actionTypes.BENCHMARKING_FX_SOURCES_SUCCESS);
export const updateField = createAction(actionTypes.BENCHMARKING_UPDATE_FIELD);
export const updateCountriesSelection = createAction(actionTypes.BENCHMARKING_COUNTRIES_UPDATE);
export const loadCountriesSuccess = createAction(actionTypes.BENCHMARKING_COUNTRIES_LOAD);
export const submitBenchmarkingPending = createAction(
  actionTypes.BENCHMARKING_GLOBAL_SUBMIT_PENDING
);
export const submittedGlobalSettings = createAction(actionTypes.BENCHMARKING_GLOBAL_SUBMITTED);

export const reloadFxSuccess = createAction(actionTypes.BENCHMARKING_RELOAD_FX_SUCCESS);
export const reloadFxPending = createAction(actionTypes.BENCHMARKING_RELOAD_FX_PENDING);
export const reloadFxError = createAction(actionTypes.BENCHMARKING_RELOAD_FX_ERROR);

export const getGlobalIdentifiers = createAction(actionTypes.BENCHMARKING_GLOBAL_IDENTIFIERS);

export const loadClusteredCountries = () => (dispatch) =>
  GET(`${benchmark.globalSettings}/countries`, { Pragma: 'no-cache' }).then((body) => {
    dispatch(loadCountriesSuccess(R.path(['data', 'response'], body)));
  });

export const reloadCountryFx = () => (dispatch, getState) => {
  const benchmarkingMain = R.path(['benchmarking', 'global', 'main'], getState());
  const { fxSourceId, fxRatesDate } = benchmarkingMain;
  if (isBlank(fxSourceId) || isBlank(fxRatesDate)) return false;

  dispatch(reloadFxPending());
  return cachedGet(
    `${benchmark.globalSettings}/countries?fxSourceId=${fxSourceId}&fxRatesDate=${offsetTimezone(
      fxRatesDate
    )}`,
    { Pragma: 'no-cache' }
  )
    .then((body) => {
      dispatch(reloadFxSuccess(R.path(['data', 'response'], body)));
    })
    .catch(() => {
      dispatch(reloadFxError());
    });
};

export const update =
  ({ field, data }) =>
  (dispatch) => {
    dispatch(updateField({ field, data }));

    dispatch(updateBenchmarkingError({ path: ['referenceData', field], data: null }));
  };

const saveBenchmarking =
  ({ method, url, headers, data, displaySaveBanner }) =>
  (dispatch) =>
    RequestFactory(method, url, headers, data)
      .then((body) => {
        dispatch(
          saveBenchmarkingSuccess({
            etag: R.path(['headers', 'etag'], body),
            id: R.path(['data', 'response', 'id'], body),
          })
        );
        if (displaySaveBanner) {
          dispatch(
            showBanner({
              type: 'success',
              content: TextOnly({ path: 'benchmarking.SAVE_SUCCESS' }),
            })
          );
        }
      })
      .catch((err) => {
        dispatch(updateBenchmarkingError({ path: ['global'], value: err }));
        dispatchError(err);
        return Promise.reject(err);
      });

export const save =
  (displaySaveBanner = true) =>
  (dispatch, getState) => {
    const state = getState();
    const main = R.path(['benchmarking', 'global', 'main'], state);
    const population = R.path(['benchmarking', 'population'], state);
    const settingsId = R.path(['benchmarking', 'global', 'main', 'id'], state);
    const settingsEtag = R.path(['benchmarking', 'global', 'main', 'etag'], state);

    const opts = {
      url: `${benchmark.globalSettings}/${settingsId || ''}`,
      method: settingsId ? 'PUT' : 'POST',
      headers: settingsId ? { Etag: settingsEtag } : {},
      data: getRequestPayload(main),
    };
    const errors = getGlobalSettingsErrors({ population, main });
    if (
      !isBlank(rejectEmptyValues(R.values(errors.population))) ||
      !isBlank(errors.referenceData) ||
      !isBlank(errors.countryDetails) ||
      !isBlank(errors.payScaleIntervals)
    ) {
      dispatch(updatePopulationError({ path: ['population'], data: errors.population }));
      dispatch(updateBenchmarkingError({ path: ['referenceData'], data: errors.referenceData }));
      dispatch(updateBenchmarkingError({ path: ['countryDetails'], data: errors.countryDetails }));
      dispatch(
        updatePopulationError({ path: ['payScaleIntervals'], data: errors.payScaleIntervals })
      );

      dispatch(
        showBanner({
          type: 'error',
          content: TextOnly({ path: 'generic.BANNER_FORM_ERROR_GENERIC' }),
        })
      );
      return Promise.reject();
    }

    dispatch(saveBenchmarkingPending());

    return dispatch(savePopulation())
      .then((body) => {
        return dispatch(
          saveBenchmarking({
            ...opts,
            data: getGlobalRequestPayload({
              populationsId: R.path(['data', 'response', 'id'], body),
              population,
              main,
            }),
            displaySaveBanner,
          })
        );
      })
      .catch((err) => {
        dispatch(saveBenchmarkingError(err));
        return dispatch(
          showBanner({
            type: 'error',
            content: TextOnly({ path: 'benchmarking.SAVE_ERROR' }),
          })
        );
      });
  };

export const submitGlobalSettings = () => (dispatch, getState) => {
  const state = getState();
  const settingsId = R.path(['benchmarking', 'global', 'main', 'id'], state);
  const settingsEtag = R.path(['benchmarking', 'global', 'main', 'etag'], state);
  dispatch(submitBenchmarkingPending());

  PUT(`${benchmark.globalSettings}/${settingsId}/status`, null, {
    ETag: settingsEtag,
  }).then(() => {
    dispatch(submittedGlobalSettings());
    dispatch(
      showBanner({
        type: 'success',
        content: TextOnly({ path: 'benchmarking.SUBMIT_GLOBAL_SUCCESS' }),
      })
    );
  });
};

export const submit = () => (dispatch) =>
  dispatch(save(false)).then(() => dispatch(submitGlobalSettings()));

export const loadGlobalIdentifiers = (noCache) => (dispatch) => {
  return cachedGet(organization.lookupCountries).then((countriesBody) => {
    const countries = R.prop('data', countriesBody);
    return (noCache ? GET : cachedGet)(benchmark.identifiers).then((body) => {
      const id = R.path(['data', 'response', 'id'], body);
      const populationsId = R.path(['data', 'response', 'populationsId'], body);
      const payload = { id, populationsId, countries };
      dispatch(getGlobalIdentifiers(payload));
      return payload;
    });
  });
};

export const loadGlobalBenchmarking = (noCache) => (dispatch) => {
  return (noCache ? GET : cachedGet)(benchmark.globalCurrent).then((response) => {
    // these should be moved to transformer
    const populationsId = R.path(['data', 'response', 'populationsId'], response);
    const id = R.path(['data', 'response', 'id'], response);
    const referenceData = R.path(['data', 'response', 'referenceData'], response) || {};
    const etag = R.path(['headers', 'etag'], response);
    const countries = R.path(['data', 'response', 'countries'], response);
    const payScaleIntervals = R.path(['data', 'response', 'payScaleIntervals'], response) || {};
    const status = R.path(['data', 'response', 'status'], response);

    dispatch(
      getBenchmarkingSettings({
        etag,
        ...referenceData,
        populationsId,
        id,
        countries,
        payScaleIntervals,
        status,
      })
    );
    return { populationsId, payScaleIntervals, id, countries };
  });
};

export const loadBenchmarking = () => (dispatch) => {
  return dispatch(loadGlobalBenchmarking(true)).then((response) => {
    const { populationsId, payScaleIntervals, id } = response;
    if (isBlank(populationsId) || isBlank(id)) return dispatch(loadClusteredCountries());
    dispatch(reloadCountryFx());
    dispatch(loadPayScaleIntervals(payScaleIntervals));
    return dispatch(loadPopulationsById(populationsId));
  });
};

export const loadFxSources = () => (dispatch) => {
  return cachedGet(foreignExchange.fxSources).then((response) =>
    dispatch(FxSourcesSuccess(R.prop('data', response)))
  );
};

export const loadDependencies = () => (dispatch) => {
  const dependencyList = [];
  dependencyList.push(dispatch(loadBenchmarking()));
  dependencyList.push(dispatch(loadSystemVariables()));
  dependencyList.push(dispatch(loadEligibilityOperators()));
  dependencyList.push(dispatch(loadFxSources()));

  dispatch(loadDependenciesPending());

  return Promise.all(dependencyList)
    .then(() => dispatch(loadDependenciesSuccess()))
    .catch((body) => {
      const err = R.path(['response', 'data', 'errors'], body);
      dispatch(loadDependenciesError());
      return Promise.reject(err);
    });
};
