import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { Col, Container, Row } from 'react-grid-system';
import SanitizedHTML from 'react-sanitized';
import { Paragraph, TitleWithButton, RichTextarea } from '@trs/components';
import { isBlank, processDate } from '@trs/utils';
import { transformPdfOutputTextContent } from './helpers';
import RewardStatusBanner from '../../../../../rewards/components/rewardStatusBanner';

import Text from '../../../../../common/Text';
import StatementSides from '../statementSides';
import RemainingBudget from '../remainingBudget';
import AvailableOptions from '../availableOptions';
import PdfExport from '../../../../../common/PdfExport';
import './flexiblePackageExport.css';
import { getTopBannerData } from '../helpers';

const FlexiblePackageExport = ({
  showOnlyNewStatement,
  currentStatementDate,
  bannerDetails,
  isCheckedAgreement,
  user,
  isPdfOutput,
}) => {
  const store = window.GLOBAL_STORE;
  const {
    flexibleRewards: {
      employeeFlexibleRewards: {
        data,
        selectedRewards,
        availableOptions,
        isComputingStatement,
        isSaving,
        metaData,
      },
    },
    cms: { theme, pdfLogo },
  } = store.getState();
  const remainingBudget = R.prop('remainingBudget', metaData);
  const excessBudget = R.prop('excessBudget', metaData);
  const remainingBudgetLabel = R.prop('remainingBudgetLabel', metaData);
  const budgetLabel = R.prop('budgetLabel', metaData);
  const { profile } = user;
  const headerTextColor = R.path(['data', 'components', 'header', 'text'], theme);

  const bannerDetailsTitle = R.prop('title', bannerDetails);
  const title =
    bannerDetailsTitle.length > 67
      ? `${bannerDetailsTitle.substring(0, 67)}...`
      : bannerDetailsTitle;

  const transformedSelectedRewards = isPdfOutput
    ? transformPdfOutputTextContent(selectedRewards)
    : selectedRewards;

  return (
    <PdfExport theme={theme}>
      <div className="flexible-package-export">
        <header>
          <div
            className="header-with-date"
            style={{
              color: R.path(['data', 'components', 'header', 'text'], theme),
              backgroundColor: R.path(['data', 'components', 'header', 'background'], theme),
            }}
          >
            <div className="logo-with-text">
              <img alt="application logo" src={pdfLogo} />
              <span style={{ borderColor: headerTextColor }}>
                <Text path="generic.LOGO" withPlatform />
              </span>
            </div>
            <span>
              {R.path(['firstName'], profile) || ''} {R.path(['lastName'], profile) || ''}
            </span>
            <Text
              path={
                currentStatementDate
                  ? 'flexibleRewards.EXPORT_SUBMISSION_DATE'
                  : 'flexibleRewards.EXPORT_STATEMENT_DATE'
              }
              transform={(label) =>
                label.replace(
                  '%DATE%',
                  processDate(currentStatementDate) || processDate(new Date())
                )
              }
            />
          </div>
        </header>
        <RewardStatusBanner className="status-banner__info">
          {getTopBannerData(user, R.path(['enrollmentWindow', 'endDate'], bannerDetails))}
        </RewardStatusBanner>
        <TitleWithButton
          className="sides-page-title"
          title={title}
          paragraph={<SanitizedHTML html={R.prop('message', bannerDetails)} />}
          wrapTitle
        />
        <Container fluid style={{ padding: 0 }}>
          <Row className="sides-main-view">
            <Col sm={12} md={12} xl={8}>
              <StatementSides
                disableAnimation
                isComputingStatement={isComputingStatement}
                data={data}
                hasAvailableOptions={!isBlank(availableOptions)}
                newStatementDate={R.prop('newStatementDate', bannerDetails)}
                currentStatementDate={currentStatementDate}
                showOnlyNewStatement={showOnlyNewStatement}
                budgetLabel={budgetLabel}
                isPdfOutput
              />
            </Col>
            <Col sm={12} md={12} xl={4}>
              {!isBlank(remainingBudget) && (
                <RemainingBudget
                  excessBudget={excessBudget}
                  amount={remainingBudget}
                  code={R.prop('currencyCode', metaData)}
                  title={remainingBudgetLabel}
                />
              )}
              <AvailableOptions
                disabled={isComputingStatement || isSaving}
                availableOptions={availableOptions}
                changeSelectedRewards={() => {}}
                selectedRewards={transformedSelectedRewards}
                computeStatement={() => {}}
                toggleIsEditing={() => {}}
                budgetLabel={budgetLabel}
                hasDecimals
              />
            </Col>
          </Row>
        </Container>
        {R.prop('disclaimer', bannerDetails) && (
          <Paragraph className="disclaimer">
            <strong id="pdfFlexDisclaimer">
              <Text path="flexibleRewards.DISCLAIMER" />
            </strong>
            <RichTextarea
              value={R.prop('disclaimer', bannerDetails)}
              readOnly
              ariaDescribedBy="pdfFlexDisclaimer"
            />
          </Paragraph>
        )}

        {bannerDetails.agreement && (
          <div className="agreementWrapper">
            <input type="checkbox" id="scales" name="scales" checked={isCheckedAgreement} />
            <RichTextarea
              className="agreementMessage"
              value={R.prop('agreement', bannerDetails)}
              ariaDescribedBy="agreement"
              readOnly
            />
          </div>
        )}
      </div>
    </PdfExport>
  );
};

FlexiblePackageExport.defaultProps = {
  currentStatementDate: '',
  isPdfOutput: false,
};

FlexiblePackageExport.propTypes = {
  showOnlyNewStatement: PropTypes.bool.isRequired,
  bannerDetails: PropTypes.shape({
    agreement: PropTypes.string,
  }).isRequired,
  isCheckedAgreement: PropTypes.bool.isRequired,
  currentStatementDate: PropTypes.string,
  user: {
    personalDetailsSetting: PropTypes.arrayOf(PropTypes.shape({})),
  }.isRequired,
  isPdfOutput: PropTypes.bool,
};

export default FlexiblePackageExport;
