import styled, { css } from 'styled-components';
import { media, toLower } from '@trs/utils';
import { FILTER_TYPES } from '../../../../routes/utils';

const StyledSideActions = styled.div`
  ${props => {
    const {
      packageType,
      theme: { spacing },
    } = props;

    return css`
      &.statement-actions {
        button {
          flex: auto;
          padding: ${spacing.tiny} ${spacing.base};
          margin-bottom: ${spacing.tiny};

          ${media.mobileLandscape`
            margin-bottom: 0;
          `}

          ${media.desktop`
            padding: ${spacing.xxSmall} ${spacing.base};
            margin-bottom: 0;
          `}
        }

        div {
          margin: 0;
        }

      .snapshot-pdf {
        display: flex;
        flex: auto;
        ${media.mobileLandscape`
          :not(:only-child) {
            margin-right: ${spacing.tiny};
          }
        `}
      }

      > div > div:last-child {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;

        .snapshot-pdf {
          flex: none;
          flex-basis: 100%;
          justify-content: center;
        }

        > button {
          flex: none;
          margin-bottom: 0;
          flex-basis: ${toLower(packageType) === FILTER_TYPES.SUBMISSION ? '48%' : '100%'};
        }

        ${media.mobile`
          > button {
            flex: auto;
            flex-basis: auto;
            margin-bottom: 0;
            margin-left: 0;
          }
        `}

        ${media.mobileLandscape`
          .snapshot-pdf {
            flex: auto;
            flex-basis: auto;
            justify-content: flex-start;
          }

          > button {
            flex: auto;
            flex-basis: auto;
            margin-bottom: 0;
          }
        `}
    `;
  }}
`;

StyledSideActions.displayName = 'StyledSideActions';
export default StyledSideActions;
