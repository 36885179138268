import { createAction } from 'redux-actions';
import * as R from 'ramda';
import { isBlank, getErrorMessage, Log } from '@trs/utils';
import { rewards } from 'config/api';
import { TextOnly } from 'modules/common/Text';
import { GET, POST, RequestFactory } from 'config/http';
import { showBanner } from '../../../../../actions/globalActions';
import globalErrors from '../../../../cms/labels/errors';
import {
  getTransformedSavePayload,
  getMimicContextHeader,
  reworkAvailableOptions,
  reworkRewards,
  transformSelectedRewardsData,
} from './helpers';
import actionTypes from './types';

const getProfileId = R.path(['profile', 'id']);

export const fetchOptionsPending = createAction(actionTypes.FETCH_OPTIONS_PENDING);
export const fetchOptionsSuccess = createAction(
  actionTypes.FETCH_OPTIONS_SUCCESS,
  (data) => data.payload,
  (data) => data.categories
);
export const fetchOptionsError = createAction(actionTypes.FETCH_OPTIONS_ERROR);

export const changeSelectedRewards = createAction(actionTypes.REWARDS_SELECTION_CHANGE);
export const changeAgreementTos = createAction(actionTypes.REWARDS_AGREEMENT_TOS_CHANGE);
export const computeStatementPending = createAction(actionTypes.COMPUTE_STATEMENT_PENDING);
export const computeStatementError = createAction(actionTypes.COMPUTE_STATEMENT_ERROR);
export const computeStatementSuccess = createAction(actionTypes.COMPUTE_STATEMENT_SUCCESS);
export const saveStatementPending = createAction(actionTypes.SAVE_STATEMENT_PENDING);
export const saveStatementError = createAction(actionTypes.SAVE_STATEMENT_ERROR);
export const saveStatementSuccess = createAction(actionTypes.SAVE_STATEMENT_SUCCESS);
export const updateEtag = createAction(actionTypes.UPDATE_ETAG);
export const sbsReset = createAction(actionTypes.SBS_RESET);
export const submitStatement = createAction(actionTypes.SUBMIT_STATEMENT);
export const submitCommentsChange = createAction(actionTypes.SUBMIT_COMMENTS_CHANGE);
export const initiateStatement = createAction(actionTypes.INITIATE_STATEMENT);
export const updateAvailableOptions = createAction(actionTypes.UPDATE_AVAILABLE_OPTIONS);

function dispatchError(error) {
  return showBanner({
    type: 'error',
    content: getErrorMessage(error),
  });
}

const mapComputePayload = ({
  currentData,
  availableOptionsMap,
  payload,
  categories,
  isCurrentHidden,
}) => ({
  currentData,
  current: !isCurrentHidden && currentData.rewards ? R.prop('rewards', currentData) : [],
  future: isBlank(R.prop('rewards', payload))
    ? []
    : R.map(
        (item) => ({
          ...item,
          shouldHighlight: R.path(
            [item.rewardId, 0, 'flexOptions', 'isIncludedInBudget'],
            availableOptionsMap
          ),
        }),
        R.prop('rewards', payload)
      ),
  metaData: R.prop('metaData', payload) || [],
  accessFlags: R.prop('modellerAccessFlags', payload) || {},
  canSubmit: R.prop('canSubmit', payload),
  hasSubmitted: R.prop('hasSubmitted', payload),
  currencies: [
    R.path(['metaData', 'currencyCode'], currentData),
    R.path(['metaData', 'currencyCode'], payload),
  ],
  categories,
});

export function computeStatement(packageId) {
  return (dispatch, getState) => {
    const { flexibleRewards, user } = getState();
    const isMimic = R.path(['mimic', 'isMimicView'], user);
    const categories = R.path(['main', 'categories'], flexibleRewards);

    const selectedRewards = R.path(['employeeFlexibleRewards', 'selectedRewards'], flexibleRewards);
    const currentData = R.path(['employeeFlexibleRewards', 'currentData'], flexibleRewards);
    const postUrl = `${rewards.employeeFlex}/${getProfileId(user)}/calculate/${packageId}`;
    const contextHeader = getMimicContextHeader(isMimic);

    const currentPackage = R.find((item) => item.packageId === packageId)(
      R.path(['enrollmentCard', 'employeePackages'], flexibleRewards)
    );

    const isCurrentHidden = R.prop('isFutureStatementOnly', currentPackage);

    if (isBlank(packageId)) {
      return dispatch(computeStatementError());
    }

    const employeeFlexibleRewardId = R.path(
      ['employeeFlexibleRewards', 'employeeFlexibleRewardId'],
      flexibleRewards
    );
    const data = {
      agreedTos: R.path(['employeeFlexibleRewards', 'metaData', 'agreedTos'], flexibleRewards),
      saveOptionsId: employeeFlexibleRewardId,
      rewardOptionsRequest: {
        selectedOptionsModels: transformSelectedRewardsData(selectedRewards),
      },
    };

    dispatch(computeStatementPending());

    return POST(postUrl, data, contextHeader)
      .then((body) => {
        const futureData = R.path(['data', 'response'], body);
        let availableOptionsPayload = R.path(['data', 'response', 'availableOptions'], body);
        availableOptionsPayload = reworkAvailableOptions(availableOptionsPayload);
        const canSubmit = R.path(['data', 'response', 'canSubmit'], body);

        dispatch(
          computeStatementSuccess(
            mapComputePayload({
              currentData,
              availableOptionsMap: R.groupBy(R.prop('id'), availableOptionsPayload),
              payload: futureData,
              categories,
              isCurrentHidden,
            })
          )
        );
        dispatch(
          fetchOptionsSuccess({
            payload: availableOptionsPayload,
            categories,
            canSubmit,
          })
        );
      })
      .catch((err) => {
        Log.warn(err);
        dispatch(computeStatementError(err));
        dispatch(
          dispatchError({ message: TextOnly({ path: 'flexibleRewards.CALCULATION_ERROR' }) })
        );
        return Promise.reject(err);
      });
  };
}

export function initiateCalculations(packageId) {
  return (dispatch, getState) => {
    const { flexibleRewards, user } = getState();

    const currentPackage = R.find((item) => item.packageId === packageId)(
      R.path(['enrollmentCard', 'employeePackages'], flexibleRewards)
    );

    const isCurrentHidden = R.prop('isFutureStatementOnly', currentPackage);
    const isMimic = R.path(['mimic', 'isMimicView'], user);
    const contextHeader = getMimicContextHeader(isMimic);
    const currentStatementUrl = `${rewards.employeeFlex}/${getProfileId(
      user
    )}/current/${packageId}`;
    const categories = R.path(['main', 'categories'], flexibleRewards);
    const initiateUrl = `${rewards.employeeFlex}/${getProfileId(user)}/initiate/${packageId}`;

    if (isBlank(packageId)) {
      return dispatch(fetchOptionsError());
    }
    dispatch(fetchOptionsPending());

    const initiatePromise = GET(initiateUrl, {
      ...contextHeader,
      Pragma: 'no-cache',
    });

    const currentStatementPromise = isCurrentHidden
      ? ''
      : GET(currentStatementUrl, {
          ...contextHeader,
          Pragma: 'no-cache',
        });

    const displayRewards = (promiseResponse) => {
      const payload = R.path([0, 'data', 'response'], promiseResponse);
      const currentData = R.path([isCurrentHidden ? 0 : 1, 'data', 'response'], promiseResponse);
      payload.rewards = reworkRewards(payload.rewards);
      payload.availableOptions = reworkAvailableOptions(payload.availableOptions);
      dispatch(
        computeStatementSuccess(
          mapComputePayload({
            currentData,
            availableOptionsMap: R.groupBy(R.prop('id'), R.prop('availableOptions', payload)),
            canSubmit: R.prop('canSubmit', payload),
            hasSubmitted: R.prop('hasSubmitted', payload),
            payload,
            categories,
          })
        )
      );
      dispatch(
        fetchOptionsSuccess({
          payload: R.prop('availableOptions', payload),
          categories,
        })
      );
      dispatch(updateEtag(R.path([0, 'headers', 'etag'], promiseResponse)));
      dispatch(initiateStatement(payload));
    };
    return initiatePromise
      .then((data) => {
        return isCurrentHidden
          ? displayRewards([data])
          : currentStatementPromise.then((body) => {
              displayRewards([data, body]);
            });
      })
      .catch((error) => {
        dispatchError(error);
        dispatch(fetchOptionsError());
      });
  };
}

export function resetToDefault(packageId) {
  return (dispatch, getState) => {
    const { user, flexibleRewards } = getState();
    const isMimic = R.path(['mimic', 'isMimicView'], user);

    const savedEtag = R.path(['employeeFlexibleRewards', 'savedEtag'], flexibleRewards);
    const contextHeader = getMimicContextHeader(isMimic);
    const opts = {
      url: `${rewards.employeeFlexibleRewards}/${getProfileId(user)}/${packageId}`,
      method: 'DELETE',
      headers: { Etag: savedEtag, ...contextHeader },
    };
    dispatch(saveStatementPending());
    return RequestFactory(opts.method, opts.url, opts.headers, opts.data)
      .then((response) => {
        dispatch(saveStatementSuccess());
        dispatch(updateEtag(R.path(['headers', 'etag'], response)));
        dispatch(
          showBanner({
            type: 'success',
            content: TextOnly({ path: 'flexibleRewards.RESET_OPTIONS_SUCCESS' }),
          })
        );
        return dispatch(initiateCalculations());
      })
      .catch((err) => {
        dispatch(saveStatementError(err));
        dispatchError(err);
        return Promise.reject(err);
      });
  };
}

export function saveSelectedOptions(packageId) {
  return (dispatch, getState) => {
    const { user, flexibleRewards } = getState();
    const selectedOptions = R.path(['employeeFlexibleRewards', 'selectedRewards'], flexibleRewards);
    const employeeFlexibleRewardId = R.path(
      ['employeeFlexibleRewards', 'employeeFlexibleRewardId'],
      flexibleRewards
    );
    const savedEtag = R.path(['employeeFlexibleRewards', 'savedEtag'], flexibleRewards);
    const payload = getTransformedSavePayload(selectedOptions, getProfileId(user), packageId);
    const hasFlexId = !isBlank(employeeFlexibleRewardId);
    const isMimic = R.path(['mimic', 'isMimicView'], user);
    const contextHeader = getMimicContextHeader(isMimic);

    const opts = {
      url: `${rewards.employeeFlexibleRewards}/${hasFlexId ? employeeFlexibleRewardId : ''}`,
      method: hasFlexId ? 'PUT' : 'POST',
      headers: hasFlexId ? { Etag: savedEtag, ...contextHeader } : contextHeader,
      data: {
        ...payload,
        agreedTos: R.path(['employeeFlexibleRewards', 'metaData', 'agreedTos'], flexibleRewards),
      },
    };
    dispatch(saveStatementPending());
    return RequestFactory(opts.method, opts.url, opts.headers, opts.data)
      .then((response) => {
        dispatch(
          saveStatementSuccess(
            hasFlexId ? employeeFlexibleRewardId : R.path(['data', 'response'], response)
          )
        );
        dispatch(updateEtag(R.path(['headers', 'etag'], response)));
      })
      .catch((err) => {
        dispatch(saveStatementError(err));
        dispatchError(err);
        return Promise.reject(err);
      });
  };
}

export const submitSelectedOptions = (packageId) => (dispatch, getState) => {
  const { user, flexibleRewards } = getState();
  const selectedOptions = R.path(['employeeFlexibleRewards', 'selectedRewards'], flexibleRewards);
  const comment = R.path(['employeeFlexibleRewards', 'comment'], flexibleRewards);
  const employeeFlexibleRewardId = R.path(
    ['employeeFlexibleRewards', 'employeeFlexibleRewardId'],
    flexibleRewards
  );
  const savedEtag = R.path(['employeeFlexibleRewards', 'savedEtag'], flexibleRewards);
  const payload = {
    id: employeeFlexibleRewardId,
    ...getTransformedSavePayload(selectedOptions, getProfileId(user), packageId),
    comment,
    agreedTos: R.path(['employeeFlexibleRewards', 'metaData', 'agreedTos'], flexibleRewards),
  };
  const isMimic = R.path(['mimic', 'isMimicView'], user);
  const contextHeader = getMimicContextHeader(isMimic);

  return dispatch(
    submitStatement(
      POST(
        `${rewards.employeeFlexibleRewards}/submit`,
        payload,
        { Etag: savedEtag, ...contextHeader },
        { showErrorModal: true, dispatch }
      ).then(() =>
        dispatch(
          showBanner({
            type: 'success',
            content: TextOnly({ path: 'flexibleRewards.SUBMIT_SELECTION_SUCCESS' }),
          })
        )
      )
    )
  );
};

export function setUpdatedAvailableOptions(newAvailableOptions) {
  return (dispatch, getState) => {
    const { flexibleRewards } = getState();
    const categories = R.path(['main', 'categories'], flexibleRewards);
    dispatch(
      updateAvailableOptions({
        newAvailableOptions,
        categories,
        availableOptionsRespModel: newAvailableOptions,
      })
    );
  };
}

export function showErrorBanner() {
  return (dispatch) => {
    dispatch(
      showBanner({
        type: 'error',
        content: globalErrors.BANNER_FORM_ERROR_GENERIC,
      })
    );
  };
}
