import { v4 as uuid } from 'uuid';
import * as R from 'ramda';
import {
  initPopulationExceptions,
  populationExceptionsChange,
  deletePopulationExceptions,
  updatePopulationExceptionsError,
  getPopulationExceptionsSuccess,
  getPopulationExceptionsPending,
  getPopulationExceptionsError,
  getVariablesLookupData,
  saveActions,
  generateImpactActions,
  resetImpactedPopulation,
} from '../actions';

import {
  initPopulationException,
  mapImpactedEmployees,
  processPopulationExceptionsChange,
  getLookupData,
} from './helpers';

export default [
  [
    initPopulationExceptions,
    (state) => {
      const exceptionsId = uuid();
      return {
        ...state,
        exceptions: initPopulationException(exceptionsId),
      };
    },
  ],
  [
    populationExceptionsChange,
    (state, action) => ({
      ...state,
      exceptions: processPopulationExceptionsChange(
        R.clone(state.exceptions),
        R.prop('payload', action)
      ),
      exceptionHasChanges: true,
    }),
  ],
  [
    deletePopulationExceptions,
    (state) => ({
      ...state,
      exceptions: [],
      exceptionsId: '',
      exceptionsEtag: '',
      exceptionHasChanges: false,
      impactedEmployees: [],
      impactedEmployeesGenerated: false,
    }),
  ],
  [
    updatePopulationExceptionsError,
    (state, action) => {
      const newState = state;
      return R.assocPath(['exceptionsErrors'], R.path(['payload', 'data'], action), newState);
    },
  ],
  [
    resetImpactedPopulation,
    (state) => ({
      ...state,
      impactedEmployees: [],
      impactedEmployeesGenerated: false,
    }),
  ],
  [
    getPopulationExceptionsSuccess,
    (state, action) => ({
      ...state,
      isPopulationExceptionsLoading: false,
      exceptions: R.path(['payload', 'response', 'eligibilityRules'], action),
      exceptionsEtag: R.path(['payload', 'etag'], action),
    }),
  ],
  [
    getPopulationExceptionsPending,
    (state) => ({
      ...state,
      isPopulationExceptionsLoading: true,
    }),
  ],
  [
    getPopulationExceptionsError,
    (state) => ({
      ...state,
      isPopulationExceptionsLoading: false,
    }),
  ],
  [
    getVariablesLookupData,
    (state, action) => {
      const { lookupData, url } = action.payload;

      return {
        ...state,
        lookUpValues: {
          ...state.lookUpValues,
          ...getLookupData(lookupData, url),
        },
      };
    },
  ],
  [saveActions.pending, (state) => ({ ...state, savingExceptions: true })],
  [
    saveActions.success,
    (state, action) => ({ ...state, savingExceptions: false, ...action.payload }),
  ],
  [
    saveActions.error,
    (state, action) => ({ ...state, exceptionsErrors: action.payload, savingExceptions: false }),
  ],
  [generateImpactActions.pending, (state) => ({ ...state, isImpactLoading: true })],
  [
    generateImpactActions.success,
    (state, action) => ({
      ...state,
      impactedEmployees: mapImpactedEmployees(R.path(['employees'], action.payload)),
      isImpactLoading: false,
      impactedEmployeesGenerated: true,
    }),
  ],
  [
    generateImpactActions.error,
    (state) => ({
      ...state,
      impactedEmployees: [],
      isImpactLoading: false,
      impactedEmployeesGenerated: false,
    }),
  ],
];
