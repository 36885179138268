const initialState = {
  countries: [],
  selectedCountry: {
    value: '',
    label: '',
  },
  exceptions: null,
  rewardsOverview: null,
  rewardsLoading: true,
  exceptionsLoading: true,
};

export default initialState;
