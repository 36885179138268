import React from 'react';
import { Button } from '@trs/components';
import PropTypes from 'prop-types';
import { toLower } from '@trs/utils';
import * as R from 'ramda';
import ReactDOM from 'react-dom';
import {
  ButtonGroup,
  ButtonGroupWrapper,
} from '../../../../../common/Form/FormButtons/ButtonGroup.style';
import Text from '../../../../../common/Text';
import StyledSideActions from './SideActions.style';
import SnapshotPdf from '../../../../../common/snapshotPdf';
import FlexiblePackageExport from '../flexiblePackageExport';
import { FILTER_TYPES } from '../../../../routes/utils';

const SideActions = ({
  submit,
  reset,
  isSaving,
  containerRef,
  hasChanges,
  isEditing,
  isMimic,
  hasBeenChecked,
  hasSubmitted,
  packageType,
  isComputingStatement,
  hasValidationErrors,
  showOnlyNewStatement,
  bannerDetails,
  isCheckedAgreement,
  isSubmissionPage,
  agreedTos,
  currentStatementDate,
  user,
  isAgreementShown,
}) => {
  const disableAll = isSaving || isEditing || isComputingStatement;
  const disableActions = disableAll || hasValidationErrors;

  return (
    <StyledSideActions className="statement-actions" packageType={packageType}>
      <ButtonGroupWrapper>
        <ButtonGroup>
          {!isSubmissionPage && (!hasSubmitted || isMimic) && (
            <Button
              key="resetDefault"
              disabled={disableAll || isMimic}
              action={reset}
              type="naked"
              className="reset-default-statement"
            >
              <Text path="flexibleRewards.RESET_DEFAULT" />
            </Button>
          )}
        </ButtonGroup>
        <ButtonGroup>
          <SnapshotPdf
            withDownloadIcon
            disabled={disableActions}
            options={{
              callback: () => {
                return Promise.resolve();
              },
              beforeDownload: (root) => {
                return new Promise((resolve) => {
                  ReactDOM.render(
                    <FlexiblePackageExport
                      showOnlyNewStatement={showOnlyNewStatement}
                      currentStatementDate={currentStatementDate}
                      bannerDetails={bannerDetails}
                      isCheckedAgreement={isCheckedAgreement}
                      user={user}
                      isPdfOutput
                    />,
                    root,
                    () => {
                      setTimeout(() => {
                        resolve();
                      });
                    }
                  );
                });
              },
              afterDownload: () => {},
              orientation: 'portrait',
              pagebreak: {
                avoid: [
                  'section',
                  '#myStatementTable',
                  '#rewardCategory',
                  '.mainStatements',
                  '.extraStatements',
                  '.Card',
                  '.reward-category',
                  '.available-options',
                  '.category-section',
                ],
              },
            }}
            loadingContentPath="flexibleRewards.EXPORTING"
            elemRef={containerRef}
            pdfName={R.prop('title', bannerDetails)}
            buttonType="link"
            buttonTextPath="flexibleRewards.EXPORT_TO_PDF"
            className="export-statement"
          />
          {((toLower(packageType) === FILTER_TYPES.SUBMISSION && hasChanges && agreedTos) ||
            (!hasSubmitted && toLower(packageType) === FILTER_TYPES.SUBMISSION)) && (
            <Button
              className="submit-statement"
              disabled={
                disableActions ||
                isMimic ||
                (isAgreementShown ? !agreedTos : !hasBeenChecked && !agreedTos)
              }
              action={submit}
            >
              <Text path="generic.BUTTON_SUBMIT" />
            </Button>
          )}
        </ButtonGroup>
      </ButtonGroupWrapper>
    </StyledSideActions>
  );
};

SideActions.defaultProps = {
  reset: null,
  isCheckedAgreement: false,
  isSubmissionPage: false,
  showOnlyNewStatement: false,
  submit: () => null,
  bannerDetails: {},
  currentStatementDate: null,
  isAgreementShown: false,
};

SideActions.propTypes = {
  isMimic: PropTypes.bool.isRequired,
  isAgreementShown: PropTypes.bool,
  hasChanges: PropTypes.bool.isRequired,
  containerRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }).isRequired,
  submit: PropTypes.func,
  reset: PropTypes.func,
  hasValidationErrors: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  isEditing: PropTypes.bool.isRequired,
  hasSubmitted: PropTypes.bool.isRequired,
  hasBeenChecked: PropTypes.bool.isRequired,
  agreedTos: PropTypes.bool.isRequired,
  packageType: PropTypes.string.isRequired,
  isComputingStatement: PropTypes.bool.isRequired,
  showOnlyNewStatement: PropTypes.bool,
  bannerDetails: PropTypes.shape({}),
  isCheckedAgreement: PropTypes.bool,
  isSubmissionPage: PropTypes.bool,
  currentStatementDate: PropTypes.string,
  user: PropTypes.shape({
    personalDetailsSetting: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        displayOrder: PropTypes.number.isRequired,
        mappingField: PropTypes.string.isRequired,
      })
    ),
  }).isRequired,
};

export default SideActions;
