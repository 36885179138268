import * as R from 'ramda';
import userManager from '../../../config/userManager/userManager';

const readTokenFromStorage = user => R.prop('access_token', user);

export const getUser = () => userManager.getUser().then(readTokenFromStorage);

export const NOTIFICATION_RECEIVED = 'ReceiveNotification';

export default {
  getUser,
};
