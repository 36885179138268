import * as R from 'ramda';

const getPermissionsFromSections = (moduleSections) => {
  const sectionPermissions = [];

  R.forEach((section) => {
    const { name: sectionName, permissions } = section;

    R.forEach((permission) => {
      const keys = R.pluck('key', permission.options);
      sectionPermissions.push({
        sectionName,
        permissionName: permission.name,
        keys,
      });
    }, permissions);
  }, moduleSections);

  return sectionPermissions;
};

export const generatePrefix = (str) => str.replace(/[\s-_]([a-zA-Z])/g, (m) => m[1].toUpperCase());

const generateModel = (rolePermissions, sectionPermissions) => {
  let model = {};

  R.forEach((rolePermission) => {
    const section = R.find((item) => R.contains(rolePermission, item.keys), sectionPermissions);
    if (!section) return false;

    const prefix = generatePrefix(`${section.sectionName}${section.permissionName}`);

    model = {
      ...model,
      [prefix]: rolePermission,
    };

    return true;
  }, rolePermissions);

  return model;
};

export const getModelFromPayload = ({ rolePermissions, modules }) => {
  const model = [];

  R.forEach((rolePermission) => {
    const moduleName = R.prop('module', rolePermission);
    const moduleSections = R.prop(
      'sections',
      modules.find((item) => item.moduleKey === moduleName)
    );

    if (!moduleSections) return false;

    const sectionPermissions = getPermissionsFromSections(moduleSections);
    model.push({
      module: moduleName,
      permissions: generateModel(rolePermission.permissions, sectionPermissions),
    });

    return true;
  }, rolePermissions);

  return model;
};

export const getNewModelPermissions = (state, payload) => {
  const { moduleKey, prefix, option } = payload;
  const { modulePermissions } = state;
  let newModulePermissions = modulePermissions;

  const existingModule = modulePermissions.find((item) => item.module === moduleKey);

  if (existingModule) {
    const { permissions } = existingModule;
    const newModule = {
      ...existingModule,
      permissions: {
        ...permissions,
        [prefix]: option,
      },
    };

    newModulePermissions = newModulePermissions.filter(
      (elem) => elem.module !== existingModule.module
    );

    newModulePermissions.push(newModule);
  } else {
    newModulePermissions.push({
      module: moduleKey,
      permissions: {
        [prefix]: option,
      },
    });
  }
  return newModulePermissions;
};
