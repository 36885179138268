import * as R from 'ramda';

export const isSetupCompleted = modules => {
  let isCompleted = true;
  R.forEach(item => {
    if (item.mandatory && !item.completed) {
      isCompleted = false;
    }
  }, modules);
  return isCompleted;
};

export default isSetupCompleted;
