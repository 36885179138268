import { combineReducers } from 'redux';

import global from '../components/global/reducer';
import local from '../components/local/reducer';
import population from '../components/common/benchmarkPopulation/reducer';

export default combineReducers({
  population,
  global,
  local,
});
