import * as R from 'ramda';
import { EXPIRY_TIMES, FORBIDDEN_STATUS_CODE } from '@trs/config';
import { safeTrim, isBlank, toBinary } from '@trs/utils';
import { showBanner } from 'actions/globalActions';
import { createAction } from 'redux-actions';
import { DELETE, GET, POST, noCacheHeader, RequestFactory } from '../../../config/http';
import { TextOnly } from '../../common/Text';
import { rewards, organization } from '../../../config/api';
import { withCache } from '../../common/timedCache';
import { dispatchError } from '../../common/helpers';
import { getSearchFilterPayload, VARIABLE_TYPES } from '../helpers';
import actionTypes from './types';

const cachedGet = withCache(GET, EXPIRY_TIMES.HH);

export const getVariablesCountries = createAction(
  actionTypes.VARIABLES_GET_COUNTRIES,
  (data) => data.promise,
  (data) => data.user
);

export const getVariablesList = createAction(
  actionTypes.VARIABLE_SEARCH,
  (data) => data.promise,
  (data) => data.authorizedModules
);

export const setVariableValue = createAction(actionTypes.VARIABLES_SAVE);
export const getVariableById = createAction(actionTypes.VARIABLES_GET_BY_ID);
export const getAffectedRewardsByVarId = createAction(actionTypes.VARIABLES_GET_AFFECTED_REWARDS);
export const variableChange = createAction(actionTypes.VARIABLE_CHANGE);
export const storeReset = createAction(actionTypes.VARIABLE_STORE_RESET);
export const deleteVariableById = createAction(actionTypes.VARIABLE_DELETE_BY_ID);

const getTextOnlyError = (error) => {
  /**
   * In case of a 403 response, the CMS key is found on the following path: ['response', 'data', 'errors', 0].
   * However, for a different status, the response has another level ('message'), hence the check on line 42
   */
  const messagePath = ['response', 'data', 'errors', 0];
  const status = R.path(['response', 'status'], error);
  if (status !== FORBIDDEN_STATUS_CODE) {
    messagePath.push('message');
  }
  const message = R.path(messagePath, error);

  return TextOnly({ path: `variables.${message}` }) || message;
};

export function loadVariables(params) {
  const payload = getSearchFilterPayload(params);
  return (dispatch, getState) => {
    const authorizedModules = R.path(['user', 'authorizedModules'], getState());

    dispatch(
      getVariablesList({
        promise: POST(`${rewards.variables}/search`, payload).catch((error) => {
          dispatchError(error, getTextOnlyError, dispatch);
        }),
        authorizedModules,
      })
    );
  };
}

export function loadVariablesCountries() {
  return (dispatch, getState) => {
    const user = R.path(['user'], getState());
    const { authorizedModules, profile } = user;
    dispatch(
      getVariablesCountries({
        promise: cachedGet(R.path(['lookupCountries'], organization)).catch((error) => {
          dispatchError(error, getTextOnlyError, dispatch);
          return Promise.reject(error);
        }),
        user: {
          authorizedModules,
          profile,
        },
      })
    );
  };
}

const getPayload = (variableType, initialData) => {
  if (variableType === VARIABLE_TYPES.list) {
    return {
      ...initialData,
      value: null,
    };
  }

  return {
    ...initialData,
    items: null,
  };
};

export function saveVariableValue() {
  return (dispatch, getState) => {
    const state = getState();
    const savedVariable = R.path(['variables', 'savedVariable'], state);
    const successLabel = R.path(['cms', 'variables', 'data', 'VARIABLED_SAVED'], state);
    const variableId = R.prop('id', savedVariable);
    const isUpdate = !isBlank(variableId);
    const apiUrl = `${rewards.variables}/${isUpdate ? variableId : ''}`;
    const variableType = R.prop('type', savedVariable);
    const variableAmount = R.path(['value', 'amount'], savedVariable);
    const variableName = R.path(['displayName'], savedVariable);
    const data = {
      id: variableId,
      country: R.path(['country', 'value'], savedVariable),
      displayName: toBinary(safeTrim(variableName)),
      type: R.prop('type', savedVariable),
      countryCode: R.path(['country', 'code'], savedVariable),
      value: {
        type: R.path(['value', 'type'], savedVariable),
        amount: !isBlank(variableAmount) ? Number(variableAmount) : null,
      },
      items: R.prop('items', savedVariable),
    };

    return dispatch(
      setVariableValue(
        RequestFactory(
          isUpdate ? 'PUT' : 'POST',
          apiUrl,
          { ETag: savedVariable.etag },
          getPayload(variableType, data),
          null,
          { redirectToUnauthorized: false }
        )
          .then((response) => {
            dispatch(
              showBanner({
                type: 'success',
                content: successLabel.replace('%NAME%', safeTrim(variableName)),
              })
            );
            return { data: response.data, headers: response.headers };
          })
          .catch((error) => {
            dispatchError(error, getTextOnlyError, dispatch);
            return Promise.reject(error);
          })
      )
    );
  };
}

export function getAffectedRewards(variableId) {
  return (dispatch) =>
    dispatch(
      getAffectedRewardsByVarId(
        GET(`${rewards.variables}/${variableId}/usage`, noCacheHeader).catch((error) => {
          dispatchError(error, () => TextOnly({ path: 'variables.USAGES_FAILED' }), dispatch);
          return Promise.reject(error);
        })
      )
    );
}

export function loadVariableById(variableId) {
  return (dispatch) =>
    dispatch(
      getVariableById(
        GET(`${rewards.variables}/${variableId}`, noCacheHeader).catch((error) => {
          dispatchError(error, getTextOnlyError, dispatch);
          return Promise.reject(error);
        })
      )
    );
}

export function deleteVariable(variableId) {
  return (dispatch) =>
    DELETE(`${rewards.variables}/${variableId}`, noCacheHeader).catch((error) => {
      dispatchError(error, getTextOnlyError, dispatch);
      return Promise.reject(error);
    });
}
