"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var StyledSimpleRule = _styledComponents["default"].div.withConfig({
  displayName: "SimpleRulestyle__StyledSimpleRule",
  componentId: "sc-16bq6s9-0"
})(["", ";"], function (props) {
  var _props$theme = props.theme,
    spacing = _props$theme.spacing,
    palette = _props$theme.palette;
  var isSelected = props.isSelected,
    hasErrors = props.hasErrors;
  return (0, _styledComponents.css)(["align-items:", ";background:", ";border:1px solid ", ";border-left:0;box-sizing:border-box;display:inline-flex;margin-bottom:8px;outline:none;padding:", " 0;position:relative;width:100%;&:last-child{margin-bottom:0px;}.rule-element{margin:0 ", ";width:200px;&.rule-operator{width:110px;}&.rule-value{max-width:300px;min-width:200px;width:auto;}input{max-width:250px;vertical-align:middle;&.input-date,&.input-number{max-width:200px;width:200px;}}&.value-element{.Select-menu-outer{width:400px;}}&.rule-operator{.Select-menu-outer{width:200px;}}}.ellipsis-overflow{text-overflow:ellipsis;white-space:nowrap;overflow:hidden;height:100%;width:100%;z-index:2;}.Select-control{.Select-value{max-width:90%;z-index:2;}.Select-input{z-index:1;}}.buttons-wrapper{display:flex;flex-grow:1;justify-content:flex-end;& > *{align-self:center;margin-right:", ";}.rule-buttons{font-weight:normal;min-width:", ";margin-right:", ";padding:", " 0;}}.menulist-wrapper{width:10px;.rule-menu-list{color:", ";cursor:pointer;}}"], hasErrors ? 'flex-start' : 'flex-end', palette.basic.white, isSelected ? palette.secondary : palette.gray.charlie, spacing.base, spacing.tiny, spacing.small, spacing.large, spacing.tiny, spacing.tiny, palette.gray.bravo);
});
StyledSimpleRule.displayName = 'StyledSimpleRule';
var _default = StyledSimpleRule;
exports["default"] = _default;