"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.upperFirstLetter = exports.trimDecimals = exports.toUpper = exports.toLower = exports.toBinary = exports.split = exports.safeTrim = exports.safeSlice = exports.lowerFirstLetter = exports.isValidURL = exports.getjsx = exports.equalsInsensitive = exports.composeEnumerableString = exports.cleanBlanks = exports.camelcase = void 0;
var R = _interopRequireWildcard(require("ramda"));
var _objects = require("../objects");
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
var toUpper = function toUpper() {
  var string = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return R.toUpper(string);
};
exports.toUpper = toUpper;
var toLower = function toLower() {
  var string = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return R.toLower(string);
};
exports.toLower = toLower;
var split = function split() {
  var string = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var separator = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  return string.split(separator);
};
exports.split = split;
var equalsInsensitive = R.curry(function (a, b) {
  return String(a).toLowerCase() === String(b).toLowerCase();
});
exports.equalsInsensitive = equalsInsensitive;
var composeEnumerableString = function composeEnumerableString(list, separator, lastSeparator) {
  var result = '';
  if (list.length < 2) {
    return R.concat(result, R.prop(0, list));
  }
  if (list.length > 2) {
    result += R.join(separator, R.slice(0, list.length - 2, list)) + separator;
  }
  if (lastSeparator) {
    result += R.join(lastSeparator, R.slice(list.length - 2, list.length, list));
  }
  return result;
};
exports.composeEnumerableString = composeEnumerableString;
var camelcase = function camelcase(string) {
  return string ? string.replace(/(?:^\w|[A-Z]|\b\w)/g, function (letter, index) {
    return index === 0 ? letter.toLowerCase() : letter.toUpperCase();
  }).replace(/\s+/g, '') : '';
};
exports.camelcase = camelcase;
var upperFirstLetter = function upperFirstLetter(string) {
  return typeof string === 'string' ? toUpper(string.charAt(0)) + string.slice(1) : string;
};
exports.upperFirstLetter = upperFirstLetter;
var lowerFirstLetter = function lowerFirstLetter(string) {
  return typeof string === 'string' ? toLower(string.charAt(0)) + string.slice(1) : string;
};
exports.lowerFirstLetter = lowerFirstLetter;
var safeTrim = function safeTrim() {
  var item = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return item && item.trim ? item.trim() : item;
};
exports.safeTrim = safeTrim;
var safeSlice = function safeSlice() {
  var item = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var start = arguments.length > 1 ? arguments[1] : undefined;
  var end = arguments.length > 2 ? arguments[2] : undefined;
  return item && item.slice ? item.slice(start, end) : item;
};
exports.safeSlice = safeSlice;
var getjsx = function getjsx(_ref) {
  var _ref$label = _ref.label,
    label = _ref$label === void 0 ? '' : _ref$label,
    _ref$pattern = _ref.pattern,
    pattern = _ref$pattern === void 0 ? '%' : _ref$pattern,
    _ref$map = _ref.map,
    map = _ref$map === void 0 ? {} : _ref$map;
  return label.split(pattern).map(function (item) {
    if (R.prop(item, map)) {
      return map[item];
    }
    return item;
  });
};
exports.getjsx = getjsx;
var trimDecimals = function trimDecimals(value) {
  var maxDecimals = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
  if ((0, _objects.isBlank)(value)) return '';
  var stringValue = value.toString();
  var decimalPointIndex = stringValue.indexOf('.');
  if (decimalPointIndex === -1) return stringValue;

  // We add 1 since the decimalPointIndex is zero-indexed
  decimalPointIndex += 1;
  var decimalCount = stringValue.length - decimalPointIndex;
  if (decimalCount === 0) return R.init(stringValue); // remove the trailing decimal point
  if (decimalCount < 3) return stringValue; // no need for trimming
  return R.slice(0, decimalPointIndex + maxDecimals, stringValue);
};
exports.trimDecimals = trimDecimals;
var cleanBlanks = function cleanBlanks() {
  var string = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  var replaceWith = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  return string.replace(/\s/g, replaceWith);
};
exports.cleanBlanks = cleanBlanks;
var isValidURL = function isValidURL(string) {
  var res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g);
  return res !== null;
};
exports.isValidURL = isValidURL;
var toBinary = function toBinary(string) {
  if ((0, _objects.isBlank)(string)) return '';
  var codeUnits = new Uint16Array(string.length);
  for (var i = 0; i < codeUnits.length; i += 1) {
    codeUnits[i] = string.charCodeAt(i);
  }
  return btoa(String.fromCharCode.apply(String, _toConsumableArray(new Uint8Array(codeUnits.buffer))));
};
exports.toBinary = toBinary;