export default {
  WIDGET_TITLE: 'Notifications',
  NOTIFICATION_TOGGLE: 'Toggle notifications list',
  NOTIFICATION_OVERALLIMPACT_TITLE: 'Impact calculated',
  NOTIFICATION_OVERALLIMPACT_CONTENT: 'The impact for reward #REWARD_NAME# has been calculated. ',
  NOTIFICATION_OVERALLIMPACT_LINK: 'Details',
  NOTIFICATION_WORKSPACE_TITLE: 'Workspace Upload %STATUS%',
  NOTIFICATION_WORKSPACE_CONTENT: 'The file %FILE_NAME% has been uploaded. ',
  NOTIFICATION_WORKSPACE_CONTENT_ERROR: '%ERROR_MESSAGE%',
  NOTIFICATION_WORKSPACE_LINK: 'Details',
  NO_RESULTS: 'There are no notifications.',
  REPORT_EXPORTED: 'File has been successfully exported.',
  DOWNLOAD_REPORT: 'Download',
  EXPORT_IS_IN_PROGRESS: '%FILENAME% export is in progress',
  NOTIFICATION_WORKSPACE_PROCESS_TRIGGERING_TITLE: 'Process %STATUS%',
  NOTIFICATION_WORKSPACE_PROCESS_TRIGGERING_CONTENT_SUCCESS: '%PROCESS_NAME% ran successfully.',
  NOTIFICATION_WORKSPACE_PROCESS_TRIGGERING_CONTENT_FAILED: '%PROCESS_NAME% has failed.',
  NOTIFICATION_GO_TO_FILE: 'Go to file',
  NOTIFICATION_GO_TO_PROCESS: 'Go to process',
  NOTIFICATION_DATA_VALIDATION_TITLE: 'Data Validation',
  NOTIFICATION_DATA_VALIDATION_CONTENT: 'File has been succesfully compiled',
  NOTIFICATION_EMPLOYEE_ELECTIONS_TITLE: 'Employee Elections',
  FORECAST_REPORT_TITLE: 'Forecast Report',
  NOTIFICATION_CUSTOM_REPORT: '%FILENAME% Report',
  MARK_AS_READ: 'Mark as Read',
  NOTIFICATION_SYSTEM_AUDIT_TITLE: 'System Audit',
  NOTIFICATION_AUDIT_CONTENT: 'File has been succesfully compiled',
  NOTIFICATION_COUNTRY_REWARDS_TITLE: 'Country Rewards Report',
  NOTIFICATION_COUNTRY_REWARDS_BODY:
    'The country rewards report for <strong>%COUNTRIES%</strong> has been generated.',
  NOTIFICATION_EMPLOYEE_DATA_LOADING_TITLE: 'Employee Data Loading',
  NOTIFICATION_SHARES_DATA_LOADING_TITLE: 'Shares Data Loading',
  NOTIFICATION_DATA_LOADING_CONTENT_SUCCESS:
    'File <strong>%FILENAME%</strong> was successfully %TYPE% on: %DATE%',
  NOTIFICATION_DATA_LOADING_CONTENT_ERRORS:
    'File <strong>%FILENAME%</strong> has been %TYPE% with errors',
  NOTIFICATION_DATA_LOADING_PROVIDER: 'Provider: %NAME%',
  NOTIFICATION_ROLE_ELIGIBILITY_TITLE: 'Role eligibility',
  NOTIFICATION_ROLE_ELIGIBILITY_CONTENT_SUCCESS:
    'The eligibility for role %ROLE_NAME% has been successfully calculated.',
  NOTIFICATION_ROLE_ELIGIBILITY_CONTENT_FAILED:
    'The eligibility calculation for role %ROLE_NAME% has failed.',
  NOTIFICATION_EXCEPTION_FILE_UPLOAD_TITLE: 'Exceptions file upload',
  NOTIFICATION_EXCEPTION_FILE_UPLOAD_CONTENT_SUCCESS:
    'Exceptions file <strong>%FILENAME%</strong> has been successfully uploaded.',
  NOTIFICATION_EXCEPTION_DELETED_TITLE: 'Exceptions Deleted',
  NOTIFICATION_EXCEPTION_DELETED_CONTENT_SUCCESS:
    '%NR_EXCEPTIONS% exceptions were successfully deleted.',
  NOTIFICATION_EXCEPTION_DELETED_CONTENT_ERROR:
    'We have encountered an error while deleting the exceptions. Please try again later.',
  NOTIFICATION_EXCEPTION_PUBLISHED_TITLE: 'Exceptions Published',
  NOTIFICATION_EXCEPTION_PUBLISHED_CONTENT_SUCCESS:
    '%NR_EXCEPTIONS% exceptions were successfully published.',
  NOTIFICATION_EXCEPTION_PUBLISHED_CONTENT_ERROR:
    'We have encountered an error while publishing the exceptions. Please try again later.',
  ENROLMENT_WINDOWS_REPORT: 'Enrolment Windows Report',
  NOTIFICATION_GENERATED_PAYSCALES_PUBLISHED_TITLE: 'PayScales Data',
  NOTIFICATION_GENERATED_PAYSCALES_PUBLISHED_CONTENT: 'The PayScales data has been updated.',
  NOTIFICATION_GENERATED_PAYSCALES_PUBLISHED_LINK: 'Details',
};
