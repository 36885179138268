"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var StyledInput = _styledComponents["default"].div.withConfig({
  displayName: "Inputstyle__StyledInput",
  componentId: "sc-1gud2ay-0"
})(["", ";"], function (props) {
  var _props$theme = props.theme,
    color = _props$theme.palette,
    typography = _props$theme.typography,
    spacing = _props$theme.spacing,
    genericBorderRadius = _props$theme.extra.genericBorderRadius;
  var hasError = props.hasError,
    isRequired = props.isRequired,
    width = props.width,
    icon = props.icon,
    variant = props.variant;
  return (0, _styledComponents.css)(["position:relative;input{background-color:", ";font-family:", ";font-size:", ";font-weight:", ";line-height:", ";color:", ";padding:0 ", ";padding-right:", ";height:", ";width:", ";transition:border 0.2s ease-out;", " ", " &:focus{border-color:", ";}&::placeholder{color:", ";font-size:", ";font-weight:", ";opacity:1;}&::-ms-clear{display:none;}&:disabled{border-color:", ";background:none;color:", ";-webkit-text-fill-color:", ";cursor:not-allowed;&::placeholder{color:", ";}}& + i,& + i + i{padding:5px;position:absolute;color:", ";cursor:pointer;&:hover{color:", ";}&.input-icon{font-size:24px;}&.input-clear-icon{font-size:16px;}}", ";}"], color.basic.white, typography.font.primary, typography.size.base, typography.weight.normal, typography.lineHeight["default"], color.gray.alpha, spacing.tiny, spacing.base, spacing.xxxBase, width, variant === 'underlined' && (0, _styledComponents.css)(["border:none;border-bottom:1px solid ", ";"], hasError || isRequired ? color.validation.errorLight : color.gray.foxtrot), variant === 'outlined' && (0, _styledComponents.css)(["border-radius:", ";border:1px solid ", ";"], genericBorderRadius, hasError || isRequired ? color.validation.errorLight : color.gray.foxtrot), color.accent.alpha, color.gray.bravo, typography.size.base, typography.weight.normal, hasError || isRequired ? color.validation.errorLight : color.gray.charlie, color.gray.charlie, color.gray.charlie, color.gray.charlie, color.gray.bravo, color.secondary, icon && (0, _styledComponents.css)(["padding-right:35px;& + i{margin-left:-30px;}"]));
});
StyledInput.displayName = 'StyledInput';
var _default = StyledInput;
exports["default"] = _default;