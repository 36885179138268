// TODO: Extract eligibility to a separate module / common component
import * as R from 'ramda';
import { isBlank } from '@trs/utils';
import { v4 as uuid } from 'uuid';

export const mapEligibilityOperators = (payload) =>
  payload.map(({ displayName, name, allowsMultipleSelect }) => ({
    value: name,
    label: displayName,
    allowsMultipleSelect,
  }));

export const mapSystemVariables = (payload) => {
  let systemVariables = payload.map(({ displayName, propertyName, type, valuesUrl }) => ({
    value: propertyName,
    label: displayName,
    type,
    valuesUrl,
  }));

  systemVariables = R.sortBy(R.prop('value'))(systemVariables);
  return systemVariables;
};

export const processPopulationChange = (statePopulations, payload) => {
  const populations = statePopulations;

  populations[payload.index] = {
    ...populations[payload.index],
    [payload.field]: payload.value,
    ...payload.other,
  };

  return populations;
};

export const getLookupData = (lookupData, url) => {
  const lookUpValues = {};
  lookUpValues[url] = [];

  if (lookupData) {
    lookupData.map((item) =>
      lookUpValues[url].push({
        label: item,
        value: item,
      })
    );
    lookUpValues[url] = R.sortBy(R.prop('value'))(lookUpValues[url]);
  }

  return lookUpValues;
};

export const addUuidsToEligibilityItems = (items) => {
  const newRules = items;

  if (isBlank(newRules)) return null;

  newRules.uid = uuid();

  if (R.prop('eligibilityRows', newRules)) {
    newRules.eligibilityRows.map((item) => addUuidsToEligibilityItems(item));
  }

  return newRules;
};
