/* eslint-disable max-lines */
import * as R from 'ramda';
import qs from 'qs';
import { templateToRewardContextMapping, dispatchError } from './helpers';
import * as actions from './types';
import { REQUEST_CONTEXT } from '../components/overallImpact/components';
import { withCache } from '../../common/timedCache';
import { GET, POST, PUT, CancelToken, addGetParameters } from '../../../config/http';
import { rewards, foreignExchange, organization, feedback, cms } from '../../../config/api';

const cachedGet = withCache(GET, 3600);

function requestRewards() {
  return {
    type: actions.REWARDS_REQUESTED,
  };
}

function requestRewardVersions() {
  return {
    type: actions.REWARDS_VERSIONS_REQUESTED,
  };
}

function getRewardCategories(categories) {
  return {
    type: actions.REWARDS_GET_CATEGORIES,
    categories,
  };
}

function getSystemVariables(systemVariables) {
  return {
    type: actions.SYSTEM_VARIABLES_GET,
    systemVariables,
  };
}

function getFxRateDates(fxRateDates) {
  return {
    type: actions.FX_RATE_GET_DATES,
    fxRateDates,
  };
}

function getEligibilityOperators(eligibilityOperators) {
  return {
    type: actions.ELIGIBILITY_OPERATORS_GET,
    eligibilityOperators,
  };
}

function getVariablesLookupData(lookupData, url) {
  return {
    type: actions.SYSTEM_VARIABLES_LOOKUP_GET,
    lookupData,
    url,
  };
}

function rewardSave(response, rewardId) {
  return {
    type: actions.REWARDS_SAVE,
    id: rewardId,
    response,
  };
}

function rewardPublished(response, rewardId) {
  return {
    type: actions.REWARDS_PUBLISHED,
    id: rewardId,
    response,
  };
}

function lockRewardGeneralFields() {
  return {
    type: actions.RULES_LOGIC_BUILDER_SAVE,
  };
}

function getLinkableRewards(linkableRewards) {
  return {
    type: actions.LINKED_REWARDS_GET,
    linkableRewards,
  };
}

function getRewardsRequested() {
  return {
    type: actions.LINKED_REWARDS_OPERANDS_REQUESTED,
  };
}

export function addRuleBlock(index) {
  return {
    type: actions.REWARDS_ADD_RULE_BLOCK,
    index,
  };
}

export function duplicateRuleBlock(index, ruleBlock, other) {
  return {
    type: actions.REWARDS_DUPLICATE_RULE_BLOCK,
    index,
    ruleBlock,
    other,
  };
}

export function deleteRuleBlock(index) {
  return {
    type: actions.REWARDS_DELETE_RULE_BLOCK,
    index,
  };
}

export function deleteRuleFormulas(index) {
  return {
    type: actions.REWARDS_DELETE_RULE_FORMULAS,
    index,
  };
}

export function deleteRuleRange(index) {
  return {
    type: actions.REWARDS_DELETE_RULE_RANGE,
    index,
  };
}

export function deleteLinkedRewardsyRule(index) {
  return {
    type: actions.LINKED_REWARDS_DELETE_RULE,
    index,
  };
}

export function etagUpdate(etag) {
  return {
    type: actions.ETAG_UPDATE,
    savedRewardEtag: etag,
  };
}

export function loadRewardCategories() {
  return (dispatch) =>
    GET(`${rewards.categories}?excludeIncompleteCategories=true`)
      .then((payload) => {
        const data = R.path(['data', 'response', 'categories'], payload);
        dispatch(getRewardCategories(data));
        return data;
      })
      .catch((e) => {
        dispatchError(e, dispatch);
      });
}

export function basicInformationChange(field, value, other) {
  return {
    type: actions.BASIC_INFORMATION_CHANGE,
    field,
    value,
    other,
  };
}

export function descriptionChange(field, value, other) {
  return {
    type: actions.DESCRIPTION_CHANGE,
    field,
    value,
    other,
  };
}

export function basicInformationReset(optionalDefaults) {
  return {
    type: actions.BASIC_INFORMATION_RESET,
    optionalDefaults,
  };
}

export function redirectToRoot() {
  return {
    type: actions.REDIRECT_TO_ROOT,
  };
}

export function redirectToExceptions() {
  return {
    type: actions.REDIRECT_TO_EXCEPTIONS,
  };
}

export function storeReset() {
  return {
    type: actions.STORE_RESET,
  };
}

export function updateBasicInformation(data, rewardId, config) {
  if (!rewardId) {
    return (dispatch, getState) =>
      POST(`${rewards.rewardsRoot}`, data, {}, { redirectToUnauthorized: false })
        .then((response) => {
          dispatch(rewardSave(response, response.data.response.id));
          dispatch(etagUpdate(response.headers.etag));
          return response;
        })
        .catch((e) => {
          dispatchError(e, dispatch, getState());
          return Promise.reject(e);
        });
  }

  return (dispatch, getState) =>
    PUT(
      `${rewards.rewardsRoot}/${rewardId}`,
      data,
      { ...config.headers },
      { redirectToUnauthorized: false }
    )
      .then((response) => {
        dispatch(rewardSave(response, response.data.response.id));
        dispatch(etagUpdate(response.headers.etag));
        return response;
      })
      .catch((e) => {
        dispatchError(e, dispatch, getState());
        return Promise.reject(e);
      });
}

export function publishDraftReward(rewardId, config) {
  return (dispatch, getState) =>
    PUT(
      `${rewards.rewardsRoot}/${rewardId}/publish`,
      {},
      { ...config.headers },
      { redirectToUnauthorized: false }
    )
      .then((response) => {
        dispatch(rewardPublished(response, rewardId));
      })
      .catch((e) => {
        dispatchError(e, dispatch, getState());
        return Promise.reject(e);
      });
}

export function createRewardRule(id, payload, config) {
  const rewardRulePayload = { rewardRuleModelRequests: [...payload] };
  return (dispatch, getState) =>
    PUT(
      `${rewards.rewardsRoot}/${id}/rules`,
      rewardRulePayload,
      { ...config.headers },
      { redirectToUnauthorized: false }
    )
      .then((response) => {
        dispatch(etagUpdate(response.headers.etag));
        dispatch(lockRewardGeneralFields());
      })
      .catch((e) => {
        dispatchError(e, dispatch, getState());
        return Promise.reject(e);
      });
}

export function loadRewards(params) {
  const { sortAsc, pagingTop: top, pagingSkip: skip, searchText, filters } = params;
  let { sortBy } = params;
  if (sortBy === 'category') {
    sortBy = 'encodedCategory';
  }

  if (sortBy === 'subcategory') {
    sortBy = 'encodedSubcategory';
  }

  if (sortBy === 'displayName') {
    sortBy = 'name';
  }

  const payload = {
    sort: {
      sortAsc,
      sortBy,
    },
    paging: {
      top,
      skip,
    },
    search: {
      searchText,
    },
    filters,
  };

  return (dispatch, getState) => {
    dispatch(requestRewards());
    POST(`${rewards.rewardsV2Root}/search`, payload)
      .then((response) => {
        dispatch({
          type: actions.REWARDS_SEARCH,
          rewardList: response,
          authorizedModules: R.path(['user', 'authorizedModules'], getState()),
        });
      })
      .catch((e) => {
        dispatch({
          type: actions.REWARDS_GET_ERROR,
          e,
        });
        dispatchError(e, dispatch);
      });
  };
}

export function loadImpactRewards(params) {
  const details = params;
  const { sortAsc, pagingTop: top, pagingSkip: skip, searchText, filters } = details;
  let { sortBy } = params;
  if (sortBy === 'category') {
    sortBy = 'encodedCategory';
  }

  if (sortBy === 'subcategory') {
    sortBy = 'encodedSubcategory';
  }

  if (sortBy === 'displayName') {
    sortBy = 'name';
  }

  const payload = {
    sort: {
      sortAsc,
      sortBy,
    },
    paging: {
      top,
      skip,
    },
    search: {
      searchText,
    },
    filters,
  };

  return (dispatch, getState) => {
    dispatch(requestRewards());
    POST(`${rewards.base}/overallImpact/rewards/search`, payload)
      .then((response) => {
        dispatch({
          type: actions.REWARDS_IMPACT_SEARCH,
          rewardList: response,
          authorizedModules: R.path(['user', 'authorizedModules'], getState()),
        });
      })
      .catch((e) => {
        dispatch({
          type: actions.REWARDS_GET_IMPACT_ERROR,
          e,
        });
        dispatchError(e, dispatch);
      });
  };
}

export const loadRewardsVersions = (excludedRewardsIds, rewardsGroupId) => (dispatch, getState) => {
  dispatch(requestRewardVersions());
  GET(`${rewards.rewardsRoot}/grouped/${rewardsGroupId}?excludedRewardsIds=${excludedRewardsIds}`)
    .then((response) => {
      dispatch({
        type: actions.REWARDS_VERSIONS_SEARCH,
        payload: {
          versions: R.path(['data', 'response'], response),
          rewardsGroupId,
        },
        authorizedModules: R.path(['user', 'authorizedModules'], getState()),
      });
    })
    .catch((e) => {
      dispatchError(e, dispatch);
    });
};

export function loadSystemVariables(variableType = '') {
  let requestUrl = rewards.systemVariables;

  if (variableType) {
    const getArgs = [{ name: 'variableType', value: variableType }];
    requestUrl = addGetParameters(requestUrl, getArgs);
  }

  return (dispatch) =>
    cachedGet(requestUrl, { Pragma: 'no-cache' })
      .then((data) => {
        dispatch(getSystemVariables(data));
      })
      .catch((e) => {
        dispatchError(e, dispatch);
      });
}

export function loadFxRateDates() {
  let requestUrl = rewards.systemVariables;
  const getArgs = [{ name: 'variableType', value: 'datetime' }];
  requestUrl = addGetParameters(requestUrl, getArgs);

  return (dispatch) =>
    cachedGet(requestUrl, { Pragma: 'no-cache' })
      .then((data) => {
        dispatch(getFxRateDates(data));
      })
      .catch((e) => {
        dispatchError(e, dispatch);
      });
}

export function loadEligibilityOperators() {
  return (dispatch) =>
    cachedGet(rewards.eligibilityOperators)
      .then((data) => {
        dispatch(getEligibilityOperators(data));
      })
      .catch((e) => {
        dispatchError(e, dispatch);
      });
}

export function loadSystemVariablesLookup(url) {
  return (dispatch) =>
    cachedGet(`${rewards.systemVariables}/${url}`)
      .then((data) => {
        dispatch(getVariablesLookupData(data, url));
      })
      .catch((e) => {
        dispatchError(e, dispatch);
      });
}

export function rulesBuilderReset() {
  return {
    type: actions.RULES_BUILDER_RESET,
  };
}

export function ruleFormulaChange(index, value, other) {
  return {
    type: actions.RULE_CHANGE,
    index,
    value,
    other,
  };
}

export function ruleChange(index, field, value, other) {
  return {
    type: actions.RULE_CHANGE,
    index,
    field,
    value,
    other,
  };
}

let cancelOperandCall;

export function getFormulaOperandValues({ resourceGroupId, resourceCountry }) {
  return (_, getState) => (searchValue) => {
    const template = R.path(['rewards', 'basicInformation', 'template'], getState());

    if (!searchValue) {
      return Promise.resolve([]);
    }

    if (cancelOperandCall) cancelOperandCall.cancel('Formula Operand call cancelled.');

    cancelOperandCall = CancelToken.source();
    const getArgs = [
      {
        name: 'resourceGroupId',
        value: resourceGroupId,
      },
      {
        name: 'resourceCountry',
        value: resourceCountry,
      },
      {
        name: 'context',
        value: templateToRewardContextMapping[template],
      },
    ];
    const requestUrl = addGetParameters(
      `${rewards.formulaOperand}/${encodeURIComponent(searchValue)}`,
      getArgs
    );

    return GET(requestUrl, {
      cancelToken: cancelOperandCall.token,
    }).then((response) => response.data);
  };
}

function getFormulaOperators(formulaOperators) {
  return {
    type: actions.FORMULA_OPERATORS_GET,
    formulaOperators,
  };
}

export function loadFormulaOperators() {
  return (dispatch) =>
    cachedGet(rewards.formulaOperators)
      .then((response) => {
        dispatch(getFormulaOperators(response.data));
      })
      .catch((e) => {
        dispatchError(e, dispatch);
      });
}

export function loadRewardsCountries() {
  return (dispatch, getState) =>
    cachedGet(organization.lookupCountries)
      .then((response) => {
        dispatch({
          type: actions.REWARDS_GET_COUNTRIES,
          error: null,
          countries: response,
          user: R.prop('user', getState()),
        });
      })
      .catch((e) => {
        dispatchError(e, dispatch);
      });
}

function getFxSources(fxSources) {
  return {
    type: actions.REWARDS_GET_FX_SOURCES,
    fxSources,
  };
}

export function loadFxSources() {
  return (dispatch) =>
    GET(foreignExchange.fxSources)
      .then((response) => {
        dispatch(getFxSources(response));
      })
      .catch((e) => {
        dispatchError(e, dispatch);
      });
}

export function loadRewardsCurrencies() {
  return (dispatch, getState) =>
    cachedGet(foreignExchange.lookupCurrencies)
      .then((response) => {
        dispatch({
          type: actions.REWARDS_GET_CURRENCIES,
          currencies: response,
          user: R.prop('user', getState()),
        });
      })
      .catch((e) => {
        dispatchError(e, dispatch);
      });
}

export function loadTaxabilityValues() {
  return (dispatch) =>
    cachedGet(rewards.mobilityLineItems)
      .then((response) => {
        dispatch({
          type: actions.REWARDS_GET_TAXABILITY_VALUES,
          taxabilityValues: response.data,
        });
      })
      .catch((e) => {
        dispatchError(e, dispatch);
      });
}

function getRewardById(response) {
  return {
    type: actions.REWARDS_GET_BY_ID,
    response,
  };
}

function getRewardStatus(response) {
  return {
    type: actions.REWARDS_GET_STATUS,
    response,
  };
}

export function loadRewardById(rewardId, origin = REQUEST_CONTEXT.REWARDS) {
  return (dispatch) => {
    dispatch({
      type: actions.FETCHING_REWARD_BY_ID,
      state: true,
    });
    return GET(`${rewards.rewardsRoot}/${rewardId}?origin=${origin}`, { Pragma: 'no-cache' })
      .then((response) => {
        dispatch(getRewardById(response));
        dispatch(etagUpdate(response.headers.etag));
        dispatch({
          type: actions.FETCHING_REWARD_BY_ID,
          state: false,
        });
        return R.path(['data', 'response'], response);
      })
      .catch((e) => {
        dispatchError(e, dispatch);
        dispatch({
          type: actions.FETCHING_REWARD_BY_ID,
          state: false,
        });
        throw e;
      });
  };
}
export function loadRewardStatus(rewardId, origin = REQUEST_CONTEXT.REWARDS) {
  return (dispatch) =>
    GET(`${rewards.rewardsRoot}/${rewardId}?origin=${origin}`, { Pragma: 'no-cache' })
      .then((response) => {
        dispatch(getRewardStatus(response));
        dispatch(etagUpdate(response.headers.etag));
      })
      .catch((e) => {
        dispatchError(e, dispatch);
      });
}

export function revertToDraft(rewardId, config) {
  return (dispatch, getState) =>
    PUT(
      `${rewards.rewardsRoot}/${rewardId}/moveToDraft`,
      {},
      { ...config.headers },
      { redirectToUnauthorized: false }
    )
      .then((response) => {
        dispatch(loadRewardById(rewardId));
        return response;
      })
      .catch((e) => {
        dispatchError(e, dispatch, getState());
        return Promise.reject(e);
      });
}

export function setRewardFeedback(rewardFeedback) {
  return {
    type: actions.SET_REWARD_FEEDBACK,
    rewardFeedback,
  };
}

export function setIsLoadingRewardFeedback(fetchingList) {
  return {
    type: actions.SET_IS_LOADING_REWARD_FEEDBACK,
    fetchingList,
  };
}

export const loadRewardFeedback = (rewardId, params) => (dispatch) => {
  dispatch(setIsLoadingRewardFeedback(true));
  const { sortAsc, pagingTop: top, pagingSkip: skip, searchText, filters } = params;
  let { sortBy } = params;

  if (sortBy === 'displayName') {
    sortBy = 'timestamp';
  }

  if (sortBy === 'feedbackScore') {
    sortBy = 'userScore';
  }

  const payload = {
    sort: {
      sortAsc,
      sortBy,
    },
    paging: {
      top,
      skip,
    },
    search: {
      searchText,
    },
    filters,
  };
  const query = qs.stringify({ rewardId });
  return POST(
    `${feedback.searchRewardFeedback}?${query}`,
    payload,
    {},
    { showErrorModal: true, dispatch }
  )
    .then((response) => {
      dispatch(setRewardFeedback(response.data));
      dispatch(setIsLoadingRewardFeedback(false));
    })
    .catch(() => {
      dispatch(setIsLoadingRewardFeedback(false));
    });
};

export function loadLinkableRewards({ resourceGroupId, resourceCountry, context }) {
  const getArgs = [
    {
      name: 'resourceGroupId',
      value: resourceGroupId,
    },
    {
      name: 'resourceCountry',
      value: resourceCountry,
    },
    {
      name: 'context',
      value: context,
    },
  ];

  const requestUrl = addGetParameters(rewards.linkableRewards, getArgs);

  return (dispatch) => {
    dispatch(getRewardsRequested());
    cachedGet(requestUrl, { Pragma: 'no-cache' })
      .then((data) => {
        dispatch(getLinkableRewards(data));
      })
      .catch((e) => {
        dispatchError(e, dispatch);
      });
  };
}

const getVideoPlatforms = (payload) => ({
  type: actions.GET_VIDEO_PLATFORMS_ACCEPTED,
  payload,
});

export const fetchVideoPlatforms = () => (dispatch) =>
  cachedGet(cms.videoPlatforms).then((response) => {
    dispatch(getVideoPlatforms(R.path(['data', 'response'], response)));
  });
