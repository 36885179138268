import React from 'react';
import APP_CONFIG from '@trs/config';
import { Chip } from '@trs/components';
import { isBlank, toLower, toUpper, processDate } from '@trs/utils';

import Text, { TextOnly } from '../../common/Text';

export const SELECTED_REWARDS_PREFIX = 'selectedRewardsIds';

export const SUPPORTED_EXTENSIONS = ['.csv', 'text/csv'];
export const FILE_MAX_SIZE = 20;

const chipStatus = {
  draft: 'default',
  published: 'primary',
  live: 'success',
  expired: 'warning',
};

export const reportKeys = {
  REPORT_P0071: 'selectedRewardsIdsP0071',
  REPORT_P0014: 'selectedRewardsIdsP0014',
};

export const reportTypes = {
  REPORT_P0071: 'P0071',
  REPORT_P0014: 'P0014',
  REPORT_EIB: 'EIB',
};

export const isBeaPackage = {
  TRUE: 'Yes',
  FALSE: 'No',
};

const { DATE_FORMAT, PACKAGE_STATUSES } = APP_CONFIG;

export const dateFormat = date => processDate(date, DATE_FORMAT);
export const formatData = data =>
  data.map(item => ({
    ...item,
    isBeaPackage: item.isBeaPackage
      ? TextOnly({ path: 'benefitsEnvelope.YES' })
      : TextOnly({ path: 'benefitsEnvelope.NO' }),
    newStatementDate: dateFormat(item.newStatementDate),
    endDate: dateFormat(item.endDate),
    startDate: dateFormat(item.startDate),
  }));

export const getTopBannerData = (basicInfo, labels) => {
  const { status, id } = basicInfo;
  const displayedKeys = {
    country: labels.PACKAGE_DETAILS_COUNTRY,
    enrolmentWindow: labels.ENROLLMENT_WINDOW,
  };
  const isDraft = status === PACKAGE_STATUSES.draft;
  const data = [];

  if (status) {
    data.push(
      <Chip
        key={`${id}-chip`}
        className="status-chip"
        type={chipStatus[toLower(status)]}
        outlined={isDraft}
      >
        <Text path={`benefitsEnvelope.STATUS_${toUpper(status)}`} />
      </Chip>
    );
  }
  Object.keys(displayedKeys).map(
    key =>
      basicInfo[key] &&
      data.push(
        <p key={`${id}-${key}`}>
          <span>{displayedKeys[key]}</span>
          <strong>{basicInfo[key]}</strong>
        </p>
      )
  );
  return data;
};

export const FORECAST_REWARDS_TYPES = {
  TEXT: 'Text',
  VARIABLE_LIST: 'Variable List',
};

export const getForecastRewardsTypes = () => [
  {
    value: FORECAST_REWARDS_TYPES.TEXT,
    label: TextOnly({ path: 'benefitsEnvelope.TYPE_TEXT' }),
    ariaLabel: `radio-${TextOnly({
      path: 'benefitsEnvelope.TYPE_TEXT',
    })}`,
    id: 'forecastRewardsTypeText',
    className: '',
    withNativeInput: true,
    iconChecked: 'ion-android-radio-button-on',
    iconUnchecked: 'ion-android-radio-button-off',
    naked: true,
  },
  {
    value: FORECAST_REWARDS_TYPES.VARIABLE_LIST,
    label: TextOnly({ path: 'benefitsEnvelope.TYPE_VARIABLE_LIST' }),
    ariaLabel: `radio-${TextOnly({
      path: 'benefitsEnvelope.TYPE_VARIABLE_LIST',
    })}`,
    id: 'forecastRewardsTypeVariableList',
    className: '',
    withNativeInput: true,
    iconChecked: 'ion-android-radio-button-on',
    iconUnchecked: 'ion-android-radio-button-off',
    naked: true,
  },
];

export const rewardsTypeChange = (onChange, rewardsType, field) => value => {
  const stateRewardsType = {
    ...rewardsType,
    [field]: value,
  };
  return onChange(stateRewardsType);
};

export const validationRules = () => ({
  rewardsSelection: [[isBlank, '', 'onChange', 'onSave']],
});
