import * as R from 'ramda';
import { createAction } from 'redux-actions';
import { getErrorMessage } from '@trs/utils';
import { GET, PUT } from '../../../../../config/http';

import { showBanner } from '../../../../../actions/globalActions';
import { auth } from '../../../../../config/api';

const actionTypes = {
  ROLES_SET_MODULE_PERMISSIONS: 'ROLES_SET_MODULE_PERMISSIONS',
  ROLES_APPLY_MODULE_PERMISSIONS: 'ROLES_APPLY_MODULE_PERMISSIONS',
  ROLES_GET_MODULE_PERMISSIONS: 'ROLES_GET_MODULE_PERMISSIONS',
  ROLES_GET_MODULE_PERMISSIONS_PENDING: 'ROLES_GET_MODULE_PERMISSIONS_PENDING',
  ROLES_GET_MODULE_PERMISSIONS_ERROR: 'ROLES_GET_MODULE_PERMISSIONS_ERROR',
};

export const setModulePermissions = createAction(actionTypes.ROLES_SET_MODULE_PERMISSIONS);
export const getModulePermissionsSuccess = createAction(actionTypes.ROLES_GET_MODULE_PERMISSIONS);
export const getModulePermissionsPending = createAction(
  actionTypes.ROLES_GET_MODULE_PERMISSIONS_PENDING
);
export const getModulePermissionsError = createAction(
  actionTypes.ROLES_GET_MODULE_PERMISSIONS_ERROR
);

function dispatchError(error, dispatch) {
  dispatch(
    showBanner({
      type: 'error',
      content: getErrorMessage(error),
    })
  );
}

export const saveModulePermissions = (roleId, modulePermissions) => (dispatch) => {
  return PUT(`${auth.roles}/${roleId}/permissions`, { modulePermissions })
    .then((response) => response)
    .catch((e) => {
      dispatchError(e, dispatch);
      return Promise.reject(e);
    });
};

export const getModulePermissions = (roleId) => (dispatch, getState) => {
  dispatch(getModulePermissionsPending());

  return GET(`${auth.roles}/${roleId}/permissions`, { Pragma: 'no-cache' })
    .then((response) => {
      const {
        lookup: { modules },
      } = getState();

      dispatch(
        getModulePermissionsSuccess({
          rolePermissions: R.path(['data', 'response', 'modulePermissions'], response),
          modules,
        })
      );
    })
    .catch((e) => {
      dispatch(getModulePermissionsError());
      dispatchError(e, dispatch);
      return Promise.reject(e);
    });
};
