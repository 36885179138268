import * as R from 'ramda';
import { createAction } from 'redux-actions';
import { offsetTimezone } from '@trs/utils';
import { GET, POST, PUT } from '../../../../../config/http';
import { showBanner } from '../../../../../actions/globalActions';
import { rewards } from '../../../../../config/api';
import { TextOnly } from '../../../../common/Text';
import { filterEmpty } from './helpers';
import { dispatchError } from '../../../../common/helpers';
import { onClear } from '../../../../../actions/enhancedDataTableActions';

const actionTypes = {
  LOAD_EMPLOYEES_PENDING: 'LOAD_EMPLOYEES_PENDING',
  LOAD_EMPLOYEES_SUCCESS: 'LOAD_EMPLOYEES_SUCCESS',
  LOAD_EMPLOYEES_ERROR: 'LOAD_EMPLOYEES_ERROR',
  SET_START_DATE: 'SET_START_DATE',
  SET_END_DATE: 'SET_END_DATE',
  OPEN_ENROLLMENT_WINDOW_SUCCESS: 'OPEN_ENROLLMENT_WINDOW_SUCCESS',
  RESET_STORE: 'RESET_STORE',
  EXPORT_WINDOWS_PENDING: 'EXPORT_WINDOWS_PENDING',
  EXPORT_WINDOWS_SUCCESS: 'EXPORT_WINDOWS_SUCCESS',
  EXPORT_WINDOWS_ERROR: 'EXPORT_WINDOWS_ERROR',
};

export const loadEmployeesPending = createAction(actionTypes.LOAD_EMPLOYEES_PENDING);
export const loadEmployeesSuccess = createAction(actionTypes.LOAD_EMPLOYEES_SUCCESS);
export const loadEmployeesError = createAction(actionTypes.LOAD_EMPLOYEES_ERROR);
export const setStartDate = createAction(actionTypes.SET_START_DATE);
export const setEndDate = createAction(actionTypes.SET_END_DATE);
export const openEnrollmentWindowSuccess = createAction(actionTypes.OPEN_ENROLLMENT_WINDOW_SUCCESS);
export const enrollmentWindowStoreReset = createAction(actionTypes.RESET_STORE);
export const exportWindowsPending = createAction(actionTypes.EXPORT_WINDOWS_PENDING);
export const exportWindowsSuccess = createAction(actionTypes.EXPORT_WINDOWS_SUCCESS);
export const exportWindowsError = createAction(actionTypes.EXPORT_WINDOWS_ERROR);

const getTextOnlyError = (error) => {
  const message = R.path(['response', 'data', 'errors', 0, 'message'], error);
  return TextOnly({ path: `flexibleRewards.${message}` }) || message;
};

export function loadEmployees(params) {
  const { sortAsc, pagingTop: top, pagingSkip: skip, searchText, filters } = params;
  let { sortBy } = params;

  if (sortBy === 'displayName') {
    sortBy = 'employeeName';
  }

  const payload = {
    sort: {
      sortAsc,
      sortBy,
    },
    paging: {
      top,
      skip,
    },
    search: {
      searchText,
    },
    filters,
  };

  return (dispatch) => {
    dispatch(loadEmployeesPending());

    dispatch(onClear());
    POST(`${rewards.employeeFlex}/search`, payload)
      .then((response) => {
        dispatch(loadEmployeesSuccess({ employees: R.path(['data', 'response'], response) }));
      })
      .catch((error) => {
        dispatchError(error, getTextOnlyError, dispatch);
        dispatch(loadEmployeesError());

        return Promise.reject(error);
      });
  };
}

export function setStartEnrollmentDate(startDate) {
  return (dispatch) => {
    dispatch(setStartDate(startDate));
  };
}

export function setEndEnrollmentDate(endDate) {
  return (dispatch) => {
    dispatch(setEndDate(endDate));
  };
}

export function openEnrollmentWindow(selectedEmployeeList, startDate, endDate) {
  const payload = {
    employeeInformation: filterEmpty(selectedEmployeeList),
    enrollmentWindow: {
      startDate: offsetTimezone(startDate),
      endDate: offsetTimezone(endDate),
    },
  };

  return (dispatch) => {
    return POST(`${rewards.employeeFlex}`, payload)
      .then(() => {
        dispatch(openEnrollmentWindowSuccess());
        dispatch(
          showBanner({
            type: 'success',
            content: TextOnly({ path: 'flexibleRewards.ENROLLMENT_WINDOW_SAVE_SUCCESS' }),
          })
        );
      })
      .catch((error) => {
        dispatchError(error, getTextOnlyError, dispatch);
        dispatch(loadEmployeesError());
        return Promise.reject(error);
      });
  };
}

export function closeEnrollmentWindow(employeeId, payload) {
  return (dispatch) => {
    return PUT(`${rewards.employeeFlex}/${employeeId}/enrollmentWindow`, payload)
      .then(() => {
        dispatch(
          showBanner({
            type: 'success',
            content: TextOnly({ path: 'flexibleRewards.ENROLLMENT_WINDOW_CLOSE_SUCCESS' }),
          })
        );
      })
      .catch((error) => {
        dispatchError(error, getTextOnlyError, dispatch);
        dispatch(loadEmployeesError());
        return Promise.reject(error);
      });
  };
}

export const exportPackageWindows = (packageId) => (dispatch) => {
  dispatch(exportWindowsPending());
  GET(`${rewards.employeeFlex}/enrollmentWindow/export/${packageId}`, null)
    .then(() => {
      dispatch(exportWindowsSuccess());
      dispatch(
        showBanner({
          type: 'info',
          content: TextOnly({
            path: 'flexibleRewards.EXPORT_WINDOWS_SUCCESS',
          }),
        })
      );
    })
    .catch(() => {
      dispatch(exportWindowsError());
      dispatch(
        showBanner({
          type: 'error',
          content: TextOnly({
            path: 'generic.BANNER_ERROR_GENERIC',
          }),
        })
      );
    });
};
