import { handleActions } from 'redux-actions';
import {
  getFolderData,
  sortList,
  getProcessData,
  startProcess,
  fileCheckOnServer,
  fileCheckReset,
  resetStore,
} from '../actions';
import { mapPayloadToModel, getSortConfig, emptyCurrentProcessSteps } from './helpers';

const DEFAULT_SORTING_STATE = {
  by: 'displayName',
  direction: 'asc',
};

export const initialState = {
  list: [],
  breadcrumbItems: [],
  hasWriteAccess: false,
  isLoading: false,
  errRedirect: null,
  sorting: DEFAULT_SORTING_STATE,
  processList: [],
  isProcessListLoading: false,
  processRunLoading: false,
  processIsRunning: false,
  checkingFileOnServer: false,
  isFileOnServer: false,
  allowedExtensions: [],
};

const reducer = handleActions(
  new Map([
    [
      sortList,
      (state, action) => {
        const { payload: by } = action;
        const { sorting: currentSorting, list } = state;
        const { newSorting, sortingFn } = getSortConfig(by, currentSorting);

        return {
          ...state,
          sorting: newSorting,
          list: sortingFn(list),
        };
      },
    ],
    [
      getFolderData,
      (state, action) => {
        const { ready, payload, error } = action;

        if (!ready) {
          return {
            ...state,
            isLoading: true,
          };
        }

        if (error) {
          return {
            ...state,
            isLoading: false,
            errRedirect: payload.page,
          };
        }

        return {
          ...state,
          list: mapPayloadToModel(payload.items, payload.hasWriteAccess),
          breadcrumbItems: payload.breadcrumbsItems,
          hasWriteAccess: payload.hasWriteAccess,
          isLoading: false,
          sorting: DEFAULT_SORTING_STATE,
          allowedExtensions: payload.allowedFiles,
        };
      },
    ],
    [
      getProcessData,
      (state, action) => {
        const { ready, payload, error } = action;

        if (!ready) {
          return {
            ...state,
            isProcessListLoading: true,
          };
        }

        if (error) {
          return {
            ...state,
            isProcessListLoading: false,
          };
        }

        return {
          ...state,
          processList: payload,
          isProcessListLoading: false,
          processRunLoading: false,
          processIsRunning: false,
        };
      },
    ],
    [
      startProcess,
      (state, action) => {
        const { ready, payload, error } = action;

        if (!ready) {
          return {
            ...state,
            processRunLoading: true,
            processIsRunning: false,
          };
        }

        if (error) {
          return {
            ...state,
            processRunLoading: false,
            processIsRunning: false,
          };
        }

        return {
          ...state,
          processList: emptyCurrentProcessSteps(payload, state.processList),
          processRunLoading: false,
          processIsRunning: true,
        };
      },
    ],
    [
      fileCheckOnServer,
      (state, action) => {
        const { ready, payload, error } = action;

        if (!ready) {
          return {
            ...state,
            isFileOnServer: false,
            checkingFileOnServer: true,
          };
        }

        if (error) {
          return {
            ...state,
            isFileOnServer: false,
            checkingFileOnServer: false,
          };
        }

        return {
          ...state,
          isFileOnServer: payload,
          checkingFileOnServer: false,
        };
      },
    ],
    [
      fileCheckReset,
      state => {
        return {
          ...state,
          isFileOnServer: false,
        };
      },
    ],
    [resetStore, () => initialState],
  ]),
  initialState
);

export default reducer;
