"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
var _constants = require("./constants");
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var StyledCheckbox = _styledComponents["default"].label.withConfig({
  displayName: "Checkboxstyle__StyledCheckbox",
  componentId: "sc-10q96g4-0"
})(["", ";"], function (props) {
  var disabled = props.disabled,
    type = props.type,
    checked = props.checked,
    hasError = props.hasError,
    small = props.small,
    large = props.large;
  var _props$theme = props.theme,
    spacing = _props$theme.spacing,
    palette = _props$theme.palette,
    typography = _props$theme.typography;
  var cursor = disabled ? 'not-allowed' : 'pointer';
  return (0, _styledComponents.css)(["cursor:", ";", " input{display:none;opacity:0;position:absolute;}i{font-size:", ";color:", ";}.checkbox-label{color:", ";margin-left:", ";transition:0.2s linear all;}.checkbox-icon{color:", ";}", ""], cursor, disabled && (0, _styledComponents.css)(["opacity:0.5;"]), typography.size.h2, checked ? palette.secondary : palette.gray.alpha, palette.gray.alpha, spacing.tiny, hasError ? "".concat(palette.validation.error, " !important") : '', type === _constants.checkboxTypes["switch"] && (0, _styledComponents.css)(["min-width:", ";min-width:", ";background-color:", ";display:flex;align-items:center;justify-content:space-between;border-radius:12px;transition:0.2s linear all;border:solid 1px ", ";cursor:", ";", " .checkbox-switch-label{color:", ";font-size:10px;font-weight:", ";margin:0 ", ";order:", ";text-align:center;text-transform:uppercase;}.checkbox-thumb{background-color:", ";border-radius:50%;border:solid 1px ", ";box-shadow:0 1px 1px 0 rgba(0,0,0,0.08);flex:0 0 ", ";height:", ";margin:-1px;order:", ";width:", ";}"], small ? spacing.large : '56px', large ? '100px' : '56px', checked ? _constants.TRACK_COLOR : palette.utility.bodyBackground, checked ? _constants.TRACK_COLOR : palette.utility.border, cursor, disabled && (0, _styledComponents.css)(["opacity:0.5;"]), checked ? palette.basic.white : palette.gray.sigma, typography.weight.bold, small ? spacing.xTiny : spacing.tiny, checked ? 0 : 1, _constants.THUMB_COLOR, checked ? _constants.TRACK_COLOR : palette.utility.border, small ? spacing.smallPlus : spacing.base, small ? spacing.smallPlus : spacing.base, checked ? 1 : 0, spacing.base));
});
StyledCheckbox.displayName = 'StyledCheckbox';
var _default = StyledCheckbox;
exports["default"] = _default;