"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
var StyledCollapseItem = _styledComponents["default"].div.withConfig({
  displayName: "CollapseItemstyle__StyledCollapseItem",
  componentId: "sc-v5lbeg-0"
})(["cursor:pointer;font-weight:500;"]);
StyledCollapseItem.displayName = 'StyledCollapseItem';
var _default = StyledCollapseItem;
exports["default"] = _default;