import { createSuffixedActions } from 'modules/config';

const actionSuffix = '_BETA';
const instanceActionTypesNames = [
  'ADD_COMPARE',
  'REMOVE_COMPARE',
  'SET_INITIAL_FIELDS',
  'RE_INITIALIZE_FIELDS',
  'LOAD_DATA_PENDING',
  'LOAD_DATA_SUCCESS',
  'LOAD_DATA_ERROR',
  'VALIDATE_MODELLER',
  'LOAD_MODELLER_OUTPUT',
  'VALIDATE_INSTANCE',
  'SELECT_EMPLOYEE',
  'SAVE_DEFAULT_PAYSCALE',
  'SAVE_DEFAULT_CURRENCY',
  'LOAD_ALL_DIMENSIONS_OPTIONS',
  'LOAD_DIMENSIONS_BY_DEFAULT_PENDING',
  'LOAD_DIMENSIONS_BY_DEFAULT_SUCCESS',
  'LOAD_DIMENSIONS_BY_DEFAULT_ERROR',
  'RESET_STORE',
];

const instanceActionTypes = createSuffixedActions(instanceActionTypesNames, actionSuffix);

export default instanceActionTypes;
