import React from 'react';
import PropTypes from 'prop-types';
import StyledLayout from './Layout.style';

const Layout = ({ children, isFixed, className }) => (
  <StyledLayout isFixed={isFixed} className={className}>
    {children}
  </StyledLayout>
);

Layout.defaultProps = {
  isFixed: false,
  className: 'layout-content',
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  isFixed: PropTypes.bool,
  className: PropTypes.string,
};

export default Layout;
