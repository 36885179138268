export const MIN_LENGTH = (fieldName, length) =>
  `${fieldName} should have at least ${length} ${length === 1 ? 'character' : 'characters'}`;
export const MAX_LENGTH = (fieldName, length) =>
  `${fieldName} should have maximum ${length} ${length === 1 ? 'character' : 'characters'}`;
export const REQUIRED = fieldName => `${fieldName} is required`;
export const VALID_FORMULA = () => 'All fields in the formula must have a value';
export const INVALID_NUMBER = fieldName => `${fieldName} is not valid`;
export const GREATER_THAN = (fieldName, value) => `${fieldName} must be greater than ${value}`;

export const getError = (template, field) => template.replace('%FIELD%', field);
export const greaterThanError = (template, first, second) =>
  template.replace('%FIELD1%', first).replace('%FIELD2%', second);

export const lengthError = (template, field, length) =>
  template.replace('%FIELD%', field).replace('%LENGTH%', length);
