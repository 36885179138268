"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var getBorderColor = function getBorderColor(_ref) {
  var error = _ref.error,
    disabled = _ref.disabled,
    isFocused = _ref.isFocused,
    color = _ref.color;
  if (error) return color.validation.error;
  if (disabled) return color.gray.charlie;
  if (isFocused) return color.secondary;
  return color.gray.bravo;
};
var StyledSearchable = _styledComponents["default"].span.withConfig({
  displayName: "SearchableDEPRECATEDstyle__StyledSearchable",
  componentId: "sc-ajem9n-0"
})(["", ";"], function (props) {
  var _props$theme = props.theme,
    color = _props$theme.palette,
    spacing = _props$theme.spacing,
    typography = _props$theme.typography;
  var value = props.value,
    isNumeric = props.isNumeric,
    error = props.error,
    disabled = props.disabled,
    employeeSearchable = props.employeeSearchable,
    searchIcon = props.searchIcon,
    itemsLength = props.itemsLength,
    width = props.width,
    isFocused = props.isFocused;
  var inputWidth = width || '200px';
  return (0, _styledComponents.css)(["position:relative;.formula-wrapper-label{display:inline-block;border-bottom:1px solid ", ";cursor:text;position:relative;height:40px;width:", ";padding-left:", ";i{top:0px;right:6px;pointer-events:none;}span{padding:1px;font-weight:normal;", ";}.formula-value,.formula-search-value{cursor:pointer;max-width:", ";vertical-align:top;display:inline-block;", " span{text-overflow:ellipsis;overflow:hidden;white-space:nowrap;display:block;}}.formula-value,.formula-search-value{overflow:hidden;text-overflow:ellipsis;white-space:nowrap;}span.formula-value .styled-tooltip{font-size:", ";max-width:400px;}input{border-bottom:0;padding-left:0;}.formula-search-value{padding-left:5px;&.formula-direction-rtl{direction:rtl;}}.list-menu{left:0;transform:translateY(101%);top:auto;bottom:0;right:auto;padding-top:", ";position:absolute;max-width:", ";overflow:hidden;button{overflow:hidden;white-space:nowrap;text-overflow:ellipsis;}&.searching-menu,&.empty-results-menu{.result-details{font-size:", ";font-weight:normal;}}.result-name{margin-bottom:", ";font-family:", ";}.result-details{font-size:", ";font-weight:300;.result-id,.result-country{display:inline;background-color:inherit;}.result-id{margin-right:", ";}}", ";}.searching-menu,.empty-results-menu{padding:12px 24px;margin:0;font-weight:normal;text-align:left;transform:translateY(102%);}.formula-search-value{padding:0;}.rule-formula{position:absolute;background-color:transparent;padding:0 0 ", ";width:", ";&.no-placeholder{width:2px;}", ";}.searchable-percent-toggle{position:absolute;right:0;top:0;label{width:25px;height:25px;margin:0 5px;i{font-size:", ";}}}", ";}"], getBorderColor({
    error: error,
    disabled: disabled,
    isFocused: isFocused,
    color: color
  }), inputWidth, spacing.tiny, employeeSearchable && (0, _styledComponents.css)(["&.formula-value,&.formula-search-value{max-width:90%;font-size:", ";line-height:19px;}"], typography.size.paragraph), isNumeric ? '110px' : '185px', disabled && (0, _styledComponents.css)(["color:", ";"], color.gray.charlie), typography.size.paragraph, spacing.tiny, width || '500px', typography.size.paragraph, spacing.tiny, typography.font.primary, typography.size.table, spacing.small, employeeSearchable && (0, _styledComponents.css)(["right:0;left:0;ul{", " max-height:421px;}"], itemsLength > 5 && (0, _styledComponents.css)(["overflow-y:scroll;"])), spacing.small, inputWidth, searchIcon && (0, _styledComponents.css)(["padding-right:", ";"], spacing.large), typography.size.misc, value && (0, _styledComponents.css)(["&.user-focused .formula-value{background-color:", ";}"], color.gray.delta));
});
StyledSearchable.displayName = 'StyledSearchable';
var _default = StyledSearchable;
exports["default"] = _default;