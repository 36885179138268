import React from 'react';
import PropTypes from 'prop-types';
import StyledRewardStatusBanner from './index.style';

const RewardStatusBanner = ({ className, children }) => (
  <StyledRewardStatusBanner className={className}>{children}</StyledRewardStatusBanner>
);

RewardStatusBanner.defaultProps = {
  className: 'reward-status-banner',
};

RewardStatusBanner.propTypes = {
  /**
   * Html class for the element
   */
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default RewardStatusBanner;
