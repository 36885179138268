import * as actions from '../actions/types';

export const initialState = {
  file: null,
  status: 'optional',
  isSaving: false,
};

export default function validationReducer(state = initialState, action) {
  switch (action.type) {
    case actions.VALIDATION_FIELD_CHANGE: {
      return {
        ...state,
        [action.field]: action.value,
      };
    }
    case actions.VALIDATION_SAVE_PENDING: {
      return {
        ...state,
        isSaving: true,
      };
    }
    default: {
      return state;
    }
  }
}
