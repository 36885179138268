import styled, { css } from 'styled-components';
import { media } from '@trs/utils';

export const ButtonGroupWrapper = styled.div`
  ${props => {
    const { spacing } = props.theme;

    return css`
      margin-top: 44px;
      display: flex;
      justify-content: space-between;
      margin-bottom: ${spacing.base};
      flex-direction: column;
      ${media.mobileLandscape`
        flex-direction: row;
      `}
    `;
  }}
`;

ButtonGroupWrapper.displayName = 'ButtonGroupWrapper';

export const ButtonGroup = styled.div`
  ${props => {
    const { spacing } = props.theme;

    return css`
      display: flex;
      margin-bottom: ${spacing.small};
      flex-direction: column;

      button {
        margin-bottom: ${spacing.small};
      }
      ${media.mobileLandscape`
        flex-direction: row;
        button {
          margin-bottom: 0;
          flex-grow: 1;
          &:not(:last-of-type) {
            margin-right: ${spacing.base};
          }
        }
      `}
    `;
  }};
`;

ButtonGroup.displayName = 'ButtonGroup';

export default ButtonGroup;
