import { handleActions } from 'redux-actions';
import {
  selectMobilityFile,
  uploadFilePending,
  uploadFileError,
  uploadFileSuccess,
  resetStore,
} from '../actions';
import initialState from './initialState';

const mobilityReducer = handleActions(
  new Map([
    [
      selectMobilityFile,
      (state, action) => {
        const {
          payload: { selectedFile, error },
        } = action;
        return {
          ...state,
          selectedFile,
          error,
        };
      },
    ],
    [
      uploadFilePending,
      state => {
        return {
          ...state,
          isUploadInProgress: true,
        };
      },
    ],
    [
      uploadFileError,
      state => {
        return {
          ...state,
          isUploadInProgress: false,
        };
      },
    ],
    [
      uploadFileSuccess,
      state => {
        return {
          ...state,
          isUploadInProgress: false,
          selectedFile: null,
          error: null,
        };
      },
    ],
    [resetStore, () => initialState],
  ]),
  initialState
);

export default mobilityReducer;
