import * as R from 'ramda';
import * as actionTypes from './actionTypes';
import { GET } from '../config/http';
import { configuration } from '../config/api';

export function setFeatureToggles(featureToggles) {
  return {
    type: actionTypes.CONFIGURATION_SET_FEATURE_TOGGLES,
    featureToggles,
  };
}

export const fetchFeatureToggles = toggleableFeatures => dispatch => {
  const qs = R.compose(
    R.join(','),
    R.map(feature => encodeURIComponent(feature))
  )(toggleableFeatures);

  return GET(`${configuration.featureToggles}?features=${qs}`, { Pragma: 'no-cache' })
    .then(response => {
      const featureToggles = R.path(['data'], response);
      dispatch(setFeatureToggles(featureToggles));
      return Promise.resolve(response);
    })
    .catch(err => {
      dispatch(setFeatureToggles([]));
      return Promise.reject(err);
    });
};
