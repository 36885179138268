import * as R from 'ramda';
import { GET, POST, PUT, CancelToken, addGetParameters } from 'config/http';
import { ltiPlans, rewards, foreignExchange, organization } from 'config/api';
import { REWARD_CONTEXTS } from '@trs/config';
import { withCache } from '../../common/timedCache';
import { createPrefixedAction } from '../config';
import dispatchError from './helpers';
import types from './types';

const cachedGet = withCache(GET, 3600);

export const getLtiPlansCurrencies = createPrefixedAction(types.GET_CURRENCIES);
export const getLtiPlansCompanyDefaultCurrency = createPrefixedAction(types.GET_DEFAULT_CURRENCY);
export const getLtiPlansCountries = createPrefixedAction(types.GET_COUNTRIES);
export const getLtiPlansFxSources = createPrefixedAction(types.GET_FX_SOURCES);
export const getLtiPlansFxRateDates = createPrefixedAction(types.GET_FX_RATE_DATES);
export const getLtiSystemVariables = createPrefixedAction(types.GET_SYSTEM_VARIABLES);
export const getLtiSystemVariablesLookup = createPrefixedAction(types.GET_SYSTEM_VARIABLES_LOOKUP);
export const getLtiEligibilityOperators = createPrefixedAction(types.GET_ELIGIBILITY_OPERATORS);
export const getLtiFormulaOperators = createPrefixedAction(types.GET_FORMULA_OPERATORS);

export const addBasicInformationChange = createPrefixedAction(types.ADD_BASIC_INFORMATION_CHANGES);
export const saveBasicInformation = createPrefixedAction(types.SAVE_BASIC_INFORMATION);
export const resetBasicInformation = createPrefixedAction(types.RESET_BASIC_INFORMATION);
export const getPlanByIdLoading = createPrefixedAction(types.GET_BY_ID_LOADING);
export const getPlanById = createPrefixedAction(types.GET_BY_ID);
export const getPlanStatusById = createPrefixedAction(types.GET_STATUS_BY_ID);
export const getAllPlansLoading = createPrefixedAction(types.GET_ALL_PLANS_LOADING);
export const getAllPlans = createPrefixedAction(types.GET_ALL_PLANS);

export const ruleChange = createPrefixedAction(types.RULE_CHANGE);
export const addRuleBlock = createPrefixedAction(types.ADD_RULE_BLOCK);
export const duplicateRuleBlock = createPrefixedAction(types.DUPLICATE_RULE_BLOCK);
export const deleteRuleBlock = createPrefixedAction(types.DELETE_RULE_BLOCK);
export const saveRulesBuilder = createPrefixedAction(types.SAVE_RULES_BUILDER);
export const resetRulesBuilder = createPrefixedAction(types.RESET_RULES_BUILDER);
export const getDistributionByType = createPrefixedAction(types.GET_DISTRIBUTION_BY_TYPE);
export const changeDistributionType = createPrefixedAction(types.CHANGE_DISTRIBUTION_TYPE);
export const setDataTableLoading = createPrefixedAction(types.DATA_TABLE_LOADING);
export const setTableTotal = createPrefixedAction(types.SET_TABLE_TOTAL);
export const changeTableCell = createPrefixedAction(types.CHANGE_TABLE_CELL);

export const etagUpdate = createPrefixedAction(types.ETAG_UPDATE);
export const resetStore = createPrefixedAction(types.RESET_STORE);
export const redirectToRoot = createPrefixedAction(types.REDIRECT_TO_ROOT);

export const saveUpdateBasicInformation = ({ id, payload, config }) => {
  if (!id) {
    return (dispatch, getState) =>
      POST(`${ltiPlans().serviceRoot}`, payload, {}, { redirectToUnauthorized: false })
        .then((response) => {
          dispatch(saveBasicInformation(R.prop('data', response)));
          dispatch(etagUpdate(response.headers.etag));
          return response;
        })
        .catch((error) => {
          dispatchError(error, dispatch, getState());
          return Promise.reject(error);
        });
  }

  return (dispatch, getState) =>
    PUT(
      `${ltiPlans().serviceRoot}/${id}`,
      payload,
      { ...config.headers },
      { redirectToUnauthorized: false }
    )
      .then((response) => {
        dispatch(saveBasicInformation(R.prop('data', response)));
        dispatch(etagUpdate(response.headers.etag));
        return response;
      })
      .catch((e) => {
        dispatchError(e, dispatch, getState());
        return Promise.reject(e);
      });
};

export function loadPlanById(ltiPlanId) {
  return (dispatch) => {
    dispatch(getPlanByIdLoading(true));

    return GET(`${ltiPlans().getLtiPlanById(ltiPlanId)}`, {
      Pragma: 'no-cache',
    })
      .then((response) => {
        const responseData = R.path(['data', 'response'], response);
        dispatch(getPlanById(responseData));
        dispatch(etagUpdate(response.headers.etag));
        dispatch(getPlanByIdLoading(false));

        return responseData;
      })
      .catch((e) => {
        dispatchError(e, dispatch);
        dispatch(getPlanByIdLoading(false));

        return Promise.reject(e);
      });
  };
}

export function loadPlanStatus(ltiPlanId) {
  return (dispatch) => {
    return GET(`${ltiPlans().getLtiPlanById(ltiPlanId)}`, {
      Pragma: 'no-cache',
    })
      .then((response) => {
        const responseData = R.path(['data', 'response'], response);
        dispatch(getPlanStatusById(responseData));
        dispatch(etagUpdate(response.headers.etag));

        return responseData;
      })
      .catch((e) => {
        dispatchError(e, dispatch);
        dispatch(getPlanByIdLoading(false));

        return Promise.reject(e);
      });
  };
}

export function loadAllPlans(params) {
  const { sortAsc, pagingTop: top, pagingSkip: skip, searchText, filters } = params;
  let { sortBy } = params;

  if (sortBy === 'displayName') {
    sortBy = 'name';
  }

  if (sortBy === 'template') {
    sortBy = 'template';
  }

  if (sortBy === 'country') {
    sortBy = 'country';
  }

  if (sortBy === 'vesting') {
    sortBy = 'vestingType';
  }

  if (sortBy === 'effectiveDate') {
    sortBy = 'startDate';
  }

  const payload = {
    sort: {
      sortAsc,
      sortBy,
    },
    paging: {
      top,
      skip,
    },
    search: {
      searchText,
    },
    filters,
  };

  return (dispatch) => {
    dispatch(getAllPlansLoading(true));

    POST(`${ltiPlans().search}`, payload)
      .then((response) => {
        dispatch(getAllPlans({ response }));
      })
      .catch((e) => {
        dispatch(getAllPlansLoading(false));
        dispatchError(e, dispatch);
      });
  };
}

export function loadCurrencies() {
  return (dispatch) =>
    GET(`${foreignExchange.lookupCurrencies}`, {
      Pragma: 'no-cache',
    })
      .then((response) => {
        dispatch(
          getLtiPlansCurrencies({
            currencies: R.prop('data', response),
          })
        );
      })
      .catch((e) => {
        dispatchError(e, dispatch);
      });
}

export const loadCompanyDefaultCurrency = () => (dispatch) => {
  GET(`${organization.currency}`, {
    Pragma: 'no-cache',
  })
    .then((response) => {
      dispatch(
        getLtiPlansCompanyDefaultCurrency({
          defaultCurrency: R.path(['data', 'response'], response),
        })
      );
    })
    .catch((e) => {
      dispatchError(e, dispatch);
    });
};

export function loadCountries() {
  return (dispatch, getState) =>
    cachedGet(organization.lookupCountries)
      .then((response) => {
        dispatch(
          getLtiPlansCountries({
            error: null,
            countries: R.prop('data', response),
            user: R.prop('user', getState()),
          })
        );
      })
      .catch((e) => {
        dispatchError(e, dispatch);
      });
}

export function loadFxSources() {
  return (dispatch) =>
    GET(foreignExchange.fxSources)
      .then((response) => {
        dispatch(getLtiPlansFxSources({ fxSources: R.prop('data', response) }));
      })
      .catch((e) => {
        dispatchError(e, dispatch);
      });
}

export function loadFxRateDates() {
  let requestUrl = rewards.systemVariables;
  const getArgs = [{ name: 'variableType', value: 'datetime' }];
  requestUrl = addGetParameters(requestUrl, getArgs);

  return (dispatch) =>
    cachedGet(requestUrl, { Pragma: 'no-cache' })
      .then((response) => {
        dispatch(getLtiPlansFxRateDates({ fxRateDates: R.prop('data', response) }));
      })
      .catch((e) => {
        dispatchError(e, dispatch);
      });
}

export function setBasicInformationChange({ field, value, other }) {
  return (dispatch) => {
    dispatch(
      addBasicInformationChange({
        field,
        value,
        other,
      })
    );
  };
}

export function loadSystemVariables(variableType = '') {
  let requestUrl = rewards.systemVariables;

  if (variableType) {
    const getArgs = [{ name: 'variableType', value: variableType }];
    requestUrl = addGetParameters(requestUrl, getArgs);
  }

  return (dispatch) =>
    cachedGet(requestUrl, { Pragma: 'no-cache' })
      .then((data) => {
        dispatch(getLtiSystemVariables(data));
      })
      .catch((e) => {
        dispatchError(e, dispatch);
      });
}

function getVariablesLookupData(lookupData, url) {
  return {
    type: getLtiSystemVariablesLookup,
    lookupData,
    url,
  };
}

export function loadSystemVariablesLookup(url) {
  return (dispatch) =>
    cachedGet(`${rewards.systemVariables}/${url}`)
      .then((data) => {
        dispatch(getVariablesLookupData(data, url));
      })
      .catch((e) => {
        dispatchError(e, dispatch);
      });
}

export function loadEligibilityOperators() {
  return (dispatch) =>
    cachedGet(rewards.eligibilityOperators)
      .then((data) => {
        dispatch(getLtiEligibilityOperators(data));
      })
      .catch((e) => {
        dispatchError(e, dispatch);
      });
}

export function loadFormulaOperators() {
  return (dispatch) =>
    cachedGet(rewards.formulaOperators)
      .then((response) => {
        dispatch(getLtiFormulaOperators(response.data));
      })
      .catch((e) => {
        dispatchError(e, dispatch);
      });
}

let cancelOperandCall;

/** user defined variables **/
export function getFormulaOperandValues({ resourceGroupId, resourceCountry }) {
  return () => (searchValue) => {
    if (!searchValue) {
      return Promise.resolve([]);
    }

    if (cancelOperandCall) cancelOperandCall.cancel('Formula Operand call cancelled.');

    cancelOperandCall = CancelToken.source();
    const getArgs = [
      {
        name: 'resourceGroupId',
        value: resourceGroupId,
      },
      {
        name: 'resourceCountry',
        value: resourceCountry,
      },
      {
        name: 'context',
        value: REWARD_CONTEXTS.ltiPlans,
      },
    ];

    const requestUrl = addGetParameters(
      `${rewards.formulaOperand}/${encodeURIComponent(searchValue)}`,
      getArgs
    );

    return GET(requestUrl, {
      cancelToken: cancelOperandCall.token,
    }).then((response) => response.data);
  };
}

export function setRuleChange(index, field, value, other) {
  return (dispatch) => {
    dispatch(ruleChange({ ruleData: { index, field, value, other } }));
  };
}

export function loadDistribution({ id, type }) {
  return (dispatch) => {
    const getArgs = [
      {
        name: 'type',
        value: type === 1 ? 'Automatic' : 'Manual',
      },
    ];

    const requestUrl = addGetParameters(`${ltiPlans().getDistribution(id)}`, getArgs);

    return GET(requestUrl)
      .then((response) => {
        const responseData = R.path(['data', 'response'], response);
        dispatch(getDistributionByType(responseData));

        return responseData;
      })
      .catch((e) => {
        dispatchError(e, dispatch);
      });
  };
}

export function saveRules({ id, payload, config }) {
  return (dispatch, getState) =>
    PUT(
      `${ltiPlans().rules(id)}`,
      payload,
      { ...config.headers },
      { redirectToUnauthorized: false }
    )
      .then((response) => {
        dispatch(saveRulesBuilder(R.prop('data', response)));
        dispatch(etagUpdate(response.headers.etag));

        return response;
      })
      .catch((e) => {
        dispatchError(e, dispatch, getState());
        return Promise.reject(e);
      });
}

export function publishLtiPlan({ id, payload = {}, config: { headers } }) {
  return (dispatch, getState) =>
    PUT(`${ltiPlans().publish(id)}`, payload, { ...headers }, { redirectToUnauthorized: false })
      .then((response) => {
        dispatch(etagUpdate(response.headers.etag));
      })
      .catch((e) => {
        dispatchError(e, dispatch, getState());
        return Promise.reject(e);
      });
}

export function revertToDraft({ id, payload = {}, config: { headers } }) {
  return (dispatch, getState) =>
    PUT(`${ltiPlans().moveToDraft(id)}`, payload, { ...headers }, { redirectToUnauthorized: false })
      .then((response) => {
        return response;
      })
      .catch((e) => {
        dispatchError(e, dispatch, getState());
        return Promise.reject(e);
      });
}
