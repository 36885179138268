import * as R from 'ramda';
import { toLower } from '@trs/utils';
import * as actions from '../actions/types';
import { isSetupCompleted } from './helpers';

const initialState = {
  modules: [],
  isSetupCompleted: true,
  isFetchingModules: false,
};

export default function mainReducer(state = initialState, action) {
  switch (action.type) {
    case actions.REQUEST_SETTINGS_MODULES: {
      return {
        ...state,
        isFetchingModules: true,
      };
    }
    case actions.GET_SETTINGS_MODULES: {
      const modules = R.map(
        item => ({
          name: toLower(item.moduleId),
          readonly: !item.isEditable,
          mandatory: item.isRequired,
          completed: item.isCompleted,
        }),
        action.response
      );

      return {
        ...state,
        modules,
        isSetupCompleted: isSetupCompleted(modules),
        isFetchingModules: false,
      };
    }
    default: {
      return state;
    }
  }
}
