import * as R from 'ramda';
import { createAction } from 'redux-actions';
import { TextOnly } from '../../../common/Text';
import actionTypes from './types';
import {
  auth,
  modeller,
  organization,
  foreignExchange,
  rewards,
  mobility,
} from '../../../../config/api';
import createModels from '../util/createModels';
import { GET, POST, CancelToken } from '../../../../config/http';
import { withCache } from '../../../common/timedCache';
import { mapValuesToKeys } from './utils';
import { validateModellerInstances } from './helpers';
import { showBanner } from '../../../../actions/globalActions';
import userManager from '../../../../config/userManager/userManager';

const cachedGet = withCache(GET, 600);
const ANNUAL_SALARY = 'Annual salary';
const ANNUAL_SALARY_ID = '111';

export const loadModellerOutput = () => (dispatch, getState) => {
  const {
    modeller: { mainReducer, instancesReducer },
  } = getState();

  return dispatch({
    type: actionTypes.LOAD_MODELLER_OUTPUT,
    payload: POST(modeller.model, createModels(mainReducer, instancesReducer), {
      RequestId: mainReducer.requestId,
    }).then((response) => response.data),
  });
};

export const validateModeller = () => (dispatch, getState) => {
  const {
    modeller: {
      instancesReducer: { instances, instanceFields },
    },
  } = getState();
  const modellerValidation = validateModellerInstances(instances, instanceFields);

  dispatch({
    type: actionTypes.VALIDATE_MODELLER,
    payload: modellerValidation,
  });

  if (modellerValidation.isModellerValid) {
    dispatch(loadModellerOutput());
  } else {
    showBanner({
      type: 'error',
      content: TextOnly({ path: 'generic.BANNER_FORM_ERROR_GENERIC' }),
    });
  }
};

export const loadBaseVariables = () => (dispatch) => {
  const getVariables = cachedGet(modeller.variables);
  const getCountries = cachedGet(organization.lookupCountries);
  const getCurrencies = cachedGet(foreignExchange.lookupCurrencies);
  const getSources = cachedGet(foreignExchange.fxSources);
  const getCategories = GET(rewards.category);
  const getLocations = cachedGet(mobility.locations);

  return dispatch({
    type: actionTypes.LOAD_VARIABLES,
    payload: Promise.all([
      getVariables,
      getCountries,
      getCurrencies,
      getSources,
      getCategories,
      getLocations,
    ]).then((values) => mapValuesToKeys(values)),
  });
};

export const setRequestId = (requestId) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_REQUEST_ID,
    payload: requestId,
  });
};

export function toggleSearchDialog(instanceId) {
  return {
    type: actionTypes.TOGGLE_SEARCH_DIALOG,
    payload: instanceId,
  };
}

export const toggleModellerProfile = () => ({
  type: actionTypes.TOGGLE_MODELLER_PROFILE,
});

let cancelOperandCall;

export const getEmployees = (requestID, searchText) => () => {
  const payload = {
    search: {
      searchText,
    },
  };

  if (cancelOperandCall) cancelOperandCall.cancel('Modeller getEmployee call cancelled.');

  cancelOperandCall = CancelToken.source();

  return POST(modeller.employeeSearch, payload, {
    Pragma: 'no-cache',
    RequestId: requestID,
    cancelToken: cancelOperandCall.token,
  });
};

export const resetStore = () => ({
  type: actionTypes.RESET_STORE,
});

export const getModellerCategoriesMapping = createAction(actionTypes.GET_CATEGORIES_MAPPING);
export const getModellerUserPermissions = createAction(actionTypes.GET_MODELLER_PERMISSIONS);
export const submitActions = {
  pending: createAction(actionTypes.SUBMIT_CATEGORIES_MAPPING_PENDING),
  success: createAction(actionTypes.SUBMIT_CATEGORIES_MAPPING_SUCCESS),
  error: createAction(actionTypes.SUBMIT_CATEGORIES_MAPPING_ERROR),
};

export const changeCategoriesMapping = createAction(actionTypes.CHANGE_CATEGORIES_MAPPING);

export const resetCategoriesMapping = createAction(actionTypes.RESET_CATEGORIES_MAPPING);

const mapModelToPayload = (mappings) => {
  let categories = R.keys(mappings).map((key) => {
    if (mappings[key].mappingName !== ANNUAL_SALARY) {
      const [, , rewardLevel3CategoryId] = key.split('/');
      const { isIncluded } = mappings[key];
      return { rewardLevel3CategoryId, isIncluded };
    }
    return null;
  });

  categories = categories.filter(
    (category) => category !== null && category.rewardLevel3CategoryId !== ANNUAL_SALARY_ID
  );
  return categories;
};

export const submitCategoriesMapping = () => (dispatch, getState) => {
  const { etag, mappings: categoriesMappings } = R.path(
    ['modeller', 'configurationReducer'],
    getState()
  );

  const categories = mapModelToPayload(categoriesMappings);

  const categoriesMappingsLabel = TextOnly({ path: 'modeller.CONFIGURATION_PAGE_TITLE' });

  dispatch(submitActions.pending());

  return POST(modeller.configuration, { categories }, { Etag: etag })
    .then((response) => {
      dispatch(
        showBanner({
          type: 'success',
          content: TextOnly({
            path: 'generic.SUBMIT_SUCCESS',
            transform: (label) => label.replace('%NAME%', categoriesMappingsLabel),
          }),
        })
      );
      return dispatch(submitActions.success({ id: R.path(['data', 'response', 'id'], response) }));
    })
    .catch((err) => {
      dispatch(showBanner({ type: 'error' }));
      dispatch(submitActions.error());
      return Promise.reject(err);
    });
};

export const loadCategoriesMapping = () => (dispatch) => {
  dispatch(
    getModellerCategoriesMapping(
      GET(modeller.configuration).then((response) => ({
        categoriesMappings: R.path(['data', 'response', 'categories'], response),
        status: R.path(['data', 'response', 'status'], response),
        etag: R.path(['headers', 'etag'], response),
      }))
    )
  );
};

export const loadUserPermissions = (dispatch) => {
  dispatch(
    getModellerUserPermissions(
      GET(`${auth.users}/permissions/MODELLER`).then((response) => {
        const permissions = R.path(['data'], response);

        userManager.setModellerModules(permissions);

        return {
          permissions,
        };
      })
    )
  );
};

export const actions = {
  toggleSearchDialog,
  getEmployees,
  resetStore,
  toggleModellerProfile,
  getModellerCategoriesMapping,
  getModellerUserPermissions,
};
