import { createGlobalStyle } from 'styled-components';
import APP_CONFIG from '@trs/config';

import '../assets/css/ionicons.min.css';
import '../assets/css/matter.css';

const theme = APP_CONFIG.theme.base;
export const SCROLLBAR_WEIGHT = '6px';

const GlobalStyle = createGlobalStyle`

  html, body, #root {
    height: 100%;
    /* For Firefox */
    scrollbar-color: ${theme.palette.gray.bravo} ${theme.palette.gray.delta};
    scrollbar-width: thin;
  }

  body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    background-color: ${theme.palette.utility.bodyBackground};
    box-sizing: border-box;
    color: ${theme.palette.gray.alpha};
    font-family: ${theme.typography.font.primary};
    margin: 0;
    padding: 0;
  }

  .App {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    height: 100%;
    
    &.customiser-open {
      padding-left: 300px;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
  }

  /* For Firefox */
  .ReactVirtualized__Grid {
    scrollbar-color: ${theme.palette.gray.bravo} ${theme.palette.gray.delta};
    scrollbar-width: thin;
  }

  
  .App-footer {
    flex-shrink: 0;
  }

  @supports (-webkit-overflow-scrolling: touch) {
    /* Fix on click outside functionality on iOS devices */
    body {
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;
    }
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* This is added as a quick-fix for ticket 1332 */
    .ie11-fix {
      margin-top: 100px;
    }
  }

  * {
    box-sizing: border-box;
    outline: none;
    font-variant-ligatures: none;
  }
  input {
    font-family: ${theme.typography.font.primary};
  }
  
  ::-webkit-scrollbar {
    width: ${SCROLLBAR_WEIGHT};
    height: ${SCROLLBAR_WEIGHT};
  }

  ::-webkit-scrollbar-track {
    background-color: ${theme.palette.gray.delta};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${theme.palette.gray.bravo};
    border-radius: 4px;
    &:hover {
      background-color: ${theme.palette.basic.gray};
    }
  }
`;

export default GlobalStyle;
