import { handleActions } from 'redux-actions';
import * as R from 'ramda';
import * as actions from '../actions';

import { getModelFromPayload, getNewModelPermissions } from './helpers';

export const initialState = {
  modulePermissions: [],
  isLoading: false,
  isError: false,
};

const reducer = handleActions(
  new Map([
    [
      actions.getModulePermissionsPending,
      state => ({
        ...state,
        isLoading: true,
      }),
    ],
    [
      actions.getModulePermissionsError,
      state => ({
        ...state,
        isLoading: false,
        isError: true,
      }),
    ],
    [
      actions.getModulePermissionsSuccess,
      (state, action) => {
        const modulePermissions = getModelFromPayload(R.prop('payload', action));

        return {
          ...state,
          modulePermissions,
          isLoading: false,
        };
      },
    ],
    [
      actions.setModulePermissions,
      (state, action) => ({
        ...state,
        modulePermissions: getNewModelPermissions(state, R.prop('payload', action)),
      }),
    ],
  ]),
  initialState
);

export default reducer;
