import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { FormattedNumber, InputV2, Button } from '@trs/components';
import { MAX_DECIMALS } from '@trs/config';
import { handleEnterKeypress, getEventValue, isBlank } from '@trs/utils';
import StyledEditableField from './EditableField.style';
import Slider from './slider/Slider';
import { EDITABLE_INPUT_TYPE, INPUT_MAX } from './helpers';
import Text, { TextOnly } from '../Text';

class EditableField extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      innerValue: R.path(['payload', 'value'], props),
      isEditing: false,
    };
  }

  onChange = (ev) => {
    if (ev.target && this.props.isIntegerOnly) {
      const event = { ...ev };
      event.target.value = Math.round(event.target.value);
    }

    this.setState({ innerValue: getEventValue(ev) });
  };

  onBlur = (ev) => this.setState({ innerValue: this.props.onBlur(getEventValue(ev)) });

  toggleEditing = () => {
    const isEditing = !this.state.isEditing;
    this.setState((prevState) => ({ isEditing: !prevState.isEditing }));
    if (this.props.toggleIsEditing) this.props.toggleIsEditing(isEditing);
  };

  confirmChange = (ev) => {
    const { onChange, payload } = this.props;
    const { innerValue } = this.state;

    if (getEventValue(ev) === 'yes') {
      this.toggleEditing();
      return onChange(null, true, innerValue);
    }
    this.toggleEditing();
    return this.setState({ innerValue: R.prop('value', payload) });
  };

  render() {
    const {
      payload,
      currency,
      isEditable,
      disabled,
      showSlider,
      inputType,
      maxInputLength,
      editingWidth,
      editLabel,
      isIntegerOnly,
      id,
      isPercentage,
      hasDecimals,
    } = this.props;
    const { isEditing, innerValue } = this.state;

    const isTextOnlyReward = !R.isNil(R.path(['textValue'], payload));
    return (
      <StyledEditableField
        fullWidthSlider={showSlider && isEditing}
        editingWidth={editingWidth}
        hasSlider={showSlider}
        isEditing={isEditing}
        className="editable-field-container"
      >
        {isEditing && (
          <Fragment>
            {showSlider ? (
              <Slider
                isPercentage={isPercentage}
                onChange={this.onChange}
                isIntegerOnly={isIntegerOnly}
                min={isBlank(payload.min) ? 0 : payload.min}
                max={isBlank(payload.max) ? innerValue : payload.max}
                value={parseFloat(innerValue)}
              />
            ) : (
              <InputV2
                id={id}
                className="editable-field-input"
                maxLength={maxInputLength}
                onChange={this.onChange}
                onBlur={this.onBlur}
                onKeyDown={handleEnterKeypress(() => this.confirmChange('yes'))}
                type={inputType}
                value={innerValue}
                ariaLabel={TextOnly({ path: 'flexibleRewards.REWARD_VALUE' })}
              />
            )}
            <div className="editable-field-confirm">
              <Button className="field-save" medium action={() => this.confirmChange('yes')}>
                <Text path="generic.BUTTON_SAVE" />
              </Button>
              <Button
                className="field-cancel"
                medium
                type="link"
                action={() => this.confirmChange('no')}
              >
                <Text path="generic.BUTTON_CANCEL" />
              </Button>
            </div>
          </Fragment>
        )}

        {!isEditing &&
          !isTextOnlyReward &&
          (inputType === 'number' ? (
            <strong className="editable-field-value">
              <FormattedNumber
                value={R.prop('value', payload)}
                isPercentage={isPercentage}
                decimals={MAX_DECIMALS}
                hasDecimals={hasDecimals}
              />
              {currency && !isPercentage && <span> {currency}</span>}
            </strong>
          ) : (
            R.prop('value', payload)
          ))}

        {isEditable && !isEditing && (
          <Button
            className="editable-field-action"
            action={this.toggleEditing}
            type="link"
            disabled={disabled}
            icon="ion-edit"
            aria-label="Edit value"
          >
            {editLabel}
          </Button>
        )}
      </StyledEditableField>
    );
  }
}

EditableField.propTypes = {
  id: PropTypes.string,
  onBlur: PropTypes.func,
  editLabel: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  toggleIsEditing: PropTypes.func,
  disabled: PropTypes.bool,
  showSlider: PropTypes.bool,
  payload: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    min: PropTypes.number,
    max: PropTypes.number,
  }).isRequired,
  currency: PropTypes.string,
  isEditable: PropTypes.bool.isRequired,
  inputType: PropTypes.string,
  maxInputLength: PropTypes.number,
  editingWidth: PropTypes.number,
  isIntegerOnly: PropTypes.bool,
  isPercentage: PropTypes.bool,
  hasDecimals: PropTypes.bool,
};

EditableField.defaultProps = {
  onBlur: (x) => x,
  editLabel: '',
  id: '',
  disabled: false,
  inputType: EDITABLE_INPUT_TYPE,
  maxInputLength: INPUT_MAX,
  currency: '',
  toggleIsEditing: null,
  showSlider: false,
  editingWidth: 0,
  isIntegerOnly: false,
  isPercentage: false,
  hasDecimals: false,
};

export default EditableField;
