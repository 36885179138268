import audit from './audit';
import dataValidation from './dataValidation';
import feedback from './feedback';
import mobility from './mobility';

export default {
  PAGE_TITLE: 'Home',
  SELECT_COUNTRY: 'Select country',
  WIDGET_SEARCH_EMPLOYEE_LABEL: 'Search by employee name, email or ID',
  WIDGET_MIMIC_EMPLOYEE_TITLE: 'Mimic employee',
  WIDGET_MIMIC_EMPLOYEE_TABLE_HEADER_ID: 'Id',
  WIDGET_MIMIC_EMPLOYEE_TABLE_HEADER_NAME: 'Name',
  WIDGET_MIMIC_EMPLOYEE_TABLE_HEADER_EMAIL: 'Email',
  WIDGET_MIMIC_EMPLOYEE_MIMIC: 'Mimic',
  WIDGET_EXCEPTIONS_TITLE: 'Exceptions',
  WIDGET_EXCEPTIONS_TOTAL_EXCEPTIONS: 'Total exceptions',
  WIDGET_EXCEPTIONS_ADDED_TODAY: 'Exceptions added today',
  WIDGET_SYSTEM_AUDIT: 'System Audit',
  WIDGET_AUDIT_PERFORM_AUDIT: 'Perform Audit',
  WIDGET_REWARDS_OVERVIEW: 'Rewards Overview',
  WIDGET_REWARDS_OVERVIEW_LIVE: 'Live',
  WIDGET_REWARDS_OVERVIEW_PUBLISHED: 'Published',
  WIDGET_REWARDS_OVERVIEW_DRAFT: 'Draft',
  WIDGET_REWARDS_OVERVIEW_EXPIRED: 'Expired',
  WIDGET_REWARDS_OVERVIEW_ERROR: 'Error',
  WIDGET_REWARDS_WILL_EXPIRE_TOMORROW: 'Rewards will expire tomorrow',
  WIDGET_REWARDS_WILL_TURN_LIVE_TOMORROW: 'Rewards will turn live tomorrow',
  WIDGET_TOTAL_REWARDS: '%COUNTRY% rewards',
  NO_WIDGETS: 'Please use the navigation to go to a specific section.',
  NO_ACCESS_FOR_SELECTED_COUNTRY: 'You do not have access to this module for the selected country.',
  ...audit,
  ...dataValidation,
  ...feedback,
  ...mobility,
};
