import { sessionStorageManager, isBlank, EXPIRED_TIME_KEY } from '@trs/utils';
import moment from 'moment';

class IdleTimer {
  constructor({
    timeout,
    warningTimeOut,
    preventStartTimer = false,
    callbacks: { onTimeout, onExpired, onWarningInactivity, resetWarningInactivity } = {},
  } = {}) {
    this.timeout = timeout;
    this.warningTimeOut = warningTimeOut;
    this.onTimeout = onTimeout;
    this.onExpired = onExpired;
    this.onWarningInactivity = onWarningInactivity;
    this.resetWarningInactivity = resetWarningInactivity;
    this.setWarningInactivityTimer = false;
    this.preventStartTimer = preventStartTimer;
    this.interval = null;
    this.timeoutTracker = null;

    this.eventHandler = this.updateTimeout.bind(this);
    this.tracker();
  }

  start(stopTimer = false) {
    this.cleanupIfExpired();

    /** generate first expired time */
    this.updateExpiredTime(stopTimer);
  }

  stop() {
    clearInterval(this.interval);
    clearInterval(this.timeoutTracker);
    this.resetWarningInactivity();
  }

  restart() {
    this.preventStartTimer = false;
    this.stop();
    this.start(true);
  }

  initTimerInterval() {
    this.setWarningInactivityTimer = false;

    /** the interval logic to track every second */
    this.interval = setInterval(() => {
      const expiredTime = parseInt(sessionStorageManager.getItem(EXPIRED_TIME_KEY) || 0, 10);
      const nowInMs = Date.now();
      const minutesDiff = Math.floor(moment(expiredTime).diff(nowInMs) / 1000);

      /** set a warning inactivity with `warningTimeOut` minutes before session is set to be terminated due to inactivity */
      if (
        !this.setWarningInactivityTimer &&
        !isBlank(this.warningTimeOut) &&
        minutesDiff <= this.warningTimeOut
      ) {
        this.onWarningInactivity();
        this.setWarningInactivityTimer = true;
      }

      if (expiredTime < Date.now()) {
        if (this.onTimeout) {
          this.cleanup();
          this.onTimeout();
        }
      }
    }, 2000);
  }

  updateTimeout() {
    this.updateExpiredTime();
  }

  updateExpiredTime(stopTimer = false) {
    if (this.preventStartTimer) {
      return;
    }

    if (!stopTimer) {
      this.stop();
    }

    this.timeoutTracker = setTimeout(() => {
      /** Calculate new expire time */
      sessionStorageManager.setItem(EXPIRED_TIME_KEY, Date.now() + this.timeout * 1000);
      this.initTimerInterval();
    }, 300);
  }

  cleanupIfExpired() {
    const expiredTime = parseInt(sessionStorageManager.getItem(EXPIRED_TIME_KEY) || 0, 10);
    if (!isBlank(expiredTime) && expiredTime > 0 && expiredTime < Date.now()) {
      this.cleanup();
      this.onExpired();
    }
  }

  setTimeout(timeoutValue) {
    this.timeout = timeoutValue;
  }

  /** Consider click and keydown events as activity on the app */
  tracker() {
    window.addEventListener('click', this.eventHandler);
    window.addEventListener('keydown', this.eventHandler);
  }

  /** Remove item from storage and event listener */
  cleanup() {
    this.stop();
    sessionStorageManager.removeItem(EXPIRED_TIME_KEY);
    window.removeEventListener('click', this.eventHandler);
    window.removeEventListener('keydown', this.eventHandler);
  }
}

export default IdleTimer;
