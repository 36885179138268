import * as R from 'ramda';
import { createAction } from 'redux-actions';
import { isBlank } from '@trs/utils';
import { encodeValuesInRules } from 'modules/common/helpers';
import { GET, RequestFactory } from '../../../../../../config/http';
import { rewards } from '../../../../../../config/api';
import { withCache } from '../../../../../common/timedCache';
import { getRequestPayload } from './helpers';
import { updateBenchmarkingError } from '../../../global/actions/helpers';
import { validateEligibilitySimplePopulation } from '../helpers';
import { dispatchError } from '../../helpers';

const ROOT_TYPE = 'BENCHMARKING/POPULATION';

export const saveActions = {
  pending: createAction(`${ROOT_TYPE}/SAVE/PENDING`),
  success: createAction(`${ROOT_TYPE}/SAVE/SUCCESS`),
  error: createAction(`${ROOT_TYPE}/SAVE/ERROR`),
};

export const actionTypes = {
  BENCHMARKING_ADD_POPULATION: 'BENCHMARKING/ADD_POPULATION',
  BENCHMARKING_DUPLICATE_POPULATION: 'BENCHMARKING/DUPLICATE_POPULATION',
  BENCHMARKING_DELETE_POPULATION: 'BENCHMARKING/DELETE_POPULATION',
  BENCHMARKING_POPULATION_CHANGE: 'BENCHMARKING/POPULATION_CHANGE',
  BENCHMARKING_GET_ELIGIBILITY_OPERATORS: 'BENCHMARKING/GET_ELIGIBILITY_OPERATORS',
  BENCHMARKING_GET_SYSTEM_VARIABLES: 'BENCHMARKING/GET_SYSTEM_VARIABLES',
  BENCHMARKING_GET_SYSTEM_VARIABLES_LOOKUP_DATA: 'BENCHMARKING/GET_SYSTEM_VARIABLES_LOOKUP_DATA',
  BENCHMARKING_SAVE_GLOBAL_SETUP: 'BENCHMARKING/SAVE_GLOBAL_SETUP',
  BENCHMARKING_GET_ELIGIBILITY_RULES: 'BENCHMARKING/GET_ELIGIBILITY_RULES',
  BENCHMARKING_INIT_POPULATIONS: 'BENCHMARKING/INIT_POPULATIONS',
  BENCHMARKING_LOAD_PAYSCALE: 'BENCHMARKING_LOAD_PAYSCALE',
  BENCHMARKING_PAYSCALE_INIT: 'BENCHMARKING/PAYSCALE/INIT',
  BENCHMARKING_PAYSCALE_ADD: 'BENCHMARKING/PAYSCALE/ADD',
  BENCHMARKING_PAYSCALE_DUPLICATE: 'BENCHMARKING/PAYSCALE/DUPLICATE',
  BENCHMARKING_PAYSCALE_DELETE: 'BENCHMARKING/PAYSCALE/DELETE',
  BENCHMARKING_PAYSCALE_UPDATE: 'BENCHMARKING/PAYSCALE/UPDATE',
  BENCHMARKING_POPULATION_ERROR: 'BENCHMARKING_POPULATION_ERROR',
};

export const getEligibilityOperators = createAction(
  actionTypes.BENCHMARKING_GET_ELIGIBILITY_OPERATORS
);
export const getVariablesLookupData = createAction(
  actionTypes.BENCHMARKING_GET_SYSTEM_VARIABLES_LOOKUP_DATA
);
export const addPopulation = createAction(actionTypes.BENCHMARKING_ADD_POPULATION);
export const duplicatePopulation = createAction(actionTypes.BENCHMARKING_DUPLICATE_POPULATION);
export const deletePopulation = createAction(actionTypes.BENCHMARKING_DELETE_POPULATION);
export const populationChange = createAction(actionTypes.BENCHMARKING_POPULATION_CHANGE);
export const getSystemVariables = createAction(actionTypes.BENCHMARKING_GET_SYSTEM_VARIABLES);
export const saveGlobalSetup = createAction(actionTypes.BENCHMARKING_SAVE_GLOBAL_SETUP);
export const getPopulationData = createAction(actionTypes.BENCHMARKING_GET_ELIGIBILITY_RULES);
export const initPopulations = createAction(actionTypes.BENCHMARKING_INIT_POPULATIONS);
export const loadPayScale = createAction(actionTypes.BENCHMARKING_LOAD_PAYSCALE);
export const updatePayScaleField = createAction(actionTypes.BENCHMARKING_PAYSCALE_UPDATE);
export const initPayScaleIntervals = createAction(actionTypes.BENCHMARKING_PAYSCALE_INIT);
export const addPayScaleInterval = createAction(actionTypes.BENCHMARKING_PAYSCALE_ADD);
export const duplicatePayScaleInterval = createAction(actionTypes.BENCHMARKING_PAYSCALE_DUPLICATE);
export const deletePayScaleInterval = createAction(actionTypes.BENCHMARKING_PAYSCALE_DELETE);

export const updatePopulationError = createAction(actionTypes.BENCHMARKING_POPULATION_ERROR);
const cachedGet = withCache(GET, 3600);

export const populationDelete = index => (dispatch, getState) => {
  const state = getState();
  const errors = R.clone(R.path(['benchmarking', 'global', 'main', 'errors', 'population'], state));
  if (errors.length > 0) {
    errors.splice(index, 1);
  }
  dispatch(deletePopulation({ index }));
  dispatch(updateBenchmarkingError({ path: ['population'], data: errors }));
};

export const changePopulationField = (index, field, value, other) => (dispatch, getState) => {
  const state = getState();
  const errors = R.clone(R.path(['benchmarking', 'global', 'main', 'errors', 'population'], state));
  const newErrors = errors[index] || [];

  if (R.path(['eligibility', R.path(['rule', 'uid'], other)], newErrors)) {
    const newPopulation = R.prop('rule', other);
    newErrors.eligibility[newPopulation.uid] = validateEligibilitySimplePopulation(newPopulation);
  }

  dispatch(populationChange({ index, field, value, other }));
  dispatch(updatePopulationError({ path: ['population', index], data: newErrors }));
};

const getPayScaleIntervalsModel = payScaleIntervals => {
  if (isBlank(payScaleIntervals)) return {};
  const model = {};
  payScaleIntervals.map(({ populationId, min, max }) => {
    model[populationId] = { min, max };
    return true;
  });
  return model;
};

export const loadPayScaleIntervals = payScaleIntervals => dispatch => {
  dispatch(loadPayScale(getPayScaleIntervalsModel(payScaleIntervals)));
};

export const loadPopulationsById = populationsId => dispatch => {
  const opts = {
    url: `${rewards.eligibilityRules}/${populationsId}`,
    method: 'GET',
    headers: { Pragma: 'no-cache' },
  };

  if (isBlank(populationsId)) {
    return Promise.reject();
  }
  return RequestFactory(opts.method, opts.url, opts.headers)
    .then(body => {
      const eligibilityRules = R.path(['data', 'response', 'eligibilityRules'], body);
      const id = R.path(['data', 'response', 'id'], body);
      const etag = R.path(['headers', 'etag'], body);
      return dispatch(getPopulationData({ eligibilityRules, id, etag }));
    })
    .catch(body => {
      const err = R.path(['response', 'data', 'errors'], body);
      return Promise.reject(err);
    });
};

export const savePopulation = () => (dispatch, getState) => {
  const state = getState();
  const population = R.path(['benchmarking', 'population'], state);
  const populationId = R.path(['benchmarking', 'population', 'id'], state);
  const populationEtag = R.path(['benchmarking', 'population', 'etag'], state);

  const deepCopyRules = JSON.parse(JSON.stringify(getRequestPayload(population)));
  const requestPayload = {
    ...deepCopyRules,
    eligibilityRules: deepCopyRules.eligibilityRules.map(currRule => ({
      ...currRule,
      eligibility: encodeValuesInRules(currRule.eligibility),
    })),
  };

  const opts = {
    url: `${rewards.eligibilityRules}/${populationId || ''}`,
    method: populationId ? 'PUT' : 'POST',
    headers: populationId ? { Etag: populationEtag } : {},
    data: requestPayload,
  };
  dispatch(saveActions.pending());

  return RequestFactory(opts.method, opts.url, opts.headers, opts.data)
    .then(body => {
      dispatch(
        saveActions.success({
          etag: R.path(['headers', 'etag'], body),
          ...R.prop('response', body),
        })
      );
      return Promise.resolve(body);
    })
    .catch(body => {
      const err = R.path(['response', 'data', 'errors'], body);
      dispatch(saveActions.error(err));
      return Promise.reject(err);
    });
};

export const loadEligibilityOperators = () => dispatch =>
  cachedGet(rewards.eligibilityOperators).then(response =>
    dispatch(getEligibilityOperators(R.prop('data', response)))
  );

export const loadSystemVariables = () => dispatch =>
  cachedGet(rewards.systemVariables, { Pragma: 'no-cache' }).then(response =>
    dispatch(getSystemVariables(R.prop('data', response)))
  );

export const loadSystemVariablesLookup = url => dispatch =>
  cachedGet(`${rewards.systemVariables}/${url}`)
    .then(response =>
      dispatch(getVariablesLookupData({ lookupData: R.prop('data', response), url }))
    )
    .catch(e => dispatchError(e, dispatch));

export const updatePayScale = ({ key, value, populationId }) => dispatch => {
  dispatch(updatePayScaleField({ key, value, populationId }));

  dispatch(updatePopulationError({ path: ['payScaleIntervals', populationId, key], data: null }));
};
