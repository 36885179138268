import * as R from 'ramda';
import { groupCountriesByCluster, getActiveCount } from '../../countryDetails/helpers';
import BM_STATUS from '../../../../common/helpers';

export const getWaitingForApprovalCount = R.compose(
  R.length,
  R.filter(
    ({ approvalFlow }) =>
      !approvalFlow.isCompleted &&
      R.contains(BM_STATUS.SUBMITTED, R.values(R.pluck('status', approvalFlow)))
  )
);

export const getCompletedCount = R.compose(
  R.length,
  R.filter(({ approvalFlow }) => approvalFlow.isCompleted)
);

const getClusterInfo = countries => {
  const clusters = R.dissoc('All', groupCountriesByCluster(countries));
  const data = R.keys(clusters).map(cluster => {
    const clusterCountries = clusters[cluster];

    return {
      clusterName: cluster,
      totalCountries: R.length(clusterCountries),
      completed: getCompletedCount(clusterCountries),
      waitingForApproval: getWaitingForApprovalCount(clusterCountries),
    };
  });

  return R.sortBy(R.prop('clusterName'))(data);
};

export const mapBEtoFE = (countries = []) => {
  const totalCountries = R.length(countries);
  const activeCountries = getActiveCount(countries);
  const inactiveCountries = totalCountries - activeCountries;
  const countriesWaitingForApproval = getWaitingForApprovalCount(countries);

  return {
    globalSettings: {
      setupFinished: true,
      data: {
        activeCountries,
        inactiveCountries,
        countriesWaitingForApproval,
      },
    },
    clusters: getClusterInfo(countries),
  };
};

export const mapClusterCountries = (countries = [], globalBenchmarkingId) =>
  R.map(
    item => ({
      ...item,
      localPreparation: R.path(['approvalFlow', 'localPreparation', 'status'], item),
      salaryIncrease: R.path(['approvalFlow', 'salaryIncrease', 'status'], item),
      payScaleIncrease: R.path(['approvalFlow', 'payScaleIncrease', 'status'], item),
      globalBenchmarkingId,
    }),
    countries
  );

export default { mapBEtoFE, mapClusterCountries };
