export default {
  SALARY_INCREASE_TITLE: 'Salary Increase',
  SALARY_INCREASE_PROPOSAL: 'Salary Increase Proposal',
  NON_SALARY_RELATED_ALLOWANCE_INCREASE: 'Non-Salary Related Allowance Increase',
  MERIT_INCREASE: 'Merit Increase',
  COLLECTIVE_INCREASE: 'Collective Increase',
  GENERAL_MANDATORY_INCREASE: 'General Mandatory Increase',
  TOTAL_SALARY_INCREASE: 'Total Salary Increase',
  SALARY_PROPOSAL_SAVE_SUCCESS: 'Salary increase proposal saved successfully',
  MERIT_P50: 'P50',
  MERIT_P75: 'P75',
  PRE_MERIT_TITLE: '%view%: Pre-Merit',
  POST_MERIT_TITLE: '%view%: Post-Merit',
  MERIT_P50_TITLE: 'P50: Pre-Merit',
  MERIT_P75_TITLE: 'P75: Pre-Merit',
  POST_MERIT_P50_TITLE: 'P50: Post-Merit',
  POST_MERIT_P75_TITLE: 'P75: Post-Merit',
  MERIT_TARGET_TOTAL_CASH: 'Target Total Cash',
  MERIT_TARGET_TOTAL_DIRECT_COMPENSATION: 'Target Total Direct Compensation',
  MERIT_ACTUAL_CASH: 'Actual Total Cash',
  MERIT_TOTAL_REWARD: 'Total Reward',
  MERIT_CALCULATING: 'Calculation is now in progress, this might take a while.',
  MERIT_ERROR: 'We have encountered an error while getting the data. Please try again later.',
  MERIT_POPULATION_NAME: 'Population',
  MERIT_POPULATION_COUNT: '#',
  MERIT_COMPENSATION_DATA: 'Compensation Data',
  MERIT_CALCULATE_ALL: 'Calculate All',
  MERIT_LAST_CALCULATED: 'Last calculated at: <strong>%DATE%</strong>',
  SALARY_INCREASE_SUBMIT_SUCCESS: 'Salary Increase was successfully submitted.',
  SALARY_INCREASE_APROVED_SUCCESS: 'Salary Increase was successfully approved.',
  SALARY_INCREASE_REJECT_SUCCESS: 'Salary Increase was rejected.',
  SALARY_INCREASE_REJECTION_TITLE: '"Salary Increase" has been rejected.',
  MERIT_NO_DATA: 'No available data. Press the calculate button to continue',
  SALARY_INCREASE_AVERAGE: 'Weighted Average',
};
