/* eslint-disable react/jsx-filename-extension */
import { isBlank, processDate, dateDaysDiff } from '@trs/utils';
import { PACKAGE_AVAILABILITY_THRESHOLD } from '@trs/config';
import React, { Fragment } from 'react';
import { FormattedNumber } from '@trs/components';
import * as R from 'ramda';
import Text, { TextOnly } from '../../../../common/Text';
import EllipsisTooltip from '../../../../common/EllipsisTooltip';

export const fixStatementBody = toFix => {
  const statementBody = document.querySelector('.statement-body');
  if (isBlank(statementBody)) return false;
  const height = statementBody.offsetHeight;
  statementBody.style.height = toFix ? `${height}px` : 'auto';
  return true;
};

export const toggleCardBorder = show => {
  const cards = document.querySelectorAll('.Card,.option-tile');
  if (isBlank(cards)) return false;
  cards.forEach(card => {
    if (show) {
      // eslint-disable-next-line no-param-reassign
      card.style.border = '1px solid #cecece';
    } else {
      card.style.removeProperty('border');
    }
  });
  return true;
};

export const getValidationWarnings = metaData => {
  const messageList = [];
  const allowNegative = R.prop('canExceedBudget', metaData);
  const currencyCode = R.prop('currencyCode', metaData);
  const remaining = R.prop('remainingBudget', metaData);
  const original = R.prop('originalBudget', metaData);
  const minimumUsage = R.prop('minimumUsageBudget', metaData);
  const budgetLabel = R.prop('budgetLabel', metaData);
  const excess = R.prop('excessBudget', metaData) || Number.MAX_VALUE;
  const hasExceeded = remaining + excess < 0;

  if (!allowNegative && remaining < 0) {
    messageList.push({
      id: 'SBS_VALIDATIONS_CANNOT_GO_NEGATIVE',
      text: TextOnly({
        path: 'flexibleRewards.SBS_VALIDATIONS_CANNOT_GO_NEGATIVE',
        transform: (label = '') => label.replace('%LABEL%', budgetLabel),
      }),
    });
  }

  if (allowNegative && hasExceeded) {
    messageList.push({
      id: 'SBS_VALIDATIONS_EXCEEDED_BUDGET',
      text: TextOnly({
        path: 'flexibleRewards.SBS_VALIDATIONS_EXCEEDED_BUDGET',
        transform: (label = '') => label.replace('%LABEL%', budgetLabel),
      }),
    });
  }

  if (!isBlank(minimumUsage) && original - remaining < minimumUsage) {
    messageList.push({
      id: 'SBS_VALIDATIONS_MINIMUM_USAGE',
      text: TextOnly({
        path: 'flexibleRewards.SBS_VALIDATIONS_MINIMUM_USAGE',
        transform: (label = '') =>
          label
            .replace('%AMOUNT%', FormattedNumber({ value: minimumUsage }))
            .replace('%CURRENCY%', currencyCode),
      }),
    });
  }

  return messageList;
};

export const getValidSelection = selectedRewards => {
  const messageList = [];
  const keys = R.keys(selectedRewards);

  keys.forEach(key => {
    const payload = R.prop('payload', selectedRewards[key]);
    const selectedValue = Number(R.prop('value', payload));
    const min = Number(R.prop('min', payload));
    const max = Number(R.prop('max', payload));

    if (!isBlank(R.prop('min', payload)) && selectedValue < min) {
      messageList.push({
        id: 'SBS_REWARD_SLIDER_VALIDATION_BELOW',
        text: TextOnly({
          path: 'flexibleRewards.SBS_REWARD_SLIDER_VALIDATION_BELOW',
          transform: (label = '') =>
            label.replace('%REWARD%', R.prop('name', selectedRewards[key])),
        }),
      });
    }
    if (!isBlank(R.prop('max', payload)) && selectedValue > max) {
      messageList.push({
        id: 'SBS_REWARD_SLIDER_VALIDATION_ABOVE',
        text: TextOnly({
          path: 'flexibleRewards.SBS_REWARD_SLIDER_VALIDATION_ABOVE',
          transform: (label = '') =>
            label.replace('%REWARD%', R.prop('name', selectedRewards[key])),
        }),
      });
    }
  });

  return messageList;
};

export const getAvailableDays = endDate => {
  const difference = dateDaysDiff(endDate, new Date());
  return (
    <Fragment>
      <strong> {processDate(endDate)} </strong>
      {difference <= PACKAGE_AVAILABILITY_THRESHOLD && (
        <strong className="countdown-warning">
          <Text
            path="flexibleRewards.AMOUNT_OF_DAYS_LEFT"
            transform={(label = '') => label.replace('%COUNT%', difference)}
          />
        </strong>
      )}
    </Fragment>
  );
};

export const getTopBannerData = (user, endDate) => {
  const endDateLabel = endDate
    ? TextOnly({ path: 'flexibleRewards.SELECTION_AVAILABLE_UNTIL' })
    : '';

  return (
    <>
      {user.personalDetailsSetting &&
        user.personalDetailsSetting
          .sort((a, b) => a.displayOrder - b.displayOrder)
          .map(detail => (
            <div className="info-prop profile-details__info" key={detail.mappingField}>
              <EllipsisTooltip
                className="info-label"
                content={detail.label}
                dataTip={detail.label}
                dataFor={detail.label}
              />
              :{' '}
              <EllipsisTooltip
                className="info-value"
                content={R.path(['profile', detail.mappingField], user) || ''}
                dataTip={R.path(['profile', detail.mappingField], user) || ''}
                dataFor={R.path(['profile', detail.mappingField], user) || ''}
              />
            </div>
          ))}
      {endDate && (
        <div className="info-prop profile-details__info" key="endDateKey">
          <p className="detail-label">{endDateLabel}</p>:
          <strong className="detail-value">{getAvailableDays(endDate)}</strong>
        </div>
      )}
    </>
  );
};

export default {
  toggleCardBorder,
  getValidationWarnings,
  getValidSelection,
};
