import React from 'react';
import * as R from 'ramda';
import { InputError } from '@trs/components';
import { FILE_ENCODING, getSize } from '@trs/utils';

const isValidFileExtension = (extensions, name) => {
  return R.any(item => name.match(item), extensions);
};

export const ERROR_CODES = {
  EXTENSION_NOT_SUPPORTED: 'EXTENSION_NOT_SUPPORTED',
  MAX_SIZE_EXCEEDED: 'MAX_SIZE_EXCEEDED',
};

export const handleFileInput = ({
  inputEvent,
  maxSize,
  sizeUnit,
  supportedFileExtensions,
  beforeFileLoaded,
  onFileLoaded,
  onError,
}) => {
  const reader = new FileReader();
  const file = R.path(['target', 'files', 0], inputEvent);
  const name = R.prop('name', file);
  const size = getSize(sizeUnit, R.prop('size', file));
  const isExtensionValid = isValidFileExtension(supportedFileExtensions, name);

  if (!isExtensionValid && onError) {
    return onError(ERROR_CODES.EXTENSION_NOT_SUPPORTED, inputEvent);
  }
  if (onError && maxSize && size > maxSize) {
    return onError(ERROR_CODES.MAX_SIZE_EXCEEDED, inputEvent);
  }

  if (file) {
    beforeFileLoaded(file);
    reader.onload = event => {
      return onFileLoaded(file, event);
    };
    reader.readAsText(file, FILE_ENCODING);
  }
  return null;
};

export const handleFileRemove = ({ onFileLoaded }) => {
  const event = { target: { result: '' } };
  onFileLoaded(null, event);
};

/* eslint-disable react/jsx-filename-extension */
export const getErrorMessages = ({ id, errors }) => {
  let errorMessages = null;

  if (errors) {
    if (typeof errors === 'string') {
      errorMessages = <InputError message={errors} id={`${id}Error0`} />;
    } else {
      errorMessages = R.map(
        (error, index) => (
          <InputError key={`${id}-${error}`} id={`${id}Error${index}`} message={error} />
        ),
        R.values(errors)
      );
    }
  }

  return errorMessages;
};
