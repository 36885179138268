const VARIABLE_TYPES = {
  value: 1,
  list: 2,
};
const AMOUNT_TYPES = {
  numeric: 1,
  percentage: 2,
};

const initialState = {
  list: [],
  variableTypes: [],
  totalResults: 0,
  fetchingList: true,
  errors: [],
  countries: [],
  savedVariable: {
    displayName: '',
    country: {},
    countryCode: '',
    value: {
      type: AMOUNT_TYPES.numeric,
      amount: null,
    },
    type: VARIABLE_TYPES.value,
    items: [],
    etag: null,
  },
  isDirty: false,
  affectedRewards: {},
  filterOptions: [],
  fetchingVariableCountries: true,
  loadError: null,
  isSavingVariable: false,
  isFetchingById: false,
  listTypeErrors: {},
};

export default initialState;
