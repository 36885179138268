import styled, { css } from 'styled-components';
import * as R from 'ramda';
import { media } from '@trs/utils';

const StyledFooterBanner = styled.div`
  ${props => {
    const {
      palette,
      spacing,
      typography,
      components: { footer },
    } = props.theme;

    const backgroundColor = R.prop('cookieNoticeBackground', footer) || palette.primary;
    const textColor = R.prop('cookieNoticeText', footer) || palette.basic.white;

    return css`
      background-color: ${backgroundColor};
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      color: ${textColor};
      padding: ${spacing.tiny} 0;
      z-index: 5;

      .layout {
        margin-bottom: 0;
        display: flex;
        align-items: center;
        padding: 0 ${spacing.small};

        > p {
          flex-grow: 1;
          margin: 0 ${spacing.basePlus} 0 0;
          font-size: ${typography.size.misc};
        }
      }

      .btn {
        background: ${palette.basic.white};
        font-size: ${typography.size.misc};
        padding: 0 ${spacing.small};
        line-height: ${typography.lineHeight.paragraph};
        min-width: 52px;
      }

      ${media.desktop`
      padding: ${spacing.xSmall} 0;
        .layout {
          padding: 0 ${spacing.xxLarge};

          > p {
            font-size: ${typography.size.paragraph};
          }
        }

        .btn {
          font-size: ${typography.size.paragraph};
          padding: ${spacing.tiny} ${spacing.base};
          line-height: ${typography.lineHeight.misc};
        }
      `};
    `;
  }}
`;
StyledFooterBanner.displayName = 'StyledFooterBanner';
export default StyledFooterBanner;
