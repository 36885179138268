import { formatNumber } from '@trs/utils/lib/general';
import * as R from 'ramda';
import { v4 as uuid } from 'uuid';
import { TextOnly } from '../../../../common/Text';

export const formatNumberWithDecimals = (number) => {
  const decimals = Number.isInteger(number) ? 0 : 2;

  return formatNumber(parseFloat(number).toFixed(decimals));
};

export const exportPieChart = (impact) => {
  const summary = R.prop('summary', impact);
  return [
    {
      Status: 'active',
      value: summary.employeesAffected,
    },
    {
      Status: 'inactive',
      value: summary.totalEmployees - summary.employeesAffected,
    },
  ];
};

export const exportDetailPieChart = (impact) => {
  const { summary, detail } = impact;

  return R.map((item) => {
    const percentage = item.eligibleEmployeesCount
      ? (item.eligibleEmployeesCount / summary.employeesAffected) * 100
      : 0;

    return {
      Status: 'active',
      label: `${percentage.toFixed(2)}%`,
      multiline: TextOnly({ path: 'rewards.RULES_BUILDER_WIDGET_OVERALL_IMPACT_LABEL_EMPLOYEES' }),
      grade:
        item.grade || TextOnly({ path: 'rewards.RULES_BUILDER_WIDGET_OVERALL_IMPACT_GRADE_EMPTY' }),
      value: item.eligibleEmployeesCount,
      amount: `${formatNumberWithDecimals(item.totalCost)} ${item.currency}`,
      isAmount: false,
      key: uuid(),
    };
  }, detail.detailItems);
};

export const calculatePercent = (impact) => {
  const summary = R.prop('summary', impact);
  const percentage =
    summary.totalEmployees !== 0 ? (summary.employeesAffected / summary.totalEmployees) * 100 : 0;
  return parseInt(percentage, 10);
};

export default { exportPieChart, exportDetailPieChart, calculatePercent };
