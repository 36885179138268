export default {
  COUNTRIES_TITLE: 'Company countries',
  COUNTRIES_SUBTITLE: "Please set the company's countries",
  COUNTRIES_CAPTION:
    'Countries will be used when creating rewards, variables and other application features.',
  COUNTRIES_BODY:
    'Please select in which country the company operates and set the default currency for them.',
  NO_COUNTRIES: 'There are no available countries',
  SHOW_SELECTED_COUNTRIES: 'Show selected countries only',
  SET_CURRENCY_FOR: 'Set currency for',
  SELECT_CURRENCY_LABEL: 'Select currency',
  COUNTRIES_BANNER_SUCCESS: 'Countries have been updated.',
  EDIT_CURRENCY: 'Edit currency',
};
