import styled, { css } from 'styled-components';
import { media } from '@trs/utils';
import * as R from 'ramda';

const StyledLogo = styled.div`
  ${props => {
    const {
      spacing,
      typography,
      palette,
      components: { header },
    } = props.theme;
    const { isLoading } = props;

    const accentColor = R.prop('accentColor', header) || palette.primary;
    return css`
      .logo-container {
        top: ${spacing.xSmall};
        display: flex;
        align-items: center;
        position: absolute;

        span {
          color: ${accentColor};
          width: 87px;
          font-weight: ${typography.weight.thick};
          font-size: ${typography.size.misc};
          ${media.tablet`
            font-size: ${typography.size.paragraph};
          `};
          ${!isLoading &&
            css`
              margin-left: ${spacing.small};
            `}
          border-left: 2px solid ${accentColor};
          padding-left: ${spacing.tiny};
          text-transform: uppercase;
        }
        .logo {
          width: auto;
          max-width: 100px;
          max-height: ${spacing.basePlus};
        }
      }

      ${media.desktop`

        .logo-container {
          top: 20px;
          span {
            font-size: ${typography.size.paragraph};
          }
          .logo {
            width: auto;
            max-height: 57px;
          }
        }
      `};
    `;
  }};
`;
StyledLogo.displayName = 'StyledLogo';

export default StyledLogo;
