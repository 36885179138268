import { SIZE_UNIT } from '@trs/utils';
import { ERROR_CODES } from 'modules/common/Form/FileInput/helpers';
import { TextOnly } from 'modules/common/Text';

export const ICON_SIZE = 20;
export const STATUSES = {
  notUploaded: 'NotUploaded',
  processing: 'Processing',
  processedSuccessfully: 'ProcessedSuccessfully',
  processedWithErrors: 'ProcessedWithErrors',
  exceptionOccurred: 'ExceptionOccurred',
};

export const FILE_MAX_SIZE = 20;

export const getErrorMessages = (KEYWORD = '') => {
  return {
    [ERROR_CODES.EXTENSION_NOT_SUPPORTED]: TextOnly({
      path: 'generic.FILE_ERROR_INCORRECT_FILE_FORMAT',
      transform: (label = '') => label.replace('%FIELD%', KEYWORD),
    }),
    [ERROR_CODES.MAX_SIZE_EXCEEDED]: TextOnly({
      path: 'generic.FILE_ERROR_MAX_SIZE_EXCEEDED',
      transform: (label = '') =>
        label.replace('%FIELD%', KEYWORD).replace('%MAX_SIZE%', `${FILE_MAX_SIZE} ${SIZE_UNIT.mb}`),
    }),
  };
};

export default {
  ICON_SIZE,
  STATUSES,
  FILE_MAX_SIZE,
  SIZE_UNIT,
  getErrorMessages,
};
