export const types = {
  GET_CURRENCIES: 'GET_CURRENCIES',
  GET_DEFAULT_CURRENCY: 'GET_DEFAULT_CURRENCY',
  GET_COUNTRIES: 'GET_COUNTRIES',
  GET_FX_SOURCES: 'GET_FX_SOURCES',
  GET_FX_RATE_DATES: 'GET_FX_RATE_DATES',
  GET_SYSTEM_VARIABLES: 'GET_SYSTEM_VARIABLES',
  GET_SYSTEM_VARIABLES_LOOKUP: 'GET_SYSTEM_VARIABLES_LOOKUP',
  GET_FORMULA_OPERATORS: 'GET_FORMULA_OPERATORS',
  GET_ELIGIBILITY_OPERATORS: 'GET_ELIGIBILITY_OPERATORS',
  ADD_BASIC_INFORMATION_CHANGES: 'ADD_BASIC_INFORMATION_CHANGES',
  SAVE_BASIC_INFORMATION: 'SAVE_BASIC_INFORMATION',
  RESET_BASIC_INFORMATION: 'RESET_BASIC_INFORMATION',
  GET_BY_ID_LOADING: 'GET_BY_ID_LOADING',
  GET_BY_ID: 'GET_BY_ID',
  GET_STATUS_BY_ID: 'GET_STATUS_BY_ID',
  GET_ALL_PLANS_LOADING: 'GET_ALL_PLANS_LOADING',
  GET_ALL_PLANS: 'GET_ALL_PLANS',
  RULE_CHANGE: 'RULE_CHANGE',
  ADD_RULE_BLOCK: 'ADD_RULE_BLOCK',
  DUPLICATE_RULE_BLOCK: 'DUPLICATE_RULE_BLOCK',
  DELETE_RULE_BLOCK: 'DELETE_RULE_BLOCK',
  GET_DISTRIBUTION_BY_TYPE: 'GET_DISTRIBUTION_BY_TYPE',
  CHANGE_DISTRIBUTION_TYPE: 'CHANGE_DISTRIBUTION_TYPE',
  DATA_TABLE_LOADING: 'DATA_TABLE_LOADING',
  SET_TABLE_TOTAL: 'SET_TABLE_TOTAL',
  CHANGE_TABLE_CELL: 'CHANGE_TABLE_CELL',
  SAVE_RULES_BUILDER: 'SAVE_RULES_BUILDER',
  RESET_RULES_BUILDER: 'RESET_RULES_BUILDER',
  ETAG_UPDATE: 'ETAG_UPDATE',
  REDIRECT_TO_ROOT: 'REDIRECT_TO_ROOT',
  RESET_STORE: 'RESET_STORE',
};

export default types;
