import * as R from 'ramda';
import { handleActions } from 'redux-actions';
import { isBlank } from '@trs/utils';

import initialState from './initialState';
import {
  getGlobalDashboardData,
  getCountriesFromCluster,
  getCanInitiate,
  initiateNewBenchmarking,
} from '../actions';
import { mapBEtoFE, mapClusterCountries } from './helpers';
import { submitActions } from '../components/mapCategories/actions';

const getCanInitiateHandler = [
  getCanInitiate,
  (state, action) => {
    const { payload } = action;

    return {
      ...state,
      canInitiate: R.prop('canInitiate', payload) || false,
    };
  },
];

const initiateHandler = [
  initiateNewBenchmarking,
  (state, action) => {
    const { ready, error } = action;

    if (!ready) {
      return {
        ...state,
        isInitiatingBenchmarking: true,
      };
    }

    if (error) {
      return {
        ...state,
        isInitiatingBenchmarking: false,
      };
    }

    return {
      ...state,
      isInitiatingBenchmarking: false,
    };
  },
];
const reducer = handleActions(
  new Map([
    initiateHandler,
    getCanInitiateHandler,
    [
      getGlobalDashboardData,
      (state, action) => {
        const { payload, ready, error } = action;

        if (!ready) {
          return {
            ...state,
            isLoading: true,
          };
        }

        if (error) {
          return {
            ...state,
            isLoading: false,
          };
        }

        if (isBlank(payload)) {
          return {
            ...state,
            isLoading: false,
            globalSettings: {
              setupFinished: false,
              data: {},
            },
          };
        }

        return {
          ...state,
          isLoading: false,
          ...mapBEtoFE(R.path(['data', 'countries'], payload)),
          globalBenchmarkingId: R.prop('globalBenchmarkingId', payload),
        };
      },
    ],
    [
      getCountriesFromCluster,
      (state, action) => {
        const { payload, ready, error } = action;

        if (!ready) {
          return {
            ...state,
            isLoading: true,
          };
        }

        if (error) {
          return {
            ...state,
            isLoading: false,
            loadError: error,
          };
        }

        if (isBlank(payload)) {
          return {
            ...state,
            isLoading: false,
            clusterCountries: [],
          };
        }

        return {
          ...state,
          isLoading: false,
          globalBenchmarkingId: R.prop('globalBenchmarkingId', payload),
          clusterCountries: mapClusterCountries(
            R.path(['data', 'countries'], payload),
            R.prop('globalBenchmarkingId', payload)
          ),
        };
      },
    ],
    [
      submitActions.success,
      (state, { payload }) => ({
        ...state,
        globalBenchmarkingId: R.prop('id', payload),
      }),
    ],
  ]),
  R.clone(initialState)
);

export default reducer;
