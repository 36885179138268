import React, { memo, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Paragraph, Button } from '@trs/components';
import * as R from 'ramda';
import { Link } from 'react-router-dom';
import NotificationHeader from '../header/NotificationHeader';
import { getjsxFromLabel } from '../../../common/helpers';
import Text, { TextOnly } from '../../../common/Text';

const OverallImpact = ({ item, markNotification }) => {
  const notificationText = getjsxFromLabel({
    label: TextOnly({ path: 'notifications.NOTIFICATION_OVERALLIMPACT_CONTENT' }),
    render: () => {
      return (
        <strong key={R.path(['content', 'rewardName'], item)}>
          {R.path(['content', 'rewardName'], item)}
        </strong>
      );
    },
    pattern: '#',
  });

  return (
    <Fragment>
      <NotificationHeader
        createdDate={R.prop('createdDate', item)}
        title={TextOnly({ path: 'notifications.NOTIFICATION_OVERALLIMPACT_TITLE' })}
      />
      <Paragraph className="notification-content">
        {notificationText}
        <Link
          to={`/rewards/view/${R.path(['content', 'rewardId'], item)}/impacts/view/${R.path(
            ['content', 'overallImpactId'],
            item
          )}`}
          onClick={() => markNotification(R.prop('id', item))}
        >
          <Text path="notifications.NOTIFICATION_OVERALLIMPACT_LINK" />
        </Link>
        {!item.isRead && (
          <Button
            type="link"
            className="notification-link"
            action={() => markNotification(item.id)}
          >
            <Text path="notifications.MARK_AS_READ" />
          </Button>
        )}
      </Paragraph>
    </Fragment>
  );
};

OverallImpact.propTypes = {
  item: PropTypes.shape({
    typeId: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    isRead: PropTypes.bool.isRequired,
    content: PropTypes.shape({}).isRequired,
    createdDate: PropTypes.string.isRequired,
  }).isRequired,
  markNotification: PropTypes.func.isRequired,
};

export default memo(OverallImpact);
