export default {
  CURRENCY_TITLE: 'Company currency',
  CURRENCY_CARD_SUBTITLE: 'Please provide company currency and foreign exchange rates',
  CURRENCY_TAB_SUBTITLE:
    'Select the company default currency and upload the default foreign exchange rates source.',
  CURRENCY_CAPTION:
    'Company currency and foreign exchange rates will be used when creating rewards in different currencies and converting it to the employee currency.',
  DEFAULT_CURRENCY: 'Company default curency',
  DEFAULT_CURRENCY_VALIDATION: 'Default curency',
  DOWNLOAD_TEMPLATE_DESC:
    'In order to convert from currency to currency please upload conversion rates.',
  FX_SOURCE_NAME: 'FX source name',
  UPLOAD_FORM_FILE_LABEL: 'Select file',
  CREATE_NEW_SOURCE: 'Create new source',
  SELECT_CURRENCY_PLACEHOLDER: 'Select...',
  MULTIPLE_FX_SOURCES:
    'You already have a foreign exchange source in the system. To create more sources please navigate to “Foreign exchange” section by using the main navigation.',
  BANNER_SAVED_CURRENCY: 'The default currency has been updated.',
  EXCHANGE_PAGE_GROUP: 'Exchange rates source',
};
