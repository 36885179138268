import * as actions from '../actions/types';

const initialState = {
  banner: {
    bannerType: '',
    bannerVisible: false,
    bannerHideTime: 10000,
    bannerMessage: '',
    statusCode: 200,
  },
  isUploadInProgress: false,
  isUploadDialogVisible: false,
};

function constructBanner(state, action) {
  return {
    ...state.banner,
    bannerType: action.bannerType,
    bannerVisible: action.bannerVisible,
    bannerMessage: action.bannerMessage,
    statusCode: action.statusCode,
  };
}

export default function exceptionsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.EXCEPTIONS_SOURCES_BEGIN_UPLOAD: {
      return {
        ...state,
        isUploadInProgress: true,
      };
    }
    case actions.EXCEPTIONS_SOURCES_UPLOAD_SUCCESS: {
      return {
        ...state,
        isUploadInProgress: false,
        isUploadDialogVisible: !state.isUploadDialogVisible,
      };
    }
    case actions.EXCEPTIONS_SOURCES_UPLOAD_ERROR: {
      return {
        ...state,
        banner: constructBanner(state, action),
        isUploadInProgress: false,
        isUploadDialogVisible: !state.isUploadDialogVisible,
      };
    }
    case actions.EXCEPTIONS_TOGGLE_UPLOAD_DIALOG: {
      return {
        ...state,
        isUploadDialogVisible: !state.isUploadDialogVisible,
      };
    }
    default: {
      return state;
    }
  }
}
