"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var StyledTooltip = _styledComponents["default"].div.withConfig({
  displayName: "Tooltipstyle__StyledTooltip",
  componentId: "sc-1scx02v-0"
})(["", ";"], function (props) {
  var _props$theme = props.theme,
    palette = _props$theme.palette,
    spacing = _props$theme.spacing,
    typography = _props$theme.typography,
    _props$theme$extra = _props$theme.extra,
    genericBorderRadius = _props$theme$extra.genericBorderRadius,
    boxShadow = _props$theme$extra.boxShadow;
  var variation = props.variation;
  var backgroundColor = variation === 'dark' ? palette.primary : palette.basic.white;
  return (0, _styledComponents.css)(["position:absolute;.main-tooltip.styled-tooltip{", " ", " border-radius:", ";font-size:", ";line-height:", ";max-width:300px;padding:", " ", ";white-space:normal;word-wrap:break-word;opacity:1;box-shadow:0 2px 8px 0 rgba(0,0,0,0.1);border:solid 1px #d8dbf0;&.place-top{&:before{height:0px;}&:after{border-top-color:", ";background-color:", ";}}&.place-bottom{&:after{border-bottom-color:", ";}", "}&.place-right{&:after{border-right-color:", ";border-right-style:solid;border-right-width:", ";border-top:", " solid transparent;border-bottom:", " solid transparent;left:-", ";top:50%;margin-top:-", ";}", "}}"], variation === 'dark' && (0, _styledComponents.css)(["background-color:", ";color:", ";"], backgroundColor, palette.basic.white), variation === 'light' && (0, _styledComponents.css)(["background-color:", ";border:1px solid ", ";box-shadow:", ";color:", ";"], backgroundColor, palette.utility.border, boxShadow, palette.gray.bravo), genericBorderRadius, typography.size.paragraph, typography.lineHeight["default"], spacing.tiny, spacing.small, backgroundColor, backgroundColor, backgroundColor, variation === 'light' && (0, _styledComponents.css)(["&:before{top:-", ";left:calc(50% + 2px);border-bottom-color:", ";border-bottom-style:solid;border-bottom-width:", ";border-left:", " solid transparent;border-right:", " solid transparent;}"], spacing.tiny, palette.utility.border, spacing.tiny, spacing.tiny, spacing.tiny), backgroundColor, spacing.tiny, spacing.tiny, spacing.tiny, spacing.tiny, spacing.tiny, variation === 'light' && (0, _styledComponents.css)(["&:before{top:calc(50% - 3px);left:calc(-", " - 1px);border-right-color:", ";border-right-style:solid;border-right-width:", ";border-top:", " solid transparent;border-bottom:", " solid transparent;}"], spacing.tiny, palette.utility.border, spacing.tiny, spacing.tiny, spacing.tiny));
});
StyledTooltip.displayName = 'StyledTooltip';
var _default = StyledTooltip;
exports["default"] = _default;