export const GET_DASHBOARD_COUNTRIES = 'GET_DASHBOARD_COUNTRIES';
export const SELECT_COUNTRY_BEGIN = 'SELECT_COUNTRY_BEGIN';
export const SELECT_COUNTRY_FINISHED = 'SELECT_COUNTRY_FINISHED';
export const MIMIC_EMPLOYEE_BEGIN = 'MIMIC_EMPLOYEE_BEGIN';
export const MIMIC_EMPLOYEE_EXIT = 'MIMIC_EMPLOYEE_EXIT';
export const MIMIC_EMPLOYEE_RESET_REDIRECT = 'MIMIC_EMPLOYEE_RESET_REDIRECT';
export const MIMIC_EMPLOYEE_GET_INFO = 'MIMIC_EMPLOYEE_GET_INFO';
export const WIDGET_EXCEPTIONS_START = 'WIDGET_EXCEPTIONS_START';
export const WIDGET_EXCEPTIONS_FINISH = 'WIDGET_EXCEPTIONS_FINISH';
export const WIDGET_REWARDS_OVERVIEW_START = 'WIDGET_REWARDS_OVERVIEW_START';
export const WIDGET_REWARDS_OVERVIEW_FINISH = 'WIDGET_REWARDS_OVERVIEW_FINISH';
export const DASHBOARD_RESET = 'DASHBOARD_RESET';
