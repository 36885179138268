export const LOOKUP_COUNTRIES = 'LOOKUP_COUNTRIES';
export const LOOKUP_FEATURE_MODULES = 'LOOKUP_FEATURE_MODULES';

export const BASIC_INFORMATION_RESET = 'BASIC_INFORMATION_RESET';

export const RULES_LOGIC_BUILDER_SAVE = 'RULES_LOGIC_BUILDER_SAVE';
export const ETAG_UPDATE = 'ETAG_UPDATE';
export const STORE_RESET = 'STORE_RESET';

export const SYSTEM_VARIABLES_GET = 'SYSTEM_VARIABLES_GET';
export const ELIGIBILITY_OPERATORS_GET = 'ELIGIBILITY_OPERATORS_GET';
export const SYSTEM_VARIABLES_LOOKUP_GET = 'SYSTEM_VARIABLES_LOOKUP_GET';

export const REDIRECT_TO_ROOT = 'REDIRECT_TO_ROOT';
export const REDIRECT_TO_EXCEPTIONS = 'REDIRECT_TO_EXCEPTIONS';

export const ADD_BANNER = 'BANNER_SHOW';
export const REMOVE_BANNER = 'BANNER_HIDE';
export const REMOVE_ALL_BANNERS = 'REMOVE_ALL_BANNERS';

export const SET_COOKIE_POLICY = 'SET_COOKIE_POLICY';

export const SHOW_DIALOG = 'SHOW_DIALOG';
export const HIDE_DIALOG = 'HIDE_DIALOG';

export const RULES_BUILDER_RESET = 'RULES_BUILDER_RESET';
export const RULE_CHANGE = 'RULE_CHANGE';

export const SET_WARNING_INACTIVITY = 'SET_WARNING_INACTIVITY';
export const RESET_TIMER_INACTIVITY = 'RESET_TIMER_INACTIVITY';
export const CLEAR_DATA_INACTIVITY = 'CLEAR_DATA_INACTIVITY';

export const EMPLOYEE_GET_CATEGORY_DETAILS = 'EMPLOYEE_GET_CATEGORY_DETAILS';
export const EMPLOYEE_CLEAR_CATEGORY_DETAILS = 'EMPLOYEE_CLEAR_CATEGORY_DETAILS';
export const EMPLOYEE_GET_REWARD_DETAILS = 'EMPLOYEE_GET_REWARD_DETAILS';

export const USER_VALIDATE = 'USER_VALIDATE';
export const USER_LOGOUT = 'USER_LOGOUT';
export const USER_LOGOUT_ERROR = 'USER_LOGOUT_ERROR';
export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGIN_ERROR = 'USER_LOGIN_ERROR';
export const USER_REDIRECT_TO_LOGIN = 'USER_REDIRECT_TO_LOGIN';
export const USER_DECLINE_DISCLAIMER = 'USER_DECLINE_DISCLAIMER';
export const AUTHENTICATION_IN_PROGRESS = 'AUTHENTICATION_IN_PROGRESS';
export const AUTHENTICATION_SUCCEEDED = 'AUTHENTICATION_SUCCEEDED';
export const AUTHENTICATION_ERROR = 'AUTHENTICATION_ERROR';
export const USER_GET_AUTHORIZED_MODULES_PENDING = 'USER_GET_AUTHORIZED_MODULES_PENDING';
export const USER_GET_AUTHORIZED_MODULES = 'USER_GET_AUTHORIZED_MODULES';
export const GET_REPORT_PERMISSIONS = 'GET_REPORT_PERMISSIONS';
export const GET_MODELLER_PERMISSIONS = 'GET_MODELLER_PERMISSIONS';
export const GET_MODELLER_PERMISSIONS_BETA = 'GET_MODELLER_PERMISSIONS_BETA';
export const USER_GET_PROFILE = 'USER_GET_PROFILE';
export const USER_GENERATE_MENU = 'USER_GENERATE_MENU';
export const USER_NO_PROFILE = 'USER_NO_PROFILE';
export const USER_GET_INACTIVITY_TIMEOUT = 'USER_GET_INACTIVITY_TIMEOUT';
export const USER_CLEAR_DATA = 'USER_CLEAR_DATA';

export const FORMULA_OPERATORS_GET = 'FORMULA_OPERATORS_GET';

export const FX_SOURCES_REQUESTED = 'FX_SOURCES_REQUESTED';
export const FX_SOURCES_GET = 'FX_SOURCES_GET';
export const FX_SOURCES_BEGIN_UPLOAD = 'FX_SOURCES_BEGIN_UPLOAD';
export const FX_SOURCES_TOGGLE_UPLOAD_DIALOG = 'FX_SOURCES_TOGGLE_UPLOAD_DIALOG';
export const FX_SOURCES_UPLOAD_SUCCESS = 'FX_SOURCES_UPLOAD_SUCCESS';
export const FX_SOURCES_ERROR = 'FX_SOURCES_ERROR';
export const FX_SOURCES_REDIRECT = 'FX_SOURCES_REDIRECT';
export const FX_RATES_REQUESTED = 'FX_RATES_REQUESTED';
export const FX_RATES_GET = 'FX_RATES_GET';
export const FX_RATES_GET_CURRENCIES = 'FX_RATES_GET_CURRENCIES';
export const FX_RATE_GET_DATES = 'FX_RATE_GET_DATES';

export const EXCEPTIONS_SOURCES_BEGIN_UPLOAD = 'EXCEPTIONS_SOURCES_BEGIN_UPLOAD';
export const EXCEPTIONS_TOGGLE_UPLOAD_DIALOG = 'EXCEPTIONS_TOGGLE_UPLOAD_DIALOG';
export const EXCEPTIONS_SOURCES_UPLOAD_SUCCESS = 'EXCEPTIONS_SOURCES_UPLOAD_SUCCESS';
export const EXCEPTIONS_SOURCES_UPLOAD_ERROR = 'EXCEPTIONS_SOURCES_UPLOAD_ERROR';

export const TABLE_STORE_RESET = 'TABLE_STORE_RESET';
export const TABLE_SORT_CHANGE = 'TABLE_SORT_CHANGE';
export const TABLE_PAGE_CHANGE = 'TABLE_PAGE_CHANGE';
export const TABLE_SEARCH_TEXT_CHANGE = 'TABLE_SEARCH_TEXT_CHANGE';
export const TABLE_SEARCH_SUBMIT = 'TABLE_SEARCH_SUBMIT';
export const TABLE_FILTERS_SUBMIT = 'TABLE_FILTERS_SUBMIT';
export const TABLE_SELECT_ROW = 'TABLE_SELECT_ROW';
export const TABLE_SELECT_ROWS = 'TABLE_SELECT_ROWS';
export const TABLE_SELECT_ALL = 'TABLE_SELECT_ALL';
export const TABLE_CLEAR_SELECTED = 'TABLE_CLEAR_SELECTED';
export const TABLE_SELECTED_ITEMS = 'TABLE_SELECTED_ITEMS';
export const TABLE_SET_SHOW_ONLY_SELECTED = 'TABLE_SET_SHOW_ONLY_SELECTED';
export const TABLE_DIRTY_CHANGE = 'TABLE_DIRTY_CHANGE';
export const TABLE_SET_CHECKED_FILTERS = 'TABLE_SET_CHECKED_FILTERS';

export const CONFIGURATION_SET_FEATURE_TOGGLES = 'CONFIGURATION_SET_FEATURE_TOGGLES';
