"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getErrorMessage = exports["default"] = void 0;
var R = _interopRequireWildcard(require("ramda"));
var _utils = require("@trs/utils");
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var getErrorPath = function getErrorPath(errors) {
  var flattened = (0, _utils.flattenObject)(errors);
  var formulaErrorPath = '';
  R.forEach(function (item) {
    if (item.indexOf('generic') !== -1 && !R.isEmpty(flattened[item])) {
      formulaErrorPath = item.split('.');
    }
  }, R.keys(flattened));
  return formulaErrorPath;
};
var getErrorMessage = function getErrorMessage(errors) {
  var errorPath = getErrorPath(errors);
  return (0, _utils.isBlank)(errorPath) ? errorPath : R.path(errorPath, errors);
};
exports.getErrorMessage = getErrorMessage;
var _default = getErrorMessage;
exports["default"] = _default;