import * as R from 'ramda';
import { handleActions } from 'redux-actions';
import { isBlank } from '@trs/utils';
import {
  storeReset,
  setVariableValue,
  getVariablesCountries,
  getVariablesList,
  variableChange,
  getAffectedRewardsByVarId,
  getVariableById,
} from '../actions';
import initialState from './initialState';
import { mapCountriesToUI, mapVariableListToGrid, mapVariableToUI } from '../helpers';

const main = handleActions(
  new Map([
    [storeReset, () => R.clone(initialState)],
    [
      setVariableValue,
      (state, action) => {
        if (!action.ready) {
          return {
            ...state,
            isSavingVariable: true,
          };
        }

        if (action.error) {
          return {
            ...state,
            variableSaveError: action.error,
            isSavingVariable: false,
          };
        }

        const etag = R.path(['payload', 'headers', 'etag'], action) || null;
        const id = R.path(['payload', 'data', 'response', 'id'], action) || {};
        return {
          ...state,
          savedVariable: {
            ...state.savedVariable,
            id,
            etag,
          },
          isSavingVariable: false,
          isDirty: false,
        };
      },
    ],
    [
      getVariablesCountries,
      (state, action) => {
        if (!action.ready) {
          return {
            ...state,
            fetchingVariableCountries: true,
          };
        }
        const profileCountry = R.path(['meta', 'profile', 'country'], action);
        const countries = mapCountriesToUI({
          data: R.path(['payload', 'data'], action),
          authorizedModules: R.path(['meta', 'authorizedModules'], action),
        });
        const defaultSelectedCountry = R.find(R.propEq('value', profileCountry))(countries);
        const savedVariableCountry = R.path(['savedVariable', 'country'], state);
        return {
          ...state,
          countries,
          savedVariable: {
            ...state.savedVariable,
            country: isBlank(savedVariableCountry) ? defaultSelectedCountry : savedVariableCountry,
          },
          fetchingVariableCountries: false,
        };
      },
    ],
    [
      getVariableById,
      (state, action) => {
        if (!action.ready) {
          return {
            ...state,
            isFetchingById: true,
          };
        }

        if (action.error) {
          return {
            ...state,
            variableLoadError: action.error,
            isFetchingById: false,
          };
        }

        const data = mapVariableToUI(R.path(['payload', 'data', 'response'], action));
        const etag = R.path(['payload', 'headers', 'etag'], action);

        return {
          ...state,
          savedVariable: {
            ...state.savedVariable,
            ...data,
            etag,
          },
          isFetchingById: false,
        };
      },
    ],
    [
      variableChange,
      (state, action) => {
        const { savedVariable } = R.clone(state);

        return {
          ...state,
          savedVariable: R.assocPath(action.payload.field, action.payload.value, savedVariable),
          isDirty: true,
        };
      },
    ],
    [
      getVariablesList,
      (state, action) => {
        if (!action.ready) {
          return {
            ...state,
            fetchingList: true,
          };
        }
        const { list, filterOptions, totalResults } = mapVariableListToGrid({
          data: R.path(['payload', 'data'], action),
          authorizedModules: R.prop('meta', action),
        });
        return {
          ...state,
          list,
          filterOptions,
          totalResults,
          fetchingList: false,
        };
      },
    ],
    [
      getAffectedRewardsByVarId,
      (state, action) => {
        return {
          ...state,
          affectedRewards: action.ready ? action.payload.data : {},
        };
      },
    ],
  ]),
  R.clone(initialState)
);

export default main;
