import React from 'react';
import * as R from 'ramda';
import { Button } from '@trs/components';
import { isBlank } from '@trs/utils';
import PropTypes from 'prop-types';
import OptionTile from './optionTile/OptionTile';
import { TILE_TYPES } from '../../../constants';
import StyledCategoryTiles from './CategoryTiles.style';
import Text from '../../../../../../common/Text';

const handleOnChange =
  ({ rewardList, item, onSelectionChange, changeRewardWithWarningMsg, isRadio }) =>
  (_, checked, value) => {
    const data = R.map(
      (reward) =>
        !isBlank(item) && reward.id === item.id
          ? {
              ...reward,
              isSelected: isBlank(checked) ? true : checked,
              payload: {
                ...item.payload,
                value: isBlank(value) ? R.path(['payload', 'value'], reward) : value,
                hasChanged: !isBlank(value),
              },
            }
          : { ...reward, isSelected: false },
      rewardList
    );

    if (isRadio) {
      let hasWarningMessage = false;
      let warningMsg = '';
      rewardList.forEach((rewardItem, index) => {
        if (
          rewardItem.isSelected &&
          rewardItem.flexOptions.warningMessage &&
          !data[index].isSelected
        ) {
          hasWarningMessage = true;
          warningMsg = rewardItem.flexOptions.warningMessage;
        }
      });
      return hasWarningMessage
        ? changeRewardWithWarningMsg(data, warningMsg)
        : onSelectionChange(data);
    }

    return item && item.isSelected && item.flexOptions.warningMessage && !checked
      ? changeRewardWithWarningMsg(data, item.flexOptions.warningMessage)
      : onSelectionChange(data);
  };

const handleStepperChange = (reward, selectedOption, onStepperChange) => () => {
  const { value } = R.prop('payload', selectedOption);
  const localReward = reward;
  localReward.payload.value = value;

  const updatedOptions = R.map(
    (option) =>
      option.id === selectedOption.id
        ? { ...option, isSelected: true }
        : { ...option, isSelected: false },
    localReward.stepperOptions
  );
  localReward.stepperOptions = updatedOptions;
  onStepperChange(localReward);
};

const CategoryTiles = ({
  onSelectionChange,
  onStepperChange,
  selectedRewards,
  disabled,
  subcategories,
  toggleIsEditing,
  handleShowStepperOptions,
  changeRewardWithWarningMsg,
  budgetLabel,
  hasDecimals,
}) => (
  <StyledCategoryTiles>
    {R.values(
      R.addIndex(R.map)((rewardList, key) => {
        const isRadio = R.length(rewardList) > 1;
        const isDirty = isRadio && R.any((item) => R.prop(item.id, selectedRewards), rewardList);
        return (
          <div key={key} className="category-tiles-container">
            {R.map((item) => {
              return (
                <React.Fragment key={item.id}>
                  <OptionTile
                    {...item}
                    description={R.prop('description', item)}
                    disabled={disabled}
                    type={isRadio ? TILE_TYPES.RADIO : TILE_TYPES.CHECKBOX}
                    isIntegerOnly={item.flexOptions.isIntegerOnly || false}
                    isFirst={item.isFirst || false}
                    onChange={handleOnChange({
                      rewardList,
                      item,
                      onSelectionChange,
                      changeRewardWithWarningMsg,
                      isRadio,
                    })}
                    toggleIsEditing={toggleIsEditing}
                    {...R.prop(item.id, selectedRewards)}
                    isSelected={!!R.prop(item.id, selectedRewards)}
                    budgetLabel={budgetLabel}
                    hasDecimals={hasDecimals}
                  />
                  {item.isStepper && (
                    <div className="category-group-footer button-center">
                      <Button
                        icon={`ion-chevron-${item.showStepperOptions ? 'down' : 'right'}`}
                        type="link"
                        action={() => {
                          const updatedReward = R.clone(item);
                          updatedReward.showStepperOptions = !updatedReward.showStepperOptions;
                          handleShowStepperOptions(updatedReward);
                        }}
                      >
                        <Text path="flexibleRewards.CHOOSE" />
                      </Button>
                    </div>
                  )}
                  {item.isStepper &&
                    item.showStepperOptions &&
                    item.stepperOptions.map((option, index) => (
                      <OptionTile
                        isFirst={index === 0}
                        key={option.value}
                        {...option}
                        flexOptions={{ isIncludedInBudget: false }}
                        type={TILE_TYPES.RADIO}
                        onChange={handleStepperChange(item, option, onStepperChange)}
                        toggleIsEditing={toggleIsEditing}
                        isSelected={option.isSelected}
                        budgetLabel={budgetLabel}
                        hasDecimals={hasDecimals}
                      />
                    ))}
                </React.Fragment>
              );
            }, rewardList)}
            {isRadio && (
              <div className="category-group-footer">
                <Button
                  icon="ion-android-close"
                  type="link"
                  action={R.compose(
                    () => toggleIsEditing(false),
                    handleOnChange({ rewardList, onSelectionChange })
                  )}
                  disabled={disabled || !isDirty}
                >
                  <Text path="flexibleRewards.CATEGORY_CLEAR_SELECTION" />
                </Button>
              </div>
            )}
          </div>
        );
      }, subcategories)
    )}
  </StyledCategoryTiles>
);

CategoryTiles.propTypes = {
  subcategories: PropTypes.shape({}).isRequired,
  disabled: PropTypes.bool,
  selectedRewards: PropTypes.shape({}).isRequired,
  changeRewardWithWarningMsg: PropTypes.func,
  onSelectionChange: PropTypes.func,
  toggleIsEditing: PropTypes.func.isRequired,
  onStepperChange: PropTypes.func.isRequired,
  handleShowStepperOptions: PropTypes.func,
  budgetLabel: PropTypes.string.isRequired,
  hasDecimals: PropTypes.bool,
};

CategoryTiles.defaultProps = {
  disabled: false,
  hasDecimals: false,
  changeRewardWithWarningMsg: () => null,
  onSelectionChange: () => null,
  handleShowStepperOptions: () => null,
};
export default React.memo(CategoryTiles);
