import generic from './generic';
import footer from './footer';
import header from './header';
import articles from './articles';
import rewards from './rewards';
import variables from './variables';
import exceptions from './exceptions';
import modeller from './modeller';
import employeeStatement from './employeeStatement';
import foreignExchange from './foreignExchange';
import userManagement from './userManagement';
import flexibleRewards from './flexibleRewards';
import rmDashboard from './rmDashboard';
import onboarding from './onboarding';
import customisation from './customisation';
import notifications from './notifications';
import feedback from './feedback';
import workspace from './workspace';
import benchmarking from './benchmarking';
import flexibleReports from './flexibleReports';
import platformConfig from './platformConfig';
import benefitsEnvelopeAudit from './benefitsEnvelopeAudit';
import benefitsEnvelope from './benefitsEnvelope';
import fixedCosts from './fixedCosts';
import ltiPlans from './ltiPlans';

export default {
  generic,
  footer,
  header,
  articles,
  rewards,
  variables,
  exceptions,
  modeller,
  employeeStatement,
  foreignExchange,
  userManagement,
  flexibleRewards,
  rmDashboard,
  onboarding,
  customisation,
  notifications,
  feedback,
  workspace,
  benchmarking,
  flexibleReports,
  platformConfig,
  benefitsEnvelope,
  benefitsEnvelopeAudit,
  fixedCosts,
  ltiPlans,
};
