const types = {
  FETCH_DEFAULT_CURRENCY_PENDING: 'FETCH_DEFAULT_CURRENCY_PENDING',
  FETCH_DEFAULT_CURRENCY_SUCCESS: 'FETCH_DEFAULT_CURRENCY_SUCCESS',
  FETCH_CURRENCIES_PENDING: 'FETCH_CURRENCIES_PENDING',
  FETCH_CURRENCIES_SUCCESS: 'FETCH_CURRENCIES_SUCCESS',
  FETCH_FXSOURCES_PENDING: 'FETCH_FXSOURCES_PENDING',
  FETCH_FXSOURCES_SUCCESS: 'FETCH_FXSOURCES_SUCCESS',
};

export default types;
