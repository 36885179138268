import * as R from 'ramda';
import { createAction } from 'redux-actions';
import { GET, POST, PUT, CancelToken, addGetParameters } from 'config/http';
import { fixedCosts, organization, foreignExchange, rewards } from 'config/api';
import { REWARD_CONTEXTS } from '@trs/config';
import { Log } from '@trs/utils';
import { withCache } from '../../common/timedCache';
import types from './types';
import dispatchError from './helpers';

const cachedGet = withCache(GET, 3600);

export const requestAllFixedCosts = createAction(types.FIXED_COSTS_REQUEST_ALL);
export const getFixedCostsSearch = createAction(types.FIXED_COSTS_GET_SEARCH);
export const getFixedCostsById = createAction(types.FIXED_COSTS_GET_BY_ID);
export const getFixedCostsCurrencies = createAction(types.FIXED_COSTS_GET_CURRENCIES);
export const getFixedCostsCompanyDefaultCurrency = createAction(
  types.FIXED_COSTS_GET_DEFAULT_CURRENCY
);
export const getFixedCostsCountries = createAction(types.FIXED_COSTS_GET_COUNTRIES);
export const setFixedCostsFilteredCountries = createAction(
  types.FIXED_COSTS_SET_FILTERED_COUNTRIES
);
export const getFixedCostsFxSources = createAction(types.FIXED_COSTS_GET_FX_SOURCES);
export const getFixedCostsCategories = createAction(types.FIXED_COSTS_GET_CATEGORIES);
export const getFixedCostsBasicInformationChange = createAction(
  types.FIXED_COSTS_BASIC_INFORMATION_CHANGE
);
export const basicInformationReset = createAction(types.FIXED_COSTS_BASIC_INFORMATION_RESET);
export const basicInformationSave = createAction(types.FIXED_COSTS_BASIC_INFORMATION_SAVE);
export const fixedCostsEtagUpdate = createAction(types.FIXED_COSTS_ETAG_UPDATE);
export const fixedCostsFetchingById = createAction(types.FIXED_COSTS_FETCHING_BY_ID);

export const getFixedCostsFormulaOperators = createAction(types.FIXED_COSTS_GET_FORMULA_OPERATORS);
export const fixedCostsRuleChange = createAction(types.FIXED_COSTS_RULE_CHANGE);
export const changeDistributionType = createAction(types.FIXED_COSTS_CHANGE_DISTRIBUTION_TYPE);
export const setDistributionTableRowsAndTotal = createAction(
  types.FIXED_COSTS_SET_DISTRIBUTION_TABLE_CONFIG
);

export const cellChange = createAction(types.FIXED_COSTS_TABLE_CELL_CHANGE);
export const getSavedLogicBuilderById = createAction(types.FIXED_COSTS_GET_SAVED_LOGIC_BUILDER);
export const rulesBuilderRevert = createAction(types.FIXED_COSTS_RULES_BUILDER_REVERT);
export const rulesBuilderReset = createAction(types.FIXED_COSTS_RULES_BUILDER_RESET);

export const storeReset = createAction(types.FIXED_COSTS_RESET_STORE);
export const redirectToRoot = createAction(types.FIXED_COSTS_REDIRECT_TO_ROOT);

export function loadFixedCosts(params) {
  const { sortAsc, pagingTop: top, pagingSkip: skip, searchText, filters } = params;
  let { sortBy } = params;
  if (sortBy === 'category') {
    sortBy = 'categoryName';
  }

  if (sortBy === 'subcategory') {
    sortBy = 'subcategoryName';
  }

  if (sortBy === 'displayName') {
    sortBy = 'name';
  }

  if (sortBy === 'countries') {
    sortBy = 'countriesName';
  }

  if (sortBy === 'effectiveDate') {
    sortBy = 'startDate';
  }

  if (sortBy === 'expiryDate') {
    sortBy = 'endDate';
  }

  if (sortBy === 'statusName') {
    sortBy = 'status';
  }

  const payload = {
    sort: {
      sortAsc,
      sortBy,
    },
    paging: {
      top,
      skip,
    },
    search: {
      searchText,
    },
    filters,
  };

  return (dispatch) => {
    dispatch(requestAllFixedCosts(true));

    POST(`${fixedCosts().search}`, payload)
      .then((response) => {
        dispatch(getFixedCostsSearch({ response }));
      })
      .catch((e) => {
        dispatch(requestAllFixedCosts(false));
        dispatchError(e, dispatch);
      });
  };
}

export function loadFixedCostsById(fixedCostId) {
  return (dispatch) => {
    dispatch(fixedCostsFetchingById(true));

    return GET(`${fixedCosts().getFixedCostById(fixedCostId)}`, {
      Pragma: 'no-cache',
    })
      .then((response) => {
        const responseData = R.path(['data', 'response'], response);

        dispatch(getFixedCostsById(responseData));
        dispatch(fixedCostsEtagUpdate(response.headers.etag));
        dispatch(fixedCostsFetchingById(false));

        return responseData;
      })
      .catch((e) => {
        dispatchError(e, dispatch);
        dispatch(fixedCostsFetchingById(false));
        return Promise.reject(e);
      });
  };
}

export function loadLogicBuilderById(fixedCostId) {
  return (dispatch) => {
    return GET(`${fixedCosts().getFixedCostById(fixedCostId)}`, {
      Pragma: 'no-cache',
    })
      .then((response) => {
        const responseData = R.path(['data', 'response'], response);

        dispatch(getSavedLogicBuilderById(responseData));
        dispatch(fixedCostsEtagUpdate(response.headers.etag));

        return responseData;
      })
      .catch((e) => {
        dispatchError(e, dispatch);
        return Promise.reject(e);
      });
  };
}

export function setBasicInformationChange({ field, value, other }) {
  return (dispatch) => {
    dispatch(
      getFixedCostsBasicInformationChange({
        field,
        value,
        other,
      })
    );
  };
}

export function loadFixedCostsCurrencies() {
  return (dispatch) =>
    GET(`${foreignExchange.lookupCurrencies}`, {
      Pragma: 'no-cache',
    })
      .then((response) => {
        dispatch(
          getFixedCostsCurrencies({
            currencies: R.prop('data', response),
          })
        );
      })
      .catch((e) => {
        dispatchError(e, dispatch);
      });
}

export const loadCompanyDefaultCurrency = () => (dispatch) => {
  GET(`${organization.currency}`, {
    Pragma: 'no-cache',
  })
    .then((response) => {
      dispatch(
        getFixedCostsCompanyDefaultCurrency({
          defaultCurrency: R.path(['data', 'response'], response),
        })
      );
    })
    .catch((e) => {
      dispatchError(e, dispatch);
    });
};

export function loadFixedCostsCountries() {
  return (dispatch, getState) =>
    cachedGet(organization.lookupCountries)
      .then((response) => {
        dispatch(
          getFixedCostsCountries({
            error: null,
            countries: R.prop('data', response),
            user: R.prop('user', getState()),
          })
        );
      })
      .catch((e) => {
        dispatchError(e, dispatch);
      });
}

export function loadFixedCostsFxSources() {
  return (dispatch) =>
    GET(foreignExchange.fxSources)
      .then((response) => {
        dispatch(getFixedCostsFxSources({ fxSources: R.prop('data', response) }));
      })
      .catch((e) => {
        dispatchError(e, dispatch);
      });
}

export function loadFixedCostsRewardCategories() {
  return (dispatch) =>
    GET(`${rewards.categories}?excludeIncompleteCategories=true`)
      .then((response) => {
        const data = R.path(['data', 'response', 'categories'], response);
        dispatch(getFixedCostsCategories({ categories: data }));
        return data;
      })
      .catch((e) => {
        dispatchError(e, dispatch);
      });
}

export const updateFixedCostsBasicInformationData = (payload, fixedCostId, config) => {
  if (!fixedCostId) {
    return (dispatch, getState) =>
      POST(`${fixedCosts().serviceRoot}`, payload, {}, { redirectToUnauthorized: false })
        .then((response) => {
          dispatch(basicInformationSave(R.prop('data', response)));
          dispatch(fixedCostsEtagUpdate(response.headers.etag));
          return response;
        })
        .catch((error) => {
          dispatchError(error, dispatch, getState());
          return Promise.reject(error);
        });
  }

  return (dispatch, getState) =>
    PUT(
      `${fixedCosts().serviceRoot}/${fixedCostId}`,
      payload,
      { ...config.headers },
      { redirectToUnauthorized: false }
    )
      .then((response) => {
        dispatch(basicInformationSave(R.prop('data', response)));
        dispatch(fixedCostsEtagUpdate(response.headers.etag));
        return response;
      })
      .catch((e) => {
        dispatchError(e, dispatch, getState());
        return Promise.reject(e);
      });
};

export function loadFixedCostsFormulaOperators() {
  return (dispatch) =>
    cachedGet(rewards.formulaOperators)
      .then((response) => {
        dispatch(getFixedCostsFormulaOperators(response.data));
      })
      .catch((e) => {
        dispatchError(e, dispatch);
      });
}

export function fixedCostsUpdateRule(index, field, value, other) {
  return (dispatch) => {
    dispatch(fixedCostsRuleChange({ ruleData: { index, field, value, other } }));
  };
}

let cancelOperandCall;

/** user defined variables **/
export function getFormulaOperandValues({ resourceGroupId, resourceCountry }) {
  return () => (searchValue) => {
    if (!searchValue) {
      return Promise.resolve([]);
    }

    if (cancelOperandCall) cancelOperandCall.cancel('Formula Operand call cancelled.');

    cancelOperandCall = CancelToken.source();
    const getArgs = [
      {
        name: 'resourceGroupId',
        value: resourceGroupId,
      },
      {
        name: 'resourceCountry',
        value: resourceCountry,
      },
      {
        name: 'context',
        value: REWARD_CONTEXTS.fixedCosts,
      },
    ];

    const requestUrl = addGetParameters(
      `${rewards.formulaOperand}/${encodeURIComponent(searchValue)}`,
      getArgs
    );

    return GET(requestUrl, {
      cancelToken: cancelOperandCall.token,
    }).then((response) => response.data);
  };
}

export function saveFixedCostsRules(id, payload, config) {
  return (dispatch, getState) =>
    PUT(
      `${fixedCosts().rules(id)}`,
      payload,
      { ...config.headers },
      { redirectToUnauthorized: false }
    )
      .then((response) => {
        dispatch(fixedCostsEtagUpdate(response.headers.etag));
      })
      .catch((e) => {
        dispatchError(e, dispatch, getState());
        return Promise.reject(e);
      });
}

export function publishDraftFixedCosts({ id, payload = {}, config: { headers } }) {
  return (dispatch, getState) =>
    PUT(`${fixedCosts().publish(id)}`, payload, { ...headers }, { redirectToUnauthorized: false })
      .then((response) => {
        dispatch(fixedCostsEtagUpdate(response.headers.etag));
      })
      .catch((e) => {
        dispatchError(e, dispatch, getState());
        return Promise.reject(e);
      });
}

export function revertToDraft({ id, payload = {}, config: { headers } }) {
  return (dispatch, getState) =>
    PUT(
      `${fixedCosts().moveToDraft(id)}`,
      payload,
      { ...headers },
      { redirectToUnauthorized: false }
    )
      .then((response) => {
        dispatch(loadFixedCostsById(id));
        return response;
      })
      .catch((e) => {
        dispatchError(e, dispatch, getState());
        return Promise.reject(e);
      });
}

export const loadDistributionTableRowsAndTotal = (payload) => async (dispatch) => {
  try {
    await dispatch(setDistributionTableRowsAndTotal(payload));
  } catch (exception) {
    Log.error(`Unable to load the fixed costs distribution table config.`, exception);
  }
};
