import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Paragraph, Button } from '@trs/components';
import NotificationHeader from 'modules/notifications/components/header';
import Text, { TextOnly } from 'modules/common/Text';
import DownloadLink from 'modules/common/DownloadLink';
import StyledDataLoading from 'modules/notifications/components/item/DataLoading/DataLoading.style';

const FileUploadNotification = ({
  notificationId,
  createdDate,
  markNotification,
  hasError,
  onFileDownload,
  onFileDownloaded,
  uploadedFileName,
  downloadLink,
  item,
}) => {
  const VALIDATED_LABEL = TextOnly({ path: 'generic.VALIDATED' });
  const { isRead } = item;

  return (
    <StyledDataLoading>
      <Fragment>
        <NotificationHeader
          createdDate={createdDate}
          title={TextOnly({ path: 'notifications.NOTIFICATION_EXCEPTION_FILE_UPLOAD_TITLE' })}
        />
        <Paragraph className="notification-content">
          <Text
            path={
              hasError
                ? 'notifications.NOTIFICATION_DATA_LOADING_CONTENT_ERRORS'
                : 'notifications.NOTIFICATION_EXCEPTION_FILE_UPLOAD_CONTENT_SUCCESS'
            }
            transform={(label = '') =>
              label.replace('%FILENAME%', uploadedFileName).replace('%TYPE%', VALIDATED_LABEL)
            }
            withHtml
          />
        </Paragraph>
        {hasError ? (
          <Fragment>
            <DownloadLink
              id={`download-${notificationId}`}
              type="link"
              className="notification-link"
              beforeHook={() => {
                const file = TextOnly({ path: 'generic.FILE' });
                onFileDownload(
                  TextOnly({
                    path: 'generic.DOWNLOAD_IN_PROGRESS',
                    transform: (label) => label.replace('%FILENAME%', file),
                  })
                );
              }}
              action={({ name, err }) => {
                markNotification(notificationId);
                onFileDownloaded(
                  err,
                  TextOnly({ path: 'generic.ERROR_DOWNLOAD' }),
                  TextOnly({
                    path: 'generic.DOWNLOAD_FINISHED',
                    transform: (label) => label.replace('%FILENAME%', name),
                  })
                );
              }}
              href={downloadLink}
              download={uploadedFileName}
            >
              <Text path="notifications.DOWNLOAD_REPORT" />
            </DownloadLink>
            {!isRead && (
              <Button
                type="link"
                className="notification-link"
                action={() => markNotification(notificationId)}
              >
                <Text path="notifications.MARK_AS_READ" />
              </Button>
            )}
          </Fragment>
        ) : (
          !isRead && (
            <Button
              type="link"
              className="notification-link"
              action={() => markNotification(notificationId)}
            >
              <Text path="notifications.MARK_AS_READ" />
            </Button>
          )
        )}
      </Fragment>
    </StyledDataLoading>
  );
};

FileUploadNotification.defaultProps = {
  onFileDownload: () => {},
  onFileDownloaded: () => {},
  hasError: false,
};

FileUploadNotification.propTypes = {
  notificationId: PropTypes.string.isRequired,
  createdDate: PropTypes.string.isRequired,
  downloadLink: PropTypes.string.isRequired,
  markNotification: PropTypes.func.isRequired,
  onFileDownload: PropTypes.func,
  onFileDownloaded: PropTypes.func,
  hasError: PropTypes.bool,
  item: PropTypes.shape({
    isRead: PropTypes.bool.isRequired,
  }).isRequired,
  uploadedFileName: PropTypes.string.isRequired,
};

export default FileUploadNotification;
