import React, { PureComponent } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PageLoader, ErrorBoundary } from '@trs/components';
import { isBlank, lowerFirstLetter } from '@trs/utils';
import { fetchCmsData } from '../actions';

const isLoadingLabels = (keys, from) =>
  R.any((item) => R.prop('loading', item), R.values(R.pickAll(keys, from)));
/*
MIO: WithDataRendering(RewardsRoot, ['Rewards', 'Exceptions'])
Enhances the root component and decouples the label calls
*/
const WithLabels = (ChildComponent, keys = []) => {
  if (isBlank(keys)) {
    throw new Error("keys array missing or empty. ex: ['Rewards']");
  }

  if (isBlank(ChildComponent)) {
    throw new Error(
      "No component provided to HOC. ex: WithLabels(RewardsRoot, ['Rewards', 'Exceptions'])"
    );
  }

  class WithLabelsComponent extends PureComponent {
    componentDidMount() {
      const { fetchLabelData } = this.props.actions;

      keys.forEach((key) => {
        fetchLabelData([{ name: 'resourceGroup', value: key }], lowerFirstLetter(key));
      });
    }

    render() {
      const { cms } = this.props;
      const loading = isLoadingLabels(R.map(lowerFirstLetter, keys), cms);
      return (
        <ErrorBoundary>
          {loading ? <PageLoader /> : <ChildComponent {...this.props} />}
        </ErrorBoundary>
      );
    }
  }

  WithLabelsComponent.propTypes = {
    cms: PropTypes.shape({}).isRequired,
    actions: PropTypes.objectOf(PropTypes.func).isRequired,
  };

  const mapStateToProps = (state) => ({
    cms: state.cms,
  });

  const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators({ fetchLabelData: fetchCmsData }, dispatch),
  });

  return connect(mapStateToProps, mapDispatchToProps)(WithLabelsComponent);
};

export default WithLabels;
