import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { TrackingManager } from '@trs/utils';
import { trackingID } from 'config/api';

const defaultOptions = {
  gaOptions: {
    debug_mode: false,
  },
  gtagOptions: {
    debug_mode: false,
    send_page_view: false,
  },
};

const GoogleAnalytics = ({ initOptions } = {}) => {
  useEffect(() => {
    TrackingManager.init({
      trackingId: trackingID,
      options: initOptions,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
};

GoogleAnalytics.propTypes = {
  initOptions: PropTypes.shape({}),
};

GoogleAnalytics.defaultProps = {
  initOptions: defaultOptions,
};

export default GoogleAnalytics;
