import React, { memo, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Paragraph, Button } from '@trs/components';
import * as R from 'ramda';
import { Link } from 'react-router-dom';
import NotificationHeader from '../header/NotificationHeader';
import { getjsxFromLabel } from '../../../common/helpers';
import Text, { TextOnly } from '../../../common/Text';

const Workspace = ({ item, markNotification }) => {
  const { content } = item;
  const isStatusSuccess = content.status === 'Success';
  return (
    <Fragment>
      <NotificationHeader
        createdDate={R.prop('createdDate', item)}
        title={TextOnly({
          path: 'notifications.NOTIFICATION_WORKSPACE_TITLE',
          transform: label => label.replace('%STATUS%', content.status),
        })}
      />
      <Paragraph className="notification-content">
        {isStatusSuccess
          ? getjsxFromLabel({
              label: TextOnly({ path: 'notifications.NOTIFICATION_WORKSPACE_CONTENT' }),
              render: () => (
                <strong key={R.path(['content', 'fileName'], item)}>
                  {R.path(['content', 'fileName'], item)}
                </strong>
              ),
            })
          : getjsxFromLabel({
              label: TextOnly({ path: 'notifications.NOTIFICATION_WORKSPACE_CONTENT_ERROR' }),
              render: () => R.path(['content', 'errorMessage'], item),
            })}
        {isStatusSuccess && (
          <Link
            to={`/workspace/${R.path(['content', 'directoryId'], item)}`}
            onClick={() => markNotification(R.prop('id', item))}
          >
            <Text path="notifications.NOTIFICATION_WORKSPACE_LINK" />
          </Link>
        )}
        {!item.isRead && (
          <Button
            type="link"
            className="notification-link"
            action={() => markNotification(item.id)}
          >
            <Text path="notifications.MARK_AS_READ" />
          </Button>
        )}
      </Paragraph>
    </Fragment>
  );
};

Workspace.propTypes = {
  item: PropTypes.shape({
    typeId: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    isRead: PropTypes.bool.isRequired,
    content: PropTypes.shape({
      status: PropTypes.string.isRequired,
    }).isRequired,
    createdDate: PropTypes.string.isRequired,
  }).isRequired,
  markNotification: PropTypes.func.isRequired,
};

export default memo(Workspace);
