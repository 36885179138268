import React from 'react';
import PropTypes from 'prop-types';
import { Card } from '@trs/components';
import * as R from 'ramda';
import DescriptionDialog from 'modules/common/DescriptionDialog';
import TOTAL_VARIANTS from '../constants';
import Total from '../total/Total';
import { TextOnly } from '../../../../../../common/Text';
import { getRewardValueOutput, getStatementsValuesIndexes } from '../helpers';
import StyledRewardCategory from './RewardCategory.style';

const RewardCategory = ({
  category,
  colors,
  currencies,
  showOnlyNewStatement,
  showTotal,
  budgetLabel,
  hasDecimals,
}) => (
  <StyledRewardCategory>
    <Card
      key={category.displayName}
      borderColor={colors && colors[category.orderIndex]}
      borderPosition="left"
      borderWeight="4px"
    >
      <header className="reward-category">{category.displayName}</header>
      {R.values(
        R.mapObjIndexed((rew, key) => {
          const rewardName = R.path([1, 'rewardName'], rew) || R.path([0, 'rewardName'], rew);
          const rewardDescription =
            R.path([1, 'description'], rew) || R.path([0, 'description'], rew);
          const descriptionBanner =
            R.path([1, 'descriptionBanner'], rew) || R.path([0, 'descriptionBanner'], rew);
          const descriptionMedia =
            R.path([1, 'descriptionMedia'], rew) || R.path([0, 'descriptionMedia'], rew);

          const isIncludedInBudget =
            R.path([0, 'isIncludedInTheBudget'], rew) || R.path([1, 'isIncludedInTheBudget'], rew);

          const rowIndex = Object.keys(category.rewards).indexOf(key);

          const currency = R.prop(0, currencies);

          const { currentIndex, newIndex } = getStatementsValuesIndexes(rew);
          const currentStatementValue = getRewardValueOutput(
            rew,
            currentIndex,
            0,
            currency,
            hasDecimals
          );
          const newStmValueIndex = showOnlyNewStatement ? 0 : newIndex;
          const newStatementValue = getRewardValueOutput(
            rew,
            newStmValueIndex,
            1,
            currency,
            hasDecimals
          );

          const hasDescription = rewardDescription || descriptionBanner || descriptionMedia;

          return (
            <div
              key={key}
              className={rowIndex % 2 !== 0 ? 'statement-row' : 'statement-row odd-row'}
            >
              <span className="statement-row-title">
                <div className="reward-description-inline">
                  {hasDescription && (
                    <DescriptionDialog
                      content={{
                        title: rewardName,
                        text: rewardDescription,
                        descriptionBanner,
                        descriptionMedia,
                      }}
                    />
                  )}
                  <span
                    className={!hasDescription ? 'reward-without-dialog' : 'reward-with-dialog'}
                  >
                    {rewardName}
                  </span>
                </div>

                {isIncludedInBudget && (
                  <span className="statement-row-title--highlight">
                    {TextOnly({
                      path: 'flexibleRewards.AVAILABLE_OPTIONS_INCLUDED_CUSTOM_LABEL',
                      transform: (label) => label.replace('%BUDGET%', budgetLabel),
                    })}
                  </span>
                )}
              </span>
              {!showOnlyNewStatement && (
                <span className="statement-row-value">{currentStatementValue}</span>
              )}
              <span className="statement-row-value">{newStatementValue}</span>
            </div>
          );
        }, category.rewards)
      )}
      {showTotal && (
        <Total
          values={category.total}
          label={TextOnly({ path: 'flexibleRewards.SIDE_TOTAL' })}
          variant={TOTAL_VARIANTS.naked}
          currencies={currencies}
          showOnlyNewStatement={showOnlyNewStatement}
          classes={Object.keys(category.rewards).length % 2 === 0 ? 'odd-row' : ''}
          hasDecimals
        />
      )}
    </Card>
  </StyledRewardCategory>
);

RewardCategory.propTypes = {
  currencies: PropTypes.arrayOf(PropTypes.string).isRequired,
  colors: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]).isRequired,
  category: PropTypes.shape({
    displayName: PropTypes.string,
    orderIndex: PropTypes.number,
    total: PropTypes.arrayOf(PropTypes.number),
    rewards: PropTypes.shape({}),
  }).isRequired,
  showOnlyNewStatement: PropTypes.bool.isRequired,
  budgetLabel: PropTypes.string.isRequired,
  showTotal: PropTypes.bool,
  hasDecimals: PropTypes.bool,
};

RewardCategory.defaultProps = {
  showTotal: true,
  hasDecimals: false,
};

export default RewardCategory;
