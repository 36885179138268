/* eslint-disable max-lines-per-function */
import styled, { css } from 'styled-components';
import * as R from 'ramda';
import { media } from '@trs/utils';

/* we need to look into refactoring this file.. */

const StyledMenu = styled.div`
  ${props => {
    const {
      palette,
      spacing,
      typography,
      components: { header },
      extra: { boxShadow },
    } = props.theme;
    const { isMimicView } = props;

    const headerBackground = R.prop('background', header) || palette.secondary;
    const headerText = R.prop('text', header) || palette.gray.alpha;
    const accentColor = R.prop('accentColor', header) || palette.primary;
    const disabledColor = R.prop('disabled', header) || palette.gray.bravo;

    return css`
      background-color: ${headerBackground};
      box-shadow: ${boxShadow};
      height: ${spacing.xLarge};
      margin-bottom: ${spacing.base};
      ${media.tablet`
        height: ${spacing.xxLarge};
        margin-bottom: ${spacing.basePlus};
      `};
      ${media.tabletLandscape`
        height: ${spacing.xxLarge};
      `};
      ${media.desktop`
        height: ${spacing.xxxLarge};
      `};
      display: flex;
      position: relative;

      ${isMimicView &&
        css`
          margin-top: 35px;
          .menu-list {
            pointer-events: none;

            li:not(:last-child) {
              display: none;
            }
          }
        `};

      .App-header {
        margin-bottom: ${spacing.xLarge};
      }

      .mobile-burger-menu {
        display: block;
        position: absolute;
        right: ${spacing.tiny};
        top: 50%;
        transform: translate(0, -50%);
      }

      .menu-icon,
      .close-icon {
        color: ${headerText};
        cursor: pointer;
        outline: none;
      }

      .menu-list {
        background: ${headerBackground};
        display: block;
        width: 100%;
        position: absolute;
        left: 0;
        top: 100%;
        margin: 0;
        padding: 0;
        color: ${headerText};
        list-style-type: none;
        z-index: 9;
        border-top: 1px solid ${palette.gray.charlie};
      }

      .menu-expanded {
        display: block;
        box-shadow: ${boxShadow};
        padding-right: ${spacing.tiny};
      }

      .menu-collapsed {
        display: none;
      }

      .menulist-wrapper {
        z-index: 1;
        position: relative;
      }

      .menu-list > a {
        color: ${headerText};
        text-decoration: none;
      }

      .CollapseTitle {
        color: ${headerText};
        font-weight: 400;
      }

      .sublist {
        display: block;
        color: ${headerText};
        cursor: default;
        background-color: ${headerBackground};
      }

      .menu-item {
        height: auto;
        margin: 0;
      }

      .menu-item-title,
      .subList-item {
        display: block;
        line-height: ${typography.lineHeight.h2};
        padding-left: ${spacing.xSmall};
        border-left: 4px solid transparent;
        position: relative;
        color: ${headerText};
        text-decoration: none;

        &:hover {
          border-left-color: ${palette.primary};
          background: ${palette.primary.charlie};
        }
      }

      .dropdown-item,
      .menu-button {
        border-left: 4px solid transparent;
        background-color: ${headerBackground};
        color: ${headerText};
        &:hover {
          border-left-color: ${palette.primary};
        }
      }

      .menu-button {
        text-transform: capitalize;
        font-weight: ${typography.weight.normal};
      }

      .CollapsibleHeader {
        color: ${headerText};
        background-color: ${headerBackground};
        padding: 0;
      }

      .collapsible {
        box-shadow: none;
      }
      .collapsible-content {
        min-width: 200px;
      }
      .menu-item-active {
        font-weight: bold;
        color: ${headerText};
      }
      .inactive {
        font-weight: 400;
        color: ${headerText};
      }

      .menu-item .header-item {
        padding-bottom: 0;
        border: 0;
      }

      .menu-item-title {
        position: relative;
      }

      .icon-icon {
        cursor: pointer;
        float: none;
        margin-top: 0px;
        padding: 0 ${spacing.small};
        font-size: ${typography.size.icon};
      }

      .dropDown-subtitle {
        color: ${disabledColor};
        font-size: ${typography.size.table};
        background-color: ${headerBackground};
        padding: 15px 0 10px 15px;
        margin: 0;
      }

      .dropdown-icon {
        margin-left: ${spacing.tiny};
        font-size: 14px;
        position: absolute;
        right: 0;
        top: 0;

        &::before {
          display: inline-block;
          transform: rotateZ(-90deg);
        }
      }

      .menu-item-active .dropdown-icon::before {
        transform: rotateZ(0);
      }

      .closed {
        display: none;
      }

      .open {
        padding: ${spacing.small} ${spacing.basePlus};
      }

      .sublist-dropdown {
        display: block;
        color: ${headerText};
        width: 100%;
        box-shadow: none;
      }

      .content-wrapper {
        z-index: 2;
      }

      ${media.tablet`
        .mobile-burger-menu {
          right: ${spacing.small};
        }

        .menu-expanded {
          padding-right: ${spacing.small};
        }
      `}

      ${media.tabletLandscape`
        .mobile-burger-menu {
          right: ${spacing.basePlus};
        }

        .menu-expanded {
          padding-right: ${spacing.basePlus};
        }

        .menu-item-title,
        .subList-item {
          padding-left: ${spacing.basePlus};
        }

        .open {
          padding: ${spacing.small} ${spacing.large};
        }
      `}

      ${media.desktop`

      .mobile-burger-menu {
        display: none;
      }

      .menu-list {
        display: flex;
        margin-left: 200px;
        background: none;
        width: auto;
        position: relative;
        left: auto;
        border-top: none;
      }

      .menu-expanded {
        box-shadow: none;
        padding-right: 0;
      }

      .menu-item {
        position: relative;
        margin: ${spacing.small};
        height: 49px;
      }
      .menu-item.profile .header-item {
        font-weight: bold;
      }

      .align-center {
        flex-grow: 1;
        text-align: right;
      }

      .align-right {
        flex-grow: 1;
        text-align: right;

        &:last-child {
          margin-right: 0;
        }

        .Collapse {
          display: inline-block;
          position: relative;
        }
      }

      .align-right + .align-right {
        flex-grow: 0;
      }

      .menu-item-body {
        display: none;
        position: absolute;
        left: 0;
        &.open {
          display: block;
          padding: 0;
          margin-top: 24px;
        }
      }

      .dropdown-icon {
        position: static;
        right: auto;
        top: auto;

        &::before {
          transform: none;
        }
      }
      .menu-item-active .dropdown-icon::before {
        transform: none;
      }

      .Menu-Wrapper{
        height: 10px;
      }
      .menu-item-active .header-item,
      .header-item:hover {
        border-bottom: 4px solid ${accentColor};
        padding-bottom: 32px;
      }
      .menu-item-title,
      .subList-item {
        display: block;
        padding-left: 0;
        border-left: none;
        color: ${headerText};

        &:hover {
          background: none;
        }
      }
      .sublist-dropdown {
        box-shadow: ${boxShadow};
        width: auto;
      }
      box-shadow: ${boxShadow};
      `};
    `;
  }};
`;

StyledMenu.displayName = 'StyledMenu';

export default StyledMenu;
