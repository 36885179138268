import React, { PureComponent } from 'react';
import { RichTextarea } from '@trs/components';
import onClickOutside from 'react-click-outside';
import PropTypes from 'prop-types';
import StyledReadMoreButton from './ReadMoreButton.style';

class ReadMoreButton extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { isOpen: false };
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  handleClickOutside = () => this.setState({ isOpen: false });

  toggleIsOpen = () => this.setState((prevState) => ({ isOpen: !prevState.isOpen }));

  render() {
    const { content, className, position } = this.props;
    const { isOpen } = this.state;

    const buttonText = 'more';

    return (
      <StyledReadMoreButton className={className} isOpen={isOpen} position={position}>
        {isOpen && (
          <div className="tooltip-text">
            <RichTextarea value={content} readOnly />
          </div>
        )}
        <button onClick={this.toggleIsOpen} className="read-more">
          {buttonText}
        </button>
      </StyledReadMoreButton>
    );
  }
}

ReadMoreButton.propTypes = {
  content: PropTypes.string,
  className: PropTypes.string,
  /** The position where to render the tooltip (relative to the container) */
  position: PropTypes.oneOf(['bottom']),
};

ReadMoreButton.defaultProps = {
  content: '',
  className: '',
  position: 'bottom',
};

export default onClickOutside(ReadMoreButton);
