import styled, { css } from 'styled-components';

const StyledCategoryTiles = styled.div`
  ${props => {
    const { spacing, palette: color, extra, typography } = props.theme;

    return css`
      margin-bottom: ${spacing.small};
      .category-tiles-container {
        margin-bottom: ${spacing.tiny};
        box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.04);
      }
      .button-center {
        display: flex;
        justify-content: center;
      }

      .category-group-footer {
        margin-bottom: ${spacing.tiny};
        font-size: ${typography.size.base};
        border-radius: 0 0 ${extra.genericBorderRadius} ${extra.genericBorderRadius};
        border: solid 1px ${color.utility.border};
        background-color: ${color.utility.bodyBackground};
        padding: ${spacing.tiny} ${spacing.small};
        border-top: 0;
        align-items: center;

        span {
          color: ${color.gray.bravo};
        }
        button {
          text-decoration: none;
          display: flex;
          align-items: center;
          font-weight: ${typography.weight.thick};
          font-size: ${typography.size.table};
          i {
            margin-right: ${spacing.tiny};
          }
        }
      }
    `;
  }};
`;

StyledCategoryTiles.displayName = 'StyledCategoryTiles';
export default StyledCategoryTiles;
