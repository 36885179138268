import { combineReducers } from 'redux';
import { handleActions, combineActions } from 'redux-actions';
import { isBlank } from '@trs/utils';
import * as R from 'ramda';
import initialState from './initialState';
import { updateBenchmarkingError } from '../actions/helpers';

import {
  saveBenchmarkingPending,
  saveBenchmarkingSuccess,
  saveBenchmarkingError,
  getBenchmarkingSettings,
  FxSourcesSuccess,
  updateField,
  loadDependenciesPending,
  loadDependenciesSuccess,
  loadDependenciesError,
  updateCountriesSelection,
  loadCountriesSuccess,
  submittedGlobalSettings,
  submitBenchmarkingPending,
  reloadFxPending,
  reloadFxSuccess,
  reloadFxError,
  getGlobalIdentifiers,
} from '../actions';

import { getActiveCount, getCountryDetailsModel } from '../components/countryDetails/helpers';
import BM_STATUS from '../../common/helpers';

import dashboard from '../components/dashboard/reducer';
import categoriesMappings from '../components/dashboard/components/mapCategories/reducer';

const getPayScaleIntervalsModel = payScaleIntervals => {
  if (isBlank(payScaleIntervals)) return {};
  const model = {};
  payScaleIntervals.map(({ populationId, min, max }) => {
    model[populationId] = { min, max };
    return true;
  });
  return model;
};

const mapFxSources = fxSources => {
  if (isBlank(fxSources)) return [];
  return fxSources.map(item => ({ value: item.id, label: item.name }));
};

const main = handleActions(
  new Map([
    [getGlobalIdentifiers, (state, { payload }) => ({ ...state, identifiers: payload })],
    [reloadFxPending, state => ({ ...state, reloadingFx: true })],
    [
      reloadFxSuccess,
      (state, action) => {
        const { countriesMaping } = state;
        R.forEach(item => {
          const row = countriesMaping[R.prop('code', item)];
          if (!isBlank(row)) row.rate = R.path(['foreignExchange', 'rate'], item) || '-';
        }, action.payload.countries);
        return { ...state, reloadingFx: false, countriesMaping };
      },
    ],
    [reloadFxError, state => ({ ...state, reloadingFx: false })],
    [
      getBenchmarkingSettings,
      (state, action) => {
        const { countries, payScaleIntervals } = action.payload;
        return {
          ...state,
          ...action.payload,
          ...getCountryDetailsModel(countries),
          payScaleIntervals: getPayScaleIntervalsModel(payScaleIntervals),
        };
      },
    ],
    [
      submittedGlobalSettings,
      state => ({
        ...state,
        status: BM_STATUS.SUBMITTED,
        savingBenchmarking: false,
      }),
    ],
    [
      combineActions(saveBenchmarkingPending, submitBenchmarkingPending),
      state => ({ ...state, savingBenchmarking: true }),
    ],
    [saveBenchmarkingError, state => ({ ...state, savingBenchmarking: false })],
    [
      saveBenchmarkingSuccess,
      (state, action) => ({ ...state, savingBenchmarking: false, ...action.payload }),
    ],
    [
      updateBenchmarkingError,
      (state, action) => {
        const newState = {
          ...state,
          savingBenchmarking: false,
        };
        return R.assocPath(['errors', ...action.payload.path], action.payload.data, newState);
      },
    ],
    [FxSourcesSuccess, (state, action) => ({ ...state, fxSources: mapFxSources(action.payload) })],
    [
      updateField,
      (state, action) => ({
        ...state,
        [action.payload.field]: action.payload.data,
      }),
    ],
    [loadDependenciesPending, state => ({ ...state, loadingDependencies: true })],
    [loadDependenciesSuccess, state => ({ ...state, loadingDependencies: false })],
    [loadDependenciesError, state => ({ ...state, loadingDependencies: false })],
    [
      updateCountriesSelection,
      (state, action) => {
        const { field, value, rowId } = action.payload;
        const updatedCountriesMaping = R.assocPath([rowId, field], value, state.countriesMaping);
        const activeCounter = getActiveCount(updatedCountriesMaping);
        return {
          ...state,
          countriesMaping: updatedCountriesMaping,
          activeCounter,
          errors: { ...state.errors, countryDetails: R.assoc(rowId, null, state.countryDetails) },
        };
      },
    ],
    [
      loadCountriesSuccess,
      (state, action) => {
        const { countries } = action.payload;

        return {
          ...state,
          ...getCountryDetailsModel(countries),
        };
      },
    ],
  ]),
  R.clone(initialState)
);

export default combineReducers({
  main,
  dashboard,
  categoriesMappings,
});
