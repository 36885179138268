"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
var R = _interopRequireWildcard(require("ramda"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var StyledCaption = _styledComponents["default"].span.withConfig({
  displayName: "Captionstyle__StyledCaption",
  componentId: "sc-p4mqq0-0"
})(["", ";"], function (props) {
  var captionPosition = props.captionPosition,
    borderWeight = props.borderWeight,
    _props$theme = props.theme,
    typography = _props$theme.typography,
    spacing = _props$theme.spacing,
    palette = _props$theme.palette,
    card = _props$theme.components.card,
    mandatory = props.mandatory;
  var border = captionPosition === 'bottom-card' && R.path(['bottomBorder', 'color'], card) ? borderWeight || R.path(['bottomBorder', 'weight'], card) : null;
  return (0, _styledComponents.css)(["font-size:", ";line-height:", ";position:absolute;bottom:-", ";left:0;padding-top:", ";transform:translateY(100%);", ";", ";"], typography.size.table, typography.lineHeight.paragraph, spacing.tiny, border, captionPosition === 'top' && (0, _styledComponents.css)(["top:-", ";padding-top:0;bottom:auto;transform:translateY(-100%);"], spacing.tiny), mandatory && (0, _styledComponents.css)(["padding-left:", ";&:before{content:'*';position:absolute;left:0;color:", ";}"], spacing.tiny, palette.gray.alpha));
});
StyledCaption.displayName = 'StyledCaption';
var _default = StyledCaption;
exports["default"] = _default;