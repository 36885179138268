"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
var _constants = require("../constants");
var _TabHeader = _interopRequireDefault(require("./TabHeader.style"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var StyledClassicContainer = (0, _styledComponents["default"])(_TabHeader["default"]).withConfig({
  displayName: "StyledClassicContainerstyle__StyledClassicContainer",
  componentId: "sc-kzgu7h-0"
})(["", ""], function (props) {
  var _props$theme = props.theme,
    typography = _props$theme.typography,
    color = _props$theme.palette,
    spacing = _props$theme.spacing,
    genericBorderRadius = _props$theme.extra.genericBorderRadius;
  var alignment = props.alignment;
  return (0, _styledComponents.css)(["border-bottom:solid 1px ", ";display:flex;height:", ";justify-content:", ";margin:0;padding-left:", ";width:100%;&&{.tab-wrapper{.tab-element{background-color:", ";border-bottom:0;border:1px solid ", ";border-radius:", " ", " 0 0;color:", ";font-size:", ";font-weight:", ";line-height:", ";margin-right:", ";padding:0 ", ";text-transform:capitalize;transition:0.2s linear background-color,0.2s linear color;&.selected{background-color:", ";border:solid 1px ", ";border-bottom:0;color:", ";margin-bottom:-1px;}}}}"], color.utility.border, spacing.large, _constants.ALIGNMENT_MAP[alignment], spacing.small, color.utility.bodyBackground, color.utility.bodyBackground, genericBorderRadius, genericBorderRadius, color.basic.gray, typography.size.base, typography.weight.bold, typography.lineHeight.high, spacing.tiny, spacing.xxxBase, color.basic.white, color.utility.border, color.secondary);
});
StyledClassicContainer.displayName = 'StyledClassicContainer';
var _default = StyledClassicContainer;
exports["default"] = _default;