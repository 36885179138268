import styled, { css } from 'styled-components';

const StyledSlider = styled.div`
  ${props => {
    const {
      spacing,
      palette: color,
      typography,
      extra: { genericBorderRadius },
    } = props.theme;

    return css`
      display: flex;

      margin: ${spacing.xxxBase} 0;
      .slider-handles {
        .input-component {
          left: 50%;
          position: absolute;
          top: -50px;
          transform: translateX(-50%);
          input {
            background-color: ${color.basic.white};
            border-radius: ${genericBorderRadius};
            border: solid 1px ${color.gray.silver};
            font-size: ${typography.size.base};
            padding: ${spacing.xTiny};
            text-align: center;
            height: ${spacing.xxxBase};
            width: ${spacing.xxxLarge};
          }
        }
      }
      .slider-component {
        flex: 1;
        position: relative;
        width: 100%;
      }
      .slider-rail {
        align-items: center;
        cursor: pointer;
        display: flex;
        height: 10px;
        position: absolute;
        width: 100%;
        .track-inner {
          background-color: ${color.utility.border};
          border-radius: 3px;
          height: 6px;
          width: 100%;
        }
      }
    `;
  }};
`;

StyledSlider.displayName = 'StyledSlider';
export default StyledSlider;
