import * as actionTypes from './actionTypes';

export const setWarningInactivity = showWarning => dispatch => {
  dispatch({
    type: actionTypes.SET_WARNING_INACTIVITY,
    payload: showWarning,
  });
};

export const resetInactivityTimer = shouldReset => dispatch => {
  dispatch({
    type: actionTypes.RESET_TIMER_INACTIVITY,
    payload: shouldReset,
  });
};

export const clearInactivityData = () => dispatch => {
  dispatch({
    type: actionTypes.CLEAR_DATA_INACTIVITY,
  });
};
