const userManagementVariables = {
  PAGE_TITLE: 'User Management',
  USERS_PAGE_SUBTITLE:
    'This section contains information about the users of the system. On this page you can add existing roles to one or multiple users. Select one or multiple users and apply a new role.',
  ROLES_PAGE_SUBTITLE:
    'This section contains information about existing roles in the system. You can create new roles or edit existing roles so that more users can have access to a specific module.',
};

const userManagementLabels = {
  EMPLOYEE_NAME: 'Employee Name',
  EMPLOYEE_ID: 'Employee ID',
  EMPLOYEE_TYPE: 'Type',
  EMPLOYEE_GRADE: 'Grade',
  EMAIL: 'Email',
  CURRENT_ROLES: 'Current Roles',
  FILTER_TITLE: 'Filter Title',
  NO_USERS: 'There are no users to display',
  NEW_BUTTON: 'Create new role',
  TAB_ROLES: 'Roles',
  TAB_USERS: 'Users',
  SHOW_SELECTED_USERS: 'Show selected users only',
  NO_OF_USERS_SELECTED: 'users selected',
  SELECT_ROLES: 'Select role(s)',
  APPLY_ROLES: 'Apply role(s)',
  USERS_FROM_COUNTRY_WARNING: '* The available users are those from the selected country(ies)',
  DIALOG_ASSIGN_MULTIPLE_ROLES_SUBTITLE:
    'Are you sure that you want to apply %COUNTROLES% Role(s) to %COUNTUSERS% Employee(s)?',
  BANNER_APPLY_ROLE_UPDATE: '%COUNT% Employee(s) were updated.',
  SELECTED_USERS: 'Selected users',
};
const rolesManagementLabels = {
  ROLE_NAME: 'Role Name',
  ROLE_COUNT: 'No. of members',
  ROLE_COUNTRY: 'Country',
  ROLE_AVAILABLE_MODULES: 'Available Modules',
  NO_ROLES: 'There are no roles to display',
  MENU_LIST_EDIT: 'Edit role',
  MENU_LIST_DELETE: 'Delete role',
  DIALOG_DELETE_TITLE: 'Are you sure you want to delete?',
  DIALOG_DELETE_SUBTITLE:
    'Please make sure you delete the correct role. The delete action is irreversible.',
  DIALOG_DELETE_ROLE_SUBTITLE: 'Deleting this role will impact %COUNT% user(s).',
  BANNER_DELETE: 'The %FIELD% role was successfully deleted.',
  DIALOG_BULK_UNASSIGN_ROLE_TITLE: 'Confirm unassign role',
  DIALOG_BULK_UNASSIGN_ROLE_SUBTITLE: 'This action will have effect based on the applied filters.',
  BANNER_BULK_UNASSIGN_ROLE_SUCCESS:
    'The role was successfully unassigned for %COUNT% user(s). Please wait until the operation completes.',
  BUTTON_BULK_UNASSIGN_ROLE: 'Unassign role',
  BUTTON_BULK_ASSIGN_ROLE: 'Assign role',
  DIALOG_BULK_ASSIGN_ROLE_TITLE: 'Confirm assign role',
  DIALOG_BULK_ASSIGN_ROLE_SUBTITLE: 'This action will have effect based on the applied filters.',
  BANNER_BULK_ASSIGN_ROLE_SUCCESS:
    'The role was successfully assigned for %COUNT% user(s). Please wait until the operation completes.',
};
const addRoleLabels = {
  ADD_ROLE_TITLE: 'Create New Role',
  TITLE_GENERAL: 'General',
  TITLE_MODULES: 'Modules',
  USER_PERMISSION_TITLE: 'User permissions',
  USER_PERMISSION_MESSAGE:
    'You only have access to a specific set of employees based on your permissions',
  USER_PERMISSION_BUTTON: 'Define Eligibility',
  CARD_MANDATORY_FIELDS_NOTE: 'Fields marked with * are mandatory',
  TAB_DEFINE_ACCESS_RIGHTS: 'Define Access Rights',
  TAB_PERMISSIONS: 'Permissions',
  TAB_SELECT_USERS: 'Select Users',
  NAME_LABEL: 'Role Name',
  COUNTRY_LABEL: 'Country',
  REQUIRE_AT_LEAST_ONE_MODULE: 'At least one module should be enabled',
  BUTTON_RESET: 'RESET',
  DIALOG_ASSIGN_ROLE_TITLE: 'Apply new role(s)?',
  DIALOG_ASSIGN_ROLE_SUBTITLE:
    'Are you sure that you want to apply %FIELD% to %COUNT% Employee(s)?',
  SELECT_ROLES_TITLE: 'Select roles',
  SELECT_USERS_TITLE: 'Select users',
  BANNER_SAVE_PERMISSIONS_SUCCESS: 'Permissions saved successfully',
  NO_ACCESS_PERMISSIONS_WARNING:
    'User permissions for %FIELD% module cannot be saved with all options specified as No access.',
};

const modulesLabels = {
  REWARDS_MANAGEMENT: 'With this module, the user will be able to manage rewards and variables',
  EMPLOYEE_DASHBOARD: 'With this module, the user will be able to see his rewards and benefits',
  USER_MANAGEMENT:
    'With this module, the user will be able to give specific access to users from the system',
  FX_MANAGEMENT:
    'With this module, the user will be able to manage exchange rates which are applied to rewards',
  ARTICLES_MANAGEMENT:
    'With this module, the user will be able to add/edit articles which will be seen by the eligible employees',
  EXCEPTIONS_MANAGEMENT:
    'With this module, the user will be able to add and remove exceptions to the rewards',
  FLEXIBLE_MANAGEMENT:
    'With this module, the user will be able to create flexible packages for his countries',
  MODELLER:
    'With this module, the user will be able to create simulations based on the existing rewards',
  REPORTS: 'With this module, the user will be able to analyze data and generate reports',
  AUDIT: 'With this module, the user will be able to see the changes made in the system',
  MIMIC_EMPLOYEE:
    'With this module, the user will be able to see other employees’ statements in ‘view only’ mode',
  SETTINGS:
    'With this module, the user will be able to manage the global configuration of the application',
  CLEAR_SELECTED: 'Clear all selected users',
  FEEDBACK_STATS: 'With this module, the user will be able to see feedback statistics',
  DATA_VALIDATION:
    'This widget allows the users to view the Employee data errors and download the Invalid Data Report with details',
  BENCHMARKING:
    'Plan evaluates internal salaries and benefits against market data and supports business decisions related to salary and pay scale increases',
  WORKSPACE: 'With this module, the user will be able to download and upload files into the system',
  FIXED_COSTS_MANAGEMENT: 'With this module, the user will be able to manage fixed costs',
  LTI_PLANS_MANAGEMENT:
    'With this module, the user will be able to create long term incentive plans for his countries',
};
const disclaimerPage = {
  DISCLAIMER_WELCOME_TEXT: 'Welcome to %PLATFORM%',
  DISCLAIMER_INTRO_TEXT: `We reward our employees based on individual contribution and performance of the business. By
  delivering your individual 3+1s, you help Unilever achieve its business objectives.`,
  DISCLAIMER_DESCRIPTION_TEXT: `We know that reward is important to you and it should not be a black box. We believe that reward should be <strong>open, fair, consistent and explainable</strong> and we aim to deliver every aspect of this in everything we do.<br><br>To get the full picture of your reward package, click on the <strong>Continue</strong> button to view your individual Total Reward Statement.<br><br>
  If you are a platform user with access to additional functionality you will be taken to your Home page.<br><br>
  Be advised that there is private and confidential information contained within the platform. Please be aware of your surroundings when viewing the information contained within.<br><br>
  By clicking the <strong>Continue</strong> button, you acknowledge that you have read, and agree to the terms and conditions.
  `,
};
const login = {
  LOGIN_TITLE: 'Login',
  LOGIN_USERNAME_LABEL: 'Username',
  LOGIN_USERNAME_HELP_TEXT: 'Please provide your work e-mail address',
  LOGIN_INVALID_EMAIL:
    'The e-mail address you have entered was not found in the system. Please use your company e-mail address',
  LOGIN_INVALID_EMAIL_FORMAT: 'The e-mail format is invalid',
  LOGIN_BUTTON_AUTHENTICATE: 'Authenticate',
};

const editUser = {
  NON_EMPLOYEE_LABEL: 'Set as Non Employee',
  NON_EMPLOYEE_DESCRIPTION: 'An external user (e.g. contractor)',
  EDIT_USER_LABEL: 'Edit User',
  FIRST_NAME_LABEL: 'First Name',
  LAST_NAME_LABEL: 'Last Name',
};
export default {
  ...editUser,
  ...userManagementLabels,
  ...userManagementVariables,
  ...modulesLabels,
  ...addRoleLabels,
  ...rolesManagementLabels,
  ...disclaimerPage,
  ...login,
};
