import * as R from 'ramda';
import {
  REWARD_CONTEXTS,
  TEMPLATE_TYPES,
  CONFLICT_STATUS_CODE,
  FORBIDDEN_STATUS_CODE,
  TOO_MANY_REQUESTS_STATUS_CODE,
} from '@trs/config';
import { getErrorMessage, getErrorStatus } from '@trs/utils';
import { showBanner } from 'actions/globalActions';

export const templateToRewardContextMapping = {
  [TEMPLATE_TYPES.simple]: [REWARD_CONTEXTS.reward],
  [TEMPLATE_TYPES.pension]: [REWARD_CONTEXTS.reward],
  [TEMPLATE_TYPES.share]: [REWARD_CONTEXTS.share],
  [TEMPLATE_TYPES.percentage]: [REWARD_CONTEXTS.reward],
};

export function dispatchError(error, dispatch, state) {
  let errorMessage = getErrorMessage(error);
  const status = getErrorStatus(error);

  if (state) {
    switch (status) {
      case CONFLICT_STATUS_CODE: {
        errorMessage = R.path(['cms', 'generic', 'data', 'CONFLICT_BANNER'], state);
        break;
      }

      case FORBIDDEN_STATUS_CODE: {
        const cmsErrorKey = R.path(['response', 'data', 'errors', 0], error);
        errorMessage = R.path(['cms', 'rewards', 'data', cmsErrorKey], state);
        break;
      }

      case TOO_MANY_REQUESTS_STATUS_CODE: {
        errorMessage = R.path(['cms', 'generic', 'data', 'TRY_LATER_GENERIC'], state);
        break;
      }

      default: {
        // nothing to do in this case
        break;
      }
    }
  }
  dispatch(
    showBanner({
      type: 'error',
      content: errorMessage,
    })
  );
}
