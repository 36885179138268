export const DEFAULT_ELIGIBILITY = [
  {
    key: 0,
    id: 0,
    name: 'Rule0',
    eligibility: {},
  },
];

const eligibilityValues = {
  rules: DEFAULT_ELIGIBILITY,
  originalRules: DEFAULT_ELIGIBILITY,
  systemVariables: [],
  eligibilityOperators: [],
  lookupValues: {},
  eligibilityErrors: [],
  eligibilityEtag: '',
  populationId: '',
};

const formValues = {
  countries: [],
  categories: [],
  form: {
    name: '',
    country: '',
    category: '',
    startDate: '',
    endDate: '',
    content: '',
    contentBanner: { type: 'Image', src: '', alt: '' },
    contentMedia: [],
    plainText: '',
  },
  originalForm: {
    name: '',
    country: '',
    category: '',
    startDate: '',
    endDate: '',
    content: '',
    contentBanner: {},
    contentMedia: [],
    plainText: '',
  },
};

export const initialState = {
  status: '',
  savedArticleId: '',
  savedArticleEtag: '',
  redirectToRoot: false,
  videoPlatforms: [],
  list: {},
  ...formValues,
  ...eligibilityValues,
};

export default initialState;
