import * as R from 'ramda';

export const mapExcludedRewards = payload =>
  R.map(
    item => ({
      id: item.id,
      rewardName: item.displayName,
      category: R.path(['category', 'categoryName'], item),
      subcategory: R.path(['category', 'subcategoryName'], item),
      type: R.path(['category', 'typeName'], item),
      effectiveDate: item.effectiveDate,
      expiryDate: item.expiryDate,
    }),
    payload
  );

export default { mapExcludedRewards };
