import { createAction } from 'redux-actions';
import * as R from 'ramda';
import { isBlank } from '@trs/utils';
import { benchmark } from '../../../../../../../config/api';
import { GET, RequestFactory } from '../../../../../../../config/http';
import { showBanner } from '../../../../../../../actions/globalActions';
import { TextOnly } from '../../../../../../common/Text';
import { loadGlobalIdentifiers } from '../../../../global/actions';
import { loadLocalBenchmarkingStatuses } from '../../../actions';
import { getLocalSettingsId } from '../../../actions/helpers';
import { loadPremerit } from '../components/merit/actions/permitAction';
import { getSalaryIncreaseAverage } from './salaryIncrease';
import actionTypes from './types';

export const saveProposal = {
  pending: createAction(actionTypes.BENCHMARKING_SAVE_PROPOSAL_PENDING),
  success: createAction(actionTypes.BENCHMARKING_SAVE_PROPOSAL_SUCCESS),
  error: createAction(actionTypes.BENCHMARKING_SAVE_PROPOSAL_ERROR),
};

export const getSalaryIncrease = createAction(actionTypes.BENCHMARKING_GET_SALARY_INCREASE_DATA);
export const cellChange = createAction(actionTypes.BENCHMARKING_SALARY_INCREASE_CELL_CHANGE);
export const cancelEditable = createAction(
  actionTypes.BENCHMARKING_SALARY_INCREASE_CANCEL_EDITABLE
);
export const validateDataForSubmit = createAction(actionTypes.BENCHMARKING_SALARY_DATA_VALID);

export const loadSalaryIncrease = () => (dispatch, getState) =>
  dispatch(loadGlobalIdentifiers()).then((globalBody) => {
    const localSalaryId = R.path(
      ['benchmarking', 'local', 'main', 'statusData', 'salaryIncrease', 'id'],
      getState()
    );
    if (isBlank(localSalaryId)) return Promise.reject();
    return dispatch(
      getSalaryIncrease(
        GET(`${benchmark.base}/benchmarking/${globalBody.id}/proposal/${localSalaryId}`, {
          Pragma: 'no-cache',
        }).then((response) => {
          const data = R.path(['data', 'response'], response);
          const salaryIncreaseId = R.prop('id', data);
          const etag = R.path(['headers', 'etag'], response);
          dispatch(loadPremerit(globalBody.id, salaryIncreaseId));

          return { data, etag };
        })
      )
    );
  });

export const saveSalaryIncrease = () => (dispatch, getState) => {
  const state = getState();
  const { tableData, proposalId, etag } = R.path(
    ['benchmarking', 'local', 'salaryIncrease', 'proposal'],
    state
  );
  const globalBenchmarkingId = R.path(
    ['benchmarking', 'global', 'main', 'identifiers', 'id'],
    state
  );
  const countryCode = R.path(['benchmarking', 'local', 'main', 'statusData', 'countryCode'], state);
  const localSettingsId = getLocalSettingsId(getState());

  if (isBlank(globalBenchmarkingId)) {
    return dispatch(saveProposal.error());
  }
  const populationProposals = tableData.map((item) =>
    R.omit(['disabled', 'selectable', 'collapsible', 'totalSalaryIncrease'], item)
  );
  const payload = {
    populationProposals,
    countryCode,
  };
  const requestUrl = proposalId
    ? `${benchmark.benchmarking}/${globalBenchmarkingId}/proposal/${proposalId}`
    : `${benchmark.benchmarking}/${globalBenchmarkingId}/proposal`;

  const opts = {
    url: requestUrl,
    method: proposalId ? 'PUT' : 'POST',
    headers: proposalId ? { Etag: etag } : {},
    data: payload,
    options: { showErrorModal: true, dispatch },
  };

  dispatch(saveProposal.pending());

  return RequestFactory(opts.method, opts.url, opts.headers, opts.data, opts.options)
    .then((response) => {
      const { id, isDataValid } = R.path(['data', 'response'], response);
      const { etag: Etag } = R.prop('headers', response);
      dispatch(saveProposal.success({ id, Etag }));
      dispatch(loadLocalBenchmarkingStatuses(localSettingsId));
      dispatch(validateDataForSubmit(isDataValid));
      dispatch(getSalaryIncreaseAverage({ data: {} }));
      dispatch(
        showBanner({
          type: 'success',
          content: TextOnly({ path: 'benchmarking.SALARY_PROPOSAL_SAVE_SUCCESS' }),
        })
      );
    })
    .catch((error) => {
      dispatch(saveProposal.error());
      return Promise.reject(error);
    });
};
