import * as R from 'ramda';
import { isBlank } from '@trs/utils';
import * as actions from '../actions/types';

const initialState = {
  list: [],
  totalResults: 0,
  redirect: false,
  fetchingList: true,
  errors: [],
  bannerMessage: '',
  filterOptions: [],
  showOnlyCheckedUsers: false,
};

export default function usersReducer(state = initialState, action) {
  switch (action.type) {
    case actions.USERS_REQUESTED: {
      return {
        ...state,
        fetchingList: true,
      };
    }

    case actions.USERS_GET: {
      let list = [];

      const { data } = action.response;

      const filterOptions = R.map(
        item => ({
          title: item.key,
          key: item.key,
          list: item.options,
        }),
        data.filterOptions || []
      );

      if (!isBlank(data)) {
        list = data.users.map(item => ({
          ...item,
        }));
      }

      return {
        ...state,
        list,
        filterOptions,
        fetchingList: false,
        totalResults: isBlank(data.count) ? 0 : data.count,
      };
    }

    default:
      return state;
  }
}
