export default {
  LOCAL_PREPARATION_TITLE: 'Local Preparation',
  COUNTRY_SPECIFIC: 'Country Specific',
  COMPANY_SPECIFIC: 'Company Specific',
  TMP_TITLE: 'Target Market Position (TMP)',
  TMG_TITLE: 'Target Market Group (TMG)',
  NO_COMPANY_ADDED: 'No company added',
  ADDITIONAL_INFO_TITLE: 'Additional Information',
  EXECUTIVE_SUMMARY_TITLE: 'Executive Summary',
  MARKET_DATA_TITLE: 'Market Data',
  MARKET_INDICATORS_TITLE: 'Market Indicators',
  NO_ADDITIONAL_INFO: 'No additional information added',
  ADDITIONAL_INFO_SAVE_SUCCESS: 'The additional information was saved successfully',
  MANUAL_LTI_VALUES: 'Manual LTI values',
  MANUAL_BENEFITS_VALUES: 'Manual Benefits values',
  DOWNLOAD_TEMPLATE: 'Download Template',
  MARKET_DATA_UPLOAD_BUTTON_TITLE: 'Add Survey Data from File',
  UPLOAD_SURVEY_DATA_TITLE: 'Upload survey data',
  UPLOAD_LAST_YEAR_DATA_TITLE: 'Upload last year market data',
  INPUT_MARKET_DATA_TITLE: 'Input Market Data',
  COLUMN_COUNTRY_SPECIFIC_NAME: 'Market Indicators',
  COLUMN_COUNTRY_SPECIFIC_PREV_YEAR: 'Previous Year (%)',
  COLUMN_COUNTRY_SPECIFIC_CURRENT_YEAR: 'Current Year (%)',
  COLUMN_COUNTRY_SPECIFIC_NEXT_YEAR: 'Next Year (%)',
  COLUMN_COMPANY_SPECIFIC_PREV_YEAR: 'Last Year Actual (%)',
  COLUMN_COMPANY_SPECIFIC_CURRENT_YEAR: 'Current Year Estimate (%)',
  COLUMN_COMPANY_SPECIFIC_NEXT_YEAR: 'Next Year Projected (%)',
  MARKET_INDICATORS_SAVE_SUCCESS: 'The market indicators were saved successfully',
  COLUMN_TMG_COMPANY: 'Company',
  COLUMN_TMG_INDUSTRY: 'Industry',
  COLUMN_TMG_REVENUE: 'Revenue (Euro)',
  COLUMN_TMG_NO_OF_EMPLOYEES: 'No of Employees',
  TMG_COMPANY_PLACEHOLDER: 'Complete company name',
  TMG_INDUSTRY_PLACEHOLDER: 'Select company industry',
  TMG_REVENUE_PLACEHOLDER: 'Complete revenue',
  TMG_NO_OF_EMPLOYEES_PLACEHOLDER: 'Complete no. of employees',
  TMG_ADD_COMPANY_SUCCESS: 'Company was added successfully',
  DELETE_TMG_COMPANY_TITLE: 'Remove company',
  DELETE_TMG_COMPANY_SUBTITLE: 'Are you sure you want to remove this company?',
  DELETE_TMG_COMPANY_SUCCESS: 'The company was removed successfully.',
  SAVE_TMG_SUCCESS: 'Target Market Group was saved successfully.',
  MARKET_DATA_SAVE_SUCCESS: 'The market data saved successfully',
  COLUMN_MARKET_DATA_POPULATION: 'Population',
  COLUMN_MARKET_DATA_FOR_PAYSCALE: 'Market Data for Payscale',
  COLUMN_MARKET_DATA_BASE_PAY: 'Base Pay',
  COLUMN_MARKET_DATA_FIXED_PAY: 'Fixed Pay',
  COLUMN_MARKET_DATA_TARGET_BONUS: 'Target Bonus',
  COLUMN_MARKET_DATA_LTI: 'LTI',
  COLUMN_MARKET_DATA_TCC: 'Target Total Cash',
  COLUMN_MARKET_DATA_TDC: 'Target Total Direct Compensation',
  COLUMN_MARKET_DATA_BENEFITS: 'Benefits',
  COLUMN_MARKET_DATA_TOTAL_REWARD: 'Total Reward',
  COLUMN_MARKET_DATA_REFERENCE: 'P75',
  COLUMN_MARKET_DATA_TARGET: 'P50',
  COLUMN_MARKET_DATA_TARGET_62: 'P62.5',
  COLUMN_MARKET_DATA_TARGET_100: 'P100',
  AGEING_FACTOR_LABEL: 'Ageing Factor',
  AGEING_DAYS_CURRENT_YEAR: 'Ageing Days in Current Year',
  AGEING_DAYS_NEXT_YEAR: 'Ageing Days in Next Year',
  CURRENT_YEAR_SALARY_INCREASE: 'Current Year Market Salary Increase',
  NEXT_YEAR_SALARY_INCREASE: 'Next Year Market Salary Increase',
  AGE_DATA_BUTTON: 'Age Data',
  AGE_DATA_DATE_LABEL: 'Aged Data as of: %DATE%',
  SURVEY_REFERENCE_DATE_LABEL: 'Survey Reference Date',
  MARKET_SALARY_CURRENT_YEAR_IS_NULL: 'Market Salary Current Year is null',
  SURVEY_REFERENCE_DATE_IS_NULL: 'Survey reference data is null',
  MARKET_DATA_INPUT_DATA_NULL: 'Market data input is null',
  AGEING_DATE_IS_NULL: 'Ageing date is null',
  MARKET_SALARY_NEXT_YEAR_IS_NULL: 'Market salary next year is null',
  MARKET_DATA_POPULATIONS_INVALID: 'Market data population is invalid',
  AGE_FIXED_ALLOWANCES: 'Age Fixed Allowances',
  REWARDS_TITLE: 'Rewards',
  REWARDS_EXCLUSION_TITLE: 'Rewards Exclusion',
  COLUMN_REWARD_NAME: 'Reward Name',
  COLUMN_REWARD_CATEGORY: 'Category',
  COLUMN_REWARD_SUBCATEGORY: 'Subcategory',
  COLUMN_REWARD_TYPE: 'Type',
  COLUMN_REWARD_EFFECTIVE_DATE: 'Effective Date',
  COLUMN_REWARD_EXPIRY_DATE: 'Expiry Date',
  REWARD_DELETED_SUCCESS: 'Reward was removed successfully.',
  DELETE_EXCLUDED_REWARD_TITLE: 'Remove excluded reward',
  DELETE_EXCLUDED_REWARD_SUBTITLE: 'Are you sure you want to remove this excluded reward?',
  REWARD_ADDED_SUCCESS: 'The reward was added successfully to the exclusion list.',
  SEARCH_REWARD_PLACEHOLDER: 'Search rewards name',
  NO_REWARDS_FOUND: 'No rewards found',
  SEARCHING_LABEL: 'Searching..',
  POPULATIONS_TITLE: 'Population',
  GLOBAL_POPULATION_TITLE: 'Global Population',
  IMPACTED_POPULATION_TITLE: 'Impacted Population',
  EXCEPTIONS_TITLE: 'Exceptions',
  EXCEPTION_ADD_NEW: 'Add new exception',
  POPULATION_EXCEPTIONS_SAVE_SUCCESS: 'Population exceptions saved successfully',
  LOCAL_PREPARATION_SUBMIT_SUCCESS: 'Local Preparation was successfully submitted.',
  LOCAL_PREPARATION_APROVED_SUCCESS: 'Local Preparation was successfully approved.',
  COLUMN_EMPLOYEE_ID: 'Employee ID',
  GENERATE_BUTTON: 'Generate',
  DOWNLOAD_REPORT: 'Download CSV',
  LOCAL_PREPARATION_REJECT_SUCCESS: 'Local Preparation was rejected.',
  LOCAL_PREPARATION_REJECTION_TITLE: '"Local Preparation" has been rejected.',
  LAST_YEAR_MARKET_DATA: 'Last year input data',
  PERCENTAGE_CHANGE: 'Percentage Change',
  LAST_YEAR_MARKET_DATA_UPLOAD_BUTTON_TITLE: 'Upload Last Year Market Data',
  LOADING_LAST_YEAR_DATA: 'Loading Last Year Input Data',
  CONTINUE_TO: 'Continue to %SECTION%',
  FIELD_IS_REQUIRED: 'This field is required',
};
