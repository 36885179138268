import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { bindActionCreators } from 'redux';
import { isBlank } from '@trs/utils';
import { showBanner } from 'actions/globalActions';
import { reports, organization, audit, rewards, benefitsEnvelope } from 'config/api';
import ProcessTriggeringNotification from 'modules/workspace/components/ProcessTriggering/Notification';
import RoleEligibilityNotification from 'modules/userManagement/components/eligibility/components/RoleEligibilityNotification';
import FileDownloadNotification from 'modules/common/FileDownloadNotification';
import FileUploadNotification from 'modules/exceptions/components/FileUploadNotification';
import ExceptionsNotification from 'modules/exceptions/components/ExceptionsNotification';
import GeneratePayscalesNotification from 'modules/onboarding/components/hr/components/PayscalesDimensions/components/GeneratePayscalesNotification';
import { TextOnly } from 'modules/common/Text';
import * as notificationsActions from '../../actions';
import { NOTIFICATION_TYPE } from '../../constants';
import Report from './Report';
import OverallImpact from './OverallImpact';
import Workspace from './Workspace';
import DataLoading from './DataLoading';
import StyledNotificationsItem from './NotificationsItem.style';

class NotificationsItem extends Component {
  markNotification = (id) => {
    this.props.actions.markNotification(id);
  };

  onFileDownload = (succMessage) => {
    const { actions } = this.props;
    actions.showBanner({
      type: 'success',
      content: succMessage,
    });
  };

  onFileDownloaded = (err, errMessage, succMessage) => {
    const { actions } = this.props;
    actions.showBanner({
      type: err ? 'error' : 'success',
      content: err ? errMessage : succMessage,
    });
  };

  // eslint-disable-next-line max-lines-per-function
  renderContent = (item) => {
    switch (item.typeId) {
      case NOTIFICATION_TYPE.OVERALL_IMPACT:
        return <OverallImpact item={item} markNotification={this.markNotification} />;
      case NOTIFICATION_TYPE.WORKSPACE:
        return <Workspace item={item} markNotification={this.markNotification} />;
      case NOTIFICATION_TYPE.REPORT:
        return (
          <Report
            item={item}
            markNotification={this.markNotification}
            onFileDownload={this.onFileDownload}
            onFileDownloaded={this.onFileDownloaded}
          />
        );
      case NOTIFICATION_TYPE.COUNTRY_REWARD_REPORT: {
        const { id, createdDate, content } = item;
        const hasFailed = content.hasFailed === 'true';
        const { fileName } = content;
        const countries = [content.countries];
        return (
          <FileDownloadNotification
            isError={hasFailed}
            title={TextOnly({ path: 'notifications.NOTIFICATION_COUNTRY_REWARDS_TITLE' })}
            content={TextOnly({
              path: 'notifications.NOTIFICATION_COUNTRY_REWARDS_BODY',
              withHtml: true,
              transform: (label = '') =>
                label.replace('%COUNTRIES%', countries.join(',') || ' -- '),
            })}
            notificationId={id}
            notificationDate={createdDate}
            markNotification={this.markNotification}
            onFileDownloaded={this.onFileDownloaded}
            downloadLink={`${reports.countryRewardsExport}/${fileName}/download`}
            item={item}
          />
        );
      }
      case NOTIFICATION_TYPE.WORKSPACE_PROCESS_TRIGGERING:
        return (
          <ProcessTriggeringNotification item={item} markNotification={this.markNotification} />
        );
      case NOTIFICATION_TYPE.DATA_VALIDATION: {
        const {
          id,
          createdDate,
          content: { country },
        } = item;
        return (
          <FileDownloadNotification
            title={TextOnly({ path: 'notifications.NOTIFICATION_DATA_VALIDATION_TITLE' })}
            content={TextOnly({ path: 'notifications.NOTIFICATION_DATA_VALIDATION_CONTENT' })}
            notificationId={id}
            notificationDate={createdDate}
            markNotification={this.markNotification}
            onFileDownload={this.onFileDownload}
            onFileDownloaded={this.onFileDownloaded}
            downloadLink={`${organization.employeeValidations}/${country}/export`}
            item={item}
          />
        );
      }
      case NOTIFICATION_TYPE.GENERATE_EMPLOYEE_ELECTIONS: {
        const {
          id,
          createdDate,
          content: { flexPackageId, employeeElectionsFileName },
        } = item;

        const fileName = employeeElectionsFileName || '';

        return (
          <FileDownloadNotification
            isError={isBlank(employeeElectionsFileName)}
            title={TextOnly({
              path: 'notifications.NOTIFICATION_EMPLOYEE_ELECTIONS_TITLE',
            })}
            content={TextOnly({ path: 'notifications.REPORT_EXPORTED' })}
            notificationId={id}
            notificationDate={createdDate}
            name={`${fileName.split('^')[0]}.${fileName.split('.')[1]}`}
            markNotification={this.markNotification}
            onFileDownload={this.onFileDownload}
            onFileDownloaded={this.onFileDownloaded}
            downloadLink={`${reports.employeeElections}/${flexPackageId}/download/${employeeElectionsFileName}`}
            item={item}
          />
        );
      }
      case NOTIFICATION_TYPE.BENEFITS_ENVELOPE_GENERATE_REPORT_CONFIGURATION: {
        const {
          id,
          createdDate,
          content: { fileName },
        } = item;

        return (
          <FileDownloadNotification
            isError={isBlank(fileName)}
            title={TextOnly({
              path: 'notifications.NOTIFICATION_CUSTOM_REPORT',
              transform: (label) => label.replace('%FILENAME%', fileName),
            })}
            content={TextOnly({ path: 'notifications.REPORT_EXPORTED' })}
            notificationId={id}
            notificationDate={createdDate}
            markNotification={this.markNotification}
            onFileDownload={this.onFileDownload}
            onFileDownloaded={this.onFileDownloaded}
            downloadLink={benefitsEnvelope.downloadReport(fileName)}
            name={fileName}
            item={item}
          />
        );
      }
      case NOTIFICATION_TYPE.FORECAST_REPORT_CONFIGURATION: {
        const {
          id,
          createdDate,
          content: { fileName },
        } = item;

        return (
          <FileDownloadNotification
            isError={isBlank(fileName)}
            title={TextOnly({ path: 'notifications.FORECAST_REPORT_TITLE' })}
            content={TextOnly({ path: 'notifications.REPORT_EXPORTED' })}
            notificationId={id}
            notificationDate={createdDate}
            markNotification={this.markNotification}
            onFileDownload={this.onFileDownload}
            onFileDownloaded={this.onFileDownloaded}
            downloadLink={benefitsEnvelope.downloadForecastReport(fileName)}
            name={fileName}
            item={item}
          />
        );
      }
      case NOTIFICATION_TYPE.ENROLMENT_WINDOWS_REPORT: {
        const {
          id,
          createdDate,
          content: { fileName },
        } = item;

        return (
          <FileDownloadNotification
            isError={isBlank(fileName)}
            title={TextOnly({
              path: 'notifications.ENROLMENT_WINDOWS_REPORT',
            })}
            content={TextOnly({ path: 'notifications.REPORT_EXPORTED' })}
            notificationId={id}
            notificationDate={createdDate}
            markNotification={this.markNotification}
            onFileDownload={this.onFileDownload}
            onFileDownloaded={this.onFileDownloaded}
            downloadLink={rewards.downloadEnrollmentWindowsReport(fileName)}
            name={fileName}
            item={item}
          />
        );
      }
      case NOTIFICATION_TYPE.DOWNLOAD_AUDIT_INFO: {
        const {
          id,
          createdDate,
          content: { fileName, isFileCreated },
        } = item;

        return (
          <FileDownloadNotification
            isError={isFileCreated === 'False'}
            title={TextOnly({ path: 'notifications.NOTIFICATION_SYSTEM_AUDIT_TITLE' })}
            content={TextOnly({ path: 'notifications.NOTIFICATION_AUDIT_CONTENT' })}
            notificationId={id}
            notificationDate={createdDate}
            markNotification={this.markNotification}
            onFileDownload={this.onFileDownload}
            onFileDownloaded={this.onFileDownloaded}
            downloadLink={`${audit.getAuditRowFile}/${fileName}`}
            item={item}
          />
        );
      }
      case NOTIFICATION_TYPE.EMPLOYEE_DATA_LOADING:
      case NOTIFICATION_TYPE.SHARES_DATA_LOADING: {
        return (
          <DataLoading
            item={item}
            markNotification={this.markNotification}
            onFileDownload={this.onFileDownload}
            onFileDownloaded={this.onFileDownloaded}
          />
        );
      }
      case NOTIFICATION_TYPE.ROLE_ELIGIBILITY: {
        return <RoleEligibilityNotification item={item} markNotification={this.markNotification} />;
      }
      case NOTIFICATION_TYPE.GENERATE_PAYSCALES: {
        return (
          <GeneratePayscalesNotification item={item} markNotification={this.markNotification} />
        );
      }
      case NOTIFICATION_TYPE.EXCEPTIONS_FILE_UPLOAD: {
        const {
          id,
          createdDate,
          content: { errorFileName, fileName },
        } = item;
        const hasError = !isBlank(errorFileName);
        const errorFileDownloadLink = hasError
          ? `${rewards.exceptionsErrorsDownload}/${errorFileName}`
          : '';

        return (
          <FileUploadNotification
            notificationId={id}
            createdDate={createdDate}
            markNotification={this.markNotification}
            onFileDownload={this.onFileDownload}
            onFileDownloaded={this.onFileDownloaded}
            downloadLink={errorFileDownloadLink}
            uploadedFileName={fileName}
            hasError={hasError}
            item={item}
          />
        );
      }
      case NOTIFICATION_TYPE.EXCEPTIONS_DELETE:
      case NOTIFICATION_TYPE.EXCEPTIONS_PUBLISH:
        return <ExceptionsNotification item={item} markNotification={this.markNotification} />;
      default:
        return null;
    }
  };

  render() {
    const { item } = this.props;
    const status = R.path(['content', 'status'], item);

    return (
      <StyledNotificationsItem
        className={`widget-item ${!item.isRead ? 'active' : ''}`}
        status={status}
      >
        {this.renderContent(item)}
        {status && <div className="notification-status-vl" />}
      </StyledNotificationsItem>
    );
  }
}

NotificationsItem.propTypes = {
  actions: PropTypes.shape({
    markNotification: PropTypes.func,
    showBanner: PropTypes.func,
  }).isRequired,
  item: PropTypes.shape({
    typeId: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    isRead: PropTypes.bool.isRequired,
    content: PropTypes.shape({}).isRequired,
  }).isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...notificationsActions, showBanner }, dispatch),
});

export default connect(null, mapDispatchToProps)(NotificationsItem);
