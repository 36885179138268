export const ltiPlansHeaderLabels = {
  PAGE_GROUP: 'LTI Plan',
  PAGE_TITLE: 'LTI Plans',
  PAGE_DESCRIPTION:
    'This section allows you to manage Long Term Incentive Plans (LTIP) that vest over multiple years. Eligible employees will be able to view the vesting schedule of their LTIP awards on their employee statement.',
  ADD_LTI_PLANS_PAGE_TITLE: 'New LTI Plan',
  NEW_BUTTON: 'Create LTI Plan',
};

export const ltiPlansStatusLabels = {
  DISPLAY_NAME: 'Name',
  STATUS: 'Status',
  START_DATE: 'Start Date',
  END_DATE: 'End Date',
  CURRENCY: 'Currency',
  COUNTRY: 'Country',
  REVERT_TO_DRAFT: 'Revert to draft',
};

export const tiPlansListLabels = {
  LIST_NO_PLANS_AVAILABLE: `There aren't any Long Term Incentive Plans in the system. You can start by adding a Long Term Incentive Plan.`,
  LIST_DISPLAY_NAME_LABEL: 'Plan Name',
  LIST_TYPE_LABEL: 'Type',
  LIST_VESTING_LABEL: 'Vesting Type',
  LIST_COUNTRY_LABEL: 'Country',
  LIST_STATUS_LABEL: 'Status',
  LIST_START_DATE_LABEL: 'Start Date',
  MENU_LIST_EDIT: 'Edit',
  MENU_LIST_VIEW: 'View',
};

export const ltiPlansBasicInfoLabels = {
  TAB_BASIC_INFO: 'Basic Information',
  BASIC_INFO_GENERAL_CARD_TITLE: 'General',
  BASIC_INFO_SCHEDULE_CARD_TITLE: 'Schedule',
  BASIC_INFO_PLAN_TYPE_LABEL: 'Plan Type',
  BASIC_INFO_PLAN_TYPE_PLACEHOLDER: 'Select a Plan Type',
  BASIC_INFO_PLAN_NAME_LABEL: 'Plan Name',
  BASIC_INFO_PLAN_NAME_PLACEHOLDER: 'Name of the plan',
  BASIC_INFO_PLAN_DESCRIPTION_LABEL: 'Plan Description',
  BASIC_INFO_PLAN_DESCRIPTION_PLACEHOLDER: 'Type your description here',
  BASIC_INFO_COUNTRY_LABEL: 'Country',
  BASIC_INFO_COUNTRY_PLACEHOLDER: 'Select a Country',
  BASIC_INFO_CURRENCY_LABEL: 'Currency',
  BASIC_INFO_CURRENCY_PLACEHOLDER: 'Select a Currency',
  BASIC_INFO_FX_SOURCES_LABEL: 'FX Source',
  BASIC_INFO_FX_SOURCES_PLACEHOLDER: 'Select FX Source',
  BASIC_INFO_FX_RATE_DATE_LABEL: 'FX Rate Date',
  BASIC_INFO_FX_RATE_DATE_PLACEHOLDER: 'Select FX Rate Date',
  BASIC_INFO_FX_RATE_CUSTOM_DATE_LABEL: 'Custom Date',
  BASIC_INFO_START_DATE_LABEL: 'Start Date',
  BASIC_INFO_START_DATE_BASEMENT_CAPTION: 'Will become effective at the selected date, 00:00 [UTC]',
  BASIC_INFO_LENGTH_IN_YEARS_TEXT_LABEL: 'Length (years)',
  BASIC_INFO_LENGTH_IN_YEARS_TEXT_PLACEHOLDER: 'Type length in years here',
  BASIC_INFO_VESTING_TEXT_LABEL: 'Vesting Type',
  BASIC_INFO_VESTING_TEXT_PLACEHOLDER: 'Select a Vesting Type',
  BASIC_INFO_RUN_STEP_LABEL: 'Step',
  BASIC_INFO_RUN_STEP_EVERY_NO_LABEL: 'No',
  BASIC_INFO_RUN_STEP_EVERY_FREQUENCY_LABEL: 'Every',
  BASIC_INFO_RECURRENCE_ERROR: 'Required',
  BASIC_INFO_RECURRENCE_MONTHLY_LABEL: 'Months',
  BASIC_INFO_RUN_RECURRENCE_ANNUAL_LABEL: 'Years',
};

export const ltiPlansRulesBuilderLabels = {
  TAB_RULES_BUILDER: 'Rules Logic Builder',
  RULES_BUILDER_ADD: 'Add',
  RULES_BUILDER_DUPLICATE: 'Duplicate',
  RULES_BUILDER_RULE_LABEL: 'Rule',
  RULES_BUILDER_EMPTY_FORMULA_ERROR_LABEL: 'Make sure you have at least one value or function.',
  RULES_BUILDER_VARIABLE_MISSING: 'Expression is required.',
  RULES_BUILDER_OPERATOR_MISSING: 'Operator is required.',
  DISTRIBUTION_AUTOMATIC_TITLE: 'Automatic distribution',
  DISTRIBUTION_AUTOMATIC_SUBTITLE:
    'Automatically distribute the long term incentive plan values over the vesting schedule.',
  DISTRIBUTION_MANUAL_TITLE: 'Manual distribution',
  DISTRIBUTION_MANUAL_SUBTITLE:
    'Manually manage the long term incentive plan values over the vesting schedule.',
  DISTRIBUTION_TABLE_COLUMN_STEP_DATE: 'STEP',
  DISTRIBUTION_TABLE_COLUMN_STEP_DISTRIBUTION: '% DISTRIBUTION',
  DISTRIBUTION_TABLE_NO_RESULTS: 'No results found.',
  DISTRIBUTION_TABLE_TOTAL: 'Total',
};

export const ltiEligibility = {
  ELIGIBILITY_GENERIC_LABEL: 'Eligibility',
  ELIGIBILITY_NEW_BLOCK_PLACEHOLDER:
    'Start by adding an %eligibility criterion% or leave it as is to apply to all %country% employees.',
  ELIGIBILITY_DELETE_RULE: 'Delete rule',
  ELIGIBILITY_DUPLICATE_RULE: 'Duplicate rule',
  ELIGIBILITY_DELETE_GROUP: 'Delete group',
  ELIGIBILITY_DUPLICATE_GROUP: 'Duplicate group',
  ELIGIBILITY_RULE_VARIABLE_NAME: 'Variable name',
  ELIGIBILITY_RULE_OPERATOR: 'Operator',
  ELIGIBILITY_RULE_VALUE: 'Value',
  ELIGIBILITY_AND_OPERATOR: 'AND',
  ELIGIBILITY_OR_OPERATOR: 'OR',
  ELIGIBILITY_IF_KEYWORD: 'IF',
  ELIGIBILITY_OPERATOR_PLACEHOLDER: 'Operator',
  ELIGIBILITY_VALUE_PLACEHOLDER: 'Value',
  ELIGIBILITY_VALUE_SELECT_PLACEHOLDER: 'Type to search value',
  ELIGIBILITY_CREATEABLE_SELECT_PLACEHOLDER: 'Type to add values',
};

export const ltiPlansFormula = {
  FORMULA_GENERIC_LABEL: 'NO. OF SHARES / AMOUNT',
  FORMULA_SIMPLE_PLACEHOLDER:
    'Start by inserting a %value% or a function (%min%, %max%, %round%, %round up%, %round down%).',
  FORMULA_INPUT_PLACEHOLDER: 'Type variable or number',
  FORMULA_INPUT_DECIMALS_PLACEHOLDER: 'No. of decimals',
  FORMULA_MAX_FUNCTION_LABEL: 'Max',
  FORMULA_MIN_FUNCTION_LABEL: 'Min',
  FORMULA_ROUND_FUNCTION_LABEL: 'Round',
  FORMULA_ROUND_UP_FUNCTION_LABEL: 'Round Up',
  FORMULA_ROUND_DOWN_FUNCTION_LABEL: 'Round Down',
};

export const ltiPlansErrors = {
  FIELD_IS_REQUIRED: 'This field is required',
  VALUE_IS_NEGATIVE: 'Negative values are no supported',
  SUM_NOT_100: 'Please make sure the distribution percentage equals 100%.',
  FORBIDDEN_POPULATION_ACCESS: 'No access permissions',
  INTEGER_ONLY: 'Integer only allowed',
  NEGATIVE_VALUES_NOT_ALLOWED: 'Negative values are not allowed',
  RULES_BUILDER_RULE_ALREADY_EXISTS: 'Resource already exists',
  RULES_BUILDER_RULE_INVALID_TYPE: 'Invalid type.',
  RULES_BUILDER_INVALID_FORMULA_ERROR_LABEL: 'Please check the syntax of your formula.',
};

export const ltiPlansModal = {
  DIALOG_REVERT_TITLE: 'Are you sure you want to revert?',
  DIALOG_REVERT_SUBTITLE: `If you select "Revert," all your changes will be lost. If you select Cancel, your changes are kept but they are not saved.`,
  DIALOG_PUBLISH_TITLE: 'Publish Long Term Incentive Plan?',
  DIALOG_PUBLISH_SUBTITLE:
    'You are about to publish this Long Term Incentive Plan to the system.The Long Term Incentive Plan ' +
    'will be calculated on its effective date (%Effective Date%) and then will ' +
    'automatically become LIVE.',
  DIALOG_LIVE_TITLE: 'Live Long Term Incentive Plan',
  DIALOG_LIVE_EDIT_TITLE: 'Are you sure you want to edit a live Long Term Incentive Plan?',
  DIALOG_LIVE_EDIT_SUBTITLE:
    'These changes will be visible to eligible employees. If you select `Cancel` your changes will be lost.',
  DIALOG_LIVE_TO_PUBLISH_SUBTITLE:
    'Editing this Long Term Incentive Plan will change its status to published and will affect its ' +
    'visibility in reports',
  DIALOG_REVERT_TO_DRAFT_TITLE: 'Are you sure you want to Revert to draft?',
  DIALOG_REVERT_TO_DRAFT_SUBTITLE:
    'These changes will have an immediate effect on the Long Term Incentive Plan. %LOSE_CHANGES%',
  DIALOG_REVERT_TO_DRAFT_LOSE_CHANGES: 'You will lose any unsaved changes when reverting to draft.',
  BANNER_REVERT_TO_DRAFT_SUCCESS:
    'Long Term Incentive Plan successfully moved to draft. Please wait until the operation completes.',
};

export default {
  ...ltiPlansHeaderLabels,
  ...ltiPlansBasicInfoLabels,
  ...ltiPlansRulesBuilderLabels,
  ...ltiPlansModal,
  ...ltiPlansStatusLabels,
  ...tiPlansListLabels,
  ...ltiEligibility,
  ...ltiPlansFormula,
  ...ltiPlansErrors,
};
