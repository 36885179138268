"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.storageMock = exports.sessionStorageManager = exports.localStorageManager = exports.INACTIVITY_TIMEOUT_KEY = exports.EXPIRED_TIME_KEY = void 0;
var INACTIVITY_TIMEOUT_KEY = 'inactivityTimeout';
exports.INACTIVITY_TIMEOUT_KEY = INACTIVITY_TIMEOUT_KEY;
var EXPIRED_TIME_KEY = '_expiredTime';
exports.EXPIRED_TIME_KEY = EXPIRED_TIME_KEY;
var localStorageManager = function () {
  var setItem = function setItem(key, value) {
    return localStorage.setItem(key, JSON.stringify(value));
  };
  var getItem = function getItem(key) {
    return JSON.parse(localStorage.getItem(key));
  };
  var removeItem = function removeItem(key) {
    return localStorage.removeItem(key);
  };
  return {
    setItem: setItem,
    getItem: getItem,
    removeItem: removeItem
  };
}();
exports.localStorageManager = localStorageManager;
var sessionStorageManager = function () {
  var setItem = function setItem(key, value) {
    return sessionStorage.setItem(key, JSON.stringify(value));
  };
  var getItem = function getItem(key) {
    return JSON.parse(sessionStorage.getItem(key));
  };
  var removeItem = function removeItem(key) {
    return sessionStorage.removeItem(key);
  };
  return {
    setItem: setItem,
    getItem: getItem,
    removeItem: removeItem
  };
}();

// Temporary solution for localStorage not defined error in unit tests
exports.sessionStorageManager = sessionStorageManager;
var storageMock = function storageMock() {
  var storage = {};
  return {
    getItem: function getItem(key) {
      return key in storage ? storage[key] : null;
    },
    setItem: function setItem(key, value) {
      storage[key] = value || '';
    }
  };
};
exports.storageMock = storageMock;