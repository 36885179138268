const types = {
  HR_SYSTEM_DATA_LOAD_STATUS: 'HR_SYSTEM_DATA_LOAD_STATUS',
  HR_SYSTEM_EMPLOYEES_FILE_SELECTED: 'HR_SYSTEM_EMPLOYEES_FILE_SELECTED',
  HR_SYSTEM_SAVE_EMPLOYEE_FILE_DATA: 'HR_SYSTEM_SAVE_EMPLOYEE_FILE_DATA',
  HR_SYSTEM_VALIDATE_EMPLOYEE_FILE_DATA: 'HR_SYSTEM_VALIDATE_EMPLOYEE_FILE_DATA',
  RESET_STORE: 'RESET_STORE',
  HR_SYSTEM_LOAD_DIMENSIONS: 'HR_SYSTEM_LOAD_DIMENSIONS',
  HR_SYSTEM_LOAD_SAVED_DIMENSIONS: 'HR_SYSTEM_LOAD_SAVED_DIMENSIONS',
  HR_SYSTEM_LOAD_COUNTRIES: 'HR_SYSTEM_LOAD_COUNTRIES',
  HR_SYSTEM_COUNTRIES_DEPENDENCIES_PENDING: 'HR_SYSTEM_COUNTRIES_DEPENDENCIES_PENDING',
  HR_SYSTEM_COUNTRIES_DEPENDENCIES_SUCCESS: 'HR_SYSTEM_COUNTRIES_DEPENDENCIES_SUCCESS',
  HR_SYSTEM_COUNTRIES_DEPENDENCIES_ERROR: 'HR_SYSTEM_COUNTRIES_DEPENDENCIES_ERROR',
  HR_SYSTEM_LOAD_SYSTEM_PAYSCALES: 'HR_SYSTEM_LOAD_SYSTEM_PAYSCALES',
  HR_SYSTEM_SET_IS_EDITABLE: 'HR_SYSTEM_SET_IS_EDITABLE',
  ADD_NEW_DIMENSION: 'ADD_NEW_DIMENSION',
  CHANGE_DIMENSION_OPTION: 'CHANGE_DIMENSION_OPTION',
  DELETE_DIMENSION: 'DELETE_DIMENSION',
  HR_SYSTEM_SAVE_CONFIGURATION: 'HR_SYSTEM_SAVE_CONFIGURATION',
  HR_SYSTEM_SAVE_CONFIGURATION_FAIL: 'HR_SYSTEM_SAVE_CONFIGURATION_FAIL',
  REVERT_DIMENSION_CONFIGURATION: 'REVERT_DIMENSION_CONFIGURATION',
  UPDATE_SYSTEM_MODE: 'UPDATE_SYSTEM_MODE',
  HR_SYSTEM_PAYSCALES_DISABLE_TOGGLE: 'HR_SYSTEM_PAYSCALES_DISABLE_TOGGLE',
  HR_SYSTEM_SAVE_SYSTEM_PAYSCALES: 'HR_SYSTEM_SAVE_SYSTEM_PAYSCALES',
  HR_SYSTEM_UPLOAD_DATA_FILE_PENDING: 'HR_SYSTEM_UPLOAD_DATA_FILE_PENDING',
  HR_SYSTEM_UPLOAD_DATA_FILE_SUCCESS: 'HR_SYSTEM_UPLOAD_DATA_FILE_SUCCESS',
  HR_SYSTEM_UPLOAD_DATA_FILE_ERROR: 'HR_SYSTEM_UPLOAD_DATA_FILE_ERROR',
  HR_SYSTEM_LOAD_CONFIG_SUCCESS: 'HR_SYSTEM_LOAD_CONFIG_SUCCESS',
  HR_SYSTEM_LOAD_CONFIG_PENDING: 'HR_SYSTEM_LOAD_CONFIG_PENDING',
  HR_SYSTEM_LOAD_CONFIG_ERROR: 'HR_SYSTEM_LOAD_CONFIG_ERROR',
  HR_SYSTEM_EDIT_TABLE_CELL: 'HR_SYSTEM_EDIT_TABLE_CELL',
};

export default types;
