import { combineReducers } from 'redux';
import * as R from 'ramda';
import instanceActionTypes from '../actions/types';
import initialState from './initialState';
import mergeModellerInstanceWithOutput from './helpers';
import instanceFields from '../components/Profile/components/WithField/reducer';

const instances = (state = initialState, action = {}) => {
  switch (action.type) {
    case instanceActionTypes.ADD_COMPARE: {
      const lastIdx = parseInt(R.last(R.keys(state)), 10);
      return {
        ...state,
        [lastIdx + 1]: state[lastIdx],
      };
    }
    case instanceActionTypes.REMOVE_COMPARE: {
      const newState = R.dissoc(action.payload, state);
      return {
        ...newState,
      };
    }
    case instanceActionTypes.SET_INITIAL_FIELDS:
    case instanceActionTypes.RE_INITIALIZE_FIELDS: {
      const { instanceId, payload: fields } = action;
      return {
        ...state,
        [instanceId]: {
          ...state[instanceId],
          fieldsStructure: R.reduce(
            (acc, key) => ({
              ...acc,
              [key]: {
                fieldType: R.path([key, 'fieldType'], fields),
              },
            }),
            {}
          )(R.keys(fields)),
          instantiated: true,
          areAdvancedFieldsInView: false,
        },
      };
    }
    case instanceActionTypes.LOAD_DATA_SUCCESS: {
      const { instanceId, payload: fields } = action;
      return {
        ...state,
        [instanceId]: {
          ...state[instanceId],
          fieldsStructure: R.reduce(
            (acc, key) => ({
              ...acc,
              [key]: {
                fieldType: R.path([key, 'fieldType'], fields),
              },
            }),
            {}
          )(R.keys(fields)),
          showValid: false,
          areAdvancedFieldsInView: true,
          isLoadingInstance: false,
          err: null,
        },
      };
    }
    case instanceActionTypes.VALIDATE_MODELLER: {
      const {
        payload: { instances: newState },
      } = action;
      return {
        ...newState,
      };
    }
    case instanceActionTypes.VALIDATE_INSTANCE: {
      const { instanceId, payload } = action;
      return {
        ...state,
        [instanceId]: {
          ...state[instanceId],
          ...payload,
        },
      };
    }
    case instanceActionTypes.SELECT_EMPLOYEE: {
      const { instanceId, payload } = action;
      return {
        ...state,
        [instanceId]: {
          ...state[instanceId],
          employeeId: payload,
        },
      };
    }
    case instanceActionTypes.SAVE_DEFAULT_PAYSCALE: {
      const { instanceId, payload } = action;
      return {
        ...state,
        [instanceId]: {
          ...state[instanceId],
          payScale: payload,
        },
      };
    }
    case instanceActionTypes.SAVE_DEFAULT_CURRENCY: {
      const { instanceId, payload } = action;
      return {
        ...state,
        [instanceId]: {
          ...state[instanceId],
          defaultCurrency: payload,
        },
      };
    }
    case instanceActionTypes.LOAD_DATA_PENDING:
    case instanceActionTypes.LOAD_COMPENSATION_GRADE_PROFILES_PENDING: {
      const { instanceId } = action;
      return {
        ...state,
        [instanceId]: {
          ...state[instanceId],
          isLoadingInstance: true,
        },
      };
    }
    case instanceActionTypes.LOAD_DATA_ERROR:
    case instanceActionTypes.LOAD_COMPENSATION_GRADE_PROFILES_ERROR: {
      const { err } = action;
      return {
        ...initialState,
        1: {
          isLoadingInstance: false,
          err,
        },
      };
    }
    case instanceActionTypes.LOAD_COMPENSATION_GRADE_PROFILES_SUCCESS: {
      const { instanceId, payload } = action;
      return {
        ...state,
        [instanceId]: {
          ...state[instanceId],
          showValid: false,
          isLoadingInstance: false,
          countryGradeProfileMappings: payload,
          err: null,
        },
      };
    }
    case instanceActionTypes.LOAD_MODELLER_OUTPUT: {
      return {
        ...state,
        ...mergeModellerInstanceWithOutput(state, action.payload),
      };
    }
    case instanceActionTypes.RESET_STORE:
      return initialState;
    default:
      return state;
  }
};

export default combineReducers({
  instances,
  instanceFields,
});
