import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Icon } from '@trs/components';
import { TextOnly } from 'modules/common/Text';
import * as notificationsActions from '../../actions';
import StyledNotificationsIcon from './NotificationsIcon.style';

class NotificationsIcon extends React.Component {
  componentDidMount() {
    this.props.actions.loadNotifications();
  }

  toggle = () => {
    this.props.actions.toggleNotificationsWidget();
  };

  render() {
    const {
      notifications: { count, isLoading },
    } = this.props;
    const { title } = this.props;
    const hasCount = count > 0;

    return (
      <StyledNotificationsIcon>
        {!isLoading && (
          <Fragment>
            <div className="notification-wrapper-desktop">
              <Icon
                className="notification-icon"
                size={24}
                icon="ion-ios-bell"
                onClick={this.toggle}
                role="button"
                aria-label={TextOnly({ path: 'notifications.NOTIFICATION_TOGGLE' })}
              />
              {hasCount && <span className="notifications-count">{count}</span>}
            </div>
            <div
              className="notification-wrapper-mobile"
              onClick={this.toggle}
              onKeyPress={this.toggle}
              role="presentation"
            >
              {title}
              {hasCount && <span> ({count})</span>}
            </div>
          </Fragment>
        )}
      </StyledNotificationsIcon>
    );
  }
}

NotificationsIcon.propTypes = {
  actions: PropTypes.shape({
    toggleNotificationsWidget: PropTypes.func,
    loadNotifications: PropTypes.func,
  }).isRequired,
  notifications: PropTypes.shape({
    count: PropTypes.number,
    isLoading: PropTypes.bool,
  }).isRequired,
  title: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  notifications: state.notifications,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(notificationsActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationsIcon);
