import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@trs/components';
import { RequestFactory } from 'config/http';
import { processFile } from './helpers';

class DownloadLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDownloading: false,
    };
  }

  downloadGET = (e) => {
    const { action, disabled, beforeHook } = this.props;
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    if (disabled) return;
    beforeHook();

    const url = e.target.getAttribute('href');
    const fileName = e.target.getAttribute('download');
    this.setState({ isDownloading: true });

    RequestFactory('GET', url, {}, {}, 'blob')
      .then((response) => processFile(response, fileName, action))
      .catch((err) => action({ err }))
      .then(() => {
        this.setState({ isDownloading: false });
      });
  };

  render() {
    const { children, disabled } = this.props;
    const { isDownloading } = this.state;

    return (
      <Button {...this.props} disabled={disabled || isDownloading} action={this.downloadGET}>
        {children}
      </Button>
    );
  }
}

DownloadLink.propTypes = {
  children: PropTypes.node.isRequired,
  action: PropTypes.func,
  beforeHook: PropTypes.func,
  disabled: PropTypes.bool,
};

DownloadLink.defaultProps = {
  action: () => {},
  beforeHook: () => {},
  disabled: false,
};

export default DownloadLink;
