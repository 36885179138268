import * as R from 'ramda';
import { debounce } from '@trs/utils';

export const DEFAULT_SORT_CONFIG = {
  by: 'displayName',
  direction: 'asc',
};

export const debounceSearchSubmit = debounce(
  (searchAction, ...params) => searchAction(...params),
  300
);

export const loadSortedData = (searchText, filters, page, sort, callback) => {
  const params = {
    sortAsc: sort.direction === 'asc',
    searchText,
    filters,
    sortBy: sort.by,
    pagingTop: page.resultsPerPage,
    pagingSkip: (page.currentPage - 1) * page.resultsPerPage,
  };

  callback(params);
};

export const mapTableFilterData = (filtersList = [], labels) => {
  let list = filtersList;
  if (!R.isEmpty(filtersList) && !R.isEmpty(labels)) {
    list = R.map(
      (filter) => ({
        ...filter,
        title: R.prop(filter.title, labels) || filter.title,
      }),
      filtersList
    );
  }
  return list;
};

export const sort = (by, sorting, defaultSortConfig) => {
  let sorted = {};

  if (sorting.by !== by) {
    sorted = {
      by,
      direction: 'asc',
    };
  } else if (sorting.direction === 'asc') {
    sorted = {
      by,
      direction: 'desc',
    };
  } else if (sorting.direction === 'desc') {
    sorted = {
      by: R.prop('by', defaultSortConfig) || 'displayName',
      direction: 'asc',
    };
  } else {
    sorted = {
      by,
      direction: 'asc',
    };
  }

  return sorted;
};

export default { debounceSearchSubmit, loadSortedData, mapTableFilterData, sort };
