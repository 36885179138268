import React, { memo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const StyledDisplayName = styled.span`
  a {
    color: inherit;
  }
`;

StyledDisplayName.displayName = 'StyledDisplayName';

const DisplayName = ({ id, name }) => (
  <StyledDisplayName>
    <Link to={`/variables/edit/${id}`}>{name}</Link>
  </StyledDisplayName>
);

DisplayName.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default memo(DisplayName);
