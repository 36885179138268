"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
var StyledTableRow = _styledComponents["default"].tr.withConfig({
  displayName: "TableRowstyle__StyledTableRow",
  componentId: "sc-1mgnkrg-0"
})(["background-color:#fff;td:last-child .single-list-item.icon-label{opacity:0;}&:hover{td:last-child .single-list-item.icon-label{opacity:1;}}"]);
StyledTableRow.displayName = 'StyledTableRow';
var _default = StyledTableRow;
exports["default"] = _default;