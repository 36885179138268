import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { Paragraph, Button } from '@trs/components';
import NotificationHeader from 'modules/notifications/components/header';
import Text, { TextOnly } from 'modules/common/Text';
import StyledDataLoading from 'modules/notifications/components/item/DataLoading/DataLoading.style';

const RoleEligibilityNotification = ({ item, markNotification }) => {
  const { id, createdDate, content } = item;
  const isStatusSuccess = R.path(['status'], content) === 'success';
  return (
    <StyledDataLoading>
      <Fragment>
        <NotificationHeader
          createdDate={createdDate}
          title={TextOnly({
            path: 'notifications.NOTIFICATION_ROLE_ELIGIBILITY_TITLE',
          })}
          data-testid="notification-header"
        />
        <Paragraph className="notification-content">
          <Text
            path={
              isStatusSuccess
                ? 'notifications.NOTIFICATION_ROLE_ELIGIBILITY_CONTENT_SUCCESS'
                : 'notifications.NOTIFICATION_ROLE_ELIGIBILITY_CONTENT_FAILED'
            }
            transform={(label = '') => label.replace('%ROLE_NAME%', content.roleName)}
          />
        </Paragraph>
        {!item.isRead && (
          <Button type="link" className="notification-link" action={() => markNotification(id)}>
            <Text path="notifications.MARK_AS_READ" />
          </Button>
        )}
      </Fragment>
    </StyledDataLoading>
  );
};

RoleEligibilityNotification.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    typeId: PropTypes.string.isRequired,
    isRead: PropTypes.bool.isRequired,
    content: PropTypes.shape({
      roleName: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
    }).isRequired,
    createdDate: PropTypes.string.isRequired,
  }).isRequired,
  markNotification: PropTypes.func.isRequired,
};

export default RoleEligibilityNotification;
