import * as R from 'ramda';
import { decimalTransform } from '@trs/utils';
import { propNames } from 'modules/modeller/live/api/baseValues';
import { setPayPointValue } from 'modules/modeller/util';
import { differenceInMonths } from 'date-fns';

const {
  country,
  countryCode,
  compensationGrade,
  compensationGradeProfile,
  annualSalary,
  minAnnualSalary,
  midAnnualSalary,
  maxAnnualSalary,
  fullTimeEquivalent,
  payPoint,
  assignmentStart,
  assignmentEnd,
  assignmentDurationInMonths,
  compensationPlanAmount,
  totalAnnualBasePay,
  currency,
} = propNames;

const transformFloatValue = (value) => (value ? parseFloat(value) : 0);

const setBaseSalaryValue = (value, midAnnualSalaryValue) => {
  const floatValue = transformFloatValue(value);
  return decimalTransform((floatValue / 100) * midAnnualSalaryValue);
};

const handleFTEChange = (value, payScaleValue) => {
  const floatValue = transformFloatValue(value);
  return decimalTransform(payScaleValue * (floatValue / 100));
};

const countryCorrelation = (value, instance) => ({
  [currency]: {
    ...instance[currency],
    value: R.prop('defaultCurrency', value),
  },
  [countryCode]: {
    ...instance[countryCode],
    value: R.prop('code', value),
  },
  [compensationGrade]: {
    ...instance[compensationGrade],
    value: null,
  },
  [compensationGradeProfile]: {
    ...instance[compensationGradeProfile],
    value: null,
  },
});

const annualSalaryFieldsCorrelation = (value, midAnnualSalaryValue, instance) => {
  const floatValue = transformFloatValue(value);
  return {
    [payPoint]: {
      ...instance[payPoint],
      value: setPayPointValue(floatValue, midAnnualSalaryValue),
    },
    [compensationPlanAmount]: {
      ...instance[compensationPlanAmount],
      value: decimalTransform(floatValue),
    },
    [totalAnnualBasePay]: {
      ...instance[totalAnnualBasePay],
      value: decimalTransform(floatValue),
    },
  };
};

const fteFieldsCorrelation = (value, annualSalaryValue, payScale, instance) => {
  const floatValue = transformFloatValue(value);
  return {
    [annualSalary]: {
      ...instance[annualSalary],
      value: annualSalaryValue,
    },
    [minAnnualSalary]: {
      ...instance[minAnnualSalary],
      value: handleFTEChange(floatValue, payScale[minAnnualSalary]),
    },
    [midAnnualSalary]: {
      ...instance[midAnnualSalary],
      value: handleFTEChange(floatValue, payScale[midAnnualSalary]),
    },
    [maxAnnualSalary]: {
      ...instance[maxAnnualSalary],
      value: handleFTEChange(floatValue, payScale[maxAnnualSalary]),
    },
    [compensationPlanAmount]: {
      ...instance[compensationPlanAmount],
      value: annualSalaryValue,
    },
    [totalAnnualBasePay]: {
      ...instance[totalAnnualBasePay],
      value: annualSalaryValue,
    },
  };
};

const payPointFieldsCorrelation = (annualSalaryValue, instance) => ({
  [annualSalary]: {
    ...instance[annualSalary],
    value: annualSalaryValue,
  },
  [compensationPlanAmount]: {
    ...instance[compensationPlanAmount],
    value: annualSalaryValue,
  },
  [totalAnnualBasePay]: {
    ...instance[totalAnnualBasePay],
    value: annualSalaryValue,
  },
});

const getCorrelatedFields = (payload, instance) => {
  const { propertyName, value, payScale } = payload;
  const midAnnualSalaryValue =
    parseInt(R.path([midAnnualSalary, 'value'], instance), 10) ||
    parseInt(R.path([annualSalary, 'value'], instance), 10);

  switch (propertyName) {
    case country: {
      return countryCorrelation(value, instance);
    }
    case compensationGrade: {
      return {
        [compensationGradeProfile]: {
          ...instance[compensationGradeProfile],
          value: '',
        },
      };
    }
    case annualSalary: {
      if (!midAnnualSalaryValue) return {};
      return annualSalaryFieldsCorrelation(value, midAnnualSalaryValue, instance);
    }
    case fullTimeEquivalent: {
      const annualSalaryValue = setBaseSalaryValue(
        R.path([payPoint, 'value'], instance),
        handleFTEChange(value, payScale[midAnnualSalary])
      );
      return fteFieldsCorrelation(value, annualSalaryValue, payScale, instance);
    }
    case payPoint: {
      if (!midAnnualSalaryValue) return {};
      const annualSalaryValue = setBaseSalaryValue(value, midAnnualSalaryValue);
      return payPointFieldsCorrelation(annualSalaryValue, instance);
    }
    case assignmentStart:
    case assignmentEnd: {
      let valueEndDate = '';
      let valueStartDate = '';
      if (payload.propertyName === assignmentEnd) {
        valueEndDate = payload.value;
      } else {
        valueEndDate = instance[assignmentEnd].value;
      }

      if (payload.propertyName === assignmentStart) {
        valueStartDate = payload.value;
      } else {
        valueStartDate = instance[assignmentStart].value;
      }

      if (!!valueStartDate && !!valueEndDate) {
        return {
          [assignmentDurationInMonths]: {
            ...instance[assignmentDurationInMonths],
            value: differenceInMonths(new Date(valueEndDate), new Date(valueStartDate)) + 1,
          },
        };
      }
      return {};
    }
    default:
      return {};
  }
};

export default getCorrelatedFields;
