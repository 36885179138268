import { handleActions } from 'redux-actions';
import * as R from 'ramda';
import {
  fetchVersionsPending,
  fetchVersionsSuccess,
  fetchVersionsError,
  versioningStoreReset,
} from '../actions';

export const initialState = {
  versions: [],
  fetchingVersions: false,
};

const reducer = handleActions(
  new Map([
    [
      fetchVersionsPending,
      () => ({
        fetchingVersions: true,
      }),
    ],
    [
      fetchVersionsError,
      () => ({
        fetchingVersions: false,
      }),
    ],
    [
      fetchVersionsSuccess,
      (_, action) => ({
        fetchingVersions: false,
        versions: R.path(['payload', 'versions'], action),
      }),
    ],
    [versioningStoreReset, () => R.clone(initialState)],
  ]),
  initialState
);

export default reducer;
