export const EMPLOYEE_GET_REWARDS_CATEGORIES = 'EMPLOYEE_GET_REWARDS_CATEGORIES';
export const EMPLOYEE_GET_CATEGORY_DRILLDOWN = 'EMPLOYEE_GET_CATEGORY_DRILLDOWN';

export const EMPLOYEE_CLEAR_REWARD_DETAILS = 'EMPLOYEE_CLEAR_CATEGORY_DETAILS';
export const EMPLOYEE_GET_REWARD_DETAILS = 'EMPLOYEE_GET_REWARD_DETAILS';
export const EMPLOYEE_GET_SHARES = 'EMPLOYEE_GET_SHARES';
export const EMPLOYEE_GET_SHARES_SUMMARY = 'EMPLOYEE_GET_SHARES_SUMMARY';
export const EMPLOYEE_GET_LTI_PLANS = 'EMPLOYEE_GET_LTI_PLANS';
export const EMPLOYEE_RESET_LTI_PLANS = 'EMPLOYEE_RESET_LTI_PLANS';
export const REDIRECT_TO_ROOT = 'REDIRECT_TO_ROOT';
export const STORE_RESET = 'STORE_RESET';
export const EMPLOYEE_GET_SHARES_SUMMARY_ERROR = 'EMPLOYEE_GET_SHARES_SUMMARY_ERROR';
export const EMPLOYEE_RESET_STATEMENT = 'EMPLOYEE_RESET_STATEMENT';

export const GET_REWARD_FEEDBACK_PENDING = 'GET_REWARD_FEEDBACK_PENDING';
export const GET_REWARD_FEEDBACK_SUCCESS = 'GET_REWARD_FEEDBACK_SUCCESS';
export const GET_REWARD_FEEDBACK_ERROR = 'GET_REWARD_FEEDBACK_ERROR';

export const CHANGE_REWARD_FEEDBACK_TEXT = 'CHANGE_REWARD_FEEDBACK_TEXT';
export const CHANGE_REWARD_FEEDBACK_SCORE = 'CHANGE_REWARD_FEEDBACK_SCORE';

export const CATEGORIES_GET_FAIL = 'CATEGORIES_GET_FAIL';

export const SEND_FEEDBACK_REWARD_PENDING = 'SEND_FEEDBACK_REWARD_PENDING';
export const SEND_FEEDBACK_REWARD_SUCCESS = 'SEND_FEEDBACK_REWARD_SUCCESS';
export const SEND_FEEDBACK_REWARD_ERROR = 'SEND_FEEDBACK_REWARD_ERROR';

export const RESET_TO_INITIAL_FEEDBACK = 'RESET_TO_INITIAL_FEEDBACK';

export const EMPLOYEE_GET_PERSONAL_DETAILS_PENDING = 'EMPLOYEE_GET_PERSONAL_DETAILS_PENDING';
export const EMPLOYEE_GET_LTI_PLANS_PENDING = 'EMPLOYEE_GET_LTI_PLANS_PENDING';
export const EMPLOYEE_GET_PERSONAL_DETAILS = 'EMPLOYEE_GET_PERSONAL_DETAILS';
