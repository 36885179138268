"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
var _utils = require("@trs/utils");
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
var StyledColoredCard = _styledComponents["default"].div.withConfig({
  displayName: "ColoredCardstyle__StyledColoredCard",
  componentId: "sc-t67clu-0"
})(["", ";"], function (props) {
  var _props$theme = props.theme,
    color = _props$theme.palette,
    spacing = _props$theme.spacing,
    typography = _props$theme.typography,
    _props$theme$extra = _props$theme.extra,
    boxShadow = _props$theme$extra.boxShadow,
    genericBorderRadius = _props$theme$extra.genericBorderRadius;
  return (0, _styledComponents.css)(["background:", ";border-radius:", ";box-shadow:", ";color:", ";font-weight:bold;padding:", " ", " ", ";text-transform:uppercase;", " ", " .card-title{padding-bottom:", ";font-size:", ";", " ", ";}.card-value{font-size:", ";", " ", ";}"], color[props.color] || props.color, genericBorderRadius, boxShadow, color.basic.white, spacing.small, spacing.small, spacing.basePlus, _utils.media.tablet(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n        padding: ", ";\n        text-align: center;\n      "])), spacing.tiny), _utils.media.desktop(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n        padding: ", " ", " ", ";\n      "])), spacing.small, spacing.small, spacing.xLarge), spacing.base, typography.size.h4, _utils.media.tablet(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n          display: inline-block;\n          padding-bottom: 0px;\n          margin-right: ", ";\n        "])), spacing.large), _utils.media.desktop(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n          padding-bottom: ", ";\n          margin-right: 0px;\n        "])), spacing.base), typography.size.h3, _utils.media.tablet(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n          display: inline-block;\n          padding-bottom: 0px;\n          font-size: ", ";\n        "])), typography.size.h3), _utils.media.desktop(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n          display: block;\n          font-size: ", ";\n        "])), typography.size.h1));
});
StyledColoredCard.displayName = 'StyledColoredCard';
var _default = StyledColoredCard;
exports["default"] = _default;