import * as R from 'ramda';
import {
  isBlank,
  decimalTransform,
  sortListDescendingByProp,
  sortListAscendingByProp,
} from '@trs/utils';
import APP_CONFIG from '@trs/config';
import { CATEGORY_SPLIT_INDEX, REWARD_CATEGORIES_TYPES } from '../constants';

const { TEMPLATE_TYPES } = APP_CONFIG;

export const getNameFromCategoryId = (categoryId, property = 'displayName') =>
  R.compose(R.prop(property), R.find(R.propEq('id', String(categoryId))));

export const groupByCategoryLevel = (list = [], level = '', categories) =>
  R.groupBy(
    (item) =>
      categories
        ? getNameFromCategoryId(R.path(['category', level], item))(categories)
        : R.path(['category', String(level)], item),
    list
  );

const notIncludedInTotal = (item) => !R.prop('includeInTheOverallTotal', item);

const percentageTemplateReward = (item) => R.prop('template', item) === TEMPLATE_TYPES.percentage;

const textOnlyReward = (item) => !R.isNil(R.prop('textValue', item));

const getRoundedSum = (values) =>
  R.reduce(
    (acc, item) => {
      const value = acc + Number(decimalTransform(item));
      return value;
    },
    0,
    values
  );

export const getSumPerStatement = (statementIndex) =>
  R.compose(
    getRoundedSum,
    R.reject(R.isNil),
    R.pluck(statementIndex),
    R.pluck('value'),
    R.reject(notIncludedInTotal),
    R.reject(percentageTemplateReward),
    R.reject(textOnlyReward),
    R.pluck(0),
    R.values
  );

const sortAgregatedByIndex = R.sortBy(R.prop('orderIndex'));

const sortAgregatedByName = R.sortBy(R.prop('name'));

export const groupAvailableOptions = (availableOptions, categories) => {
  const withOrderIndex = R.map(
    (item) => ({
      ...item,
      orderIndex: getNameFromCategoryId(
        R.path(['category', 'level1'], item),
        'orderIndex'
      )(categories),
    }),
    availableOptions
  );

  const sortedWithOrder = sortAgregatedByIndex(withOrderIndex);
  const groupedByCategory = groupByCategoryLevel(sortedWithOrder, 'level1', categories);

  return R.map(
    (nestedList) => groupByCategoryLevel(sortAgregatedByName(nestedList), 'level3'),
    groupedByCategory
  );
};

const splitAtDefined = R.splitWhen(R.compose(R.lt(CATEGORY_SPLIT_INDEX), R.prop('orderIndex')));

const getValueFromRewardGroupId = (propName, rewardGroupId, includeInTheOverallTotal) =>
  R.compose(
    R.prop(propName),
    R.find(
      R.allPass([
        R.propEq('rewardGroupId', rewardGroupId),
        R.propEq('includeInTheOverallTotal', includeInTheOverallTotal),
      ])
    )
  );

const processGrouped = R.compose(R.reject(isBlank), splitAtDefined, sortAgregatedByIndex, R.values);

const getSubtotal = R.map((item) => {
  const totalValues = R.pluck('total', item);
  return [getRoundedSum(R.pluck(0, totalValues)), getRoundedSum(R.pluck(1, totalValues))];
});

const getTotal = (subtotal) => [
  getRoundedSum(R.pluck(0, subtotal)),
  getRoundedSum(R.pluck(1, subtotal)),
];

export const sortDescByValue = R.map(
  R.sortWith([R.descend((item) => R.path(['value', 1], item) || R.path(['value', 0], item))])
);

const concatStatements = (acc, item, current, future) => {
  const data = acc.concat({
    ...item,
    value: !R.isNil(item.textValue)
      ? [
          getValueFromRewardGroupId(
            'textValue',
            item.rewardGroupId,
            item.includeInTheOverallTotal
          )(current),
          getValueFromRewardGroupId(
            'textValue',
            item.rewardGroupId,
            item.includeInTheOverallTotal
          )(future),
        ]
      : [
          getValueFromRewardGroupId(
            'value',
            item.rewardGroupId,
            item.includeInTheOverallTotal
          )(current),
          getValueFromRewardGroupId(
            'value',
            item.rewardGroupId,
            item.includeInTheOverallTotal
          )(future),
        ],
  });
  return data;
};

const mergeCurrentWithFuture = (current = [], future) =>
  R.reduce(
    (acc, item) => concatStatements(acc, item, current, future),
    [],
    [...current, ...future]
  );

export const getTemplateOrderIndex = (statement) => {
  if (statement.template === TEMPLATE_TYPES.percentage) {
    return REWARD_CATEGORIES_TYPES.PERCENTAGE;
  }

  if (!statement.isAmount && !R.isNil(statement.textValue)) {
    return REWARD_CATEGORIES_TYPES.TEXT_ONLY;
  }

  return REWARD_CATEGORIES_TYPES.DEFAULT;
};

export const getStatementValue = (statement) => {
  const currentStatement = statement.value[0];
  const newStatement = statement.value[1];

  return isBlank(newStatement) ? currentStatement : newStatement;
};

const getSortedRewardsItems = (sortedRewards) => {
  const sortedItems = [];
  R.keys(sortedRewards).forEach((key) => {
    sortedRewards[key].forEach((reward) => {
      sortedItems.push(reward);
    });
  });
  return sortedItems;
};

export const sortItemsByTemplateOrderIndex = (rewards, templateOrderIndex) => {
  if (
    R.equals(templateOrderIndex, REWARD_CATEGORIES_TYPES.DEFAULT) ||
    R.equals(templateOrderIndex, REWARD_CATEGORIES_TYPES.PERCENTAGE)
  ) {
    return sortListDescendingByProp('value', rewards);
  }

  return sortListAscendingByProp('rewardName', rewards);
};

export const sortItemsWithinRewards = (rewardCategories) => {
  return R.mapObjIndexed((rewards, key) => {
    return sortItemsByTemplateOrderIndex(rewards, parseInt(key, 10));
  })(rewardCategories);
};

export const extractRewardsWithStatementValues = (rewardGroups) => {
  const cleanupRewardsGroups = {
    newStatementRewards: {},
    currentStatementRewards: {},
  };

  R.keys(rewardGroups).forEach((key) => {
    const filteredNewValuesCategories = R.filter(
      ({ value }) => !isBlank(value[1]),
      rewardGroups[key]
    );

    const filteredCurrentValuesCategories = R.filter(
      ({ value }) => isBlank(value[1]) && !isBlank(value[0]),
      rewardGroups[key]
    );

    if (!isBlank(filteredNewValuesCategories)) {
      cleanupRewardsGroups.newStatementRewards = {
        ...cleanupRewardsGroups.newStatementRewards,
        [key]: filteredNewValuesCategories,
      };
    }

    if (!isBlank(filteredCurrentValuesCategories)) {
      cleanupRewardsGroups.currentStatementRewards = {
        ...cleanupRewardsGroups.currentStatementRewards,
        [key]: filteredCurrentValuesCategories,
      };
    }
  });

  return cleanupRewardsGroups;
};

export const createAndGroupRewardsByTemplatedOrderIndex = (rewardGroups) => {
  return R.keys(rewardGroups).reduce((acc, groupId) => {
    const statements = rewardGroups[groupId];
    statements.forEach((statement) => {
      const templateOrderIndex = getTemplateOrderIndex(statement);

      (acc[templateOrderIndex] = acc[templateOrderIndex] || []).push({
        groupId,
        templateOrderIndex,
        templateType: statement.template,
        rewardName: statement.rewardName || null,
        value: getStatementValue(statement),
        originalObject: rewardGroups[groupId],
      });
    });

    return acc;
  }, {});
};

export const extractAndSortRewards = (rewardGroups) => {
  const templatedOrderIndexRewards = createAndGroupRewardsByTemplatedOrderIndex(rewardGroups);
  const sortedTemplatedRewards = sortItemsWithinRewards(templatedOrderIndexRewards);

  return getSortedRewardsItems(sortedTemplatedRewards);
};

export const composeSortedRewards = (sortedRewards) => {
  const sortedGroupIds = [];
  const sortedStatements = [];

  sortedRewards.forEach(({ groupId, originalObject }) => {
    if (!sortedGroupIds.includes(groupId)) {
      sortedGroupIds.push(groupId);
    }

    const index = sortedStatements.findIndex((elem) => {
      return elem.findIndex((el) => el.rewardGroupId === groupId) !== -1;
    });

    if (index !== -1) {
      sortedStatements[index].push(originalObject[0]);
    } else {
      sortedStatements.push(originalObject);
    }
  });

  const sortedRewardsArraysToObject = R.zipObj(sortedGroupIds, sortedStatements);

  return sortedRewardsArraysToObject;
};

const sortRewards = (rewardGroups) => {
  let sortedNewStatementRewards = [];
  let sortedCurrentStatementRewards = [];

  const { newStatementRewards, currentStatementRewards } =
    extractRewardsWithStatementValues(rewardGroups);

  if (!isBlank(newStatementRewards)) {
    sortedNewStatementRewards = extractAndSortRewards(newStatementRewards);
  }

  if (!isBlank(currentStatementRewards)) {
    sortedCurrentStatementRewards = extractAndSortRewards(currentStatementRewards);
  }

  const allPlainSortedRewards = R.concat(sortedNewStatementRewards, sortedCurrentStatementRewards);

  const sortedRewards = composeSortedRewards(allPlainSortedRewards);

  return sortedRewards;
};

export const getGroupedCalculatedStatement = ({ current, future, categories, currencies }) => {
  const data = mergeCurrentWithFuture(current, future);
  const groupedByCategoryId = R.groupBy((reward) => String(reward.categoryId), data);
  const orderedGroups = sortDescByValue(groupedByCategoryId);

  const namedGroup = R.mapObjIndexed((item, key) => {
    const orderedItemByRewardId = R.sortBy(R.prop('rewardGroupId'), item);

    const groupedByRewardGroupId = R.groupBy(R.prop('rewardGroupId'), orderedItemByRewardId);

    const sortedRewards = sortRewards(groupedByRewardGroupId);

    const sortedRewardsValues = R.reduce(
      (acc, reward) => {
        reward.forEach((elem) => {
          acc.push(elem);
        });
        return acc;
      },
      [],
      R.values(sortedRewards)
    );

    const shouldGroup = (a, b) =>
      R.prop('includeInTheOverallTotal', a) === R.prop('includeInTheOverallTotal', b) &&
      R.prop('categoryId', a) === R.prop('categoryId', b) &&
      R.prop('rewardName', a) === R.prop('rewardName', b);

    const groupedByNameAndTotal = R.groupWith(shouldGroup, sortedRewardsValues);

    return {
      orderIndex: getNameFromCategoryId(key, 'orderIndex')(categories),
      displayName: getNameFromCategoryId(key)(categories),
      categoryId: key,
      rewards: sortedRewards,
      total: [
        getSumPerStatement(0)(groupedByNameAndTotal),
        getSumPerStatement(1)(groupedByNameAndTotal),
      ],
    };
  }, orderedGroups);

  const processedGrouped = processGrouped(namedGroup);

  const subtotal = getSubtotal(processedGrouped);

  const overallTotal = getTotal(subtotal);

  return { groups: processedGrouped, overallTotal, subtotal: subtotal[0], currencies };
};

export const getSelectedRewards = (payload) =>
  R.reduce(
    (acc, item) => {
      if (item.isSelected) {
        acc[item.id] = { ...item };
      }
      return acc;
    },
    {},
    payload
  );

export default {
  groupAvailableOptions,
  getNameFromCategoryId,
  getSelectedRewards,
  groupByCategoryLevel,
};
