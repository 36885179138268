import styled, { css } from 'styled-components';

const StyledEmployeeSatisfactionCol = styled.span`
  ${({ theme }) => {
    const { spacing, extra } = theme;
    return css`
      display: flex;
      align-items: center;

      .feedback-rating-icon {
        display: flex;
        align-items: center;
        ${extra.feedbackStatus.feedbackIcons()}
      }

      .score {
        width: 100%;
        padding-left: ${spacing.small};
      }
    `;
  }};
`;

StyledEmployeeSatisfactionCol.displayName = 'StyledEmployeeSatisfactionCol';
export default StyledEmployeeSatisfactionCol;
