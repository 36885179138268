const routePaths = {
  HOME: '/',
  RM_DASHBOARD: '/home',
  PLAN: '/plan',
  WORKSPACE: '/workspace',
  REPORT: '/report',
  USERS: '/users',
  SETTINGS: '/settings',
  VARIABLES: '/variables',
  ARTICLES: '/articles',
  REWARDS: '/rewards',
  EXCEPTIONS: '/exceptions',
  MODEL: '/model',
  EMPLOYEE_STATEMENT: '/employee-statement',
  EMPLOYEE_STATEMENT_VIEW: '/employee-statement/view',
  REWARDS_IMPACT: '/reward-impact',
  REWARDS_VIEW_IMPACT_VIEW: '/rewards/view/:rewardId/impacts/view/:impactId',
  REWARDS_EDIT_IMPACTS_VIEW: '/rewards/edit/:rewardId/impacts/view/:impactId',
  BENEFITS_ENVELOPE: '/benefits-envelope',
  BENEFITS_ENVELOPE_AUDIT: '/benefits-envelope-audit',
  FLEXIBLE_REWARDS: '/flexible-rewards',
  FOREIGN_EXCHANGE: '/foreign-exchange',
  FIXED_COSTS: '/fixed-costs',
  LTI_PLANS: '/lti-plans',
  LOGIN: '/login',
  LOGOUT: '/logout',
  RENEW: '/renew',
  CALLBACK: '/callback',
  NO_MODULE: '/no-modules',
  UNAUTHORIZED: '/unauthorized',
  ACCESS_DENIED: '/access-denied',
  ERROR: '/error',
  ERROR_404: '/404',
};

export default routePaths;
