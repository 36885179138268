"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.VIEW_TYPE = exports.ALIGNMENT_MAP = void 0;
var VIEW_TYPE = {
  "default": 'default',
  pill: 'pill',
  splash: 'splash',
  classic: 'classic'
};
exports.VIEW_TYPE = VIEW_TYPE;
var ALIGNMENT_MAP = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end'
};
exports.ALIGNMENT_MAP = ALIGNMENT_MAP;
var _default = {
  VIEW_TYPE: VIEW_TYPE
};
exports["default"] = _default;