import * as R from 'ramda';
import actionTypes from '../actions/types';
import { getAllCountries, getSelectedCountries } from './helpers';

export const initialState = {
  countries: [],
  currencies: [],
  isFetchingCurrencies: false,
  selectedCountries: [],
  isFetchingCountries: false,
  showOnlySelectedCheck: false,
  sorting: {
    by: 'name',
    direction: 'asc',
  },
};

export default function authenticationReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.FETCH_COUNTRIES_PENDING: {
      return {
        ...state,
        isFetchingCountries: true,
      };
    }
    case actionTypes.FETCH_COUNTRIES_SUCCESS: {
      return {
        ...state,
        isFetchingCountries: false,
        countries: getAllCountries(action.payload),
        selectedCountries: getSelectedCountries(action.payload),
      };
    }
    case actionTypes.COUNTRIES_SHOW_ONLY_SELECTED: {
      return {
        ...state,
        showOnlySelectedCheck: action.value,
      };
    }
    case actionTypes.FETCH_CURRENCIES_PENDING: {
      return {
        ...state,
        isFetchingCurrencies: true,
      };
    }
    case actionTypes.FETCH_CURRENCIES_SUCCESS: {
      const data = action.payload;
      const currencies = [];

      data.map(item =>
        currencies.push({
          value: item.code,
          label: item.code,
        })
      );

      return {
        ...state,
        currencies,
        isFetchingCurrencies: false,
      };
    }
    case actionTypes.COUNTRIES_SORT_CHANGE: {
      const { countries } = state;
      const { options } = action;
      let sortedCountries = [];

      if (options.direction === 'asc') {
        sortedCountries = R.sort(R.ascend(R.prop(options.by)), countries);
      } else {
        sortedCountries = R.sort(R.descend(R.prop(options.by)), countries);
      }

      return {
        ...state,
        sorting: action.options,
        countries: sortedCountries,
      };
    }

    default: {
      return state;
    }
  }
}
