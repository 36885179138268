"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getValueFromArray = exports.getSimpleRuleValues = exports.getSelectValue = exports.getBooleanOptions = exports.emptySimpleRule = exports.defaultRuleValueProps = exports.defaultRuleOperatorsProps = exports.defaultRule = exports["default"] = exports.computeInputType = exports.addUidsToRules = void 0;
var R = _interopRequireWildcard(require("ramda"));
var _uuid = require("uuid");
var _utils = require("@trs/utils");
var _Searchable = _interopRequireDefault(require("../formula/Searchable"));
var _Input = _interopRequireDefault(require("../input/Input"));
var _Datepicker = _interopRequireDefault(require("../datepicker/Datepicker"));
var _select = _interopRequireDefault(require("../select"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
var getBooleanOptions = R.memoizeWith(R.identity, function (labels) {
  return [{
    value: true,
    label: labels.ELIGIBILITY_YES
  }, {
    value: false,
    label: labels.ELIGIBILITY_NO
  }];
});
exports.getBooleanOptions = getBooleanOptions;
var getSelectValue = function getSelectValue(value, isMultiple) {
  if (isMultiple && value instanceof Array) {
    return value.map(function (item) {
      return {
        label: item,
        value: item
      };
    });
  }
  if (!isMultiple) {
    return value instanceof Array ? {
      value: R.prop(0, value),
      label: R.prop(0, value)
    } || null : value;
  }
  return [];
};
exports.getSelectValue = getSelectValue;
var getValueFromArray = function getValueFromArray(value) {
  return value instanceof Array ? R.prop(0, value) || '' : '';
};
exports.getValueFromArray = getValueFromArray;
var emptySimpleRule = {
  variable: '',
  operator: '',
  values: []
};
exports.emptySimpleRule = emptySimpleRule;
var addUidsToRules = function addUidsToRules(rules) {
  var newRules = rules;
  if (newRules.eligibilityRows) {
    newRules.uid = (0, _uuid.v4)();
    newRules.eligibilityRows.map(function (item) {
      return addUidsToRules(item);
    });
  } else {
    newRules.uid = (0, _uuid.v4)();
  }
  return newRules;
};
exports.addUidsToRules = addUidsToRules;
var getSimpleRuleValues = function getSimpleRuleValues(variable, operator, value, variableOperands) {
  var rule = R.clone(emptySimpleRule);
  rule.variable = R.prop('value', variable) || variable || '';
  rule.operator = R.prop('value', operator) || operator || '';
  rule.variableOperands = variableOperands;
  rule.values = R.prop('value', value) || (value instanceof Date ? value.toString() : value) || [];
  return rule;
};
exports.getSimpleRuleValues = getSimpleRuleValues;
var defaultRule = {
  variable: null,
  operator: null,
  values: []
};
exports.defaultRule = defaultRule;
var defaultRuleValueProps = {
  disabled: true,
  type: 'string',
  valuesUrl: ''
};
exports.defaultRuleValueProps = defaultRuleValueProps;
var defaultRuleOperatorsProps = {
  disabled: true
};
exports.defaultRuleOperatorsProps = defaultRuleOperatorsProps;
var getOperandData = function getOperandData(item, variableOperands) {
  var data = R.find(R.propEq('value', R.prop(0, item) || ''), variableOperands) || {};
  return data;
};

// update function and use HOC
var computeInputType = function computeInputType(_ref) {
  var _ref$variableOperands = _ref.variableOperands,
    variableOperands = _ref$variableOperands === void 0 ? [] : _ref$variableOperands,
    errors = _ref.errors,
    onChange = _ref.onChange,
    type = _ref.type,
    value = _ref.value,
    lookUpValues = _ref.lookUpValues,
    allowsMultipleSelect = _ref.allowsMultipleSelect,
    id = _ref.id,
    labels = _ref.labels,
    operatorValue = _ref.operatorValue;
  var ValueInput;
  var inputProps;
  var handleOnChange = onChange;
  var operator = R.prop('value', operatorValue);
  if (operator === 'inlist' || operator === 'notinlist') {
    var variableOperand = getOperandData(value, variableOperands);
    ValueInput = _Searchable["default"];
    inputProps = _objectSpread({
      allowNumeric: false,
      error: errors,
      isFocused: true,
      tooltipPosition: 'top',
      uniqueKey: id,
      formulaChange: function formulaChange(item) {
        return onChange([item.value], [item]);
      }
    }, variableOperand);
    return {
      ValueInput: ValueInput,
      inputProps: inputProps,
      handleOnChange: handleOnChange
    };
  }
  if (!lookUpValues || lookUpValues.length === 0) {
    switch (type) {
      case 'boolean':
        ValueInput = _select["default"];
        inputProps = {
          searchable: false,
          options: getBooleanOptions(labels),
          multi: false,
          value: R.prop(0, value)
        };
        handleOnChange = function handleOnChange(option) {
          onChange([option.value]);
        };
        break;
      case 'double':
      case 'integer':
      case 'decimal':
        if (operator === 'in' || operator === 'notin') {
          ValueInput = _select["default"];
          inputProps = {
            separator: ';',
            type: 'number',
            withLocaleFormatNumber: true,
            transformer: function transformer(item) {
              if (/^([0-9]*\.?[0-9]*|[0-9]*\.?|\.?[0-9]*)$/.test(item)) {
                return item;
              }
              return '';
            },
            options: lookUpValues,
            multi: true,
            createable: true,
            value: getSelectValue(value, allowsMultipleSelect),
            placeholder: labels.ELIGIBILITY_CREATEABLE_SELECT_PLACEHOLDER
          };
          handleOnChange = function handleOnChange(options) {
            var values = options.map(function (val) {
              return val.value;
            });
            onChange(values);
          };
        } else {
          ValueInput = _Input["default"];
          inputProps = {
            fullWidth: true,
            className: 'input-number',
            value: getValueFromArray(value) || undefined,
            type: 'number',
            withLocaleFormatNumber: true
          };
          handleOnChange = function handleOnChange(event) {
            onChange([event.target.value]);
          };
        }
        break;
      case 'datetime':
        ValueInput = _Datepicker["default"];
        inputProps = {
          fullWidth: true,
          className: 'input-date',
          value: getValueFromArray(value)
        };
        handleOnChange = function handleOnChange(date) {
          onChange([(0, _utils.offsetTimezone)(date)]);
        };
        break;
      case 'string':
      default:
        if (operator === 'in' || operator === 'notin') {
          ValueInput = _select["default"];
          inputProps = {
            separator: ';',
            searchable: false,
            options: lookUpValues,
            multi: true,
            createable: true,
            value: getSelectValue(value, allowsMultipleSelect),
            placeholder: labels.ELIGIBILITY_CREATEABLE_SELECT_PLACEHOLDER
          };
          handleOnChange = function handleOnChange(options) {
            var values = options.map(function (val) {
              return val.value;
            });
            onChange(values);
          };
        } else {
          ValueInput = _Input["default"];
          inputProps = {
            multiline: true,
            fullWidth: true,
            className: 'input-text',
            value: getValueFromArray(value),
            type: 'text'
          };
          handleOnChange = function handleOnChange(event) {
            onChange([event.target.value]);
          };
        }
        break;
    }
  } else {
    ValueInput = _select["default"];
    inputProps = {
      searchable: true,
      options: lookUpValues,
      multi: allowsMultipleSelect,
      value: getSelectValue(value, allowsMultipleSelect),
      placeholder: labels.ELIGIBILITY_VALUE_SELECT_PLACEHOLDER || 'Search value'
    };
    if (!allowsMultipleSelect) {
      handleOnChange = function handleOnChange(option) {
        onChange([option.value]);
      };
    } else {
      handleOnChange = function handleOnChange(options) {
        var values = options.map(function (val) {
          return val.value;
        });
        onChange(values);
      };
    }
  }
  return {
    ValueInput: ValueInput,
    inputProps: inputProps,
    handleOnChange: handleOnChange
  };
};
exports.computeInputType = computeInputType;
var _default = {
  getBooleanOptions: getBooleanOptions,
  getSelectValue: getSelectValue,
  getValueFromArray: getValueFromArray,
  emptySimpleRule: emptySimpleRule,
  addUidsToRules: addUidsToRules,
  getSimpleRuleValues: getSimpleRuleValues,
  defaultRule: defaultRule,
  defaultRuleValueProps: defaultRuleValueProps,
  defaultRuleOperatorsProps: defaultRuleOperatorsProps
};
exports["default"] = _default;