import APP_CONFIG from '@trs/config';
import * as R from 'ramda';
import cmsActions from '../actions/types';
import cmsKeys from '../keys';
import { LOGO_ASSET_NAME } from '../util';

const baseTheme = R.path(['theme', 'base'], APP_CONFIG);
const GENERIC_LOGO_PATH = `/${LOGO_ASSET_NAME}`;

const initialKeyState = {
  loading: true,
  data: {},
  err: '',
};

const miniKeyReducer = (state = initialKeyState, action = {}) => {
  switch (action.type) {
    case cmsActions.FETCH_START:
      return { ...state, loading: true };
    case cmsActions.FETCH_OK:
      return { ...state, data: action.data, loading: false };
    case cmsActions.FETCH_FAIL:
      return { ...state, err: action.data, loading: false };
    case cmsActions.FETCH_HAS_DATA:
    default:
      return state;
  }
};

const overrideCurrentTheme = (current, future) => R.mergeDeepRight(current, future);

// Theme is added here temporarily
const initialState = {
  ...Object.keys(cmsKeys).reduce(
    (state, key) => ({
      ...state,
      [key]: miniKeyReducer(),
    }),
    {}
  ),
  themeOverride: {
    loading: true,
    data: baseTheme,
  },
  theme: {
    displayName: baseTheme.displayName,
    loading: false,
    data: baseTheme,
    err: '',
  },
  logo: {
    path: GENERIC_LOGO_PATH,
    isLoading: true,
  },
  /**
   * The PDF export doesn't accept cross-origin, so we need to get a base64 encoded version
   * of the png logo
   */
  pdfLogo: null,
  videoPlatforms: [],
};

const cmsReducer = (state = initialState, action) => {
  switch (action.type) {
    case cmsActions.FETCH_START:
    case cmsActions.FETCH_OK:
    case cmsActions.FETCH_FAIL:
    case cmsActions.FETCH_HAS_DATA:
      return { ...state, [action.key]: miniKeyReducer(state[action.key], action) };
    case cmsActions.THEME_EMPTY_GET:
      return { ...state, themeOverride: { ...state.themeOverride, loading: false } };
    case cmsActions.THEME_GET:
      return {
        ...state,
        themeOverride: {
          ...state.themeOverride,
          loading: false,
          data: action.data,
        },
      };
    case cmsActions.THEME_SWITCH:
      return {
        ...state,
        theme: {
          ...state.theme,
          loading: false,
          data: overrideCurrentTheme(
            R.path(['theme', 'data'], state),
            R.path(['themeOverride', 'data'], state)
          ),
        },
      };
    case cmsActions.LOGO_GET:
      return {
        ...state,
        logo: {
          path: R.path(['payload', 'logo'], action),
          isLoading: false,
        },
      };
    case cmsActions.LOGO_GET_ERROR:
      return {
        ...state,
        logo: {
          ...state.logo,
          isLoading: false,
        },
      };
    case cmsActions.PDF_LOGO_GET:
      return {
        ...state,
        pdfLogo: R.path(['payload', 'pdfLogo'], action),
      };
    default:
      return state;
  }
};

export default cmsReducer;
