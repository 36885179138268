export const ACTIVE_TYPES = {
  fixed: 'FIXED',
  percentage: 'PERCENTAGE',
};
export const NAME_MAX_CHARS = 40;

export const FX_RATE_DATE_CUSTOM = {
  label: 'Custom Date',
  value: 'fxRateCustomDate',
  valuesUrl: null,
  type: 'datetime',
};
