import * as R from 'ramda';

export const getEtagFromId = id =>
  R.compose(
    R.prop('etag'),
    R.find(R.propEq('id', id))
  );

export const setElectionRewardsPayload = params => {
  const { sortAsc, pagingTop: top, pagingSkip: skip, searchText, filters } = params;
  let { sortBy } = params;

  if (sortBy === 'category') {
    sortBy = 'encodedCategory';
  }

  if (sortBy === 'subcategory') {
    sortBy = 'encodedSubcategory';
  }

  if (sortBy === 'type') {
    sortBy = 'encodedType';
  }

  if (sortBy === 'displayName') {
    sortBy = 'name';
  }

  return {
    sort: {
      sortAsc,
      sortBy,
    },
    paging: {
      top,
      skip,
    },
    search: {
      searchText,
    },
    filters,
  };
};

export default {
  getEtagFromId,
  setElectionRewardsPayload,
};
