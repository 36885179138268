export default {
  DASHBOARD: 'Home',
  REWARDS_MANAGEMENT: 'Manage',
  REWARDS: 'Rewards',
  VARIABLES: 'Variables',
  EXCEPTIONS: 'Exceptions',
  FIXED_COSTS: 'Fixed Costs',
  LTI_PLANS: 'LTI Plans',
  FOREIGN_EXCHANGE: 'Foreign Exchange',
  ARTICLES_MANAGEMENT: 'Articles',
  FLEX_REWARDS: 'FLEX REWARDS',
  FLEX_PACKAGES: 'Flex Packages',
  BENEFITS_ENVELOPE: 'Benefits Envelope',
  ENROLLMENT_WINDOW: 'Enrolment Window',
  BENCHMARKING: 'Plan',
  SYSTEM: 'System',
  USER_MANAGEMENT: 'User Management',
  AUDIT: 'Audit',
  SETTINGS: 'Settings',
  EMPLOYEE_STATEMENT: 'My Statement',
  LOGOUT: 'Log Out',
  MODELLER: 'Model',
  REWARD_PACKAGE: 'Reward Package',
  CONFIGURATION: 'Configuration',
  REWARD_IMPACT: 'Reward Impact',
  REPORTS: 'Report',
  NOTIFICATIONS: 'Notifications',
  WORKSPACE: 'Workspace',
};
