import { Button, Dialog, H3 } from '@trs/components';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Text from '../Text';

class InactivityDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      minutes: `${Math.floor(this.props.expiryTimestamp / 60)}`,
      seconds: '00',
      difference: props.expiryTimestamp,
      intervalStarted: false,
    };
    this.interval = null;
  }

  handleCountDown() {
    this.interval = setInterval(() => {
      const { difference } = this.state;
      let countDownDiff = difference;
      if (!this.state.intervalStarted) {
        countDownDiff -= 1;
        this.setState({ intervalStarted: true });
      }
      if (countDownDiff >= 0) {
        const minutes = Math.floor(countDownDiff / 60);
        const seconds = countDownDiff - minutes * 60;
        this.setState({
          minutes,
          seconds: seconds > 9 ? seconds : `0${seconds}`,
        });
      }
      this.setState({ difference: countDownDiff - 1 });
    }, 1000);
  }

  componentDidMount() {
    this.handleCountDown();
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const { buttonAction, labelContinue, title } = this.props;
    const { minutes, seconds } = this.state;

    return (
      <Dialog
        width="850px"
        rightActions={
          <Button
            type="primary"
            action={() => {
              buttonAction();
            }}
            id="continueButton"
          >
            {labelContinue}
          </Button>
        }
        onClose={(event) => {
          event.stopPropagation();
        }}
      >
        <H3 className="dialog-title">{title}</H3>
        <Text
          path="generic.EXPIRE_SUBTITLE"
          transform={(label) => label.replace('%TIMESTAMP%', `${minutes}m ${seconds}s`)}
          withHtml
        />
      </Dialog>
    );
  }
}

InactivityDialog.defaultProps = {
  title: '',
};

InactivityDialog.propTypes = {
  expiryTimestamp: PropTypes.number.isRequired,
  title: PropTypes.string,
  buttonAction: PropTypes.func.isRequired,
  labelContinue: PropTypes.string.isRequired,
};

export default InactivityDialog;
