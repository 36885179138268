import { Card, H5, FormattedNumber } from '@trs/components';
import { isBlank } from '@trs/utils';
import { MAX_DECIMALS } from '@trs/config';
import PropTypes from 'prop-types';
import React from 'react';
import StyledRemainingBudget from './RemainingBudget.style';
import Text, { TextOnly } from '../../../../../common/Text';
import DescriptionTooltip from '../../../../../common/DescriptionTooltip';

const RemainingBudget = ({ amount, code, excessBudget, minimumUsageBudget, title }) => {
  const DEFAULT_TITLE = TextOnly({ path: 'flexibleRewards.EXCEEDED_TOOLTIP_DEFAULT_BUDGET_TITLE' });
  return (
    <StyledRemainingBudget isNegative={amount < 0}>
      <Card className="remaining-budget-container">
        <H5>{title || <Text path="flexibleRewards.REMAINING_BUDGET" />}</H5>
        <div className="remaining-budget-content">
          <strong>
            <FormattedNumber value={amount} decimals={MAX_DECIMALS} hasDecimals />
            <span className="budget-country-code">{code}</span>
          </strong>
        </div>
        <div className="remaining-budget-footer">
          {!isBlank(excessBudget) && (
            <div className="exceeding-budget">
              <span className="footer-item-title">
                <Text path="flexibleRewards.EXCEEDING_BUDGET_ALLOWED" />
              </span>
              <strong className="footer-item-value">
                <FormattedNumber value={excessBudget} decimals={MAX_DECIMALS} hasDecimals />
                <span className="budget-country-code">{code}</span>
              </strong>
              <DescriptionTooltip
                className="exceeding-tooltip"
                content={TextOnly({
                  path: 'flexibleRewards.EXCEEDED_TOOLTIP_TEXT',
                  transform: (label = '') => label.replace('%title%', title || DEFAULT_TITLE),
                })}
              />
            </div>
          )}
          {!isBlank(minimumUsageBudget) && (
            <div className="minimum-usage">
              <span className="footer-item-title">
                <Text path="flexibleRewards.MINIMUM_BUDGET_ALLOWED" />
              </span>
              <strong className="footer-item-value">
                <FormattedNumber value={minimumUsageBudget} decimals={MAX_DECIMALS} hasDecimals />
                <span className="budget-country-code">{code}</span>
              </strong>
              <DescriptionTooltip
                className="minimum-usage-tooltip"
                content={TextOnly({ path: 'flexibleRewards.MINIMUM_USAGE_TOOLTIP_TEXT' })}
              />
            </div>
          )}
        </div>
      </Card>
    </StyledRemainingBudget>
  );
};
RemainingBudget.propTypes = {
  amount: PropTypes.number.isRequired,
  code: PropTypes.string.isRequired,
  excessBudget: PropTypes.number,
  minimumUsageBudget: PropTypes.number,
  title: PropTypes.string,
};

RemainingBudget.defaultProps = {
  excessBudget: null,
  minimumUsageBudget: null,
  title: null,
};

export default RemainingBudget;
