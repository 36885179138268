import { createAction } from 'redux-actions';
import * as R from 'ramda';
import { GET, POST, PATCH, PUT, RequestFactory } from 'config/http';
import { connectors, payscales, organization } from 'config/api';
import { showBanner } from 'actions/globalActions';
import { TextOnly } from 'modules/common/Text';
import { processFile } from 'modules/common/DownloadLink/helpers';
import { isBlank } from '@trs/utils/lib/objects';
import { filteredAuthCountries } from './helpers';
import types from './types';

export const getHRSystemDataLoadStatus = createAction(types.HR_SYSTEM_DATA_LOAD_STATUS);
export const selectEmployeesDataFile = createAction(types.HR_SYSTEM_EMPLOYEES_FILE_SELECTED);
export const saveEmployeeFileData = createAction(types.HR_SYSTEM_SAVE_EMPLOYEE_FILE_DATA);
export const validateEmployeeFileData = createAction(types.HR_SYSTEM_VALIDATE_EMPLOYEE_FILE_DATA);
export const getCountries = createAction(types.HR_SYSTEM_LOAD_COUNTRIES);
export const loadCountriesDependenciesPending = createAction(
  types.HR_SYSTEM_COUNTRIES_DEPENDENCIES_PENDING
);
export const loadCountriesDependenciesSuccess = createAction(
  types.HR_SYSTEM_COUNTRIES_DEPENDENCIES_SUCCESS
);
export const loadCountriesDependenciesError = createAction(
  types.HR_SYSTEM_COUNTRIES_DEPENDENCIES_ERROR
);
export const getDimensions = createAction(types.HR_SYSTEM_LOAD_DIMENSIONS);
export const loadConfigurationSuccess = createAction(types.HR_SYSTEM_LOAD_CONFIG_SUCCESS);
export const loadConfigurationPending = createAction(types.HR_SYSTEM_LOAD_CONFIG_PENDING);
export const loadConfigurationError = createAction(types.HR_SYSTEM_LOAD_CONFIG_ERROR);
export const getSystemPayscales = createAction(types.HR_SYSTEM_LOAD_SYSTEM_PAYSCALES);
export const saveSystemPayscales = createAction(types.HR_SYSTEM_SAVE_SYSTEM_PAYSCALES);
export const addNewEmptyDimension = createAction(types.ADD_NEW_DIMENSION);
export const changeDimensionOption = createAction(types.CHANGE_DIMENSION_OPTION);
export const deleteDimension = createAction(types.DELETE_DIMENSION);
export const revertDimensionConfiguration = createAction(types.REVERT_DIMENSION_CONFIGURATION);
export const saveConfiguration = createAction(types.HR_SYSTEM_SAVE_CONFIGURATION);
export const saveConfigurationFail = createAction(types.HR_SYSTEM_SAVE_CONFIGURATION_FAIL);
export const editTableCell = createAction(types.HR_SYSTEM_EDIT_TABLE_CELL);
export const resetStore = createAction(types.RESET_STORE);
export const updateSystemMode = createAction(types.UPDATE_SYSTEM_MODE);
export const setIsCustomPayscaleEditable = createAction(types.HR_SYSTEM_SET_IS_EDITABLE);
export const setDisableToggleState = createAction(types.HR_SYSTEM_PAYSCALES_DISABLE_TOGGLE);
export const uploadFilePending = createAction(types.HR_SYSTEM_UPLOAD_DATA_FILE_PENDING);
export const uploadFileSuccess = createAction(types.HR_SYSTEM_UPLOAD_DATA_FILE_SUCCESS);
export const uploadFileError = createAction(types.HR_SYSTEM_UPLOAD_DATA_FILE_ERROR);

export const loadHRSystemDataLoadStatus = () => (dispatch) => {
  return dispatch(
    getHRSystemDataLoadStatus(
      GET(connectors.employeesUpload, {}, { showErrorModal: true, dispatch })
        .then((response) => R.path(['data', 'response'], response))
        .catch((error) => {
          return Promise.reject(error);
        })
    )
  );
};

export const savePayscaleTableEdit = (payscalesPayload, selectedCountry) => (dispatch) => {
  return dispatch(
    saveSystemPayscales(
      PUT(payscales.getSystemPayscales(selectedCountry.value), payscalesPayload)
        .then((response) => {
          dispatch(
            showBanner({
              type: 'success',
              content: TextOnly({ path: 'onboarding.HR_SYSTEM_PAYSCALES_SAVE_CHANGES_SUCCESS' }),
            })
          );

          return {
            response: {
              ...R.path(['data', 'response'], response),
              countryCode: selectedCountry.value,
            },
          };
        })
        .catch((error) => {
          dispatch(
            showBanner({
              type: 'error',
              content: TextOnly({ path: 'onboarding.HR_SYSTEM_PAYSCALES_SAVE_CHANGES_ERROR' }),
            })
          );
          return Promise.reject(error);
        })
    )
  );
};

export const toggleDisableState =
  ({ toggleState }) =>
  (dispatch) => {
    return dispatch(
      setDisableToggleState({
        toggleState,
      })
    );
  };

export const changePayscaleSystemMode = () => (dispatch, getState) => {
  const {
    onboarding: {
      hr: {
        payscales: { isSystemGenerated },
      },
    },
  } = getState();

  return dispatch(
    updateSystemMode({
      isSystemGenerated: !isSystemGenerated,
    })
  );
};

export const resetPayscaleSystemMode = () => (dispatch, getState) => {
  const {
    onboarding: {
      hr: {
        payscales: { originalIsSystemGenerated },
      },
    },
  } = getState();

  return dispatch(
    updateSystemMode({
      isSystemGenerated: originalIsSystemGenerated,
    })
  );
};

const postDataFileRequest = (inValidationMode = false, dispatch, getState) => {
  const {
    onboarding: {
      hr: { selectedFile },
    },
  } = getState();
  const formData = new FormData();

  formData.append(
    'file',
    new Blob([selectedFile], { type: 'text/csv' }),
    R.prop('name', selectedFile)
  );

  return POST(
    `${connectors.employeesUpload}?RunInValidationMode=${inValidationMode}`,
    formData,
    {},
    { showErrorModal: true, dispatch }
  )
    .then((response) => {
      dispatch(
        showBanner({
          type: 'success',
          content: TextOnly({
            path: inValidationMode
              ? 'onboarding.LOAD_DATA_UPLOADED_FILE_VALIDATION_STARTED'
              : 'generic.UPLOAD_FILE_SUCCESS',
          }),
        }),
        showBanner({
          type: 'success',
          content: TextOnly({
            path: inValidationMode ? null : 'onboarding.HR_SYSTEM_SENT_SUCCESS',
          }),
        })
      );
      return R.path(['data', 'response'], response);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

export const saveEmployeesData = () => (dispatch, getState) =>
  dispatch(saveEmployeeFileData(postDataFileRequest(false, dispatch, getState)));

export const validateEmployeesData = () => (dispatch, getState) =>
  dispatch(validateEmployeeFileData(postDataFileRequest(true, dispatch, getState)));

export const loadDimensions = () => (dispatch) => {
  return dispatch(
    getDimensions(
      GET(payscales.getDimensions, {}, { showErrorModal: true, dispatch })
        .then((response) => R.path(['data', 'response', 'dimensions'], response))
        .catch((error) => {
          return Promise.reject(error);
        })
    )
  );
};

export const loadCountries = () => (dispatch) => {
  return dispatch(
    getCountries(
      GET(organization.lookupCountries, {}, { showErrorModal: true, dispatch })
        .then((response) => response)
        .catch((error) => {
          return Promise.reject(error);
        })
    )
  );
};

export const loadCountriesDependencies = () => (dispatch, getState) => {
  const dependencyList = [];
  dependencyList.push(dispatch(loadCountries()));

  dispatch(loadCountriesDependenciesPending());

  return Promise.all(dependencyList)
    .then((response) => {
      const countries = R.path(['payload', 'data'], response[0]);
      const { authorizedModules, profile } = R.prop('user', getState());

      const filteredCountries = filteredAuthCountries({ authorizedModules, profile, countries });

      dispatch(loadCountriesDependenciesSuccess(filteredCountries));
    })
    .catch((body) => {
      const err = R.path(['response', 'data', 'errors'], body);
      dispatch(loadCountriesDependenciesError());
      return Promise.reject(err);
    });
};

export const loadSystemPayscales =
  ({ countryCode }) =>
  (dispatch) => {
    return dispatch(
      getSystemPayscales(
        GET(payscales.getSystemPayscales(countryCode), {}, { showErrorModal: true, dispatch })
          .then((response) => ({
            response: R.path(['data', 'response'], response),
          }))
          .catch((error) => {
            return Promise.reject(error);
          })
      )
    );
  };

export const loadPayscalesConfiguration =
  ({ saveDimensionsData, saveOptionsData }) =>
  (dispatch) => {
    dispatch(loadConfigurationPending({ saveDimensionsData, saveOptionsData }));

    return GET(payscales.handleConfiguration, {}, { showErrorModal: true, dispatch })
      .then((response) => {
        const data = R.path(['data', 'response'], response);
        return dispatch(
          loadConfigurationSuccess({
            ...data,
            saveDimensionsData,
            saveOptionsData,
            ETag: R.path(['headers', 'etag'], response),
          })
        );
      })
      .catch((error) => {
        const errorMessage = R.path(['response', 'data', 'errors', 0, 'message'], error);

        dispatch(
          showBanner({
            type: 'error',
            content: errorMessage,
          })
        );

        return dispatch(loadConfigurationError());
      });
  };

export const saveOptionsMode = () => (dispatch, getState) => {
  const state = getState();
  const isSystemGenerated = R.path(['onboarding', 'hr', 'payscales', 'isSystemGenerated'], state);
  const configurationId = R.path(['onboarding', 'hr', 'configurationId'], state);
  const ETag = R.path(['onboarding', 'hr', 'payscales', 'ETag'], state);

  return dispatch(
    saveConfiguration(
      PATCH(
        `${payscales.handleConfiguration}/${configurationId}/mode`,
        { isSystemGenerated },
        { ETag }
      )
        .then((response) => {
          dispatch(
            showBanner({
              type: 'success',
              content: TextOnly({ path: 'onboarding.DIALOG_SAVE_SUCCESS' }),
            }),
            showBanner({
              type: 'info',
              content: TextOnly({
                path: 'onboarding.HR_SYSTEM_PAYSCALES_SAVED_DIMENSIONS_RECALCULATING',
              }),
            })
          );

          return R.path(['data', 'response'], response);
        })
        .catch((body) => {
          const errorBody = R.path(['response', 'data'], body);
          const errors = R.prop('errors', errorBody);

          errors.forEach((err) => {
            const { field, message } = err;

            dispatch(
              saveConfigurationFail({
                field,
                message,
              }),
              showBanner({
                type: 'error',
                content: message,
              })
            );
          });

          return R.path('isSuccessful', errorBody);
        })
    )
  );
};

export const saveDimensions = () => (dispatch, getState) => {
  const state = getState();
  const dimensionIds = R.path(['onboarding', 'hr', 'dimensionsData'], state);
  const configurationId = R.path(['onboarding', 'hr', 'configurationId'], state);
  const ETag = R.path(['onboarding', 'hr', 'payscales', 'ETag'], state);

  return dispatch(
    saveConfiguration(
      PATCH(
        `${payscales.handleConfiguration}/${configurationId}/dimensions`,
        { dimensionIds },
        { ETag }
      )
        .then((response) => {
          dispatch(
            showBanner({
              type: 'success',
              content: TextOnly({ path: 'onboarding.DIALOG_SAVE_SUCCESS' }),
            }),
            showBanner({
              type: 'info',
              content: TextOnly({
                path: 'onboarding.HR_SYSTEM_PAYSCALES_SAVED_DIMENSIONS_RECALCULATING',
              }),
            })
          );

          return R.path(['data', 'response'], response);
        })
        .catch((body) => {
          const errorBody = R.path(['response', 'data'], body);
          const errors = R.prop('errors', errorBody);
          errors.forEach((err) => {
            const { field, message } = err;

            dispatch(
              saveConfigurationFail({
                field,
                message,
              })
            );
          });

          return R.path('isSuccessful', errorBody);
        })
    )
  );
};

export const uploadPayscalesData = (payload, selectedCountry) => (dispatch) => {
  dispatch(uploadFilePending());

  return POST(payscales.uploadPayscale(selectedCountry.value), payload)
    .then((data) => {
      if (!isBlank(R.path(['data', 'response', 'failedRowsIds'], data))) {
        throw new Error();
      }

      dispatch(
        uploadFileSuccess({
          response: { ...R.path(['data', 'response'], data), countryCode: selectedCountry.value },
        })
      );
      dispatch(
        showBanner({
          type: 'success',
          content: TextOnly({ path: 'onboarding.DIALOG_SAVE_SUCCESS' }),
        })
      );
    })
    .catch((error) => {
      const errorMessage = R.path(['response', 'data', 'errors', 0, 'message'], error);

      dispatch(uploadFileError());
      dispatch(
        showBanner({
          type: 'error',
          content: errorMessage,
        })
      );
    });
};

export const exportPayscales = (selectedCountry) => (dispatch) => {
  const url = payscales.exportPayscale(selectedCountry);

  return RequestFactory('GET', url, {}, {}, 'blob')
    .then((response) => processFile(response, ''))
    .catch(() =>
      dispatch(
        showBanner({
          type: 'error',
          content: TextOnly({ path: 'generic.BANNER_FILE_ERROR_GENERIC' }),
        })
      )
    );
};

export const revertConfiguration = () => (dispatch) => dispatch(revertDimensionConfiguration());
