"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var StyledTableHeadCell = _styledComponents["default"].th.withConfig({
  displayName: "TableHeadCellstyle__StyledTableHeadCell",
  componentId: "sc-e1wnxx-0"
})(["", ";"], function (props) {
  var _props$theme = props.theme,
    palette = _props$theme.palette,
    spacing = _props$theme.spacing,
    typography = _props$theme.typography;
  var sortable = props.sortable,
    align = props.align,
    maxWidth = props.maxWidth,
    sortIconsHeight = props.sortIconsHeight;
  return (0, _styledComponents.css)(["{background-color:", ";box-sizing:border-box;color:", ";font-weight:", ";height:", ";padding:0 ", ";text-align:left;text-transform:uppercase;font-size:", ";user-select:none;&:last-child{border-top-right-radius:2px;}", ";", ";", ";}"], palette.basic.white, palette.gray.solo, typography.weight.bold, spacing.large, spacing.tiny, typography.size.misc, sortable && (0, _styledComponents.css)(["cursor:pointer;.sort-icons{display:inline-flex;flex-direction:column;margin-left:", ";margin-top:-9px;vertical-align:middle;", " i{display:inline-block;height:9px;font-size:", ";}}"], spacing.tiny, sortIconsHeight && (0, _styledComponents.css)(["height:", ";"], sortIconsHeight), typography.size.icon), align && (0, _styledComponents.css)(["text-align:", ";"], align), maxWidth && (0, _styledComponents.css)(["max-width:", "px;"], maxWidth));
});
StyledTableHeadCell.displayName = 'StyledTableHeadCell';
var _default = StyledTableHeadCell;
exports["default"] = _default;