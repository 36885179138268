import { RECURRENCE_OPTIONS_TYPES } from 'modules/common/constants';
import APP_CONFIG from '@trs/config';

const {
  GENERAL_STATUSES_KEYS: { STATUS_LIVE, STATUS_PUBLISHED, STATUS_EXPIRED, STATUS_ERROR },
} = APP_CONFIG;

export const ERROR_CODES = {
  VALUE_IS_NEGATIVE: 'VALUE_IS_NEGATIVE',
  SUM_NOT_100: 'SUM_NOT_100',
  FIELD_IS_REQUIRED: 'FIELD_IS_REQUIRED',
  FORBIDDEN_POPULATION_ACCESS: 'FORBIDDEN_POPULATION_ACCESS',
};

export const DISPLAY_PLAN_NAME_FIELD_MAX_LENGTH = 100;
export const DISPLAY_PLAN_NAME_FIELD_MIN_LENGTH = 3;
export const DISPLAY_PLAN_DESCRIPTION_MAX_LENGTH = 500;
export const DISPLAY_PLAN_LENGTH_IN_YEARS_MIN_LENGTH = 1;
export const DISPLAY_PLAN_LENGTH_IN_YEARS_MAX_LENGTH = 2;
export const PERCENTAGE_DISTRIBUTION_MAX_LENGTH = 12;
export const DEFAULT_TOTAL_DISTRIBUTION = 100;

export const REVERT_STATUSES = [STATUS_LIVE, STATUS_PUBLISHED, STATUS_EXPIRED, STATUS_ERROR];
export const ROUTING_MAP = ['basic-information', 'rules'];
export const RECURRENCE_OPTIONS = [RECURRENCE_OPTIONS_TYPES.MONTHS, RECURRENCE_OPTIONS_TYPES.YEARS];

export const DETAILS_STATUS_LABELS = {
  status: 'ltiPlans.STATUS',
  country: 'ltiPlans.COUNTRY',
  currency: 'ltiPlans.CURRENCY',
  startDate: 'ltiPlans.START_DATE',
  revertToDraft: 'ltiPlans.REVERT_TO_DRAFT',
};

export const TEMPLATE_TYPES = [
  {
    value: 'Amount',
    label: 'Amount',
    code: 'Amount',
  },
];

export const VESTING_TYPES = [
  {
    value: 'Graded',
    label: 'Graded',
    code: 'Graded',
  },
];

export const FX_RATE_DATE_CUSTOM = {
  label: 'Custom Date',
  value: 'fxRateCustomDate',
  valuesUrl: null,
  type: 'datetime',
};

export const RESPONSE_DISTRIBUTION_TYPE = {
  AUTOMATIC: 1,
  MANUAL: 2,
};

export const DEFAULT_TABLE_COLUMN_IDS = {
  DATE: 'date',
  DISTRIBUTION: 'distribution',
};
