import { FORECAST_REWARDS_TYPES, reportKeys, reportTypes } from '../routes/helpers';

export default {
  packages: [],
  isLoading: true,
  isError: false,
  etag: '',
  rewards: {
    [reportKeys.REPORT_P0071]: [],
    [reportKeys.REPORT_P0014]: [],
  },
  list: [],
  enrollmentRewards: {
    filterOptions: [],
    fetchingList: false,
  },
  newData: {
    rewardsSelection: {
      [reportKeys.REPORT_P0071]: [],
      [reportKeys.REPORT_P0014]: [],
    },
    rewardBeaId: null,
    rewardsIds: {},
    hasChanges: false,
    hasP0071Changes: false,
    hasP0014Changes: false,
    isSending: false,
  },
  forecastRewards: {
    type: FORECAST_REWARDS_TYPES.TEXT,
    employeeList: '',
    variableList: '',
    calculateOnDate: null,
    rewardsIds: {},
    rewardBeaId: '',
    isCalculating: false,
    loading: false,
    allRewards: [],
  },
  showUploadDialog: false,
  isUploading: false,
  errorData: {
    [reportTypes.REPORT_P0014]: [],
    [reportTypes.REPORT_P0071]: [],
  },
  completed: {
    [reportTypes.REPORT_P0014]: false,
    [reportTypes.REPORT_P0071]: false,
  },
  configData: {
    [reportTypes.REPORT_P0014]: false,
    [reportTypes.REPORT_P0071]: false,
  },
};
