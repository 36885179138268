import { clone } from 'ramda';
import { isBlank } from '@trs/utils';
import * as actions from '../actions/types';
import initialState from './initialState';

export default function foreignExchangeReducer(state = initialState, action) {
  switch (action.type) {
    case actions.FX_SOURCES_REQUESTED: {
      return {
        ...state,
        fetchingList: true,
      };
    }
    case actions.FX_SOURCES_GET: {
      const { data } = action.response;

      return {
        ...state,
        list: data,
        fetchingList: false,
        totalResults: isBlank(data) ? 0 : data.length,
      };
    }
    case actions.FX_SOURCES_BEGIN_UPLOAD: {
      return {
        ...state,
        isUploadInProgress: true,
      };
    }
    case actions.FX_SOURCES_ERROR: {
      return {
        ...state,
        isUploadInProgress: false,
        fetchingList: false,
      };
    }
    case actions.FX_SOURCES_UPLOAD_SUCCESS: {
      return {
        ...state,
        isUploadInProgress: false,
      };
    }
    case actions.FX_SOURCES_REDIRECT: {
      return {
        ...state,
        redirectTo: action.sourceId,
      };
    }
    case actions.FX_SOURCES_TOGGLE_UPLOAD_DIALOG: {
      return {
        ...state,
        isUploadDialogVisible: !state.isUploadDialogVisible,
      };
    }
    /** FX rates reducer */
    case actions.FX_RATES_REQUESTED: {
      return {
        ...state,
        rates: {
          ...state.rates,
          isFetching: true,
        },
        redirectTo: '',
      };
    }
    case actions.FX_RATES_GET: {
      const { rates, count, sourceName, isAutomatic } = action.response.data;

      return {
        ...state,
        rates: {
          list: rates,
          isFetching: false,
          count,
          sourceName,
          isAutomatic,
        },
      };
    }
    case actions.FX_RATES_GET_CURRENCIES: {
      const { data } = action.response;
      const currencies = [];

      data.map(item =>
        currencies.push({
          value: item.code,
          label: item.code,
        })
      );

      return {
        ...state,
        currencies,
      };
    }
    case actions.FX_RATES_RESET: {
      return {
        ...clone(initialState),
      };
    }
    default: {
      return state;
    }
  }
}
