export const FX_SOURCES_REQUESTED = 'FX_SOURCES_REQUESTED';
export const FX_SOURCES_GET = 'FX_SOURCES_GET';
export const FX_SOURCES_BEGIN_UPLOAD = 'FX_SOURCES_BEGIN_UPLOAD';
export const FX_SOURCES_TOGGLE_UPLOAD_DIALOG = 'FX_SOURCES_TOGGLE_UPLOAD_DIALOG';
export const FX_SOURCES_UPLOAD_SUCCESS = 'FX_SOURCES_UPLOAD_SUCCESS';
export const FX_SOURCES_ERROR = 'FX_SOURCES_ERROR';
export const FX_SOURCES_REDIRECT = 'FX_SOURCES_REDIRECT';
export const FX_RATES_REQUESTED = 'FX_RATES_REQUESTED';
export const FX_RATES_GET = 'FX_RATES_GET';
export const FX_RATES_RESET = 'FX_RATES_RESET';
export const FX_RATES_GET_CURRENCIES = 'FX_RATES_GET_CURRENCIES';
export const FX_RATE_GET_DATES = 'FX_RATE_GET_DATES';
