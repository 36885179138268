export default {
  SHARES_TITLE: 'Shares',
  SHARES_SUBTITLE: 'Please provide shares information',
  SHARES_LOAD_DATA_CARD_SUBTITLE: 'Load Data for shares providers.',
  SHARES_CAPTION:
    'Shares features allow you to view shares amounts in %PLATFORM%. This information is optional and can be setup later if needed.',
  SHARES_PLEASE_SELECT_A_PROVIDER: 'Please select a provider',
  SHARES_CARD_SUBTITLE:
    'Shares details will be presented in the Employee Statement only for the employees eligible for shares, based on configurations in this section.',
  CONFIGURE_SHARES_PROVIDER: 'Configure Shares Provider',
  CONFIGURE_SHARES_PROVIDER_SUBTITLE: 'Configure shares providers and their associated shares.',
  ADD_SHARES_PROVIDER: 'Add Shares Provider',
  ADD_SHARE_PROVIDER_TITLE: 'Add Shares Provider',
  EDIT_SHARE_PROVIDER_TITLE: 'Edit %NAME%',
  SHARES_PROVIDER_NAME: 'Shares Provider Name',
  ENTER_SHARES_PROVIDER_NAME: 'Enter Shares Provider Name',
  SHARES_PROVIDER_URL: 'Shares Provider URL',
  ENTER_SHARES_PROVIDER_URL: 'Enter Shares Provider URL',
  SHARES: 'Shares',
  SHARES_INDEX: 'Shares %INDEX%',
  SHARES_INDEX_AND_NAME: 'Shares %INDEX% %NAME%',
  SHARE_PROVIDER_SYMBOL: 'Provider Share Symbol',
  ENTER_SHARE_PROVIDER_SYMBOL: 'Enter Provider Share Symbol',
  SHARE_NAME: 'Share Name',
  ENTER_SHARE_NAME: 'Enter Share Name',
  SHARE_SYMBOL: 'Share Symbol',
  ENTER_SHARE_SYMBOL: 'Enter Share Symbol',
  ADD_SHARES: 'Add Shares',
  LOAD_SHARES_DATA_TITLE: 'Load Shares Data',
  LOAD_SHARES_DATA_SUBTITLE: 'Upload file after downloading the template below.',
  SHARES_DOWNLOAD_TEMPLATE: 'Download Template',
  DIALOG_SAVE_SUCCESS: 'Your changes have been saved successfully!',
  DUPLICATED_FIELD: '%FIELD% cannot be duplicated',
  SHARE_SYMBOL_INVALID: 'Specified Share Symbol does not exist.',
};
