import * as R from 'ramda';
import { v4 as uuid } from 'uuid';

export const mapImpactedEmployees = (employeeList) =>
  R.map(
    (item) => ({
      employeeId: item,
      uid: uuid(),
    }),
    employeeList
  );

export const initPopulationException = (exceptionsId) => [
  {
    id: exceptionsId,
    name: 'Exceptions',
    eligibility: {
      eligibilityRows: [
        {
          variable: '',
          operator: '',
          values: [],
          uid: uuid(),
        },
      ],
      uid: uuid(),
    },
  },
];

export const processPopulationExceptionsChange = (stateExceptions, payload) => {
  const exceptions = stateExceptions;
  exceptions[0] = {
    ...exceptions[0],
    [payload.field]: payload.value,
    ...payload.other,
  };

  return exceptions;
};

// this needs to be referenced from benchmarking population
export const getLookupData = (lookupData, url) => {
  const lookUpValues = {};
  lookUpValues[url] = [];

  if (lookupData) {
    lookupData.map((item) =>
      lookUpValues[url].push({
        label: item,
        value: item,
      })
    );
    lookUpValues[url] = R.sortBy(R.prop('value'))(lookUpValues[url]);
  }

  return lookUpValues;
};

export default {
  initPopulationException,
  mapImpactedEmployees,
  processPopulationExceptionsChange,
  getLookupData,
};
