"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
var R = _interopRequireWildcard(require("ramda"));
var _utils = require("@trs/utils");
var _templateObject, _templateObject2, _templateObject3;
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
/* bottomBorder is something that's defined on the card component at theme level.
  It is being overridden on the employee statement carousel.
  Overriden in various dashboards as well, both in terms of color and weight.
*/
var defaultBorderWeight = '10px';
var getBorderColor = function getBorderColor(theme, props) {
  var components = theme.components;
  var borderColor = props.borderColor,
    borderWeight = props.borderWeight,
    borderPosition = props.borderPosition;
  var border = R.path(['bottomBorder', 'color', 'card'], components) || borderWeight || borderColor || borderPosition ? "".concat(borderWeight || R.path(['bottomBorder', 'weight', 'card'], components) || defaultBorderWeight, " solid ").concat(borderColor || R.path(['bottomBorder', 'color', 'card'], components)) : null;
  return border;
};
var StyledActionCard = _styledComponents["default"].div.withConfig({
  displayName: "ActionCardstyle__StyledActionCard",
  componentId: "sc-6e3xwo-0"
})(["", ";"], function (props) {
  var _props$theme = props.theme,
    palette = _props$theme.palette,
    spacing = _props$theme.spacing;
  var isTile = props.isTile,
    last = props.last,
    borderPosition = props.borderPosition;
  return (0, _styledComponents.css)(["position:relative;border-radius:", ";border:1px solid ", ";background-color:", ";box-shadow:0px 1px 8px rgba(0,0,0,0.04);margin-bottom:", ";", " .action-card-container{padding:", " ", " ", ";", " ", ";}", " .paragraph-subtitle{color:", ";}.action-card-header{display:flex;flex-direction:row;.content-toggler{&.active{visibility:hidden;}}}.contextual-menu{position:absolute;right:", ";z-index:1;}.action-card-custom-title{word-break:break-all;width:calc(100% - 32px);}.action-card-subtitle{margin-bottom:", ";color:", ";", "}"], spacing.xTiny, palette.accent.tau, palette.basic.white, spacing.base, "border-".concat(borderPosition, ": ").concat(getBorderColor(props.theme, props), ";"), spacing.small, spacing.small, spacing.basePlus, _utils.media.tablet(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n            padding: ", " ", " ", ";\n        "])), spacing.small, spacing.small, spacing.base), _utils.media.desktop(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n            padding: ", " ", " ", ";\n        "])), spacing.base, spacing.small, spacing.base), isTile && (0, _styledComponents.css)(["margin-bottom:", ";"], last ? spacing.large : spacing.base), palette.basic.gray, spacing.tiny, spacing.small, palette.basic.gray, _utils.media.desktop(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n          margin-bottom: ", ";\n        "])), spacing.base));
});
StyledActionCard.displayName = 'StyledActionCard';
var _default = StyledActionCard;
exports["default"] = _default;