import React from 'react';
import PropTypes from 'prop-types';
import * as signalR from '@microsoft/signalr';
import { isBlank, Log, noop } from '@trs/utils';
import { getUser, NOTIFICATION_RECEIVED } from '../helpers';

class NotificationsEngine extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      connection: null,
    };
  }

  componentDidMount() {
    this.connect();
  }

  componentWillUnmount() {
    this.disconnect();
  }

  bindEvents = (connection) => {
    const { actions } = this.props;
    connection.start().catch((err) => {
      Log.warn(`Notifications Engine: Failed to connect with error ${err}`);
      if (!isBlank(err)) {
        connection.start();
      }
    });

    connection.on(NOTIFICATION_RECEIVED, (notification) => {
      Log.warn(`Notifications Engine: Notification received`);
      actions.onWSMessage(notification);
    });

    connection.onclose((err) => {
      Log.warn(`Notifications Engine: Failed to close connection with error ${err}`);

      if (!isBlank(err)) {
        connection.start();
      }
    });
  };

  connect = () => {
    const { wsUrl } = this.props;
    getUser()
      .then((token) => {
        let connection = null;
        try {
          connection = new signalR.HubConnectionBuilder()
            .withUrl(wsUrl, {
              accessTokenFactory: () => token,
              /* We set the transport as LongPolling to avoid sending the access token as a query param */
              transport: signalR.HttpTransportType.LongPolling,
            })
            .withAutomaticReconnect()
            .build();
        } catch (exception) {
          Log.warn(`We have encountered an issue while connecting to signalR ${exception}`);
        }

        if (connection !== null) {
          this.setState({ connection });
          this.bindEvents(connection);
        }
      })
      .catch(noop);
  };

  disconnect = () => {
    const { connection } = this.state;

    if (connection) connection.stop();
  };

  render() {
    return <span />;
  }
}

NotificationsEngine.propTypes = {
  wsUrl: PropTypes.string.isRequired,
  actions: PropTypes.shape({
    onWSMessage: PropTypes.func.isRequired,
  }).isRequired,
};

export default NotificationsEngine;
