"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = {
  primary: '#1E296B',
  secondary: '#856dcb',
  accent: {
    alpha: '#856dcb',
    bravo: '#f2f2f6',
    charlie: '#EAEBFE',
    delta: '#F8F9FF',
    echo: '#4ab56f',
    foxtrot: '#EBECFF',
    sigma: '#BFDEED',
    tau: '#d8dbf0'
  },
  gray: {
    alpha: '#4A4A4A',
    shark: '#303338',
    bravo: '#9B9B9B',
    charlie: '#D8D8D8',
    delta: '#F4F4F4',
    echo: '#FCFCFC',
    foxtrot: '#e5e5e5',
    golf: '#80828e',
    sigma: '#464546',
    zircon: '#f6f7fb',
    dusty: '#9b9b9b',
    silver: '#bdbdbd',
    solo: '#333852'
  },
  validation: {
    valid: '#4caf50',
    validLight: '#7ED321',
    validLighter: '#DEFCD9',
    warning: '#F57804',
    warningLight: '#FCFAD9',
    error: '#e6453a',
    errorLight: '#FCD9D9',
    errorLighter: '#FFF6F6',
    errorMedium: '#FAA38A',
    errorHeavy: '#B42727',
    info: '#1d7fd1'
  },
  basic: {
    black: '#000',
    white: '#FFF',
    gray: '#8b8b8b'
  },
  icons: {
    warning: '#F5A623'
  },
  utility: {
    border: '#D8DBF0',
    bodyBackground: '#F7F7FA',
    dialogBodyWarning: '#FFEDBF',
    highlight: '#FEF1D1'
  }
};
exports["default"] = _default;