import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { PageLoader } from '@trs/components';
import Layout from '../../common/layout/Layout';
import Login from './Login';
import { fetchCmsData } from '../../cms/actions';
import cmsKeys from '../../cms/keys';

const cmsStateKey = cmsKeys.userManagement;

class Root extends Component {
  componentDidMount() {
    const {
      actions: { fetchData },
    } = this.props;
    const params = [{ name: 'resourceGroup', value: 'UserManagement' }];
    fetchData(params, cmsStateKey);
  }

  render() {
    const { loading } = this.props;
    return <Layout>{loading ? <PageLoader /> : <Login />}</Layout>;
  }
}

Root.propTypes = {
  actions: PropTypes.shape({
    fetchData: PropTypes.func.isRequired,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = state => state.cms[cmsStateKey];

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ fetchData: fetchCmsData }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Root);
