/* eslint-disable max-lines-per-function */
import * as R from 'ramda';

import actionTypes from '../actions/types';
import initialState from './initialState';
import { reportTypes, reportKeys, SELECTED_REWARDS_PREFIX } from '../routes/helpers';

export default function benefitsEnvelopeReducer(state = R.clone(initialState), action) {
  switch (action.type) {
    case actionTypes.PACKAGES_FETCH_START: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case actionTypes.PACKAGES_CLEAR: {
      return {
        ...state,
        isLoading: false,
        packages: initialState.packages,
      };
    }
    case actionTypes.PACKAGES_FETCH_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        packages: action.payload.response,
      };
    }
    case actionTypes.PACKAGES_FETCH_FAILED: {
      return {
        ...state,
        isLoading: false,
        isError: true,
        packages: initialState.packages,
      };
    }
    case actionTypes.REWARDS_FETCH_FAILED: {
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    }
    case actionTypes.REWARDS_FETCH_START: {
      return {
        ...state,
        isLoading: true,
        rewards: initialState.rewards,
        configData: initialState.configData,
        completed: initialState.completed,
        newData: initialState.newData,
      };
    }
    case actionTypes.REWARDS_FETCH_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isError: false,
        etag: R.path(['headers', 'etag'], action.payload),
        rewards: {
          ...state.rewards,
          [reportKeys.REPORT_P0071]: R.path(
            ['data', `${SELECTED_REWARDS_PREFIX}${reportTypes.REPORT_P0071}`],
            action.payload
          ),
          [reportKeys.REPORT_P0014]: R.path(
            ['data', `${SELECTED_REWARDS_PREFIX}${reportTypes.REPORT_P0014}`],
            action.payload
          ),
        },
        configData: {
          [reportTypes.REPORT_P0071]:
            R.path(['data', 'p0071Configured'], action.payload) ||
            state.configData[reportTypes.REPORT_P0071],
          [reportTypes.REPORT_P0014]:
            R.path(['data', 'p0014Configured'], action.payload) ||
            state.configData[reportTypes.REPORT_P0014],
        },
        completed: {
          [reportTypes.REPORT_P0071]:
            R.path(['data', 'p0071Configured'], action.payload) ||
            state.completed[reportTypes.REPORT_P0071],
          [reportTypes.REPORT_P0014]:
            R.path(['data', 'p0014Configured'], action.payload) ||
            state.completed[reportTypes.REPORT_P0014],
        },
        newData: {
          ...state.newData,
          rewardsSelection: {
            ...state.newData.rewardsSelection,
            [reportKeys.REPORT_P0071]: R.path(
              ['data', `${SELECTED_REWARDS_PREFIX}${reportTypes.REPORT_P0071}`],
              action.payload
            ),
            [reportKeys.REPORT_P0014]: R.path(
              ['data', `${SELECTED_REWARDS_PREFIX}${reportTypes.REPORT_P0014}`],
              action.payload
            ),
          },
        },
        rewardBeaId: R.path(['data', 'rewardBeaId'], action.payload),
        rewardsIds: R.path(['data', 'rewardsIds'], action.payload),
      };
    }
    case actionTypes.ALL_REWARDS_FETCH_FAILED: {
      return {
        ...state,
        enrollmentRewards: {
          ...state.enrollmentRewards,
          fetchingList: false,
        },
      };
    }
    case actionTypes.ALL_REWARDS_FETCH_START: {
      return {
        ...state,
        enrollmentRewards: {
          ...state.enrollmentRewards,
          fetchingList: true,
        },
      };
    }
    case actionTypes.ALL_REWARDS_FETCH_SUCCESS: {
      const rewardsCount = action.payload.length;
      return {
        ...state,
        list: action.payload,
        pagination: {
          ...state.pagination,
          totalResults: rewardsCount || 0,
        },
        enrollmentRewards: {
          ...state.enrollmentRewards,
          fetchingList: false,
        },
      };
    }

    case actionTypes.REWARDS_SELECTION_CHANGE: {
      const { field, checked, rewardId } = action.payload;

      let selectedRewards = state.newData.rewardsSelection[field];
      if (checked) {
        selectedRewards = R.uniq([rewardId, ...selectedRewards]);
      } else {
        selectedRewards = selectedRewards.filter(item => item !== rewardId);
      }

      const newRewardsSelection = {
        ...state.newData.rewardsSelection,
        [field]: selectedRewards,
      };

      const hasP0071Changes = !R.isEmpty(
        R.symmetricDifference(
          newRewardsSelection[reportKeys.REPORT_P0071],
          state.rewards[reportKeys.REPORT_P0071]
        )
      );

      const hasP0014Changes = !R.isEmpty(
        R.symmetricDifference(
          newRewardsSelection[reportKeys.REPORT_P0014],
          state.rewards[reportKeys.REPORT_P0014]
        )
      );

      const hasChanges = hasP0071Changes || hasP0014Changes;

      return {
        ...state,
        completed: {
          [reportTypes.REPORT_P0071]: hasP0071Changes
            ? false
            : state.configData[reportTypes.REPORT_P0071],
          [reportTypes.REPORT_P0014]: hasP0014Changes
            ? false
            : state.configData[reportTypes.REPORT_P0014],
        },
        newData: {
          ...state.newData,
          hasChanges,
          hasP0071Changes,
          hasP0014Changes,
          rewardsSelection: newRewardsSelection,
        },
      };
    }

    case actionTypes.REWARDS_SELECTION_SUBMIT: {
      return {
        ...state,
        newData: {
          ...state.newData,
          isSending: true,
        },
      };
    }

    case actionTypes.REWARDS_SELECTION_SUBMIT_FAILED: {
      return {
        ...state,
        newData: {
          ...state.newData,
          isSending: false,
          hasChanges: true,
        },
      };
    }

    case actionTypes.REWARDS_SELECTION_SUBMIT_SUCCESS: {
      return {
        ...state,
        etag: action.payload || state.etag,
        newData: {
          ...state.newData,
          isSending: false,
          hasChanges: false,
        },
      };
    }

    case actionTypes.RESET_DATA: {
      return {
        ...state,
        rewards: initialState.rewards,
        newData: initialState.newData,
        forecastRewards: initialState.forecastRewards,
      };
    }

    case actionTypes.FILE_UPLOAD_DIALOG_TOGGLE: {
      const { payload } = action;
      const showUploadDialog = payload !== undefined ? payload : !state.showUploadDialog;
      return {
        ...state,
        showUploadDialog,
      };
    }

    case actionTypes.FILE_UPLOAD: {
      return {
        ...state,
        showUploadDialog: false,
        isUploading: true,
      };
    }

    case actionTypes.FILE_UPLOAD_ERROR: {
      return {
        ...state,
        isUploading: false,
        errorData: {
          ...state.errorData,
          [action.reportType]: action.payload,
        },
        completed: {
          ...state.completed,
          [action.reportType]: false,
        },
      };
    }

    case actionTypes.FILE_UPLOAD_SUCCESS: {
      return {
        ...state,
        isUploading: false,
        etag: action.payload || state.etag,
        errorData: {
          ...state.errorData,
          [action.reportType]: [],
        },
        completed: {
          ...state.completed,
          [action.reportType]: true,
        },
      };
    }

    case actionTypes.FORECAST_REWARDS_STATUS: {
      return {
        ...state,
        forecastRewards: {
          ...state.forecastRewards,
          isCalculating: R.prop('isCalculating', action.payload),
        },
      };
    }

    case actionTypes.GET_ALL_REWARDS: {
      return {
        ...state,
        forecastRewards: {
          ...state.forecastRewards,
          loading: true,
        },
      };
    }

    case actionTypes.GET_ALL_REWARDS_SUCCESS: {
      return {
        ...state,
        forecastRewards: {
          ...state.forecastRewards,
          loading: false,
          allRewards: action.payload,
        },
      };
    }

    case actionTypes.GET_ALL_REWARDS_FAILED: {
      return {
        ...state,
        forecastRewards: {
          ...state.forecastRewards,
          loading: false,
        },
      };
    }

    case actionTypes.FORECAST_REWARDS_CHANGE: {
      return {
        ...state,
        forecastRewards: {
          ...state.forecastRewards,
          [action.payload.field]: action.payload.value,
        },
      };
    }

    case actionTypes.FORECAST_REWARDS_START: {
      return {
        ...state,
        forecastRewards: {
          ...state.forecastRewards,
          isCalculating: true,
        },
      };
    }

    case actionTypes.FORECAST_REWARDS_SUCCESS: {
      return {
        ...state,
        forecastRewards: {
          ...state.forecastRewards,
          isCalculating: false,
        },
      };
    }

    case actionTypes.FORECAST_REWARDS_FAILED: {
      return {
        ...state,
        forecastRewards: {
          ...state.forecastRewards,
          isCalculating: false,
        },
      };
    }

    default:
      return state;
  }
}
