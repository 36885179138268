import * as R from 'ramda';
import { createAction } from 'redux-actions';
import { Log } from '@trs/utils';
import actionTypes from './types';
import { getSalaryIncreaseAverage } from '../../../actions/salaryIncrease';
import { GET, POST } from '../../../../../../../../../config/http';
import { benchmark } from '../../../../../../../../../config/api';
import { isNoContent, HEATMAP_CALC_STATUS } from '../helpers';

export const calculatePremerit = createAction(actionTypes.BENCHMARKING_CALCULATE_PREMERIT);
export const getPremerit = createAction(actionTypes.BENCHMARKING_GET_PREMERIT);

export const calculateMerit = () => (dispatch, getState) => {
  const globalId = R.path(['benchmarking', 'global', 'main', 'identifiers', 'id'], getState());
  const salaryId = R.path(
    ['benchmarking', 'local', 'salaryIncrease', 'proposal', 'proposalId'],
    getState()
  );

  const baseUri = `${benchmark.base}/benchmarking/${globalId}/salaryIncreases/${salaryId}/calculations`;

  return dispatch(calculatePremerit(POST(baseUri)));
};

export const loadPremerit = (globalBenchmarkingId, salaryIncreaseId) => (dispatch, getState) => {
  const state = getState();
  const globalId = R.path(['benchmarking', 'global', 'main', 'identifiers', 'id'], state);
  const salaryId = R.path(
    ['benchmarking', 'local', 'salaryIncrease', 'proposal', 'proposalId'],
    state
  );

  const baseUri = `${benchmark.base}/benchmarking/${
    globalBenchmarkingId || globalId
  }/salaryIncreases/${salaryIncreaseId || salaryId}/calculations`;

  return dispatch(
    getPremerit(
      GET(baseUri).then((response) => {
        const responseStatus = R.prop('status', response);
        const calculationStatus = R.path(['data', 'response', 'metadata', 'status'], response);
        if (isNoContent(responseStatus)) {
          return {
            data: {},
            metadata: {},
          };
        }
        switch (calculationStatus) {
          case HEATMAP_CALC_STATUS.NOT_STARTED:
          case HEATMAP_CALC_STATUS.RUNNING: {
            Log.warn(`Calculation in progress. Status: ${calculationStatus}`);
            return dispatch(calculatePremerit());
          }
          case HEATMAP_CALC_STATUS.COMPLETED: {
            const metadata = R.path(['data', 'response', 'metadata'], response);

            dispatch(getSalaryIncreaseAverage({ data: metadata }));
            return {
              data: R.path(['data', 'response', 'payload'], response),
              metadata,
            };
          }
          case HEATMAP_CALC_STATUS.FAILED:
          case HEATMAP_CALC_STATUS.TERMINATED:
          default: {
            Log.warn(`Heatmap calculation error. Status: ${calculationStatus}`);
            return Promise.reject();
          }
        }
      })
    )
  );
};
