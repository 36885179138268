export default {
  VARIABLES_GET_COUNTRIES: 'VARIABLES_GET_COUNTRIES',
  VARIABLES_SAVE: 'VARIABLES_SAVE',
  VARIABLE_SEARCH: 'VARIABLE_SEARCH',
  VARIABLES_GET_BY_ID: 'VARIABLES_GET_BY_ID',
  VARIABLE_CHANGE: 'VARIABLE_CHANGE',
  VARIABLE_STORE_RESET: 'VARIABLE_STORE_RESET',
  VARIABLE_DELETE_BY_ID: 'VARIABLE_DELETE_BY_ID',
  VARIABLES_GET_AFFECTED_REWARDS: 'VARIABLES_GET_AFFECTED_REWARDS',
};
