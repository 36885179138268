import * as R from 'ramda';
import {
  CONFLICT_STATUS_CODE,
  FORBIDDEN_STATUS_CODE,
  TOO_MANY_REQUESTS_STATUS_CODE,
} from '@trs/config';
import { showBanner } from 'actions/globalActions';
import { TextOnly } from 'modules/common/Text';
import {
  upperFirstLetter,
  isBlank,
  rejectEmptyValues,
  getErrorMessage,
  getErrorStatus,
} from '@trs/utils';
import { getError } from 'config/errors';

const dispatchError = (error, dispatch, state) => {
  let errorMessage = getErrorMessage(error);
  const status = getErrorStatus(error);

  if (state) {
    switch (status) {
      case CONFLICT_STATUS_CODE: {
        errorMessage = R.path(['cms', 'generic', 'data', 'CONFLICT_BANNER'], state);
        break;
      }

      case FORBIDDEN_STATUS_CODE: {
        const cmsErrorKey = R.path(['response', 'data', 'errors', 0], error);
        errorMessage = R.path(['cms', 'articles', 'data', cmsErrorKey], state);
        break;
      }

      case TOO_MANY_REQUESTS_STATUS_CODE: {
        errorMessage = R.path(['cms', 'generic', 'data', 'TRY_LATER_GENERIC'], state);
        break;
      }

      default:
        break;
    }
  }
  dispatch(
    showBanner({
      type: 'error',
      content: errorMessage,
    })
  );
};

export const validateSimpleEligibility = (eligibilityObject) =>
  R.mapObjIndexed((item, key) => {
    let keyLabel;
    switch (key) {
      case 'variable':
        keyLabel = TextOnly({ path: 'articles.ELIGIBILITY_RULE_VARIABLE_NAME' });
        break;
      case 'operator':
        keyLabel = TextOnly({ path: 'articles.ELIGIBILITY_RULE_OPERATOR' });
        break;
      case 'values':
        keyLabel = TextOnly({ path: 'articles.ELIGIBILITY_RULE_VALUE' });
        break;
      default:
        keyLabel = key;
        break;
    }

    return getError(TextOnly({ path: 'generic.ERROR_REQUIRED' }), upperFirstLetter(keyLabel));
  }, R.filter(R.isEmpty, eligibilityObject));

export const getEligibilityErrors = (eligibility, errors = {}) => {
  const errorObject = errors;
  if (eligibility.eligibilityRows) {
    R.forEach(
      (eligibilityRow) => getEligibilityErrors(eligibilityRow, errorObject),
      eligibility.eligibilityRows
    );
  }

  if (!isBlank(R.prop('eligibilityRowsOperator', eligibility))) {
    return errorObject;
  }

  const populationErrors = validateSimpleEligibility({
    variable: R.prop('variable', eligibility),
    operator: R.prop('operator', eligibility),
    values: R.prop('values', eligibility),
  });

  if (!isBlank(populationErrors)) {
    errorObject[eligibility.uid] = populationErrors;
  }

  return errorObject;
};

export const validateEligibility = (eligibilityObj) => {
  const { eligibility } = eligibilityObj;

  const errors = getEligibilityErrors(eligibility);
  return isBlank(rejectEmptyValues(R.values(errors))) ? {} : errors;
};

export default dispatchError;
