"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var StyledFunction = _styledComponents["default"].div.withConfig({
  displayName: "Functionstyle__StyledFunction",
  componentId: "sc-105pmqk-0"
})(["", ";"], function (props) {
  var _props$theme = props.theme,
    palette = _props$theme.palette,
    spacing = _props$theme.spacing,
    genericBorderRadius = _props$theme.extra.genericBorderRadius;
  var alternate = props.alternate,
    disabled = props.disabled;
  return (0, _styledComponents.css)(["background-color:", ";> .formula-group-operator{background:", ";border:1px solid ", ";color:", ";font-weight:bold;justify-content:center;min-height:", ";outline:none;position:relative;text-align:center;border-radius:", " 0 0 ", ";display:flex;padding:0 ", ";align-items:center;", "}> .formula-group-elements{width:100%;border:1px solid ", ";padding:20px 8px;border-left:0;strong.formula-comma{font-size:40px;margin:0 10px;position:relative;bottom:-10px;}}&.formula-group{display:flex;flex-direction:row;}"], alternate ? palette.basic.white : palette.gray.echo, alternate ? palette.accent.delta : palette.accent.foxtrot, palette.accent.alpha, palette.primary, spacing.xxLarge, genericBorderRadius, genericBorderRadius, spacing.xTiny, disabled && (0, _styledComponents.css)(["background:", ";border:0;color:", ";"], palette.gray.charlie, palette.basic.white), palette.gray.charlie);
});
StyledFunction.displayName = 'StyledFunction';
var _default = StyledFunction;
exports["default"] = _default;