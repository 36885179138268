import React from 'react';
import classNames from 'classnames';
import { Checkbox, Radio } from '@trs/components';
import { toLower, decimalTransform } from '@trs/utils';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import StyledOptionTile from './OptionTile.style';
import EditableField from '../../../../../../../common/EditableField/EditableField';
import { NUMERIC_TYPES } from '../../../../../../../common/EditableField/helpers';
import hasSlider from './helpers';
import { TILE_TYPES, RADIO_ICONS } from '../../../../constants';
import RewardCaption from './RewardCaption';
import { TextOnly } from '../../../../../../../common/Text';

const OptionTile = ({
  currency,
  disabled,
  flexOptions,
  isSelected,
  name,
  onChange,
  payload,
  toggleIsEditing,
  type,
  description,
  descriptionBanner,
  descriptionMedia,
  id,
  isFirst,
  isIntegerOnly,
  template,
  budgetLabel,
  hasDecimals,
}) => {
  const showSlider = hasSlider(
    payload.min,
    payload.max,
    R.prop('isChangeableByEmployee', flexOptions)
  );

  const isIncludedInBudget = R.prop('isIncludedInBudget', flexOptions);

  const isPercentage = R.path(['type'], payload) === NUMERIC_TYPES.percentage || currency === '%';

  return (
    <StyledOptionTile
      isSelected={isSelected}
      type={type}
      className={isFirst ? 'option-tile-first' : 'option-tile'}
    >
      <div
        className={classNames({
          'option-tile-action': true,
          'option-tile-action--selected': isSelected,
        })}
      >
        {toLower(type) === TILE_TYPES.RADIO ? (
          <Radio
            checked={isSelected}
            disabled={disabled}
            iconChecked={RADIO_ICONS.iconChecked}
            iconUnchecked={RADIO_ICONS.iconUnchecked}
            naked
            name={name}
            id={id}
            onChange={onChange}
          />
        ) : (
          <Checkbox
            className="option-tile-checkbox"
            onChange={onChange}
            checked={isSelected}
            disabled={disabled}
          />
        )}
      </div>
      <div className="option-tile-content">
        <RewardCaption
          showSlider={showSlider}
          {...payload}
          name={name}
          description={description}
          descriptionBanner={descriptionBanner}
          descriptionMedia={descriptionMedia}
          template={template}
          isPercentage={isPercentage}
          textValue={R.prop('textValue', payload)}
        />
        <EditableField
          id={id}
          onBlur={isIntegerOnly ? undefined : decimalTransform}
          showSlider={showSlider}
          onChange={onChange}
          key={id}
          disabled={disabled}
          toggleIsEditing={toggleIsEditing}
          isIntegerOnly={isIntegerOnly}
          payload={{
            ...payload,
            value: isIntegerOnly
              ? Math.round(R.prop('value', payload))
              : decimalTransform(R.prop('value', payload)),
            min: R.prop('displayMin', payload),
            max: R.prop('displayMax', payload),
          }}
          currency={currency}
          editLabel={TextOnly({ path: 'generic.BUTTON_CHANGE' })}
          isEditable={R.prop('isChangeableByEmployee', flexOptions)}
          template={template}
          isPercentage={isPercentage}
          hasDecimals={hasDecimals}
        />
        {isIncludedInBudget && (
          <span className="option-tile-content--highlight">
            {TextOnly({
              path: 'flexibleRewards.AVAILABLE_OPTIONS_INCLUDED_CUSTOM_LABEL',
              transform: (label) => label.replace('%BUDGET%', budgetLabel),
            })}
          </span>
        )}
      </div>
    </StyledOptionTile>
  );
};

OptionTile.propTypes = {
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  toggleIsEditing: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  payload: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    min: PropTypes.number,
    max: PropTypes.number,
  }).isRequired,
  currency: PropTypes.string,
  flexOptions: PropTypes.shape({
    isChangeableByEmployee: PropTypes.bool,
    isIncludedInBudget: PropTypes.bool,
    isSelectedByDefault: PropTypes.bool,
  }).isRequired,
  description: PropTypes.string,
  isFirst: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  isIntegerOnly: PropTypes.bool,
  template: PropTypes.string.isRequired,
  budgetLabel: PropTypes.string.isRequired,
  descriptionBanner: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
    type: PropTypes.string,
  }),
  descriptionMedia: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
      type: PropTypes.string,
    })
  ),
  hasDecimals: PropTypes.bool,
};

OptionTile.defaultProps = {
  disabled: false,
  description: '',
  isIntegerOnly: false,
  descriptionBanner: null,
  descriptionMedia: null,
  currency: '',
  hasDecimals: false,
};

export default React.memo(OptionTile);
