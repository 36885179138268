"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = function _default(typographyBase) {
  return {
    size: {
      small: "".concat(0.625 * typographyBase, "px"),
      misc: "".concat(0.75 * typographyBase, "px"),
      table: "".concat(0.875 * typographyBase, "px"),
      icon: "".concat(1 * typographyBase, "px"),
      paragraph: "".concat(1 * typographyBase, "px"),
      base: "".concat(typographyBase, "px"),
      paragraphPlus: "".concat(1.25 * typographyBase, "px"),
      paragraphMedium: "".concat(1.375 * typographyBase, "px"),
      paragraphBig: "".concat(2 * typographyBase, "px"),
      h5: "".concat(0.875 * typographyBase, "px"),
      h4: "".concat(1.125 * typographyBase, "px"),
      h3: "".concat(1.25 * typographyBase, "px"),
      h2: "".concat(1.5 * typographyBase, "px"),
      h1: "".concat(2.25 * typographyBase, "px")
    },
    font: {
      primary: "'Matter', sans-serif"
    },
    lineHeight: {
      "default": 1.2,
      regular: 1.33,
      medium: 1.43,
      high: 1.5,
      misc: "".concat(1 * typographyBase, "px"),
      paragraphSmall: "".concat(1.25 * typographyBase, "px"),
      paragraph: "".concat(1.5 * typographyBase, "px"),
      h4: "".concat(2 * typographyBase, "px"),
      h3: "".concat(2.5 * typographyBase, "px"),
      h2: "".concat(3 * typographyBase, "px"),
      h1: "".concat(3.5 * typographyBase, "px")
    },
    weight: {
      thin: 300,
      normal: 400,
      thick: 500,
      semiBold: 600,
      bold: 700,
      strong: 900
    }
  };
};
exports["default"] = _default;