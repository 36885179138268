const initialState = {
  fetchingCategories: true,
  fetchingShares: true,
  rewardsCategories: {
    categories: [],
    totalValue: 0,
    extraCategories: [],
  },
  categoryDrilldown: [],
  redirectToRoot: false,
  shareData: {
    shares: [],
    granted: null,
  },
  shareSummary: {
    shares: [],
    error: null,
  },
  ltiPlans: {
    currency: null,
    years: [],
    plans: [],
    total: [],
  },
  rewardDetailsError: null,
  isLoadingLtiPlans: false,
  isLoadingDetails: true,
  personalDetailsSetting: [],
};

export default initialState;
