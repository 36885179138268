"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
var _utils = require("@trs/utils");
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var StyledStatusPill = _styledComponents["default"].span.withConfig({
  displayName: "StatusPillstyle__StyledStatusPill",
  componentId: "sc-437tcc-0"
})(["", ";"], function (props) {
  var _props$theme = props.theme,
    spacing = _props$theme.spacing,
    palette = _props$theme.palette,
    _props$theme$extra = _props$theme.extra,
    rewardsStatus = _props$theme$extra.rewardsStatus,
    genericStatus = _props$theme$extra.genericStatus;
  var size = props.size,
    status = props.status;
  var pillCircleColor = rewardsStatus[(0, _utils.toLower)(status)] || genericStatus[(0, _utils.toLower)(status)] || palette.basic.black;
  return (0, _styledComponents.css)(["font-size:", "px;margin-bottom:", ";.pill-name,.pill-circle,.pill-value{font-size:", "px;display:inline-block;padding:0;}.pill-circle{background-color:", ";border-radius:", "px;height:", "px;margin:0 0.5em 0.15em 0;vertical-align:middle;width:", "px;}.pill-value{font-weight:bold;vertical-align:baseline;}"], size, spacing.small, size, pillCircleColor, size, size, size);
});
StyledStatusPill.displayName = 'StyledStatusPill';
var _default = StyledStatusPill;
exports["default"] = _default;