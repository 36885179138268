import { combineReducers } from 'redux';
import audit from '../components/audit/reducer';
import dashboard from '../components/dashboard/reducer';
import dataValidationWidget from '../components/dashboard/components/DataValidation/reducer';
import feedbackStatsWidget from '../components/dashboard/components/FeedbackStats/reducer';
import mobilityWidget from '../components/dashboard/components/Mobility/reducer';

export default combineReducers({
  audit,
  dashboard,
  dataValidationWidget,
  feedbackStatsWidget,
  mobilityWidget,
});
