import APP_CONFIG from '@trs/config';

const { breakpointsNumeric } = APP_CONFIG;
const MAX_WIDTH = breakpointsNumeric.xDesktop;
export const ORIENTATIONS = {
  landscape: 'landscape',
  portrait: 'portrait',
};
export const getOrientation = (offsetWidth = 0) =>
  offsetWidth > breakpointsNumeric.desktop ? ORIENTATIONS.landscape : ORIENTATIONS.portrait;

export const getSnapshotOptions = ({
  ignoredElements = [],
  offsetWidth,
  pdfName,
  orientation,
  beforeDownload,
}) => ({
  margin: 1,
  filename: `${pdfName}.pdf`,
  image: {
    type: 'jpeg',
    quality: 1,
  },
  html2canvas: {
    logging: false,
    scale: 2,
    width: MAX_WIDTH,
    ignoreElements: (element) => {
      return (
        (element && element.classList && element.classList.contains('card-link')) ||
        ignoredElements.includes(element.tagName)
      );
    },
    onclone: (canvasDoc) => {
      const canvasDocContainer = canvasDoc.getElementsByClassName('html2pdf__container')[0];
      canvasDocContainer.style.width = `${(MAX_WIDTH * 2.54) / 96}cm`;
      return beforeDownload(canvasDocContainer);
    },
  },
  jsPDF: {
    unit: 'cm',
    format: 'a4',
    orientation: orientation || getOrientation(offsetWidth),
  },
});

export default {
  getOrientation,
  getSnapshotOptions,
};
