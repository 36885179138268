const initialState = {
  isLoading: true,
  invalidEmployeesCount: 0,
  totalEmployeesCount: 0,
  invalidFieldsCount: 0,
  generatingCsv: false,
  isCsvAvailable: false,
};

export default initialState;
