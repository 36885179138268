import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Text from 'modules/common/Text';
import StyledProvider from './Provider.style';

const Provider = ({ name }) => (
  <StyledProvider>
    <Text
      path="notifications.NOTIFICATION_DATA_LOADING_PROVIDER"
      transform={(label = '') => label.replace('%NAME%', name)}
    />
  </StyledProvider>
);

Provider.propTypes = {
  name: PropTypes.string.isRequired,
};

export default memo(Provider);
