"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validateViaKey = exports.stringToLocale = exports.sortByPropCaseInsensitive = exports.shortenText = exports.scrollTo = exports.removeNumberFormatting = exports.ramdaMapIndexed = exports.parseURLFromText = exports.noop = exports.media = exports.isTouchDevice = exports.isSwipeDisabled = exports.isMobile = exports.isLengthSmallerThan = exports.isLengthGreaterThan = exports.hasSpecialCharacters = exports.hasOnlyWhiteSpaces = exports.hasOnlySpecialCharacters = exports.hasDecimalsWithinValue = exports.getSize = exports.getNumberSeparatorsForLocale = exports.getErrorStatus = exports.getErrorMessage = exports.getCountryCode = exports.formatNumber = exports.floor = exports.decimalTransform = exports.debounce = exports.convertDecimalToPercentage = exports.ceil = exports.betaFeaturePrefix = exports.actionCreators = exports.WARNING_SIGN_OUT_TIME_SECONDS = exports.TYPES = exports.SIZE_UNIT = exports.REGEXP_MAP = exports.FILE_ENCODING = exports.DEFAULT_TIME_OUT = void 0;
var R = _interopRequireWildcard(require("ramda"));
var _styledComponents = require("styled-components");
var _config = _interopRequireDefault(require("@trs/config"));
var _objects = require("../objects");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var breakpoints = _config["default"].breakpoints,
  breakpointsNumeric = _config["default"].breakpointsNumeric,
  UNITS = _config["default"].UNITS,
  MAX_DECIMALS = _config["default"].MAX_DECIMALS;
var DEFAULT_TIME_OUT = 900; // seconds
exports.DEFAULT_TIME_OUT = DEFAULT_TIME_OUT;
var WARNING_SIGN_OUT_TIME_SECONDS = 120;
exports.WARNING_SIGN_OUT_TIME_SECONDS = WARNING_SIGN_OUT_TIME_SECONDS;
var REGEXP_MAP = {
  variableName: /^[a-zA-Z]+([ ]{0,1}[a-zA-Z0-9-_]+)*[ ]{0,1}$/,
  // alphanumeric, dash, space and underscore regular expression
  excellFile: /(\.xlsx)$/i
};
exports.REGEXP_MAP = REGEXP_MAP;
var FILE_ENCODING = 'UTF-8';
exports.FILE_ENCODING = FILE_ENCODING;
var SIZE_UNIT = {
  kb: 'KB',
  mb: 'MB'
};
exports.SIZE_UNIT = SIZE_UNIT;
var betaFeaturePrefix = 'betaFeature';
exports.betaFeaturePrefix = betaFeaturePrefix;
var TYPES = {
  OFF: 'OFF',
  ON: 'ON'
};
exports.TYPES = TYPES;
var getErrorMessage = function getErrorMessage(error) {
  return R.path(['response', 'data', 'errors', 0, 'message'], error) || R.prop('message', error);
};
exports.getErrorMessage = getErrorMessage;
var getErrorStatus = function getErrorStatus(error) {
  return R.path(['response', 'status'], error);
};
exports.getErrorStatus = getErrorStatus;
var media = Object.keys(breakpoints).reduce(function (accumulator, breakpoint) {
  return R.assoc(breakpoint, function () {
    return (0, _styledComponents.css)(["@media (min-width:", "){", ";}"], R.prop(breakpoint, breakpoints), _styledComponents.css.apply(void 0, arguments));
  }, accumulator);
}, {});
exports.media = media;
var debounce = function debounce(func, wait, immediate) {
  var timeout;
  return function callable() {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    var context = this;
    var later = function laterCallable() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};
exports.debounce = debounce;
var isLengthGreaterThan = R.curry(function (length, item) {
  return R.gt(R.length(item), length);
});
exports.isLengthGreaterThan = isLengthGreaterThan;
var isLengthSmallerThan = R.curry(function (length, item) {
  return R.lt(R.length(item), length);
});
exports.isLengthSmallerThan = isLengthSmallerThan;
var isTouchDevice = function isTouchDevice() {
  return !!(typeof window !== 'undefined' && ('ontouchstart' in window || window.DocumentTouch && typeof document !== 'undefined' && document instanceof window.DocumentTouch)) || !!(typeof navigator !== 'undefined' && (navigator.maxTouchPoints || navigator.msMaxTouchPoints));
};
exports.isTouchDevice = isTouchDevice;
var parseURLFromText = function parseURLFromText(text) {
  return typeof text === 'string' ? "".concat(text, " ").replace(/(http|https|www|ftp)+[:.].*?(?=\s)/gi, function (item) {
    return "<a href=\"".concat(item, "\" target=\"_blank\">").concat(item, "</a>");
  }) : '';
};
exports.parseURLFromText = parseURLFromText;
var sortByPropCaseInsensitive = function sortByPropCaseInsensitive(prop) {
  return R.sortBy(R.compose(R.toLower, R.toString, R.prop(prop)));
};
exports.sortByPropCaseInsensitive = sortByPropCaseInsensitive;
var scrollTo = function scrollTo() {
  var offset = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  if (typeof offset === 'number') return window.scrollTo({
    top: offset,
    behavior: 'smooth'
  });
  if (typeof offset === 'string') {
    var elem = document.querySelector(offset);
    if (!elem) return 0;
    return window.scrollTo({
      top: elem.getBoundingClientRect().top + window.scrollY,
      behavior: 'smooth'
    });
  }
  return 0;
};
exports.scrollTo = scrollTo;
var validateViaKey = function validateViaKey() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    key = _ref.key,
    value = _ref.value;
  return R.prop(key, REGEXP_MAP) && value && REGEXP_MAP[key].test(value);
};
exports.validateViaKey = validateViaKey;
var getSize = function getSize(unit, size) {
  var unitIndex = UNITS.indexOf(unit.toLowerCase()) + 1;
  return size / Math.pow(1024, unitIndex);
};
exports.getSize = getSize;
var actionCreators = function actionCreators(type) {
  for (var _len2 = arguments.length, argNames = new Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
    argNames[_key2 - 1] = arguments[_key2];
  }
  return function () {
    for (var _len3 = arguments.length, args = new Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
      args[_key3] = arguments[_key3];
    }
    var action = {
      type: type
    };
    argNames.forEach(function (arg, index) {
      action[argNames[index]] = args[index];
    });
    return action;
  };
};
exports.actionCreators = actionCreators;
var noop = function noop() {};
exports.noop = noop;
var getCountryCode = function getCountryCode(_ref2) {
  var countries = _ref2.countries,
    country = _ref2.country;
  return R.prop('code')(R.find(R.propEq('value', country))(countries));
};

/* 
  toFixed handles rounding wrongfully hence this method
  of using Math.round of the value multiplied by 10 to the power
  of decimals.
*/
exports.getCountryCode = getCountryCode;
var decimalTransform = function decimalTransform(value) {
  var decimals = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : MAX_DECIMALS;
  if (!decimals) return Math.round(value);
  var multiplier = Math.pow(10, decimals);
  return (Math.round(value * multiplier) / multiplier).toFixed(decimals);
};
exports.decimalTransform = decimalTransform;
var floor = function floor(value) {
  return (0, _objects.isBlank)(value) ? null : Math.floor(value);
};
exports.floor = floor;
var ceil = function ceil(value) {
  return (0, _objects.isBlank)(value) ? null : Math.ceil(value);
};
exports.ceil = ceil;
var ramdaMapIndexed = R.addIndex(R.map);
exports.ramdaMapIndexed = ramdaMapIndexed;
var convertDecimalToPercentage = function convertDecimalToPercentage(value) {
  var fixedDecimals = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  return parseFloat((value * 100).toFixed(fixedDecimals));
};
exports.convertDecimalToPercentage = convertDecimalToPercentage;
var isSwipeDisabled = function isSwipeDisabled() {
  return !isTouchDevice() || window.innerWidth >= 768;
};
exports.isSwipeDisabled = isSwipeDisabled;
var isMobile = window.innerWidth >= breakpointsNumeric.mobile && window.innerWidth <= breakpointsNumeric.tabletLandscape;
exports.isMobile = isMobile;
var hasSpecialCharacters = function hasSpecialCharacters(text) {
  var format = /[%^+[\]]/;
  return format.test(text);
};
exports.hasSpecialCharacters = hasSpecialCharacters;
var hasOnlySpecialCharacters = function hasOnlySpecialCharacters(text) {
  var pattern = /^[^a-zA-Z0-9]+$/;
  return pattern.test(text);
};
exports.hasOnlySpecialCharacters = hasOnlySpecialCharacters;
var hasDecimalsWithinValue = function hasDecimalsWithinValue(value) {
  if (typeof value === 'string') return value.includes('.');
  return !Number.isInteger(value);
};
exports.hasDecimalsWithinValue = hasDecimalsWithinValue;
var hasOnlyWhiteSpaces = function hasOnlyWhiteSpaces(string) {
  return /^\s+$/.test(string);
};

// slice text and do not break words
exports.hasOnlyWhiteSpaces = hasOnlyWhiteSpaces;
var shortenText = function shortenText(text, max) {
  return text && text.length > max ? text.split('\n').join('\n ').split('&nbsp;').join('&nbsp; ').slice(0, max).split(' ').slice(0, -1).join(' ') : text;
};
exports.shortenText = shortenText;
var getNumberSeparatorsForLocale = function getNumberSeparatorsForLocale() {
  var locale = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : undefined;
  var referenceNumber = 123456.7;
  var formatted = referenceNumber.toLocaleString(locale);
  return {
    specialNumberFormat: formatted.length === 10,
    thousandSeparator: formatted.slice(-6, -5),
    decimalSeparator: formatted.slice(-2, -1)
  };
};
exports.getNumberSeparatorsForLocale = getNumberSeparatorsForLocale;
var removeNumberFormatting = function removeNumberFormatting(number) {
  var locale = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : undefined;
  if ((0, _objects.isBlank)(number)) return '';
  var sep = getNumberSeparatorsForLocale(locale);
  var thousandRegex = new RegExp(sep.thousandSeparator.match(/\s/) ? /\s/ : sep.thousandSeparator, 'g');
  return number.replace(thousandRegex, '').replace(sep.decimalSeparator, '.');
};
exports.removeNumberFormatting = removeNumberFormatting;
var stringToLocale = function stringToLocale(number) {
  var locale = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : undefined;
  var sep = getNumberSeparatorsForLocale(locale);
  var startingPos = number.length - 1;
  var thousandDigitsCount = 0;
  var formattedNumber = number;
  var decimalPos = number.indexOf('.');
  if (decimalPos !== -1) {
    startingPos = decimalPos - 1;
    formattedNumber = formattedNumber.replace('.', sep.decimalSeparator);
  }
  for (var index = startingPos; index >= 0; index -= 1) {
    thousandDigitsCount += 1;
    if (thousandDigitsCount === 3 && index !== 0 && formattedNumber[index - 1] !== '-') {
      thousandDigitsCount = sep.specialNumberFormat ? 1 : 0;
      formattedNumber = "".concat(formattedNumber.slice(0, index)).concat(sep.thousandSeparator).concat(formattedNumber.slice(index));
    }
  }
  return formattedNumber;
};
exports.stringToLocale = stringToLocale;
var formatNumber = function formatNumber(number) {
  var locale = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : undefined;
  var hasDecimals = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  if ((0, _objects.isBlank)(number)) return '';
  var transformedNumber = hasDecimals ? decimalTransform(number) : number;
  var value = typeof transformedNumber === 'number' ? transformedNumber.toString() : transformedNumber;
  return stringToLocale(value, locale);
};
exports.formatNumber = formatNumber;
validateViaKey.__docgenInfo = {
  "description": "",
  "methods": [],
  "displayName": "validateViaKey"
};