import { createAction } from 'redux-actions';
import * as R from 'ramda';
import { PUT, GET } from '../../../../../config/http';
import { benchmark } from '../../../../../config/api';
import { showBanner } from '../../../../../actions/globalActions';
import BM_STATUS, { dispatchError } from '../../common/helpers';
import { getBenchmarkingSteps, getNameFromCode } from './helpers';
import { loadGlobalIdentifiers } from '../../global/actions';

export const actionTypes = {
  BENCHMARKING_UPDATE_STEP_STATUS: 'BENCHMARKING_UPDATE_STEP_STATUS',
  BENCHMARKING_LOAD_STATUSES: 'BENCHMARKING_LOAD_STATUSES',
  BENCHMARKING_LOAD_MAIN_STATUSES: 'BENCHMARKING_LOAD_MAIN_STATUSES',
  BENCHMARKING_STATUSES_RESET: 'BENCHMARKING/STATUSES/RESET',
};

export const updateStepStatus = createAction(actionTypes.BENCHMARKING_UPDATE_STEP_STATUS);
export const loadSettingsStatuses = createAction(actionTypes.BENCHMARKING_LOAD_STATUSES);
export const loadMainSettingsStatuses = createAction(actionTypes.BENCHMARKING_LOAD_MAIN_STATUSES);
export const statusesReset = createAction(actionTypes.BENCHMARKING_STATUSES_RESET);

export const loadLocalBenchmarkingStatuses = (localSettingsId, isMain = false) => dispatch => {
  return dispatch(loadGlobalIdentifiers()).then(globalBody => {
    const globalBenchmarkingId = R.prop('id', globalBody);

    const baseUrl = `${
      benchmark.benchmarking
    }/${globalBenchmarkingId}/localSettings/${localSettingsId}`;

    if (isMain) {
      return dispatch(
        loadMainSettingsStatuses(
          GET(baseUrl).then(response => {
            const statusData = R.path(['data', 'response'], response);
            const countryCode = R.path(['data', 'response', 'countryCode'], response);
            const isManualSetup = R.path(['data', 'response', 'isManualSetup'], response);

            const countryName = getNameFromCode(countryCode)(globalBody);

            return { countryName, statusData, isManualSetup };
          })
        )
      );
    }

    return dispatch(
      loadSettingsStatuses(
        GET(baseUrl).then(response => {
          const statusData = R.path(['data', 'response'], response);
          return { statusData };
        })
      )
    );
  });
};

export const updateStatus = (payload, successMessage, step) => (dispatch, getState) => {
  const BENCHMARKING_STEPS = getBenchmarkingSteps();
  const { id: localSettingsId, etag: localSettingsEtag } = R.path(
    ['benchmarking', 'local', 'main', 'statusData'],
    getState()
  );
  const benchmarkingId = R.path(
    ['benchmarking', 'global', 'main', 'identifiers', 'id'],
    getState()
  );

  return dispatch(
    updateStepStatus(
      PUT(
        `${benchmark.benchmarking}/${benchmarkingId}/localSettings/${localSettingsId}/${
          BENCHMARKING_STEPS[step].url
        }`,
        payload,
        { ETag: localSettingsEtag }
      )
        .then(response => {
          dispatch(
            showBanner({
              type: 'success',
              content: successMessage,
            })
          );
          return {
            data: R.path(['data', 'response'], response),
          };
        })
        .catch(error => {
          dispatchError(error, dispatch);
          return Promise.reject(error);
        })
    )
  );
};

export const submitForApproval = step => dispatch => {
  const BENCHMARKING_STEPS = getBenchmarkingSteps();
  const payload = {
    statusName: BM_STATUS.SUBMITTED,
  };

  const successMessage = R.path([step, 'submitSuccessMessage'], BENCHMARKING_STEPS);

  return dispatch(updateStatus(payload, successMessage, step));
};

export const approveStep = step => dispatch => {
  const BENCHMARKING_STEPS = getBenchmarkingSteps();
  const payload = {
    statusName: BM_STATUS.APPROVED,
  };
  const successMessage = R.path([step, 'approveSuccessMessage'], BENCHMARKING_STEPS);

  return dispatch(updateStatus(payload, successMessage, step));
};

export const rejectStep = (step, message) => dispatch => {
  const BENCHMARKING_STEPS = getBenchmarkingSteps();
  const payload = {
    statusName: BM_STATUS.REJECTED,
    message,
  };

  const successMessage = R.path([step, 'rejectSuccessMessage'], BENCHMARKING_STEPS);

  return dispatch(updateStatus(payload, successMessage, step));
};
