import styled, { css } from 'styled-components';
import { media } from '@trs/utils';
import APP_CONFIG from '@trs/config';

const { breakpoints } = APP_CONFIG;
const StyledLayout = styled.div`
  ${props => {
    const { isFixed } = props;
    const { spacing } = props.theme;

    if (isFixed) {
      return css`
        width: ${breakpoints.desktop};
      `;
    }
    return css`
      margin: 0 auto;
      width: 100%;
      max-width: ${breakpoints.maxWidth};

      &.layout-wrapper {
        flex: 1;
        margin-bottom: ${spacing.xLarge};
        padding: 0 ${spacing.small};
        ${media.xDesktop`
          padding: 0;
        `}
      }

      &.layout-content {
        height: 100%;
      }
    `;
  }};
`;

StyledLayout.displayName = 'StyledLayout';
export default StyledLayout;

// To be added on the footer part : min-height: 70vh
