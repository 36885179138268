import actions from '../actions/types';

export const initialState = {
  pending: false,
};

function packageDetailsReducer(state = initialState, action = {}) {
  switch (action.type) {
    case actions.GET_PACKAGE_DETAILS_PENDING: {
      return {
        ...state,
        pending: true,
      };
    }
    case actions.GET_PACKAGE_DETAILS: {
      return {
        ...state,
        ...action.payload,
        pending: false,
      };
    }
    case actions.GET_PACKAGE_DETAILS_ERROR: {
      return {
        ...state,
        pending: false,
      };
    }
    default: {
      return state;
    }
  }
}

export default packageDetailsReducer;
