import {
  OnboardingRoot,
  FlexibleRewardRoot,
  ModellerRoot,
  ImpactAnalysisRoot,
  VariablesRoot,
  ArticlesRoot,
  RewardsRoot,
  ForeignExchangeRoot,
  RmDashboardRoot,
  UserManagementRoot,
  ExceptionsRoot,
  EmployeeStatementRoot,
  WorkspaceRoot,
  BenchmarkingRoot,
  ReportsRoot,
  BenefitsEnvelopeRoot,
  BenefitsEnvelopeAuditRoot,
  FixedCostsRoot,
  LtiPlansRoot,
} from './helpers/ModuleLoader';
import Homepage from './static/home';
import LoginRoot from '../modules/login/routes/Root';
import Logout from '../modules/login/routes/Logout';
import Renew from '../modules/login/routes/Renew';
import AccessDeniedPage from './static/accessDenied';
import NotAuthorizedPage from './static/notAuthorized';
import NoModulesAssignedPage from './static/noModulesAssigned';
import UnexpectedError from './static/unexpectedError';
import CallbackPage from '../config/userManager/callback';
import SubmissionPage from '../modules/flexibleRewards/components/submissionPage/components';
import LabelProvider from '../modules/cms/components/LabelProvider';
import ROUTE_PATHS from './routePaths';

export const privateRoutes = [
  {
    id: 'settings',
    path: ROUTE_PATHS.SETTINGS,
    component: OnboardingRoot,
    title: 'TRS - Settings',
  },
  {
    id: 'variables',
    path: ROUTE_PATHS.VARIABLES,
    component: VariablesRoot,
    title: 'TRS - Variables',
  },
  {
    id: 'rewards-view-impacts-view',
    path: ROUTE_PATHS.REWARDS_VIEW_IMPACT_VIEW,
    exact: true,
    component: LabelProvider(RewardsRoot, ['Rewards', 'Exceptions', 'Feedback', 'Modeller']),
    title: 'TRS - Rewards View Impacts View',
  },
  {
    id: 'rewards-edit-impacts-view',
    path: ROUTE_PATHS.REWARDS_EDIT_IMPACTS_VIEW,
    exact: true,
    component: LabelProvider(RewardsRoot, ['Rewards', 'Exceptions', 'Feedback', 'Modeller']),
    title: 'TRS - Rewards Edit Impacts View',
  },
  {
    id: 'articles',
    path: ROUTE_PATHS.ARTICLES,
    component: LabelProvider(ArticlesRoot, ['Articles']),
    title: 'TRS - Articles',
  },
  {
    id: 'rewards',
    path: ROUTE_PATHS.REWARDS,
    component: LabelProvider(RewardsRoot, ['Rewards', 'Exceptions', 'Feedback', 'Modeller']),
    title: 'TRS - Rewards',
  },
  {
    id: 'exceptions',
    path: ROUTE_PATHS.EXCEPTIONS,
    component: LabelProvider(ExceptionsRoot, ['Exceptions']),
    title: 'TRS - Exceptions',
  },
  {
    id: 'modeller',
    path: ROUTE_PATHS.MODEL,
    component: ModellerRoot,
    title: 'TRS - Model',
  },
  {
    id: 'impact',
    path: ROUTE_PATHS.REWARDS_IMPACT,
    component: LabelProvider(ImpactAnalysisRoot, ['Rewards']),
    title: 'TRS - Impact Analysis',
  },
  {
    id: 'employeeStatement',
    path: ROUTE_PATHS.EMPLOYEE_STATEMENT,
    component: EmployeeStatementRoot,
    title: 'TRS - Employee Statement',
  },
  {
    id: 'employeeStatementView',
    path: ROUTE_PATHS.EMPLOYEE_STATEMENT_VIEW,
    component: SubmissionPage,
    title: 'Employee Statement View',
  },
  {
    id: 'foreignExchange',
    path: ROUTE_PATHS.FOREIGN_EXCHANGE,
    component: ForeignExchangeRoot,
    title: 'TRS - Foreign Exchange',
  },
  {
    id: 'userManagement',
    path: ROUTE_PATHS.USERS,
    component: UserManagementRoot,
    title: 'TRS - User Management',
  },
  {
    id: 'flexibleRewards',
    path: ROUTE_PATHS.FLEXIBLE_REWARDS,
    component: LabelProvider(FlexibleRewardRoot, ['Rewards', 'FlexibleRewards']),
    title: 'TRS - Flexible Rewards',
  },
  {
    id: 'workspace',
    path: ROUTE_PATHS.WORKSPACE,
    component: LabelProvider(WorkspaceRoot, ['Workspace']),
    title: 'TRS - Workspace',
  },
  {
    id: 'benchmarking',
    path: ROUTE_PATHS.PLAN,
    component: LabelProvider(BenchmarkingRoot, ['Rewards', 'Benchmarking']),
    title: 'TRS - Benchmarking',
  },
  {
    id: 'rmDashboard',
    path: ROUTE_PATHS.RM_DASHBOARD,
    component: RmDashboardRoot,
    title: 'TRS - RM Dashboard',
  },
  {
    id: 'homepage',
    path: ROUTE_PATHS.HOME,
    component: Homepage,
    title: 'Total Rewards System',
    exact: true,
  },
  {
    id: 'reports',
    path: ROUTE_PATHS.REPORT,
    component: LabelProvider(ReportsRoot, ['FlexibleReports']),
    title: 'Report',
  },
  {
    id: 'benefitsEnvelope',
    path: ROUTE_PATHS.BENEFITS_ENVELOPE,
    title: 'TRS - Benefits Envelope',
    component: LabelProvider(BenefitsEnvelopeRoot, ['BenefitsEnvelope']),
  },
  {
    id: 'benefitsEnvelopeAudit',
    path: ROUTE_PATHS.BENEFITS_ENVELOPE_AUDIT,
    title: 'TRS - Benefits Envelope Audit',
    component: LabelProvider(BenefitsEnvelopeAuditRoot, ['BenefitsEnvelopeAudit']),
  },
  {
    id: 'fixedCosts',
    path: ROUTE_PATHS.FIXED_COSTS,
    component: LabelProvider(FixedCostsRoot, ['FixedCosts']),
    title: 'TRS - Fixed Costs',
  },
  {
    id: 'ltiPlans',
    path: ROUTE_PATHS.LTI_PLANS,
    component: LabelProvider(LtiPlansRoot, ['LtiPlans']),
    title: 'TRS - LTI Plans',
  },
];

export const publicRoutes = [
  {
    id: 'login',
    path: ROUTE_PATHS.LOGIN,
    component: LoginRoot,
    title: 'TRS - Authenticate',
  },
  {
    id: 'logout',
    path: ROUTE_PATHS.LOGOUT,
    component: Logout,
    title: 'TRS - Authenticate',
  },
  {
    id: 'renew',
    path: ROUTE_PATHS.RENEW,
    component: Renew,
    title: 'TRS - Authenticate',
  },
  {
    id: 'callback',
    path: ROUTE_PATHS.CALLBACK,
    component: CallbackPage,
    title: 'TRS-callback',
  },
  {
    id: '401-page',
    path: ROUTE_PATHS.UNAUTHORIZED,
    component: NotAuthorizedPage,
    title: 'TRS - Unauthorized',
  },
  {
    id: 'no-modules',
    path: ROUTE_PATHS.NO_MODULE,
    component: NoModulesAssignedPage,
    title: 'TRS - No Modules',
  },
  {
    id: 'access-denied',
    path: ROUTE_PATHS.ACCESS_DENIED,
    component: AccessDeniedPage,
    title: 'TRS - Access Denied',
  },
  {
    id: 'error',
    path: ROUTE_PATHS.ERROR,
    component: UnexpectedError,
    title: 'TRS - Error',
  },
];
