import styled, { css } from 'styled-components';
import { media } from '@trs/utils';

const StyledLoginPage = styled.section`
  ${props => {
    const { spacing } = props.theme;

    return css`
      padding: 0 ${spacing.tiny} ${spacing.large};


      ${media.mobileLandscape`
        padding: 0;
      `}

      .login-form-group {
        width: auto;

        label {
          display: block;
          position: relative;
          left: 0;
          transform: none;
          margin-bottom: ${spacing.base};
        }

        &:first-of-type {
          margin-top: ${spacing.large};
        }

        &:last-child {
          text-align: center;

          ${media.tablet`
            text-align: right;
          `}
        }

        ${media.mobileLandscape`
          width: 70%;

          &:first-of-type {
            margin-top: ${spacing.xLarge};
            margin-right: ${spacing.base};
          }

          label {
            position: absolute;
            left: -${spacing.xLarge};
            transform: translateX(-100%);
          }
        `}

        ${media.tablet`
          margin-right: 115px;
          width: 370px;

          &:first-of-type {
            margin-right: 115px;
          }
        `}

        ${media.tabletLandscape`
          margin-right: auto;

          &:first-of-type {
            margin-right: auto;
          }
        `}
      }
`;
  }};
`;

StyledLoginPage.displayName = 'StyledLoginPage';
export default StyledLoginPage;
