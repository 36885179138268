import React from 'react';
import * as R from 'ramda';
import { MODULES_WITH_NOTIFICATIONS } from 'modules/notifications/constants';
import NotificationsIcon from 'modules/notifications/components/icon/NotificationsIcon';
import { shouldHideFeature, matchRoles } from 'modules/common/helpers';
import userModules, { routes } from '../userModules';
import { TextOnly } from '../../../modules/common/Text';
import system from './system';
import rewardManager from './rewardManager';
import profiles from './profiles';
import modeller from './modeller';
import FEATURE_TOGGLE_KEYS from '../../featureToggleKeys';
import reports from './reports';

export const getMenuModel = (featureToggles = [], modules = {}) => [
  {
    title: TextOnly({ path: 'header.DASHBOARD' }),
    key: [
      userModules.AUDIT,
      userModules.MIMIC_EMPLOYEE,
      userModules.REWARDS_MANAGEMENT,
      userModules.REPORTS,
      userModules.MODELLER,
      userModules.FLEXIBLE_MANAGEMENT,
      userModules.EXCEPTIONS_MANAGEMENT,
      userModules.USER_MANAGEMENT,
      userModules.SETTINGS,
      userModules.FX_MANAGEMENT,
      userModules.WORKSPACE,
      userModules.ARTICLES_MANAGEMENT,
      userModules.FIXED_COSTS_MANAGEMENT,
      userModules.LTI_PLANS_MANAGEMENT,
    ],
    items: [],
    route: '/home',
  },
  {
    title: TextOnly({ path: 'header.BENEFITS_ENVELOPE' }),
    key: [userModules.BENEFITS_ENVELOPE],
    items: [],
    route: '/benefits-envelope',
    hidden:
      !R.isEmpty(
        R.intersection(Object.keys(modules), [
          userModules.REWARDS_MANAGEMENT,
          userModules.EXCEPTIONS_MANAGEMENT,
          userModules.FX_MANAGEMENT,
          userModules.FLEXIBLE_MANAGEMENT,
        ])
      ) || !matchRoles([userModules.BENEFITS_ENVELOPE], modules),
  },
  ...rewardManager({ authorizedModules: modules }),
  ...modeller(),
  ...reports(),
  {
    title: TextOnly({ path: 'header.BENCHMARKING' }),
    key: [userModules.BENCHMARKING],
    items: [],
    route: '/plan',
    hidden: shouldHideFeature(FEATURE_TOGGLE_KEYS.BENCHMARKING, featureToggles),
  },
  {
    title: TextOnly({ path: 'header.WORKSPACE' }),
    key: [userModules.WORKSPACE],
    items: [],
    route: '/workspace',
  },
  ...system(),
  {
    title: TextOnly({ path: 'header.NOTIFICATIONS' }),
    key: MODULES_WITH_NOTIFICATIONS,
    items: [],
    itemProps: {
      component: <NotificationsIcon title={TextOnly({ path: 'header.NOTIFICATIONS' })} />,
      align: 'align-right',
    },
  },
  ...profiles(),
];

export const LANDING_PAGES = {
  [userModules.EMPLOYEE_DASHBOARD]: '/employee-statement',
  [userModules.EXCEPTIONS_MANAGEMENT]: '/exceptions',
  [userModules.FLEXIBLE_MANAGEMENT]: '/flexible-rewards',
  [userModules.BENEFITS_ENVELOPE]: '/benefits-envelope',
  [userModules.FX_MANAGEMENT]: '/foreign-exchange',
  [userModules.AUDIT]: '/home',
  [userModules.MIMIC_EMPLOYEE]: '/home',
  [userModules.MODELLER]: '/model/reward-package',
  [userModules.REPORTS]: '/home',
  [userModules.REWARDS_MANAGEMENT]: '/rewards',
  [userModules.USER_MANAGEMENT]: '/users',
  [userModules.WORKSPACE]: '/workspace',
  [userModules.BENCHMARKING]: '/plan',
  [userModules.SETTINGS]: '/settings',
  [userModules.DATA_VALIDATION]: '/home',
  RM_DASHBOARD: '/home',
  NO_MODULES: '/no-modules',
  ACCESS_DENIED: '/access-denied',
};

const DASHBOARD_PAGES = ['/', '/home'];

export const hasRouteAccess = (authorizedModules, nextPath) => {
  const module = R.find(R.propEq('route', nextPath))(routes);
  const moduleAccessKeys = R.prop('access', module) || [];

  return (
    DASHBOARD_PAGES.includes(nextPath) ||
    (!R.isEmpty(moduleAccessKeys) &&
      !R.isEmpty(R.intersection(R.keys(authorizedModules), moduleAccessKeys)))
  );
};
