import Big from 'big.js';
import * as R from 'ramda';
import { processDate, toLower } from '@trs/utils';
import { DATE_FORMAT, GLOBAL_KEYWORD, GENERAL_STATUSES_KEYS } from '@trs/config';
import { RESPONSE_DISTRIBUTION_TYPE, DEFAULT_TOTAL_DISTRIBUTION } from 'modules/ltiPlans/constants';

export const isCalculating = (status) => status === GENERAL_STATUSES_KEYS.STATUS_CALCULATING;

export const hasLocalAccess = ({ authorizedModules, countryCode }) => {
  return (
    !R.includes(GLOBAL_KEYWORD, authorizedModules) &&
    toLower(countryCode) === toLower(GLOBAL_KEYWORD)
  );
};

export const getSaveBannerContent = ({ basicInformation, ltiPlanId, labels, genericLabels }) => {
  return (ltiPlanId ? genericLabels.BANNER_UPDATE : genericLabels.BANNER_CREATE).replace(
    '%FIELD%',
    `${labels.PAGE_GROUP} ${basicInformation.displayName}`
  );
};

export const getServerErrorLabel = (field, labels) => R.prop(field, labels);

/** compute total with decimal arithmetics **/
export const computeTotal = (payload) => {
  const totalResult = payload.reduce((result, entry) => {
    /** when only decimal separator is present **/
    const distributionValue = Number.isNaN(Number(entry.value)) ? 0 : Number(entry.value);
    return Big(result).plus(Big(distributionValue)).toString();
  }, 0);

  return Number(totalResult);
};

export const mapTableData = (planSteps = []) => {
  const mappedData = [];
  if (!R.isEmpty(planSteps)) {
    planSteps.forEach((step) => {
      mappedData.push({
        id: step.id,
        date: processDate(step.date, DATE_FORMAT),
        value: step.value,
      });
    });
  }

  return mappedData;
};

export const mapTableTotal = (distributionType, planSteps) => ({
  total: R.equals(distributionType, RESPONSE_DISTRIBUTION_TYPE.MANUAL)
    ? computeTotal(planSteps)
    : DEFAULT_TOTAL_DISTRIBUTION,
});

export const mapTableRowsWithIndexAndGetTotal = ({ type, steps }) => {
  const mapIndexed = R.addIndex(R.map);

  return {
    rows: mapIndexed((step, index) => {
      return { ...step, id: index };
    }, steps),
    ...mapTableTotal(type, steps),
  };
};
