import React from 'react';
import { pure } from 'recompose';

import { H1, GenericErrorPage } from '@trs/components';
import Layout from '../../../modules/common/layout/Layout';
import Text from '../../../modules/common/Text';

const NoModulesAssignedPage = () => (
  <Layout>
    <GenericErrorPage>
      <H1>
        <Text path="generic.NO_MODULES_ASSIGNED_TITLE" withPlatform />
      </H1>
      <p className="error-page-description">
        <Text path="generic.NO_ACCESS_TO_MODULE" withPlatform />
      </p>
      <p className="error-page-description">
        <Text path="generic.PLEASE_CONTACT_SUPPORT" withPlatform withHtml />
      </p>
    </GenericErrorPage>
  </Layout>
);

export default pure(NoModulesAssignedPage);
