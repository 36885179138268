import React, { PureComponent } from 'react';
import { Icon, Dialog, Button, Template, H3 } from '@trs/components';
import PropTypes from 'prop-types';
import { TextOnly } from 'modules/common/Text';
import { isBlank } from '@trs/utils';
import { badImageURL } from '../../../assets/images';
import StyledDescriptionDialog from './DescriptionDialog.style';

const TOOLTIP_ICON_SIZE = 10;

class DescriptionDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { isOpen: false };
  }

  toggle = () => {
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
  };

  handleClickOutside = () => this.setState({ isOpen: false });

  render() {
    const {
      content: { title, text, descriptionBanner, descriptionMedia },
      customButton,
      className,
      header,
      children,
      disabled,
      iconDescription,
    } = this.props;
    const { isOpen } = this.state;
    const hasHeader = !isBlank(header);

    return (
      <StyledDescriptionDialog className={className} isOpen={isOpen} hasHeader={hasHeader}>
        {isOpen && (
          <Dialog
            className="description-template-dialog"
            onClose={this.handleClickOutside}
            rightActions={
              <Button action={this.handleClickOutside} type="primary" id="globalDialogButtonCancel">
                Close
              </Button>
            }
          >
            <Icon
              icon="ion-close"
              className="dialog-close-icon"
              onClick={this.handleClickOutside}
              size={16}
            />
            {title && (
              <div className="dialog-title">
                <H3>{title}</H3>
              </div>
            )}
            {hasHeader && <div className="description-header">{header}</div>}
            <Template
              readOnly
              disabled
              className="dialog-template-container"
              text={text}
              banner={descriptionBanner}
              media={descriptionMedia}
              images={{
                badImageURL,
              }}
              recommendedMediaSizes={{
                Banner: {
                  width: 850,
                  height: 300,
                },
                Media: {
                  width: 400,
                  height: 270,
                },
              }}
            />
          </Dialog>
        )}
        {children || customButton.children ? (
          <Button
            disabled={disabled}
            action={this.toggle}
            type={customButton.type}
            className={customButton.className}
          >
            {customButton.children || children}
          </Button>
        ) : (
          <Icon
            icon="ion-information"
            className="icon-information"
            size={TOOLTIP_ICON_SIZE}
            onClick={this.toggle}
            aria-label={
              TextOnly({ path: 'employeeStatement.REWARD_DESCRIPTION' }) || iconDescription
            }
          />
        )}
      </StyledDescriptionDialog>
    );
  }
}

DescriptionDialog.propTypes = {
  disabled: PropTypes.bool,
  content: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
    descriptionBanner: PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
      type: PropTypes.string,
    }),
    descriptionMedia: PropTypes.arrayOf(
      PropTypes.shape({
        src: PropTypes.string,
        alt: PropTypes.string,
        type: PropTypes.string,
      })
    ),
  }),
  header: PropTypes.node,
  customButton: PropTypes.shape({
    type: PropTypes.oneOf(['primary', 'naked', 'link']),
    className: PropTypes.string,
    children: PropTypes.node,
  }),
  children: PropTypes.node,
  className: PropTypes.string,
  iconDescription: PropTypes.string,
};

DescriptionDialog.defaultProps = {
  disabled: false,
  content: {
    title: '',
    text: '',
    descriptionBanner: null,
    descriptionMedia: null,
  },
  header: null,
  customButton: {
    type: 'link',
    className: '',
    children: null,
  },
  children: null,
  className: '',
  iconDescription: '',
};

export default DescriptionDialog;
