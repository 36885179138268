import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { Link } from 'react-router-dom';
import { Button } from '@trs/components';

import * as globalActions from '../../../actions/globalActions';

const getAction = (action, actions) => R.path(action.split('.'), actions);

const MenuSublistItem = ({ elem, toggle, actions }) => {
  const renderSubElement = (subElement, subElementActions) => {
    return subElement.action ? (
      <Button
        action={R.compose(toggle, getAction(subElement.action, subElementActions))}
        className="menu-button"
      >
        {subElement.title}
      </Button>
    ) : (
      <Link className="dropdown-item" to={subElement.route} onClick={toggle}>
        {subElement.title}
      </Link>
    );
  };

  return (
    <ul className="sublist">
      {elem.items.map(
        (subelement) =>
          subelement && (
            <li
              key={subelement.title}
              className={subelement.id ? `subelement-${subelement.id}` : ''}
            >
              {!subelement.items.length ? (
                renderSubElement(subelement, actions)
              ) : (
                <ul className="sublist">
                  <li className="dropDown-subtitle">{subelement.title}</li>
                  {subelement.items.map((subsub) => (
                    <li key={subsub.title}>
                      <Link className="dropdown-item" to={subsub.route} onClick={toggle}>
                        {subsub.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          )
      )}
    </ul>
  );
};

MenuSublistItem.propTypes = {
  elem: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  toggle: PropTypes.func.isRequired,
  actions: PropTypes.shape({}).isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    globalActions: bindActionCreators(globalActions, dispatch),
  },
});

export default connect(null, mapDispatchToProps)(MenuSublistItem);
