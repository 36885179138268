"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
var R = _interopRequireWildcard(require("ramda"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var StyledRuleGroup = _styledComponents["default"].div.withConfig({
  displayName: "RuleGroupstyle__StyledRuleGroup",
  componentId: "sc-j16q25-0"
})(["", ";"], function (props) {
  var _props$theme = props.theme,
    palette = _props$theme.palette,
    spacing = _props$theme.spacing,
    genericBorderRadius = _props$theme.extra.genericBorderRadius;
  var isSelected = props.isSelected,
    groupOperator = props.groupOperator;
  var colors = {
    AND: {
      background: palette.accent.foxtrot,
      border: palette.accent.alpha,
      color: palette.primary
    },
    OR: {
      background: palette.accent.delta,
      border: palette.accent.alpha,
      color: palette.primary
    },
    IF: {
      background: palette.primary,
      border: palette.accent.alpha,
      color: palette.basic.white
    }
  };
  var groupHeadlineBackground = isSelected ? palette.secondary : R.path([groupOperator, 'background'], colors);
  return (0, _styledComponents.css)(["background-color:", ";border:1px solid ", ";margin:0 0 ", " ", ";border-radius:", " 0 0 ", ";", " > .group-operator{background:", ";color:", ";border:", ";justify-content:center;min-height:", ";min-width:40px;outline:none;position:relative;text-align:center;border-radius:", ";.operator-label{position:absolute;top:50%;font-weight:bold;transform:translateY(-50%);width:100%;font-size:14px;}.edit-menu{.rule-menu-list{margin:5px;}.close-button{margin-top:-5px;}}}> .group-elements{position:relative;width:100%;}&:last-child{margin-bottom:0px;}&.rule-group{display:flex;flex-direction:row;}"], palette.basic.white, isSelected ? palette.secondary : palette.basic.white, spacing.xTiny, spacing.xTiny, genericBorderRadius, genericBorderRadius, isSelected ? (0, _styledComponents.css)(["> .group-elements{> .simple-rule{border-right:0;&:first-child{border-top:0;}&:last-child{border-bottom:0;}}}"]) : '', groupHeadlineBackground, isSelected ? palette.basic.white : R.path([groupOperator, 'color'], colors), groupOperator !== 'IF' && !isSelected && "1px solid ".concat(R.path([groupOperator, 'border'], colors)), spacing.xxLarge, isSelected ? 0 : "".concat(genericBorderRadius, " 0 0 ").concat(genericBorderRadius));
});
StyledRuleGroup.displayName = 'StyledRuleGroup';
var _default = StyledRuleGroup;
exports["default"] = _default;