import * as R from 'ramda';
import { createAction } from 'redux-actions';
import { rewards } from '../../../../../config/api';
import { GET } from '../../../../../config/http';
import { dispatchError } from '../../../../benchmarking/components/common/helpers';
import actions from './types';
import mapPayload from './helpers';

const getPackageDetailsPending = () => ({
  type: actions.GET_PACKAGE_DETAILS_PENDING,
});

export const getPackageDetails = createAction(
  actions.GET_PACKAGE_DETAILS,
  packageDetails => packageDetails
);

const getPackageDetailsError = () => ({
  type: actions.GET_PACKAGE_DETAILS_ERROR,
});

export default function getPackageForEmail(packageId, handleError) {
  return (dispatch, getState) => {
    dispatch(getPackageDetailsPending());
    GET(`${rewards.employeeFlexibleStatement}/${packageId}`)
      .then(response => {
        const { flexibleRewards } = getState();
        const categories = R.path(['main', 'categories'], flexibleRewards);
        const payload = R.path(['data', 'response'], response);

        dispatch(getPackageDetails(mapPayload({ payload, categories })));
      })
      .catch(error => {
        handleError();
        dispatch(getPackageDetailsError());
        dispatchError(error, dispatch);
      });
  };
}
