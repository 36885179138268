import { isBlank, convertDecimalToPercentage } from '@trs/utils';
import * as R from 'ramda';

export const FIELDS_INCLUDED_IN_TOTAL = [
  'meritIncrease',
  'collectiveIncrease',
  'generalMandatoryIncrease',
];

export const computeTotal = row =>
  FIELDS_INCLUDED_IN_TOTAL.reduce((acc, field) => acc + Number(row[field]), 0);

const getFieldData = value => (isBlank(value) ? '' : value);

export const mapPayloadToTableData = payload =>
  payload.map(item => ({
    ...item,
    nonSalaryRelatedAllowanceIncrease: getFieldData(item.nonSalaryRelatedAllowanceIncrease),
    meritIncrease: getFieldData(item.meritIncrease),
    collectiveIncrease: getFieldData(item.collectiveIncrease),
    generalMandatoryIncrease: getFieldData(item.generalMandatoryIncrease),
    totalSalaryIncrease: getFieldData(item.totalSalaryIncrease),
  }));

export const mapAverage = data => {
  if (isBlank(data)) return {};

  const meritIncrease =
    convertDecimalToPercentage(R.path(['meritIncreaseAverage', 'value'], data), 2) || 0;
  const collectiveIncrease =
    convertDecimalToPercentage(R.path(['collectiveIncreaseAverage', 'value'], data), 2) || 0;
  const generalMandatoryIncrease =
    convertDecimalToPercentage(R.path(['generalMandatoryIncreaseAverage', 'value'], data), 2) || 0;
  const totalSalaryIncrease =
    convertDecimalToPercentage(R.path(['totalSalaryIncreaseAverage', 'value'], data), 2) || 0;

  return {
    meritIncrease,
    collectiveIncrease,
    generalMandatoryIncrease,
    totalSalaryIncrease,
  };
};
