import styled, { css } from 'styled-components';

const StyledItemName = styled.div`
  ${(props) => {
    const { isDirectory, withTooltip, theme } = props;

    return css`
      ${withTooltip &&
      css`
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
      `}

      a {
        ${isDirectory &&
        css`
          color: inherit;
          text-decoration: none;
          span:hover {
            text-decoration: underline;
          }
        `}
      }

      i {
        display: inline-block;
        margin-top: -2px;
        margin-right: ${theme.spacing.tiny};
      }
    `;
  }}
`;

StyledItemName.displayName = 'StyledItemName';
export default StyledItemName;
