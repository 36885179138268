"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var StyledSearchableDropdown = _styledComponents["default"].div.withConfig({
  displayName: "SearchableDropdownstyle__StyledSearchableDropdown",
  componentId: "sc-m7w1my-0"
})(["", ";"], function (props) {
  var isFullWidth = props.isFullWidth,
    isScrollable = props.isScrollable;
  var _props$theme = props.theme,
    spacing = _props$theme.spacing,
    typography = _props$theme.typography,
    palette = _props$theme.palette;
  return (0, _styledComponents.css)(["position:absolute;top:0;right:", ";bottom:0;left:0;max-width:", ";padding-top:2px;transform:translateY(101%);z-index:2;ul{", " max-height:280px;}&&& button{overflow:hidden;white-space:pre;text-overflow:ellipsis;&:not(span){&:hover,&.selected{.result-name,.result-details{color:", ";}}}}.result-name{margin-bottom:", ";font-family:", ";font-weight:500;color:", ";}.result-details{font-size:", ";font-weight:", ";color:", ";.result-id,.result-country{display:inline;background-color:inherit;}.result-id{margin-right:", ";}}"], isFullWidth ? '0' : 'auto', isFullWidth ? '100%' : '400px', isScrollable && (0, _styledComponents.css)(["overflow-y:scroll;"]), palette.secondary, spacing.xTiny, typography.font.primary, palette.gray.sigma, typography.size.table, typography.weight.thin, palette.basic.gray, spacing.basePlus);
});
StyledSearchableDropdown.displayName = 'StyledSearchableDropdown';
var _default = StyledSearchableDropdown;
exports["default"] = _default;