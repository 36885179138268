import { GET, PATCH, POST, RequestFactory } from 'config/http';
import * as R from 'ramda';
import { createAction } from 'redux-actions';
import { withCache } from 'modules/common/timedCache';
import { isBlank } from '@trs/utils';
import { encodeValuesInRules } from 'modules/common/helpers';
import types from './types';
import { rewards, articles, organization, cms } from '../../../config/api';
import dispatchError, { validateEligibility, validateSimpleEligibility } from './helpers';

const cachedGet = withCache(GET, 3600);

export const getVideoPlatforms = createAction(types.ARTICLES_VIDEO_PLATFORMS_ACCEPTED);
export const getSystemVariables = createAction(types.ARTICLES_SYSTEM_VARIABLES);
export const getEligibilityOperators = createAction(types.ARTICLES_ELIGIBILITY_OPERATORS);
export const getVariablesLookupData = createAction(types.ARTICLES_SYSTEM_VARIABLES_LOOKUP);
export const eligibilityChange = createAction(types.ARTICLES_ELIGIBILITY_CHANGE);
export const updateEligibilityError = createAction(types.ARTICLES_ELIGIBILITY_ERROR);
export const updateEligibilityData = createAction(types.UPDATE_ELIGIBILITY_DATA);
export const loadDependenciesPending = createAction(types.LOAD_ELIGIBILITY_PENDING);
export const loadDependenciesSuccess = createAction(types.LOAD_ELIGIBILITY_SUCCESS);
export const loadDependenciesError = createAction(types.LOAD_ELIGIBILITY_ERROR);

export const getArticlesCategories = createAction(types.ARTICLES_GET_CATEGORIES);
export const getArticlesCountries = createAction(types.ARTICLES_GET_COUNTRIES);
export const getArticlesList = createAction(types.ARTICLES_GET_LIST);

export const getFormInputChange = createAction(types.ARTICLES_FORM_INPUT_CHANGE);
export const etagUpdate = createAction(types.ARTICLES_ETAG_UPDATE);
export const redirectToRoot = createAction(types.ARTICLES_REDIRECT_TO_ROOT);

export const formSave = createAction(types.ARTICLES_FORM_SAVE);
export const formReset = createAction(types.ARTICLES_FORM_RESET);
export const storeReset = createAction(types.RESET_STORE);

export const loadVideoPlatforms = () => (dispatch) =>
  cachedGet(cms.videoPlatforms).then((response) => {
    dispatch(getVideoPlatforms(R.path(['data', 'response'], response)));
  });

export const loadEligibilityOperators = () => (dispatch) =>
  cachedGet(rewards.eligibilityOperators).then((response) =>
    dispatch(getEligibilityOperators(R.prop('data', response)))
  );

export const loadSystemVariables = () => (dispatch) =>
  cachedGet(rewards.systemVariables, { Pragma: 'no-cache' }).then((response) =>
    dispatch(getSystemVariables(R.prop('data', response)))
  );

export const loadSystemVariablesLookup = (url) => (dispatch) =>
  cachedGet(`${rewards.systemVariables}/${url}`)
    .then((response) =>
      dispatch(getVariablesLookupData({ lookupData: R.prop('data', response), url }))
    )
    .catch((error) => dispatchError(error, dispatch));

export const loadEligibilityDependencies = () => (dispatch) => {
  const dependencyList = [];
  dependencyList.push(dispatch(loadSystemVariables()));
  dependencyList.push(dispatch(loadEligibilityOperators()));

  dispatch(loadDependenciesPending());

  return Promise.all(dependencyList)
    .then(() => dispatch(loadDependenciesSuccess()))
    .catch((body) => {
      const err = R.path(['response', 'data', 'errors'], body);
      dispatch(loadDependenciesError());
      return Promise.reject(err);
    });
};

export const loadArticlesCountries = () => {
  return (dispatch, getState) =>
    cachedGet(organization.lookupCountries)
      .then((response) => {
        dispatch(
          getArticlesCountries({
            error: null,
            countries: R.prop('data', response),
            user: R.prop('user', getState()),
          })
        );
      })
      .catch((error) => dispatchError(error, dispatch));
};

export const loadArticlesCategories = () => {
  return (dispatch) =>
    GET(`${articles.categories}`)
      .then((payload) => {
        const data = R.path(['data', 'response'], payload);
        dispatch(getArticlesCategories(data));
        return data;
      })
      .catch((error) => dispatchError(error, dispatch));
};

export const loadArticlesList = (payload) => {
  return (dispatch) =>
    POST(`${articles.list}`, payload)
      .then((response) => {
        const data = R.path(['data'], response);
        dispatch(getArticlesList(data));
      })
      .catch((error) => {
        dispatchError(error, dispatch);
      });
};

export const revertArticleToDraft = (articleId, config) => {
  return (dispatch, getState) =>
    PATCH(
      `${articles.revertToDraft(articleId)}`,
      {},
      { ...config.headers },
      { redirectToUnauthorized: false }
    )
      .then((response) => {
        dispatch(formSave(R.prop('data', response)));
        dispatch(etagUpdate(response.headers.etag));
        return response;
      })
      .catch((error) => {
        dispatchError(error, dispatch, getState());
        return Promise.reject(error);
      });
};

export const publishDraftArticle = (articleId, config) => {
  return (dispatch, getState) =>
    PATCH(
      `${articles.publish(articleId)}`,
      {},
      { ...config.headers },
      { redirectToUnauthorized: false }
    )
      .then((response) => {
        dispatch(formSave(R.prop('data', response)));
        dispatch(etagUpdate(response.headers.etag));
        return response;
      })
      .catch((error) => {
        dispatchError(error, dispatch, getState());
        return Promise.reject(error);
      });
};

export const updateFormData = (payload) => {
  return (dispatch, getState) =>
    POST(`${articles.articlesRoot}`, payload, {}, { redirectToUnauthorized: false })
      .then((response) => {
        dispatch(formSave(R.prop('data', response)));
        dispatch(etagUpdate(response.headers.etag));
        return response;
      })
      .catch((error) => {
        dispatchError(error, dispatch, getState());
        return Promise.reject(error);
      });
};

export const updateArticleData = (payload) => {
  return (dispatch) => {
    const { article, eligibility } = payload;
    const encodedEligibilityRules = JSON.parse(JSON.stringify(eligibility.rules));
    encodedEligibilityRules[0].eligibility = encodeValuesInRules(
      R.path([0, 'eligibility'], encodedEligibilityRules)
    );

    const eligibilityRequestOptions = {
      url: `${rewards.eligibilityRules}/${article.populationId || ''}`,
      method: article.populationId ? 'PUT' : 'POST',
      headers: article.populationId ? { Etag: eligibility.eligibilityEtag } : {},
    };

    if (isBlank(R.path([0, 'eligibility'], eligibility.rules))) {
      article.populationId = null;
      return dispatch(updateFormData(article));
    }

    return RequestFactory(
      eligibilityRequestOptions.method,
      eligibilityRequestOptions.url,
      eligibilityRequestOptions.headers,
      { eligibilityRules: encodedEligibilityRules }
    ).then((response) => {
      article.populationId = R.path(['data', 'response', 'id'], response);
      const eligibilityEtag = R.path(['headers', 'etag'], response);

      dispatch(
        updateEligibilityData({ etag: eligibilityEtag, populationId: article.populationId })
      );

      return dispatch(updateFormData(article));
    });
  };
};

export const validateEligibilities = (eligibilities) => (dispatch) => {
  const errors = [];
  eligibilities.forEach((pop, index) => {
    errors[index] = validateEligibility(pop);
  });
  if (!isBlank(errors[0])) {
    dispatch(updateEligibilityError({ path: [0, 'eligibility'], data: errors[0] }));
  }
  return errors;
};

export const changeEligibilityField = (index, field, value, other) => (dispatch, getState) => {
  const state = getState();
  const errors = R.clone(R.path(['articles', 'eligibilityErrors'], state));
  const newErrors = errors[index] || [];

  if (R.path(['eligibility', R.path(['rule', 'uid'], other)], newErrors)) {
    const newEligibility = R.prop('rule', other);
    newErrors.eligibility[newEligibility.uid] = validateSimpleEligibility(newEligibility);
  }

  dispatch(eligibilityChange({ index, field, value, other }));
  if (!isBlank(newErrors)) {
    dispatch(updateEligibilityError({ path: [index], data: newErrors }));
  }
};
