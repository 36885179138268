import employeeCategoryDetails from './employeeCategoryDetails';
import shares from './shares';
import enrollmentCard from './enrollmentCard';

const ltiPlansWidget = {
  LTI_PLAN_CARD_TITLE: 'My Long Term Incentives (Vesting Values by Year)',
  LTI_PLAN_NAME: 'Plan Name',
  LTI_PLAN_TOTAL: 'Total',
  LTI_PLAN_TOTAL_BY_YEAR: 'Total by Year',
  LTI_PLAN_DESCRIPTION: 'Long Term Incentives description',
  LTI_PLAN_DIALOG_START_DATE_LABEL: 'Start date',
  LTI_PLAN_DIALOG_DESCRIPTION_LABEL: 'Description',
};

export default {
  PAGE_TITLE: 'Welcome',
  DASHBOARD_DESCRIPTION: `Welcome to your total reward statement*.<br>Within your statement you can see the full breakdown of all the different categories of reward elements that you receive.<br>You can drill down and see detailed information about these reward components and provide anonymous feedback.<br>
   We hope you will find this information useful in understanding the true value of your total reward package.
  `,
  ES_HOME: 'Home',
  ES_OVERALL: 'Overall',
  PROFILE_CARD_TITLE: 'Personal Details',
  PROFILE_CARD_COUNTRY_LABEL: 'Country',
  PROFILE_CARD_EMPLOYEE_ID_LABEL: 'Employee ID',
  PROFILE_CARD_EMPLOYEE_NAME: 'Employee Name',
  PROFILE_CARD_GRADE_LABEL: 'Grade',
  MIMIC_EMPLOYEE_BANNER:
    'You are currently viewing Employee Statement as %EMPLOYEE_NAME% %EMPLOYEE_ID%',
  MIMIC_EMPLOYEE_EXIT_LABEL: 'Exit mimic mode',
  NO_REWARDS_FOUND_TITLE: 'My rewards',
  NO_REWARDS_FOUND:
    'There aren’t any rewards assigned to you. Please contact the HR team so you can get access to your rewards.',
  EXTRA_PANEL_DESC: 'The rewards shown in this category are not included in your overall benefit.',
  STATEMENT_DOWNLOAD_DATE: 'Statement Download Date: %DATE%',
  DOWNLOAD_PDF_STATEMENT: 'Download Statement',
  ...employeeCategoryDetails,
  ...shares,
  ...enrollmentCard,
  ...ltiPlansWidget,
};
