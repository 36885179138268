import styled, { css } from 'styled-components';

const StyledDisclaimerPage = styled.section`
  ${props => {
    const { spacing } = props.theme;

    return css`
      .disclaimer-content {
        padding: ${spacing.base} ${spacing.basePlus} ${spacing.basePlus} ${spacing.basePlus};
        margin-bottom: ${spacing.basePlus};

        h4 {
          line-height: ${spacing.basePlus};
        }

        > div {
          line-height: ${spacing.base};
          margin: 0;
        }
      }

      .disclaimer-title {
        margin-bottom: ${spacing.large};
      }
    `;
  }};
`;

StyledDisclaimerPage.displayName = 'StyledDisclaimerPage';
export default StyledDisclaimerPage;
