import { addDays } from '@trs/utils';
import { DEFAULT_FROM_DAYS, ROLES_SELECT_DATA } from '../config/constants';

const initialState = {
  isLoading: false,
  isTableLoading: false,
  isDownloadResultsLoading: false,
  id: '',
  name: '',
  email: '',
  country: '',
  list: [],
  totalResults: 0,
  filter: {
    userId: '',
    employeeId: '',
    fromDate: addDays(new Date(), -DEFAULT_FROM_DAYS),
    fromTime: '00:00',
    toDate: new Date(),
    toTime: '23:59',
    role: ROLES_SELECT_DATA[1],
    page: 1,
    top: 10,
  },
};

export default initialState;
