import * as R from 'ramda';
import initialState from './initialState';
import {
  getStatusByScore,
  convertDecimalToPercentage,
  calculateAverageScore,
  getArrayOfScoresFromRewards,
  isValidRewardFeedback,
  amendmentFeedback,
  isChanged,
} from '../components/helpers';
import * as actions from '../actions/types';

export default function feedbackReducer(state = initialState, action = {}) {
  switch (action.type) {
    case actions.GET_EMPLOYEE_TOTAL_SCORE_PENDING:
      return {
        ...state,
        isFetchingTotalScore: true,
      };
    case actions.GET_EMPLOYEE_TOTAL_SCORE_SUCCESS: {
      const {
        payload: { score },
      } = action;
      return {
        ...state,
        feedbackScore: convertDecimalToPercentage(score),
        status: getStatusByScore(score),
        isFetchingTotalScore: false,
      };
    }
    case actions.GET_EMPLOYEE_TOTAL_SCORE_ERROR: {
      return {
        ...state,
        feedbackScore: initialState.feedbackScore,
        status: initialState.status,
        isFetchingTotalScore: false,
      };
    }
    case actions.GET_EMPLOYEES_TOTAL_SCORE_PENDING: {
      return {
        ...state,
        isFetchingEmployeesTotalScore: true,
      };
    }
    case actions.GET_EMPLOYEES_TOTAL_SCORE_SUCCESS: {
      const {
        payload: { score, hasFeedback },
      } = action;
      return {
        ...state,
        employeesTotalScore: convertDecimalToPercentage(score),
        employeesStatus: getStatusByScore(score),
        isFetchingEmployeesTotalScore: false,
        hasFeedback,
      };
    }
    case actions.GET_EMPLOYEES_TOTAL_SCORE_ERROR: {
      return {
        ...state,
        employeesTotalScore: initialState.employeesTotalScore,
        employeesStatus: initialState.employeesStatus,
        isFetchingEmployeesTotalScore: false,
      };
    }
    case actions.GET_REWARDS_FEEDBACK_PENDING:
      return {
        ...state,
        isFetchingRewardsFeedback: true,
      };
    case actions.GET_REWARDS_FEEDBACK_SUCCESS: {
      const { rewards } = action;
      const cloneRewards = amendmentFeedback(rewards);
      const averageScore = calculateAverageScore(getArrayOfScoresFromRewards(rewards));
      return {
        ...state,
        rewardsFeedback: cloneRewards,
        rewardsFeedbackInitial: cloneRewards,
        feedbackScore: convertDecimalToPercentage(averageScore),
        status: getStatusByScore(averageScore),
        isFetchingRewardsFeedback: false,
      };
    }
    case actions.GET_REWARDS_FEEDBACK_FAIL:
      return {
        ...state,
        isFetchingRewardsFeedback: false,
      };
    case actions.FEEDBACK_SUBMIT_REWARDS_PENDING:
      return {
        ...state,
        isFeedbackSubmitPending: true,
      };
    case actions.FEEDBACK_SUBMIT_REWARDS_SUCCESS: {
      const cloneRewardsFeedback = amendmentFeedback(state.rewardsFeedback);
      return {
        ...state,
        isFeedbackSubmitPending: false,
        hasUnsavedChanges: false,
        rewardsFeedbackInitial: cloneRewardsFeedback,
        rewardsFeedback: cloneRewardsFeedback,
      };
    }
    case actions.FEEDBACK_SUBMIT_REWARDS_ERROR:
      return {
        ...state,
        isFeedbackSubmitPending: false,
        hasUnsavedChanges: true,
      };
    case actions.SELECT_RATING: {
      const { rewardsFeedback, rewardsFeedbackInitial } = state;
      const { index, rating } = action;
      const clonedRewardsFeedback = R.clone(rewardsFeedback);
      clonedRewardsFeedback[index].feedbackScore = rating;
      clonedRewardsFeedback[index].isValid = isValidRewardFeedback(
        clonedRewardsFeedback[index].feedbackText,
        rating
      );
      clonedRewardsFeedback[index].hasRewardFeedbackChanged = isChanged(
        clonedRewardsFeedback,
        rewardsFeedbackInitial,
        index
      );

      const averageScore = calculateAverageScore(
        getArrayOfScoresFromRewards(clonedRewardsFeedback)
      );

      return {
        ...state,
        feedbackScore: convertDecimalToPercentage(averageScore),
        status: getStatusByScore(averageScore),
        hasUnsavedChanges: true,
        rewardsFeedback: clonedRewardsFeedback,
      };
    }
    case actions.CHANGE_FEEDBACK_TEXT: {
      const { rewardsFeedback, rewardsFeedbackInitial } = state;
      const { index, feedbackText } = action;
      const clonedFeedback = R.clone(rewardsFeedback);
      clonedFeedback[index].feedbackText = feedbackText;
      clonedFeedback[index].isValid = isValidRewardFeedback(
        feedbackText,
        clonedFeedback[index].feedbackScore
      );
      clonedFeedback[index].hasRewardFeedbackChanged = isChanged(
        clonedFeedback,
        rewardsFeedbackInitial,
        index
      );
      return {
        ...state,
        hasUnsavedChanges: true,
        rewardsFeedback: clonedFeedback,
      };
    }
    case actions.RESET_FEEDBACK_PAGE: {
      return {
        ...initialState,
      };
    }
    default: {
      return state;
    }
  }
}
