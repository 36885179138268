/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import * as R from 'ramda';
import APP_CONFIG from '@trs/config';
import { camelcase, isBlank, toLower } from '@trs/utils';
import { TextOnly } from 'modules/common/Text';
import { filterCountriesByAccess } from '../common/helpers';
import userModules from '../../config/userManager/userModules';
import DisplayName from './components/displayName';
import { menuListContextKeys } from './routes/utils';

const { GLOBAL_KEYWORD, PERCENTAGE_TOGGLER_ICONS } = APP_CONFIG;

export const NAME_MAX_LENGTH = 100;

export const getSearchFilterPayload = (params) => {
  const { sortAsc, pagingTop: top, pagingSkip: skip, searchText, filters } = params;
  let { sortBy } = params;

  if (sortBy === 'displayName') {
    sortBy = 'name';
  }

  const payload = {
    sort: {
      sortAsc,
      sortBy,
    },
    paging: {
      top,
      skip,
    },
    search: {
      searchText,
    },
    filters,
  };
  return payload;
};

export const loadSortedData = (searchText, filters, page, sort, callback) => {
  const params = {
    sortAsc: sort.direction === 'asc',
    searchText,
    filters,
    sortBy: sort.by,
    pagingTop: page.resultsPerPage,
    pagingSkip: (page.currentPage - 1) * page.resultsPerPage,
  };
  callback(params);
};

export const mapServerErrorToFields = (error) => {
  const serverErrors = R.path(['response', 'data', 'errors'], error);
  if (!isBlank(serverErrors)) {
    return R.mergeAll(
      R.map(
        ({ field, message }) => ({ [camelcase(field)]: { serverError: message } }),
        serverErrors
      )
    );
  }
  return {};
};

export const mapCountriesToUI = ({ data = [], authorizedModules = {} }) => {
  const countries = [];

  data.forEach((country) =>
    countries.push({
      value: country.name,
      label: country.name,
      code: country.code,
    })
  );
  // to be reviewed
  return filterCountriesByAccess({
    countries,
    module: userModules.REWARDS_MANAGEMENT,
    authorizedModules,
  });
};

export const hasLocalAccess = (authorizedModules = [], country) => {
  const rewardsManagementModule = R.path([userModules.REWARDS_MANAGEMENT], authorizedModules) || [];
  return (
    !R.includes(GLOBAL_KEYWORD, rewardsManagementModule) &&
    toLower(country) === toLower(GLOBAL_KEYWORD)
  );
};

export const mapVariableListToGrid = ({ data = {}, authorizedModules = [] }) => {
  let list = [];
  const filterOptions = R.map(
    (item) => ({
      title: item.key,
      key: item.key,
      list: item.options,
    }),
    data.filterOptions || []
  );

  if (!isBlank(data) && !isBlank(data.variables)) {
    list = data.variables.map((item) => {
      const hasLocal = hasLocalAccess(authorizedModules, item.country);
      return {
        ...item,
        name: <DisplayName id={item.id} name={item.displayName} />,
        menuListProps: {
          [menuListContextKeys.delete]: {
            disabled: item.usageCount > 0 || hasLocal,
          },
        },
        menuTransform: (menuList) => {
          if (hasLocal || item.usageCount) return [{}];
          return menuList;
        },
      };
    });
  }

  return {
    list,
    filterOptions,
    totalResults: data.count || 0,
  };
};

export const mapVariableToUI = (data = {}) => {
  const country = R.prop('country', data);
  return {
    ...data,
    displayName: R.prop('displayName', data),
    country: { value: country, label: country, code: R.prop('countryCode', data) },
    type: R.prop('type', data),
    value: { amount: R.path(['value', 'amount'], data), type: R.path(['value', 'type'], data) },
  };
};

export const VARIABLE_TYPES = {
  value: 1,
  list: 2,
};

export const AMOUNT_TYPES = {
  numeric: 1,
  percentage: 2,
};

export const getUsageTypesLabels = () => ({
  1: TextOnly({ path: 'variables.USAGE_REWARDS' }),
  2: TextOnly({ path: 'variables.USAGE_FLEX' }),
  3: TextOnly({ path: 'variables.USAGE_BENCHMARKING' }),
});

export const getTypeRadioButtons = () => [
  {
    name: 'contribution-type-radios',
    value: VARIABLE_TYPES.value,
    naked: true,
    iconChecked: 'ion-android-radio-button-on',
    iconUnchecked: 'ion-android-radio-button-off',
    label: TextOnly({ path: 'variables.VALUE_LABEL' }),
  },
  {
    value: VARIABLE_TYPES.list,
    name: 'contribution-type-radios',
    iconChecked: 'ion-android-radio-button-on',
    iconUnchecked: 'ion-android-radio-button-off',
    naked: true,
    label: TextOnly({ path: 'variables.LIST_TYPE_LABEL' }),
  },
];

export const getAmountTypeRadios = () => [
  {
    value: AMOUNT_TYPES.numeric,
    ariaLabel: 'variableTypeNumber',
    id: 'variableTypeNumber',
    textAsIcon: PERCENTAGE_TOGGLER_ICONS.NUMERIC,
  },
  {
    value: AMOUNT_TYPES.percentage,
    ariaLabel: 'variableTypePercentage',
    id: 'variableTypePercentage',
    textAsIcon: PERCENTAGE_TOGGLER_ICONS.PERCENTAGE,
  },
];

export default {
  loadSortedData,
  mapServerErrorToFields,
  getSearchFilterPayload,
};
