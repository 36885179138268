import * as R from 'ramda';
import { decimalTransform } from '@trs/utils';

export const MIN_VALUE = 0;
export const MAX_VALUE = 100;
export const inRange = (value) => value >= MIN_VALUE && value <= MAX_VALUE;
export const sortByValue = R.sortBy(R.prop('value'));

export const setPayPointValue = (annualSalary, midAnnualSalaryValue) => {
  if (!annualSalary && !midAnnualSalaryValue) {
    return '100.00';
  }

  return decimalTransform(
    R.divide(annualSalary, midAnnualSalaryValue || annualSalary) * 100
  ).toString();
};

export const sortAscendingByOrderIndex = (metadata) => R.sortBy(R.prop('orderIndex'))(metadata);
