export default {
  COUNTRY_LABEL: '%COUNTRY% package',
  EDIT_LABEL: 'Edit',
  VIEW_LABEL: 'View',
  DEACTIVATE_PACKAGE: 'Deactivate',
  DELETE_PACKAGE: 'Delete',
  EXPORT_EMPLOYEE_ELECTIONS: 'Export Employee Elections',
  TYPE_LABEL: 'Type',
  COUNTRY_NAME_LABEL: 'Country',
  DATE_LABEL: 'Date',
  FROM_LABEL: 'From',
  TO_LABEL: 'To',
  CLOSE_ENROLLMENT_LABEL: 'Close enrollment',
  ALL_FILTER_OPTION: 'All',
  MODELLING_FILTER_OPTION: 'Modelling',
  SUBMISSION_FILTER_OPTION: 'Submission',
  TYPE_FILTER_TITLE: 'Show',
  NO_PACKAGES: 'There are currently no packages. You can start by setting up a new one.',
  PACKAGE_DEACTIVATE_SUCCESS: 'Package deactivated successfully!',
  PACKAGE_DELETE_SUCCESS: 'Package deleted successfully!',
  PACKAGE_DEACTIVATE_ERROR: 'There was an error de-activating this package',
  PACKAGE_DELETE_ERROR: 'There was an error deleting this package',
  MODAL_DEACTIVATE_TITLE: 'Deactivate Package',
  MODAL_DEACTIVATE_SUBTITLE: 'Are you sure you want to deactivate this package?',
  MODAL_DELETE_PACKAGE_TITLE: 'Are you sure you wish to delete this package?',
  MODAL_DELETE_SUBMISSION_PACKAGE_SUBTITLE:
    'Once deleted, you will no longer have access to this package and you will not be able to export the Employee Elections associated to it.',
  MODAL_DELETE_MODELLING_PACKAGE_SUBTITLE:
    'Once deleted, you will no longer have access to this package.',
};
