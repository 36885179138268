export default {
  CHANGEABLE_BY_EMPLOYEE: 'Changeable By Employee',
  EXCLUDE: 'Exclude',
  OPTIONAL: 'Optional',
  INCLUDE_IN_BUDGET: 'Include In Budget',
  SELECTED_BY_DEFAULT: 'Selected By Default',
  ERROR_REWARDS_SELECTION_REQUIRED: 'At least one reward is required.',
  PUBLISH_BANNER_TITLE: 'Publish %COUNTRY% package',
  PUBLISH_BANNER_TEXT:
    '%NO% rewards were marked as optional for %COUNTRY%. Are you sure you want to publish this package?',
  SAME_VERSION_GROUP_DIALOG_TITLE: 'Overlapping reward versions',
  SAME_VERSION_GROUP_DIALOG_SUBTITLE:
    'Please note that you have already selected another version of this reward: <strong>%name%</strong>.<br><strong><em>It is best to avoid this scenario.</em></strong><br><br>Would you like to proceed?',
  NO_REWARDS:
    'There are no Rewards in the system. You can start by adding a reward from the "Manage" section',
  INTEGER_ONLY: 'Integer only',
  HIDE_WHEN_SELECTED: 'Hide when selected',
  WARNING_DIALOG_TITLE: 'Would you like to display a warning once deselected?',
  WARNING_DIALOG_DESCRIPTION:
    'This will apply to the Employee model when a user deselects this reward if it was specified as "selected by default"',
  WARNING_DIALOG_TEXT_LABEL: 'Warning content',
  WARNING_DIALOG_LENGTH_LABEL: 'Text must be less than %limit% characters.',
};
