import Big from 'big.js';
import * as R from 'ramda';
import * as moment from 'moment';
import { GLOBAL_KEYWORD, CONFLICT_STATUS_CODE } from '@trs/config';
import {
  isBlank,
  sortListAscendingByProp,
  sortListDescendingByProp,
  toBinary,
  getErrorStatus,
} from '@trs/utils';
import { showBanner } from '../../actions/globalActions';

import userModules from '../../config/userManager/userModules';
import { TextOnly } from './Text';

export const OVERALL_STATUSSES = {
  ready: 'Ready',
  notExecuted: 'NotExecuted',
  inProgress: 'InProgress',
  success: 'Success',
  warning: 'Warning',
  failed: 'Failed',
};

export const formatGMTToUTC = (value, format) => {
  return moment.utc(moment(value).format(format)).format();
};

export const filterCountriesByAccess = ({ countries, authorizedModules, module }) => {
  /**
   * Since the Employee dashboard module always has "global" access, we need to remove it
   * from the available modules list, in order to have the proper country list
   */
  const filteredModules = Object.keys(authorizedModules)
    .filter((key) => key !== userModules.EMPLOYEE_DASHBOARD)
    .reduce((obj, key) => {
      obj[key] = authorizedModules[key]; // eslint-disable-line no-param-reassign
      return obj;
    }, {});

  const countryList = !R.isNil(module)
    ? R.prop(module, filteredModules)
    : R.compose(R.uniq, R.flatten, R.values)(filteredModules);

  if (countryList && countryList.includes(GLOBAL_KEYWORD)) {
    return countries;
  }

  return countries
    ? countries.filter(
        (country) =>
          countryList && countryList.includes(country.code || country.countryCode || country.value)
      )
    : [];
};

export const getjsxFromLabel = ({ label, render, pattern = '%' }) =>
  label.split(pattern).map((item, idx) => {
    if (idx === 1) {
      // this needs handling for multiple "holes"
      return render(item);
    }
    return item;
  });

/*
    obj: {
      '%FIELD%': 'pieces',
      '%SIGN%': '!',
    }
*/
export const replace = (label = '', obj = {}) =>
  R.compose(
    R.reduce((acc, key) => acc.replace(key, obj[key]), label),
    R.keys()
  )(obj);

export const matchRoles = (requiredRoles, userRoles) =>
  !isBlank(R.intersection(requiredRoles, Object.keys(userRoles)));

export const shouldHideFeature = (feature, featureToggles) => {
  return !featureToggles.includes(feature);
};

export const isGlobalOnModule = (authorizedModules, userModule) => {
  const module = R.path([userModule], authorizedModules);
  return module ? R.contains(GLOBAL_KEYWORD, module) : false;
};

export const filterSelect = (value, source) =>
  value && source && R.find(R.propEq('id', value))(source);

export const dispatchError = (error, errorFinder, dispatch) => {
  let errorMessage = errorFinder(error) || R.prop('message', error);
  const status = getErrorStatus(error);

  if (status === CONFLICT_STATUS_CODE) {
    errorMessage = TextOnly({ path: 'generic.CONFLICT_BANNER' });
  }

  dispatch(
    showBanner({
      type: 'error',
      content: errorMessage,
    })
  );
};

export const checkForPermission = (permissions, permissionName) => {
  let permission = false;

  if (permissions && Object.keys(permissions).length !== 0 && permissionName) {
    // eslint-disable-next-line
    for (const country in permissions) {
      permission = permissions[country].includes(permissionName);
      if (permission) break;
    }
  }
  return permission;
};

export const transformPdfTextContent = (content) => {
  let updatedContent = R.clone(content);
  /** match only one character/number between two rounded brackets
   * ex (A), (P), (C), (p), (c), (0), (5) etc */
  const regex = /\([A-Za-z0-9]\)/g;
  const matches = R.uniq(R.match(regex, content));

  if (matches.length > 0) {
    matches.forEach((item) => {
      const contentBetweenBrackets = item.match(/\(([^)]+)\)/)[1];
      // add space after left parenthesis and before right parenthesis
      updatedContent = updatedContent.replaceAll(item, `( ${contentBetweenBrackets} )`);
    });
  }

  return updatedContent;
};

export const sortingRules = {
  ASC: 'asc',
  DESC: 'desc',
  DEFAULT: 'default',
};

export const sortDirectionOrder = [sortingRules.ASC, sortingRules.DESC, sortingRules.DEFAULT];

export const getNextDirectionMapping = (sortOrder = sortDirectionOrder) => {
  const nextDirectionMapping = {};

  for (let i = 0; i < sortOrder.length - 1; i += 1) {
    nextDirectionMapping[sortOrder[i]] = sortOrder[i + 1];
  }

  [nextDirectionMapping[sortOrder[sortOrder.length - 1]]] = sortOrder;

  return nextDirectionMapping;
};

export const filterItemsToSort = (items, sortByProp) => {
  const listWithEmptyProperties = [];
  const listWithoutEmptyProperties = [];

  items.forEach((item) => {
    if (isBlank(item[sortByProp])) {
      listWithEmptyProperties.push(item);
    } else {
      listWithoutEmptyProperties.push(item);
    }
  });

  return {
    itemsToSort: listWithoutEmptyProperties,
    itemsWithEmptyProp: listWithEmptyProperties,
  };
};

export const sortItemsByDirection = (items, { by: sortBy, direction }) => {
  const { itemsToSort, itemsWithEmptyProp } = filterItemsToSort(items, sortBy);

  if (R.equals(direction, sortingRules.DESC)) {
    const sortedDESCList = sortListDescendingByProp(sortBy, itemsToSort);
    // items with empty prop should be last
    return R.concat(sortedDESCList, itemsWithEmptyProp);
  }

  const sortedASCList = sortListAscendingByProp(sortBy, itemsToSort);
  // items with empty prop should be first
  return R.concat(itemsWithEmptyProp, sortedASCList);
};

export const encodeValuesInRules = (rules) => {
  if (isBlank(rules)) {
    return rules;
  }

  const newRules = { ...rules };
  if (rules.eligibilityRows)
    newRules.eligibilityRows = rules.eligibilityRows.map((eligibilityRow) => {
      return encodeValuesInRules(eligibilityRow);
    });

  if (rules.values) {
    newRules.values = rules.values.map((value) =>
      typeof value === 'boolean' ? toBinary(value.toString()) : toBinary(value)
    );
  }

  return newRules;
};

export const encodeValuesInModel = (value) => {
  return typeof value === 'boolean' ? toBinary(value.toString()) : toBinary(value);
};

export const isInMimicMode = (isMimicView) => {
  return typeof isMimicView !== 'object' ? isMimicView : !isBlank(isMimicView);
};

export const convertStringToNumber = (value) => {
  return typeof value === 'string' && value !== '' ? Big(value).toNumber() : value;
};

export default {
  dispatchError,
  filterCountriesByAccess,
  isGlobalOnModule,
  getNextDirectionMapping,
  sortingRules,
  sortDirectionOrder,
  sortItemsByDirection,
  encodeValuesInRules,
  isInMimicMode,
};
