import styled, { css } from 'styled-components';
import { media } from '@trs/utils';

const StyledNotificationsIcon = styled.div`
  ${props => {
    const { palette, typography, spacing } = props.theme;

    return css`
      .notification-wrapper-desktop {
        position: relative;
        display: none;
      }

      .notification-wrapper-mobile {
        cursor: pointer;
        line-height: ${typography.lineHeight.h2};
        padding-left: ${spacing.xSmall};
        border-left: 4px solid transparent;

        &:hover {
          border-color: ${palette.primary};
        }
      }

      .notification-icon {
        cursor: pointer;
      }

      .notifications-count {
        position: absolute;
        right: -100%;
        top: 50%;
        transform: translate(-3px, -100%);
        background: ${palette.validation.error};
        border-radius: 8px;
        padding: 0 6px;
        font-size: ${typography.size.small};
        line-height: ${typography.lineHeight.misc};
        color: ${palette.basic.white};
      }

      ${media.tabletLandscape`
        .notification-wrapper-mobile {
          padding-left: ${spacing.basePlus};
        }
      `};

      ${media.desktop`
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 100%;
        .notification-wrapper-desktop {
          display: block;
        }
        .notification-wrapper-mobile {
          display: none;
        }
      `};
    `;
  }};
`;

StyledNotificationsIcon.displayName = 'StyledNotificationsIcon';

export default StyledNotificationsIcon;
