const mapCountriesToModel = countries =>
  countries.map(country => ({
    value: country.code,
    label: country.name,
  }));

export const mapRequestToRoleModel = role => ({
  name: role.name,
  countries: mapCountriesToModel(role.countries),
  availableModules: role.availableModules,
  isEditable: role.isEditable,
  usersInRole: role.numberOfMembers,
  populationId: role.populationId || null,
});

const mapRolesToUserModelDropdown = roles =>
  roles.map(role => ({
    value: role.id,
    label: role.name,
  }));

export const mapRolesToUserModel = user => ({
  firstName: user.firstName,
  lastName: user.lastName,
  roles: mapRolesToUserModelDropdown(user.roles),
  email: user.email,
  isNonEmployee: user.isNonEmployee ? 'yes' : 'no',
});
