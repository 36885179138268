import styled, { css } from 'styled-components';
import { media } from '@trs/utils';

const StyledStickyContainer = styled.div`
  ${({ theme, position, isFullWidth, noMargin }) => {
    const { palette, spacing } = theme;

    return css`
      .element-sticky {
        transform: none !important;
      }
      ${!noMargin &&
        position === 'bottom' &&
        css`
          margin-top: ${spacing.xLarge};
        `}

      .layout {
        padding: 0;
        margin-bottom: 0;
      }

      .sticky {
        left: 0;
        z-index: 5;
        margin: 0;

        .layout {
          padding: 0 ${spacing.small};
        }

        .layout div {
          ${noMargin &&
            css`
              margin-top: 0;
              margin-bottom: 0;
            `}
        }

        ${media.xDesktop`
          .layout {
            padding: 0;
          }
        `}

        ${isFullWidth &&
          css`
            width: 100% !important;
            background: ${palette.basic.white};
            padding: ${spacing.small} 0;
            box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.15);
          `}

        /* Since it's a common use case, we can leave this here */
        .tab-buttons,
        .tab-buttons > div {
          margin: 0;
        }
      }
    `;
  }}
`;

StyledStickyContainer.displayName = 'StyledStickyContainer';
export default StyledStickyContainer;
