"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.processNumeric = exports.isNumericValue = exports.isNumericType = exports.getFieldPayload = exports.getFieldDescription = exports.SEARCHABLE_TYPES = void 0;
var R = _interopRequireWildcard(require("ramda"));
var _FormattedNumber = _interopRequireDefault(require("../../locale/FormattedNumber"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var SEARCHABLE_TYPES = {
  NUMERIC_SIMPLE: 'numeric',
  NUMERIC_PERCENTAGE: 'percentage',
  EXTERNAL: 'variable'
};
exports.SEARCHABLE_TYPES = SEARCHABLE_TYPES;
var processNumeric = function processNumeric(value) {
  if (!value) return '';
  var stringValue = typeof value === 'number' ? String(value) : value;
  var processed = stringValue.replace(/^0{1,}/, '') || '0';
  return processed.indexOf('.') === 0 ? "0".concat(processed) : processed;
};
exports.processNumeric = processNumeric;
var isNumericType = R.memoizeWith(R.identity, function (type) {
  return type === SEARCHABLE_TYPES.NUMERIC_SIMPLE || type === SEARCHABLE_TYPES.NUMERIC_PERCENTAGE;
});
exports.isNumericType = isNumericType;
var isNumericValue = R.memoizeWith(R.identity, function (value) {
  return /^-?([0-9]*\.?[0-9]*|[0-9]*\.?|\.?[0-9]*)$/.test(value);
});
exports.isNumericValue = isNumericValue;
var getNumericLocaleDescription = function getNumericLocaleDescription(_ref) {
  var type = _ref.type,
    value = _ref.value,
    isFocused = _ref.isFocused;
  var isPercentage = type === SEARCHABLE_TYPES.NUMERIC_PERCENTAGE;
  if (isFocused || value === '-') return value;
  return (0, _FormattedNumber["default"])({
    value: value,
    isPercentage: isPercentage,
    maximumFractionDigits: 5
  });
};
var getFieldDescription = function getFieldDescription(_ref2) {
  var type = _ref2.type,
    value = _ref2.value,
    isFocused = _ref2.isFocused,
    _ref2$description = _ref2.description,
    description = _ref2$description === void 0 ? '' : _ref2$description,
    _ref2$search = _ref2.search,
    search = _ref2$search === void 0 ? '' : _ref2$search,
    _ref2$displayName = _ref2.displayName,
    displayName = _ref2$displayName === void 0 ? '' : _ref2$displayName;
  var isNumeric = isNumericType(type);
  if (isNumeric) {
    return getNumericLocaleDescription({
      type: type,
      value: value,
      isFocused: isFocused
    });
  }
  if (search || displayName) return search || displayName;
  return description || displayName || value;
};
exports.getFieldDescription = getFieldDescription;
var getFieldPayload = function getFieldPayload(_ref3) {
  var _ref3$value = _ref3.value,
    value = _ref3$value === void 0 ? '' : _ref3$value,
    _ref3$description = _ref3.description,
    description = _ref3$description === void 0 ? '' : _ref3$description,
    _ref3$dataSource = _ref3.dataSource,
    dataSource = _ref3$dataSource === void 0 ? '' : _ref3$dataSource,
    _ref3$type = _ref3.type,
    type = _ref3$type === void 0 ? '' : _ref3$type,
    _ref3$dataType = _ref3.dataType,
    dataType = _ref3$dataType === void 0 ? '' : _ref3$dataType,
    _ref3$displayName = _ref3.displayName,
    displayName = _ref3$displayName === void 0 ? '' : _ref3$displayName;
  return {
    value: type === SEARCHABLE_TYPES.EXTERNAL ? "".concat(dataSource, ".").concat(value) : value,
    description: description || value,
    dataSource: dataSource || value,
    type: type,
    dataType: dataType,
    displayName: displayName || value
  };
};
exports.getFieldPayload = getFieldPayload;