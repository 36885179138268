import * as R from 'ramda';
import actionTypes from '../actions/types';
import getCorrelatedFields from './helpers';

const initialState = {
  1: {},
};

const fieldReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.ADD_COMPARE: {
      const lastIdx = parseInt(R.last(R.keys(state)), 10);
      return {
        ...state,
        [lastIdx + 1]: state[lastIdx],
      };
    }
    case actionTypes.REMOVE_COMPARE: {
      const newState = R.dissoc(action.payload, state);
      return {
        ...newState,
      };
    }
    case actionTypes.SET_INITIAL_FIELDS:
    case actionTypes.RE_INITIALIZE_FIELDS: {
      const { instanceId, payload: fields } = action;
      return {
        ...state,
        [instanceId]: {
          ...fields,
        },
      };
    }
    case actionTypes.LOAD_DATA_SUCCESS: {
      const { instanceId, payload: fields } = action;
      return {
        ...state,
        [instanceId]: {
          ...fields,
        },
      };
    }
    case actionTypes.HANDLE_CHANGE: {
      const { instanceId, field, payload } = action;
      if (R.path([field, 'propertyName'], state[instanceId]) === field) {
        return {
          ...state,
          [instanceId]: {
            ...state[instanceId],
            [field]: {
              ...R.path([instanceId, field], state),
              value: payload,
            },
          },
        };
      }
      return state;
    }
    case actionTypes.CORRELATE_FIELDS: {
      const { instanceId, payload } = action;
      return {
        ...state,
        [instanceId]: {
          ...state[instanceId],
          ...getCorrelatedFields(payload, state[instanceId]),
        },
      };
    }
    case actionTypes.RESET_STORE:
      return initialState;
    default:
      return state;
  }
};

export default fieldReducer;
