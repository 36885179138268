import styled, { css } from 'styled-components';

const StyledDataLoading = styled.div`
  ${props => {
    const { spacing, typography } = props.theme;

    return css`
      .provider-name {
        display: block;
        margin-top: ${spacing.xTiny};
        font-weight: ${typography.weight.bold};
      }
      .notification-link {
        display: block;
        margin-top: ${spacing.tiny};
        margin-left: 0;
        text-align: left;
        font-weight: ${typography.weight.bold};
      }
    `;
  }};
`;

StyledDataLoading.displayName = 'StyledDataLoading';

export default StyledDataLoading;
