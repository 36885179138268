"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
var _Section = _interopRequireDefault(require("../Section/Section"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var StyledStepperMember = (0, _styledComponents["default"])(_Section["default"]).withConfig({
  displayName: "StepperMemberstyle__StyledStepperMember",
  componentId: "sc-1x1ebga-0"
})(["", ""], function (props) {
  var ruleIndex = props.ruleIndex,
    theme = props.theme;
  var spacing = theme.spacing;
  return (0, _styledComponents.css)(["margin-top:16px;", " .stepper-block-title{text-transform:uppercase;margin:8px 0 24px;}.stepper-label-toggle{margin-left:16px;text-transform:lowercase;}.input-component{margin:0 16px;position:relative;input{padding-left:0;margin-bottom:32px;&:only-child{margin-bottom:0;}}}.stepper-input-separator{color:#4a4a4a;font-weight:bold;font-size:24px;line-height:10px;position:relative;top:8px;}.stepper-input-group{display:flex;flex-direction:row;}.stepper-input-container{display:flex;flex-direction:row;position:relative;}"], ruleIndex === 0 && (0, _styledComponents.css)(["margin-top:0;.section-body{margin:0;border:0;padding:0 ", " 0 0;}"], spacing.small));
});
StyledStepperMember.displayName = 'StyledStepperMember';
var _default = StyledStepperMember;
exports["default"] = _default;