import { createAction } from 'redux-actions';
import * as R from 'ramda';
import { isBlank } from '@trs/utils';
import { rewards } from '../../../../../config/api';
import { GET } from '../../../../../config/http';
import { getMimicContextHeader } from '../../sideBySide/actions/helpers';
import { TextOnly } from '../../../../common/Text';
import { dispatchError } from '../../../../common/helpers';

const actionTypes = {
  GET_FLEX_PACKAGE_PENDING: 'flex/banner/pending',
  GET_FLEX_PACKAGE_ERROR: 'flex/banner/error',
  GET_FLEX_PACKAGE_SUCCESS: 'flex/banner/success',
  ENROLLMENT_CARD_RESET: 'flex/banner/reset',
};

export const getEmployeePackagesPending = createAction(actionTypes.GET_FLEX_PACKAGE_PENDING);
export const getEmployeePackagesError = createAction(actionTypes.GET_FLEX_PACKAGE_ERROR);
export const getEmployeePackagesSuccess = createAction(actionTypes.GET_FLEX_PACKAGE_SUCCESS);
export const enrollmentCardReset = createAction(actionTypes.ENROLLMENT_CARD_RESET);

const getTextOnlyError = error => {
  const message = R.path(['response', 'data', 'errors', 0, 'message'], error);
  return TextOnly({ path: `flexibleRewards.${message}` }) || message;
};

export function getEmployeePackages() {
  return (dispatch, getState) => {
    const { user } = getState();
    const isMimic = R.path(['mimic', 'isMimicView'], user);
    dispatch(getEmployeePackagesPending());
    const contextHeader = getMimicContextHeader(isMimic);

    return GET(`${rewards.employeeFlex}/${R.path(['profile', 'id'], user)}/eligible`, {
      ...contextHeader,
      Pragma: 'no-cache',
    })
      .then(payload => {
        if (isBlank(R.path(['data', 'response'], payload))) {
          return dispatch(getEmployeePackagesError('PACKAGE_LOAD_ERROR'));
        }
        return dispatch(getEmployeePackagesSuccess(R.path(['data', 'response'], payload)));
      })
      .catch(error => {
        dispatchError(error, getTextOnlyError, dispatch);
        return Promise.reject(error);
      });
  };
}
