import * as R from 'ramda';
import { isBlank } from '@trs/utils';
import { showBanner } from 'actions/globalActions';
import { inRange } from '../../util';

export const checkInstanceFieldsValid = (fields) =>
  R.keys(fields).reduce((final, key) => {
    const fieldValue = R.path([key, 'value'], fields);
    if (!isBlank(R.path([key, 'value'], fields)) && R.path([key, 'rangedValue'], fields)) {
      return inRange(fieldValue);
    }
    return !isBlank(fieldValue) && final;
  }, true);

export const validateModellerInstances = (instances, instanceFields) => {
  let isModellerValid = true;
  const transformedInstances = R.reduce((res, instanceKey) => {
    const fields = R.prop(instanceKey, instanceFields);
    const isInstanceValid = checkInstanceFieldsValid(fields);
    // false if any is false
    isModellerValid = isModellerValid && isInstanceValid && R.length(R.keys(fields)) > 0;
    return {
      ...res,
      [instanceKey]: {
        ...instances[instanceKey],
        isValid: isInstanceValid,
        showValid: true,
      },
    };
  }, {})(R.keys(instanceFields));

  return {
    instances: transformedInstances,
    isModellerValid,
  };
};

export const transformOptions = (dimensionOptions) => {
  return dimensionOptions.reduce(
    (arr, current) =>
      arr.concat({
        value: current,
        label: current,
      }),
    []
  );
};

export const transformDimensionValuesToOptions = (dimensions) => {
  return R.reduce(
    (obj, current) => ({ ...obj, [current.id]: transformOptions(current.dimensionValues) }),
    {}
  )(dimensions);
};

export const getNestedArrayIds = R.pipe(R.values, R.chain(R.prop('id')));

export default { checkInstanceFieldsValid, transformOptions, transformDimensionValuesToOptions };

export function dispatchError(error, dispatch) {
  const errorMessage = R.path(['response', 'data', 'errors', 0, 'message'], error);
  dispatch(
    showBanner({
      type: 'error',
      content: errorMessage,
    })
  );
}
