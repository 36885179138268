"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var StyledTablePagination = _styledComponents["default"].div.withConfig({
  displayName: "TablePaginationstyle__StyledTablePagination",
  componentId: "sc-14dtwc8-0"
})(["", ";"], function (props) {
  var _props$theme = props.theme,
    color = _props$theme.palette,
    spacing = _props$theme.spacing;
  var currentPage = props.currentPage,
    totalResults = props.totalResults,
    resultsPerPage = props.resultsPerPage,
    itemsLimit = props.itemsLimit,
    isTableLoading = props.isTableLoading,
    disableLastPageArrow = props.disableLastPageArrow;
  var lastPage = Math.ceil(totalResults / resultsPerPage);
  return (0, _styledComponents.css)([".pagination-first,.pagination-previous,.pagination-last,.pagination-next{cursor:pointer;}.pagination-previous,.pagination-next{padding:", ";}.pagination-last{margin-right:", ";margin-left:", ";}.pagination-first{margin-right:", ";}.pagination-previous{margin-right:", ";}.pagination-next{margin-left:", ";}.pagination-status{position:relative;}", " ", " ", ";"], spacing.tiny, spacing.base, spacing.tiny, spacing.tiny, spacing.basePlus, spacing.basePlus, (isTableLoading || currentPage === 1) && (0, _styledComponents.css)([".pagination-previous,.pagination-first{cursor:not-allowed;color:", ";}"], color.gray.charlie), (isTableLoading || currentPage === lastPage) && (0, _styledComponents.css)([".pagination-next,.pagination-last{cursor:not-allowed;color:", ";}"], color.gray.charlie), (isTableLoading || disableLastPageArrow && totalResults >= itemsLimit) && (0, _styledComponents.css)([".pagination-last{cursor:not-allowed;color:", ";}"], color.gray.charlie));
});
StyledTablePagination.displayName = 'StyledTablePagination';
var _default = StyledTablePagination;
exports["default"] = _default;