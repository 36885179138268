import React from 'react';
import * as R from 'ramda';
import { Radio } from '@trs/components';
import constantCase from 'constant-case';
import { REGEX } from '../constants';
import { TextOnly } from '../../common/Text';
import EllipsisTooltip from '../../common/EllipsisTooltip';

const CATEGORY_MAPPINGS = [
  {
    columnId: 'included',
    mappingId: '1',
    mappingName: 'Included',
  },
  {
    columnId: 'notIncluded',
    mappingId: '2',
    mappingName: 'Not Included',
  },
  {
    columnId: 'notIncluded',
    mappingId: null,
    mappingName: 'Not Included',
  },
];

export const FR_STATUS = {
  ALL: 'ALL',
  NOT_SUBMITTED: 'NOT_SUBMITTED',
  SUBMITTED: 'SUBMITTED',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
};

export const radioCellRenderer =
  (mappings, onChange, disableAll, hasPermissions) => (_0, _1, columnId, _3, row) => {
    const { categoryId, subcategoryId, typeId, isEditable } = row;
    const key = `${categoryId}/${subcategoryId}/${typeId}`;
    const mapping = mappings[key];

    return (
      <Radio
        naked
        disabled={(disableAll && isEditable) || !hasPermissions}
        checked={R.prop('columnId', mapping) === columnId}
        iconChecked="ion-android-radio-button-on"
        iconUnchecked="ion-android-radio-button-off"
        onChange={() => {
          const newMapping = R.find(R.propEq('columnId', columnId))(CATEGORY_MAPPINGS);
          return onChange({ key, newMapping });
        }}
      />
    );
  };

const getColumnConfigReport = (mappings, onChange, disableAll, hasPermissions) => ({
  customTypesReport: {
    id: 'customTypesReport',
    title: TextOnly({ path: 'flexibleReports.COLUMN_CUSTOM_TYPES' }),
    width: 650,
    cellRenderer: (_0, _1, _2, _3, row) => {
      const typeName = R.prop('typeName', row);

      return (
        <EllipsisTooltip
          className="info-label-type"
          content={typeName}
          dataTip={typeName}
          dataFor={typeName}
        />
      );
    },
  },
  includedReport: {
    id: 'included',
    title: TextOnly({ path: 'flexibleReports.COLUMN_INCLUDED' }),
    width: 300,
    cellRenderer: radioCellRenderer(mappings, onChange, disableAll, hasPermissions),
  },
  notIncluded: {
    id: 'notIncluded',
    title: TextOnly({ path: 'flexibleReports.COLUMN_NOT_INCLUDED' }),
    alignment: 'center',
    cellRenderer: radioCellRenderer(mappings, onChange, disableAll, hasPermissions),
  },
});

export const mapRows = (columns, rows) => {
  const array = [];
  if (R.isNil(columns) || R.isNil(rows)) {
    return array;
  }

  R.forEach((item) => {
    const row = {};
    R.forEach((cell) => {
      const column = R.find(R.propEq('index', R.path(['index'], cell)), columns);
      row[R.path(['name'], column)] = R.path(['value'], cell);
    }, R.path(['cells'], item));

    array.push(row);
    row.id = R.toString(item.index);
  }, rows);

  return array;
};

export const mapColumns = (columns, labels) => {
  const obj = {};
  if (R.isNil(columns)) {
    return obj;
  }

  R.forEach((col) => {
    const columnName = R.path(['name'], col);
    obj[columnName] =
      R.path([constantCase(`col_${columnName}`)], labels) ||
      R.join(' ', R.match(REGEX, columnName));
  }, columns);

  return obj;
};

export default getColumnConfigReport;
