export default {
  BENCHMARKING_LOCAL_TITLE: 'Plan',
  DASHBOARD_TITLE: 'Dashboard',
  FILTER_ALL: 'All',
  STATUS_NOT_SUBMITTED: 'Not Submitted',
  STATUS_SUBMITTED: 'Submitted',
  STATUS_APPROVED: 'Approved',
  STATUS_REJECTED: 'Rejected',
  DASHBOARD_CURRENCY: 'Currency',
  DASHBOARD_FXRATE: 'FX Rate',
  DASHBOARD_AGEING_DATE: 'Ageing Date',
  DASHBOARD_TMP: 'Target Market Position',
  DASHBOARD_CONTACT: 'Contact Person',
  DASHBOARD_MANUAL_CONFIGURATION: '(Manual Configuration)',
  DASHBOARD_COLUMN_COUNTRY: 'Country',
  DASHBOARD_COLUMN_GLOBAL: 'Global Preparation',
  DASHBOARD_COLUMN_LOCAL: 'Local Preparation',
  DASHBOARD_COLUMN_SALARY: 'Salary Increase',
  DASHBOARD_COLUMN_PAY_SCALE: 'Payscale Increase',
  DASHBOARD_FILTER_VIEW: 'View',
  DASHBOARD_GLOBAL_NOT_STARTED:
    "We're sorry, Global Preparation has not been started. Please try again later.",
  DASHBOARD_REPORT: 'Report',
  DASHBOARD_EXPORT_SUMMARY: 'Export Summary',
};
