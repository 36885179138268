"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
var _config = _interopRequireDefault(require("@trs/config"));
var _utils = require("@trs/utils");
var _templateObject;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
var breakpoints = _config["default"].breakpoints;
var StyledDataTable = _styledComponents["default"].div.withConfig({
  displayName: "DataTablestyle__StyledDataTable",
  componentId: "sc-1vtjk43-0"
})(["", ";"], function (props) {
  var _props$theme = props.theme,
    palette = _props$theme.palette,
    spacing = _props$theme.spacing,
    genericBorderRadius = _props$theme.extra.genericBorderRadius;
  var hasPagination = props.hasPagination,
    isLoading = props.isLoading,
    hasError = props.hasError;
  return (0, _styledComponents.css)([".data-table-header{display:flex;align-items:baseline;.search-wrapper{flex:1;}}.table-head{border-bottom:solid 1px ", ";}.table-child-row{padding-left:", ";}.data-table-wrapper{position:relative;background-color:", ";display:flex;flex-flow:row;margin-bottom:", ";width:100%;box-shadow:0 1px 8px 0 rgba(0,0,0,0.04);border-radius:", ";border:1px solid ", ";& > *{flex:1 100%;}.table-wrapper{flex-grow:1;flex-basis:0;overflow-x:auto;", " ", " .table-container{position:relative;", "}", " .no-results-message p{margin-bottom:0;padding:", ";text-align:center;}}}"], palette.utility.border, spacing.xLarge, palette.gray.echo, spacing.basePlus, genericBorderRadius, palette.accent.tau, hasError && (0, _styledComponents.css)(["border:1px solid ", ";"], palette.validation.error), _utils.media.maxWidth(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n            overflow-x: visible;\n          "]))), isLoading && (0, _styledComponents.css)(["min-height:136px;"]), hasPagination && (0, _styledComponents.css)([".table-container{min-height:500px;min-width:", ";}"], breakpoints.tabletLandscape), spacing.basePlus);
});
StyledDataTable.displayName = 'StyledDataTable';
var _default = StyledDataTable;
exports["default"] = _default;