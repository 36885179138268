import * as R from 'ramda';
import { processSilentRenew } from 'redux-oidc';
import { isBlank, Log, sessionStorageManager, INACTIVITY_TIMEOUT_KEY } from '@trs/utils';
import { GET, POST } from '../config/http';
import * as actionTypes from './actionTypes';
import { organization, auth, configuration } from '../config/api';
import { showBanner } from './globalActions';
import { fetchFeatureToggles, setFeatureToggles } from './configurationActions';
import userManager from '../config/userManager/userManager';
import FEATURE_TOGGLE_KEYS from '../config/featureToggleKeys';
import { loadUserPermissions } from '../modules/modeller/live/actions';
import { loadUserPermissions as loadUserPermissionsBeta } from '../modules/modeller/beta/actions';
import { loadUserReportsPermissions } from '../modules/flexible-reports/actions';

const FEATURE_TOGGLES = [
  FEATURE_TOGGLE_KEYS.AUDIT,
  FEATURE_TOGGLE_KEYS.ARTICLES,
  FEATURE_TOGGLE_KEYS.FEEDBACK,
  FEATURE_TOGGLE_KEYS.DATA_VALIDATION,
  FEATURE_TOGGLE_KEYS.BENCHMARKING,
  FEATURE_TOGGLE_KEYS.REPORTS,
  FEATURE_TOGGLE_KEYS.MODELLER_SEARCH_EMPLOYEE,
  FEATURE_TOGGLE_KEYS.DYNAMIC_MODELLER,
  FEATURE_TOGGLE_KEYS.SHARES_WIDGET_DISABLED,
  FEATURE_TOGGLE_KEYS.ANALYTICS,
  FEATURE_TOGGLE_KEYS.PAYSCALES,
  FEATURE_TOGGLE_KEYS.LIVE_FX,
  FEATURE_TOGGLE_KEYS.SHARES_DATA_SETTINGS,
  FEATURE_TOGGLE_KEYS.FIXED_COSTS,
  FEATURE_TOGGLE_KEYS.LTI_PLANS,
];

export function declineDisclaimer() {
  return {
    type: actionTypes.USER_DECLINE_DISCLAIMER,
  };
}

export const getAuthorizedModules = (dispatch) => {
  let authorization = userManager.getAuthorizedModules();

  return new Promise((resolve, reject) => {
    // if (R.isNil(authorization)) {
    GET(`${auth.users}/modules`)
      .then((response) => {
        authorization = R.path(['data'], response);
        userManager.setAuthorizedModules(authorization);
        resolve(authorization);
      })
      .then(() => {
        loadUserPermissions(dispatch);
        loadUserPermissionsBeta(dispatch);
        loadUserReportsPermissions(dispatch);
      })
      .catch((err) => {
        reject(err);
      });
    // } else {
    //   resolve(authorization);
    // }
  });
};

const getUserProfile = () => {
  let profile = userManager.getProfileData();

  return new Promise((resolve, reject) => {
    if (R.isNil(profile)) {
      GET(`${organization.employeeProfile}`)
        .then((response) => {
          profile = R.path(['data'], response);
          userManager.setProfileData(profile);
          resolve(profile);
        })
        .catch((err) => {
          if (R.path(['response', 'status'], err) === 404) {
            profile = {};
            resolve(profile);
          }
          reject(err);
        });
    } else {
      resolve(profile);
    }
  });
};

export const getInactivityTimeout = () => {
  return new Promise((resolve, reject) => {
    GET(configuration.configurations)
      .then((response) => {
        const timeout = parseInt(R.path(['data', 'response'], response), 10);
        resolve(timeout);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const fetchingAuthorizedModules = (isPending) => ({
  type: actionTypes.USER_GET_AUTHORIZED_MODULES_PENDING,
  payload: {
    pending: isPending,
  },
});

export function getProfileAndAuthorization(dispatch) {
  dispatch(fetchingAuthorizedModules(true));
  return Promise.all([getUserProfile(), getAuthorizedModules(dispatch), getInactivityTimeout()])
    .then((response) => {
      // these 2 are dependent
      if (isBlank(R.path(['0'], response))) {
        dispatch({
          type: actionTypes.USER_NO_PROFILE,
        });
      } else {
        dispatch({
          type: actionTypes.USER_GET_PROFILE,
          payload: R.path(['0'], response),
        });
        dispatch({
          type: actionTypes.USER_GET_AUTHORIZED_MODULES,
          payload: R.path(['1'], response),
        });
      }

      const timeout = R.path(['2'], response);
      if (timeout) {
        sessionStorageManager.setItem(INACTIVITY_TIMEOUT_KEY, timeout);
        dispatch({
          type: actionTypes.USER_GET_INACTIVITY_TIMEOUT,
          payload: timeout,
        });
      }
    })
    .finally(() => {
      dispatch(fetchingAuthorizedModules(false));
    });
}

export const silentRenew = () => (dispatch, getState) => {
  const {
    user: { isLoggedIn },
  } = getState();

  if (isLoggedIn) {
    processSilentRenew();
  }
};

export const logoutUser = () => (dispatch) => {
  return userManager
    .getUser()
    .then((user) => {
      if (user) {
        return userManager
          .signoutRedirect()
          .then(() => {
            userManager.clearTRSData();
            dispatch({
              type: actionTypes.USER_CLEAR_DATA,
            });
          })
          .catch((err) => {
            dispatch({
              type: actionTypes.USER_LOGOUT_ERROR,
              err,
            });
            return Promise.reject(err);
          });
      }
      userManager.removeUser();
      userManager.clearTRSData();
      window.location = '/';
      return false;
    })
    .catch((err) => {
      userManager.clearTRSData();
      return Promise.reject(err);
    });
};

export function validateUser(email) {
  return (dispatch) =>
    POST(auth.validate, { email }).then((response) => {
      const isValid = response.data.response;

      dispatch({
        type: actionTypes.USER_VALIDATE,
        email,
        isValid,
      });

      return response;
    });
}
export function redirectUser(domainHint) {
  return {
    type: actionTypes.USER_REDIRECT_TO_LOGIN,
    domainHint,
  };
}

export function authSucceeded() {
  return {
    type: actionTypes.AUTHENTICATION_SUCCEEDED,
  };
}

export function generateMenu(isSetupCompleted) {
  return {
    type: actionTypes.USER_GENERATE_MENU,
    isSetupCompleted,
  };
}

function attachUserManagerEvents(dispatch) {
  function expiredTokenCB(e) {
    Log.warn(`Access token expired ${e}`, 'Access token');
    dispatch(
      showBanner({
        type: 'error',
        content: 'Access token expired',
      })
    );
    dispatch(logoutUser()).catch(() => {
      userManager.events.removeAccessTokenExpired(expiredTokenCB);
    });
  }

  function expiringTokenCB(e) {
    Log.warn(`Access token expiring ${e}`, 'Access token');
  }
  function silentRenewCB(e) {
    Log.error(`Silent renew token error ${e}`, 'Silent renew');
    dispatch(
      showBanner({
        type: 'error',
        content: 'Silent renew token error',
      })
    );
    dispatch(logoutUser()).catch(() => {
      userManager.events.removeSilentRenewError(silentRenewCB);
    });
  }

  userManager.events.removeAccessTokenExpiring(expiringTokenCB);
  userManager.events.addAccessTokenExpiring(expiringTokenCB);

  userManager.events.removeAccessTokenExpired(expiredTokenCB);
  userManager.events.addAccessTokenExpired(expiredTokenCB);

  userManager.events.removeSilentRenewError(silentRenewCB);
  userManager.events.addSilentRenewError(silentRenewCB);
}

export const loginUser = (isSetupCompleted) => (dispatch) => {
  userManager.clearStaleState();

  return new Promise((resolve, reject) => {
    userManager
      .getUser()
      .then((user) => {
        if (user) {
          attachUserManagerEvents(dispatch);
          getProfileAndAuthorization(dispatch)
            .then(() => {
              dispatch(fetchFeatureToggles(FEATURE_TOGGLES));
              dispatch(generateMenu(isSetupCompleted));
              dispatch(authSucceeded());
              resolve(true);
            })
            .catch((err) => {
              const statusCode = R.path(['response', 'status'], err);
              if (statusCode !== 404) {
                userManager.clearTRSData();
              }

              dispatch({
                type: actionTypes.USER_LOGIN_ERROR,
                statusCode,
              });
              reject(err);
            });
        } else {
          // not logged in
          dispatch(setFeatureToggles([]));
          dispatch({
            type: actionTypes.USER_LOGIN_ERROR,
          });
          reject(new Error('User login error'));
        }
      })
      .catch((err) => {
        dispatch(setFeatureToggles([]));
        dispatch({
          type: actionTypes.USER_LOGIN_ERROR,
        });
        reject(err);
      });
  });
};

export const authenticateUser = (isSetupCompleted) => (dispatch) => {
  userManager.clearStaleState();

  dispatch({
    type: actionTypes.AUTHENTICATION_IN_PROGRESS,
  });
  return userManager.getUser().then((user) => {
    if (user) {
      attachUserManagerEvents(dispatch);

      return getProfileAndAuthorization(dispatch)
        .then(() => {
          dispatch(fetchFeatureToggles(FEATURE_TOGGLES));
          dispatch(generateMenu(isSetupCompleted));
          dispatch(authSucceeded());
          return Promise.resolve(user);
        })
        .catch((err) => {
          dispatch(setFeatureToggles([]));
          dispatch({
            type: actionTypes.AUTHENTICATION_ERROR,
            err,
          });
          return Promise.reject(err);
        });
    }
    userManager.setCurentLocation(R.path(['location', 'pathname'], window));
    dispatch(setFeatureToggles([]));
    dispatch({
      type: actionTypes.AUTHENTICATION_ERROR,
    });
    return Promise.reject();
  });
};

export const renewAuthorizedModules = () => (dispatch) => {
  return getProfileAndAuthorization(dispatch).then(() => {
    dispatch(generateMenu(true));
  });
};
