import styled, { css } from 'styled-components';

const getPositionStyle = ({ position, theme }) => {
  const { palette, spacing } = theme;

  switch (position) {
    case 'top':
      return {
        tooltipPosProps: 'bottom: calc(100% + 10px); left: -10px;',
        tooltipBeforeProps: `
          bottom: -${spacing.tiny};
          left: ${spacing.tiny};
          border-top-color: ${palette.utility.border};
          border-top-width: ${spacing.tiny};
          border-top-style: solid;
        `,
        tooltipAfterProps: `
          bottom: calc(-${spacing.tiny} + 2px);
          left: ${spacing.tiny};
          border-top-color: ${palette.basic.white};
          border-top-width: ${spacing.tiny};
          border-top-style: solid;
        `,
      };
    case 'bottom':
    default:
      return {
        tooltipPosProps: 'top: 34px; left: -10px;',
        tooltipBeforeProps: `
          top: -${spacing.tiny};
          left: ${spacing.tiny};
          border-bottom-color: ${palette.utility.border};
          border-bottom-width: ${spacing.tiny};
          border-bottom-style: solid;
        `,
        tooltipAfterProps: `
          top: calc(-${spacing.tiny} + 2px);
          left: ${spacing.tiny};
          border-bottom-color: ${palette.basic.white};
          border-bottom-width: ${spacing.tiny};
          border-bottom-style: solid;
        `,
      };
  }
};

const StyledDescriptionTooltip = styled.div`
  ${({ theme, isOpen, position }) => {
    const {
      palette,
      typography,
      spacing,
      extra: { boxShadow, genericBorderRadius },
    } = theme;

    const { tooltipPosProps, tooltipBeforeProps, tooltipAfterProps } = getPositionStyle({
      position,
      theme,
    });

    return css`
      display: inline-block;

      * {
        -moz-user-select: none;
      }

      .tooltip-text {
        display: block;
        position: absolute;
        ${tooltipPosProps}
        z-index: 3;
        width: 300px;
        color: ${palette.gray.bravo};
        font-size: ${typography.size.table};
        line-height: ${typography.lineHeight.paragraphSmall};
        background-color: ${palette.basic.white};
        border: 1px solid ${palette.utility.border};
        box-shadow: ${boxShadow};
        border-radius: ${genericBorderRadius};

        .wrapper-class {
          max-width: 550px;
          max-height: 160px;
          overflow-y: auto;
          padding: ${spacing.tiny};
        }

        &:before {
          content: '';
          position: absolute;
          ${tooltipBeforeProps}
          border-left: ${spacing.tiny} solid transparent;
          border-right: ${spacing.tiny} solid transparent;
        }

        &:after {
          content: '';
          position: absolute;
          ${tooltipAfterProps}
          border-left: ${spacing.tiny} solid transparent;
          border-right: ${spacing.tiny} solid transparent;
        }

        .public-DraftEditor-content {
          > div {
            > div:first-child > div {
              margin-top: 0;
            }

            > div:last-child > div {
              margin-bottom: 0;
            }
          }
        }
      }

      a {
        color: ${palette.secondary};
      }

      i {
        border: 1px solid ${palette.basic.gray};
        border-radius: 50%;
        padding: 2px 6px;
        cursor: pointer;

        ${isOpen &&
          css`
            background-color: ${palette.secondary};
            border-color: ${palette.secondary};
            color: ${palette.basic.white};
          `}
      }
    `;
  }}
`;

StyledDescriptionTooltip.displayName = 'StyledDescriptionTooltip';
export default StyledDescriptionTooltip;
