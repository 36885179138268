"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var StyledIcon = _styledComponents["default"].i.withConfig({
  displayName: "Iconstyle__StyledIcon",
  componentId: "sc-1k8n7sr-0"
})(["", ";"], function (props) {
  var size = props.size,
    disabled = props.disabled,
    color = props.theme.palette,
    hasError = props.hasError,
    collapsable = props.collapsable;
  var palette = props.theme.palette;
  return (0, _styledComponents.css)(["font-size:", "px;outline:none;", " ", ";"], size, collapsable && (0, _styledComponents.css)(["&&&{order:1;z-index:1;margin:0 auto;background-color:", ";color:", ";padding:10px;border-radius:50%;transform:translate(0,-50%);border:2px solid ", ";}"], color.basic.white, color.gray.bravo, hasError ? color.validation.errorLight : color.gray.foxtrot), disabled && (0, _styledComponents.css)(["&&&{color:", ";cursor:not-allowed;}"], palette.gray.charlie));
});
StyledIcon.displayName = 'StyledIcon';
var _default = StyledIcon;
exports["default"] = _default;