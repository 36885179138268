const instanceActionTypes = {
  ADD_COMPARE: 'ADD_COMPARE',
  REMOVE_COMPARE: 'REMOVE_COMPARE',
  SET_INITIAL_FIELDS: 'SET_INITIAL_FIELDS',
  RE_INITIALIZE_FIELDS: 'RE_INITIALIZE_FIELDS',
  LOAD_DATA_PENDING: 'LOAD_DATA_PENDING',
  LOAD_DATA_SUCCESS: 'LOAD_DATA_SUCCESS',
  LOAD_DATA_ERROR: 'LOAD_DATA_ERROR',
  VALIDATE_MODELLER: 'VALIDATE_MODELLER',
  LOAD_MODELLER_OUTPUT: 'LOAD_MODELLER_OUTPUT',
  VALIDATE_INSTANCE: 'VALIDATE_INSTANCE',
  SELECT_EMPLOYEE: 'SELECT_EMPLOYEE',
  SAVE_DEFAULT_PAYSCALE: 'SAVE_DEFAULT_PAYSCALE',
  SAVE_DEFAULT_CURRENCY: 'SAVE_DEFAULT_CURRENCY',
  LOAD_COMPENSATION_GRADE_PROFILES_PENDING: 'LOAD_COMPENSATION_GRADE_PROFILES_PENDING',
  LOAD_COMPENSATION_GRADE_PROFILES_SUCCESS: 'LOAD_COMPENSATION_GRADE_PROFILES_SUCCESS',
  LOAD_COMPENSATION_GRADE_PROFILES_ERROR: 'LOAD_COMPENSATION_GRADE_PROFILES_ERROR',
  RESET_STORE: 'RESET_STORE',
};

export default instanceActionTypes;
