import {
  getExcludedRewardsPending,
  getExcludedRewardsSuccess,
  getExcludedRewardsError,
  deleteExcludedRewardSuccess,
} from '../actions';
import { mapExcludedRewards } from './helpers';

export default [
  [
    getExcludedRewardsPending,
    state => ({
      ...state,
      isRewardsLoading: true,
    }),
  ],
  [
    getExcludedRewardsError,
    state => ({
      ...state,
      isRewardsLoading: false,
    }),
  ],
  [
    getExcludedRewardsSuccess,
    (state, action) => ({
      ...state,
      isRewardsLoading: false,
      excludedRewards: mapExcludedRewards(action.payload),
    }),
  ],
  [
    deleteExcludedRewardSuccess,
    (state, action) => ({
      ...state,
      rewardIds: action.payload,
      excludedRewards: mapExcludedRewards(action.payload),
    }),
  ],
];
