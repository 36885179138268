import * as R from 'ramda';
import * as types from '../actions/types';
import initialState from './initialState';
import mapRequestToRewardsOverviewModel from './helpers';
import { filterCountriesByAccess } from '../../../../common/helpers';

const dashboard = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.GET_DASHBOARD_COUNTRIES: {
      const authorizedCountries = filterCountriesByAccess({
        countries: R.prop('countries', action),
        authorizedModules: R.prop('authorizedModules', action),
      });

      const countries = authorizedCountries.map(country => ({
        label: country.name,
        value: country.code,
      }));

      return {
        ...state,
        countries,
        selectedCountry: R.head(countries),
      };
    }
    case types.SELECT_COUNTRY_BEGIN:
      return {
        ...state,
        selectedCountry: action.country,
      };

    case types.WIDGET_EXCEPTIONS_START: {
      return {
        ...state,
        exceptionsLoading: true,
      };
    }

    case types.WIDGET_EXCEPTIONS_FINISH:
      return {
        ...state,
        exceptions: action.exceptions.data.response,
        exceptionsLoading: false,
      };

    case types.WIDGET_REWARDS_OVERVIEW_START: {
      return {
        ...state,
        rewardsLoading: true,
      };
    }

    case types.WIDGET_REWARDS_OVERVIEW_FINISH: {
      const rewardsOverview = mapRequestToRewardsOverviewModel(action.rewardsOverview.data);

      return {
        ...state,
        rewardsOverview,
        rewardsLoading: false,
      };
    }

    case types.DASHBOARD_RESET: {
      return {
        ...initialState,
        countries: R.clone(state.countries),
      };
    }

    default:
      return state;
  }
};

export default dashboard;
