"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Tabs = exports.Tab = exports.StyledMediaCreator = exports.StyledEditMenu = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var StyledEditMenu = _styledComponents["default"].div.withConfig({
  displayName: "StyledTemplatePopupstyle__StyledEditMenu",
  componentId: "sc-1jg1wz8-0"
})(["", ";"], function (_ref) {
  var theme = _ref.theme;
  return (0, _styledComponents.css)(["position:absolute;background-color:", ";top:4%;right:3%;z-index:11;border-radius:4px;.menu-list{display:flex;flex-direction:column;align-items:flex-start;}button{padding:", " ", ";text-decoration:none;color:", ";&:hover{color:", ";}i{margin-right:", ";&.delete-icon{margin-right:", ";}}}.input-clear-icon{float:right;margin-right:-", ";margin-top:-6px;color:", ";cursor:pointer;}"], theme.palette.basic.white, theme.spacing.xSmall, theme.spacing.small, theme.palette.gray.alpha, theme.palette.basic.black, theme.spacing.tiny, theme.spacing.small, theme.spacing.xSmall, theme.palette.primary);
});
exports.StyledEditMenu = StyledEditMenu;
var StyledMediaCreator = _styledComponents["default"].div.withConfig({
  displayName: "StyledTemplatePopupstyle__StyledMediaCreator",
  componentId: "sc-1jg1wz8-1"
})(["", ";"], function (_ref2) {
  var theme = _ref2.theme;
  return (0, _styledComponents.css)(["top:3%;right:3%;z-index:11;position:absolute;width:300px;display:block;flex-direction:column;align-items:flex-start;background-color:", ";box-shadow:", ";border-radius:4px;padding:0px ", ";.popup-title{font-weight:", ";margin-bottom:", ";}.input-clear-icon{float:right;margin-right:-", ";margin-top:-5px;color:", ";cursor:pointer;}.helpertext{color:", ";height:", ";}.footer{display:flex;justify-content:flex-end;padding:", " ", ";button{margin-left:", ";padding:", " ", ";p{margin:0px;}}}"], theme.palette.basic.white, theme.extra.boxShadow, theme.spacing.xSmall, theme.typography.weight.bold, theme.spacing.tiny, theme.spacing.small, theme.palette.primary, theme.palette.primary, theme.spacing.smallPlus, theme.spacing.small, theme.spacing.tiny, theme.spacing.tiny, theme.spacing.tiny, theme.spacing.small);
});
exports.StyledMediaCreator = StyledMediaCreator;
var Tabs = _styledComponents["default"].div.withConfig({
  displayName: "StyledTemplatePopupstyle__Tabs",
  componentId: "sc-1jg1wz8-2"
})(["", ""], function (_ref3) {
  var theme = _ref3.theme;
  return (0, _styledComponents.css)(["display:flex;flex-direction:row;width:100%;padding-bottom:", ";"], theme.spacing.tiny);
});
exports.Tabs = Tabs;
var Tab = _styledComponents["default"].div.withConfig({
  displayName: "StyledTemplatePopupstyle__Tab",
  componentId: "sc-1jg1wz8-3"
})(["", ";"], function (_ref4) {
  var theme = _ref4.theme;
  return (0, _styledComponents.css)(["flex:1;cursor:pointer;p{display:inline-flex;margin:0;padding:", ";border-top:3px solid transparent;}&.active{p{color:", ";border-top:3px solid ", ";}}"], theme.spacing.xTiny, theme.palette.primary, theme.palette.primary);
});
exports.Tab = Tab;