const actions = {
  FETCH_START: 'cms/fetch_start',
  FETCH_OK: 'cms/fetch_ok',
  FETCH_FAIL: 'cms/fetch_fail',
  FETCH_HAS_DATA: 'cms/fetch_has_data',
  THEME_SWITCH: 'cms/theme_switch',
  THEME_EMPTY_GET: 'cms/theme/empty_get',
  THEME_GET: 'cms/theme/get',
  LOGO_GET: 'cms/logo/get',
  LOGO_GET_ERROR: 'cms/logo/error',
  PDF_LOGO_GET: 'cms/pdfLogo/get',
};
export default actions;
