import * as R from 'ramda';
import { handleActions } from 'redux-actions';
import {
  getCategoriesMapping,
  changeCategoriesMapping,
  resetCategoriesMapping,
  submitActions,
} from '../actions';
import initialState from './initialState';
import { mapPayloadToCategoryMappings, mapPayloadToTableStructure } from './helpers';
import BM_STATUS from '../../../../../../common/helpers';

const submitHandler = [
  [submitActions.pending, state => ({ ...state, isSubmitting: true })],
  [
    submitActions.success,
    state => ({
      ...state,
      isSubmitting: false,
      status: BM_STATUS.SUBMITTED,
      hasChanges: false,
    }),
  ],
  [submitActions.error, state => ({ ...state, isSubmitting: false })],
];

const reducer = handleActions(
  new Map([
    [
      getCategoriesMapping,
      (state, action) => {
        const { payload, ready, error } = action;

        if (!ready) {
          return {
            ...state,
            isLoading: true,
          };
        }

        if (error) {
          return {
            ...state,
            isLoading: false,
            categories: {},
            mappings: {},
            globalSettingsId: null,
          };
        }

        const { categoriesMappings, etag, status, globalSettingsId } = payload;

        return {
          ...state,
          hasChanges: false,
          isLoading: false,
          categories: mapPayloadToTableStructure(categoriesMappings),
          etag,
          status,
          globalSettingsId,
          mappings: mapPayloadToCategoryMappings(categoriesMappings),
        };
      },
    ],
    [
      changeCategoriesMapping,
      (state, { payload: { key, newMapping } }) => ({
        ...state,
        hasChanges: true,
        mappings: {
          ...state.mappings,
          [key]: newMapping,
        },
      }),
    ],
    [resetCategoriesMapping, () => R.clone(initialState)],
    ...submitHandler,
  ]),
  R.clone(initialState)
);

export default reducer;
