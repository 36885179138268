import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Paragraph, Button } from '@trs/components';
import * as R from 'ramda';
import { Link } from 'react-router-dom';
import NotificationHeader from 'modules/notifications/components/header';
import Text, { TextOnly } from 'modules/common/Text';

export const GeneratePayscalesNotification = ({ item, markNotification }) => {
  const { id, createdDate } = item;

  return (
    <>
      <NotificationHeader
        createdDate={createdDate}
        title={TextOnly({
          path: 'notifications.NOTIFICATION_GENERATED_PAYSCALES_PUBLISHED_TITLE',
        })}
      />
      <Paragraph className="notification-content">
        {TextOnly({
          path: 'notifications.NOTIFICATION_GENERATED_PAYSCALES_PUBLISHED_CONTENT',
        })}
        <Link to="/settings/hr_system" onClick={() => markNotification(R.prop('id', item))}>
          <Text path="notifications.NOTIFICATION_GENERATED_PAYSCALES_PUBLISHED_LINK" />
        </Link>
        <Button type="link" className="notification-link" action={() => markNotification(id)}>
          <Text path="notifications.MARK_AS_READ" />
        </Button>
      </Paragraph>
    </>
  );
};
GeneratePayscalesNotification.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    content: PropTypes.shape({ status: PropTypes.string.isRequired }),
    createdDate: PropTypes.string.isRequired,
  }).isRequired,
  markNotification: PropTypes.func.isRequired,
};

export default memo(GeneratePayscalesNotification);
