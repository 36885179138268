import * as R from 'ramda';
import { handleActions } from 'redux-actions';
import { isBlank } from '@trs/utils';
import { mapBEToFE } from '../helpers';
import { finishCalculate, meritReset, getPrevious } from '../actions';
import { getPremerit, calculatePremerit } from '../actions/permitAction';
import initialState from './initialState';

const mapPayload = (data) => ({
  target: isBlank(R.prop('target', data)) ? [] : mapBEToFE(R.prop('target', data)),
  reference: isBlank(R.prop('reference', data)) ? [] : mapBEToFE(R.prop('reference', data)),
  compensationData: isBlank(R.prop('compensationData', data))
    ? []
    : mapBEToFE(R.prop('compensationData', data), true),
});

const previousHandler = [
  getPrevious,
  (state, action) => {
    const {
      ready,
      error,
      payload: { data, metadata },
    } = action;

    if (!ready || error) {
      return {
        ...state,
      };
    }

    return {
      ...state,
      previous: {
        preMerit: mapPayload(R.prop('preMerit', data)),
        postMerit: mapPayload(R.prop('postMerit', data)),
        metadata,
      },
    };
  },
];

const meritHandler = [
  previousHandler,
  [meritReset, () => R.clone(initialState)],

  [
    finishCalculate,
    (state) => ({
      ...state,
      calculatingMerit: false,
    }),
  ],
  [
    calculatePremerit,
    (state) => ({
      ...state,
      calculatingMerit: true,
      preMerit: {
        target: [],
        reference: [],
        compensationData: [],
        metadata: {},
      },
      postMerit: {
        target: [],
        reference: [],
        compensationData: [],
        metadata: {},
      },
    }),
  ],
  [
    getPremerit,
    (state, action) => {
      const {
        ready,
        error,
        payload: { data, metadata },
      } = action;

      if (!ready) {
        return {
          ...state,
          isLoadingMerit: true,
        };
      }

      if (error) {
        return {
          ...state,
          meritError: error,
          isLoadingMerit: false,
        };
      }

      return {
        ...state,
        preMerit: mapPayload(R.prop('preMerit', data)),
        postMerit: mapPayload(R.prop('postMerit', data)),
        metadata,
        isLoadingMerit: false,
      };
    },
  ],
];

const reducer = handleActions(new Map(meritHandler), R.clone(initialState));

export default reducer;
