export const defaultShare = {
  providerShareSymbol: '',
  shareName: '',
  shareSymbol: '',
};

export const defaultProvider = {
  id: null,
  name: '',
  url: '',
  shares: [defaultShare],
};
