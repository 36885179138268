import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import GlobalStyle from '../../../config/style';

const history = createBrowserHistory();

const PdfExport = ({ children, theme }) => {
  const store = window.GLOBAL_STORE;

  return (
    <Provider store={store}>
      <Router history={history}>
        <Fragment>
          <GlobalStyle />
          <ThemeProvider theme={theme.data}>{children}</ThemeProvider>
        </Fragment>
      </Router>
    </Provider>
  );
};

PdfExport.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.shape({
    data: PropTypes.shape({}),
  }).isRequired,
};

export default PdfExport;
