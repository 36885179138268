import styled, { css } from 'styled-components';

const StyledProvider = styled.span`
  ${props => {
    const { spacing, typography } = props.theme;

    return css`
      display: block;
      margin-top: ${spacing.xTiny};
      font-weight: ${typography.weight.bold};
    `;
  }};
`;

StyledProvider.displayName = 'StyledProvider';

export default StyledProvider;
