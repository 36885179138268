import * as actions from '../actions/types';

export const initialState = {
  status: 'optional',
  airincUrl: '',
  airincAccount: '',
  companyUser: '',
  password: '',
  file: null,
  isSaving: false,
};

export default function mobilityReducer(state = initialState, action) {
  switch (action.type) {
    case actions.MOBILITY_FIELD_CHANGE: {
      return {
        ...state,
        [action.field]: action.value,
      };
    }
    case actions.MOBILITY_SAVE_PENDING: {
      return {
        ...state,
        isSaving: true,
      };
    }
    default: {
      return state;
    }
  }
}
