import React, { memo } from 'react';
import PropTypes from 'prop-types';
import StyledEmployeeSatisfactionCol from './EmployeeSatisfactionCol.style';
import { FEEDBACK_RATING_ICON_SIZE_TINY } from '../../constants';
import Face from '../face/Face';

const EmployeeSatisfactionCol = ({ hasFeedback, score, statusIconName }) => {
  return (
    <StyledEmployeeSatisfactionCol>
      <Face status={statusIconName} size={FEEDBACK_RATING_ICON_SIZE_TINY} />
      <div className="score">{hasFeedback ? `${score}%` : '-'}</div>
    </StyledEmployeeSatisfactionCol>
  );
};

EmployeeSatisfactionCol.propTypes = {
  statusIconName: PropTypes.string.isRequired,
  score: PropTypes.number.isRequired,
  hasFeedback: PropTypes.bool.isRequired,
};

export default memo(EmployeeSatisfactionCol);
