import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import * as R from 'ramda';
import { Icon, H5 } from '@trs/components';

import { notifications } from '../../../../config/api';
import * as notificationsActions from '../../actions';
import Text from '../../../common/Text';
import StyledNotificationsSidebar from './NotificationsSidebar.style';
import NotificationsEngine from '../engine/NotificationsEngine';
import NotificationsItem from '../item/NotificationsItem';

class NotificationsSidebar extends Component {
  toggle = () => {
    this.props.actions.toggleNotificationsWidget();
  };

  render() {
    const { list, isWidgetOpen } = this.props.notifications;

    return (
      <Fragment>
        <NotificationsEngine wsUrl={notifications.ws} actions={this.props.actions} />
        {isWidgetOpen && (
          <StyledNotificationsSidebar>
            <div className="widget-title">
              <H5>
                <Text path="notifications.WIDGET_TITLE" />
              </H5>
              <Icon
                icon="ion-close"
                className="widget-close"
                onClick={this.toggle}
                size={16}
                aria-label="Close notifications"
              />
            </div>

            <ul className="widget-content">
              {!R.length(list) ? (
                <li className="widget-item">
                  <Text path="notifications.NO_RESULTS" />
                </li>
              ) : (
                <Fragment>
                  {R.map(
                    item => (
                      <NotificationsItem item={item} key={item.id} />
                    ),
                    list
                  )}
                </Fragment>
              )}
            </ul>
          </StyledNotificationsSidebar>
        )}
      </Fragment>
    );
  }
}

NotificationsSidebar.propTypes = {
  actions: PropTypes.shape({
    toggleNotificationsWidget: PropTypes.func,
  }).isRequired,
  notifications: PropTypes.shape({
    list: PropTypes.arrayOf(PropTypes.shape({})),
    isWidgetOpen: PropTypes.bool,
  }).isRequired,
};

const mapStateToProps = state => ({
  notifications: state.notifications,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(notificationsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsSidebar);
