export const GET_REPORTS_PENDING = 'GET_REPORTS_PENDING';
export const GET_REPORTS_FAILURE = 'GET_REPORTS_FAILURE';
export const GET_REPORTS_SUCCESS = 'GET_REPORTS_SUCCESS';
export const GET_REPORT_PENDING = 'GET_REPORT_PENDING';
export const GET_REPORT_SUCCESS = 'GET_REPORT_SUCCESS';
export const UPDATE_COUNTRIES = 'UPDATE_COUNTRIES';
export const SELECT_COUNTRY = 'SELECT_COUNTRY';
export const CLEAR_REPORT_DATA = 'CLEAR_REPORT_DATA';
export const TRIGGER_COUNTRY_REWARDS_EXPORT = 'TRIGGER_COUNTRY_REWARDS_EXPORT';
export const CHANGE_REPORT_DEFINITION = 'CHANGE_REPORT_DEFINITION';
export const CLEAR_REPORT_DEFINITION = 'CLEAR_REPORT_DEFINITION';
export const ADD_EXTERNAL_REPORT_PENDING = 'ADD_EXTERNAL_REPORT_PENDING';
export const ADD_EXTERNAL_REPORT_FAILURE = 'ADD_EXTERNAL_REPORT_FAILURE';
export const ADD_EXTERNAL_REPORT_SUCCESS = 'ADD_EXTERNAL_REPORT_SUCCESS';
export const GET_EXTERNAL_REPORTS_PENDING = 'GET_EXTERNAL_REPORTS_PENDING';
export const GET_EXTERNAL_REPORTS_SUCCESS = 'GET_EXTERNAL_REPORTS_SUCCESS';
export const GET_EXTERNAL_REPORTS_FAILURE = 'GET_EXTERNAL_REPORTS_FAILURE';
export const GET_FLEX_REPORTS_PENDING = 'GET_FLEX_REPORTS_PENDING';
export const GET_FLEX_REPORTS_SUCCESS = 'GET_FLEX_REPORTS_SUCCESS';
export const GET_FLEX_REPORTS_FAILURE = 'GET_FLEX_REPORTS_FAILURE';
export const GET_FLEX_EMBED_REPORTS_PENDING = 'GET_FLEX_EMBED_REPORTS_PENDING';
export const GET_FLEX_EMBED_REPORTS_SUCCESS = 'GET_FLEX_EMBED_REPORTS_SUCCESS';
export const GET_FLEX_EMBED_REPORTS_FAILURE = 'GET_FLEX_EMBED_REPORTS_FAILURE';
export const GET_REPORT_PERMISSIONS = 'GET_REPORT_PERMISSIONS';
export const GET_CATEGORIES_MAPPING_REPORT = 'GET_CATEGORIES_MAPPING_REPORT';
export const CHANGE_CATEGORIES_MAPPING_REPORT = 'CHANGE_CATEGORIES_MAPPING_REPORT';
export const RESET_CATEGORIES_MAPPING_REPORT = 'RESET_CATEGORIES_MAPPING_REPORT';
export const SUBMIT_CATEGORIES_MAPPING_PENDING = 'SUBMIT_CATEGORIES_MAPPING_PENDING';
export const SUBMIT_CATEGORIES_MAPPING_SUCCESS = 'SUBMIT_CATEGORIES_MAPPING_SUCCESS';
export const SUBMIT_CATEGORIES_MAPPING_ERROR = 'SUBMIT_CATEGORIES_MAPPING_ERROR';
export const SET_LAUNCHER_SCREEN_VISIBILITY = 'SET_LAUNCHER_SCREEN_VISIBILITY';
