import * as R from 'ramda';
import { sortByPropCaseInsensitive } from '@trs/utils';

export const mapRequestToSharesModel = (shares) =>
  shares.map((share) => {
    const data = {
      name: share.name,
      provider: share.provider,
      sharePrice: share.sharePrice,
      priceLastUpdated: share.priceLastUpdated,
      shareData: [
        {
          shareStatus: 'vested',
          count: share.vested.count,
          /** The value key is used by the recharts library in order to display the pie chart */
          value: share.vested.percentage,
          amount: share.vested.amount,
          employeeAmount: share.vested.employeeAmount,
        },
        {
          shareStatus: 'unvested',
          count: share.unvested.count,
          value: share.unvested.percentage,
          amount: share.unvested.amount,
          employeeAmount: share.unvested.employeeAmount,
        },
      ],
    };

    return data;
  });

export const getSplitId = (input = '') => R.head(R.split('_', input));

export const getCategoryFromCategoryId = (categoryLevel1Id) =>
  R.find(R.propEq('id', getSplitId(categoryLevel1Id)));

export const getNameFromCategoryId = R.curry((categoryMappings, category) => {
  const categoryObj = getCategoryFromCategoryId(R.prop('categoryLevel1Id', category))(
    categoryMappings
  );
  return {
    ...category,
    orderIndex: R.prop('orderIndex', categoryObj),
    displayName: R.prop('displayName', categoryObj),
  };
});

export const mapCategoryNameToId = ({ categoryMappings, employeeCategories }) => ({
  ...employeeCategories,
  categories: R.sortBy(
    R.prop('orderIndex'),
    R.map(getNameFromCategoryId(categoryMappings), R.prop('categories', employeeCategories))
  ),
  extraCategories: R.sortBy(
    R.prop('orderIndex'),
    R.map(getNameFromCategoryId(categoryMappings), R.prop('extraCategories', employeeCategories))
  ),
});

const getIsAmount = (item) => item.isAmount;

export const getSortedTextOnlyRewards = R.compose(
  sortByPropCaseInsensitive('name'),
  R.reject(getIsAmount)
);

export const sortTextOnlyAlphabetically = (data) => {
  // filter only amount data
  const amountData = data.filter(getIsAmount);
  // filter only text and sort items
  const sortedTextOnly = getSortedTextOnlyRewards(data);
  return [...amountData, ...sortedTextOnly];
};

export default {
  mapRequestToSharesModel,
  mapCategoryNameToId,
  sortTextOnlyAlphabetically,
  getSortedTextOnlyRewards,
};
