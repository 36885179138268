import * as R from 'ramda';
import { betaFeaturePrefix } from '@trs/utils';
import { getTypeFromPayload } from './helpers';
import * as actionTypes from '../actions/actionTypes';

const initialState = {
  featureToggles: [],
  betaFeatureToggles: {},
  isFeatureTogglesLoading: true,
};

export default function configurationReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.CONFIGURATION_SET_FEATURE_TOGGLES: {
      const { featureToggles } = action;
      return {
        ...state,
        featureToggles: R.reduce(
          (accumulator, currentValue) => {
            if (currentValue.isEnabled) return accumulator.concat([currentValue.name]);
            return accumulator;
          },
          [],
          featureToggles
        ),
        betaFeatureToggles: R.reduce(
          (next, currentValue) => {
            if (currentValue.isEnabled) {
              const betaName = `${betaFeaturePrefix}${currentValue.name}`;
              return { ...next, [betaName]: getTypeFromPayload(currentValue) };
            }
            return next;
          },
          {},
          featureToggles
        ),
        isFeatureTogglesLoading: false,
      };
    }

    default:
      return state;
  }
}
