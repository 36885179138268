import * as R from 'ramda';
import { createAction } from 'redux-actions';

const actionTypes = {
  BENCHMARKING_LOCAL_PREPARATION_ETAG: 'BENCHMARKING_LOCAL_PREPARATION_ETAG',
};
export const etagUpdate = createAction(actionTypes.BENCHMARKING_LOCAL_PREPARATION_ETAG);

export const mapRewardsToSearch = rewardList =>
  R.map(
    item => ({
      ...item,
      description: item.displayName,
    }),
    rewardList
  );

export default { mapRewardsToSearch, etagUpdate };
