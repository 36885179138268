import * as R from 'ramda';

export const mergeModellerInstanceWithOutput = (instances, payload) =>
  R.keys(instances).reduce(
    (res, instanceKey) => ({
      ...res,
      [instanceKey]: {
        ...instances[instanceKey],
        output: R.path(
          ['response', 'results', 'employees', R.keys(instances).indexOf(instanceKey)],
          payload
        ),
      },
    }),
    {}
  );

export default mergeModellerInstanceWithOutput;
