const initialState = {
  requestId: undefined,
  isSearchDialogVisible: false,
  isSearchInProgress: false,
  isLoading: false,
  isSubmitting: false,
  mappings: {},
  hasChanges: false,
  etag: null,
  status: null,
  variables: [],
  countries: [],
  currencies: [],
  sources: [],
  categories: {},
  typesCategories: [],
  selectedInstance: undefined,
  error: {
    isError: undefined,
    response: '',
    status: undefined,
    statusText: '',
  },
  isModellerValid: false,
  modellerTabIndex: undefined,
  type: undefined,
};

export default initialState;
