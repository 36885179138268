import * as R from 'ramda';
import { createAction } from 'redux-actions';
import { GET, RequestFactory, PATCH } from '../../../../../../../config/http';
import { showBanner } from '../../../../../../../actions/globalActions';
import { benchmark } from '../../../../../../../config/api';
import { TextOnly } from '../../../../../../common/Text';
import { loadGlobalIdentifiers } from '../../../../global/actions';
import { dispatchError } from '../../../../common/helpers';

const actionTypes = {
  BENCHMARKING_LOAD_PAYSCALE_INCREASE: 'BENCHMARKING_LOAD_PAYSCALE_INCREASE',
  BENCHMARKING_PAYSCALE_INCREASE_CANCEL_EDITABLE: 'BENCHMARKING_PAYSCALE_INCREASE_CANCEL_EDITABLE',
  BENCHMARKING_PAYSCALE_INCREASE_CELL_CHANGE: 'BENCHMARKING_PAYSCALE_INCREASE_CELL_CHANGE',
  BENCHMARKING_SAVE_PAYSCALE_PROPOSAL_PENDING: 'BENCHMARKING_SAVE_PAYSCALE_PROPOSAL_PENDING',
  BENCHMARKING_SAVE_PAYSCALE_PROPOSAL_SUCCESS: 'BENCHMARKING_SAVE_PAYSCALE_PROPOSAL_SUCCESS',
  BENCHMARKING_SAVE_PAYSCALE_PROPOSAL_ERROR: 'BENCHMARKING_SAVE_PAYSCALE_PROPOSAL_ERROR',
  BENCHMARKING_PAYSCALE_INCREASE_LOAD_STATUS: 'BENCHMARKING_PAYSCALE_INCREASE_LOAD_STATUS',
  BENCHMARKING_CALCULATE_PAYSCALE_PROPOSAL: 'BENCHMARKING_CALCULATE_PAYSCALE_PROPOSAL',
  BENCHMARKING_GET_COST_TO_BRING_TO_MINIMUM: 'BENCHMARKING_GET_COST_TO_BRING_TO_MINIMUM',
  BENCHMARKING_PAYSCALE_RESET: 'BENCHMARKING/PAYSCALE/RESET',
  BENCHMARKING_PAYSCALE_DATA_VALID: 'BENCHMARKING_PAYSCALE_DATA_VALID',
};

export const loadPayscaleIncreaseSuccess = createAction(
  actionTypes.BENCHMARKING_LOAD_PAYSCALE_INCREASE
);
export const cancelEditable = createAction(
  actionTypes.BENCHMARKING_PAYSCALE_INCREASE_CANCEL_EDITABLE
);
export const cellChange = createAction(actionTypes.BENCHMARKING_PAYSCALE_INCREASE_CELL_CHANGE);
export const saveProposal = {
  pending: createAction(actionTypes.BENCHMARKING_SAVE_PAYSCALE_PROPOSAL_PENDING),
  success: createAction(actionTypes.BENCHMARKING_SAVE_PAYSCALE_PROPOSAL_SUCCESS),
  error: createAction(actionTypes.BENCHMARKING_SAVE_PAYSCALE_PROPOSAL_ERROR),
};
export const getPayscaleProposal = createAction(
  actionTypes.BENCHMARKING_CALCULATE_PAYSCALE_PROPOSAL
);
export const getCostToBringToMinimum = createAction(
  actionTypes.BENCHMARKING_GET_COST_TO_BRING_TO_MINIMUM
);
export const resetPayscale = createAction(actionTypes.BENCHMARKING_PAYSCALE_RESET);
export const validateDataForSubmit = createAction(actionTypes.BENCHMARKING_PAYSCALE_DATA_VALID);

export const loadPayscaleIncrease = () => (dispatch, getState) =>
  dispatch(loadGlobalIdentifiers()).then(globalBody => {
    const globalBenchmarkingId = R.prop('id', globalBody);
    const localPayscaleId = R.path(
      ['benchmarking', 'local', 'main', 'statusData', 'payscaleIncrease', 'id'],
      getState()
    );

    return dispatch(
      loadPayscaleIncreaseSuccess(
        GET(
          `${benchmark.benchmarking}/${globalBenchmarkingId}/payscaleIncreases/${localPayscaleId}`
        )
          .then(resp => {
            const data = R.path(['data', 'response'], resp);
            return { data };
          })
          .catch(error => {
            dispatchError(error, dispatch);
            return Promise.reject(error);
          })
      )
    );
  });

export const savePayscaleIncrease = () => (dispatch, getState) => {
  const { proposedPayscales, payscaleId, etag } = R.path(
    ['benchmarking', 'local', 'payscaleIncrease'],
    getState()
  );
  const benchmarkId = R.path(['benchmarking', 'global', 'main', 'identifiers', 'id'], getState());

  const payload = {
    proposedPayscales: R.map(
      item => ({
        populationId: item.populationId,
        populationName: item.populationName,
        currentYearPayPoint: item.currentYearPayPoint,
        middlePayPoint: item.middlePayPoint,
      }),
      proposedPayscales
    ),
  };
  const requestUrl = `${benchmark.benchmarking}/${benchmarkId}/payscaleIncreases/${payscaleId}`;

  const opts = {
    url: requestUrl,
    method: 'PATCH',
    headers: { Etag: etag },
    data: payload,
    options: { showErrorModal: true, dispatch },
  };

  dispatch(saveProposal.pending());

  return RequestFactory(opts.method, opts.url, opts.headers, opts.data, opts.options)
    .then(response => {
      const {
        proposedPayscales: newPayscales,
        payscaleIntervals,
        etag: Etag,
        isDataValid,
      } = R.path(['data', 'response'], response);
      dispatch(saveProposal.success({ newPayscales, payscaleIntervals, Etag }));
      dispatch(validateDataForSubmit(isDataValid));
      dispatch(
        showBanner({
          type: 'success',
          content: TextOnly({ path: 'benchmarking.PAYSCALE_PROPOSAL_SAVE_SUCCESS' }),
        })
      );
    })
    .catch(error => {
      dispatch(saveProposal.error());
      dispatchError(error, dispatch);
      return Promise.reject(error);
    });
};

export const calculateProposedPayscale = () => (dispatch, getState) => {
  const { benchmarkingId, payscaleId, etag, proposedPayscales } = R.path(
    ['benchmarking', 'local', 'payscaleIncrease'],
    getState()
  );

  const requestUrl = `${
    benchmark.benchmarking
  }/${benchmarkingId}/payscaleIncreases/${payscaleId}/calculation`;

  return dispatch(
    getPayscaleProposal(
      PATCH(requestUrl, { proposedPayscales }, { Etag: etag }).then(response => ({
        data: R.path(['data', 'response'], response),
        etag: R.path(['headers', 'etag'], response),
      }))
    )
  );
};

export const loadCostToBringToMinimum = () => (dispatch, getState) => {
  const { statusData } = R.path(['benchmarking', 'local', 'main'], getState());
  const {
    benchmarkingId,
    payscaleIncrease: { id: localPayscaleId },
  } = statusData;

  return dispatch(
    getCostToBringToMinimum(
      GET(`${benchmark.benchmarking}/${benchmarkingId}/payscaleIncreases/${localPayscaleId}`)
        .then(resp => R.path(['data', 'response'], resp))
        .catch(error => {
          dispatchError(error, dispatch);
          return Promise.reject(error);
        })
    )
  );
};
