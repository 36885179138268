import styled, { css } from 'styled-components';

const StyledEditableField = styled.span`
  ${props => {
    const { spacing, typography, palette: color } = props.theme;
    const { fullWidthSlider, editingWidth, isEditing } = props;
    const editActionsWidth = '56px';

    return css`
      align-items: center;
      display: flex;
      line-height: ${typography.lineHeight.high};
      justify-content: space-between;
      align-items: stretch;
      ${fullWidthSlider
        ? css`
            &&& {
              flex-direction: column;
              .editable-field-confirm {
                display: flex;
                justify-content: flex-end;
              }
            }
          `
        : ''}

      .editable-field-action {
        cursor: pointer;
        opacity: 0.5;
        transition: 0.2s linear opacity;
        i {
          font-size: ${typography.size.base};
        }
        &:hover:not(:disabled) {
          opacity: 1;
        }
        &:disabled {
          cursor: not-allowed;
        }
      }
      .input-component input {
        background-color: transparent;
        width: 100px;
        padding: 0 ${spacing.xTiny} ${spacing.tiny};
      }
      input {
        height: 100%;
        font-size: ${typography.size.base};
      }
      label.radio-wrapper {
        width: ${spacing.smallPlus};
        height: ${spacing.smallPlus};
        margin-left: ${spacing.tiny};
        border: 0;
        flex: 0 0 ${spacing.smallPlus};
        i {
          font-size: ${typography.size.misc};
          color: ${color.basic.white};
        }
        &:first-child {
          background-color: ${color.secondary};
        }
        &:nth-child(2) {
          background-color: ${color.validation.error};
        }
      }
      .option-edit {
        color: ${color.secondary};
        cursor: pointer;
        margin-left: ${spacing.tiny};
        margin-right: ${spacing.xTiny};
      }

      .editable-field-value {
        font-weight: ${typography.weight.bold};
        font-size: ${typography.size.base};
        word-break: break-all;
        word-break: break-word;
        strong {
          text-align: right;
        }
        span {
          font-size: ${typography.size.misc};
        }
      }

      .editable-field-error {
        font-size: ${typography.size.misc};
        padding-left: 0;
        padding-bottom: 0;
        width: 100%;
      }
      button {
        text-decoration: none;
        font-size: ${typography.size.table};
        font-weight: ${typography.weight.thick};
      }
      .editable-field-confirm {
        .field-cancel {
          font-size: ${typography.size.base};
          font-weight: ${typography.weight.thick};
          line-height: ${typography.lineHeight.high};
          color: ${color.gray.alpha};
        }
      }
      .input-wrapper {
        margin-right: ${spacing.tiny};
        flex: 1;
      }

      .field-save {
        padding: ${spacing.xSmall} ${spacing.small};
        margin-right: ${spacing.tiny};
      }
      ${isEditing && editingWidth
        ? css`
            width: ${editingWidth}px;

            .input-component {
              width: calc(100% - ${editActionsWidth});
            }

            .input-component input {
              width: 100%;
            }
          `
        : ''}
    `;
  }};
`;

StyledEditableField.displayName = 'StyledEditableField';
export default StyledEditableField;
