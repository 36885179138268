const initialState = {
  list: [],
  totalResults: 0,
  fetchingList: true,
  banner: {
    bannerType: '',
    bannerVisible: false,
    bannerHideTime: 10000,
    bannerMessage: '',
    statusCode: 200,
  },
  isUploadInProgress: false,
  isUploadDialogVisible: false,
  redirectTo: '',
  rates: {
    list: [],
    isFetching: false,
    count: 0,
    sourceName: '',
    isAutomatic: true,
  },
  currencies: [],
};

export default initialState;
