import * as R from 'ramda';

import actionTypes from '../actions/types';

import initialState from './initialState';

export default function benefitsEnvelopeAuditReducer(state = R.clone(initialState), action) {
  switch (action.type) {
    case actionTypes.FETCH_AUDITS_START: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case actionTypes.CLEAR_AUDITS: {
      return initialState;
    }
    case actionTypes.FETCH_AUDITS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        audits: action.payload.data.audits,
        filterOptions: R.map(
          item => ({
            title: item.key,
            key: item.key,
            list: item.options,
          }),
          R.path(['payload', 'data', 'filterOptions'], action) || []
        ),
        totalResults: action.payload.data.count,
      };
    }
    default:
      return state;
  }
}
