import * as R from 'ramda';
import { createAction } from 'redux-actions';
import { organization } from 'config/api';
import { GET, POST } from 'config/http';
import types from './types';

export const getValidationData = createAction(types.GET_DATA_VALIDATION);
export const generateValidationData = createAction(types.GENERATE_DATA_VALIDATION);
export const resetStore = createAction(types.RESET_STORE);

export const getDataValidation = country => dispatch => {
  dispatch(
    getValidationData(
      GET(
        `${organization.employeeValidations}/${country}`,
        { Pragma: 'no-cache' },
        { showErrorModal: true, dispatch }
      ).then(response => R.prop('data', response))
    )
  );
};

export const generateDataValidation = () => (dispatch, getState) => {
  const country = R.path(['rmDashboard', 'dashboard', 'selectedCountry', 'value'], getState());
  dispatch(
    generateValidationData(
      POST(
        `${organization.employeeValidations}/${country}/generate`,
        {},
        {},
        { showErrorModal: true, dispatch }
      )
    )
  );
};
