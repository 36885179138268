import forecastRewards from './forecastRewards';
import header from '../header';

export default {
  ...header,
  BENEFITS_ENVELOPE_PAGE_TITLE: 'Benefits Envelope Packages',
  BENEFITS_ENVELOPE_PAGE_SUBTITLE:
    'Here you can see all the submission packages defined in the United Kingdom. In order to generate the additional custom report for the Benefit Envelope campaigns, please edit the individual package and mark the rewards which you want to have included.',
  BENEFITS_ENVELOPE_PAGE_AUDIT_SUBTITLE:
    'If you would like to see all the requests and responses made to the Benefits Envelope API in the past 24 months, please %click here%.',
  BENEFITS_ENVELOPE_FORECAST_REWARDS:
    'In order to forecast the rewards owned by employees in a moment in time, %click here%.',
  FORECAST_REWARDS_IS_CALCULATING:
    'A calculation is currently processing. Please wait for it to finish.',
  PACKAGE_NAME: 'Package name',
  PACKAGE_COUNTRY: 'country',
  NEW_STATEMENT_DATE: 'New statement date',
  START_DATE: 'Start date',
  END_DATE: 'End date',
  BEA_PACKAGE: 'Bea Package',
  PACKAGE_STATUS: 'Status',
  NO_PACKAGES: 'No package data',
  EDIT: 'Edit',
  VIEW: 'View',
  EXPORT: 'Export custom report',
  EXPORT_P0071: 'Export P0071 Report',
  EXPORT_P0014: 'Export P0014 Report',
  EXPORT_EIB: 'Export EIB Report',
  EXPORT_IS_IN_PROGRESS: '%FILENAME% export is in progress',
  YES: 'Yes',
  NO: 'No',
  BENEFITS_ENVELOPE_NOT_ALLOWED:
    'There is no data to display, as you do not have access to UK flexible rewards.',
  REQUEST_FAILED: 'Unable to load submission packages',
  PACKAGE_DETAILS_COUNTRY: 'Country',
  ENROLLMENT_WINDOW: 'Enrolment window',
  STATUS_DRAFT: 'Draft',
  STATUS_PUBLISHED: 'Published',
  STATUS_LIVE: 'Live',
  STATUS_EXPIRED: 'Expired',
  REWARDS_LIST_SUBTITLE: 'Rewards in the custom report',
  REWARDS_LIST_PARAGRAPH:
    'From this section, you may select which rewards would you like to have included in the package custom report. Please be aware that once the package is Live, you will no longer be able to make any changes here.',
  EFFECTIVE_DATE: 'Effective date',
  DISPLAY_NAME: 'Name',
  EXPIRY_DATE: 'Expiry date',
  INCLUDE: 'Include',
  BEA: 'BEA',
  REPORT_P0071: 'P0071',
  REPORT_P0014: 'P0014',
  REWARDS_SUBMIT_SUCCESS: 'Rewards selection successfuly saved',
  CUSTOM_REPORTS: 'Custom Reports',
  TAB_CHOOSE_REWARDS: 'Rewards Selection',
  TAB_P0071: 'Configure P0071',
  TAB_P0014: 'Configure P0014',
  TAB_EIB_TEMPLATE: 'Configure EIB Template',
  TAB_ELIGIBILITY: 'Eligibility',
  NO_REWARDS: 'No rewards available',
  P0014_TITLE: 'P0014 Details',
  P0071_TITLE: 'P0071 Details',
  P0071_ACTION: 'In order to configure the P0071 report, please %download the template%.',
  P0014_ACTION: 'In order to configure the P0014 report, please %download the template%.',
  REPORT_UPLOAD: 'Upload Configuration',
  UPLOAD_FORM_TITLE: 'Upload custom report configuration',
  UPLOAD_ERROR_LINE: 'Error on line %row%, column %column%. %message%.',
  UPLOAD_ERROR_LINE_ONLY: 'Error on line %row%. %message%.',
  UPLOAD_ERROR_ONLY: 'Error: %message%.',
  UPLOAD_ERROR_TITLE:
    'This step has been configured with errors. Please fix the following lines in order to continue:',
  UPLOAD_CONFIG_DOWNLOAD_TEXT:
    'This step has been configured with success. You may revise the configuration at anytime %here%.',
  FILE_UPLOAD_SUCCESS: 'File successfully uploaded',
  ...forecastRewards,
  CONFIRM_CHANGES_TAB_P0071_TITLE: 'The rewards configured for P0071 have changed',
  CONFIRM_CHANGES_TAB_P0071_SUBTITLE:
    'If you continue, the existing P0071 configuration for this package will be reset and any existing report will be deleted.',
  CONFIRM_CHANGES_TAB_P0014_TITLE: 'The rewards configured for P0014 have changed',
  CONFIRM_CHANGES_TAB_P0014_SUBTITLE:
    'If you continue, the existing P0014 configuration for this package will be reset and any existing report will be deleted.',
  CONFIRM_CHANGES_TAB_P0071_P0014_TITLE: 'The rewards configured have changed',
  CONFIRM_CHANGES_TAB_P0071_P0014_SUBTITLE:
    'If you continue, the existing P0071 and P0014 configuration for this package will be reset and any existing report will be deleted.',
  CLOSE_CHANGES_SUBTITLE:
    'Based on your reward changes, saving the new rewards setup will reset any existing configration for P0071 or P0014 reports.',
  WARNING_DIALOG_TITLE: 'Would you like to add an alias for this reward?',
  WARNING_DIALOG_DESCRIPTION:
    'The alias defined for this reward will be used when exporting the EIB report.',
  WARNING_DIALOG_TEXT_LABEL: 'Alias name',
  WARNING_DIALOG_LENGTH_LABEL: 'Text must be less than 1000 characters.',
  EIB_CONFIG_SUBMIT_SUCCESS: 'EIB Configuration successfuly saved',
  EIB_INCLUDE_REWARD_ERROR: 'Please select at least one reward for calculation',
  EIB_BEA_REWARD_ERROR: 'Please select one reward as BEA',
};
