import axios from 'axios';
import * as R from 'ramda';
import { isBlank } from '@trs/utils';
import userManager from '../userManager/userManager';
import { showBanner } from '../../actions/globalActions';

const SECONDS_IN_A_MONTH = 2592000;

export const { CancelToken } = axios;

export const noCacheHeader = {
  'Cache-Control': 'no-cache',
  Pragma: 'no-cache',
};
const assetsCacheHeader = {
  'Cache-Control': `max-age=${SECONDS_IN_A_MONTH}`,
};

const readTokenFromStorage = (user) => R.path(['access_token'], user);
const headersWithToken = (headers, user) => {
  const token = readTokenFromStorage(user);
  if (isBlank(token)) return headers;

  return {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
    ...headers,
  };
};

const errorHandler = (error) => {
  const dispatch = R.path(['config', 'dispatch'], error);
  if (
    R.path(['config', 'showErrorModal'], error) &&
    R.type(dispatch) === 'Function' &&
    window.location.pathname !== '/unauthorized'
  ) {
    const errorMessage =
      R.path(['config', 'errorMessage'], error) ||
      R.path(['config', 'message '], error) ||
      R.path(['response', 'data', 'errors', 0, 'message'], error) ||
      R.prop('message', error);

    dispatch(showBanner({ type: 'error', content: errorMessage }));
  }

  return Promise.reject(error);
};

export const configureInterceptors = ({ history, logout }) => {
  axios.interceptors.response.use(null, (error) => {
    const redirectToUnauthorized = R.pathOr(true, ['config', 'redirectToUnauthorized'], error);
    const status = R.path(['response', 'status'], error);

    if (status === 403 && redirectToUnauthorized) {
      history.push('/unauthorized');
    } else if (status === 401) {
      logout();
    }
    return Promise.reject(error);
  });
};

export const RequestFactory = (method, url, headers = {}, data, responseType, options) =>
  userManager
    .getUser()
    .then((user) => headersWithToken(headers, user))
    .then((authHeaders) =>
      axios({
        method,
        url,
        data,
        headers: authHeaders,
        crossDomain: true,
        responseType,
        ...options,
      }).catch((error) => errorHandler(error))
    );

export const GET = (url, headers = {}, ...options) =>
  RequestFactory('get', url, { ...noCacheHeader, ...headers }, null, null, ...options);

export const POST = (url, data, headers = {}, ...options) =>
  RequestFactory('post', url, headers, data, null, ...options);

export const PUT = (url, data, headers = {}, ...options) =>
  RequestFactory('put', url, headers, data, null, ...options);

export const PATCH = (url, data, headers = {}, ...options) =>
  RequestFactory('patch', url, headers, data, null, ...options);

export const DELETE = (url, headers = {}, ...options) =>
  RequestFactory('delete', url, headers, null, null, ...options);

export const addGetParameters = (url, paramsList) => {
  let urlWithParams = url;
  for (let i = 0; i < paramsList.length; i += 1) {
    urlWithParams += `${i === 0 ? '?' : '&'}${paramsList[i].name}=${encodeURIComponent(
      paramsList[i].value
    )}`;
  }
  return urlWithParams;
};

export const fetchStaticAsset = (url, responseType = null) =>
  RequestFactory('get', url, assetsCacheHeader, null, responseType);

export default {
  RequestFactory,
  GET,
  POST,
  PUT,
  PATCH,
  DELETE,
  addGetParameters,
  configureInterceptors,
};
