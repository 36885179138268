import styled, { css } from 'styled-components';

const StyledNotificationHeader = styled.div`
  ${props => {
    const {
      isError,
      theme: { spacing, typography, palette },
    } = props;

    return css`
      .notification-date {
        font-size: ${typography.size.misc};
        margin-bottom: ${spacing.tiny};
        color: ${palette.gray.golf};
        line-height: ${typography.lineHeight.paragraph};
      }
      .notification-title {
        font-weight: ${typography.weight.thick};
        margin-bottom: ${spacing.xTiny};
        line-height: ${typography.lineHeight.paragraphSmall};

        ${isError &&
          css`
            color: ${palette.validation.error};
          `}
      }
    `;
  }};
`;

StyledNotificationHeader.displayName = 'StyledNotificationHeader';

export default StyledNotificationHeader;
