import * as R from 'ramda';
import { createAction } from 'redux-actions';
import { REWARD_CONTEXTS } from '@trs/config';
import { GET, PUT, DELETE, addGetParameters, POST, CancelToken, noCacheHeader } from 'config/http';
import { rewards, reports } from 'config/api';
import { withCache } from 'modules/common/timedCache';
import { TextOnly } from 'modules/common/Text';
import { showBanner } from '../../../actions/globalActions';
import { getEtagFromId, setElectionRewardsPayload } from './helpers';

export const actionTypes = {
  SYNC_TO_REDUCER: 'SYNC_TO_REDUCER',
  GET_REWARD_CATEGORIES: 'GET_REWARD_CATEGORIES',
  GET_FLEX_BY_ID: 'GET_FLEX_BY_ID',
  GET_FLEXIBLE_REWARDS: 'GET_FLEXIBLE_REWARDS',
  GET_AVAILABLE_COUNTRIES: 'GET_AVAILABLE_COUNTRIES',
  GET_SYSTEM_VARIABLES_LOOKUP: 'GET_SYSTEM_VARIABLES_LOOKUP',
  GET_FORMULA_OPERATORS: 'GET_FORMULA_OPERATORS',
  GET_SYSTEM_VARIABLES: 'GET_SYSTEM_VARIABLES',
  GET_ELIGIBILITY_OPERATORS: 'GET_ELIGIBILITY_OPERATORS',
  GET_ELECTION_REWARDS: 'GET_ELECTION_REWARDS',
  GET_ELECTION_SELECTED_REWARDS: 'GET_ELECTION_SELECTED_REWARDS',
  DISABLE_PACKAGE: 'DISABLE_PACKAGE',
  COUNTRY_VALUE_UPDATE: 'COUNTRY_VALUE_UPDATE',
  ENROLMENT_WINDOW_UPDATE: 'ENROLMENT_WINDOW_UPDATE',
  BASIC_INFO_REVERT: 'BASIC_INFO_REVERT',
  STORE_RESET: 'STORE_RESET',
  GET_ALL_REWARDS: 'GET_ALL_REWARDS',
  DELETE_PACKAGE: 'DELETE_PACKAGE',
};

const cachedGet = withCache(GET, 3600);

export const syncToReducer = createAction(actionTypes.SYNC_TO_REDUCER);
export const getRewardCategories = createAction(actionTypes.GET_REWARD_CATEGORIES);
export const getFlexById = createAction(actionTypes.GET_FLEX_BY_ID);
export const getFlexibleRewards = createAction(actionTypes.GET_FLEXIBLE_REWARDS);
export const getAvailableCountries = createAction(actionTypes.GET_AVAILABLE_COUNTRIES);
export const getSystemVariablesLookup = createAction(actionTypes.GET_SYSTEM_VARIABLES_LOOKUP);
export const getFormulaOperators = createAction(actionTypes.GET_FORMULA_OPERATORS);
export const getSystemVariables = createAction(actionTypes.GET_SYSTEM_VARIABLES);
export const getEligibilityOperators = createAction(actionTypes.GET_ELIGIBILITY_OPERATORS);
export const getElectionRewards = createAction(actionTypes.GET_ELECTION_REWARDS);
export const getElectionSelectedRewards = createAction(actionTypes.GET_ELECTION_SELECTED_REWARDS);
export const disablePackage = createAction(actionTypes.DISABLE_PACKAGE);
export const countryValueUpdate = createAction(actionTypes.COUNTRY_VALUE_UPDATE);
export const enrolmentWindowUpdate = createAction(actionTypes.ENROLMENT_WINDOW_UPDATE);
export const onBasicInfoRevert = createAction(actionTypes.BASIC_INFO_REVERT);
export const storeReset = createAction(actionTypes.STORE_RESET);
export const getAllRewards = createAction(actionTypes.GET_ALL_REWARDS);
export const deletePackageById = createAction(actionTypes.DELETE_PACKAGE);

export const loadRewardCategories = () => (dispatch) =>
  dispatch(
    getRewardCategories(
      cachedGet(
        `${rewards.categories}?excludeIncompleteCategories=true`,
        rewards.category,
        { Pragma: 'no-cache' },
        { showErrorModal: true, dispatch }
      ).then((payload) => R.path(['data', 'response', 'categories'], payload))
    )
  );

export const loadFlexById = (flexId) => (dispatch) =>
  dispatch(
    getFlexById(
      GET(
        `${rewards.flex}/${flexId}`,
        { Pragma: 'no-cache' },
        { showErrorModal: true, dispatch }
      ).then((response) => response)
    )
  );

export const loadFlexibleRewards = () => (dispatch) =>
  dispatch(
    getFlexibleRewards(
      GET(`${rewards.flex}/all`, noCacheHeader, { showErrorModal: true, dispatch }).then(
        (response) => R.prop('data', response)
      )
    )
  );

export const loadExportableFlexibleRewards = () => (dispatch) =>
  dispatch(
    getFlexibleRewards(
      GET(`${rewards.flex}/exportable`, noCacheHeader, { showErrorModal: true, dispatch }).then(
        (response) => R.prop('data', response)
      )
    )
  );

export const loadAvailableCountries = () => (dispatch, getState) =>
  dispatch(
    getAvailableCountries(
      GET(`${rewards.flex}/countries`, noCacheHeader, { showErrorModal: true, dispatch }).then(
        (response) => ({
          countries: R.prop('data', response),
          user: R.prop('user', getState()),
        })
      )
    )
  );

let cancelOperandCall;

export const getFormulaOperandValues =
  ({ resourceCountry }) =>
  () =>
  (searchValue) => {
    if (!searchValue) {
      return Promise.resolve([]);
    }
    if (cancelOperandCall) cancelOperandCall.cancel('Formula Operand call cancelled.');
    cancelOperandCall = CancelToken.source();

    return cachedGet(
      `${rewards.formulaOperand}/${encodeURIComponent(
        searchValue
      )}?resourceCountry=${resourceCountry}&context=${REWARD_CONTEXTS.flexPackage}`,
      { cancelToken: cancelOperandCall.token }
    ).then((response) => R.prop('data', response));
  };

export const loadSystemVariablesLookup = (url) => (dispatch) =>
  dispatch(
    getSystemVariablesLookup(
      cachedGet(
        `${rewards.systemVariables}/${url}`,
        { Pragma: 'no-cache' },
        { showErrorModal: true, dispatch }
      ).then((response) => ({
        lookupData: response,
        url,
      }))
    )
  );

export const loadFormulaOperators = () => (dispatch) =>
  dispatch(
    getFormulaOperators(
      cachedGet(
        rewards.formulaOperators,
        { Pragma: 'no-cache' },
        { showErrorModal: true, dispatch }
      ).then((response) => R.prop('data', response))
    )
  );

export const loadSystemVariables = (variableType = '') => {
  let requestUrl = rewards.systemVariables;

  if (variableType) {
    const getArgs = [{ name: 'variableType', value: variableType }];
    requestUrl = addGetParameters(requestUrl, getArgs);
  }

  return (dispatch) =>
    dispatch(
      getSystemVariables(
        cachedGet(requestUrl, { Pragma: 'no-cache' }, { showErrorModal: true, dispatch }).then(
          (response) => response
        )
      )
    );
};

export const loadEligibilityOperators = () => (dispatch) =>
  dispatch(
    getEligibilityOperators(
      cachedGet(
        rewards.eligibilityOperators,
        { Pragma: 'no-cache' },
        { showErrorModal: true, dispatch }
      ).then((response) => response)
    )
  );

export const loadElectionRewards = (flexId, params) => (dispatch) =>
  dispatch(
    getElectionRewards(
      POST(`${rewards.flex}/${flexId}/rewards`, setElectionRewardsPayload(params), noCacheHeader, {
        showErrorModal: true,
        dispatch,
      }).then((response) => R.prop('data', response))
    )
  );

export const loadElectionSelectedRewards = (flexId) => (dispatch) =>
  dispatch(
    getElectionSelectedRewards(
      GET(`${rewards.flex}/${flexId}/rewards`, noCacheHeader, {
        showErrorModal: true,
        dispatch,
      }).then((response) => R.path(['data', 'response'], response) || [])
    )
  );

export const deactivatePackage = (packageId) => (dispatch, getState) => {
  const flexPackages = R.path(['flexibleRewards', 'main', 'flexPackages'], getState());
  const etag = getEtagFromId(packageId)(R.concat(...Object.values(flexPackages)));

  return dispatch(
    disablePackage(
      PUT(
        `${rewards.flex}/${packageId}/deactivate`,
        { id: packageId },
        {
          ...noCacheHeader,
          Etag: etag,
        }
      )
        .then(() => {
          dispatch(
            showBanner({
              type: 'success',
              content: TextOnly({ path: 'flexibleRewards.PACKAGE_DEACTIVATE_SUCCESS' }),
            })
          );
          dispatch(loadFlexibleRewards());
        })
        .catch(() =>
          dispatch(
            showBanner({
              type: 'error',
              content: TextOnly({ path: 'flexibleRewards.PACKAGE_DEACTIVATE_ERROR' }),
            })
          )
        )
    )
  );
};

export const deletePackage = (packageId) => (dispatch, getState) => {
  const flexPackages = R.path(['flexibleRewards', 'main', 'flexPackages'], getState());
  const etag = getEtagFromId(packageId)(R.concat(...Object.values(flexPackages)));

  return dispatch(
    deletePackageById(
      DELETE(
        `${rewards.flex}/${packageId}`,
        { id: packageId },
        {
          ...noCacheHeader,
          Etag: etag,
        }
      )
        .then(() => {
          dispatch(
            showBanner({
              type: 'success',
              content: TextOnly({ path: 'flexibleRewards.PACKAGE_DELETE_SUCCESS' }),
            })
          );
          dispatch(loadFlexibleRewards());
        })
        .catch(() =>
          dispatch(
            showBanner({
              type: 'error',
              content: TextOnly({ path: 'flexibleRewards.PACKAGE_DELETE_ERROR' }),
            })
          )
        )
    )
  );
};

export const generateReport = (packageId) => (dispatch) =>
  POST(`${reports.employeeElections}/${packageId}/trigger`, {}).then(() => {
    const file = TextOnly({ path: 'generic.FILE' });
    return dispatch(
      showBanner({
        type: 'info',
        content: TextOnly({
          path: 'notifications.EXPORT_IS_IN_PROGRESS',
          transform: (label = '') => label.replace('%FILENAME%', file),
        }),
      })
    );
  });

export const getAllFlexibleRewards = (packageId, params) => (dispatch) =>
  dispatch(
    getAllRewards(
      POST(rewards.allRewards(packageId), setElectionRewardsPayload(params), noCacheHeader, {
        showErrorModal: true,
        dispatch,
      }).then((response) => R.prop('data', response))
    )
  );
