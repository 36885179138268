import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const StyledTrack = styled.div`
  ${props => {
    const {
      palette: color,
      extra: { genericBorderRadius },
    } = props.theme;
    const { source, target } = props;

    return css`
      cursor: pointer;
      height: 10px;
      display: flex;
      align-items: center;
      left: ${source.percent}%;
      position: absolute;
      width: ${target.percent - source.percent}%;
      z-index: 1;
      transition: 0.2s linear all;
      .track-inner {
        background-color: ${color.secondary};
        border-radius: ${genericBorderRadius};
        height: 4px;
        width: 100%;
      }
    `;
  }};
`;

StyledTrack.displayName = 'StyledTrack';

const Track = ({ source, target, getTrackProps }) => (
  <StyledTrack source={source} target={target} {...getTrackProps()}>
    <div className="track-inner" />
  </StyledTrack>
);

Track.propTypes = {
  source: PropTypes.shape({
    percent: PropTypes.number,
  }).isRequired,
  target: PropTypes.shape({
    percent: PropTypes.number,
  }).isRequired,
  getTrackProps: PropTypes.func.isRequired,
};

Track.defaultProps = {};

export default Track;
