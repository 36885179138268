import * as R from 'ramda';
import { createAction } from 'redux-actions';

import { GET } from '../../../../../../../config/http';
import { benchmark } from '../../../../../../../config/api';
import { withCache } from '../../../../../../common/timedCache';
import { loadGlobalIdentifiers } from '../../../../global/actions';

const cachedGet = withCache(GET, 3600);

export const actionTypes = {
  BENCHMARKING_LOAD_COUNTRIES: 'BENCHMARKING/DASHBOARD/COUNTRIES',
};

export const loadDashboardCountries = createAction(actionTypes.BENCHMARKING_LOAD_COUNTRIES);

export const getBenchmarkingCountries = () => dispatch =>
  dispatch(
    loadDashboardCountries(
      dispatch(loadGlobalIdentifiers()).then(response => {
        const { id } = response;

        return cachedGet(`${benchmark.benchmarking}/${id}/countries`).then(resp => {
          const countries = R.path(['data', 'response', 'countries'], resp);
          return { countries, globalBenchmarkingId: id };
        });
      })
    )
  );
