export const suffixName = (suffix, name) => {
  if (suffix && !name) {
    return null;
  }
  return !suffix ? name : `${name}${suffix}`;
};

export const createSuffixedActions = (actions, suffix) => {
  return actions.reduce((current, previous) => {
    return { ...current, [previous]: suffixName(suffix, previous) };
  }, {});
};
