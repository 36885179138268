import styled, { css } from 'styled-components';

export const StyledBoxColor = styled.div`
  ${props => css`
    position: relative;
    text-align: center;

    &:before {
      display: block;
      background-color: ${props.boxColor};
      height: ${props.theme.spacing.base};
      width: ${props.theme.spacing.base};
      content: '';
    }
  `}
`;

const StyledImpactDetails = styled.div`
  ${props => {
    const { typography, spacing } = props.theme;

    return css`
      position: relative;

      .impact-detail-doughnut {
        width: 100%;
      }

      .doughnut-wrapper {
        position: relative;
        margin-bottom: ${spacing.small};
      }

      .doughnut-wrapper .doughnut-legend {
        height: auto;
      }
      #downloadBanner {
        position: absolute;
        bottom: -100px;
      }

      .download-button {
        text-decoration: none;
      }
      .doughnut-chart {
        pointer-events: auto;
      }

      svg text.multine {
        font-weight: ${typography.weight.normal};
        font-size: ${typography.size.table};
      }
      svg text {
        font-weight: ${typography.weight.bold};
        font-size: ${typography.size.h3};
      }
    `;
  }};
`;

StyledImpactDetails.displayName = 'StyledImpactDetails';

export default StyledImpactDetails;
