/* eslint-disable no-param-reassign */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import { withTheme } from 'styled-components';
import { TextOnly } from '../../../../../../common/Text';

import StyledColumnBody from './ColumnBody.style';
// import TOTAL_VARIANTS from '../constants';
import Total from '../total/Total';
import { getCategory, transformPdfOutputTextContent } from './helpers';
import RewardCategory from '../rewardCategory/RewardCategory';

const ColumnBody = ({
  data,
  theme,
  disableAnimation,
  showOnlyNewStatement,
  isExtra,
  budgetLabel,
  isPdfOutput,
}) => {
  const columnBodyData = R.clone(data);

  columnBodyData.groups.forEach((group) => {
    group.forEach((category) => {
      Object.keys(category.rewards).forEach((rewardGroup) => {
        category.rewards[rewardGroup] = category.rewards[rewardGroup].filter(
          (version) => version.includeInTheOverallTotal !== isExtra
        );
      });
    });
  });

  columnBodyData.groups.forEach((group) => {
    group.forEach((category, categoryIndex) => {
      let updatedRewards = [];
      const rewardGroups = Object.keys(category.rewards).filter(
        (rewardGroup) => !R.isEmpty(category.rewards[rewardGroup])
      );

      rewardGroups.forEach((reward) => {
        const categoryRewards = isPdfOutput
          ? transformPdfOutputTextContent(category.rewards[reward])
          : category.rewards[reward];

        updatedRewards = {
          ...updatedRewards,
          [reward]: categoryRewards,
        };
      });

      category.rewards = updatedRewards;

      if (R.isEmpty(category.rewards)) {
        delete group[categoryIndex];
      }
    });
  });

  return (
    <StyledColumnBody
      disableAnimation={disableAnimation}
      showOnlyNewStatement={showOnlyNewStatement}
    >
      {R.length(R.prop('groups', columnBodyData)) > 1
        ? columnBodyData.groups.map((subList, categoriesGroupIndex) => {
            return (
              <Fragment key={categoriesGroupIndex === 0 ? 'total' : 'subtotal'}>
                {subList.map((category) => {
                  return (
                    <RewardCategory
                      id="rewardCategory"
                      key={category.displayName}
                      colors={R.path(['extra', 'rewardsCategories', 'categories'], theme)}
                      category={getCategory(category, isExtra)}
                      currencies={R.prop('currencies', columnBodyData)}
                      showOnlyNewStatement={showOnlyNewStatement}
                      showTotal={!isExtra}
                      budgetLabel={budgetLabel}
                      hasDecimals
                    />
                  );
                })}
                {/* {categoriesGroupIndex === 0 && (
              <Total
                label={TextOnly({ path: 'flexibleRewards.SIDE_SUBTOTAL' })}
                variant={TOTAL_VARIANTS.subtotal}
                values={R.prop('subtotal', data)}
                currencies={R.prop('currencies', data)}
                showOnlyNewStatement={showOnlyNewStatement}
              />
            )} */}
              </Fragment>
            );
          })
        : R.path(['groups', 0], columnBodyData) &&
          columnBodyData.groups[0].map((category) => (
            <RewardCategory
              id="rewardCategory"
              key={R.prop('displayName', category)}
              theme={theme}
              category={getCategory(category, isExtra)}
              index={category.orderIndex}
              currencies={R.prop('currencies', columnBodyData)}
              showOnlyNewStatement={showOnlyNewStatement}
              showTotal={!isExtra}
              budgetLabel={budgetLabel}
              hasDecimals
            />
          ))}
      {!isExtra && (
        <Total
          label={TextOnly({
            path: 'flexibleRewards.SIDE_TOTAL',
          })}
          values={R.prop('overallTotal', columnBodyData)}
          currencies={R.prop('currencies', columnBodyData)}
          showOnlyNewStatement={showOnlyNewStatement}
          hasDecimals
        />
      )}
      {isExtra && (
        <div
          className="borderBottom extraStatements"
          style={{ backgroundColor: R.path(['palette', 'secondary'], theme) }}
        />
      )}
    </StyledColumnBody>
  );
};

ColumnBody.propTypes = {
  data: PropTypes.shape({
    currencies: PropTypes.arrayOf(PropTypes.string),
    overallTotal: PropTypes.arrayOf(PropTypes.number),
    subtotal: PropTypes.arrayOf(PropTypes.number),
    groups: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({}))),
  }).isRequired,
  theme: PropTypes.shape({}).isRequired,
  disableAnimation: PropTypes.bool,
  showOnlyNewStatement: PropTypes.bool.isRequired,
  isExtra: PropTypes.bool, // isExtra = true if includeInTheOverallTotal is false
  budgetLabel: PropTypes.string.isRequired,
  isPdfOutput: PropTypes.bool,
};

ColumnBody.defaultProps = {
  disableAnimation: false,
  isExtra: false,
  isPdfOutput: false,
};

export default withTheme(ColumnBody);
