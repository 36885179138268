/* eslint-disable max-lines */
import * as R from 'ramda';
import { v4 as uuid } from 'uuid';
import { handleActions } from 'redux-actions';
import { isBlank } from '@trs/utils';
import userModules from 'config/userManager/userModules';
import { filterCountriesByAccess } from 'modules/common/helpers';
import {
  getLtiPlansCurrencies,
  getLtiPlansCompanyDefaultCurrency,
  getLtiPlansCountries,
  getLtiPlansFxSources,
  getLtiPlansFxRateDates,
  getLtiSystemVariables,
  getLtiSystemVariablesLookup,
  getLtiEligibilityOperators,
  getLtiFormulaOperators,
  addBasicInformationChange,
  saveBasicInformation,
  resetBasicInformation,
  getPlanByIdLoading,
  getAllPlansLoading,
  getAllPlans,
  getPlanById,
  getPlanStatusById,
  ruleChange,
  addRuleBlock,
  duplicateRuleBlock,
  deleteRuleBlock,
  getDistributionByType,
  changeDistributionType,
  changeTableCell,
  setDataTableLoading,
  setTableTotal,
  saveRulesBuilder,
  resetRulesBuilder,
  etagUpdate,
  redirectToRoot,
  resetStore,
} from '../actions';
import { FX_RATE_DATE_CUSTOM } from '../constants';
import {
  transformResponseGetAllPlans,
  mapResponseToBasicInfoModel,
  mapResponseToRuleBuilderRuleModel,
  mapResponseToRuleBuilderDistributionModel,
  transformDirtyRowsData,
} from './helpers';
import initialState, { defaultRule } from './initialState';

const ltiPlansReducer = handleActions(
  new Map([
    [
      getAllPlansLoading,
      (state, action) => {
        return {
          ...state,
          fetchingList: action.payload,
        };
      },
    ],
    [
      getAllPlans,
      (state, action) => {
        const ltiPlansCount = action.payload.response.data.count;
        const data = action.payload.response.data.longTermIncentivePlans;
        const filterOptions = R.map(
          (item) => ({
            title: item.key,
            key: item.key,
            list: item.options,
          }),
          action.payload.response.data.filterOptions || []
        );

        return {
          ...state,
          list: transformResponseGetAllPlans(data),
          filterOptions,
          totalResults: ltiPlansCount || 0,
          fetchingList: false,
        };
      },
    ],
    [
      getLtiPlansCurrencies,
      (state, action) => {
        const { currencies } = action.payload;
        const currenciesOptions = [];

        currencies.map((currency) =>
          currenciesOptions.push({
            value: currency.code,
            label: currency.code,
          })
        );

        return {
          ...state,
          currencies: currenciesOptions,
        };
      },
    ],
    [
      getLtiPlansCompanyDefaultCurrency,
      (state, action) => {
        const { defaultCurrency } = action.payload;

        return {
          ...state,
          basicInformation: {
            ...state.basicInformation,
            currency: defaultCurrency,
          },
          swapBasicInformation: {
            ...state.swapBasicInformation,
            currency: defaultCurrency,
          },
          companyDefaultCurrency: defaultCurrency,
        };
      },
    ],
    [
      getLtiPlansCountries,
      (state, action) => {
        const {
          user: { authorizedModules, profile },
          countries,
          error,
        } = action.payload;

        if (error) {
          return {
            ...state,
            serverError: true,
          };
        }

        const countriesOptions = [];
        const defaultCurrencyMappings = filterCountriesByAccess({
          countries,
          module: userModules.LTI_PLANS_MANAGEMENT,
          authorizedModules,
        });

        defaultCurrencyMappings.map((country) =>
          countriesOptions.push({
            value: country.name,
            label: country.name,
            code: country.code,
          })
        );

        if (isBlank(countriesOptions)) {
          countriesOptions.push({
            value: profile.country,
            label: profile.country,
            code: R.prop('code')(R.find(R.propEq('name', profile.country))(countries)),
          });
        }

        return {
          ...state,
          countries: countriesOptions,
          defaultCurrencyMappings,
        };
      },
    ],
    [
      getLtiPlansFxSources,
      (state, action) => {
        const { fxSources } = action.payload;
        const fxSourcesOptions = [];

        if (fxSources) {
          fxSources.map((source) =>
            fxSourcesOptions.push({
              value: source.id,
              label: source.name,
            })
          );
        }
        return {
          ...state,
          fxSources: fxSourcesOptions,
        };
      },
    ],
    [
      getLtiPlansFxRateDates,
      (state, action) => {
        const { fxRateDates } = action.payload;

        const mappedFxRateDates = [FX_RATE_DATE_CUSTOM];
        fxRateDates.map(({ displayName, propertyName, type, valuesUrl }) => {
          mappedFxRateDates.push({
            value: propertyName,
            label: displayName,
            type,
            valuesUrl,
          });
          return false;
        });

        return {
          ...state,
          fxRateDates: mappedFxRateDates,
        };
      },
    ],
    [
      getLtiSystemVariables,
      (state, action) => {
        const { data } = action.payload;
        let systemVariables = [];

        data.forEach(({ displayName, propertyName, type, valuesUrl }) => {
          systemVariables.push({
            value: propertyName,
            label: displayName,
            type,
            valuesUrl,
          });
        });

        systemVariables = R.sortBy(R.prop('value'))(systemVariables);

        return {
          ...state,
          systemVariables,
        };
      },
    ],
    [
      getLtiSystemVariablesLookup,
      (state, action) => {
        const { lookupData, url } = action;
        const lookUpValues = {};
        lookUpValues[url] = [];

        if (lookupData.data) {
          lookupData.data.forEach((item) =>
            lookUpValues[url].push({
              label: item,
              value: item,
            })
          );
          lookUpValues[url] = R.sortBy(R.prop('value'))(lookUpValues[url]);
        } else {
          lookUpValues[url] = [];
        }
        return {
          ...state,
          lookUpValues: { ...state.lookUpValues, ...lookUpValues },
        };
      },
    ],
    [
      getLtiEligibilityOperators,
      (state, action) => {
        const { data } = action.payload;
        const eligibilityOperators = [];

        data.forEach(({ displayName, name, allowsMultipleSelect }) => {
          eligibilityOperators.push({
            value: name,
            label: displayName,
            allowsMultipleSelect,
          });
        });

        return {
          ...state,
          eligibilityOperators,
        };
      },
    ],
    [
      getLtiFormulaOperators,
      (state, action) => {
        return {
          ...state,
          formulaOperators: action.payload,
        };
      },
    ],
    [
      addBasicInformationChange,
      (state, action) => {
        const { field, value, other } = action.payload;

        return {
          ...state,
          basicInformation: {
            ...state.basicInformation,
            [field]: value,
            ...other,
          },
        };
      },
    ],
    [
      saveBasicInformation,
      (state, action) => {
        const { response } = action.payload;

        return {
          ...state,
          swapBasicInformation: R.clone(state.basicInformation),
          savedLtiPlanId: response.id,
        };
      },
    ],
    [
      resetBasicInformation,
      (state) => {
        return {
          ...state,
          basicInformation: state.swapBasicInformation,
        };
      },
    ],
    [
      getPlanByIdLoading,
      (state, action) => {
        return {
          ...state,
          loadingById: action.payload,
        };
      },
    ],
    [
      getPlanById,
      (state, action) => {
        const response = action.payload;
        const { id, status } = response;

        const basicInformation = mapResponseToBasicInfoModel(response, state.countries);
        const rules = mapResponseToRuleBuilderRuleModel(response, state);
        const { distribution, swapDistribution } = mapResponseToRuleBuilderDistributionModel(
          response,
          state
        );

        return {
          ...state,
          savedLtiPlanId: id,
          status,
          basicInformation,
          rules,
          distribution: {
            ...distribution,
            tableDataWillLoad: false,
          },
          swapBasicInformation: basicInformation,
          swapRules: rules,
          swapDistribution: {
            ...swapDistribution,
            tableDataWillLoad: false,
          },
        };
      },
    ],
    [
      getPlanStatusById,
      (state, action) => {
        const { id, status } = action.payload;

        return {
          ...state,
          savedLtiPlanId: id,
          status,
        };
      },
    ],
    [
      etagUpdate,
      (state, action) => {
        return {
          ...state,
          savedLtiPlanEtag: action.payload,
          swapBasicInformation: R.clone(state.basicInformation),
          swapRules: R.clone(state.rules),
        };
      },
    ],
    [
      ruleChange,
      (state, action) => {
        const {
          ruleData: { index, field, value, other },
        } = action.payload;
        const rules = R.clone(state.rules);

        rules[index] = {
          ...rules[index],
          [field]: value,
          ...other,
        };

        return {
          ...state,
          rules,
        };
      },
    ],
    [
      addRuleBlock,
      (state, action) => {
        const index = action.payload;
        const rules = R.clone(state.rules);
        return {
          ...state,
          rules: R.insert(index + 1, defaultRule, rules),
        };
      },
    ],
    [
      duplicateRuleBlock,
      (state, action) => {
        const index = action.payload;
        const rules = R.clone(state.rules);
        const newRuleBlock = { ...rules[index] };

        return {
          ...state,
          rules: R.insert(index + 1, { ...newRuleBlock, key: uuid() }, rules),
        };
      },
    ],
    [
      deleteRuleBlock,
      (state, action) => {
        const { index } = action.payload;
        const rules = R.clone(state.rules);

        return {
          ...state,
          rules: R.remove(index, 1, rules),
        };
      },
    ],
    [
      getDistributionByType,
      (state, action) => {
        const { distribution: newDistribution } = mapResponseToRuleBuilderDistributionModel(
          { distribution: { ...action.payload } },
          state
        );

        return {
          ...state,
          distribution: {
            ...state.distribution,
            ...newDistribution,
          },
        };
      },
    ],
    [
      changeDistributionType,
      (state, action) => {
        const { distributionType } = action.payload;
        return {
          ...state,
          distribution: {
            ...state.distribution,
            type: distributionType,
          },
        };
      },
    ],
    [
      changeTableCell,
      (state, action) => {
        const {
          dirtyRow: { id, value },
          total,
        } = action.payload;

        const dataForDirtyRows = transformDirtyRowsData({
          data: state.distribution.table.dirtyRows,
          id,
          value,
        });

        return {
          ...state,
          distribution: {
            ...state.distribution,
            table: {
              ...state.distribution.table,
              dirtyRows: dataForDirtyRows,
              total,
            },
          },
        };
      },
    ],
    [
      setDataTableLoading,
      (state, action) => {
        return {
          ...state,
          distribution: { ...state.distribution, tableDataWillLoad: action.payload },
        };
      },
    ],
    [
      setTableTotal,
      (state, action) => {
        return {
          ...state,
          distribution: {
            ...state.distribution,
            table: {
              ...state.distribution.table,
              dirtyRows: [],
              total: action.payload,
            },
          },
        };
      },
    ],
    [
      saveRulesBuilder,
      (state, action) => {
        const { response } = action.payload;

        return {
          ...state,
          swapRules: R.clone(state.rules),
          savedLtiPlanId: response.id,
        };
      },
    ],
    [
      resetRulesBuilder,
      (state) => {
        return {
          ...state,
          rules: state.swapRules,
          distribution: { ...state.swapDistribution, tableDataWillLoad: true },
        };
      },
    ],
    [
      redirectToRoot,
      (state) => {
        return {
          ...state,
          redirectToRoot: true,
        };
      },
    ],
    [resetStore, () => ({ ...initialState })],
  ]),
  initialState
);

export default ltiPlansReducer;
