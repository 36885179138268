import React, { memo, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Tooltip } from '@trs/components';

const StyledEllipsisTooltip = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
StyledEllipsisTooltip.displayName = 'StyledEllipsisTooltip';

const EllipsisTooltip = ({
  connectWithTooltip,
  className,
  content,
  dataTip,
  dataFor,
  dataOffset,
}) => (
  <Fragment>
    {connectWithTooltip && <Tooltip id={`tip-${dataFor}`} />}
    <StyledEllipsisTooltip
      className={className}
      data-testid={className}
      data-tip={dataTip}
      data-for={connectWithTooltip ? `tip-${dataFor}` : null}
      data-place="top"
      data-offset={dataOffset}
    >
      {content}
    </StyledEllipsisTooltip>
  </Fragment>
);

EllipsisTooltip.defaultProps = {
  className: '',
  dataOffset: '',
  dataFor: '',
  connectWithTooltip: true,
};

EllipsisTooltip.propTypes = {
  className: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  dataTip: PropTypes.string.isRequired,
  dataFor: PropTypes.string,
  dataOffset: PropTypes.string,
  connectWithTooltip: PropTypes.bool,
};

export default memo(EllipsisTooltip);
