import * as R from 'ramda';
import userModules from '../userModules';
import { TextOnly } from '../../../modules/common/Text';
import userManager, { permissions } from '../userManager';

const permissionForConfig = [
  permissions.REPORTS.REPORTS_CONFIGURATION_UPDATE,
  permissions.REPORTS.REPORTS_CONFIGURATION_READ,
];

const reports = () => {
  const reportItems = [
    {
      title: TextOnly({ path: 'header.REPORTS' }),
      key: [userModules.REPORTS],
      items: [],
      route: '/report',
    },
  ];

  const reportModules = userManager.getReportsModules();

  const hasConfigAccess =
    reportModules &&
    !R.isEmpty(reportModules) &&
    R.length(R.intersection(permissionForConfig, R.values(reportModules)[0])) > 0;

  if (hasConfigAccess) {
    reportItems.push({
      title: TextOnly({ path: 'header.CONFIGURATION' }),
      key: [userModules.REPORTS],
      items: [],
      route: '/report/configuration',
    });
  }

  return [
    {
      title: TextOnly({ path: 'header.REPORTS' }),
      key: [userModules.REPORTS],
      items: reportItems,
      route: '',
    },
  ];
};
export default reports;
