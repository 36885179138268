import setup from './setup';
import localPreparation from './localPreparation';
import salaryIncrease from './salaryIncrease';
import payscaleIncrease from './payscaleIncrease';
import countryDetails from './countryDetails';
import dashboard from './dashboard';
import statuses from './statuses';
import globalDashboard from './globalDashboard';

export default {
  PAGE_TITLE: 'Plan',
  BUTTON_SUBMIT_APPROVAL: 'Submit for Approval',
  APPROVE_STEP_TITLE: 'Approve step',
  APPROVE_STEP_SUBTITLE: 'Are you sure you want to approve this step?',
  REJECTION_REASON_TITLE: 'Rejection reason',
  REJECTION_REASON_SUBTITLE: 'Please provide a reason for rejection',
  MAIN_STATUS_ERROR: 'We could not retrieve the data. Please try again later.',
  MAIN_STATUS_ERROR_CTA: 'Return to %Dashboard%',
  CONFIRM_EXIT_TITLE: 'Are you sure you want to exit?',
  CONFIRM_EXIT_SUBTITLE: 'You are going to lose all your unsaved changes.',
  NO_DATA: 'No Data',
  ...globalDashboard,
  ...setup,
  ...localPreparation,
  ...salaryIncrease,
  ...payscaleIncrease,
  ...countryDetails,
  ...dashboard,
  ...statuses,
};
