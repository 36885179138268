"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
var R = _interopRequireWildcard(require("ramda"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var StyledTableCell = _styledComponents["default"].td.withConfig({
  displayName: "TableCellstyle__StyledTableCell",
  componentId: "sc-o51ka7-0"
})(["", ";"], function (props) {
  var _props$theme = props.theme,
    palette = _props$theme.palette,
    spacing = _props$theme.spacing,
    _props$theme$extra = _props$theme.extra,
    rewardsStatus = _props$theme$extra.rewardsStatus,
    genericStatus = _props$theme$extra.genericStatus;
  var highlight = props.highlight,
    align = props.align,
    maxWidth = props.maxWidth,
    minWidth = props.minWidth,
    width = props.width,
    children = props.children,
    type = props.type;
  var getStatusDotColor = function getStatusDotColor(child) {
    var mappedStatus = props.mappedStatus,
      statusCode = props.statusCode;
    var path = R.path([1], child);
    if (statusCode) {
      return genericStatus[statusCode.toLowerCase()];
    }
    if (!R.isNil(mappedStatus)) {
      var mappedKey = path ? mappedStatus[path].toLowerCase() : '';
      return rewardsStatus[mappedKey] || genericStatus[mappedKey];
    }
    return rewardsStatus[path.toLowerCase() || ''] || genericStatus[path.toLowerCase() || ''];
  };
  return (0, _styledComponents.css)(["color:", ";white-space:nowrap;text-overflow:ellipsis;.icon-ion-android-calendar{margin-left:", ";}", " ", " ", " ", " ", " ", " ", ""], palette.gray.alpha, spacing.tiny, type === 'status' && (0, _styledComponents.css)(["text-transform:capitalize;.status-dot{height:12px;width:12px;border-radius:50%;margin-right:", ";display:inline-block;background-color:", ";position:relative;background-color:", ";}"], spacing.tiny, rewardsStatus[R.path([1], children) ? children[1].toLowerCase() : ''], getStatusDotColor(children) || palette.basic.black), type === 'checkbox' && (0, _styledComponents.css)(["color:", ";text-align:center;"], palette.primary), align && (0, _styledComponents.css)(["text-align:", ";"], align), highlight && (0, _styledComponents.css)(["color:", ";"], palette.primary), maxWidth && (0, _styledComponents.css)(["max-width:", "px;overflow:hidden;text-overflow:ellipsis;span{max-width:", "px;@supports (-ms-ime-align:auto){display:inline;}@media all and (-ms-high-contrast:none){display:inline;}}a{overflow:hidden;text-overflow:ellipsis;}"], maxWidth, maxWidth - 32), minWidth && (0, _styledComponents.css)(["min-width:", "px;"], minWidth), width && (0, _styledComponents.css)(["width:", "px;"], width));
});
StyledTableCell.displayName = 'StyledTableCell';
var _default = StyledTableCell;
exports["default"] = _default;