export default {
  ELIGIBILITY_NEW_BLOCK_PLACEHOLDER:
    'Start by adding an %eligibility criterion% or leave it as is to apply to all %country% employees.',
  ELIGIBILITY_NEW_LOCAL_OVERRIDE_BLOCK_PLACEHOLDER:
    'Start by adding an %eligibility criterion% or leave it as is to apply to the selected country.',
  ELIGIBILITY_RULE_VARIABLE_NAME: 'Variable name',
  ELIGIBILITY_RULE_OPERATOR: 'Operator',
  ELIGIBILITY_RULE_VALUE: 'Value',
  ELIGIBILITY_VARIABLE_PLACEHOLDER: 'Variable',
  ELIGIBILITY_OPERATOR_PLACEHOLDER: 'Operator',
  ELIGIBILITY_VALUE_PLACEHOLDER: 'Value',
  ELIGIBILITY_VALUE_SELECT_PLACEHOLDER: 'Type to search value',
  ELIGIBILITY_CREATEABLE_SELECT_PLACEHOLDER: 'Type to add values',
  ELIGIBILITY_AND_OPERATOR: 'AND',
  ELIGIBILITY_OR_OPERATOR: 'OR',
  ELIGIBILITY_IF_KEYWORD: 'IF',
  ELIGIBILITY_DELETE_RULE: 'Delete rule',
  ELIGIBILITY_DUPLICATE_RULE: 'Duplicate rule',
  ELIGIBILITY_DELETE_GROUP: 'Delete group',
  ELIGIBILITY_DUPLICATE_GROUP: 'Duplicate group',
  ELIGIBILITY_GENERIC_LABEL: 'Eligibility',
  ELIGIBILITY_COUNTRY_CHANGE_DIALOG_TITLE: 'Change Country?',
  ELIGIBILITY_COUNTRY_CHANGE_DIALOG_SUBTITLE:
    'Changing the country will remove the reward formula.',
  ELIGIBILITY_YES: 'Yes',
  ELIGIBILITY_NO: 'No',
};
