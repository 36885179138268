"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
var _constants = require("../constants");
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var StyledTabsHeaders = _styledComponents["default"].div.withConfig({
  displayName: "TabHeaderstyle__StyledTabsHeaders",
  componentId: "sc-1nlnro0-0"
})(["", ";"], function (props) {
  var _props$theme = props.theme,
    typography = _props$theme.typography,
    spacing = _props$theme.spacing,
    color = _props$theme.palette;
  var alignment = props.alignment,
    noMargins = props.noMargins,
    isWizzard = props.isWizzard,
    controllable = props.controllable;
  return (0, _styledComponents.css)(["display:flex;font-size:", ";line-height:", ";align-self:", ";order:", ";", ";.tab-wrapper{display:flex;height:100%;.tab-element{padding:0px ", ";color:", ";font-weight:", ";border-bottom:", " solid ", ";text-transform:capitalize;cursor:default;line-height:30px;text-align:left;width:100%;display:flex;align-items:center;", ";&:focus{outline:none;}&.selected{color:", ";border-bottom-color:", ";}.wizzard-step{display:", ";width:", ";height:", ";margin-right:", ";color:", ";line-height:", ";border-radius:50%;text-align:center;background-color:", ";&.selected{background-color:", ";}}}}"], typography.size.paragraph, typography.lineHeight["default"], _constants.ALIGNMENT_MAP[alignment], alignment === 'left' ? 1 : 2, noMargins && (0, _styledComponents.css)(["margin-top:0;"]), spacing.small, color.gray.charlie, typography.weight.thick, spacing.xTiny, color.gray.charlie, !controllable && (0, _styledComponents.css)(["cursor:pointer;transition:all 0.2s ease-in-out;&:hover{color:", ";border-bottom-color:", ";}"], color.gray.bravo, color.gray.bravo), color.primary, color.primary, isWizzard ? 'block' : 'none', spacing.base, spacing.base, spacing.small, color.basic.white, typography.lineHeight.paragraph, color.gray.charlie, color.primary);
});
StyledTabsHeaders.displayName = 'StyledTabsHeaders';
var _default = StyledTabsHeaders;
exports["default"] = _default;