import * as R from 'ramda';
import { FORBIDDEN_STATUS_CODE } from '@trs/config';
import { createAction } from 'redux-actions';
import { GET, POST } from 'config/http';
import { rewards } from 'config/api';
import { showBanner } from 'actions/globalActions';
import { TextOnly } from 'modules/common/Text';
import { dispatchError } from '../../../actions/helpers';
import { mapResponseToBasicInfoModel, mapRulesToRequestModel } from '../../../reducer/helpers';

const actionTypes = {
  CALCULATE_IMPACT_PENDING: 'CALCULATE_IMPACT_PENDING',
  CALCULATE_IMPACT_SUCCESS: 'CALCULATE_IMPACT_SUCCESS',
  CALCULATE_IMPACT_ERROR: 'CALCULATE_IMPACT_ERROR',
  CALCULATE_IMPACT_UNAUTHORIZED: 'CALCULATE_IMPACT_UNAUTHORIZED',
  IMPACT_STORE_RESET: 'IMPACT_STORE_RESET',
  LOAD_OVERALL_IMPACT_RULES: 'LOAD_OVERALL_IMPACT_RULES',
  RULE_CHANGE: 'RULE_CHANGE',
  REWARDS_ADD_RULE_BLOCK: 'REWARDS_ADD_RULE_BLOCK',
  REWARDS_DUPLICATE_RULE_BLOCK: 'REWARDS_DUPLICATE_RULE_BLOCK',
  REWARDS_DELETE_RULE_BLOCK: 'REWARDS_DELETE_RULE_BLOCK',
  REWARDS_DELETE_RULE_FORMULAS: 'REWARDS_DELETE_RULE_FORMULAS',
};

export const calculateOverallImpactPending = createAction(actionTypes.CALCULATE_IMPACT_PENDING);
export const calculateOverallImpactSuccess = createAction(actionTypes.CALCULATE_IMPACT_SUCCESS);
export const calculateOverallImpactError = createAction(actionTypes.CALCULATE_IMPACT_ERROR);
export const calculateOverallImpactUnauthorized = createAction(
  actionTypes.CALCULATE_IMPACT_UNAUTHORIZED
);
export const overallImpactStoreReset = createAction(actionTypes.IMPACT_STORE_RESET);
export const loadOverallImpactRules = createAction(actionTypes.LOAD_OVERALL_IMPACT_RULES);
export const overallImpactRuleChange = createAction(actionTypes.RULE_CHANGE);
export const overallImpactAddRule = createAction(actionTypes.REWARDS_ADD_RULE_BLOCK);
export const overallImpactDuplicateRule = createAction(actionTypes.REWARDS_DUPLICATE_RULE_BLOCK);
export const overallImpactDeleteRule = createAction(actionTypes.REWARDS_DELETE_RULE_BLOCK);
export const overallImpactDeleteRuleFormulas = createAction(
  actionTypes.REWARDS_DELETE_RULE_FORMULAS
);

export const calculateRewardOverallImpact =
  (rewardId, payload, origin, config) => (dispatch, getState) => {
    dispatch(calculateOverallImpactPending());

    const overallImpactPayload = {
      rewardRules: payload,
      rewardId,
      // origin - where the request is coming from (rewards or rewards-impact)
      origin,
    };

    const requestHeaders = {
      context: origin === 'rewards' ? 'rewards' : 'model-impact',
      ...config.headers,
    };

    return POST(`${rewards.overallImpact}`, overallImpactPayload, requestHeaders, {
      redirectToUnauthorized: false,
    })
      .then((response) => {
        dispatch(calculateOverallImpactSuccess({ impact: R.path(['data', 'response'], response) }));
        showBanner({
          type: 'success',
          content: TextOnly({ path: 'rewards.OVERALL_IMPACT_CALCULATING_MESSAGE' }),
        });
        return R.path(['data'], response);
      })
      .catch((error) => {
        dispatchError(error, dispatch, getState());
        const status = R.path(['response', 'status'], error);

        if (status === FORBIDDEN_STATUS_CODE) dispatch(calculateOverallImpactUnauthorized());
        else dispatch(calculateOverallImpactError());

        return Promise.reject(error);
      });
  };

export const loadOverallImpactById = (impactId) => (dispatch) => {
  dispatch(calculateOverallImpactPending());
  return GET(`${rewards.overallImpact}/${impactId}`)
    .then((response) => {
      const impact = R.merge(
        { impactId },
        R.path(['data', 'response', 'overallImpactDetails'], response)
      );
      const impactRewards = R.path(['data', 'response', 'overallImpactReward'], response);
      impactRewards.basicInformation = mapResponseToBasicInfoModel(impactRewards);
      impactRewards.rules = mapRulesToRequestModel(
        impactRewards.rules,
        R.path(['basicInformation', 'template'], impactRewards)
      );
      impactRewards.origin = R.path(['data', 'response', 'origin'], response);
      impactRewards.category = {};
      impactRewards.settings = {};
      impactRewards.expiryDate = '';

      if (!impact || !impactRewards.rules) {
        dispatchError('Error loading overall impact by id', dispatch);
        dispatch(calculateOverallImpactError());
      } else {
        dispatch(
          calculateOverallImpactSuccess({
            impact,
          })
        );
        dispatch(loadOverallImpactRules(impactRewards));
      }

      return impactRewards.origin;
    })
    .catch((error) => {
      dispatchError(error, dispatch);
      dispatch(calculateOverallImpactError());
    });
};

export function ruleFormulaChange(index, value, other) {
  return {
    type: actionTypes.RULE_CHANGE,
    index,
    value,
    other,
  };
}

export const ruleChange = (index, field, value, other) => (dispatch) => {
  dispatch(
    overallImpactRuleChange({
      type: actionTypes.RULE_CHANGE,
      index,
      field,
      value,
      other,
    })
  );
};

export const addRuleBlock = (index) => (dispatch) => {
  dispatch(
    overallImpactAddRule({
      type: actionTypes.addRuleBlock,
      index,
    })
  );
};

export const deleteRuleFormulas = (index) => (dispatch) => {
  dispatch(
    overallImpactDeleteRuleFormulas({
      type: actionTypes.REWARDS_DELETE_RULE_FORMULAS,
      index,
    })
  );
};
