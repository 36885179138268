import styled, { css } from 'styled-components';

const StyledAvailableOptions = styled.aside`
  ${props => {
    const { spacing, palette: color, typography } = props.theme;

    return css`
      color: ${color.gray.alpha};
      flex: 1 1 37%;
      .CollapseItem {
        cursor: default;
      }
      h3 {
        margin: 0;
        border: 0;
      }
      h5 {
        font-weight: ${typography.weight.semiBold};
        color: ${color.secondary};
        .category-toggle-icon {
          margin-right: ${spacing.tiny};
        }
        i,
        span {
          cursor: pointer;
        }
      }
      .category-section {
        margin-bottom: ${spacing.base};
        .category-title {
          text-transform: uppercase;
          margin-bottom: ${spacing.tiny};
        }
      }
    `;
  }};
`;

StyledAvailableOptions.displayName = 'StyledAvailableOptions';
export default StyledAvailableOptions;
