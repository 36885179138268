"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var StyledDropdown = _styledComponents["default"].span.withConfig({
  displayName: "Dropdownstyle__StyledDropdown",
  componentId: "sc-1ocu8f5-0"
})(["", ";"], function (props) {
  var isFullWidth = props.isFullWidth;
  var _props$theme = props.theme,
    palette = _props$theme.palette,
    spacing = _props$theme.spacing,
    typography = _props$theme.typography,
    _props$theme$extra = _props$theme.extra,
    genericBorderRadius = _props$theme$extra.genericBorderRadius,
    boxShadow = _props$theme$extra.boxShadow;
  return (0, _styledComponents.css)(["background-color:", ";box-shadow:", ";display:inline-block;min-width:200px;max-width:400px;text-align:left;user-select:none;z-index:3;border-radius:", ";ul{font-size:", ";list-style-type:none;margin:0px;padding:0px;a,button,span{background-color:white;border:0;box-sizing:border-box;color:", ";cursor:pointer;display:block;font-size:", ";line-height:", ";outline:none;padding:", " ", " ", " ", ";text-align:left;text-decoration:none;transition:background-color 0.2s ease-in-out;white-space:nowrap;width:100%;i{width:", ";display:inline-block;margin-right:", ";text-align:center;font-size:", ";margin-top:-4px;}&.employee-name{color:", ";}&:not(span){&:hover,&.selected{background-color:", ";color:", ";i{font-size:", ";}}}}span.disabled{cursor:not-allowed;opacity:0.5;}}"], palette.basic.white, boxShadow, genericBorderRadius, typography.size.paragraph, palette.gray.alpha, typography.size.paragraph, typography.lineHeight["default"], spacing.xSmall, spacing.base, spacing.xSmall, spacing.xSmall, spacing.small, spacing.xSmall, typography.size.paragraph, palette.gray.alpha, palette.accent.charlie, palette.secondary, typography.size.paragraph);
});
StyledDropdown.displayName = 'StyledDropdown';
var _default = StyledDropdown;
exports["default"] = _default;