export const rewardsExceptions = {
  EXCEPTIONS_PAGE_TITLE: 'Exceptions',
  EXCEPTIONS_COLLAPSIBLE_TITLE: 'Exceptions',
  EXCEPTIONS_UPLOAD_BUTTON_TITLE: 'Upload Exception File',
  EXCEPTIONS_TAB_SUBTITLE:
    'You are about to add exceptions for this reward. Please select the correct document and publish the exceptions in order to be active.',
  EXCEPTIONS_TAB_SUBTITLE_NON_EMPTY:
    'There are exceptions loaded in for this reward. For values to be active they need to be published. To upload new values please select the correct document.',
  EXCEPTIONS_PAGE_SUBTITLE: 'Please copy the rewards ID and create and upload the excel file.',
  DOWNLOAD_TEMPLATE: 'Download template',
  EXCEPTIONS_PAGE_GROUP: 'Exceptions source',
  UPLOAD_FORM_FILE_LABEL: 'Select file',
  UPLOAD_FORM_BUTTON_TEXT: 'Browse',
  UPLOAD_FORM_TITLE: 'Upload exception file',
  EXCEPTIONS_FILE_UPLOAD_SUCCESS:
    'The exceptions file is being uploaded. This might take a while to complete.',
  EXCEPTIONS_FILE_ERROR_MAX_SIZE_EXCEEDED: '%FIELD% file exceeds the maximum of 20MB.',
  EMPLOYEE_ID: 'Employee Id',
  EMPLOYEE_NAME: 'Employee Name',
  REWARD_NAME: 'Reward Name',
  COUNTRY: 'Country',
  VALUE: 'Value',
  CURRENCY: 'Currency',
  EXCEPTION_STATUS: 'Exception Status',
  EXCEPTION_MENU_LIST_PUBLISH: 'Publish',
  EXCEPTION_MENU_LIST_DELETE: 'Delete',
  NO_EXCEPTIONS:
    "There aren't any Exceptions in the system. You can start by uploading an exceptions file.",
  UPLOAD_DATE: 'Upload Date',
  CONFIRM_DELETE_HEAD: 'Are you sure you want to delete this exception?',
  CONFIRM_DELETE_TEXT:
    'Please make sure you delete the correct exception. The delete action is irreversible.',
  CONFIRM_PUBLISH_HEAD: 'Are you sure you want to publish this exception?',
  CONFIRM_PUBLISH_TEXT: 'Please make sure you publish the correct exception.',
  CONFIRM_DELETE_ALL_HEAD: 'Are you sure you want to delete these exceptions?',
  CONFIRM_DELETE_ALL_TEXT:
    'Please make sure you delete the correct exceptions. The delete action is irreversible.',
  CONFIRM_PUBLISH_ALL_HEAD: 'Are you sure you want to publish these exceptions?',
  CONFIRM_PUBLISH_ALL_TEXT:
    'Please make sure you publish the correct exceptions. The publish action is irreversible.',
  EXCEPTIONS_SEARCH_CAPTION:
    '%FIELD% exception(s) found. Use the filter button on the left-hand side of the table to narrow down your search',
  CLEAR_SELECTED: 'Clear selection',
  DELETE_SELECTED: 'Delete selected exceptions',
  NR_EXCEPTIONS_SELECTED: '%NR% exceptions selected',
};

export const exceptionsSpecificLabels = {
  EXCEPTION_IS_BEING_DELETED: 'Exception is being deleted. This might take a while to complete.',
  EXCEPTIONS_ARE_BEING_DELETED:
    'Exceptions are being deleted. This might take a while to complete.',
  EXCEPTIONS_ARE_BEING_PUBLISHED:
    'Exceptions are being published. This might take a while to complete.',
};

export default {
  ...rewardsExceptions,
  ...exceptionsSpecificLabels,
};
