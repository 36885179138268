import * as actions from '../actions/types';

const initialState = {
  rewardDetails: {},
  rewardFeedback: {
    feedbackText: '',
    feedbackScore: -1,
  },
  rewardFeedbackInitial: {
    feedbackText: '',
    feedbackScore: -1,
  },
  isLoadingFeedback: false,
  isFeedbackSubmitPending: false,
};

export default function employeeCategoryDetailsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.EMPLOYEE_GET_REWARD_DETAILS: {
      return {
        ...state,
        rewardDetailsError: action.error,
        isLoadingDetails: !action.ready,
        rewardDetails: action.payload,
      };
    }
    case actions.GET_REWARD_FEEDBACK_PENDING: {
      return {
        ...state,
        isLoadingFeedback: true,
      };
    }
    case actions.GET_REWARD_FEEDBACK_SUCCESS: {
      return {
        ...state,
        rewardFeedback: action.payload,
        rewardFeedbackInitial: action.payload,
        isLoadingFeedback: false,
      };
    }
    case actions.GET_REWARD_FEEDBACK_ERROR: {
      return {
        ...state,
        isLoadingFeedback: false,
      };
    }
    case actions.CHANGE_REWARD_FEEDBACK_SCORE: {
      return {
        ...state,
        rewardFeedback: {
          ...state.rewardFeedback,
          feedbackScore: action.payload,
        },
      };
    }
    case actions.SEND_FEEDBACK_REWARD_PENDING: {
      return {
        ...state,
        isFeedbackSubmitPending: true,
      };
    }
    case actions.SEND_FEEDBACK_REWARD_ERROR: {
      return {
        ...state,
        isFeedbackSubmitPending: false,
      };
    }
    case actions.RESET_TO_INITIAL_FEEDBACK: {
      return {
        ...state,
        rewardFeedback: state.rewardFeedbackInitial,
      };
    }
    case actions.SEND_FEEDBACK_REWARD_SUCCESS: {
      return {
        ...state,
        isFeedbackSubmitPending: false,
        rewardFeedbackInitial: state.rewardFeedback,
      };
    }
    case actions.CHANGE_REWARD_FEEDBACK_TEXT: {
      return {
        ...state,
        rewardFeedback: {
          ...state.rewardFeedback,
          feedbackText: action.payload,
        },
      };
    }
    case actions.EMPLOYEE_CLEAR_REWARD_DETAILS: {
      return { ...initialState };
    }
    default:
      return state;
  }
}
