export default {
  HR_SYSTEM_TITLE: 'HR System',
  HR_SYSTEM_SUBTITLE: 'Please connect to your HR system',
  HR_SYSTEM_CAPTION:
    'Employee details will be used when creating rewards and when presenting employee statement.',
  HR_SYSTEM_LOAD_DATA_CARD_SUBTITLE:
    'Employee details will be used when creating rewards and when presenting employee statement. Upload files using the current template shown below.',
  HR_SYSTEM_EMPLOYEE_DATA_CARD: 'Employee Data',
  HR_SYSTEM_EMPLOYEE_DATA_CARD_SUBTITILE:
    'Employee data will be used throughout the application and can be uploaded in this section.',
  HR_SYSTEM_DETAILS: 'Details',
  HR_SYSTEM_DETAILS_INFO:
    '%PLATFORM% currently allows Workday connections. The below are the platform required details.',
  HR_SYSTEM_WORKDAY_URL: 'Workday url',
  HR_SYSTEM_WORKDAY_CLIENT_ID: 'Workday Client ID',
  HR_SYSTEM_WORKDAY_CLIENT_SECRET: 'Workday Client Secret Key',
  HR_SYSTEM_WORKDAY_VERSION: 'Workday Version',
  HR_SYSTEM_SENT_SUCCESS: 'File(s) successfully sent to Workspace.',
  HR_SYSTEM_PAYSCALES_TITLE: 'Pay Scales',
  HR_SYSTEM_PAYSCALES_SUBTITLE:
    'Pay scales will be used when modelling reward packages and can be configured in this section.',
  HR_SYSTEM_PAYSCALES_DIMENSIONS_TITLE: 'Dimensions',
  HR_SYSTEM_PAYSCALES_DIMENSIONS_SUBTITLE:
    'Pay scales can be configured by adding up to 6 dimensions below.',
  HR_SYSTEM_PAYSCALES_ADD_DIMENSION: 'Add Dimension',
  HR_SYSTEM_PAYSCALES_DIMENSION_INDEX: 'Dimension %INDEX%',
  HR_SYSTEM_PAYSCALES_DIMENSIONS_CHANGE:
    'The settings will be immediately implemented and will change the table data.',
  HR_SYSTEM_PAYSCALES_DIMENSIONS_CHANGE_MANAGE:
    'The settings will be immediately implemented and will change the table data. Any custom data added will be overwritten by data from the source HR information system.',
  HR_SYSTEM_PAYSCALES_SYSTEM_TITLE: 'Pay Scales Data',
  HR_SYSTEM_PAYSCALES_SYSTEM_SUBTITLE: 'System pay scales data can be viewed and managed below.',
  HR_SYSTEM_PAYSCALES_NO_RESULTS: 'No results found.',
  HR_SYSTEM_MODE_TITLE: 'Use HR System Pay Scales',
  HR_SYSTEM_MODE_SUBTITLE:
    'This option will bring in pay scale data from the source HR information system.',
  HR_MANAGE_MODE_TITLE: 'Manage Pay Scales',
  HR_MANAGE_MODE_SUBTITLE: 'This option will allow you to manually manage pay scale data.',
  HR_SYSTEM_SEARCH_PLACEHOLDER: 'Search by Dimension',
  HR_SYSTEM_PAYSCALES_COUNTRY: 'Selected Country',
  HR_SYSTEM_PAYSCALES_TABLE_DROPDOWN_EDIT_BUTTON: 'Edit Pay Scales',
  HR_SYSTEM_PAYSCALES_TABLE_DROPDOWN_DOWNLOAD_BUTTON: 'Download File',
  HR_SYSTEM_PAYSCALES_TABLE_DROPDOWN_ADD_FILE_BUTTON: 'Add Pay Scales from File',
  HR_SYSTEM_PAYSCALES_RECALCULATING_LOADER:
    'Data is loading. You will be notified when Pay Scale data is ready.',
  HR_SYSTEM_PAYSCALES_SAVED_DIMENSIONS_RECALCULATING:
    'Pay Scales data is recalculating. You will be notified when it is ready.',
  HR_SYSTEM_PAYSCALES_SAVE_CHANGES_SUCCESS: 'Your changes have been saved successfully!',
  HR_SYSTEM_PAYSCALES_SAVE_CHANGES_ERROR: 'An error has occured!',
  HR_SYSTEM_PAYSCALES_PAGE_GROUP: 'Pay Scales source',
  HR_SYSTEM_PAYSCALES_UPLOAD_TITLE: 'Upload Pay Scales File',
};
