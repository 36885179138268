import { handleActions } from 'redux-actions';
import { getValidationData, generateValidationData, resetStore } from '../actions';
import initialState from './initialState';

const dataValidationReducer = handleActions(
  new Map([
    [
      getValidationData,
      (state, action) => {
        const { ready, payload, error } = action;
        if (!ready) {
          return {
            ...state,
            isLoading: true,
          };
        }
        if (error) {
          return {
            ...state,
            isLoading: false,
          };
        }
        return {
          ...state,
          ...payload.response,
          isLoading: false,
        };
      },
    ],
    [
      generateValidationData,
      (state, action) => {
        const { ready, error } = action;
        if (!ready) {
          return {
            ...state,
            generatingCsv: true,
          };
        }
        if (error) {
          return {
            ...state,
            generatingCsv: false,
          };
        }
        return {
          ...state,
        };
      },
    ],
    [resetStore, () => initialState],
  ]),
  initialState
);

export default dataValidationReducer;
