/* eslint-disable max-lines-per-function */
import styled, { css } from 'styled-components';
import { media } from '@trs/utils';

const StyledSideBySide = styled.div`
  ${(props) => {
    const { spacing, typography, palette: color } = props.theme;
    const { isMimic } = props;
    const samsungTabletWidth = 712;
    return css`
      .reward-description-inline {
        display: flex;
      }
      .reward-status-banner {
        justify-content: space-between;
        li {
          margin-right: ${spacing.small};
          ${media.tabletLandscape`
            p > span {
              display: block;
            }
          `}
          ${media.desktop`
            p > span {
              display: inline;
            }
          `}
        }
      }

      .disclaimer .rdw-editor-main {
        background-color: ${color.gray.zircon};
      }
      .agreementWrapper .rdw-editor-main {
        background-color: ${color.gray.zircon};
      }
      .sides-page-title .rdw-editor-main {
        background-color: ${color.gray.zircon};
      }

      .profile-details__info {
        display: flex;
        flex-flow: wrap;
        align-items: center;
        margin-right: ${spacing.xBase};

        .styled-tooltip {
          max-width: 250px;
          border: none;
        }

        .info-label {
          display: inline;
          max-width: 100px;

          ${media.mobile`
            max-width: 150px;
          `}
          ${media.mobileLandscape`
            max-width: 250px;
          `}
          ${media.tablet`
            max-width: 320px;
          `}
          ${media.desktop`
            max-width: 138px;
          `}
        }

        .info-value {
          display: inline;
          max-width: 100px;
          margin-left: ${spacing.tiny};
          font-weight: bold;
          ${media.mobile`
            max-width: 150px;
          `}
          ${media.mobileLandscape`
            max-width: 250px;
          `}
          ${media.tablet`
            max-width: 320px;
          `}
          ${media.desktop`
            max-width: 120px;
          `}
        }
      }

      .detail-value {
        margin-left: 5px;
      }

      .status-banner__info {
        justify-content: start;
      }
      .countdown-warning {
        color: ${color.validation.error};
      }
      .sides-page-title {
        h1 {
          color: ${color.gray.solo};
          margin-bottom: ${spacing.small};
        }
        p {
          color: ${color.basic.gray};
          font-size: ${typography.base};
          line-height: ${typography.lineHeight.high};
          margin-bottom: ${spacing.basePlus};
        }
      }
      .selection-countdown + .flex-fixed-banner {
        margin-top: ${spacing.small};
      }
      .flex-fixed-banner {
        > div {
          padding: ${spacing.small} ${spacing.base};
          background-color: ${color.basic.white};
          border: 1px solid;
          margin-bottom: 0;
        }
      }
      .profile-card {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        display: flex;
        h3 {
          margin: 0;
          line-height: 1;
          &:after {
            content: ':';
          }
        }
        h3,
        .profile-card__info {
          margin-right: 16px;
        }
        margin-bottom: 16px;
      }
      .side-by-side-content {
        margin-top: ${spacing.small};
      }
      .Card {
        margin-bottom: ${spacing.tiny};
        min-height: ${spacing.xxLarge};
        padding: ${spacing.small};
      }

      .disclaimer {
        margin-bottom: ${spacing.small};
        font-size: ${typography.size.table};
        word-break: break-all;
        word-break: break-word;
      }

      .agreementWrapper {
        display: flex;
        font-size: ${typography.size.table};
      }

      .agreementWrapper > .checkbox-wrapper {
        padding: ${spacing.small} ${spacing.tiny} ${spacing.small} 0px;
      }
      .agreementWrapper > .checkbox-icon {
        border: red;
      }

      .agreementErrorMessage {
        padding: 0px;
        margin-top: -${spacing.basePlus};
        margin-left: ${spacing.basePlus};
        font-size: ${typography.size.table};
        color: ${color.validation.error};
      }

      .sticky {
        position: static !important;
        padding: 0;
        background: transparent;
        box-shadow: none;

        .layout {
          padding: 0;
        }

        ${media.tablet`
          position: fixed !important;
          padding: ${spacing.tiny} 0;
          background: ${color.basic.white};
          box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.15);

          .layout {
            padding: 0 ${spacing.small};
          }
        `}

        @media (min-width: ${samsungTabletWidth}px) {
          position: fixed !important;
          padding: ${spacing.tiny} 0;
          background: ${color.basic.white};
          box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.15);

          .layout {
            padding: 0 ${spacing.small};
          }
        }

        ${media.mobile`
          position: fixed !important;
          padding: ${spacing.tiny} 0;
          background: ${color.basic.white};
          box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.15);

          .layout {
            padding: 0 ${spacing.small};
          }
        `}

        ${media.desktop`
          padding: ${spacing.small} 0;
        `}
      }

      .sticky-options {
        padding: 0;
        .layout {
          padding: 0 !important;
        }
        .sticky {
          padding: 0;
          background: transparent;
          box-shadow: none;
        }

        ${isMimic &&
        css`
          .sticky {
            margin-top: 35px;
          }
        `}
      }

      .flex-package-selection {
        padding-top: 5px;
        width: 320px;
      }

      .relative-page-loader {
        position: relative;
      }
    `;
  }};
`;

StyledSideBySide.displayName = 'StyledSideBySide';
export default StyledSideBySide;
