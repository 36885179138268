"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var tempBaseSpacing = '20px'; // to be discussed with Design

var StyledThreshold = _styledComponents["default"].div.withConfig({
  displayName: "Thresholdstyle__StyledThreshold",
  componentId: "sc-1hnzyzs-0"
})(["", ";"], function (props) {
  var _props$theme = props.theme,
    palette = _props$theme.palette,
    spacing = _props$theme.spacing;
  return (0, _styledComponents.css)([".threshold-block{margin:40px 0;position:relative;outline:none;> .Formula-toolbox{left:93px;}.formula-button{display:inline-block;vertical-align:top;}.threshold-element{display:inline-block;vertical-align:top;position:relative;margin:0 ", " 0 0;> span{position:relative;}&.contribution-element{margin-left:20px;}}.threshold-header{padding:0;text-align:right;width:50px;margin-right:40px;}.threshold-operator{padding:0;position:relative;min-width:130px;}.Select-control{padding:0 ", " ", ";.Select-arrow-zone{vertical-align:top;}.Select-value{line-height:1.2;}}.threshold-operator,.searchable-input{display:inline-block;vertical-align:top;}.threshold-block-function{position:relative;display:block;margin:0;> button{position:absolute;left:0;top:0;z-index:2;&.delete-member{top:-12px;margin:0;left:80px;}&.add-member{top:auto;margin:0;left:80px;bottom:-12px;}}}.formula-group{margin:", " 0 ", " 90px;.formula-group-operator{padding:0 ", ";white-space:nowrap;flex:1 0 auto;}.formula-group-elements{padding:", " ", ";background-color:", ";> span{margin:0 ", ";}}}.inputError{left:0;}}&.formula-group{display:flex;flex-direction:row;}.threshold-contribution{&.threshold-block .formula-group{margin-left:", ";}.formula-element-wrapper{margin-right:", ";}}"], tempBaseSpacing, spacing.tiny, tempBaseSpacing, tempBaseSpacing, tempBaseSpacing, spacing.tiny, tempBaseSpacing, spacing.tiny, palette.basic.white, spacing.small, spacing.tiny, spacing.small);
});
StyledThreshold.displayName = 'StyledThreshold';
var _default = StyledThreshold;
exports["default"] = _default;