import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ErrorPage, Paragraph, Button, LinkButton } from '@trs/components';
import Text, { TextOnly } from '../../../modules/common/Text';
import * as images from '../../../assets/images';
import { SUPPORT_EMAIL } from '../../constants';

const NotAuthorizedPage = ({ landingPage }) => (
  <ErrorPage
    imageSrc={images.accessDenied}
    title={TextOnly({ path: 'generic.NOT_AUTHORIZED_TITLE' })}
    text={
      <Paragraph>
        <Text path="generic.NOT_AUTHORIZED_DESC" withHtml />
        &nbsp;
        <LinkButton href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</LinkButton>
        <span> ) for more details.</span>
      </Paragraph>
    }
    button={
      <Link to={landingPage === null ? '/' : landingPage}>
        <Button>
          <Text path="generic.ERROR_PAGE_BUTTON" />
        </Button>
      </Link>
    }
  />
);

NotAuthorizedPage.defaultProps = {
  landingPage: '/',
};

NotAuthorizedPage.propTypes = {
  landingPage: PropTypes.string,
};

const mapStateToProps = ({ user }) => ({
  landingPage: user.landingPage,
});

export default connect(mapStateToProps)(NotAuthorizedPage);
