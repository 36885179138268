export default {
  SIDE_PAGE_TITLE: 'Select your rewards',
  SIDE_PAGE_DESCRIPTION: '<<Placeholder>>',
  CURRENT_STATEMENT: 'Current Statement',
  YOUR_SELECTION: 'Your Selection',
  AVAILABLE_OPTIONS: 'Available Options',
  EXPORT: 'Export',
  EXPORT_TO_PDF: 'Export to PDF',
  EXPORTING: 'Exporting',
  SAVE: 'Save',
  RESET_DEFAULT: 'Reset To Default',
  NO_OPTIONS: 'You currently have no optional rewards to choose from',
  SIDE_TOTAL: 'Total',
  SIDE_SUBTOTAL: 'Subtotal',
  SIDE_NAME_TITLE: 'Rewards',
  SIDE_CURRENT_TITLE: 'Current Statement',
  SIDE_FUTURE_TITLE: 'New Statement',
  PROCESSING_REWARDS: 'Processing your rewards...',
  NO_CALCULATED_REWARDS: 'There are currently no available rewards.',
  PACKAGE_LOAD_ERROR:
    'We have encountered an issue while loading this package. Go to your %employee statement%',
  CALCULATION_ERROR: 'We have encountered an issue while calculating your statement.',
  SAVE_OPTIONS_SUCCESS_MODELLING: 'Your options were saved successfully.',
  SAVE_OPTIONS_SUCCESS_SUBMISSION:
    'Your options were saved successfully. You can submit your selection until %DATE%.',
  RESET_OPTIONS_SUCCESS: 'Your options have been reset.',
  RESTORE_OPTIONS:
    'The flex rewards in this package have been changed. Please review your selection!',
  UPDATED_CHOICES: 'Your choices were updated. You can submit your choices until %DATE%.',
  MODELLING_SAVED_CHOICES: 'Your choices were updated.',
  SHOW_AVAILABLE_OPTIONS: 'Available Options',
  RESET_DIALOGUE_TITLE: 'Reset To Default',
  WARNING_MSG_TITLE: 'Are you sure you want to continue?',
  RESET_DIALOGUE_SUBTITLE: 'Are you sure you want to reset your selection?',
  AVAILABLE_OPTIONS_TILE_CAPTION: 'Contribution should be between %MIN% and %MAX%.',
  AVAILABLE_OPTIONS_INCLUDED_BUDGET: 'Included in Budget',
  AVAILABLE_OPTIONS_INCLUDED_CUSTOM_LABEL: 'Included in %BUDGET%',
  CATEGORY_GROUP_HEADER: 'Please select one from this group',
  CATEGORY_CLEAR_SELECTION: 'Clear selection',
  CHOOSE: 'Choose',
  REMAINING_BUDGET: 'Remaining Budget',
  EXCEEDING_BUDGET_ALLOWED: 'Exceeding Value Allowed: ',
  MINIMUM_BUDGET_ALLOWED: 'Minimum Usage: ',
  NO_DESCRIPTION: 'No description',
  SELECTION_AVAILABLE_UNTIL: 'Flexible package available until',
  AMOUNT_OF_DAYS_LEFT: '(%COUNT% days left)',
  SBS_BANNER_HEADER:
    'In order to submit your selection, the following validation errors need to be fixed:',
  SBS_VALIDATIONS_CANNOT_GO_NEGATIVE: 'The package %LABEL% can not be negative.',
  SBS_VALIDATIONS_EXCEEDED_BUDGET: 'You have exceeded the negative %LABEL% limit.',
  SBS_VALIDATIONS_MINIMUM_USAGE: 'You need to select at least %AMOUNT% %CURRENCY%.',
  SBS_REWARD_SLIDER_VALIDATION_BELOW: '%REWARD% value is below the minimum limit allowed.',
  SBS_REWARD_SLIDER_VALIDATION_ABOVE: '%REWARD% value is above the maximum limit allowed.',
  SUBMIT_SELECTION_DIALOG_TITLE: 'Are you sure you wish to submit your choices?',
  SUBMIT_SELECTION_DIALOG_SUBTITLE:
    'Once submitted, you will no longer be able to make any changes. You will be able to view your elections until the enrolment window closes on <strong>%DATE%</strong>.',
  SUBMIT_SELECTION_COMMENTS: 'Comments (optional)',
  SUBMIT_SELECTION_COMMENTS_PLACEHOLDER: 'Write comment here',
  SUBMIT_SELECTION_COMMENTS_LENGTH: 'You can add up to 1000 characters with spaces.',
  SUBMIT_SELECTION_SUCCESS: 'Your choices have been submitted.',
  EXCEEDED_TOOLTIP_TEXT: 'This is the maximum amount by which the %title% value can be exceeded',
  EXCEEDED_TOOLTIP_DEFAULT_BUDGET_TITLE: 'budget',
  MINIMUM_USAGE_TOOLTIP_TEXT: 'This is the mandatory minimum spending that must be achieved.',
  TOGGLE_CATEGORY: 'Toggle %CATEGORY% options',
  REWARD_VALUE: 'Reward value',
  ACCEPT_WARNING: 'Accept',
  ACCEPT_WARRNING_MESSAGE: 'You need to tick the checkbox before you can submit.',
  CHANGE_ACTIVE_PACKAGE: 'You are about to switch the active package',
  CHANGE_ACTIVE_PACKAGE_SUBTITLE:
    'Changing the selected package will cause all progress to be lost.',
  PACKAGE_AVAILABILITY: 'Available until',
  PACKAGE_NAVIGATION_PLACEHOLDER: 'Change package',
  EXTRA: '%categoryTitle% (EXTRA)',
};
