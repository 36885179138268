export default {
  AUTHENTICATION_TITLE: 'Authentication',
  AUTHENTICATION_SUBTITLE:
    'The authentication section requires you to provide information about the access to %PLATFORM%.',
  AUTHENTICATION_BODY:
    'Employee Profile settings will be used to display information about users Personal Details on My Statement Page.',
  AUTHENTICATION_LIST_SIGNON: 'Sign on/ reply URL = https://[domain]/login',
  AUTHENTICATION_LIST_REQUIRED: 'Required API = Graph API',
  AUTHENTICATION_LIST_DELEGATED:
    'Delegated Permisions = Access the directory and sign in the user, read directory data, read all users basic profiles, sign in and read user profile.',
  AUTHENTICATION_CAPTION: 'The connection with Azure Active Directory was established',
  OPEN_ID_META_PATH: 'OpenId Meta File Path',
  AD_SECRET: 'AD Client Secret Key',
  AD_APP_ID: 'AD Application Id',
  AD_TENANT_ID: 'AD Tenant Id',
};
