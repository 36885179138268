import * as R from 'ramda';
import { handleActions } from 'redux-actions';
import initialState from './initialState';
import {
  mapEligibilityOperators,
  mapSystemVariables,
  processPopulationChange,
  getLookupData,
  addUuidsToEligibilityItems,
} from './helpers';

import {
  getEligibilityOperators,
  getSystemVariables,
  populationChange,
  getVariablesLookupData,
  updatePopulationError,
  getPopulationById,
  updatePopulationEtag,
  storeReset,
  revertEligibility,
} from '../actions';

const reducer = handleActions(
  new Map([
    [
      getEligibilityOperators,
      (state, action) => ({
        ...state,
        eligibilityOperators: mapEligibilityOperators(R.prop('payload', action)),
      }),
    ],
    [
      getSystemVariables,
      (state, action) => ({
        ...state,
        systemVariables: mapSystemVariables(R.prop('payload', action)),
      }),
    ],
    [
      getPopulationById,
      (state, { payload }) => {
        const populations = payload.eligibilityRules.map(rule => ({
          ...rule,
          // this needs to be added so that the "AND" and "OR" buttons function properly
          eligibility: R.merge({}, addUuidsToEligibilityItems(rule.eligibility)),
        }));

        return {
          ...state,
          populations,
          populationsSnapshot: populations,
        };
      },
    ],
    [
      updatePopulationEtag,
      (state, { payload }) => {
        return {
          ...state,
          etag: payload.etag,
        };
      },
    ],
    [
      populationChange,
      (state, action) => ({
        ...state,
        populations: processPopulationChange(R.clone(state.populations), R.prop('payload', action)),
      }),
    ],
    [
      updatePopulationError,
      (state, action) => {
        const newState = state;
        return R.assocPath(['errors', ...action.payload.path], action.payload.data, newState);
      },
    ],
    [
      getVariablesLookupData,
      (state, action) => {
        const { lookupData, url } = action.payload;

        return {
          ...state,
          lookUpValues: {
            ...state.lookUpValues,
            ...getLookupData(lookupData, url),
          },
        };
      },
    ],
    [
      revertEligibility,
      state => ({
        ...state,
        populations: [...state.populationsSnapshot],
      }),
    ],
    [
      storeReset,
      () => ({
        ...initialState,
      }),
    ],
  ]),
  R.clone(initialState)
);

export default reducer;
