import * as R from 'ramda';
import { v4 as uuid } from 'uuid';
import { isBlank } from '@trs/utils';
import { DATA_TYPES } from '@trs/components';
import {
  computeTotal,
  foundCountryGlobal,
  getFilteredVariablesCountryCodes,
  mapCustomDistributionTableConfig,
} from 'modules/fixedCosts/common/helpers';
import {
  RECURRENCE_OPTIONS,
  DEFAULT_TOTAL_DISTRIBUTION,
  RESPONSE_DISTRIBUTION_TYPE,
} from '../constants';

const addDataType = ({ argument }) => {
  return R.map(R.assoc('dataType', DATA_TYPES.MIXED))(argument);
};

const addUuidsToFormulaItems = (formulaItems) =>
  formulaItems.map((formulaItem) =>
    formulaItem.arguments
      ? {
          key: uuid(),
          ...formulaItem,
          arguments: [
            addUuidsToFormulaItems(R.path(['arguments', 0], formulaItem)),
            addUuidsToFormulaItems(
              addDataType({ argument: R.path(['arguments', 1], formulaItem) })
            ),
          ],
        }
      : {
          ...formulaItem,
          key: uuid(),
        }
  );

export const mapRulesToRequestModel = (rules) => {
  return rules.map((rule) => {
    const payload = {
      ...rule,
      key: uuid(),
    };

    payload.formula = addUuidsToFormulaItems(rule.formula.items);

    return payload;
  });
};

export const mapResponseToBasicInfoModel = ({
  displayName,
  countries,
  currency,
  description,
  startDate,
  endDate,
  recurrence,
  foreignExchange,
  category,
}) => {
  const recurrenceFrequency = R.path(['frequency'], recurrence);
  const recurrenceCount = R.path(['every'], recurrence);
  const descriptionPlainText =
    !R.isNil(description) && !R.isEmpty(description) ? description.replace(/<[^>]+>/g, '') : '';

  return {
    displayName,
    countries,
    variablesCountryCodes: getFilteredVariablesCountryCodes(countries),
    currency,
    fxSource: R.path(['sourceId'], foreignExchange),
    category: R.path(['level1Id'], category),
    subcategory: R.path(['level2Id'], category),
    type: R.path(['level3Id'], category),
    description,
    startDate,
    endDate,
    recurrence: isBlank(recurrence) ? 'no' : 'yes',
    recurrenceCount: recurrenceCount || null,
    recurrenceFrequency:
      recurrenceFrequency && recurrenceFrequency && RECURRENCE_OPTIONS[recurrenceFrequency - 1],
    plainText: descriptionPlainText,
  };
};

export const mapResponsePercentageDistributionByCountry = ({ distributions }) => {
  if (
    R.equals(distributions.length, 1) &&
    foundCountryGlobal([].concat(distributions[0].country))
  ) {
    return [];
  }

  return R.map(({ country: { code, label, value }, percentage }) => {
    return {
      id: code,
      code,
      label,
      country: value,
      distribution: Number(percentage),
    };
  }, distributions);
};

const mapResponseDistributionTableConfig = ({ percentageDistribution, isAutomatic }) => {
  const rows = mapResponsePercentageDistributionByCountry(percentageDistribution);
  const total = isAutomatic ? DEFAULT_TOTAL_DISTRIBUTION : computeTotal(rows);

  return {
    rows,
    total,
  };
};

export const mapResponseToRuleBuilderRuleModel = ({ rule }, initialState) => {
  return isBlank(rule) ? initialState.rules : mapRulesToRequestModel([].concat(rule));
};

export const mapResponseToTableDistribution = ({
  countries,
  distributionType,
  percentageDistribution,
}) => {
  const isAutomatic = R.equals(distributionType, RESPONSE_DISTRIBUTION_TYPE.AUTOMATIC);
  const distributionTableConfig = isBlank(percentageDistribution)
    ? mapCustomDistributionTableConfig({ countries, isAutomatic })
    : mapResponseDistributionTableConfig({ percentageDistribution, isAutomatic });

  return {
    isAutomatic,
    table: {
      ...distributionTableConfig,
    },
  };
};

export const mapResponseRuleBuilderDistributionModel = (response, state, initialState) => {
  const {
    isAutomatic,
    table: { rows, total },
  } = mapResponseToTableDistribution(response);

  const mapSwapAutomatic = isAutomatic
    ? { table: { rows, total } }
    : initialState.distribution.swapAutomatic;
  const mapSwapManual = !isAutomatic
    ? { table: { rows, total } }
    : initialState.distribution.swapManual;

  return {
    isAutomatic,
    swapIsAutomatic: isAutomatic,
    tableConfigWillLoad: false,
    table: {
      ...state.distribution.table,
      rows,
      total,
      dirtyRows: [],
    },
    swapAutomatic: mapSwapAutomatic,
    swapManual: mapSwapManual,
  };
};

export const transformResponseGetAllFixedCosts = (fixedCosts) => {
  if (isBlank(fixedCosts)) return [];

  return fixedCosts.map((item) => ({
    ...item,
    displayName: item.name,
    menuTransform: (menuList, dataItem) => {
      const index = R.findIndex(R.propEq('key', 'edit'))(menuList);
      return dataItem.canEdit ? menuList : R.remove(index, 1, menuList);
    },
  }));
};

export const transformDirtyRowsData = ({ data, id, value }) => {
  const newDirtyRowsState = R.clone(data);
  const index = R.findIndex(R.propEq('id', id))(newDirtyRowsState);

  if (R.equals(index, -1)) {
    newDirtyRowsState.push({ id, value });
  } else {
    newDirtyRowsState[index] = R.assoc('value', value, newDirtyRowsState[index]);
  }

  return newDirtyRowsState;
};

export default mapResponseToBasicInfoModel;
