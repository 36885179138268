import styled, { css } from 'styled-components';
import { media } from '@trs/utils';

const StyledDescriptionDialog = styled.div`
  ${({ theme, isOpen }) => {
    const { palette, spacing, typography } = theme;

    return css`
      display: inline-block;

      * {
        -moz-user-select: none;
      }
      a {
        color: ${palette.secondary};
      }

      .icon-information {
        border: 1px solid ${palette.basic.gray};
        border-radius: 50%;
        padding: 2px 6px;
        cursor: pointer;

        ${isOpen &&
        css`
          background-color: ${palette.gray.delta};
          border-color: ${palette.secondary};
          color: ${palette.basic.white};
        `}
      }

      .dialog-close-icon {
        position: absolute;
        margin-top: ${spacing.xBase};
        right: 0;
        cursor: pointer;
      }

      .dialog-message {
        position: relative;
        padding: 0;
        font-size: ${typography.size.table};
        line-height: ${typography.lineHeight.paragraph};
      }

      .description-template-dialog {
        padding: 0 ${spacing.base};
        max-height: 90vh;
        overflow: auto;
      }

      .dialog-actions-container {
        background-color: transparent;
      }

      .dialog-title {
        color: ${palette.secondary};
        padding: ${spacing.base} ${spacing.base} ${spacing.small} 0;
        word-break: break-word;

        h3 {
          line-clamp: 2;
          -webkit-line-clamp: 2;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }

      .description-header {
        margin-bottom: ${spacing.small};
        font-size: ${typography.size.table};
      }

      ${media.tabletLandscape`
        .description-template-dialog {
          padding: 0px;
        }

        .rdw-editor-main {
            padding: 0 ${spacing.base} 0 ${spacing.tiny};
        }

        .dialog-template-container {
          border: 0px;
        }

        .dialog-actions-container {
          background-color: ${palette.basic.white};
          border: 0px;

          .dialog-actions-right {
            padding: ${spacing.small};
          }
        }

        .dialog-close-icon {
          display: none;  
        }

        .dialog-message {
          background-color: ${palette.basic.white};
          font-size: ${typography.size.paragraph};
        }

        .description-header {
          padding: 0 ${spacing.base} ${spacing.xSmall} ${spacing.base};
          background-color: ${palette.basic.white};
          margin-bottom: 0;
        }

        .dialog-title {
          background-color: ${palette.basic.white};
          padding: ${spacing.base} ${spacing.base} ${spacing.small};
        }
      `}
    `;
  }}
`;

StyledDescriptionDialog.displayName = 'StyledDescriptionDialog';
export default StyledDescriptionDialog;
