import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import * as R from 'ramda';
import { Icon, H4 } from '@trs/components';
import StyledCountryItem from './CountryItem.style';
import Text from '../../../../../common/Text';

const CountryItem = ({
  country,
  collapsible,
  isCompleted,
  isManualSetup,
  isActive,
  localSettingsId,
  collapseProps,
  dataTestId,
}) => (
  <StyledCountryItem isActive={isActive} data-testid={dataTestId}>
    {isActive ? (
      <Fragment>
        {collapsible && (
          <Icon
            onClick={R.prop('toggle', collapseProps)}
            className="chevron"
            size={14}
            icon={`ion-chevron-${R.prop('isOpen', collapseProps) ? 'down' : 'right'}`}
          />
        )}
        <Link to={`/plan/local-preparation/${localSettingsId}`}>
          <H4>{country}</H4>
        </Link>
      </Fragment>
    ) : (
      <H4>{country}</H4>
    )}

    {isManualSetup && (
      <span className="manual-configuration">
        <Text path="benchmarking.DASHBOARD_MANUAL_CONFIGURATION" />
      </span>
    )}
    {isCompleted && <Icon className="completed-checkmark" size={18} icon="ion-checkmark-circled" />}
  </StyledCountryItem>
);

CountryItem.propTypes = {
  collapseProps: PropTypes.shape({
    isOpen: PropTypes.bool,
    toggle: PropTypes.func,
  }),
  localSettingsId: PropTypes.string,
  country: PropTypes.string.isRequired,
  isCompleted: PropTypes.bool.isRequired,
  isManualSetup: PropTypes.bool.isRequired,
  isActive: PropTypes.bool.isRequired,
  collapsible: PropTypes.bool,
  dataTestId: PropTypes.string,
};

CountryItem.defaultProps = {
  collapseProps: {
    isOpen: false,
    toggle: () => {},
  },
  collapsible: false,
  localSettingsId: '',
  dataTestId: 'country-item',
};

export default CountryItem;
