import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import * as R from 'ramda';
import { SIZE_UNIT, toLower, isBlank } from '@trs/utils';
import { ERROR_CODES } from 'modules/common/Form/FileInput/helpers';
import { TextOnly } from 'modules/common/Text';

export const ROOT_FOLDER = 'root';

export const ITEM_TYPES = {
  directory: 'directory',
  file: 'file',
};

export const ICONS = {
  [ITEM_TYPES.directory]: 'ion-folder',
  [ITEM_TYPES.file]: 'ion-android-document',
};

export const ICON_SIZE = 22;

export const isDirectory = (itemType) => toLower(itemType) === ITEM_TYPES.directory;

export const isFileView = (list) =>
  R.reduce((final, item) => toLower(item.itemType) === ITEM_TYPES.file && final, true, list);

export const getItemId = (item) => (isBlank(item.id) ? item.displayName : item.id);

export const getBreadcrumbPath = (breadcrumbPath) =>
  breadcrumbPath.map((item) => {
    const itemLink = item.directoryId !== ROOT_FOLDER ? item.directoryId : '';

    return (
      <Link to={`/workspace/${itemLink}`} key={item.directoryId}>
        {item.displayName}
      </Link>
    );
  });

export const StyledWorkspaceActions = styled.section`
  ${(props) => {
    const { spacing } = props.theme;

    return css`
      align-items: flex-end;
      display: flex;
      margin: ${spacing.small} 0 ${spacing.tiny};

      ul {
        margin: 0 0 ${spacing.xTiny};
      }

      > button {
        margin-left: auto;
      }
    `;
  }}
`;

export const FILE_MAX_SIZE = 10;

export const getErrorMessages = () => {
  const KEYWORD = TextOnly({ path: 'workspace.PAGE_TITLE' });
  return {
    [ERROR_CODES.EXTENSION_NOT_SUPPORTED]: TextOnly({
      path: 'generic.FILE_ERROR_INCORRECT_FILE_FORMAT',
      transform: (label = '') => label.replace('%FIELD%', KEYWORD),
    }),
    [ERROR_CODES.MAX_SIZE_EXCEEDED]: TextOnly({
      path: 'generic.FILE_ERROR_MAX_SIZE_EXCEEDED',
      transform: (label = '') =>
        label.replace('%FIELD%', KEYWORD).replace('%MAX_SIZE%', `${FILE_MAX_SIZE} ${SIZE_UNIT.mb}`),
    }),
  };
};
