"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.processCatValues = exports.mergeDataWithColor = exports.getLighterColor = exports.getCellOptions = exports.filterEmptyValues = exports["default"] = void 0;
var R = _interopRequireWildcard(require("ramda"));
var _color = _interopRequireDefault(require("color"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
var DEFAULT_CELL_STROKE_WIDTH = 1;
var processCatValues = function processCatValues(data) {
  var valueKey = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'value';
  return R.map(function (item) {
    return _objectSpread(_objectSpread({}, item), {}, {
      isAmount: !!item[valueKey]
    });
  }, data);
};
exports.processCatValues = processCatValues;
var filterEmptyValues = function filterEmptyValues(data) {
  var valueKey = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'value';
  return R.reject(function (item) {
    return !item[valueKey];
  }, data);
};
exports.filterEmptyValues = filterEmptyValues;
var getLighterColor = function getLighterColor(fullColor) {
  var perc = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0.9;
  return (0, _color["default"])(fullColor).fade(perc).string();
};
exports.getLighterColor = getLighterColor;
var getNegativeFill = function getNegativeFill(_ref) {
  var color = _ref.color,
    isActive = _ref.isActive;
  if (isActive) {
    return getLighterColor(color, 0.45);
  }
  return getLighterColor(color, 0.98);
};
var mergeDataWithColor = function mergeDataWithColor() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var colorValues = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  return data.map(function (item, idx) {
    return _objectSpread(_objectSpread({}, item), {}, {
      color: colorValues[idx % colorValues.length]
    });
  });
};
exports.mergeDataWithColor = mergeDataWithColor;
var getCellOptions = function getCellOptions(_ref2) {
  var entry = _ref2.entry,
    labelKey = _ref2.labelKey,
    activeIndex = _ref2.activeIndex,
    index = _ref2.index;
  var cellOptions = {
    key: "cell-".concat(entry[labelKey]),
    fill: entry.isNegative ? getNegativeFill({
      color: entry.color,
      isActive: activeIndex === index
    }) : entry.color,
    stroke: entry.color,
    strokeDasharray: entry.isNegative ? '2 2' : '',
    strokeWidth: DEFAULT_CELL_STROKE_WIDTH
  };
  if (activeIndex !== -1 && activeIndex !== index) {
    cellOptions.fill = getLighterColor(entry.color);
    cellOptions.stroke = 0;
  }
  return cellOptions;
};
exports.getCellOptions = getCellOptions;
var _default = {
  filterEmptyValues: filterEmptyValues
};
exports["default"] = _default;