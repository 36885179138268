import { createSuffixedActions } from 'modules/config';

const actionSuffix = '_BETA';
const actionTypesNames = [
  'VALIDATE_MODELLER',
  'LOAD_MODELLER_OUTPUT',
  'SET_REQUEST_ID',
  'TOGGLE_SEARCH_DIALOG',
  'LOAD_VARIABLES',
  'FETCH_EMPLOYEES_LIST_PENDING',
  'FETCH_EMPLOYEES_LIST_SUCCESS',
  'FETCH_EMPLOYEES_LIST_ERROR',
  'RESET_STORE',
  'TOGGLE_MODELLER_PROFILE',
  'GET_CATEGORIES_MAPPING',
  'SUBMIT_CATEGORIES_MAPPING_PENDING',
  'SUBMIT_CATEGORIES_MAPPING_SUCCESS',
  'SUBMIT_CATEGORIES_MAPPING_ERROR',
  'CHANGE_CATEGORIES_MAPPING',
  'RESET_CATEGORIES_MAPPING',
  'GET_MODELLER_PERMISSIONS',
  'SET_MODELLER_EMPLOYEE_DIMENSIONS_VALUES',
  'RESET_MODELLER_EMPLOYEE_CORRELATED_DIMENSIONS',
  'LOAD_ALL_DIMENSIONS_OPTIONS',
  'LOAD_DIMENSIONS_BY_DEFAULT_PENDING',
  'LOAD_DIMENSIONS_BY_DEFAULT_SUCCESS',
  'LOAD_DIMENSIONS_BY_DEFAULT_ERROR',
  'RE_INITIALIZE_FIELDS',
  'LOAD_DATA_PENDING',
  'LOAD_DATA_ERROR',
  'ADD_COMPARE',
  'REMOVE_COMPARE',
];

const actionTypes = createSuffixedActions(actionTypesNames, actionSuffix);

export default actionTypes;
