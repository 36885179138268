import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Icon } from '@trs/components';
import { toLower } from '@trs/utils';
import EllipsisTooltip from 'modules/common/EllipsisTooltip';
import { ICONS, ICON_SIZE, getItemId, isDirectory } from '../helpers';
import StyledItemName from './index.style';

const DisplayName = ({ item, withTooltip }) => {
  const { displayName, itemType } = item;
  const icon = <Icon icon={ICONS[toLower(itemType)]} size={ICON_SIZE} />;

  return (
    <StyledItemName
      isDirectory={isDirectory(itemType)}
      withTooltip={withTooltip}
      data-testid="display-name-wrapper"
    >
      {isDirectory(itemType) ? (
        <Link to={`/workspace/${getItemId(item)}`}>
          {icon}
          <span>{displayName}</span>
        </Link>
      ) : (
        <>
          {icon}
          {withTooltip ? (
            <EllipsisTooltip
              className="display-name"
              content={displayName}
              dataTip={displayName}
              dataFor={displayName}
            />
          ) : (
            <span>{displayName}</span>
          )}
        </>
      )}
    </StyledItemName>
  );
};

DisplayName.defaultProps = {
  withTooltip: false,
};

DisplayName.propTypes = {
  item: PropTypes.shape({
    itemType: PropTypes.string,
    displayName: PropTypes.string,
  }).isRequired,
  withTooltip: PropTypes.bool,
};

export default DisplayName;
