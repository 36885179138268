"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.transformUrl = exports.isValidURL = exports.isNotAllowedUrl = exports.defaultMediaProps = exports.RECOMMENDED_SIZE = exports.RECOMMENDED_MOBILE_SIZE = exports.MOBILE_TEXT_LENGTH = exports.MEDIA_COMPONENTS = exports.MAX_TEXT_LENGTH = void 0;
var _config = require("@trs/config");
var _RECOMMENDED_SIZE;
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
var defaultMediaProps = {
  type: _config.MEDIA_TYPE.IMAGE,
  src: '',
  alt: ''
};
exports.defaultMediaProps = defaultMediaProps;
var isNotAllowedUrl = function isNotAllowedUrl(string) {
  var unsafeLinksList = /<iframe.*|<script.*|<html.*/;
  return unsafeLinksList.test(string);
};
exports.isNotAllowedUrl = isNotAllowedUrl;
var isValidURL = function isValidURL(string) {
  var res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g);
  return res !== null;
};
exports.isValidURL = isValidURL;
var MEDIA_COMPONENTS = {
  BANNER: 'Banner',
  MEDIA: 'Media'
};
exports.MEDIA_COMPONENTS = MEDIA_COMPONENTS;
var RECOMMENDED_SIZE = (_RECOMMENDED_SIZE = {}, _defineProperty(_RECOMMENDED_SIZE, MEDIA_COMPONENTS.BANNER, {
  width: 930,
  height: 300
}), _defineProperty(_RECOMMENDED_SIZE, MEDIA_COMPONENTS.MEDIA, {
  width: 400,
  height: 270
}), _RECOMMENDED_SIZE);
exports.RECOMMENDED_SIZE = RECOMMENDED_SIZE;
var RECOMMENDED_MOBILE_SIZE = _defineProperty({}, MEDIA_COMPONENTS.BANNER, {
  width: '100%',
  height: '100%'
});
exports.RECOMMENDED_MOBILE_SIZE = RECOMMENDED_MOBILE_SIZE;
var MAX_TEXT_LENGTH = {
  URL: 500,
  ALT: 80,
  TEXT: 2500
};
exports.MAX_TEXT_LENGTH = MAX_TEXT_LENGTH;
var youTubeGetID = function youTubeGetID(url) {
  var id = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  return id[2] !== undefined ? id[2].split(/[^0-9a-z_-]/i)[0] : id[0];
};
var transformUrl = function transformUrl() {
  var url = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  if (url.includes('youtube')) {
    var id = youTubeGetID(url);
    return "https://www.youtube.com/embed/".concat(id);
  }
  if (url.includes('vimeo')) {
    return url.replace('vimeo.com/', 'player.vimeo.com/video/');
  }
  return url;
};
exports.transformUrl = transformUrl;
var MOBILE_TEXT_LENGTH = 300;
exports.MOBILE_TEXT_LENGTH = MOBILE_TEXT_LENGTH;