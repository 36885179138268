export const fixedCostsHeaderLabels = {
  PAGE_GROUP: 'Fixed cost',
  PAGE_TITLE: 'Fixed Costs',
  ADD_FIXED_COSTS_PAGE_TITLE: 'New Fixed Cost',
  NEW_BUTTON: 'Create Fixed Cost',
};

export const fixedCostsListLabels = {
  NO_FIXED_COSTS_AVAILABLE: `There aren't any Fixed Costs in the system. You can start by adding a Fixed Cost.`,
  COUNTRY: 'Country',
  DISPLAY_NAME: 'Name',
  STATUS_LABEL: 'Status',
  START_DATE: 'Start Date',
  NEVER_LABEL: 'Never',
  MENU_LIST_EDIT: 'Edit',
  VIEW_BUTTON: 'View',
};

export const fixedCostsStatusLabels = {
  DISPLAY_NAME: 'Name',
  STATUS: 'Status',
  START_DATE: 'Start Date',
  END_DATE: 'End Date',
  CURRENCY: 'Currency',
  REVERT_TO_DRAFT: 'Revert to draft',
};

export const fixedCostsBannerLabels = {
  BANNER_REVERT_TO_DRAFT_SUCCESS:
    'Fixed cost successfully moved to draft. Please wait until the operation completes.',
};

export const fixedCostsModal = {
  DIALOG_REVERT_TITLE: 'Are you sure you want to revert?',
  DIALOG_REVERT_SUBTITLE: `If you select "Revert," all your changes will be lost. If you select Cancel, your changes are kept but they are not saved.`,
  DIALOG_PUBLISH_TITLE: 'Publish Fixed Cost?',
  DIALOG_PUBLISH_SUBTITLE:
    'You are about to publish this Fixed Cost to the system.The Fixed Cost ' +
    'will be calculated on its effective date (%Effective Date%) and then will ' +
    'automatically become LIVE.',
  DIALOG_PUBLISH_EXPIRED_SUBTITLE:
    'You are about to publish this Fixed Cost to the system with the expired status.',
  DIALOG_LIVE_TITLE: 'Live Fixed Cost',
  DIALOG_LIVE_EDIT_TITLE: 'Are you sure you want to edit a live Fixed Cost?',
  DIALOG_LIVE_EDIT_SUBTITLE:
    'These changes be visible in reports. If you select Cancel your changes will be lost',
  DIALOG_LIVE_TO_PUBLISH_SUBTITLE:
    'Editing this live Fixed Cost will change its status to published and will affect its ' +
    'visibility in reports',
  DIALOG_TO_EXPIRED_TITLE: 'Expired Fixed Cost',
  DIALOG_TO_EXPIRED_SUBTITLE: "The changes you've made will set this Fixed Cost status to expired.",
  DIALOG_REVERT_TO_DRAFT_TITLE: 'Are you sure you want to Revert to draft?',
  DIALOG_REVERT_TO_DRAFT_SUBTITLE:
    'These changes will have an immediate effect on the Fixed Cost. %LOSE_CHANGES%',
  DIALOG_REVERT_TO_DRAFT_LOSE_CHANGES: 'You will lose any unsaved changes when reverting to draft.',
};

export const fixedCostsBasicInfoLabels = {
  TAB_BASIC_INFO: 'Basic Information',
  BASIC_INFO_GENERAL_CARD_TITLE: 'General',
  BASIC_INFO_CATEGORIZATION_CARD_TITLE: 'Categorization',
  BASIC_INFO_DESCRIPTION_TITLE: 'Description',
  BASIC_INFO_VALIDITY_DATES_CARD_TITLE: 'Validity dates',
  BASIC_INFO_NAME_LABEL: 'Name',
  BASIC_INFO_NAME_PLACEHOLDER: 'Name of the fixed cost',
  BASIC_INFO_COUNTRY_LABEL: 'Country',
  BASIC_INFO_COUNTRY_PLACEHOLDER: 'Select one or multiple Countries',
  BASIC_INFO_CURRENCY_LABEL: 'Currency',
  BASIC_INFO_FX_SOURCES_LABEL: 'FX Source',
  BASIC_INFO_FX_SOURCES_PLACEHOLDER: 'Select FX Source',
  BASIC_INFO_CATEGORY_LABEL: 'Category',
  BASIC_INFO_CATEGORY_PLACEHOLDER: 'Select a Category',
  BASIC_INFO_SUBCATEGORY_LABEL: 'Subcategory',
  BASIC_INFO_SUBCATEGORY_PLACEHOLDER: 'Select a Subcategory',
  BASIC_INFO_TYPE_LABEL: 'Type',
  BASIC_INFO_TYPE_PLACEHOLDER: 'Select a Type',
  BASIC_INFO_DESCRIPTION_TEXT_LABEL: 'Description',
  BASIC_INFO_DESCRIPTION_TEXT_PLACEHOLDER: 'Type your description here',
  BASIC_INFO_VALIDITY_START_DATE_LABEL: 'Start Date',
  BASIC_INFO_VALIDITY_START_DATE_BASEMENT_CAPTION:
    'Will become effective at the selected date, 00:00 [UTC]',
  BASIC_INFO_VALIDITY_END_DATE_LABEL: 'End Date',
  BASIC_INFO_VALIDITY_END_DATE_BASEMENT_CAPTION: 'Will expire at the selected date, 23:59 [UTC]',
  BASIC_INFO_VALIDITY_RUN_COSTS_LABEL: 'Run This Cost',
  BASIC_INFO_VALIDITY_RUN_COSTS_AUTOMATIC_LABEL: 'Automatically',
  BASIC_INFO_VALIDITY_RUN_COSTS_EVERY_LABEL: 'Every',
  BASIC_INFO_VALIDITY_RUN_COSTS_EVERY_NO_LABEL: 'No',
  BASIC_INFO_VALIDITY_RUN_COSTS_EVERY_FREQUENCY_LABEL: 'Frequency',
  BASIC_INFO_VALIDITY_RECURRENCE_ERROR: 'Required',
  BASIC_INFO_VALIDITY_RECURRENCE_MONTHS_LABEL: 'Month(s)',
  BASIC_INFO_VALIDITY_RECURRENCE_YEARS_LABEL: 'Year(s)',
};

export const fixedCostsRulesBuilderLabels = {
  TAB_RULES_BUILDER: 'Rules Logic Builder',
  RULES_BUILDER_RULE_LABEL: 'Global Rule',
  RULES_BUILDER_RULE_SUBTITLE: 'Cost',
  RULES_BUILDER_EMPTY_FORMULA_ERROR_LABEL: 'Make sure you have at least one value or function.',
  RULES_BUILDER_VARIABLE_MISSING: 'Expression is required.',
  RULES_BUILDER_OPERATOR_MISSING: 'Operator is required.',
  FORMULA_VALUE_PLACEHOLDER: 'Start by inserting a %value%.',
  FORMULA_INPUT_PLACEHOLDER: 'Type variable or number',
  DISTRIBUTION_AUTOMATIC_TITLE: 'Automatic distribution',
  DISTRIBUTION_AUTOMATIC_SUBTITLE:
    'Automatically distribute fixed costs equally among the selected countries.',
  DISTRIBUTION_MANUAL_TITLE: 'Manual distribution',
  DISTRIBUTION_MANUAL_SUBTITLE: 'Manually manage fixed cost data across selected countries.',
  DISTRIBUTION_TABLE_COLUMN_COUNTRY: 'Country',
  DISTRIBUTION_TABLE_COLUMN_DISTRIBUTION: '% Distribution',
  DISTRIBUTION_TABLE_NO_RESULTS: 'No results found.',
  DISTRIBUTION_TABLE_TOTAL: 'Total',
};

export const fixedCostsErrors = {
  FIELD_IS_REQUIRED: 'This field is required',
  VALUE_IS_NEGATIVE: 'Negative values are no supported',
  SUM_NOT_100: 'Please make sure the distribution percentage equals 100%.',
  RULES_BUILDER_RULE_ALREADY_EXISTS: 'Resource already exists',
  RULES_BUILDER_RULE_INVALID_TYPE: 'Invalid type.',
  RULES_BUILDER_INVALID_FORMULA_ERROR_LABEL: 'Please check the syntax of your formula.',
};

export default {
  ...fixedCostsHeaderLabels,
  ...fixedCostsListLabels,
  ...fixedCostsStatusLabels,
  ...fixedCostsBannerLabels,
  ...fixedCostsModal,
  ...fixedCostsBasicInfoLabels,
  ...fixedCostsRulesBuilderLabels,
  ...fixedCostsErrors,
};
