import { combineReducers } from 'redux';

import dashboard from './dashboard';
import countries from '../components/countries/reducer';
import currency from '../components/currency/reducer';
import hr from '../components/hr/reducer';
import mobility from '../components/mobility/reducer';
import shares, { sharesProviders } from '../components/shares/reducer';
import validation from '../components/validation/reducer';
import customisation from './customisation';
import rewardsCategories from '../components/rewards/reducer';

export default combineReducers({
  dashboard,
  countries,
  currency,
  hr,
  mobility,
  shares,
  sharesProviders,
  validation,
  customisation,
  rewardsCategories,
});
