"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setHours = exports.getTime = exports.checkTimeFormat = exports.TIME_LABEL = exports.ROW_HEIGHT = exports.MERIDIEM_TYPE = exports.LIST_HEIGHT = void 0;
var R = _interopRequireWildcard(require("ramda"));
var _utils = require("@trs/utils");
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var TIME_RGX = /^(0[0-9]|1[0-9]|2[0-3]|[0-9]):[0-5][0-9]$/;
var NR_ROWS = 8;
var MINUTES = ['00', '15', '30', '45', '59'];
var TIME_LABEL = 'Time';
exports.TIME_LABEL = TIME_LABEL;
var MERIDIEM_TYPE = ['AM', 'PM'];
exports.MERIDIEM_TYPE = MERIDIEM_TYPE;
var ROW_HEIGHT = 30;
exports.ROW_HEIGHT = ROW_HEIGHT;
var LIST_HEIGHT = NR_ROWS * ROW_HEIGHT;
exports.LIST_HEIGHT = LIST_HEIGHT;
var setTimeDigits = function setTimeDigits(value) {
  var isMeridiem = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  return !isMeridiem && (0, _utils.formatTimeDigit)(value);
};
var setMeridiemHour = function setMeridiemHour(value) {
  var hour = value % 12;
  return hour === 0 ? 12 : hour;
};
var getMeridiem = function getMeridiem(date) {
  return date.toLocaleTimeString().slice(-2);
};
var fillRange = function fillRange(_ref) {
  var start = _ref.start,
    end = _ref.end;
  return R.range(start, end);
};
var constructHour = function constructHour(hour, isMeridiem) {
  return R.map(function (minute) {
    return "".concat(setTimeDigits(hour, isMeridiem), ":").concat(minute);
  }, MINUTES);
};
var setHours = function setHours() {
  var isMeridiem = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  var range = {
    start: isMeridiem ? 1 : 0,
    end: isMeridiem ? 13 : 24
  };
  var arr = R.map(function (hour) {
    return constructHour(hour, isMeridiem);
  }, fillRange(range));
  return arr.concat.apply([], arr);
};
exports.setHours = setHours;
var getTime = function getTime(date, isMeridiem) {
  if ((0, _utils.isBlank)(date)) return null;
  var h = isMeridiem ? setMeridiemHour(date.getHours()) : setTimeDigits(date.getHours());
  var m = setTimeDigits(date.getMinutes());
  return "".concat(h, ":").concat(m, " ").concat(isMeridiem ? getMeridiem(date) : '').trim();
};
exports.getTime = getTime;
var checkTimeFormat = function checkTimeFormat(_ref2) {
  var value = _ref2.value,
    hours = _ref2.hours,
    isMeridiem = _ref2.isMeridiem;
  if (TIME_RGX.test(value.split(' ')[0])) {
    return value;
  }
  return value ? "".concat(R.head(hours), " ").concat(isMeridiem ? MERIDIEM_TYPE[0] : '').trim() : '';
};
exports.checkTimeFormat = checkTimeFormat;