import * as R from 'ramda';
import Either from 'data.either';
import { isBlank, lowerFirstLetter } from '@trs/utils';
import * as http from '../../../../config/http';

const { Right, Left } = Either;

export const defaults = { errors: {}, isDirty: false, isSaving: false };

// this needs to be documented in depth

const makePredicate =
  ([predFn, e]) =>
  (a, form) =>
    predFn(a, form) ? Left({ e, details: predFn(a, form) }) : Right(a);

const makePredicates = R.map(makePredicate);

const runPredicates =
  (form) =>
  ([input, validations]) =>
    R.map((predFn) => predFn(input, form), makePredicates(validations));

const getServerErrors = (error) => {
  const serverErrors = {};
  const responseErrors = R.path(['response', 'data', 'errors'], error) || R.prop('message', error);
  if (!isBlank(responseErrors)) {
    R.map((item) => {
      if (item.field) serverErrors[lowerFirstLetter(item.field)] = item.message;
      return false;
    }, responseErrors);
  }
  if (isBlank(serverErrors)) {
    serverErrors.errorBody = error;
  }
  return serverErrors;
};

const validate = (form) => R.map(R.compose(R.sequence(Either.of), runPredicates(form)));

const makeValidationObject = R.mergeWithKey((k, l, r) => [l, r]);

export const getErrors = (form) => R.compose(validate(form), makeValidationObject);

const ErrorHandler = ({ e, details }) => (R.is(Object, details) ? details : e);

export const ErrorComponent = (result) =>
  result.cata({
    Right: () => null,
    Left: ErrorHandler,
  });

export const makeHttpCall = ({ updateState, callback, syncToReducer, payloadData }) =>
  http[payloadData.method](payloadData.endpoint, payloadData.payload, payloadData.options)
    .then((response) => {
      updateState((finalState) => {
        if (syncToReducer) {
          syncToReducer({
            ...R.prop('form', finalState),
            etag: R.path(['headers', 'etag'], response) || R.path(['form', 'etag'], finalState),
            id: R.path(['data', 'response', 'id'], response) || R.path(['form', 'id'], finalState),
          });
        }
        return {
          ...R.assoc(
            'form',
            {
              ...R.prop('form', finalState),
              etag: R.path(['headers', 'etag'], response) || R.path(['form', 'etag'], finalState),
              id:
                R.path(['data', 'response', 'id'], response) || R.path(['form', 'id'], finalState),
            },
            finalState
          ),
          ...defaults,
        };
      });
      callback(null, response);
    })
    .catch((error) => {
      const serverErrors = getServerErrors(error);
      updateState((finalState) => ({
        ...finalState,
        isSaving: false,
        errors: serverErrors,
      }));
      callback(serverErrors, null);
    });

export const runValidations = ({
  name,
  value,
  nestedState = { form: {} },
  validationRules,
  handler = 'onSave',
  shouldDirty,
}) => {
  const form = R.prop('form', nestedState);
  const filteredValidations = R.map(R.reject(R.contains(handler)), validationRules);

  if (!name) {
    if (isBlank(nestedState)) {
      return { isDirty: true };
    }
    // only validate what's in the validation rules as keys
    const formSlice = R.pickAll(R.keys(filteredValidations), form);
    return R.assoc(
      'errors',
      {
        // run the validations against the form slice
        ...R.map(ErrorComponent, getErrors(form)(formSlice, filteredValidations)),
      },
      nestedState
    );
  }
  // send the field change to the form state
  const newState = R.assocPath(['form', name], value, nestedState);
  if (shouldDirty) newState.isDirty = true;
  const fieldValidations = R.pick([name], filteredValidations);
  if (isBlank(fieldValidations)) {
    return newState;
  }
  const errors = R.map(
    ErrorComponent,
    getErrors(form)(
      // we don't want this to run over all fields, so on change get only those that matter
      R.pick([name], R.prop('form', newState)),
      fieldValidations
    )
  );
  return R.assoc('errors', { ...R.prop('errors', nestedState), ...errors }, newState);
};
