import { handleActions } from 'redux-actions';
import {
  getAuditData,
  setAuditFilter,
  changeTablePage,
  filterAuditData,
  generateLinesDataFile,
  resetStore,
} from '../actions';
import initialState from './initialState';
import { buildFilter, mapResponseToModel } from './helpers';

const reducer = handleActions(
  new Map([
    [
      getAuditData,
      (state, action) => {
        const { ready, payload, error } = action;

        if (!ready) {
          return {
            ...state,
            isLoading: true,
          };
        }

        if (error) {
          return {
            ...state,
            isLoading: false,
          };
        }

        const data = mapResponseToModel(payload.response, payload.params);

        return {
          ...state,
          ...data,
          isLoading: false,
          filter: {
            ...state.filter,
            ...data.filter,
          },
        };
      },
    ],
    [
      setAuditFilter,
      (state, action) => {
        const {
          payload: { tableFilter, page },
        } = action;
        return {
          ...state,
          filter: {
            ...state.filter,
            ...buildFilter(tableFilter),
            page,
          },
        };
      },
    ],
    [
      changeTablePage,
      (state, action) => {
        const {
          payload: { page },
        } = action;
        return {
          ...state,
          filter: {
            ...state.filter,
            page,
          },
        };
      },
    ],
    [
      filterAuditData,
      (state, action) => {
        const { ready, payload, error } = action;

        if (!ready) {
          return {
            ...state,
            isTableLoading: true,
          };
        }

        if (error) {
          return {
            ...state,
            isTableLoading: false,
          };
        }

        const data = mapResponseToModel(payload.response, payload.params);

        return {
          ...state,
          ...data,
          isTableLoading: false,
          filter: {
            ...state.filter,
            ...data.filter,
          },
        };
      },
    ],
    [
      generateLinesDataFile,
      (state, action) => {
        const { ready } = action;

        if (!ready) {
          return {
            ...state,
            isDownloadResultsLoading: true,
          };
        }

        return {
          ...state,
          isDownloadResultsLoading: false,
        };
      },
    ],
    [resetStore, () => initialState],
  ]),
  initialState
);

export default reducer;
