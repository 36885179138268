/* eslint-disable no-param-reassign */
import * as R from 'ramda';
import { hasSpecialCharacters, isBlank } from '@trs/utils';
import { toBinary } from '@trs/utils/lib/strings';

/** maximum depth of nested categories */
const MAX_DEPTH = 3;

export const levelCategories = (categories, defaultIndex = 0) =>
  categories.map((category) => {
    if (defaultIndex < MAX_DEPTH) {
      return {
        ...category,
        level: defaultIndex,
        editMode: false,
        originalDisplayName: R.prop('displayName', category),
        categories: levelCategories(category.categories, defaultIndex + 1),
      };
    }
    return true;
  });

export const encodeNamesInCategories = (categories) => {
  if (isBlank(categories)) {
    return categories;
  }

  let newCategories = [...categories];
  newCategories = categories.map((category) => {
    if (category.categories) {
      category.categories = encodeNamesInCategories(category.categories);
    }

    if (category.displayName) {
      category.displayName = toBinary(category.displayName);
    }

    return category;
  });

  return newCategories;
};

export const NEW_CATEGORY_ID_PREFIX = 'NEW_CATEGORY_';

/** append a new empty category */
export const addNewCategory = (mainCategory) => ({
  ...mainCategory,
  categories: [
    ...mainCategory.categories,
    {
      categories: [],
      editMode: true,
      /** temporary ID */
      id: `${NEW_CATEGORY_ID_PREFIX}${Math.floor(Math.random() * 1000)}`,
      displayName: '',
      isUsed: '-',
      isDefault: false,
      level: mainCategory.level + 1,
    },
  ],
});

/**
 *
 * @param {string} categoryId
 * @param {string} key
 * @param {string} value
 * @param {array} categories
 * @returns array of categories
 * Search the category with the given id, and update it's key value
 */
export const updateCategoryValue = (categoryId, key, value, categories) =>
  categories.map((category) => {
    let categoryUpdated = category;

    if (category.id === categoryId) {
      categoryUpdated = {
        ...categoryUpdated,
        [key]: value,
      };
    } else {
      categoryUpdated = {
        ...categoryUpdated,
        categories: updateCategoryValue(categoryId, key, value, category.categories),
      };
    }

    return categoryUpdated;
  });

/**
 *
 * @param {string} categoryId
 * @param {categories} categories
 * @returns array of categories
 * Discard changes made on displayName (return it's value to original display name)
 */
export const discardChanges = (categoryId, categories) =>
  categories.map((category) => {
    let categoryUpdated = category;

    if (category.id === categoryId) {
      categoryUpdated.displayName = categoryUpdated.originalDisplayName;
      categoryUpdated.editMode = false;
      categoryUpdated.error = null;
    } else {
      categoryUpdated = {
        ...categoryUpdated,
        categories: discardChanges(categoryId, category.categories),
      };
    }

    return categoryUpdated;
  });

/**
 *
 * @param {string} categoryId
 * @param {array} categories
 * @returns array of categories
 * search the category with the given id and add a new category
 */
export const appendNewCategory = (categoryId, categories) => {
  let categoryFound = false;

  /** search in the categories with level 0 */
  return categories.map((level0Category) => {
    let mainCategory = level0Category;

    if (!categoryFound && R.equals(R.prop('id', level0Category), categoryId)) {
      categoryFound = true;
      mainCategory = addNewCategory(level0Category);
    }

    if (!categoryFound) {
      /** search in the categories with level 1 */
      mainCategory.categories = level0Category.categories.map((level1Category) => {
        if (R.equals(R.prop('id', level1Category), categoryId)) {
          categoryFound = true;
          return addNewCategory(level1Category);
        }

        return level1Category;
      });
    }

    return mainCategory;
  });
};

/**
 *
 * @param {string} categoryId
 * @param {array} categories
 * @returns array of categories
 */
export const deleteCategory = (categoryId, categories) => {
  if (!R.isNil(R.find(R.propEq('id', categoryId))(categories))) {
    return categories.filter((level0Category) => level0Category.id !== categoryId);
  }

  let categoryFound = false;

  return categories.map((level0Category) => {
    categoryFound = !R.isNil(R.find(R.propEq('id', categoryId))(level0Category.categories));
    let categoriesUpdated = level0Category.categories;

    if (categoryFound) {
      categoriesUpdated = categoriesUpdated.filter(
        (level1Category) => level1Category.id !== categoryId
      );
    } else {
      categoriesUpdated = categoriesUpdated.map((level1Category) => {
        categoryFound = !R.isNil(R.find(R.propEq('id', categoryId))(level1Category.categories));
        let l1CategoriesUpdated = level1Category.categories;

        if (categoryFound) {
          l1CategoriesUpdated = l1CategoriesUpdated.filter(
            (level2Category) => level2Category.id !== categoryId
          );
        }

        return {
          ...level1Category,
          categories: l1CategoriesUpdated,
        };
      });
    }

    return {
      ...level0Category,
      categories: categoriesUpdated,
    };
  });
};

/**
 *
 * @param {string} categoryId
 * @param {array} categories
 * @returns array of strings
 * Returns the siblings categories of a category by id
 */
export const getSiblings = (categoryId, categories) => {
  let categoryFound = R.find(R.propEq('id', categoryId))(categories);

  if (categoryFound) {
    return categories;
  }

  let siblings = [];

  categories.map((level0Category) => {
    categoryFound = R.find(R.propEq('id', categoryId))(level0Category.categories);
    if (categoryFound) {
      siblings = level0Category.categories;
      return level0Category.categories;
    }
    return level0Category.categories.map((level1Category) => {
      categoryFound = R.find(R.propEq('id', categoryId))(level1Category.categories);
      if (categoryFound) {
        siblings = level1Category.categories;
        return level1Category.categories;
      }
      return level1Category.categories.map((level2Category) => {
        categoryFound = R.find(R.propEq('id', categoryId))(level2Category.categories);
        if (categoryFound) {
          siblings = level2Category.categories;
          return level2Category.categories;
        }
        return null;
      });
    });
  });

  return siblings;
};

export const getSiblingsCategories = (categoryId, categories) => {
  const siblings = getSiblings(categoryId, categories);
  return siblings.map((category) => category.displayName.trim());
};

/**
 * Validations:
 * 1. cannot be empty
 * 2. should not contain special characters
 * 3. should have max 100 characters
 * 4. should be unique within the same category
 */
const isEmptyErr = (value) => {
  return R.isEmpty(R.trim(value)) ? 'Name cannot be empty.' : null;
};

const isValidLength = (value) =>
  value.length > 100 ? 'This field should have a maximum of 100 characters.' : null;

const isUnique = (value, displayNames) => {
  const val = value.trim();
  return !R.equals(R.indexOf(val, displayNames), R.lastIndexOf(val, displayNames))
    ? 'Name must be unique.'
    : null;
};

export const getFieldError = (value, categories, skipEmptyCheck) => {
  const emptyErr = isEmptyErr(value);

  if (!skipEmptyCheck && emptyErr) {
    return emptyErr;
  }

  const hasSpecialChars = hasSpecialCharacters(value);
  if (hasSpecialChars) return 'Special symbols are forbidden.';

  const lengthErr = isValidLength(value);
  const uniqueErr = isUnique(value, categories);

  if (lengthErr && uniqueErr) {
    return `${lengthErr} ${uniqueErr}`;
  }

  if (lengthErr) return lengthErr;
  if (uniqueErr) return uniqueErr;

  return null;
};

/**
 *
 * @param {array} categories
 * @returns array
 * PUT request does not accept ids, name and originalDisplayName for the categories newly added
 */
export const cleanUpCategories = (categories) => {
  return categories.map((category) => {
    const cleanupCategory = R.omit(['name', 'originalDisplayName'], category);

    return {
      ...cleanupCategory,
      id:
        !R.isNil(cleanupCategory.id) && cleanupCategory.id.startsWith(NEW_CATEGORY_ID_PREFIX)
          ? null
          : cleanupCategory.id,
      categories: cleanUpCategories(cleanupCategory.categories),
    };
  });
};

export const updateOrderIndex = (categories) =>
  categories.map((category, index) => ({
    ...category,
    orderIndex: index,
  }));
