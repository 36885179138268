export const GET_REWARDS_FEEDBACK_SUCCESS = 'FEEDBACK_GET_REWARDS_FEEDBACK_SUCCESS';
export const GET_REWARDS_FEEDBACK_FAIL = 'FEEDBACK_GET_REWARDS_FEEDBACK_FAIL';
export const GET_REWARDS_FEEDBACK_PENDING = 'FEEDBACK_GET_REWARDS_FEEDBACK_PENDING';

export const SELECT_RATING = 'FEEDBACK_SELECT_RATING';
export const CHANGE_FEEDBACK_TEXT = 'FEEDBACK_CHANGE_TEXT';
export const RESET_FEEDBACK_PAGE = 'FEEDBACK_RESET_PAGE';

export const FEEDBACK_SUBMIT_REWARDS_PENDING = 'FEEDBACK_SUBMIT_REWARDS_PENDING';
export const FEEDBACK_SUBMIT_REWARDS_SUCCESS = 'FEEDBACK_SUBMIT_REWARDS_SUCCESS';
export const FEEDBACK_SUBMIT_REWARDS_ERROR = 'FEEDBACK_SUBMIT_REWARDS_ERROR';

export const GET_EMPLOYEE_TOTAL_SCORE_PENDING = 'GET_EMPLOYEE_TOTAL_SCORE_PENDING';
export const GET_EMPLOYEE_TOTAL_SCORE_SUCCESS = 'GET_EMPLOYEE_TOTAL_SCORE_SUCCESS';
export const GET_EMPLOYEE_TOTAL_SCORE_ERROR = 'GET_EMPLOYEE_TOTAL_SCORE_ERROR';

export const GET_EMPLOYEES_TOTAL_SCORE_PENDING = 'GET_EMPLOYEES_TOTAL_SCORE_PENDING';
export const GET_EMPLOYEES_TOTAL_SCORE_SUCCESS = 'GET_EMPLOYEES_TOTAL_SCORE_SUCCESS';
export const GET_EMPLOYEES_TOTAL_SCORE_ERROR = 'GET_EMPLOYEES_TOTAL_SCORE_ERROR';
