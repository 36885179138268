import * as R from 'ramda';
import { handleActions } from 'redux-actions';
import { decimalTransform } from '@trs/utils';
import { combineReducers } from 'redux';
import {
  getSalaryIncrease,
  cellChange,
  saveProposal,
  cancelEditable,
  validateDataForSubmit,
} from '../actions';
import { getSalaryIncreaseAverage } from '../actions/salaryIncrease';
import {
  FIELDS_INCLUDED_IN_TOTAL,
  computeTotal,
  mapPayloadToTableData,
  mapAverage,
} from './helpers';
import initialState from './initialState';
import merit from '../components/merit/reducer';

const proposal = handleActions(
  new Map([
    [
      getSalaryIncreaseAverage,
      (state, { payload }) => {
        return { ...state, weightedAverage: mapAverage(R.prop('data', payload)) };
      },
    ],
    [saveProposal.pending, (state) => ({ ...state, isSaving: true })],
    [
      saveProposal.success,
      (state, action) => ({
        ...state,
        isSaving: false,
        proposalId: R.path(['payload', 'id'], action),
        etag: R.path(['payload', 'Etag'], action),
        tableDataSnapshot: state.tableData,
      }),
    ],
    [
      validateDataForSubmit,
      (state, action) => ({
        ...state,
        isDataValid: action.payload,
      }),
    ],
    [saveProposal.error, (state) => ({ ...state, isSaving: false })],
    [
      getSalaryIncrease,
      (state, action) => {
        const {
          ready,
          error,
          payload: { data, etag },
        } = action;

        if (!ready) {
          return {
            ...state,
            isLoading: true,
          };
        }

        if (error) {
          return {
            ...state,
            isLoading: false,
            salaryIncreaseLoadError: true,
          };
        }

        const tableData = mapPayloadToTableData(R.prop('populationProposals', data));

        return {
          ...state,
          tableData,
          tableDataSnapshot: tableData,
          proposalId: R.prop('id', data),
          etag,
          isLoading: false,
          isDataValid: R.prop('isDataValid', data),
        };
      },
    ],
    [
      cancelEditable,
      (state) => ({
        ...state,
        tableData: state.tableDataSnapshot,
      }),
    ],
    [
      cellChange,
      (state, action) => {
        const { rowIndex, field, value } = action.payload;
        const { tableData } = state;
        let newTableData = R.assocPath([rowIndex, field], value, tableData);

        if (R.contains(field, FIELDS_INCLUDED_IN_TOTAL)) {
          newTableData = R.assocPath(
            [rowIndex, 'totalSalaryIncrease'],
            decimalTransform(computeTotal(newTableData[rowIndex])),
            newTableData
          );
        }

        return {
          ...state,
          tableData: newTableData,
        };
      },
    ],
  ]),
  R.clone(initialState)
);

export default combineReducers({
  proposal,
  merit,
});
