const actionTypes = {
  BENCHMARKING_GET_SALARY_INCREASE_DATA: 'BENCHMARKING/GET_SALARY_INCREASE_DATA',
  BENCHMARKING_SALARY_INCREASE_CELL_CHANGE: 'BENCHMARKING/SALARY_INCREASE_CELL_CHANGE',
  BENCHMARKING_SALARY_INCREASE_CANCEL_EDITABLE: 'BENCHMARKING/SALARY_INCREASE_CANCEL_EDITABLE',
  BENCHMARKING_SAVE_PROPOSAL_PENDING: 'BENCHMARKING/SAVE_PROPOSAL/PENDING',
  BENCHMARKING_SAVE_PROPOSAL_SUCCESS: 'BENCHMARKING/SAVE_PROPOSAL/SUCCESS',
  BENCHMARKING_SAVE_PROPOSAL_ERROR: 'BENCHMARKING/SAVE_PROPOSAL/ERROR',
  BENCHMARKING_GET_PREMERIT: 'BENCHMARKING/LOAD_PREMERIT',
  BENCHMARKING_GET_HEATMAP_THRESHOLDS: 'BENCHMARKING/GET_HEATMAP_THRESHOLDS',
  BENCHMARKING_SALARY_DATA_VALID: 'BENCHMARKING_SALARY_DATA_VALID',
  BENCHMARKING_SALARY_INCREASE_AVERAGE: 'BENCHMARKING/SALARY_INCREASE/AVERAGE',
};

export default actionTypes;
