"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var StyledPreviewChip = _styledComponents["default"].span.withConfig({
  displayName: "PreviewChipstyle__StyledPreviewChip",
  componentId: "sc-84i72w-0"
})(["", ""], function (props) {
  var _props$theme = props.theme,
    color = _props$theme.palette,
    typography = _props$theme.typography,
    spacing = _props$theme.spacing;
  var light = props.light,
    label = props.label;
  var chipColor = light ? color.gray.bravo : color.gray.alpha;
  return (0, _styledComponents.css)(["display:inline-block;padding:", " ", ";margin-right:", ";font-family:", ";vertical-align:middle;", " ", ""], spacing.tiny, spacing.base, spacing.basePlus, typography.font.primary, !label && (0, _styledComponents.css)(["font-size:", ";line-height:", ";border-radius:20px;border:1px solid ", ";background-color:", ";min-height:42px;"], typography.size.paragraph, typography.lineHeight.paragraph, chipColor, color.gray.delta), label && (0, _styledComponents.css)(["color:", ";font-size:", ";line-height:", ";font-weight:700;padding-left:0px;margin-right:", ";"], color.gray.bravo, typography.size.paragraph, typography.lineHeight["default"], spacing.basePlus));
});
StyledPreviewChip.displayName = 'StyledPreviewChip';
var _default = StyledPreviewChip;
exports["default"] = _default;