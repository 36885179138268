"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
var _color = _interopRequireDefault(require("color"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var computeColor = function computeColor(isExpanded, disabled, palette) {
  if (disabled) {
    return palette.gray.bravo;
  }
  if (isExpanded) {
    return palette.primary;
  }
  return 'inherit';
};
var StyledTableFilter = _styledComponents["default"].div.withConfig({
  displayName: "TableFilterstyle__StyledTableFilter",
  componentId: "sc-1prpmmu-0"
})(["", ";"], function (props) {
  var _props$theme = props.theme,
    palette = _props$theme.palette,
    spacing = _props$theme.spacing,
    typography = _props$theme.typography,
    _props$theme$extra = _props$theme.extra,
    boxShadow = _props$theme$extra.boxShadow,
    genericBorderRadius = _props$theme$extra.genericBorderRadius;
  var isExpanded = props.isExpanded,
    disabled = props.disabled;
  return (0, _styledComponents.css)(["&.filter-wrapper{background-color:", ";border-right:1px solid ", ";border-radius:", " 0 0 ", ";", " color:", ";flex:0 1 ", ";position:relative;z-index:1;@media all and (-ms-high-contrast:none),(-ms-high-contrast:active){padding-bottom:80px;}@supports (-ms-ime-align:auto){padding-bottom:80px}.filter-header{font-weight:", ";height:", ";line-height:", ";padding-left:", ";position:relative;text-transform:uppercase;", " .filter-icon{cursor:", ";font-size:", ";line-height:", ";position:absolute;right:0;text-align:center;width:", ";&::before{margin-left:6px;}&:focus{outline:0;}}}.filter-content{height:100%;max-height:458px;padding:", ";overflow-y:auto;}.filter-footer{bottom:0;padding:", ";position:absolute;width:100%;}.FilterCollapseBody{li{align-items:center;}}}"], palette.basic.white, isExpanded ? palette.accent.charlie : palette.gray.delta, genericBorderRadius, genericBorderRadius, isExpanded && (0, _styledComponents.css)(["box-shadow:", ";"], boxShadow), computeColor(isExpanded, disabled, palette), isExpanded ? '284px' : spacing.large, typography.weight.thick, spacing.large, spacing.large, spacing.small, isExpanded && (0, _styledComponents.css)(["border-bottom:solid 1px ", ";"], (0, _color["default"])(palette.accent.alpha).lighten(0.16).hex()), disabled ? 'not-allowed' : 'pointer', typography.size.h4, spacing.large, spacing.large, spacing.xSmall, spacing.small);
});
StyledTableFilter.displayName = 'StyledTableFilter';
var _default = StyledTableFilter;
exports["default"] = _default;