import basicInfo from './basicInfo';
import budgetBuilder from './budgetBuilder';
import dashboard from './dashboard';
import rewardSelection from './rewardSelection';
import sideBySide from './sideBySide';
import enrollmentWindow from './enrollmentWindow';
import emailAndDisclaimer from './emailAndDisclaimer';

export default {
  PAGE_TITLE: 'Flexible Packages',
  ACTIVATE: 'Create Package',
  FLEX_SETUP: 'Flexible Packages Setup',
  BASIC_INFO_LABEL: 'Basic Information',
  BUDGET_BUILDER: 'Budget Builder',
  REWARDS_SELECTION: 'Rewards Selection',
  ELIGIBLE_POPULATION: 'Eligible Population',
  DISCLAIMER: 'Disclaimer',
  AGREEMENT: 'Agreement text',
  EMAIL_AND_DISCLAIMER: 'Email & Disclaimer',
  DASHBOARD_SUBTITLE:
    'This section allows you to create flexible packages that will allow employees to view their statement at a future date.',
  CLEAR_SELECTED: 'Clear selections',
  EXPORT_SUBMISSION_DATE: 'Submission Date: %DATE%',
  EXPORT_STATEMENT_DATE: 'Model Date: %DATE%',
  STATUS_DRAFT: 'Draft',
  STATUS_PUBLISHED: 'Published',
  STATUS_LIVE: 'Live',
  STATUS_EXPIRED: 'Expired',
  TAB_CHANGE_DIALOG_TITLE: 'Are you sure you want to leave this page?',
  TAB_CHANGE_DIALOG_MESSAGFE:
    'Your changes have not been saved. If you leave this page all the unsaved information will be lost.',
  ID_IS_REQUIRED: 'Reward id is required',
  FIELD_CANNOT_BE_EMPTY: 'Field cannot be empty',
  AT_LEAST_ONE_REWARD_NEEDED_FOR_SUBMISSION_FLEX_PACKAGE_TYPE:
    'At least one reward needs to be selected for package of type Submission',
  REWARDS_CANNOT_BE_INCLUDED_IN_BUDGET_WHEN_NO_BUDGET_EXISTS:
    'Rewards cannot be included in budget when no budget exists',
  MANDATORY_TABS_NEEDED_FOR_FLEX_PACKAGE_PUBLISH:
    'Mandatory tabs must be filled in for publishing a flex package',
  PUBLISH_PACKAGE_TITLE: 'Publish package "%TITLE%"?',
  OPTIONAL_DATA_FIELDS_LEFT_BLANK:
    'Note that the following optional data fields have been left blank:',
  GLOBAL_PACKAGES: 'Global packages',
  NATIONAL_PACKAGES: 'Country packages',
  GLOBAL: 'Global',
  ...basicInfo,
  ...budgetBuilder,
  ...dashboard,
  ...rewardSelection,
  ...sideBySide,
  ...enrollmentWindow,
  ...emailAndDisclaimer,
};
