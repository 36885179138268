import * as R from 'ramda';
import { createAction } from 'redux-actions';
import { isBlank } from '@trs/utils';
import { GET } from 'config/http';
import { rewards } from 'config/api';
import { withCache } from 'modules/common/timedCache';
import { dispatchError } from 'modules/common/helpers';
import { validateEligibilitySimplePopulation, validatePopulation } from './helpers';

const ROOT_TYPE = 'USER_MANAGEMENT';

export const actionTypes = {
  GET_ELIGIBILITY_OPERATORS: `${ROOT_TYPE}/GET_ELIGIBILITY_OPERATORS`,
  GET_SYSTEM_VARIABLES: `${ROOT_TYPE}/GET_SYSTEM_VARIABLES`,
  GET_SYSTEM_VARIABLES_LOOKUP_DATA: `${ROOT_TYPE}/GET_SYSTEM_VARIABLES_LOOKUP_DATA`,
  LOAD_ELIGIBILITY_PENDING: `${ROOT_TYPE}/LOAD_ELIGIBILITY_PENDING`,
  LOAD_ELIGIBILITY_SUCCESS: `${ROOT_TYPE}/LOAD_ELIGIBILITY_SUCCESS`,
  LOAD_ELIGIBILITY_ERROR: `${ROOT_TYPE}/LOAD_ELIGIBILITY_ERROR`,
  REVERT_ELIGIBILITY: `${ROOT_TYPE}/REVERT_ELIGIBILITY`,
  POPULATION_CHANGE: `${ROOT_TYPE}/POPULATION_CHANGE`,
  POPULATION_ERROR: `${ROOT_TYPE}/POPULATION_ERROR`,
  GET_POPULATION_BY_ID: `${ROOT_TYPE}/GET_POPULATION_BY_ID`,
  UPDATE_POPULATION_ETAG: `${ROOT_TYPE}/UPDATE_POPULATION_ETAG`,
  STORE_RESET: `${ROOT_TYPE}/STORE_RESET`,
};

export const getEligibilityOperators = createAction(actionTypes.GET_ELIGIBILITY_OPERATORS);
export const getVariablesLookupData = createAction(actionTypes.GET_SYSTEM_VARIABLES_LOOKUP_DATA);
export const getSystemVariables = createAction(actionTypes.GET_SYSTEM_VARIABLES);
export const loadDependenciesPending = createAction(actionTypes.LOAD_ELIGIBILITY_PENDING);
export const loadDependenciesSuccess = createAction(actionTypes.LOAD_ELIGIBILITY_SUCCESS);
export const loadDependenciesError = createAction(actionTypes.LOAD_ELIGIBILITY_ERROR);
export const populationChange = createAction(actionTypes.POPULATION_CHANGE);
export const updatePopulationError = createAction(actionTypes.POPULATION_ERROR);
export const getPopulationById = createAction(actionTypes.GET_POPULATION_BY_ID);
export const updatePopulationEtag = createAction(actionTypes.UPDATE_POPULATION_ETAG);
export const storeReset = createAction(actionTypes.STORE_RESET);
export const revertEligibility = createAction(actionTypes.REVERT_ELIGIBILITY);

const cachedGet = withCache(GET, 3600);

export const loadEligibilityOperators = () => dispatch =>
  cachedGet(rewards.eligibilityOperators).then(response =>
    dispatch(getEligibilityOperators(R.prop('data', response)))
  );

export const loadSystemVariables = () => dispatch =>
  cachedGet(rewards.systemVariables, { Pragma: 'no-cache' }).then(response =>
    dispatch(getSystemVariables(R.prop('data', response)))
  );

export const loadSystemVariablesLookup = url => dispatch =>
  cachedGet(`${rewards.systemVariables}/${url}`)
    .then(response =>
      dispatch(getVariablesLookupData({ lookupData: R.prop('data', response), url }))
    )
    .catch(e => dispatchError(e, dispatch));

export const loadDependencies = () => dispatch => {
  const dependencyList = [];
  dependencyList.push(dispatch(loadSystemVariables()));
  dependencyList.push(dispatch(loadEligibilityOperators()));

  dispatch(loadDependenciesPending());

  return Promise.all(dependencyList)
    .then(() => dispatch(loadDependenciesSuccess()))
    .catch(body => {
      const err = R.path(['response', 'data', 'errors'], body);
      dispatch(loadDependenciesError());
      return Promise.reject(err);
    });
};

export const changePopulationField = (index, field, value, other) => (dispatch, getState) => {
  const state = getState();
  const errors = R.clone(R.path(['userManagement', 'eligibilityReducer', 'errors'], state));
  const newErrors = errors[index] || [];

  if (R.path(['eligibility', R.path(['rule', 'uid'], other)], newErrors)) {
    const newPopulation = R.prop('rule', other);
    newErrors.eligibility[newPopulation.uid] = validateEligibilitySimplePopulation(newPopulation);
  }

  dispatch(populationChange({ index, field, value, other }));
  if (!isBlank(newErrors)) {
    dispatch(updatePopulationError({ path: [index], data: newErrors }));
  }
};

export const validatePopulations = populations => dispatch => {
  const errors = [];
  populations.forEach((pop, index) => {
    errors[index] = validatePopulation(pop);
  });
  if (!isBlank(errors[0])) {
    dispatch(updatePopulationError({ path: [0, 'eligibility'], data: errors[0] }));
  }
  return errors;
};

export const loadPopulationById = populationId => dispatch => {
  if (!populationId) return null;

  return GET(`${rewards.eligibilityRules}/${populationId}`)
    .then(body => {
      const { eligibilityRules } = R.path(['data', 'response'], body);
      const { etag } = R.prop('headers', body);
      dispatch(updatePopulationEtag({ etag }));
      return dispatch(getPopulationById({ eligibilityRules }));
    })
    .catch(e => {
      dispatchError(e, dispatch);
      return Promise.reject(e);
    });
};
