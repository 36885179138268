import userModules from '../userModules';
import { TextOnly } from '../../../modules/common/Text';

const getSystemItems = () => [
  {
    title: TextOnly({ path: 'header.USER_MANAGEMENT' }),
    key: [userModules.USER_MANAGEMENT],
    items: [],
    route: '/users',
  },
  {
    title: TextOnly({ path: 'header.SETTINGS' }),
    key: [userModules.SETTINGS],
    items: [],
    route: '/settings',
  },
];

const system = () => [
  {
    title: TextOnly({ path: 'header.SYSTEM' }),
    key: [userModules.USER_MANAGEMENT, userModules.SETTINGS],
    items: getSystemItems(),
    route: '',
  },
];

export default system;
