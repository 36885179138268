import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import {
  Currency,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
} from '@trs/components';

import StyledTabularLegend from './TabularLegend.style';
import { StyledBoxColor } from './impactDetails.style';

const TabularLegend = ({
  items,
  labelKey,
  colors,
  accentColor,
  currency,
  activeIndex,
  highlightRow,
  removeHighlight,
  highlightActive,
  onItemClick,
  prefixId,
  idKey,
  cols,
  extraRow,
  ...otherProps
}) => (
  <StyledTabularLegend
    id={prefixId ? `${prefixId}_legend` : 'legend'}
    itemsCount={items.length}
    accentColor={accentColor}
    {...otherProps}
  >
    <Table hoverable>
      <TableHead>
        <TableRow>
          <TableHeadCell />
          {cols.map(col => (
            <TableHeadCell key={col.label}>{col.label}</TableHeadCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map((item, idx) => {
          const boxColor = colors && colors[idx];
          return (
            <TableRow
              highlightRow={highlightRow}
              currency={currency}
              onMouseOver={
                highlightActive &&
                (() => {
                  highlightActive(idx);
                })
              }
              onFocus={
                highlightActive &&
                (() => {
                  highlightActive(idx);
                })
              }
              id={prefixId ? `${prefixId}LegendItem${idx}` : `legendItem${idx}`}
              onMouseOut={removeHighlight}
              onBlur={removeHighlight}
              onClick={onItemClick(item[idKey])}
              label={item[labelKey] || ''}
              value={item.isNegative ? -item.value : item.value}
              percent={item.percentage}
              boxColor={colors && colors[idx]}
              key={item.key}
              active={activeIndex === idx}
              isAmount={item.isAmount}
              values={item.values}
            >
              <TableCell>
                <StyledBoxColor boxColor={boxColor.string ? boxColor.string() : boxColor} />
              </TableCell>
              {cols.map(col => {
                const value = R.prop(col.propName, item);
                return (
                  <TableCell key={col.propName}>
                    {col.type === 'string' && value}
                    {col.type === 'number' && <Currency currency="">{value}</Currency>}
                    {col.type === 'currency' && <Currency currency={currency}>{value}</Currency>}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })}
        <TableRow>
          <TableCell />
          {cols.map(col => {
            const value = R.prop(col.propName, extraRow);
            return (
              <TableCell key={col.propName}>
                {col.type === 'string' && value}
                {col.type === 'number' && <Currency currency="">{value}</Currency>}
                {col.type === 'currency' && <Currency currency={currency}>{value}</Currency>}
              </TableCell>
            );
          })}
        </TableRow>
      </TableBody>
    </Table>
  </StyledTabularLegend>
);

TabularLegend.defaultProps = {
  labelKey: 'label',
  pageSize: 5,
  currency: 'EUR',
  activeIndex: -1,
  highlightRow: false,
  highlightActive: () => {},
  removeHighlight: () => {},
  colors: null,
  accentColor: null,
  onItemClick: () => {},
  prefixId: null,
  cols: [],
  extraRow: {},
};

TabularLegend.propTypes = {
  idKey: PropTypes.string.isRequired,
  /** items to display in the legend */
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  /** Name of the label key of the data */
  labelKey: PropTypes.string,
  /** array of colors to use for the color box of the legend items */
  colors: PropTypes.arrayOf(PropTypes.string),
  /** the color to use for various stying purposes */
  accentColor: PropTypes.string,
  /** number of items to display per page */
  pageSize: PropTypes.number,
  /** prefix the item id's */
  prefixId: PropTypes.string,
  /** currency to display value with */
  currency: PropTypes.string,
  /** index of the selected item in the legend */
  activeIndex: PropTypes.number,
  /** if active, the LegendItem row will be highlighted on mouseenter */
  highlightRow: PropTypes.bool,
  /** callback to update the selected index */
  highlightActive: PropTypes.func,
  /** callback to reset the selected index */
  removeHighlight: PropTypes.func,
  /** action on item click */
  onItemClick: PropTypes.func,
  cols: PropTypes.arrayOf(PropTypes.shape({})),
  extraRow: PropTypes.shape({}),
};

export default TabularLegend;
