import * as R from 'ramda';
import { isBlank } from '@trs/utils';
import actionTypes from '../actions/types';
import getCorrelatedFields, { getResetCorrelatedDimensions } from './helpers';

const initialState = {
  1: {},
};

const fieldReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.ADD_COMPARE: {
      const {
        payload: { compareData },
      } = action;

      const lastIdx = parseInt(R.last(R.keys(state)), 10);
      const newState = R.reduce(
        (result, dimensionId) => {
          const { callback } = compareData[dimensionId];
          return {
            ...result,
            [dimensionId]: { ...state[lastIdx][dimensionId], callback },
          };
        },
        {
          ...state[lastIdx],
        }
      )(R.keys(compareData));

      return {
        ...state,
        [lastIdx + 1]: newState,
      };
    }
    case actionTypes.REMOVE_COMPARE: {
      const newState = R.dissoc(action.payload, state);
      return {
        ...newState,
      };
    }
    case actionTypes.SET_INITIAL_FIELDS:
    case actionTypes.RE_INITIALIZE_FIELDS: {
      const { instanceId, payload: fields } = action;
      return {
        ...state,
        [instanceId]: {
          ...fields,
        },
      };
    }
    case actionTypes.LOAD_DATA_SUCCESS: {
      const { instanceId, payload: fields } = action;

      return {
        ...state,
        [instanceId]: {
          ...fields,
        },
      };
    }
    case actionTypes.LOAD_DIMENSIONS_BY_DEFAULT_SUCCESS: {
      const {
        instanceId,
        payload: { baseFields, fieldsOptions },
      } = action;

      const newFieldInstanceState = R.keys(baseFields).reduce((acc, dimensionId) => {
        let stateFieldBase = state[instanceId][dimensionId];

        if (!isBlank(fieldsOptions[dimensionId])) {
          stateFieldBase = {
            ...stateFieldBase,
            ...{
              options: fieldsOptions[dimensionId],
              value: '',
            },
          };
        }

        return { ...acc, [dimensionId]: stateFieldBase };
      }, {});

      return {
        ...state,
        [instanceId]: {
          ...newFieldInstanceState,
        },
      };
    }
    case actionTypes.RESET_MODELLER_EMPLOYEE_CORRELATED_DIMENSIONS: {
      const {
        payload: { resetDimensions },
        instanceId,
      } = action;

      return {
        ...state,
        [instanceId]: {
          ...state[instanceId],
          ...getResetCorrelatedDimensions(resetDimensions, state[instanceId]),
        },
      };
    }
    case actionTypes.HANDLE_CHANGE: {
      const { instanceId, field, payload } = action;
      if (R.path([field, 'id'], state[instanceId]) === field) {
        return {
          ...state,
          [instanceId]: {
            ...state[instanceId],
            [field]: {
              ...R.path([instanceId, field], state),
              value: payload,
            },
          },
        };
      }
      return state;
    }
    case actionTypes.CORRELATE_FIELDS: {
      const { instanceId, payload } = action;
      return {
        ...state,
        [instanceId]: {
          ...state[instanceId],
          ...getCorrelatedFields(payload, state[instanceId]),
        },
      };
    }
    case actionTypes.RESET_STORE:
      return initialState;
    default:
      return state;
  }
};

export default fieldReducer;
