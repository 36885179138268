"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
var _utils = require("@trs/utils");
var _templateObject, _templateObject2;
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }
var StyledDividedContainer = _styledComponents["default"].div.withConfig({
  displayName: "DividedContainerstyle__StyledDividedContainer",
  componentId: "sc-13n9989-0"
})(["", ";"], function (props) {
  var _props$theme = props.theme,
    color = _props$theme.palette,
    spacing = _props$theme.spacing,
    genericBorderRadius = _props$theme.extra.genericBorderRadius;
  var withBorder = props.withBorder,
    leftWidth = props.leftWidth,
    rightWidth = props.rightWidth,
    mobileOnTop = props.mobileOnTop,
    separatorColor = props.separatorColor;
  var leftChildrenWidth = leftWidth || '40%';
  var rightChildrenWidth = rightWidth || '60%';
  var separator = separatorColor || color.primary;
  return (0, _styledComponents.css)(["display:flex;flex-wrap:wrap;.container-title{margin:0;margin-bottom:", ";width:377px;white-space:pre-line;}.container-left{div:last-child{margin-bottom:0;}}> div{flex-basis:100%;}", " .container-left{margin-bottom:", ";}", " .with-separator{&::after{content:'';display:block;width:30%;border-bottom:1px solid ", ";margin:", " 0 ", ";}}", ";"], spacing.tiny, withBorder && (0, _styledComponents.css)(["border:1px solid ", ";border-radius:", ";padding:", ";"], color.primary, genericBorderRadius, spacing.small), spacing.basePlus, mobileOnTop === 'right' && (0, _styledComponents.css)(["flex-direction:column-reverse;", " .container-left{margin-bottom:0;}"], _utils.media.mobileLandscape(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n          flex-direction: row;\n        "])))), separator, spacing.base, spacing.small, _utils.media.mobileLandscape(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n        align-items: center;\n\n        ", "\n\n        .container-left {\n          flex-basis: ", ";\n          max-width: ", "; /*needed for IE11*/\n          width: ", ";\n          margin-bottom: 0;\n          padding: 0;\n          display: table;\n        }\n\n        .container-right {\n          border-left: 1px solid ", ";\n          flex-basis: ", ";\n          max-width: ", "; /*needed for IE11*/\n          width: ", ";\n          padding-left: ", ";\n        }\n\n        .with-separator {\n          &::after {\n            display: none;\n          }\n\n          * {\n            white-space: normal;\n          }\n        }\n      "])), withBorder && (0, _styledComponents.css)(["padding:", " ", ";"], spacing.small, spacing.base), leftChildrenWidth, leftChildrenWidth, leftChildrenWidth, separator, rightChildrenWidth, rightChildrenWidth, rightChildrenWidth, spacing.large));
});
StyledDividedContainer.displayName = 'StyledDividedContainer';
var _default = StyledDividedContainer;
exports["default"] = _default;