import styled, { css } from 'styled-components';

const StyledFormGroup = styled.div`
  ${props => {
    const { spacing, palette } = props.theme;
    const { width, inline, zIndex, isSaving, marginBottom } = props;

    return css`
      width: ${width || '400'}px;
      margin: 0 auto ${spacing.basePlus};
      ${marginBottom &&
        css`
          margin-bottom: ${marginBottom};
        `} position: relative;

      .inline-item.no-margin {
        margin: 0;
      }

      ${isSaving &&
        css`
          cursor: wait;
        `} &:last-of-type {
        margin: 0 auto;
      }

      label {
        position: absolute;
        left: -${spacing.xLarge};
        transform: translateX(-100%);
      }

      input,
      textarea {
        width: 100%;
      }

      ${width &&
        css`
          transform: translateX(${width - 360}px);
        `} ${inline &&
        css`
          display: flex;
          z-index: ${zIndex};

          .inline-item {
            margin-right: ${spacing.basePlus};
            width: 200px;
            &:last-child {
              margin-right: 0;
            }
          }
          div.Select {
            width: 185px;

            input {
              max-width: 150px;
            }
            .Select-menu-outer {
              background-color: ${palette.basic.white};

              .Select-option {
                margin: ${spacing.tiny} 0;
              }
              .Select-option:nth-child(2) {
                border-top: 1px solid ${palette.gray.charlie};
              }
            }
          }
          .radio-group-wrapper {
            width: 150px;
            justify-content: flex-start;

            .radio-wrapper {
              margin-right: ${spacing.small};
            }
          }
        `};
    `;
  }};
`;

StyledFormGroup.displayName = 'StyledFormGroup';
export default StyledFormGroup;
