export default {
  REWARDS_CONFIGURATION_TITLE: 'Rewards',
  REWARDS_CONFIGURATION_SUBTITLE: 'Please set rewards configuration',
  REWARDS_CONFIGURATION_CAPTION:
    'Rewards configuration allows you to configure reward categories levels that will be used when creating your rewards.',
  REWARDS_SUBTITLE: 'Rewards specifications will be used when creating rewards.',
  REWARDS_CATEGORIES_TITLE: 'Rewards Categories',
  REWARDS_CATEGORIES_SUBTITLE:
    'Customise rewards categories to be used when creating rewards. Please note that you will not be able to delete a reward category, subcategory, or type if it is core, or if it is in use by existing rewards.',
  COLUMN_MARKED_IN_USE: 'Marked in Use',
  NO_RESULTS: 'No results found.',
  YES: 'Yes',
  NO: 'No',
  ADD_REWARDS_SUBCATEGORY: 'Add Rewards Subcategory',
  EDIT_REWARDS_CATEGORY: 'Edit Rewards Category',
  DELETE_REWARDS_CATEGORY: 'Delete Rewards Category',
  ADD_REWARDS_TYPE: 'Add Rewards Type',
  EDIT_REWARDS_SUBCATEGORY: 'Edit Rewards Subcategory',
  DELETE_REWARDS_SUBCATEGORY: 'Delete Rewards Subcategory',
  EDIT_REWARDS_TYPE: 'Edit Rewards Type',
  DELETE_REWARDS_TYPE: 'Delete Rewards Type',
  ADD_REWARDS_CATEGORY: 'Add Rewards Category',
  REWARD_CATEGORIES: 'Reward Categories',
  REWARDS_SUCCESSFULL_UPDATE: 'Rewards categories have been successfully updated.',
};
