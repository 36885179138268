/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import { isBlank } from '@trs/utils';
import { EMAIL_REGEX } from '@trs/config';
import PropTypes from 'prop-types';

import * as R from 'ramda';
import { Checkbox, InputV2, SelectV2 } from '@trs/components';
import { formatNumber } from '@trs/utils/lib/general';
import { TextOnly } from '../../../../../common/Text';
import { getError } from '../../../../../../config/errors';

export const mapBEToFE = (model = []) =>
  R.map(
    item => ({
      ...item,
      ...item.foreignExchange,
      ...item.targetMarketPosition,
      ...item.contactModel,
      status: false,
      contactName: R.path(['contactModel', 'name'], item) || R.path(['contact', 'name'], item),
      email: R.path(['contactModel', 'email'], item) || R.path(['contact', 'email'], item),
      country: item.name,
    }),
    model
  );

export const groupCountriesByCluster = countries => {
  return { All: countries, ...R.groupBy(R.prop('cluster'), countries) };
};
export const getColumnConfig = () => ({
  isActive: {
    id: 'isActive',
    title: TextOnly({ path: 'benchmarking.COLUMN_STATUS' }),
  },
  country: {
    id: 'country',
    title: TextOnly({ path: 'benchmarking.COLUMN_COUNTRY' }),
  },
  basePay: {
    id: 'basePay',
    title: TextOnly({ path: 'benchmarking.COLUMN_BASE_PAY' }),
  },
  fixedPay: {
    id: 'fixedPay',
    title: TextOnly({ path: 'benchmarking.COLUMN_FIXED_PAY' }),
  },
  variablePay: {
    id: 'variablePay',
    title: TextOnly({ path: 'benchmarking.COLUMN_VARIABLE_PAY' }),
  },
  benefits: {
    id: 'benefits',
    title: TextOnly({ path: 'benchmarking.COLUMN_BENEFITS' }),
  },
  currency: {
    id: 'currency',
    title: TextOnly({ path: 'benchmarking.COLUMN_CURRENCY' }),
  },
  rate: {
    id: 'rate',
    title: TextOnly({ path: 'benchmarking.COLUMN_FX_RATE' }),
  },
  contactName: {
    id: 'contactName',
    title: TextOnly({ path: 'benchmarking.COLUMN_CONTACT_NAME' }),
    width: 500,
  },
  email: {
    id: 'email',
    title: TextOnly({ path: 'benchmarking.COLUMN_CONTACT_EMAIL' }),
    width: 500,
  },
  isManualSetup: {
    id: 'isManualSetup',
    title: TextOnly({ path: 'benchmarking.COLUMN_MANUAL' }),
    alignment: 'center',
  },
});

export const getCountriesObject = (countries = []) =>
  R.reduce(
    (acc, item) => {
      acc[item.code] = {
        ...item,
        ...item.foreignExchange,
        ...item.targetMarketPosition,
        ...item.contactModel,
        status: false,
        contactName: R.path(['contactModel', 'name'], item) || R.path(['contact', 'name'], item),
        email: R.path(['contactModel', 'email'], item) || R.path(['contact', 'email'], item),
        country: item.name,
      };
      return acc;
    },
    {},
    countries
  );

export const tmpOptions = [
  {
    value: 50,
    label: 50,
  },
  {
    value: 62.5,
    label: 62.5,
  },
  {
    value: 75,
    label: 75,
  },
  {
    value: 100,
    label: 100,
  },
];

// eslint-disable-next-line react/prop-types
export const getTmpSelect = ({
  updateCountriesSelection,
  rowId,
  columnId,
  value = '',
  disabled,
}) => (
  <SelectV2
    id={`${rowId}-${columnId}`}
    onChange={ev =>
      updateCountriesSelection({
        rowId,
        field: columnId,
        value: ev.value,
      })
    }
    options={tmpOptions}
    isSearchable={false}
    value={{
      value,
      label: value,
    }}
    disabled={disabled}
  />
);

getTmpSelect.propTypes = {
  updateCountriesSelection: PropTypes.func.isRequired,
  rowId: PropTypes.string.isRequired,
  columnId: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
};
export const cellRenderer = (
  rowId,
  updateCountriesSelection,
  countriesMaping,
  errors,
  disabled
) => {
  const columnConfig = getColumnConfig();
  return {
    [columnConfig.isActive.id]: () => {
      const isActive = R.path([rowId, 'isActive'], countriesMaping);
      return (
        <Checkbox
          type="switch"
          disabled={disabled}
          onChange={(event, checked) =>
            updateCountriesSelection({
              rowId,
              field: columnConfig.isActive.id,
              value: checked,
            })
          }
          checked={isActive}
        />
      );
    },
    [columnConfig.basePay.id]: () =>
      getTmpSelect({
        updateCountriesSelection,
        rowId,
        columnId: columnConfig.basePay.id,
        value: R.path([rowId, columnConfig.basePay.id], countriesMaping),
        disabled,
      }),
    [columnConfig.fixedPay.id]: () =>
      getTmpSelect({
        updateCountriesSelection,
        rowId,
        columnId: columnConfig.fixedPay.id,
        value: R.path([rowId, columnConfig.fixedPay.id], countriesMaping),
        disabled,
      }),
    [columnConfig.variablePay.id]: () =>
      getTmpSelect({
        updateCountriesSelection,
        rowId,
        columnId: columnConfig.variablePay.id,
        value: R.path([rowId, columnConfig.variablePay.id], countriesMaping),
        disabled,
      }),
    [columnConfig.benefits.id]: () =>
      getTmpSelect({
        updateCountriesSelection,
        rowId,
        columnId: columnConfig.benefits.id,
        value: R.path([rowId, columnConfig.benefits.id], countriesMaping),
        disabled,
      }),
    [columnConfig.rate.id]: data => {
      return formatNumber(data);
    },
    [columnConfig.contactName.id]: data => (
      <InputV2
        isDisabled={disabled}
        id={`${rowId}-${columnConfig.contactName.id}`}
        value={data || ''}
        error={R.path([rowId, columnConfig.contactName.id], errors)}
        placeholder={TextOnly({ path: 'benchmarking.COLUMN_CONTACT_NAME' })}
        onChange={ev =>
          updateCountriesSelection({
            rowId,
            field: columnConfig.contactName.id,
            value: ev.target.value,
          })
        }
      />
    ),
    [columnConfig.email.id]: data => (
      <InputV2
        id={`${rowId}-${columnConfig.email.id}`}
        isDisabled={disabled}
        value={data || ''}
        error={R.path([rowId, columnConfig.email.id], errors)}
        placeholder={TextOnly({ path: 'benchmarking.COLUMN_CONTACT_EMAIL' })}
        onChange={ev =>
          updateCountriesSelection({
            rowId,
            field: columnConfig.email.id,
            value: ev.target.value,
          })
        }
      />
    ),
    [columnConfig.isManualSetup.id]: () => {
      const isActive = R.path([rowId, 'isManualSetup'], countriesMaping);
      return (
        <Checkbox
          type="normal"
          disabled={disabled}
          onChange={(event, checked) =>
            updateCountriesSelection({
              rowId,
              field: columnConfig.isManualSetup.id,
              value: checked,
            })
          }
          checked={isActive}
        />
      );
    },
  };
};

export const getActiveCount = (countries = []) =>
  R.compose(R.length, R.values, R.filter(R.prop('isActive')))(countries);

export const getCountryDetailsModel = (countries = []) => {
  const countriesByCluster = groupCountriesByCluster(mapBEToFE(countries));
  const countriesMaping = getCountriesObject(countries);
  const activeCounter = getActiveCount(countries);

  return {
    countriesByCluster,
    countriesMaping,
    activeCounter,
    countryList: countries,
  };
};

export const getListColumnConfig = () => R.values(getColumnConfig());

export const validateCountryDetails = (countryDetails = {}) => {
  const columnConfig = getColumnConfig();

  const errors = {};
  R.map(item => {
    if (item.isActive) {
      if (isBlank(item.contactName)) {
        errors[item.code] = {
          ...errors[item.code],
          [columnConfig.contactName.id]: getError(
            TextOnly({ path: 'generic.ERROR_REQUIRED' }),
            TextOnly({ path: 'benchmarking.COLUMN_CONTACT_NAME' })
          ),
        };
      }
      if (isBlank(item.email)) {
        errors[item.code] = {
          ...errors[item.code],
          [columnConfig.email.id]: getError(
            TextOnly({ path: 'generic.ERROR_REQUIRED' }),
            TextOnly({ path: 'benchmarking.COLUMN_CONTACT_EMAIL' })
          ),
        };
      }
    }

    if (!isBlank(item.email) && !EMAIL_REGEX.test(item.email)) {
      errors[item.code] = {
        ...errors[item.code],
        [columnConfig.email.id]: TextOnly({ path: 'benchmarking.INVALID_EMAIL' }),
      };
    }
    return item;
  }, countryDetails);
  return errors;
};

export default {
  getColumnConfig,
  groupCountriesByCluster,
  mapBEToFE,
  getCountriesObject,
  getCountryDetailsModel,
  getListColumnConfig,
};
