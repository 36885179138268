import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, Card, Input, Label } from '@trs/components';
import { getEventValue, handleEnterKeypress } from '@trs/utils';
import { EMAIL_REGEX } from '@trs/config';
import StyledFormGroup from '../../variables/components/FormGroup.style';
import * as userActions from '../../../actions/userActions';
import { showBanner, removeAllBanners } from '../../../actions/globalActions';
import StyledLoginPage from './Login.style';
import cmsKeys from '../../cms/keys';
import { CONFIG_AUTH } from '../../../config/api';

const cmsStateKey = cmsKeys.userManagement;

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      isValidFormat: true,
      errors: [],
      loading: false,
    };
  }

  handleUsernameChange = (event) => {
    const username = getEventValue(event);
    const isValidFormat = username ? EMAIL_REGEX.test(username) : true;

    this.setState({ username, isValidFormat });
  };

  handleInputBlur = () => {
    const { isValidFormat } = this.state;
    const { label } = this.props;
    const errors = [];

    if (!isValidFormat) {
      errors.push(label.LOGIN_INVALID_EMAIL_FORMAT);
    }

    this.setState({ errors });
  };

  handleSubmit = () => {
    const { label } = this.props;
    this.setState({ loading: true });
    this.props.actions
      .validateUser(this.state.username.trim())
      .then((response) => {
        const isValid = response.data.response;

        if (isValid) {
          this.props.actions.redirectUser(CONFIG_AUTH.tenant_domain);
        } else {
          this.props.actions.showBanner({
            type: 'error',
            content: label.LOGIN_INVALID_EMAIL,
          });
          this.setState({ loading: false });
        }
      })
      .catch(() => {
        this.props.actions.showBanner({
          type: 'error',
        });
        this.setState({ loading: false });
      });
  };

  render() {
    const { user, label } = this.props;
    const { errors, isValidFormat, loading } = this.state;
    const disableSubmit = !this.state.username || !isValidFormat || loading;

    return (
      <StyledLoginPage>
        {user.isLoggedIn && <Redirect push to="/" />}
        <Card title={label.LOGIN_TITLE}>
          <StyledFormGroup className="login-form-group">
            <Label htmlFor="username">{label.LOGIN_USERNAME_LABEL}</Label>
            <Input
              id="username"
              onChange={this.handleUsernameChange}
              value={this.state.username}
              onKeyDown={!disableSubmit ? handleEnterKeypress(this.handleSubmit) : null}
              onBlur={this.handleInputBlur}
              helpText={label.LOGIN_USERNAME_HELP_TEXT}
              errors={errors}
            />
          </StyledFormGroup>
          <StyledFormGroup className="login-form-group">
            <Button id="authenticateUser" action={this.handleSubmit} disabled={disableSubmit}>
              {label.LOGIN_BUTTON_AUTHENTICATE}
            </Button>
          </StyledFormGroup>
        </Card>
      </StyledLoginPage>
    );
  }
}

Login.defaultProps = {
  user: {},
};

Login.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  user: PropTypes.shape({
    isLoggedIn: PropTypes.bool,
  }),
  label: PropTypes.shape({
    LOGIN_INVALID_EMAIL_FORMAT: PropTypes.string,
    LOGIN_BUTTON_AUTHENTICATE: PropTypes.string,
    LOGIN_USERNAME_HELP_TEXT: PropTypes.string,
    LOGIN_USERNAME_LABEL: PropTypes.string,
    LOGIN_TITLE: PropTypes.string,
    LOGIN_INVALID_EMAIL: PropTypes.string,
  }).isRequired,
  genericLabel: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  label: state.cms[cmsStateKey].data,
  genericLabel: state.cms.generic.data,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ ...userActions, showBanner, removeAllBanners }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
