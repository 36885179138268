"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _Log = _interopRequireDefault(require("./Log"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }
var withDeprecationNotice = function withDeprecationNotice(WrappedComponent, label) {
  _Log["default"].warn(label);
  return WrappedComponent;
};
var _default = withDeprecationNotice;
exports["default"] = _default;