import eligibility from './eligibility';

export const articlesLabels = {
  PAGE_TITLE: 'Article List',
  ARTICLE_LIST_TITLE: 'Articles',
  NEW_BUTTON: 'New Article',
  DISPLAY_NAME: 'Name',
  COUNTRY: 'Country',
  START_DATE: 'Start Date',
  END_DATE: 'End Date',
};

export const articlesFormLabels = {
  FORM_GENERAL_CARD_TITLE: 'General',
  FORM_VALIDITY_DATE_CARD_TITLE: 'Validity Date',
  FORM_ELIGIBILITY_CARD_TITLE: 'Eligibility',
  FORM_CONTENT_CARD_TITLE: 'Content',
  CONTENT_BANNER_LABEL: 'Banner',
  PREVIEW_TITLE: 'Preview article',
  GENERAL_LABEL_NAME: 'Name',
  GENERAL_PLACEHOLDER_NAME: 'Name of the article',
  GENERAL_LABEL_CATEGORY: 'Category',
  GENERAL_PLACEHOLDER_CATEGORY: 'Select a category',
  GENERAL_LABEL_COUNTRY: 'Country',
  GENERAL_PLACEHOLDER_COUNTRY: 'Select a country',
  VALIDITY_LABEL_START_DATE: 'Start Date',
  VALIDITY_LABEL_END_DATE: 'End Date',
  VALIDITY_CAPTION_START_DATE: 'Will become effective at the selected date, 00:00 [UTC]',
  VALIDITY_CAPTION_END_DATE: 'Will expire at the selected date, 23:59 [UTC]',
};

export const articlesDialogLabels = {
  DIALOG_PUBLISH_TITLE: 'Publish article?',
  DIALOG_PUBLISH_SUBTITLE:
    'You are about to publish this article to the system. The article ' +
    'will automatically become LIVE on its effective date (%Effective Date%) ' +
    'for the affected employees.',
  DIALOG_PUBLISH_EXPIRED_SUBTITLE:
    'You are about to publish this article to the system with the expired status.',
  DIALOG_LIVE_EDIT_SUBTITLE:
    'These changes will have an immediate effect on the statements of the employees ' +
    'that receive this article.',
  ARTICLE_LIST_SUBTITLE:
    'The section allows you to create articles that will be viewed by the employees in their Articles widget.',
  DIALOG_REVERT_TO_DRAFT_TITLE: 'Revert %CURRENT_STATUS% article to draft?',
  DIALOG_REVERT_TO_DRAFT_LOSE_CHANGES: 'You will lose any unsaved changes when reverting to draft.',
};

export const articlesBannerLabels = {
  REVERT_TO_DRAFT_SUCCESS: 'Article successfully moved to draft.',
  ARTICLE_LIST_NO_RESULTS: `No results found. 
  Please change your search criteria.`,
  ARTICLE_LIST_SEARCH_RESULTS_FOUND: '%NUMBER% result(s) found.',
};

export const articlesContentLabels = {
  ADD_MEDIA: 'Click to add media',
  EMBED_LINK: 'Embed link',
  ENTER_LINK_HERE: 'Enter link here',
  IMAGE_RECOMMENDED_SIZE: 'Recommended size',
  ENTER_TEXT_HERE: 'Enter text here',
  MEDIA_IMAGE_ALT_TEXT: 'Alt text',
  MEDIA_VIDEO_TITLE: 'Title',
  CANCEL_MEDIA_LINK: 'Cancel',
  ADD_MEDIA_LINK: 'Add',
  TEXT_PLACEHOLDER: 'Type your description here*',
  ARTICLE_LIST_SEARCH_PLACEHOLDER: 'Search a title or category',
};

export const newArticleLabels = {
  PAGE_GROUP: 'Article',
  ADD_ARTICLE_PAGE_TITLE: 'New Article',
  EDIT_ARTICLE_PAGE_TITLE: 'Edit Article',
  STATUS_LABEL: 'Status',
  TAB_SETTINGS: 'Settings',
  PREVIEW_BUTTON_PREVIEW_LABEL: 'Preview',
  EDIT_BUTTON: 'Edit',
  VIEW_BUTTON: 'View',
  REVERT_TO_DRAFT_BUTTON: 'Revert to Draft',
  CREATE_ARTICLE_BUTTON: 'Create Article',
  CATEGORY_LABEL: 'Category',
};

export default {
  ...eligibility,
  ...articlesLabels,
  ...articlesFormLabels,
  ...articlesContentLabels,
  ...articlesBannerLabels,
  ...articlesDialogLabels,
  ...newArticleLabels,
};
