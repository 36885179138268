import { v4 as uuid } from 'uuid';
import { ACTIVE_TYPES } from '../constants';

const initialState = {
  rules: [
    {
      key: uuid(),
      eligibility: {},
      additionalRewardsEligibility: {},
      formula: [],
      threshold: [],
      localOverride: {
        countryCode: '',
        country: '',
      },
      isStepper: false,
      stepper: {
        activeType: ACTIVE_TYPES.fixed,
        [ACTIVE_TYPES.fixed]: {
          steps: [],
        },
        [ACTIVE_TYPES.percentage]: {
          steps: [],
        },
      },
      complex: {
        activeType: ACTIVE_TYPES.fixed,
        [ACTIVE_TYPES.fixed]: {
          contribution: [],
          minimum: [],
          maximum: [],
        },
        [ACTIVE_TYPES.percentage]: {
          contribution: [],
          additionalFormulaItems: [],
          minimum: [],
          maximum: [],
        },
      },
    },
  ],
  hasSavedLogicBuilder: false,
  formulaOperators: [],
  categories: [],
  savedRewardId: '',
  savedRewardEtag: '',
  countries: [],
  currencies: [],
  videoPlatforms: [],
  defaultCurrencyMappings: [],
  fxSources: [],
  taxabilityValues: [],
  basicInformation: {
    country: '',
    fxSource: null,
    fxRateDate: '',
    fxRateCustomDate: '',
    currency: '',
    template: '',
    displayName: '',
    description: '',
    descriptionBanner: {},
    descriptionMedia: [],
    category: null,
    subcategory: null,
    type: null,
    includeInEmployeeView: true,
    includeInTheOverallTotal: true,
    isInternal: false,
    effectiveDate: null,
    recurrence: 'no',
    recurrenceCount: null,
    recurrenceFrequency: null,
    contributionType: 'gross',
    taxableReward: null,
    expiryDate: null,
  },
  hasChanges: false,
  redirectToRoot: false,
  redirectToExceptions: false,
  list: [],
  filterOptions: [],
  totalResults: 0,
  fetchingList: false,
  fetchingRewardsVersions: false,
  hasExceptions: false,
  systemVariables: [],
  linkableRewards: [],
  linkableRewardsLoading: false,
  eligibilityOperators: [],
  fxRateDates: [],
  fetchingRewardById: false,
  rewardFeedback: {
    list: [],
    filterOptions: [],
    totalResults: 0,
    fetchingList: false,
  },
};

export default initialState;
