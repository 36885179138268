import * as R from 'ramda';
import { v4 as uuid } from 'uuid';
import { isBlank } from '@trs/utils';
import { roundFunctionTypes, DATA_TYPES } from '@trs/components';
import { mapTableRowsWithIndexAndGetTotal } from 'modules/ltiPlans/common/helpers';
import { RECURRENCE_OPTIONS, VESTING_TYPES, FX_RATE_DATE_CUSTOM } from '../constants';

const addDataType = ({ formulaItem, argument }) => {
  const dataType =
    formulaItem && roundFunctionTypes.includes(formulaItem.value)
      ? DATA_TYPES.NUMERIC
      : DATA_TYPES.MIXED;

  return R.map(R.assoc('dataType', dataType))(argument);
};

const addUuidsToFormulaItems = (formulaItems) =>
  formulaItems.map((formulaItem) =>
    formulaItem.arguments
      ? {
          key: uuid(),
          ...formulaItem,
          arguments: [
            addUuidsToFormulaItems(R.path(['arguments', 0], formulaItem)),
            addUuidsToFormulaItems(
              addDataType({ formulaItem, argument: R.path(['arguments', 1], formulaItem) })
            ),
          ],
        }
      : {
          ...formulaItem,
          key: uuid(),
        }
  );

const addUuidsToEligibilityItems = (items) => {
  const newRules = items;

  if (isBlank(newRules)) return null;

  newRules.uid = uuid();

  if (R.prop('eligibilityRows', newRules)) {
    newRules.eligibilityRows.map((item) => addUuidsToEligibilityItems(item));
  }

  return newRules;
};

export const mapRulesToRequestModel = (rules) => {
  return rules.map((rule) => {
    return {
      ...rule,
      key: uuid(),
      eligibility: R.merge({}, addUuidsToEligibilityItems(rule.eligibility)),
      formula: addUuidsToFormulaItems(rule.formulaItems),
      localOverride: {
        countryCode: '',
        country: '',
      },
    };
  });
};

export const mapResponseToBasicInfoModel = (
  {
    template,
    displayName,
    description,
    countryCode,
    currency,
    foreignExchange,
    startDate,
    lengthInYears,
    vestingType,
    recurrence,
  },
  countries
) => {
  const recurrenceFrequency = R.path(['frequency'], recurrence);
  const recurrenceCount = R.path(['every'], recurrence) || null;
  const countryWithValue = R.filter((country) => country.code === countryCode, countries)[0];
  const fxSourceId = R.path(['sourceId'], foreignExchange);
  const fxRateDate = R.path(['fixedDateVariableName'], foreignExchange);
  const fxRateCustomDate = R.path(['fixedCustomDate'], foreignExchange);
  const vestingTypeWithValue = R.filter(
    (vesting) => vesting.code === vestingType,
    VESTING_TYPES
  )[0];

  return {
    template,
    displayName,
    description,
    country: countryWithValue,
    countryCode,
    currency,
    fxSource: fxSourceId,
    fxRateDate: R.isNil(fxRateDate) && fxRateCustomDate ? FX_RATE_DATE_CUSTOM.value : fxRateDate,
    fxRateCustomDate: fxRateCustomDate || null,
    startDate,
    vestingType: vestingTypeWithValue,
    lengthInYears,
    recurrenceCount,
    recurrenceFrequency:
      recurrenceFrequency && recurrenceFrequency && RECURRENCE_OPTIONS[recurrenceFrequency - 1],
  };
};

export const mapResponseToRuleBuilderRuleModel = ({ rules }, initialState) => {
  return isBlank(rules) ? initialState.rules : mapRulesToRequestModel(rules);
};

export const mapResponseToRuleBuilderDistributionModel = ({ distribution }, initialState) => {
  if (isBlank(distribution)) return initialState.distribution;

  const { type } = distribution;
  const swapTable = mapTableRowsWithIndexAndGetTotal(distribution);

  return {
    distribution: {
      type,
      table: {
        ...initialState.distribution.table,
        ...swapTable,
        dirtyRows: [],
      },
    },
    swapDistribution: {
      ...initialState.swapDistribution,
      type,
      table: {
        ...swapTable,
        dirtyRows: [],
      },
    },
  };
};

export const transformResponseGetAllPlans = (ltiPlans) => {
  if (isBlank(ltiPlans)) return [];

  return ltiPlans.map((item) => ({
    ...item,
    displayName: item.name,
    menuTransform: (menuList, dataItem) => {
      const index = R.findIndex(R.propEq('key', 'edit'))(menuList);
      return dataItem.canEdit ? menuList : R.remove(index, 1, menuList);
    },
  }));
};

export const transformDirtyRowsData = ({ data, id, value }) => {
  const newDirtyRowsState = R.clone(data);
  const index = R.findIndex(R.propEq('id', id))(newDirtyRowsState);

  if (R.equals(index, -1)) {
    newDirtyRowsState.push({ id, value });
  } else {
    newDirtyRowsState[index] = R.assoc('value', value, newDirtyRowsState[index]);
  }

  return newDirtyRowsState;
};

export default mapResponseToBasicInfoModel;
