import buttons from './buttons';
import dialog from './dialog';
import pageTitles from './pageTitles';
import form from './form';
import errors from '../errors';
import accessibility from './accessibility';

export default {
  ...buttons,
  ...dialog,
  ...pageTitles,
  ...form,
  APP_TITLE: 'Welcome to %PLATFORM%',
  LOGO: '%PLATFORM%',
  COOKIE_POLICY:
    'We use cookies to ensure that we give you the best experience on our website. If you continue to use this site we will assume that you are happy with it.',
  CONFLICT_BANNER: 'This resource has already been updated. Please refresh the page and try again.',
  FILE_SELECTION_LABEL: 'Select File',
  LEGAL_DISCLAIMER: 'Legal Disclaimer',
  LEGAL_DISCLAIMER_DESC:
    'At the time of viewing, the data contained in this statement was correct to the best of our knowledge. However, you may be entitled to other benefits not shown on this statement. Please note that this statement is for information purposes only and does not amend your terms and conditions of employment.',
  LEGAL_DISCLAIMER_REDIRECT:
    "For full details in your local language, click <a href='https://drive.google.com/file/d/1t74v8mSmYW0GlxzaUigwTuGuqDedV0Aa/view?usp=sharing' target='_blank'>here</a>",
  DOWNLOAD_IN_PROGRESS: '%FILENAME% download is in progress',
  DOWNLOAD_FINISHED: '%FILENAME% download finished successfully',
  UPLOAD_FILE_SUCCESS: 'Upload succesfully completed.',
  REPORT: 'Report',
  FILE: 'File',
  SUBMIT_SUCCESS: '%NAME% has been successfully submitted',
  PAGINATION_EXCEEDED:
    'Pagination exceeded.<br />The maximum number of pages is <strong>%NR%</strong>',
  PROCESSED: 'processed',
  VALIDATED: 'validated',
  EXPIRE_TITLE: 'Your session is about to expire.',
  EXPIRE_SUBTITLE:
    'You will be logged out in <strong>%TIMESTAMP%</strong>. Please click Continue to stay signed in.',
  STATUS_DRAFT: 'Draft',
  STATUS_PUBLISHED: 'Published',
  STATUS_LIVE: 'Live',
  STATUS_EXPIRED: 'Expired',
  STATUS_ERROR: 'Error',
  STATUS_CALCULATING: 'Calculating',
  ...errors,
  ...accessibility,
};
