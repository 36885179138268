import * as R from 'ramda';
import { handleActions } from 'redux-actions';
import { loadDashboardCountries } from '../actions';
import initialState from './initialState';
import { mapResponseToColumns } from '../helpers';

const dashboard = handleActions(
  new Map([
    [
      loadDashboardCountries,
      (state, action) => {
        const {
          ready,
          error,
          payload: { countries, globalBenchmarkingId },
        } = action;

        if (!ready) {
          return {
            ...state,
            isLoadingDashboardCountries: true,
          };
        }

        if (error) {
          return {
            ...state,
            isLoadingDashboardCountries: false,
            loadError: error,
          };
        }
        const dashboardCountries = mapResponseToColumns(countries, globalBenchmarkingId);
        return {
          ...state,
          globalBenchmarkingId,
          dashboardCountries,
          isLoadingDashboardCountries: false,
        };
      },
    ],
  ]),
  R.clone(initialState)
);

export default dashboard;
