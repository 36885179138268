"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var StyledMenuList = _styledComponents["default"].div.withConfig({
  displayName: "MenuListstyle__StyledMenuList",
  componentId: "sc-v4wiix-0"
})(["", ";"], function (props) {
  var palette = props.theme.palette;
  var isExpanded = props.isExpanded,
    position = props.position;
  var positioningProps;
  switch (position) {
    case 'top-left':
      positioningProps = {
        box: (0, _styledComponents.css)(["left:50%;top:50%;"]),
        close: (0, _styledComponents.css)(["left:0;transform:translate(-50%,-50%);"])
      };
      break;
    case 'bottom-left':
      positioningProps = {
        box: (0, _styledComponents.css)(["left:50%;bottom:0%;"]),
        close: (0, _styledComponents.css)(["left:0;bottom:0;transform:translate(-50%,50%);"])
      };
      break;
    case 'bottom-right':
      positioningProps = {
        box: (0, _styledComponents.css)(["right:50%;bottom:0%;"]),
        close: (0, _styledComponents.css)(["right:0;bottom:0;transform:translate(50%,50%);"])
      };
      break;
    case 'top-right':
    default:
      positioningProps = {
        box: (0, _styledComponents.css)(["right:10px;top:50%;"]),
        close: (0, _styledComponents.css)(["right:50%;top:50%;transform:translate(50%,-50%);"])
      };
      break;
  }
  return (0, _styledComponents.css)(["position:relative;float:right;.content-toggler{cursor:pointer;display:inline-block;width:10px;text-align:center;line-height:1;i{font-size:24px;}}.single-list-item{color:", ";cursor:pointer;&.:not(.icon-label){text-decoration:underline;}&.disabled{pointer-events:none;cursor:not-allowed;color:", ";}}.list-container{position:absolute;z-index:99;", ";}", " .close-button{cursor:pointer;color:", ";display:inline-block;line-height:1;z-index:100;}"], palette.secondary, palette.gray.charlie, positioningProps.box, isExpanded && (0, _styledComponents.css)([".close-button{position:absolute;z-index:4;", " width:20px;}"], positioningProps.close), palette.primary);
});
StyledMenuList.displayName = 'StyledMenuList';
var _default = StyledMenuList;
exports["default"] = _default;