export default {
  availableOptions: {},
  availableOptionsRespModel: [],
  selectedRewards: {},
  metaData: {
    agreedTos: false,
  },
  comment: '',
  employeeFlexibleRewardId: null,
  savedEtag: '',
  isFetchingOptions: false,
  isComputingStatement: false,
  isSaving: false,
  data: {},
  current: [],
  future: [],
  currency: [],
  availableOptionsMap: {},
  accessFlags: {},
  employeeStatementHasBeenReset: false,
  isIntegerOnly: false,
};
