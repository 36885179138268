import styled, { css } from 'styled-components';

const StyledMimicEmployeeViewBanner = styled.div`
  ${props => {
    const { palette, spacing } = props.theme;

    return css`
      background-color: ${palette.primary};
      color: ${palette.basic.white};
      left: 0;
      padding: ${spacing.tiny};
      position: fixed;
      text-align: center;
      top: 0;
      width: 100%;
      z-index: 10;

      span {
        margin: 0 ${spacing.basePlus} 0 0;
      }

      button {
        color: ${palette.basic.white};
      }
    `;
  }};
`;

StyledMimicEmployeeViewBanner.displayName = 'StyledMimicEmployeeViewBanner';
export default StyledMimicEmployeeViewBanner;
