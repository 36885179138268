"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styledComponents = _interopRequireWildcard(require("styled-components"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
var StyledChip = _styledComponents["default"].span.withConfig({
  displayName: "Chipstyle__StyledChip",
  componentId: "sc-xz5t09-0"
})(["", ""], function (props) {
  var outlined = props.outlined,
    type = props.type;
  var _props$theme = props.theme,
    palette = _props$theme.palette,
    typography = _props$theme.typography,
    spacing = _props$theme.spacing,
    chips = _props$theme.extra.chips;
  var colors = {};
  switch (type) {
    case 'primary':
      {
        colors = {
          border: chips.primary,
          background: chips.primary,
          text: chips.primary
        };
        break;
      }
    case 'secondary':
      {
        colors = {
          border: chips.secondary,
          background: chips.secondary,
          text: chips.secondary
        };
        break;
      }
    case 'success':
      {
        colors = {
          border: chips.success,
          background: chips.success,
          text: chips.success
        };
        break;
      }
    case 'warning':
      {
        colors = {
          border: chips.warning,
          background: chips.warning,
          text: chips.warning
        };
        break;
      }
    case 'error':
      {
        colors = {
          border: chips.error,
          background: chips.error,
          text: chips.error
        };
        break;
      }
    default:
      {
        colors = {
          border: chips["default"],
          background: chips["default"],
          text: chips["default"]
        };
        break;
      }
  }
  return (0, _styledComponents.css)(["display:flex;height:", ";align-items:center;justify-content:center;min-width:120px;padding:", " ", ";text-align:center;font-weight ", ";font-style:italic;line-height:", ";font-family:", ";border-radius:", ";font-size:", ";", "}"], spacing.basePlus, spacing.xTiny, spacing.xSmall, typography.weight.semiBold, typography.lineHeight.paragraph, typography.font.primary, spacing.base, typography.size.misc, outlined ? (0, _styledComponents.css)(["color:", ";border:1px solid ", ";background-color:", ";"], colors.text, colors.border, palette.basic.white) : (0, _styledComponents.css)(["color:", ";background-color:", ";"], palette.basic.white, colors.background));
});
StyledChip.displayName = 'StyledChip';
var _default = StyledChip;
exports["default"] = _default;