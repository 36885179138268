export default {
  STEP_DEFAULT: 'Default increment',
  STEP_INDEX: 'Increment %index%',
  STEP_REMOVE_DEFAULT_LABEL: 'remove default label',
  STEP_REMOVE_STEP_LABEL: 'remove step label',
  STEP_ADD_DEFAULT_LABEL: 'add default label',
  STEP_ADD_STEP_LABEL: 'add step label',
  STEP_ERROR_LABEL_MESSAGE: 'Label is required',
  STEP_ERROR_VALUE_MESSAGE: 'Value is required',
  STEP_DEFAULT_LABEL_PLACEHOLDER: 'Default increment',
  STEP_DEFAULT_VALUE_PLACEHOLDER: 'Value for increment 1',
  STEP_ADD: 'Add step',
};
