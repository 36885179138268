import React, { PureComponent } from 'react';
import { Icon, RichTextarea } from '@trs/components';
import onClickOutside from 'react-click-outside';
import PropTypes from 'prop-types';
import { TextOnly } from 'modules/common/Text';
import StyledDescriptionTooltip from './DescriptionTooltip.style';

const TOOLTIP_ICON_SIZE = 10;

class DescriptionTooltip extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { isOpen: false };
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  handleClickOutside = () => this.setState({ isOpen: false });

  toggleIsOpen = () => this.setState((prevState) => ({ isOpen: !prevState.isOpen }));

  render() {
    const { content, className, position } = this.props;
    const { isOpen } = this.state;

    return (
      <StyledDescriptionTooltip
        className={className}
        isOpen={isOpen}
        position={position}
        data-testid="description-tooltip"
      >
        {isOpen && (
          <div className="tooltip-text">
            <RichTextarea value={content} readOnly />
          </div>
        )}
        <Icon
          icon="ion-information"
          size={TOOLTIP_ICON_SIZE}
          onClick={this.toggleIsOpen}
          aria-label={TextOnly({ path: 'employeeStatement.REWARD_DESCRIPTION' })}
        />
      </StyledDescriptionTooltip>
    );
  }
}

DescriptionTooltip.propTypes = {
  content: PropTypes.string,
  className: PropTypes.string,
  /** The position where to render the tooltip (relative to the container) */
  position: PropTypes.oneOf(['top', 'bottom']),
};

DescriptionTooltip.defaultProps = {
  content: '',
  className: '',
  position: 'bottom',
};

export default onClickOutside(DescriptionTooltip);
